import { StyleSheet } from "react-native";
import { SCALING_FACTORS } from "~/components/text/constants";
import { FontSize } from "~/state/settings";
import { markdownStyles } from "./styles";
import { Theme } from "styled-components/native";

export function transformStyles(
  theme: Theme,
  fontScale?: FontSize,
  defaultStyles = markdownStyles
): StyleSheet.NamedStyles<any> {
  const scalingFactor = SCALING_FACTORS[fontScale || FontSize.Medium];
  const fontColor = theme.colors.black;

  const pxScaled = (px: number) => px * scalingFactor;

  return {
    ...(defaultStyles as StyleSheet.NamedStyles<any>),
    body: {
      ...defaultStyles.body,
      fontSize: pxScaled(defaultStyles.body.fontSize),
      lineHeight: pxScaled(defaultStyles.body.lineHeight),
      color: fontColor,
    },
    heading3: {
      ...defaultStyles.heading3,
      fontSize: pxScaled(defaultStyles.heading3.fontSize),
      lineHeight: pxScaled(defaultStyles.heading3.lineHeight),
      color: fontColor,
    },
    heading4: {
      ...defaultStyles.heading4,
      fontSize: pxScaled(defaultStyles.heading4.fontSize),
      lineHeight: pxScaled(defaultStyles.heading4.lineHeight),
      color: fontColor,
    },
    superscript: {
      ...defaultStyles.superscript,
      fontSize: pxScaled(defaultStyles.superscript.fontSize),
      lineHeight: pxScaled(defaultStyles.superscript.lineHeight),
      color: fontColor,
    },
    code_inline: {
      ...defaultStyles.code_inline,
      color: theme.colors.amber700,
      backgroundColor: theme.colors.white,
    },
    code_block: {
      backgroundColor: theme.colors.white,
    },
    pre: {
      backgroundColor: theme.colors.white,
    },
    blockquote: {
      backgroundColor: theme.colors.white,
    },
  };
}
