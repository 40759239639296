import { StyleSheet, View } from "react-native";
import styled from "styled-components/native";
import { Text2Center, Text3Center } from "~/components/text";
import { colors, fontSizes, lineHeights, spacers } from "~/styles/theme";

export const SavePrayerRequestsHeaderContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0 ${spacers.ss4};
`;

const SavePrayerRequestsHeaderButton = styled.TouchableOpacity`
  width: 100px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: ${spacers.ss4} ${spacers.ss5};
  border-radius: 42px;
`;

export const SavePrayerRequestsButtonText = styled(Text3Center)`
  font-family: SFPro;
  color: ${({ theme }) => theme.colors.gray800};
  line-height: ${lineHeights.lh3};
`;

export const SavePrayerRequestsDoneButton = styled(
  SavePrayerRequestsHeaderButton
)`
  background-color: ${({ theme }) => theme.colors.gray200};
`;

export const SavePrayerRequestsCancelButton = styled(
  SavePrayerRequestsHeaderButton
)``;

export const SavePrayerRequestsTitle = styled(Text2Center)`
  flex: 1;
  font-family: SFPro;
  letter-spacing: 0.5px;
`;

export const SavePrayerRequestsDivider = styled(View)`
  border: ${StyleSheet.hairlineWidth}px solid
    ${({ theme }) => theme.colors.gray300};
  margin: ${spacers.ss5} 0;
`;

export const PrayerRequestsInput = styled.TextInput.attrs(({ theme }) => ({
  placeholderTextColor: theme.colors.gray500,
}))`
  min-height: 200px;
  padding: 0 ${spacers.ss5};
  padding-top: ${spacers.ss3};
  padding-bottom: 200px;
  font-size: ${fontSizes.fs3};
`;
