import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { colors, spacers, lineHeights } from "~/styles/theme";
import { pxToNumber } from "~/utils/theme";
import { Text1, Text2 } from "~/components/text";
import { ImageCache } from "~/components/image-cache";
import { isWeb } from "~/utils/platform";

export const RESOURCE_HEIGHT = 118;

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  border-radius: ${spacers.ss6};
  border: 1px solid ${({ theme }) => theme.colors.neutral200};
  background-color: ${({ theme }) => theme.colors.white};
  height: ${RESOURCE_HEIGHT}px;
  margin: ${spacers.ss5} ${spacers.ss8};
  ${isWeb ? "margin-bottom: 0px" : ""}
`;

const RESOURCE_IMAGE_MARGIN = pxToNumber(spacers.ss6);
const RESOURCE_IMAGE_SIZE = `${RESOURCE_HEIGHT - RESOURCE_IMAGE_MARGIN}px`;

export const ResourceCover = styled(ImageCache)`
  height: ${RESOURCE_IMAGE_SIZE};
  width: ${RESOURCE_IMAGE_SIZE};
  margin-left: ${spacers.ss4};
  border-radius: ${spacers.ss5};
`;

export const ResourceBox = styled.View`
  height: ${RESOURCE_IMAGE_SIZE};
  width: ${RESOURCE_IMAGE_SIZE};
  margin-left: ${spacers.ss4};
  border-radius: ${spacers.ss5};
`;

export const Content = styled.View`
  flex: 1;
  align-self: flex-start;
  margin: ${spacers.ss5} ${spacers.ss6};
`;

export const AvatarContainer = styled.View`
  flex-direction: row;
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

export const AvatarBox = styled.View`
  margin-left: ${spacers.ss3};
  top: ${spacers.ss3};
`;

export const Title = styled(Text1)`
  font-size: 13px;
  letter-spacing: 0.5px;
  font-family: MontserratBold;
  text-transform: uppercase;
  line-height: ${lineHeights.lh1};
  color: ${({ theme }) => theme.colors.gray600};
  font-weight: bold;
`;

export const Description = styled(Text2)`
  font-size: 15px;
  margin-top: ${spacers.ss3};
  font-family: SFPro;
  line-height: ${lineHeights.lh2};
  color: ${({ theme }) => theme.colors.black};
`;

export const skeletonStyles = StyleSheet.create({
  image: {
    position: "absolute",
  },
  content: {
    flexDirection: "column",
    flex: 1,
  },
});

export const imageSkeletonLayout = [
  {
    height: pxToNumber(RESOURCE_IMAGE_SIZE),
    width: pxToNumber(RESOURCE_IMAGE_SIZE),
    marginLeft: pxToNumber(spacers.ss4),
    borderRadius: pxToNumber(spacers.ss5),
  },
];

export const contentSkeletonLayout = [
  { width: "100%", height: pxToNumber(lineHeights.lh1) },
  {
    width: "100%",
    height: pxToNumber(lineHeights.lh2),
    marginTop: pxToNumber(spacers.ss3),
  },
];
