import * as React from "react";
import { ListRenderItem, TextInput, TouchableOpacity } from "react-native";
import { useTheme } from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { LibraryStackParamList } from "~/navigation/library-stack";
import { Screen } from "~/components/screen";
import { Plan } from "~/components/plan";
import { NoPlansFound } from "~/components/no-plans-found";
import { formatMessage } from "~/utils/translation";
import { useAppSelector } from "~/state/hooks";
import { getPlansBySearchTerm } from "~/state/flamelink/selectors";
import type { PlansContent } from "~/state/flamelink";
import * as routes from "~/constants/routes";
import { isAndroid } from "~/utils/platform";

import {
  CancelText,
  PlanContainer,
  PlansContainer,
  PlanTitle,
  SearchBar,
  SearchContainer,
} from "./styles";
import { messages } from "./intl";

export type LibrarySearchProps = NativeStackScreenProps<
  LibraryStackParamList,
  "library.search"
>;

const keyExtractor = (plan: PlansContent) => plan.id;

interface Props {
  onCancel: () => void;
  onPlanPress: (planId: string) => void;
}

export const LibrarySearchComponent = ({ onCancel, onPlanPress }: Props) => {
  const searchBarRef = React.useRef<TextInput>(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const plans = useAppSelector((state) =>
    getPlansBySearchTerm(state, searchTerm)
  );
  const theme = useTheme();

  const onChangeSearch = (query: string) => setSearchTerm(query);

  const onReserSearchTerm = () => setSearchTerm("");

  const renderPlan: ListRenderItem<PlansContent> = React.useCallback(
    ({ item }) => {
      const onPress = () => onPlanPress(item.id);

      return (
        <PlanContainer>
          <Plan
            key={item.id}
            planId={item.id}
            onPress={onPress}
            withLoader={false}
          />

          <PlanTitle>{item.title}</PlanTitle>
        </PlanContainer>
      );
    },
    [onPlanPress]
  );

  React.useEffect(() => searchBarRef.current?.focus(), []);
  const color = theme?.colors.black;

  return (
    <>
      <SearchContainer>
        <SearchBar
          placeholder={formatMessage(messages.search)}
          onChangeText={onChangeSearch}
          value={searchTerm}
          ref={searchBarRef}
          iconColor={color}
          inputStyle={{ color }}
          placeholderTextColor={theme?.colors.gray500}
        />

        <TouchableOpacity onPress={onCancel}>
          <CancelText>{formatMessage(messages.cancel)}</CancelText>
        </TouchableOpacity>
      </SearchContainer>

      {plans.length ? (
        <PlansContainer
          data={plans}
          renderItem={renderPlan}
          keyExtractor={keyExtractor}
        />
      ) : (
        <NoPlansFound
          resetText={formatMessage(messages.resetSearchTerm)}
          onResetPress={onReserSearchTerm}
        />
      )}
    </>
  );
};

export const LibrarySearch: React.FC<LibrarySearchProps> = () => {
  const navigation = useNavigation<LibrarySearchProps["navigation"]>();

  const onCancel = React.useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onPlanPress = React.useCallback(
    (planId: string) => navigation.navigate(routes.plan, { planId }),
    [navigation]
  );

  return (
    <Screen screenName="library-search">
      <LibrarySearchComponent onCancel={onCancel} onPlanPress={onPlanPress} />
    </Screen>
  );
};
