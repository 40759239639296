import styled, { css } from "styled-components/native";
import { Text3 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const ChecklistContainer = styled.View`
  background-color: ${({ theme }) => theme.colors.gray100};
  border-radius: ${spacers.ss6};
  border-color: ${({ theme }) => theme.colors.gray300};
  border-width: 1px;
  padding: ${spacers.ss6};
  margin: ${spacers.ss4} ${spacers.ss5};
`;

export const ListTitle = styled(Text3)`
  font-family: SFProDisplayBold;
  margin: 0 0 ${spacers.ss3};
`;

export const ListItem = styled.View`
  flex-direction: row;
  margin: ${spacers.ss4} 0;
`;

export const ItemText = styled(Text3)<{ withMargin?: boolean }>`
  font-family: SFPro;
  margin: 0 ${spacers.ss6} 0 0;

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin-left: ${spacers.ss5};
    `}
`;

export const Bullet = styled(ItemText).attrs({
  children: "•",
})`
  margin: 0 ${spacers.ss4};
`;
