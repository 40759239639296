import styled from "styled-components/native";

import { Text1 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";
import { MaxWidthBreakpoints, getWebContainerStyle } from "~/utils/web";

export const Container = styled.View`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Content = styled.View`
  flex-direction: row;
  padding-vertical: ${spacers.ss8};
  ${getWebContainerStyle(MaxWidthBreakpoints.Desktop, false, true)}
`;

export const ButtonBox = styled.View`
  margin-right: ${spacers.ss6};
`;

export const TextBox = styled.View`
  flex: 1;
`;

export const ConsentTitle = styled(Text1)`
  font-family: SFProDisplayBold;
  margin-right: ${spacers.ss3};
`;

export const ConsentText = styled(Text1)`
  margin-right: ${spacers.ss3};
`;

export const ConsentButton = styled.TouchableOpacity`
  margin-right: ${spacers.ss3};
`;

export const ConsentButtonText = styled(Text1)`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

export const Button = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  padding: ${spacers.ss4} ${spacers.ss6};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

export const ButtonText = styled(Text1)`
  color: ${({ theme }) => theme.colors.white};
`;
