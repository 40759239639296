import * as React from "react";
import { Switch } from "react-native";
import MaterialIcon from "@expo/vector-icons/MaterialIcons";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "styled-components/native";
import {
  PaymentIntentResult,
  StripePaymentElementChangeEvent,
  loadStripe,
} from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { colors, spacers } from "~/styles/theme";
import { Icon, IconTypes } from "~/components/icon";
import { useAlerts } from "~/state/alerts";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { useModal } from "~/state/modal/hook.web";

import { Button } from "../buttons";
import { Footer } from "../footer";

import {
  Container,
  TitleBox,
  Title,
  DisclaimerBox,
  Text,
  EditButton,
  EditText,
  Section,
  TopBox,
  ConsiderTitle,
  ConsiderText,
  SwitchBox,
  SwitchText,
  CloseButton,
  Row,
  InputBox,
  InputLabel,
  WebInput,
} from "./styles";
import { messages } from "./intl";
import {
  fetchPaymentIntentClientSecret,
  getPaymentCurrency,
  getPaymentCurrencyLabel,
} from "~/state/payments";
import {
  updateDefaultPaymentMethod,
  confirmPayment as confirmPaymentAction,
} from "~/state/payments/actions";
import { getStripeProps } from "~/state/payments/utils";
import { logError } from "~/utils/logger";
import { store } from "~/state/store";

import { CONTRIBUTIONS_RETURN_URL } from "~/constants";
import { isValidEmail } from "~/utils/strings";

interface Props {
  value: number;
  isMonthly: boolean;
  setIsMonthly: (index: number) => void;
  onBack: () => void;
  onSuccess: () => void;
  setFirstName: (name: string) => void;
}

type OptionsMode = "payment" | "setup" | "subscription";

export const PaymentDetailsComponent = React.memo<Props>(
  ({ value, isMonthly, setIsMonthly, onBack, onSuccess, setFirstName }) => {
    const titleMessage = isMonthly
      ? messages.titleMonthly
      : messages.titleOneTime;

    const buttonMessage = isMonthly
      ? messages.buttonMonthly
      : messages.buttonOneTime;

    const uid = useAppSelector(getUserId);

    const [showConsider, setShowConsider] = React.useState(!!uid);
    const [isSwitched, setIsSwitched] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isComplete, setIsComplete] = React.useState(false);
    const [isCardPayment, setIsCardPayment] = React.useState(true);
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const env = useAppSelector(getEnvironment);
    const currency = useAppSelector(getPaymentCurrency);
    const currencyLabel = useAppSelector(getPaymentCurrencyLabel);
    const theme = useTheme();

    const dispatch = useAppDispatch();
    const { showModal, hideModal } = useModal();

    const navigation = useNavigation();
    const { pushAlert } = useAlerts();
    const stripe = useStripe();
    const elements = useElements();

    const amount = `${currencyLabel}${value}`;
    const considerAmount = `${currencyLabel}${value}`;

    const areFieldsValid =
      uid || !isCardPayment || (!!name && isValidEmail(email));
    const isFormComplete = isComplete && !!areFieldsValid;

    const handleSuccess = React.useCallback(() => {
      onSuccess();
      setIsLoading(false);
    }, [onSuccess]);

    const handleError = React.useCallback(
      (error: any) => {
        navigation.goBack();
        setIsLoading(false);
        logError(error);
      },
      [navigation]
    );

    const handlePayPress = React.useCallback(async () => {
      await elements?.submit();

      setIsLoading(true);
      try {
        const { clientSecret, error } = await fetchPaymentIntentClientSecret({
          amount: value,
          currency,
          uid,
          isSubscription: false,
          name,
          email,
          env,
        });

        if (error || !clientSecret) {
          pushAlert({ message: messages.errorPayment, color: colors.red500 });
          handleError(error);
          return;
        } else {
          const result = await stripe?.confirmPayment({
            // @ts-ignore
            elements,
            clientSecret,
            confirmParams: {
              return_url: CONTRIBUTIONS_RETURN_URL,
            },
            // @ts-ignore
            redirect: "if_required",
          });

          const { paymentIntent, error: paymentError } =
            result as PaymentIntentResult;
          const paymentMethodId = paymentIntent?.payment_method as string;

          if (paymentMethodId) {
            dispatch(
              updateDefaultPaymentMethod({
                methodId: paymentMethodId,
                onSuccess: () => {},
                onError: () => {},
              })
            );
          }

          if (paymentIntent?.status === "succeeded") {
            pushAlert({
              message: messages.successPayment,
              color: colors.emerald500,
            });
            handleSuccess();
            dispatch(
              confirmPaymentAction({
                amount: paymentIntent?.amount,
                paymentIntentId: paymentIntent?.id,
              })
            );
            return;
          } else {
            pushAlert({
              message: messages.errorPaymentConfirmation,
              color: colors.red500,
            });
            handleError(paymentError);
            return;
          }
        }
      } catch (e) {
        pushAlert({ message: messages.errorPayment, color: colors.red500 });
        handleError(e);
        return;
      }
    }, [
      currency,
      dispatch,
      elements,
      env,
      handleError,
      handleSuccess,
      pushAlert,
      stripe,
      uid,
      value,
      name,
      email,
    ]);

    const handleOnChange = React.useCallback(
      ({ complete, value: elementValue }: StripePaymentElementChangeEvent) => {
        setIsComplete(complete);
        setIsCardPayment(elementValue?.type === "card");
      },
      []
    );

    const handleShowModal: any = React.useCallback(
      (switchValue: boolean) => {
        showModal(
          <Section isCenter hasPadding>
            <CloseButton>
              <MaterialIcon onPress={hideModal} name="close" size={20} />
            </CloseButton>
            <Icon type={IconTypes.ChurchBig} size={50} />
            <ConsiderTitle>{messages.considerTitle}</ConsiderTitle>
            <ConsiderText>{messages.considerText}</ConsiderText>
            <SwitchBox>
              <Switch value={switchValue} onValueChange={toggleSwitch} />
              <SwitchText>
                {{
                  ...messages.considerSwitch,
                  values: { amount: considerAmount },
                }}
              </SwitchText>
            </SwitchBox>
          </Section>
        );
      },
      [considerAmount, showModal]
    );

    const toggleSwitch = React.useCallback(
      (isEnabled: boolean) => {
        setIsSwitched(isEnabled);
        setIsMonthly(!isEnabled ? 1 : 0);
        handleShowModal(isEnabled);
      },
      [setIsMonthly, setIsSwitched, handleShowModal]
    );

    const handleDonate = React.useCallback(() => {
      if (!isMonthly && showConsider) {
        handleShowModal(isSwitched);
        setShowConsider(false);
      } else {
        handlePayPress();
        if (name) {
          setFirstName(name.split(" ")[0]);
        }
      }
    }, [
      handlePayPress,
      isMonthly,
      isSwitched,
      handleShowModal,
      showConsider,
      setFirstName,
      name,
    ]);

    return (
      <Container>
        <TopBox>
          <TitleBox>
            <Title>{{ ...titleMessage, values: { amount } }}</Title>
            <EditButton onPress={onBack}>
              <EditText>{messages.edit}</EditText>
            </EditButton>
          </TitleBox>
        </TopBox>

        <Section>
          {!uid && isCardPayment ? (
            <Row>
              <InputBox isFirst>
                <InputLabel>{messages.name}</InputLabel>
                <WebInput
                  value={name}
                  onChangeText={setName}
                  autoComplete="name"
                />
              </InputBox>
              <InputBox>
                <InputLabel>{messages.email}</InputLabel>
                <WebInput
                  value={email}
                  onChangeText={setEmail}
                  autoComplete="email"
                />
              </InputBox>
            </Row>
          ) : null}

          <PaymentElement onChange={handleOnChange} onLoadError={handleError} />
        </Section>

        <Button
          text={{ ...buttonMessage, values: { amount } }}
          onPress={handleDonate}
          isActive={isFormComplete}
          isDisabled={!isFormComplete || isLoading}
          isLoading={isLoading}
        />
        {isMonthly ? (
          <DisclaimerBox>
            <MaterialIcon
              name="credit-card"
              color={theme.colors.gray500}
              size={16}
            />
            <Text>{messages.disclaimer}</Text>
          </DisclaimerBox>
        ) : (
          <Footer />
        )}
      </Container>
    );
  }
);

export const PaymentDetails = React.memo<Props>(
  ({ value, isMonthly, setIsMonthly, onBack, onSuccess, setFirstName }) => {
    const currency = useAppSelector(getPaymentCurrency);

    let stripePromise = null;
    // Avoid ecreating the Stripe object on every render.
    if (!stripePromise) {
      stripePromise = loadStripe(
        getStripeProps(store.getState()).publishableKey
      );
    }

    const options = {
      mode: "payment" as OptionsMode,
      amount: value * 100,
      currency,
      paymentMethodTypes: ["card"],
      appearance: {
        variables: {
          fontFamily: "SFPro, system-ui, sans-serif",
          colorText: colors.gray600,
          borderRadius: spacers.ss3,
        },
      },
    };

    React.useEffect(() => {
      if (!value) {
        onBack();
      }
    }, [value, onBack]);

    if (!value || !stripePromise) {
      return null;
    }

    return (
      <Elements stripe={stripePromise} options={options}>
        <PaymentDetailsComponent
          value={value}
          isMonthly={isMonthly}
          setIsMonthly={setIsMonthly}
          onBack={onBack}
          onSuccess={onSuccess}
          setFirstName={setFirstName}
        />
      </Elements>
    );
  }
);
