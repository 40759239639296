import { EmojiSection } from "./types";

export const emojis: EmojiSection[] = [
  {
    title: "smileys",
    data: [
      {
        keywords: [
          "happy",
          "smile",
          "smiley",
          "smiley face",
          "so happy",
          "that’s great",
          "so great",
          "yay",
          "hooray",
          "hurrah",
          "cheerful",
          "delighted",
          "joyful",
          "pleased",
          "grinning face",
          "face",
          "joy",
          ":d",
          "grin",
          "grinning",
        ],
        name: "grinning face",
        order: 1,
        unified: "1F600",
        emoji: "😀",
      },
      {
        keywords: [
          "happy",
          "smile",
          "smiley",
          "smiley face",
          "so happy",
          "that’s great",
          "so great",
          "yay",
          "hooray",
          "hurrah",
          "hoorah",
          "cheerful",
          "delighted",
          "joyful",
          "pleased",
          "grinning face with big eyes",
          "face",
          "joy",
          "haha",
          ":d",
          ":)",
          "funny",
          "smiling face with open mouth",
        ],
        name: "smiling face with open mouth",
        order: 2,
        unified: "1F603",
        emoji: "😃",
      },
      {
        keywords: [
          "happy",
          "smiley face",
          "too funny",
          "funny",
          "so happy",
          "that’s great",
          "so great",
          "yay",
          "hooray",
          "hurrah",
          "hoorah",
          "thrilled",
          "in a good mood",
          "cheerful",
          "delighted",
          "joyful",
          "overjoyed",
          "pleased",
          "squinting smiley",
          "grinning face with smiling eyes",
          "face",
          "joy",
          "haha",
          "laugh",
          "like",
          ":d",
          ":)",
          "smile",
          "smiling face with open mouth and smiling eyes",
        ],
        name: "smiling face with open mouth and smiling eyes",
        order: 3,
        unified: "1F604",
        emoji: "😄",
      },
      {
        keywords: [
          "braces",
          "happy",
          "smiley face",
          "too funny",
          "funny",
          "so happy",
          "that’s great",
          "so great",
          "yay",
          "hooray",
          "hurrah",
          "hoorah",
          "thrilled",
          "in a good mood",
          "cheerful",
          "delighted",
          "joyful",
          "overjoyed",
          "pleased",
          "squinting smiley",
          "beaming face with smiling eyes",
          "face",
          "smile",
          "joy",
          "kawaii",
          "grin",
          "grinning face with smiling eyes",
        ],
        name: "grinning face with smiling eyes",
        order: 4,
        unified: "1F601",
        emoji: "😁",
      },
      {
        keywords: [
          "happy",
          "lol",
          "so funny",
          "too funny",
          "laughing",
          "lmao",
          "lolz",
          "lols",
          "lulz",
          "rotfl",
          "rotflmao",
          "excited",
          "cracking up",
          "cracked up",
          "crack me up",
          "hilarious",
          "funny",
          "funniest",
          "laugh",
          "so excited",
          "haha",
          "yay",
          "hooray",
          "smiley",
          "smiley face",
          "grinning squinting face",
          "joy",
          "satisfied",
          "face",
          "glad",
          "xd",
          "smiling face with open mouth and tightly closed eyes",
        ],
        name: "smiling face with open mouth and tightly-closed eyes",
        order: 5,
        unified: "1F606",
        emoji: "😆",
      },
      {
        keywords: [
          "sweating",
          "phew",
          "relieved",
          "thank goodness",
          "thankfully",
          "what a relief",
          "that’s a relief",
          "grinning face with sweat",
          "face",
          "hot",
          "happy",
          "laugh",
          "sweat",
          "smile",
          "relief",
          "sweat smile",
          "smiling face with open mouth and cold sweat",
        ],
        name: "smiling face with open mouth and cold sweat",
        order: 6,
        unified: "1F605",
        emoji: "😅",
      },
      {
        keywords: [
          "rofl",
          "laughing face",
          "rolling on the floor laughing face",
          "happy crying",
          "happy tears",
          "so funny",
          "too funny",
          "hilarious",
          "lol",
          "cracking up",
          "cracked up",
          "crack me up",
          "dying laughing",
          "tears of joy",
          "tears of happiness",
          "funny",
          "funniest",
          "laughing",
          "haha",
          "hehe",
          "crying smiley",
          "lmao",
          "lolz",
          "lols",
          "lulz",
          "rotfl",
          "rotflmao",
          "roflmao",
          "rolling on the floor laughing",
          "face",
          "rolling",
          "floor",
        ],
        name: "rolling on the floor laughing",
        order: 7,
        unified: "1F923",
        emoji: "🤣",
      },
      {
        keywords: [
          "so funny",
          "too funny",
          "hilarious",
          "lol",
          "cracking up",
          "cracked up",
          "crack me up",
          "dying laughing",
          "tears of joy",
          "tears of happiness",
          "funny",
          "funniest",
          "laugh",
          "haha",
          "hehe",
          "crying smiley",
          "smiley face",
          "laughing",
          "lmao",
          "lolz",
          "lols",
          "lulz",
          "rotfl",
          "rotflmao",
          "face with tears of joy",
          "face",
          "cry",
          "tears",
          "weep",
          "happy",
          "happytears",
          "joy",
        ],
        name: "face with tears of joy",
        order: 8,
        unified: "1F602",
        emoji: "😂",
      },
      {
        keywords: [
          "happy",
          "smiley face",
          "smiley emoji",
          "that’s nice",
          "in a good mood",
          "i’m ok",
          "i’m fine",
          "slightly smiling face",
          "face",
          "smile",
        ],
        name: "slightly smiling face",
        order: 9,
        unified: "1F642",
        emoji: "🙂",
      },
      {
        keywords: [
          "silly",
          "happy",
          "upside down happy face",
          "silly face",
          "happy face",
          "upside down face",
          "joking",
          "goofy",
          "hehe",
          "face",
          "flipped",
          "smile",
        ],
        name: "upside-down face",
        order: 10,
        unified: "1F643",
        emoji: "🙃",
      },
      {
        keywords: [
          "wink",
          "winking",
          "winking smiley",
          "winking emoji",
          "hehe",
          "heehee",
          "i got you",
          "don’t worry",
          "no worries",
          "you’re welcome",
          "you’re very welcome",
          "good luck",
          "amazing",
          "enjoy",
          "winking face",
          "face",
          "happy",
          "mischievous",
          "secret",
          ";)",
          "smile",
          "eye",
        ],
        name: "winking face",
        order: 11,
        unified: "1F609",
        emoji: "😉",
      },
      {
        keywords: [
          "happy",
          "blushing",
          "blushing emoji",
          "blushing smiley",
          "so good",
          "you’re welcome",
          "you’re very welcome",
          "thank you",
          "thank you so much",
          "that’s nice",
          "lovely",
          "excited",
          "yay",
          "can’t wait",
          "love it",
          "enjoy",
          "smile",
          "smiley",
          "smiley face",
          "smiling face with smiling eyes",
          "face",
          "flushed",
          "crush",
          "embarrassed",
          "shy",
          "joy",
          "blush",
        ],
        name: "smiling face with smiling eyes",
        order: 12,
        unified: "1F60A",
        emoji: "😊",
      },
      {
        keywords: [
          "angel",
          "halo",
          "innocent",
          "halo smiley",
          "you’re welcome",
          "my pleasure",
          "it wasn’t me",
          "halo emoji",
          "smiling face with halo",
          "face",
          "heaven",
        ],
        name: "smiling face with halo",
        order: 13,
        unified: "1F607",
        emoji: "😇",
      },
      {
        keywords: [
          "smiling face with hearts",
          "face",
          "love",
          "like",
          "affection",
          "valentines",
          "infatuation",
          "crush",
          "hearts",
          "adore",
          "smiling face with 3 hearts",
          "smiling face with smiling eyes and three hearts",
        ],
        name: "smiling face with smiling eyes and three hearts",
        order: 14,
        unified: "1F970",
        emoji: "🥰",
      },
      {
        keywords: [
          "love it",
          "in love",
          "love smiley",
          "i love you",
          "i love them",
          "i love him",
          "i love her",
          "in love with",
          "it’s beautiful",
          "you’re beautiful",
          "so beautiful",
          "my love",
          "crushing on",
          "i’m crushing",
          "love you",
          "smiling face with heart eyes",
          "face",
          "love",
          "like",
          "affection",
          "valentines",
          "infatuation",
          "crush",
          "heart",
          "heart eyes",
          "smiling face with heart shaped eyes",
        ],
        name: "smiling face with heart-shaped eyes",
        order: 15,
        unified: "1F60D",
        emoji: "😍",
      },
      {
        keywords: [
          "starry eyed",
          "star eyes",
          "star struck",
          "starry eyes",
          "stars",
          "face",
          "smile",
          "starry",
          "eyes",
          "grinning",
          "grinning face with star eyes",
        ],
        name: "grinning face with star eyes",
        order: 16,
        unified: "1F929",
        emoji: "🤩",
      },
      {
        keywords: [
          "kiss",
          "blow a kiss",
          "kissy face",
          "kiss face",
          "kissing smiley",
          "kissing emoji",
          "kisses",
          "xo",
          "xx",
          "xoxo",
          "kiss you",
          "love you",
          "i love you",
          "missing you",
          "miss you",
          "smooch",
          "face blowing a kiss",
          "face",
          "love",
          "like",
          "affection",
          "valentines",
          "infatuation",
          "kissing heart",
          "face throwing a kiss",
        ],
        name: "face throwing a kiss",
        order: 17,
        unified: "1F618",
        emoji: "😘",
      },
      {
        keywords: [
          "whistling",
          "kissing face",
          "kissy face",
          "kiss face",
          "duck face",
          "love",
          "like",
          "face",
          "3",
          "valentines",
          "infatuation",
          "kiss",
          "kissing",
        ],
        name: "kissing face",
        order: 18,
        unified: "1F617",
        emoji: "😗",
      },
      {
        keywords: [
          "you’re welcome",
          "you’re very welcome",
          "thank you",
          "thank you so much",
          "that’s nice",
          "lucky me",
          "i’m lucky",
          "blushing",
          "shy",
          "smiling face",
          "face",
          "blush",
          "massage",
          "happiness",
          "relaxed",
          "white smiling face",
        ],
        name: "white smiling face",
        order: 19,
        unified: "263A-FE0F",
        emoji: "☺️",
      },
      {
        keywords: [
          "kiss",
          "kiss face",
          "kissy face",
          "smooch",
          "kisses",
          "smooches",
          "xo",
          "xx",
          "xoxo",
          "kiss you",
          "kissing face",
          "duck face",
          "kissing face with closed eyes",
          "face",
          "love",
          "like",
          "affection",
          "valentines",
          "infatuation",
          "kissing closed eyes",
        ],
        name: "kissing face with closed eyes",
        order: 20,
        unified: "1F61A",
        emoji: "😚",
      },
      {
        keywords: [
          "kiss",
          "kissing face",
          "whistling",
          "kissy face",
          "kiss face",
          "duck face",
          "kissing face with smiling eyes",
          "face",
          "affection",
          "valentines",
          "infatuation",
          "kissing smiling eyes",
        ],
        name: "kissing face with smiling eyes",
        order: 21,
        unified: "1F619",
        emoji: "😙",
      },
      {
        keywords: ["smiling face with tear", "sad", "cry", "pretend"],
        name: "smiling face with tear",
        order: 22,
        unified: "1F972",
        emoji: "🥲",
      },
      {
        keywords: [
          "delicious",
          "yummy",
          "nom nom",
          "nomnom",
          "tasty",
          "yum",
          "goofy",
          "hungry",
          "licking lips",
          "licking my lips",
          "tongue smiley",
          "face savoring food",
          "happy",
          "joy",
          "tongue",
          "smile",
          "face",
          "silly",
          "nom",
          "savouring",
          "face savouring delicious food",
        ],
        name: "face savouring delicious food",
        order: 23,
        unified: "1F60B",
        emoji: "😋",
      },
      {
        keywords: [
          "silly",
          "tongue",
          "sticking my tongue out",
          "tongue out emoji",
          "tongue out face",
          "tongue out smiley",
          "face with tongue",
          "face",
          "prank",
          "childish",
          "playful",
          "mischievous",
          "smile",
          "stuck out tongue",
          "face with stuck out tongue",
        ],
        name: "face with stuck-out tongue",
        order: 24,
        unified: "1F61B",
        emoji: "😛",
      },
      {
        keywords: [
          "crazy",
          "silly",
          "hehe",
          "hee hee",
          "teehee",
          "cray",
          "wild",
          "excited",
          "loco",
          "sticking my tongue out",
          "tongue out emoji",
          "tongue out face",
          "tongue out smiley",
          "winking face with tongue",
          "face",
          "prank",
          "childish",
          "playful",
          "mischievous",
          "smile",
          "wink",
          "tongue",
          "stuck out tongue winking eye",
          "face with stuck out tongue and winking eye",
        ],
        name: "face with stuck-out tongue and winking eye",
        order: 25,
        unified: "1F61C",
        emoji: "😜",
      },
      {
        keywords: [
          "goofy face",
          "zany face",
          "silly face",
          "funny face",
          "face",
          "goofy",
          "crazy",
          "grinning face with one large and one small eye",
        ],
        name: "grinning face with one large and one small eye",
        order: 26,
        unified: "1F92A",
        emoji: "🤪",
      },
      {
        keywords: [
          "crazy",
          "lol",
          "laughing",
          "lolz",
          "lols",
          "lulz",
          "rotfl",
          "rotflmao",
          "excited",
          "silly",
          "loco",
          "sticking my tongue out",
          "tongue out emoji",
          "tongue out face",
          "tongue out smiley",
          "squinting face with tongue",
          "face",
          "prank",
          "playful",
          "mischievous",
          "smile",
          "tongue",
          "stuck out tongue closed eyes",
          "face with stuck out tongue and tightly closed eyes",
        ],
        name: "face with stuck-out tongue and tightly-closed eyes",
        order: 27,
        unified: "1F61D",
        emoji: "😝",
      },
      {
        keywords: [
          "money",
          "money face",
          "money emoji",
          "money smiley",
          "filthy rich",
          "ballin",
          "skrilla",
          "cash rollin",
          "skrillz",
          "cash money",
          "seeing dollar signs",
          "rich emoji",
          "rich people",
          "baller",
          "money mouth face",
          "face",
          "rich",
          "dollar",
        ],
        name: "money-mouth face",
        order: 28,
        unified: "1F911",
        emoji: "🤑",
      },
      {
        keywords: [
          "hug",
          "my bad",
          "hugs",
          "tickles",
          "hugging",
          "hugging face",
          "hug face",
          "huggy",
          "touchy feely",
          "affectionate",
          "jazz hands",
          "face",
          "smile",
        ],
        name: "hugging face",
        order: 29,
        unified: "1F917",
        emoji: "🤗",
      },
      {
        keywords: [
          "hand over mouth",
          "laughing",
          "giggling",
          "laugh",
          "giggle",
          "face with hand over mouth",
          "face",
          "whoops",
          "shock",
          "surprise",
          "smiling face with smiling eyes and hand covering mouth",
        ],
        name: "smiling face with smiling eyes and hand covering mouth",
        order: 30,
        unified: "1F92D",
        emoji: "🤭",
      },
      {
        keywords: [
          "shush",
          "hush",
          "shushing",
          "hushing",
          "quiet",
          "shhh",
          "shushing face",
          "face",
          "face with finger covering closed lips",
        ],
        name: "face with finger covering closed lips",
        order: 31,
        unified: "1F92B",
        emoji: "🤫",
      },
      {
        keywords: [
          "hmm",
          "thinking",
          "think",
          "let me think",
          "i don’t know",
          "not sure",
          "pensive",
          "i’ll get back to you",
          "let me get back to you",
          "i’ll let you know",
          "perplexed",
          "pondering",
          "ponder",
          "stumped",
          "contemplative",
          "contemplate",
          "speculating",
          "evaluating",
          "puzzling",
          "thinking face",
          "face",
          "hmmm",
          "consider",
        ],
        name: "thinking face",
        order: 32,
        unified: "1F914",
        emoji: "🤔",
      },
      {
        keywords: [
          "shut up",
          "zip it",
          "be quiet",
          "shut your mouth",
          "lips are sealed",
          "mouth shut",
          "lips sealed",
          "mute",
          "zipper mouth face",
          "face",
          "sealed",
          "zipper",
          "secret",
        ],
        name: "zipper-mouth face",
        order: 33,
        unified: "1F910",
        emoji: "🤐",
      },
      {
        keywords: [
          "face with raised eyebrow",
          "raised eyebrow",
          "skeptic",
          "skeptical",
          "colbert",
          "quizzical",
          "people’s eyebrow",
          "face",
          "distrust",
          "scepticism",
          "disapproval",
          "disbelief",
          "surprise",
          "face with one eyebrow raised",
        ],
        name: "face with one eyebrow raised",
        order: 34,
        unified: "1F928",
        emoji: "🤨",
      },
      {
        keywords: [
          "um",
          "straight face",
          "blank face",
          "umm",
          "blanked",
          "idk",
          "unamused",
          "seriously",
          "sorry",
          "unsure",
          "confused",
          "blankly",
          "apathetic",
          "bored",
          "unenthused",
          "unenthusiastic",
          "indifferent",
          "conflicted",
          "unnerved",
          "vacant expression",
          "blank expression",
          "no emotion",
          "emotionless",
          "neutral face",
          "indifference",
          "meh",
          ":|",
          "neutral",
        ],
        name: "neutral face",
        order: 35,
        unified: "1F610",
        emoji: "😐",
      },
      {
        keywords: [
          "bored",
          "annoyed",
          "ugh",
          "tired",
          "sleepy",
          "resigned",
          "not feeling it",
          "unamused",
          "seriously",
          "straight face",
          "blank face",
          "blankly",
          "apathetic",
          "unenthused",
          "unenthusiastic",
          "indifferent",
          "expressionless",
          "hmph",
          "expressionless face",
          "face",
          "   ",
          "meh",
          "deadpan",
        ],
        name: "expressionless face",
        order: 36,
        unified: "1F611",
        emoji: "😑",
      },
      {
        keywords: [
          "speechless",
          "no words",
          "whut",
          "stunned",
          "flummoxed",
          "gobsmacked",
          "blankly",
          "vacant expression",
          "blank expression",
          "face with no mouth",
          "face without mouth",
          "face",
          "hellokitty",
          "no mouth",
        ],
        name: "face without mouth",
        order: 37,
        unified: "1F636",
        emoji: "😶",
      },
      {
        keywords: [
          "smirk",
          "smirking",
          "mischievous",
          "heheh",
          "smirking face",
          "face",
          "smile",
          "mean",
          "prank",
          "smug",
          "sarcasm",
        ],
        name: "smirking face",
        order: 38,
        unified: "1F60F",
        emoji: "😏",
      },
      {
        keywords: [
          "not amused",
          "lame",
          "boo",
          "unamused",
          "seriously",
          "bad mood",
          "in a funk",
          "annoyed",
          "unenthused",
          "unenthusiastic",
          "suspicious",
          "wary",
          "unnerved",
          "unamused face",
          "indifference",
          "bored",
          "straight face",
          "serious",
          "sarcasm",
          "unimpressed",
          "skeptical",
          "dubious",
          "side eye",
        ],
        name: "unamused face",
        order: 39,
        unified: "1F612",
        emoji: "😒",
      },
      {
        keywords: [
          "duh",
          "uhh",
          "rolling eyes",
          "eye roll",
          "rolling eye face",
          "face with rolling eyes",
          "oh please",
          "donk",
          "obviously",
          "face",
          "eyeroll",
          "frustrated",
        ],
        name: "face with rolling eyes",
        order: 40,
        unified: "1F644",
        emoji: "🙄",
      },
      {
        keywords: [
          "braces",
          "tense",
          "nervous",
          "oops",
          "eek",
          "gnashing teeth",
          "jittery",
          "whoops",
          "restless",
          "uneasy",
          "apprehensive",
          "fidgety",
          "agitated",
          "unnerved",
          "grimacing face",
          "face",
          "grimace",
          "teeth",
          "grimacing",
        ],
        name: "grimacing face",
        order: 41,
        unified: "1F62C",
        emoji: "😬",
      },
      {
        keywords: [
          "lying",
          "pinocchio",
          "lied",
          "liar",
          "long nose face",
          "long nose",
          "lying face",
          "face",
          "lie",
        ],
        name: "lying face",
        order: 42,
        unified: "1F925",
        emoji: "🤥",
      },
      {
        keywords: [
          "relieved",
          "sounds good",
          "relaxed",
          "relaxing",
          "happy",
          "relieved face",
          "face",
          "phew",
          "massage",
          "happiness",
        ],
        name: "relieved face",
        order: 43,
        unified: "1F60C",
        emoji: "😌",
      },
      {
        keywords: [
          "sad",
          "hurt",
          "sad emoji",
          "sad face",
          "sullen",
          "oh well",
          "sigh",
          "disappointed",
          "lonely",
          "feel bad",
          "downcast",
          "long face",
          "somber",
          "solemn",
          "melancholy",
          "dejected",
          "glum",
          "gloomy",
          "heartbroken",
          "dreary",
          "unhappy",
          "sorrowful",
          "bummed out",
          "long faced",
          "pensive face",
          "face",
          "depressed",
          "upset",
          "pensive",
        ],
        name: "pensive face",
        order: 44,
        unified: "1F614",
        emoji: "😔",
      },
      {
        keywords: ["sleepy face", "face", "tired", "rest", "nap", "sleepy"],
        name: "sleepy face",
        order: 45,
        unified: "1F62A",
        emoji: "😪",
      },
      {
        keywords: [
          "delicious",
          "yummy",
          "nom nom",
          "nomnom",
          "drool",
          "drooling",
          "sleepy",
          "drooling face",
          "face",
        ],
        name: "drooling face",
        order: 46,
        unified: "1F924",
        emoji: "🤤",
      },
      {
        keywords: [
          "sleepy",
          "sleeping",
          "tired",
          "napping",
          "sleepy face",
          "sleeping face",
          "nap",
          "sleep",
          "snoring",
          "nap time",
          "asleep",
          "exhausted",
          "zzz",
          "zs",
          "face",
          "night",
        ],
        name: "sleeping face",
        order: 47,
        unified: "1F634",
        emoji: "😴",
      },
      {
        keywords: [
          "sick",
          "mask",
          "flu",
          "stinks",
          "nasty",
          "sars",
          "epidemic",
          "epidemics",
          "ill",
          "contagious",
          "contagion",
          "outbreak",
          "outbreaks",
          "illin",
          "face mask",
          "sick mask",
          "medical mask",
          "face with medical mask",
          "surgeon",
          "eww",
          "bad breath",
          "face",
          "disease",
        ],
        name: "face with medical mask",
        order: 48,
        unified: "1F637",
        emoji: "😷",
      },
      {
        keywords: [
          "sick",
          "thermometer",
          "fever",
          "temperature",
          "flu",
          "high fever",
          "high temperature",
          "ill",
          "illin",
          "face with thermometer",
          "unwell",
          "not well",
          "cold",
        ],
        name: "face with thermometer",
        order: 49,
        unified: "1F912",
        emoji: "🤒",
      },
      {
        keywords: [
          "sick",
          "headache",
          "bandage",
          "bumped my head",
          "bumped your head",
          "bumped his head",
          "bumped her head",
          "hurt",
          "beat up",
          "face with bandage",
          "face with head bandage",
          "injured",
          "clumsy",
        ],
        name: "face with head-bandage",
        order: 50,
        unified: "1F915",
        emoji: "🤕",
      },
      {
        keywords: [
          "green face",
          "sick face",
          "nauseated face",
          "sick",
          "nauseated",
          "puke",
          "puking",
          "pukes",
          "puked",
          "vomit",
          "feeling sick",
          "going to be sick",
          "throw up",
          "barf",
          "barfing",
          "poisoned",
          "yuck",
          "nasty",
          "face",
          "gross",
          "green",
          "ill",
        ],
        name: "nauseated face",
        order: 51,
        unified: "1F922",
        emoji: "🤢",
      },
      {
        keywords: [
          "vomit",
          "vomiting",
          "vomited",
          "vomits",
          "puke",
          "puking",
          "puked",
          "pukes",
          "sick",
          "upchuck",
          "barf",
          "barfed",
          "barfing",
          "barfs",
          "face vomiting",
          "face",
          "face with open mouth vomiting",
        ],
        name: "face with open mouth vomiting",
        order: 52,
        unified: "1F92E",
        emoji: "🤮",
      },
      {
        keywords: [
          "sick",
          "flu",
          "ill",
          "face with tissue",
          "unwell",
          "not well",
          "face with kleenex",
          "face with handkerchief",
          "face with a cold",
          "blowing nose",
          "sneeze",
          "sneezing",
          "sneezed",
          "gesundheit",
          "salud",
          "have a cold",
          "sneezing face",
          "face",
          "allergy",
        ],
        name: "sneezing face",
        order: 53,
        unified: "1F927",
        emoji: "🤧",
      },
      {
        keywords: [
          "spicy",
          "pepper",
          "hot",
          "sweaty",
          "hot face",
          "face",
          "feverish",
          "heat",
          "red",
          "sweating",
          "overheated face",
        ],
        name: "overheated face",
        order: 54,
        unified: "1F975",
        emoji: "🥵",
      },
      {
        keywords: [
          "cold face",
          "face",
          "blue",
          "freezing",
          "frozen",
          "frostbite",
          "icicles",
          "freezing face",
        ],
        name: "freezing face",
        order: 55,
        unified: "1F976",
        emoji: "🥶",
      },
      {
        keywords: [
          "woozy face",
          "face",
          "dizzy",
          "intoxicated",
          "tipsy",
          "wavy",
          "face with uneven eyes and wavy mouth",
        ],
        name: "face with uneven eyes and wavy mouth",
        order: 56,
        unified: "1F974",
        emoji: "🥴",
      },
      {
        keywords: [
          "dizzy",
          "dizzy face",
          "cross eyes",
          "cross eyed",
          "passed out",
          "i’m dying",
          "i’m dead",
          "drunk",
          "intoxicated",
          "hungover",
          "hung over",
          "x eyes",
          "x eyed",
          "spent",
          "unconscious",
          "xox",
        ],
        name: "dizzy face",
        order: 57,
        unified: "1F635",
        emoji: "😵",
      },
      {
        keywords: [
          "exploding head",
          "mind blown",
          "head exploding",
          "shocked",
          "face",
          "mind",
          "blown",
          "shocked face with exploding head",
        ],
        name: "shocked face with exploding head",
        order: 58,
        unified: "1F92F",
        emoji: "🤯",
      },
      {
        keywords: [
          "cowboy",
          "cowboy hat",
          "face with hat",
          "face with cowboy hat",
          "cowboy hat face",
          "face",
          "cowgirl",
          "hat",
        ],
        name: "face with cowboy hat",
        order: 59,
        unified: "1F920",
        emoji: "🤠",
      },
      {
        keywords: [
          "partying face",
          "face",
          "celebration",
          "woohoo",
          "face with party horn and party hat",
        ],
        name: "face with party horn and party hat",
        order: 60,
        unified: "1F973",
        emoji: "🥳",
      },
      {
        keywords: [
          "disguised face",
          "pretent",
          "brows",
          "glasses",
          "moustache",
        ],
        name: "disguised face",
        order: 61,
        unified: "1F978",
        emoji: "🥸",
      },
      {
        keywords: [
          "sunglasses",
          "cool",
          "sunglasses on",
          "smiling face with sunglasses",
          "sunglasses emoji",
          "sunglasses smiley",
          "stunner shades",
          "sunny",
          "cool kid",
          "ballin",
          "chillin",
          "cool guy",
          "cool dude",
          "cool chick",
          "cool girl",
          "baller",
          "awesome",
          "relaxing",
          "relaxed",
          "no stress",
          "face",
          "smile",
          "summer",
          "beach",
          "sunglass",
        ],
        name: "smiling face with sunglasses",
        order: 62,
        unified: "1F60E",
        emoji: "😎",
      },
      {
        keywords: [
          "nerd",
          "geek",
          "nerd face",
          "nerdy",
          "geeky",
          "book smart",
          "wearing glasses",
          "wear glasses",
          "take glasses",
          "four eyes",
          "intelligent",
          "professor",
          "teacher’s pet",
          "face",
          "dork",
        ],
        name: "nerd face",
        order: 63,
        unified: "1F913",
        emoji: "🤓",
      },
      {
        keywords: ["monocle", "face with monocle", "face", "stuffy", "wealthy"],
        name: "face with monocle",
        order: 64,
        unified: "1F9D0",
        emoji: "🧐",
      },
      {
        keywords: [
          "confused",
          "bothered",
          "perplexed",
          "defeated",
          "indifferent",
          "puzzled",
          "disorganized",
          "discombobulated",
          "baffled",
          "bewildered",
          "hesitant",
          "unnerved",
          "confused face",
          "face",
          "indifference",
          "huh",
          "weird",
          "hmmm",
          ":/",
        ],
        name: "confused face",
        order: 65,
        unified: "1F615",
        emoji: "😕",
      },
      {
        keywords: [
          "worried",
          "anxious",
          "afraid",
          "concerned",
          "scared",
          "uneasy",
          "nervous",
          "scare me",
          "scares me",
          "insecure",
          "troubled",
          "distressed",
          "dreading",
          "hesitant",
          "worried face",
          "face",
          "concern",
          ":(",
        ],
        name: "worried face",
        order: 66,
        unified: "1F61F",
        emoji: "😟",
      },
      {
        keywords: [
          "sad",
          "bummed",
          "hurt",
          "frown face",
          "frowny face",
          "sad emoji",
          "sad face",
          "disappointed",
          "lonely",
          "feel bad",
          "downcast",
          "long face",
          "somber",
          "solemn",
          "melancholy",
          "dejected",
          "glum",
          "gloomy",
          "dreary",
          "unhappy",
          "sorrowful",
          "bummed out",
          "slightly frowning face",
          "face",
          "frowning",
          "upset",
        ],
        name: "slightly frowning face",
        order: 67,
        unified: "1F641",
        emoji: "🙁",
      },
      {
        keywords: [
          "sad",
          "bummed",
          "hurt",
          "frown face",
          "frowny face",
          "sad emoji",
          "sad face",
          "disappointed",
          "lonely",
          "feel bad",
          "downcast",
          "long face",
          "somber",
          "solemn",
          "melancholy",
          "dejected",
          "glum",
          "gloomy",
          "dreary",
          "unhappy",
          "sorrowful",
          "miserable",
          "bummed out",
          "frowning face",
          "face",
          "upset",
          "frown",
          "white frowning face",
        ],
        name: "frowning face",
        order: 68,
        unified: "2639-FE0F",
        emoji: "☹️",
      },
      {
        keywords: [
          "shocked",
          "omg",
          "wow",
          "ohh",
          "ohhh",
          "whoa",
          "whut",
          "astonished",
          "baffled",
          "surprised",
          "appalled",
          "astounded",
          "flabbergasted",
          "startled",
          "taken aback",
          "ooh",
          "oooh",
          "face with open mouth",
          "face",
          "surprise",
          "impressed",
          ":o",
          "open mouth",
        ],
        name: "face with open mouth",
        order: 69,
        unified: "1F62E",
        emoji: "😮",
      },
      {
        keywords: [
          "shocked",
          "surprised",
          "omg",
          "wow",
          "ohh",
          "ohhh",
          "whoa",
          "whut",
          "baffled",
          "astonished",
          "appalled",
          "astounded",
          "flabbergasted",
          "startled",
          "taken aback",
          "ooh",
          "oooh",
          "hushed face",
          "face",
          "woo",
          "shh",
          "hushed",
        ],
        name: "hushed face",
        order: 70,
        unified: "1F62F",
        emoji: "😯",
      },
      {
        keywords: [
          "astonished",
          "shocked",
          "surprised",
          "omg",
          "wow",
          "ohh",
          "ohhh",
          "whoa",
          "whut",
          "baffled",
          "appalled",
          "astounded",
          "flabbergasted",
          "startled",
          "taken aback",
          "ooh",
          "oooh",
          "astonished face",
          "face",
          "xox",
          "poisoned",
        ],
        name: "astonished face",
        order: 71,
        unified: "1F632",
        emoji: "😲",
      },
      {
        keywords: [
          "surprised",
          "worried",
          "shocked",
          "embarrassed",
          "wow",
          "seriously",
          "for real",
          "alarmed",
          "concerned",
          "so wired",
          "scared",
          "spooked",
          "afraid",
          "uneasy",
          "disturbed",
          "scare me",
          "scares me",
          "bewildered",
          "stunned",
          "astonished",
          "appalled",
          "astounded",
          "flushed face",
          "face",
          "blush",
          "shy",
          "flattered",
          "flushed",
        ],
        name: "flushed face",
        order: 72,
        unified: "1F633",
        emoji: "😳",
      },
      {
        keywords: [
          "pleading face",
          "face",
          "begging",
          "mercy",
          "face with pleading eyes",
        ],
        name: "face with pleading eyes",
        order: 73,
        unified: "1F97A",
        emoji: "🥺",
      },
      {
        keywords: [
          "worried",
          "stunned",
          "anxious",
          "afraid",
          "concerned",
          "scared",
          "uneasy",
          "disturbed",
          "astonished",
          "appalled",
          "alarmed",
          "spooked",
          "frowning face with open mouth",
          "face",
          "aw",
          "what",
          "frowning",
        ],
        name: "frowning face with open mouth",
        order: 74,
        unified: "1F626",
        emoji: "😦",
      },
      {
        keywords: [
          "worried",
          "stunned",
          "anxious",
          "afraid",
          "concerned",
          "scared",
          "uneasy",
          "disturbed",
          "astonished",
          "appalled",
          "alarmed",
          "spooked",
          "anguished",
          "fearful",
          "anguished face",
          "face",
          "nervous",
        ],
        name: "anguished face",
        order: 75,
        unified: "1F627",
        emoji: "😧",
      },
      {
        keywords: [
          "fearful face",
          "face",
          "scared",
          "terrified",
          "nervous",
          "oops",
          "huh",
          "fearful",
        ],
        name: "fearful face",
        order: 76,
        unified: "1F628",
        emoji: "😨",
      },
      {
        keywords: [
          "anxious face with sweat",
          "face",
          "nervous",
          "sweat",
          "cold sweat",
          "face with open mouth and cold sweat",
        ],
        name: "face with open mouth and cold sweat",
        order: 77,
        unified: "1F630",
        emoji: "😰",
      },
      {
        keywords: [
          "sad but relieved face",
          "face",
          "phew",
          "sweat",
          "nervous",
          "disappointed relieved",
          "disappointed but relieved face",
        ],
        name: "disappointed but relieved face",
        order: 78,
        unified: "1F625",
        emoji: "😥",
      },
      {
        keywords: [
          "sad",
          "hurt",
          "cry",
          "tearful",
          "sad emoji",
          "sad face",
          "single tear",
          "disappointed",
          "lonely",
          "feel bad",
          "crying",
          "upset",
          "crying face",
          "face",
          "tears",
          "depressed",
          ":'(",
        ],
        name: "crying face",
        order: 79,
        unified: "1F622",
        emoji: "😢",
      },
      {
        keywords: [
          "tears",
          "sad",
          "hurt",
          "cry",
          "sad emoji",
          "sad face",
          "butt hurt",
          "cry baby",
          "cry babies",
          "upset",
          "lonely",
          "emotional",
          "so sad",
          "so upset",
          "so lonely",
          "so hurt",
          "despondent",
          "heartbroken",
          "sobbing",
          "wailing",
          "crying",
          "miserable",
          "bawling",
          "hysterical",
          "fussy",
          "distraught",
          "loudly crying face",
          "face",
          "depressed",
          "sob",
        ],
        name: "loudly crying face",
        order: 80,
        unified: "1F62D",
        emoji: "😭",
      },
      {
        keywords: [
          "surprised",
          "worried",
          "scared",
          "concerned",
          "spooked",
          "afraid",
          "oh no",
          "alarmed",
          "panicky",
          "startled",
          "scares me",
          "i’m shocked",
          "face screaming in fear",
          "face",
          "munch",
          "omg",
          "scream",
        ],
        name: "face screaming in fear",
        order: 81,
        unified: "1F631",
        emoji: "😱",
      },
      {
        keywords: [
          "tortured",
          "painful",
          "excruciating",
          "ache",
          "achy",
          "achey",
          "miserable",
          "confounded face",
          "face",
          "confused",
          "sick",
          "unwell",
          "oops",
          ":s",
          "confounded",
        ],
        name: "confounded face",
        order: 82,
        unified: "1F616",
        emoji: "😖",
      },
      {
        keywords: [
          "painful",
          "uncomfortable",
          "agitated",
          "irritated",
          "persevering face",
          "face",
          "sick",
          "no",
          "upset",
          "oops",
          "persevere",
        ],
        name: "persevering face",
        order: 83,
        unified: "1F623",
        emoji: "😣",
      },
      {
        keywords: [
          "sad",
          "hurt",
          "sad emoji",
          "sad face",
          "disappointed",
          "so sad",
          "embarrassed",
          "frown face",
          "frowny face",
          "lonely",
          "feel bad",
          "bummed out",
          "downcast",
          "long face",
          "dejected",
          "gloomy",
          "heartbroken",
          "dreary",
          "long faced",
          "unhappy",
          "sorrowful",
          "disappointed face",
          "face",
          "upset",
          "depressed",
          ":(",
        ],
        name: "disappointed face",
        order: 84,
        unified: "1F61E",
        emoji: "😞",
      },
      {
        keywords: [
          "sweat",
          "tired",
          "sweating",
          "hard work",
          "work hard",
          "working hard",
          "downcast face with sweat",
          "face",
          "hot",
          "sad",
          "exercise",
          "face with cold sweat",
        ],
        name: "face with cold sweat",
        order: 85,
        unified: "1F613",
        emoji: "😓",
      },
      {
        keywords: [
          "stressed",
          "worried",
          "distraught",
          "distressed",
          "anxious",
          "tormented",
          "apprehensive",
          "flustered",
          "neglected",
          "lonely",
          "dismayed",
          "hopeless",
          "miserable",
          "dejected",
          "despair",
          "despairing",
          "embarrassed",
          "exhausted",
          "hysterical",
          "fussy",
          "weary face",
          "face",
          "tired",
          "sleepy",
          "sad",
          "frustrated",
          "upset",
          "weary",
        ],
        name: "weary face",
        order: 86,
        unified: "1F629",
        emoji: "😩",
      },
      {
        keywords: [
          "stressed",
          "worried",
          "distraught",
          "flustered",
          "whiny",
          "distressed",
          "anxious",
          "tormented",
          "apprehensive",
          "tired face",
          "sick",
          "whine",
          "upset",
          "frustrated",
        ],
        name: "tired face",
        order: 87,
        unified: "1F62B",
        emoji: "😫",
      },
      {
        keywords: ["yawning face", "tired", "sleepy"],
        name: "yawning face",
        order: 88,
        unified: "1F971",
        emoji: "🥱",
      },
      {
        keywords: [
          "fuming",
          "pissed",
          "argh",
          "pissed off",
          "angry",
          "raging",
          "perturbed",
          "seething",
          "with rage",
          "hmph",
          "irritated",
          "face with steam from nose",
          "face",
          "gas",
          "phew",
          "proud",
          "pride",
          "triumph",
          "face with look of triumph",
        ],
        name: "face with look of triumph",
        order: 89,
        unified: "1F624",
        emoji: "😤",
      },
      {
        keywords: [
          "angry",
          "mad",
          "upset",
          "pissed",
          "angry face",
          "mad face",
          "steaming red",
          "road rage",
          "grumpy face",
          "grumpy",
          "surly",
          "agitated",
          "annoyed",
          "pissed off",
          "irritable",
          "pouting face",
          "hate",
          "despise",
          "rage",
        ],
        name: "pouting face",
        order: 90,
        unified: "1F621",
        emoji: "😡",
      },
      {
        keywords: [
          "angry",
          "mad",
          "pissed",
          "mad face",
          "angry face",
          "grumpy face",
          "upset",
          "surly",
          "agitated",
          "annoyed",
          "irritated",
          "irritating",
          "pissed off",
          "irritable",
          "face",
          "frustrated",
        ],
        name: "angry face",
        order: 91,
        unified: "1F620",
        emoji: "😠",
      },
      {
        keywords: [
          "bleeped",
          "face with symbols",
          "symbols over mouth",
          "cursing",
          "swearing",
          "cussing",
          "censored",
          "face with symbols on mouth",
          "face",
          "profanity",
          "expletive",
          "serious face with symbols covering mouth",
        ],
        name: "serious face with symbols covering mouth",
        order: 92,
        unified: "1F92C",
        emoji: "🤬",
      },
      {
        keywords: ["smiling face with horns", "devil", "horns", "smiling imp"],
        name: "smiling face with horns",
        order: 93,
        unified: "1F608",
        emoji: "😈",
      },
      {
        keywords: ["angry face with horns", "devil", "angry", "horns", "imp"],
        name: "imp",
        order: 94,
        unified: "1F47F",
        emoji: "👿",
      },
      {
        keywords: ["skull", "death", "dead", "skeleton", "creepy"],
        name: "skull",
        order: 95,
        unified: "1F480",
        emoji: "💀",
      },
      {
        keywords: [
          "skull and crossbones",
          "poison",
          "poisonous",
          "danger",
          "deadly",
          "scary",
          "death",
          "pirate",
          "evil",
        ],
        name: "skull and crossbones",
        order: 96,
        unified: "2620-FE0F",
        emoji: "☠️",
      },
      {
        keywords: [
          "poop",
          "poo",
          "doo doo",
          "dookie",
          "crap",
          "caca",
          "turd",
          "poops",
          "turds",
          "pile of poo",
          "hankey",
          "shitface",
          "fail",
          "shit",
        ],
        name: "pile of poo",
        order: 97,
        unified: "1F4A9",
        emoji: "💩",
      },
      {
        keywords: ["clown", "clowns", "clown face", "face"],
        name: "clown face",
        order: 98,
        unified: "1F921",
        emoji: "🤡",
      },
      {
        keywords: [
          "japanese ogre",
          "ogre",
          "monster",
          "red",
          "mask",
          "halloween",
          "scary",
          "creepy",
          "devil",
          "demon",
          "japanese",
        ],
        name: "japanese ogre",
        order: 99,
        unified: "1F479",
        emoji: "👹",
      },
      {
        keywords: [
          "japanese goblin",
          "goblin",
          "red",
          "evil",
          "mask",
          "monster",
          "scary",
          "creepy",
          "japanese",
        ],
        name: "japanese goblin",
        order: 100,
        unified: "1F47A",
        emoji: "👺",
      },
      {
        keywords: [
          "ghost",
          "boo",
          "halloween",
          "disappeared",
          "ghosted",
          "spooky",
          "scary",
        ],
        name: "ghost",
        order: 101,
        unified: "1F47B",
        emoji: "👻",
      },
      {
        keywords: [
          "alien",
          "extraterrestrial",
          "extraterrestrials",
          "martians",
          "aliens",
          "martian",
          "ufo",
          "paul",
          "weird",
          "outer space",
          "extraterrestrial alien",
        ],
        name: "extraterrestrial alien",
        order: 102,
        unified: "1F47D",
        emoji: "👽",
      },
      {
        keywords: ["alien monster", "game", "arcade", "play", "space invader"],
        name: "alien monster",
        order: 103,
        unified: "1F47E",
        emoji: "👾",
      },
      {
        keywords: [
          "robot",
          "robots",
          "computer",
          "machine",
          "bot",
          "robot face",
        ],
        name: "robot face",
        order: 104,
        unified: "1F916",
        emoji: "🤖",
      },
      {
        keywords: [
          "grinning cat",
          "animal",
          "cats",
          "happy",
          "smile",
          "smiley cat",
          "smiling cat face with open mouth",
        ],
        name: "smiling cat face with open mouth",
        order: 105,
        unified: "1F63A",
        emoji: "😺",
      },
      {
        keywords: [
          "grinning cat with smiling eyes",
          "animal",
          "cats",
          "smile",
          "smile cat",
          "grinning cat face with smiling eyes",
        ],
        name: "grinning cat face with smiling eyes",
        order: 106,
        unified: "1F638",
        emoji: "😸",
      },
      {
        keywords: [
          "cat with tears of joy",
          "animal",
          "cats",
          "haha",
          "happy",
          "tears",
          "joy cat",
          "cat face with tears of joy",
        ],
        name: "cat face with tears of joy",
        order: 107,
        unified: "1F639",
        emoji: "😹",
      },
      {
        keywords: [
          "smiling cat with heart eyes",
          "animal",
          "love",
          "like",
          "affection",
          "cats",
          "valentines",
          "heart",
          "heart eyes cat",
          "smiling cat face with heart shaped eyes",
        ],
        name: "smiling cat face with heart-shaped eyes",
        order: 108,
        unified: "1F63B",
        emoji: "😻",
      },
      {
        keywords: [
          "cat with wry smile",
          "animal",
          "cats",
          "smirk",
          "smirk cat",
          "cat face with wry smile",
        ],
        name: "cat face with wry smile",
        order: 109,
        unified: "1F63C",
        emoji: "😼",
      },
      {
        keywords: [
          "kissing cat",
          "animal",
          "cats",
          "kiss",
          "kissing cat face with closed eyes",
        ],
        name: "kissing cat face with closed eyes",
        order: 110,
        unified: "1F63D",
        emoji: "😽",
      },
      {
        keywords: [
          "weary cat",
          "animal",
          "cats",
          "munch",
          "scared",
          "scream",
          "scream cat",
          "weary cat face",
        ],
        name: "weary cat face",
        order: 111,
        unified: "1F640",
        emoji: "🙀",
      },
      {
        keywords: [
          "crying cat",
          "animal",
          "tears",
          "weep",
          "sad",
          "cats",
          "upset",
          "cry",
          "crying cat face",
        ],
        name: "crying cat face",
        order: 112,
        unified: "1F63F",
        emoji: "😿",
      },
      {
        keywords: ["pouting cat", "animal", "cats", "pouting cat face"],
        name: "pouting cat face",
        order: 113,
        unified: "1F63E",
        emoji: "😾",
      },
      {
        keywords: [
          "stop seeing",
          "oh no",
          "see no evil monkey",
          "monkey",
          "animal",
          "nature",
          "haha",
          "see no evil",
        ],
        name: "see-no-evil monkey",
        order: 114,
        unified: "1F648",
        emoji: "🙈",
      },
      {
        keywords: [
          "stop listening",
          "oh no",
          "hear no evil monkey",
          "animal",
          "monkey",
          "nature",
          "hear no evil",
        ],
        name: "hear-no-evil monkey",
        order: 115,
        unified: "1F649",
        emoji: "🙉",
      },
      {
        keywords: [
          "stop talking",
          "oh no",
          "oops",
          "speak no evil monkey",
          "monkey",
          "animal",
          "nature",
          "omg",
          "speak no evil",
        ],
        name: "speak-no-evil monkey",
        order: 116,
        unified: "1F64A",
        emoji: "🙊",
      },
      {
        keywords: [
          "kiss",
          "muah",
          "mwah",
          "xoxo",
          "xx",
          "kiss mark",
          "face",
          "lips",
          "love",
          "like",
          "affection",
          "valentines",
        ],
        name: "kiss mark",
        order: 117,
        unified: "1F48B",
        emoji: "💋",
      },
      {
        keywords: [
          "love letter",
          "email",
          "like",
          "affection",
          "envelope",
          "valentines",
        ],
        name: "love letter",
        order: 118,
        unified: "1F48C",
        emoji: "💌",
      },
      {
        keywords: [
          "love",
          "heart with arrow",
          "like",
          "heart",
          "affection",
          "valentines",
          "cupid",
        ],
        name: "heart with arrow",
        order: 119,
        unified: "1F498",
        emoji: "💘",
      },
      {
        keywords: [
          "heart",
          "present",
          "gift",
          "heart with ribbon",
          "love",
          "valentines",
          "gift heart",
        ],
        name: "heart with ribbon",
        order: 120,
        unified: "1F49D",
        emoji: "💝",
      },
      {
        keywords: [
          "love",
          "heart",
          "twinkling",
          "sparkling heart",
          "like",
          "affection",
          "valentines",
        ],
        name: "sparkling heart",
        order: 121,
        unified: "1F496",
        emoji: "💖",
      },
      {
        keywords: [
          "love",
          "heart",
          "heart beating",
          "heartbeats",
          "heartbeat",
          "growing heart",
          "like",
          "affection",
          "valentines",
          "pink",
          "heartpulse",
        ],
        name: "growing heart",
        order: 122,
        unified: "1F497",
        emoji: "💗",
      },
      {
        keywords: [
          "heart",
          "heart beating",
          "beating heart",
          "love",
          "like",
          "affection",
          "valentines",
          "pink",
          "heartbeat",
        ],
        name: "beating heart",
        order: 123,
        unified: "1F493",
        emoji: "💓",
      },
      {
        keywords: [
          "heart",
          "revolving hearts",
          "love",
          "like",
          "affection",
          "valentines",
        ],
        name: "revolving hearts",
        order: 124,
        unified: "1F49E",
        emoji: "💞",
      },
      {
        keywords: [
          "love",
          "hearts",
          "two hearts",
          "like",
          "affection",
          "valentines",
          "heart",
        ],
        name: "two hearts",
        order: 125,
        unified: "1F495",
        emoji: "💕",
      },
      {
        keywords: [
          "heart",
          "heart decoration",
          "purple square",
          "love",
          "like",
        ],
        name: "heart decoration",
        order: 126,
        unified: "1F49F",
        emoji: "💟",
      },
      {
        keywords: [
          "heart exclamation",
          "decoration",
          "love",
          "heavy heart exclamation mark ornament",
        ],
        name: "heart exclamation",
        order: 127,
        unified: "2763-FE0F",
        emoji: "❣️",
      },
      {
        keywords: [
          "heart",
          "broken heart",
          "sad",
          "sorry",
          "break",
          "heartbreak",
        ],
        name: "broken heart",
        order: 128,
        unified: "1F494",
        emoji: "💔",
      },
      {
        keywords: [
          "love",
          "heart",
          "red heart",
          "like",
          "valentines",
          "heavy black heart",
        ],
        name: "heavy black heart",
        order: 129,
        unified: "2764-FE0F",
        emoji: "❤️",
      },
      {
        keywords: [
          "orange heart",
          "orange hearts",
          "love",
          "like",
          "affection",
          "valentines",
        ],
        name: "orange heart",
        order: 130,
        unified: "1F9E1",
        emoji: "🧡",
      },
      {
        keywords: [
          "heart",
          "yellow heart",
          "love",
          "like",
          "affection",
          "valentines",
        ],
        name: "yellow heart",
        order: 131,
        unified: "1F49B",
        emoji: "💛",
      },
      {
        keywords: [
          "heart",
          "green heart",
          "love",
          "like",
          "affection",
          "valentines",
        ],
        name: "green heart",
        order: 132,
        unified: "1F49A",
        emoji: "💚",
      },
      {
        keywords: [
          "heart",
          "blue heart",
          "love",
          "like",
          "affection",
          "valentines",
        ],
        name: "blue heart",
        order: 133,
        unified: "1F499",
        emoji: "💙",
      },
      {
        keywords: [
          "heart",
          "purple heart",
          "love",
          "like",
          "affection",
          "valentines",
        ],
        name: "purple heart",
        order: 134,
        unified: "1F49C",
        emoji: "💜",
      },
      {
        keywords: ["brown heart", "coffee"],
        name: "brown heart",
        order: 135,
        unified: "1F90E",
        emoji: "🤎",
      },
      {
        keywords: ["heart", "black heart", "evil"],
        name: "black heart",
        order: 136,
        unified: "1F5A4",
        emoji: "🖤",
      },
      {
        keywords: ["white heart", "pure"],
        name: "white heart",
        order: 137,
        unified: "1F90D",
        emoji: "🤍",
      },
      {
        keywords: [
          "one hundred",
          "100",
          "hundred points",
          "score",
          "perfect",
          "numbers",
          "century",
          "exam",
          "quiz",
          "test",
          "pass",
          "hundred",
          "hundred points symbol",
        ],
        name: "hundred points symbol",
        order: 138,
        unified: "1F4AF",
        emoji: "💯",
      },
      {
        keywords: ["anger sign", "anger symbol", "angry", "mad", "anger"],
        name: "anger symbol",
        order: 139,
        unified: "1F4A2",
        emoji: "💢",
      },
      {
        keywords: [
          "pow",
          "bam",
          "boom",
          "explosion",
          "collision",
          "bomb",
          "explode",
          "blown",
          "collision symbol",
        ],
        name: "collision symbol",
        order: 140,
        unified: "1F4A5",
        emoji: "💥",
      },
      {
        keywords: [
          "star",
          "comet",
          "comets",
          "dizzy",
          "sparkle",
          "shoot",
          "magic",
          "dizzy symbol",
        ],
        name: "dizzy symbol",
        order: 141,
        unified: "1F4AB",
        emoji: "💫",
      },
      {
        keywords: [
          "sweat",
          "water",
          "water droplets",
          "water drops",
          "sweat droplets",
          "drip",
          "oops",
          "sweat drops",
          "splashing sweat symbol",
        ],
        name: "splashing sweat symbol",
        order: 142,
        unified: "1F4A6",
        emoji: "💦",
      },
      {
        keywords: [
          "vape",
          "gust",
          "fart",
          "smoke",
          "wind",
          "fast",
          "gusts",
          "farts",
          "dashing away",
          "air",
          "shoo",
          "puff",
          "dash",
          "dash symbol",
        ],
        name: "dash symbol",
        order: 143,
        unified: "1F4A8",
        emoji: "💨",
      },
      {
        keywords: ["hole", "embarrassing"],
        name: "hole",
        order: 144,
        unified: "1F573-FE0F",
        emoji: "🕳️",
      },
      {
        keywords: ["bomb", "boom", "explode", "explosion", "terrorism"],
        name: "bomb",
        order: 145,
        unified: "1F4A3",
        emoji: "💣",
      },
      {
        keywords: [
          "speech balloon",
          "bubble",
          "words",
          "message",
          "talk",
          "chatting",
        ],
        name: "speech balloon",
        order: 146,
        unified: "1F4AC",
        emoji: "💬",
      },
      {
        keywords: ["eye in speech bubble", "info"],
        name: "eye in speech bubble",
        order: 147,
        unified: "1F441-FE0F-200D-1F5E8-FE0F",
        emoji: "👁️‍🗨️",
      },
      {
        keywords: [
          "left speech bubble",
          "words",
          "message",
          "talk",
          "chatting",
        ],
        name: "left speech bubble",
        order: 148,
        unified: "1F5E8-FE0F",
        emoji: "🗨️",
      },
      {
        keywords: [
          "right anger bubble",
          "caption",
          "speech",
          "thinking",
          "mad",
        ],
        name: "right anger bubble",
        order: 149,
        unified: "1F5EF-FE0F",
        emoji: "🗯️",
      },
      {
        keywords: [
          "thought",
          "chat",
          "daydream",
          "thought balloon",
          "daydreams",
          "thoughts",
          "think",
          "thinking",
          "thought bubble",
          "bubble",
          "cloud",
          "speech",
          "dream",
        ],
        name: "thought balloon",
        order: 150,
        unified: "1F4AD",
        emoji: "💭",
      },
      {
        keywords: [
          "sleeping",
          "zzz",
          "zs",
          "sleep",
          "napping",
          "nap",
          "sleepy",
          "asleep",
          "tired",
          "exhausted",
          "snoring",
          "drowsy",
          "dream",
          "sleeping symbol",
        ],
        name: "sleeping symbol",
        order: 151,
        unified: "1F4A4",
        emoji: "💤",
      },
    ],
  },
  {
    title: "people",
    data: [
      {
        keywords: [
          "bye",
          "hi",
          "hello",
          "see ya",
          "bye bye",
          "hey",
          "slap",
          "wave",
          "waving",
          "goodbye",
          "wave goodbye",
          "waving goodbye",
          "see you later",
          "ttfn",
          "waving hand",
          "hands",
          "gesture",
          "solong",
          "farewell",
          "palm",
          "waving hand sign",
        ],
        name: "waving hand sign",
        order: 1,
        unified: "1F44B",
        emoji: "👋",
      },
      {
        keywords: [
          "hand",
          "back hand",
          "back of hand",
          "raised back of hand",
          "fingers",
          "raised",
          "backhand",
        ],
        name: "raised back of hand",
        order: 2,
        unified: "1F91A",
        emoji: "🤚",
      },
      {
        keywords: [
          "hand with fingers splayed",
          "hand",
          "fingers",
          "palm",
          "raised hand with fingers splayed",
        ],
        name: "hand with fingers splayed",
        order: 3,
        unified: "1F590-FE0F",
        emoji: "🖐️",
      },
      {
        keywords: [
          "stop",
          "high five",
          "raised hand",
          "fingers",
          "highfive",
          "palm",
          "ban",
          "hand",
        ],
        name: "raised hand",
        order: 4,
        unified: "270B",
        emoji: "✋",
      },
      {
        keywords: [
          "live long and prosper",
          "vulcan",
          "vulcan salute",
          "spock",
          "hand",
          "fingers",
          "star trek",
          "spock hand",
          "raised hand with part between middle and ring fingers",
        ],
        name: "raised hand with part between middle and ring fingers",
        order: 5,
        unified: "1F596",
        emoji: "🖖",
      },
      {
        keywords: [
          "ok",
          "perfect",
          "okay",
          "sounds good",
          "dece",
          "a ok",
          "ok hand",
          "fingers",
          "limbs",
          "ok hand sign",
        ],
        name: "ok hand sign",
        order: 6,
        unified: "1F44C",
        emoji: "👌",
      },
      {
        keywords: ["pinched fingers", "size", "tiny", "small"],
        name: "pinched fingers",
        order: 7,
        unified: "1F90C",
        emoji: "🤌",
      },
      {
        keywords: ["pinching hand", "tiny", "small", "size"],
        name: "pinching hand",
        order: 8,
        unified: "1F90F",
        emoji: "🤏",
      },
      {
        keywords: [
          "peace",
          "peace out",
          "dueces",
          "victory hand",
          "fingers",
          "ohyeah",
          "hand",
          "victory",
          "two",
          "v",
        ],
        name: "victory hand",
        order: 9,
        unified: "270C-FE0F",
        emoji: "✌️",
      },
      {
        keywords: [
          "crossed",
          "fingers crossed",
          "hand with fingers crossed",
          "index and middle finger crossed",
          "i hope",
          "here’s hoping",
          "crossed fingers",
          "good",
          "lucky",
          "hand with index and middle fingers crossed",
        ],
        name: "hand with index and middle fingers crossed",
        order: 10,
        unified: "1F91E",
        emoji: "🤞",
      },
      {
        keywords: [
          "i love you",
          "gesture",
          "sign language",
          "ily",
          "love you gesture",
          "hand",
          "fingers",
          "i love you hand sign",
        ],
        name: "i love you hand sign",
        order: 11,
        unified: "1F91F",
        emoji: "🤟",
      },
      {
        keywords: [
          "sign of the horns",
          "rock on",
          "heavy metal",
          "hand",
          "fingers",
          "evil eye",
          "sign of horns",
          "the horns",
        ],
        name: "sign of the horns",
        order: 12,
        unified: "1F918",
        emoji: "🤘",
      },
      {
        keywords: [
          "call me",
          "shaka sign",
          "shaka",
          "hang loose",
          "hang ten",
          "hand with thumb and pinky",
          "hit me up",
          "call me hand",
          "hands",
          "gesture",
        ],
        name: "call me hand",
        order: 13,
        unified: "1F919",
        emoji: "🤙",
      },
      {
        keywords: [
          "point left",
          "points left",
          "pointing left",
          "over here",
          "backhand index pointing left",
          "direction",
          "fingers",
          "hand",
          "left",
          "white left pointing backhand index",
        ],
        name: "white left pointing backhand index",
        order: 14,
        unified: "1F448",
        emoji: "👈",
      },
      {
        keywords: [
          "point right",
          "points right",
          "pointed right",
          "over there",
          "backhand index pointing right",
          "fingers",
          "hand",
          "direction",
          "right",
          "white right pointing backhand index",
        ],
        name: "white right pointing backhand index",
        order: 15,
        unified: "1F449",
        emoji: "👉",
      },
      {
        keywords: [
          "point up",
          "points up",
          "pointing up",
          "up there",
          "backhand index pointing up",
          "fingers",
          "hand",
          "direction",
          "up",
          "point up 2",
          "white up pointing backhand index",
        ],
        name: "white up pointing backhand index",
        order: 16,
        unified: "1F446",
        emoji: "👆",
      },
      {
        keywords: [
          "point down",
          "points down",
          "pointing down",
          "down there",
          "backhand index pointing down",
          "fingers",
          "hand",
          "direction",
          "down",
          "white down pointing backhand index",
        ],
        name: "white down pointing backhand index",
        order: 18,
        unified: "1F447",
        emoji: "👇",
      },
      {
        keywords: [
          "pointing",
          "i agree",
          "one",
          "one second",
          "excuse me",
          "index pointing up",
          "hand",
          "fingers",
          "direction",
          "up",
          "point up",
          "white up pointing index",
        ],
        name: "white up pointing index",
        order: 19,
        unified: "261D-FE0F",
        emoji: "☝️",
      },
      {
        keywords: [
          "ok",
          "yep",
          "great",
          "nice",
          "thumbs up",
          "good job",
          "well done",
          "sounds good",
          "thumbsup",
          "yes",
          "awesome",
          "good",
          "agree",
          "accept",
          "cool",
          "hand",
          "like",
          "+1",
          "thumbs up sign",
        ],
        name: "thumbs up sign",
        order: 20,
        unified: "1F44D",
        emoji: "👍",
      },
      {
        keywords: [
          "nope",
          "thumbs down",
          "negative",
          "thumbsdown",
          "no",
          "dislike",
          "hand",
          " 1",
          "thumbs down sign",
        ],
        name: "thumbs down sign",
        order: 21,
        unified: "1F44E",
        emoji: "👎",
      },
      {
        keywords: [
          "respect",
          "raised fist",
          "fingers",
          "hand",
          "grasp",
          "fist",
        ],
        name: "raised fist",
        order: 22,
        unified: "270A",
        emoji: "✊",
      },
      {
        keywords: [
          "fist",
          "fist bump",
          "fists",
          "dap",
          "boom pow",
          "oncoming fist",
          "angry",
          "violence",
          "hit",
          "attack",
          "hand",
          "facepunch",
          "punch",
          "fisted hand sign",
        ],
        name: "fisted hand sign",
        order: 23,
        unified: "1F44A",
        emoji: "👊",
      },
      {
        keywords: [
          "fist",
          "punch",
          "left punch",
          "left facing punch",
          "left facing fist",
          "hand",
          "fistbump",
        ],
        name: "left-facing fist",
        order: 24,
        unified: "1F91B",
        emoji: "🤛",
      },
      {
        keywords: [
          "fist",
          "punch",
          "right punch",
          "right facing punch",
          "right facing fist",
          "hand",
          "fistbump",
        ],
        name: "right-facing fist",
        order: 25,
        unified: "1F91C",
        emoji: "🤜",
      },
      {
        keywords: [
          "clapping",
          "applause",
          "awesome",
          "clap",
          "good job",
          "bravo",
          "yay",
          "clap your hands",
          "give yourself a hand",
          "clapping hands",
          "hands",
          "praise",
          "congrats",
          "clapping hands sign",
        ],
        name: "clapping hands sign",
        order: 26,
        unified: "1F44F",
        emoji: "👏",
      },
      {
        keywords: [
          "woohoo",
          "wohoo",
          "yay",
          "raise the roof",
          "woop",
          "hands in the air",
          "high five",
          "raising hands",
          "gesture",
          "hooray",
          "yea",
          "celebration",
          "hands",
          "raised hands",
          "person raising both hands in celebration",
        ],
        name: "person raising both hands in celebration",
        order: 27,
        unified: "1F64C",
        emoji: "🙌",
      },
      {
        keywords: [
          "open hands",
          "fingers",
          "butterfly",
          "hands",
          "open",
          "open hands sign",
        ],
        name: "open hands sign",
        order: 28,
        unified: "1F450",
        emoji: "👐",
      },
      {
        keywords: [
          "hands held out",
          "open hands",
          "open palms",
          "palms up together",
          "cupped hands",
          "hands",
          "gesture",
          "cupped",
          "prayer",
        ],
        name: "palms up together",
        order: 29,
        unified: "1F932",
        emoji: "🤲",
      },
      {
        keywords: [
          "handshake",
          "two hands in handshake",
          "two hands shaking",
          "make a deal",
          "making a deal",
          "seal the deal",
          "agreement",
          "shake",
        ],
        name: "handshake",
        order: 30,
        unified: "1F91D",
        emoji: "🤝",
      },
      {
        keywords: [
          "amen",
          "pretty please",
          "prayer",
          "namaste",
          "praying",
          "thank you",
          "thanks",
          "gratitude",
          "welcome",
          "folded hands",
          "please",
          "hope",
          "wish",
          "highfive",
          "pray",
          "person with folded hands",
        ],
        name: "person with folded hands",
        order: 31,
        unified: "1F64F",
        emoji: "🙏",
      },
      {
        keywords: [
          "drawing",
          "writing",
          "pen in hand",
          "writing hand",
          "lower left ballpoint pen",
          "stationery",
          "write",
          "compose",
        ],
        name: "writing hand",
        order: 32,
        unified: "270D-FE0F",
        emoji: "✍️",
      },
      {
        keywords: [
          "nail polish",
          "nail salon",
          "doing my nails",
          "nails done",
          "manicure",
          "getting my nails done",
          "painting my nails",
          "paint my nails",
          "get my nails done",
          "beauty",
          "finger",
          "fashion",
          "nail",
          "nail care",
        ],
        name: "nail polish",
        order: 33,
        unified: "1F485",
        emoji: "💅",
      },
      {
        keywords: [
          "selfie",
          "taking a selfie",
          "taking a photo",
          "taking a pic",
          "camera phone",
          "taking a picture",
          "camera",
          "phone",
        ],
        name: "selfie",
        order: 34,
        unified: "1F933",
        emoji: "🤳",
      },
      {
        keywords: [
          "bicep",
          "dumbbell",
          "working out",
          "strong",
          "biceps",
          "dumbbells",
          "work out",
          "works out",
          "worked out",
          "flex",
          "strong arm",
          "pull up",
          "guns",
          "muscles",
          "flexed biceps",
          "arm",
          "hand",
          "summer",
          "muscle",
        ],
        name: "flexed biceps",
        order: 35,
        unified: "1F4AA",
        emoji: "💪",
      },
      {
        keywords: ["mechanical arm", "accessibility"],
        name: "mechanical arm",
        order: 36,
        unified: "1F9BE",
        emoji: "🦾",
      },
      {
        keywords: ["mechanical leg", "accessibility"],
        name: "mechanical leg",
        order: 37,
        unified: "1F9BF",
        emoji: "🦿",
      },
      {
        keywords: ["leg", "kick", "limb"],
        name: "leg",
        order: 38,
        unified: "1F9B5",
        emoji: "🦵",
      },
      {
        keywords: ["foot", "kick", "stomp"],
        name: "foot",
        order: 39,
        unified: "1F9B6",
        emoji: "🦶",
      },
      {
        keywords: [
          "ear",
          "lobe",
          "hear",
          "ears",
          "lobes",
          "listen",
          "listening",
          "listen to me",
          "face",
          "sound",
        ],
        name: "ear",
        order: 40,
        unified: "1F442",
        emoji: "👂",
      },
      {
        keywords: ["ear with hearing aid", "accessibility"],
        name: "ear with hearing aid",
        order: 41,
        unified: "1F9BB",
        emoji: "🦻",
      },
      {
        keywords: ["nose", "nostril", "noses", "nostrils", "smell", "sniff"],
        name: "nose",
        order: 42,
        unified: "1F443",
        emoji: "👃",
      },
      {
        keywords: ["brain", "brains", "smart", "intelligent"],
        name: "brain",
        order: 43,
        unified: "1F9E0",
        emoji: "🧠",
      },
      {
        keywords: ["anatomical heart", "health", "heartbeat"],
        name: "anatomical heart",
        order: 44,
        unified: "1FAC0",
        emoji: "🫀",
      },
      {
        keywords: ["lungs", "breathe"],
        name: "lungs",
        order: 45,
        unified: "1FAC1",
        emoji: "🫁",
      },
      {
        keywords: ["tooth", "teeth", "dentist"],
        name: "tooth",
        order: 46,
        unified: "1F9B7",
        emoji: "🦷",
      },
      {
        keywords: ["bone", "skeleton"],
        name: "bone",
        order: 47,
        unified: "1F9B4",
        emoji: "🦴",
      },
      {
        keywords: [
          "eyes",
          "look",
          "spying",
          "i see you",
          "eye balls",
          "creeper",
          "stalker",
          "peep",
          "watch",
          "stalk",
          "peek",
          "see",
        ],
        name: "eyes",
        order: 48,
        unified: "1F440",
        emoji: "👀",
      },
      {
        keywords: ["eye", "face", "look", "see", "watch", "stare"],
        name: "eye",
        order: 49,
        unified: "1F441-FE0F",
        emoji: "👁️",
      },
      {
        keywords: ["tongue", "lick", "mouth", "playful"],
        name: "tongue",
        order: 50,
        unified: "1F445",
        emoji: "👅",
      },
      {
        keywords: ["mouth", "lips", "mouths", "kiss"],
        name: "mouth",
        order: 51,
        unified: "1F444",
        emoji: "👄",
      },
      {
        keywords: [
          "baby",
          "kiddie",
          "kiddo",
          "babies",
          "kiddies",
          "kiddos",
          "child",
          "infant",
          "newborn",
          "children",
          "infants",
          "newborns",
          "boy",
          "girl",
          "toddler",
        ],
        name: "baby",
        order: 52,
        unified: "1F476",
        emoji: "👶",
      },
      {
        keywords: ["child", "young person", "kid", "gender neutral", "young"],
        name: "child",
        order: 53,
        unified: "1F9D2",
        emoji: "🧒",
      },
      {
        keywords: ["boy", "man", "male", "guy", "teenager"],
        name: "boy",
        order: 54,
        unified: "1F466",
        emoji: "👦",
      },
      {
        keywords: ["girl", "female", "woman", "teenager"],
        name: "girl",
        order: 55,
        unified: "1F467",
        emoji: "👧",
      },
      {
        keywords: ["person", "gender neutral", "adult"],
        name: "adult",
        order: 56,
        unified: "1F9D1",
        emoji: "🧑",
      },
      {
        keywords: [
          "man",
          "mustache",
          "father",
          "dad",
          "guy",
          "classy",
          "sir",
          "moustache",
        ],
        name: "man",
        order: 58,
        unified: "1F468",
        emoji: "👨",
      },
      {
        keywords: [
          "bearded",
          "beard",
          "man beard",
          "person",
          "bewhiskered",
          "bearded person",
        ],
        name: "bearded person",
        order: 59,
        unified: "1F9D4",
        emoji: "🧔",
      },
      {
        keywords: [
          "man red hair",
          "hairstyle",
          "red haired man",
          "man: red hair",
        ],
        name: "man: red hair",
        order: 60,
        unified: "1F468-200D-1F9B0",
        emoji: "👨‍🦰",
      },
      {
        keywords: [
          "man curly hair",
          "hairstyle",
          "curly haired man",
          "man: curly hair",
        ],
        name: "man: curly hair",
        order: 61,
        unified: "1F468-200D-1F9B1",
        emoji: "👨‍🦱",
      },
      {
        keywords: [
          "man white hair",
          "old",
          "elder",
          "white haired man",
          "man: white hair",
        ],
        name: "man: white hair",
        order: 62,
        unified: "1F468-200D-1F9B3",
        emoji: "👨‍🦳",
      },
      {
        keywords: ["man bald", "hairless", "bald man", "man: bald"],
        name: "man: bald",
        order: 63,
        unified: "1F468-200D-1F9B2",
        emoji: "👨‍🦲",
      },
      {
        keywords: ["woman", "female", "girls", "lady"],
        name: "woman",
        order: 64,
        unified: "1F469",
        emoji: "👩",
      },
      {
        keywords: [
          "woman red hair",
          "hairstyle",
          "red haired woman",
          "woman: red hair",
        ],
        name: "woman: red hair",
        order: 65,
        unified: "1F469-200D-1F9B0",
        emoji: "👩‍🦰",
      },
      {
        keywords: [
          "person red hair",
          "hairstyle",
          "red haired person",
          "person: red hair",
        ],
        name: "person: red hair",
        order: 66,
        unified: "1F9D1-200D-1F9B0",
        emoji: "🧑‍🦰",
      },
      {
        keywords: [
          "woman curly hair",
          "hairstyle",
          "curly haired woman",
          "woman: curly hair",
        ],
        name: "woman: curly hair",
        order: 67,
        unified: "1F469-200D-1F9B1",
        emoji: "👩‍🦱",
      },
      {
        keywords: [
          "person curly hair",
          "hairstyle",
          "curly haired person",
          "person: curly hair",
        ],
        name: "person: curly hair",
        order: 68,
        unified: "1F9D1-200D-1F9B1",
        emoji: "🧑‍🦱",
      },
      {
        keywords: [
          "woman white hair",
          "old",
          "elder",
          "white haired woman",
          "woman: white hair",
        ],
        name: "woman: white hair",
        order: 69,
        unified: "1F469-200D-1F9B3",
        emoji: "👩‍🦳",
      },
      {
        keywords: [
          "person white hair",
          "elder",
          "old",
          "white haired person",
          "person: white hair",
        ],
        name: "person: white hair",
        order: 70,
        unified: "1F9D1-200D-1F9B3",
        emoji: "🧑‍🦳",
      },
      {
        keywords: ["woman bald", "hairless", "bald woman", "woman: bald"],
        name: "woman: bald",
        order: 71,
        unified: "1F469-200D-1F9B2",
        emoji: "👩‍🦲",
      },
      {
        keywords: ["person bald", "hairless", "bald person", "person: bald"],
        name: "person: bald",
        order: 72,
        unified: "1F9D1-200D-1F9B2",
        emoji: "🧑‍🦲",
      },
      {
        keywords: [
          "woman blond hair",
          "woman",
          "female",
          "girl",
          "blonde",
          "person",
          "blond haired woman",
          "woman: blond hair",
        ],
        name: "woman: blond hair",
        order: 73,
        unified: "1F471-200D-2640-FE0F",
        emoji: "👱‍♀️",
      },
      {
        keywords: [
          "man blond hair",
          "man",
          "male",
          "boy",
          "blonde",
          "guy",
          "person",
          "blond haired man",
          "man: blond hair",
        ],
        name: "man: blond hair",
        order: 74,
        unified: "1F471-200D-2642-FE0F",
        emoji: "👱‍♂️",
      },
      {
        keywords: [
          "older adult",
          "older person",
          "elder",
          "senior citizen",
          "human",
          "senior",
          "gender neutral",
        ],
        name: "older adult",
        order: 75,
        unified: "1F9D3",
        emoji: "🧓",
      },
      {
        keywords: [
          "old man",
          "grandpa",
          "grandfather",
          "papa",
          "grandad",
          "elderly man",
          "older man",
          "human",
          "male",
          "men",
          "old",
          "elder",
          "senior",
        ],
        name: "older man",
        order: 76,
        unified: "1F474",
        emoji: "👴",
      },
      {
        keywords: [
          "old woman",
          "grandma",
          "grandmother",
          "nanna",
          "elderly lady",
          "old lady",
          "human",
          "female",
          "women",
          "lady",
          "old",
          "elder",
          "senior",
          "older woman",
        ],
        name: "older woman",
        order: 77,
        unified: "1F475",
        emoji: "👵",
      },
      {
        keywords: [
          "man frowning",
          "male",
          "boy",
          "man",
          "sad",
          "depressed",
          "discouraged",
          "unhappy",
        ],
        name: "man frowning",
        order: 79,
        unified: "1F64D-200D-2642-FE0F",
        emoji: "🙍‍♂️",
      },
      {
        keywords: [
          "woman frowning",
          "female",
          "girl",
          "woman",
          "sad",
          "depressed",
          "discouraged",
          "unhappy",
        ],
        name: "woman frowning",
        order: 80,
        unified: "1F64D-200D-2640-FE0F",
        emoji: "🙍‍♀️",
      },
      {
        keywords: ["man pouting", "male", "boy", "man"],
        name: "man pouting",
        order: 82,
        unified: "1F64E-200D-2642-FE0F",
        emoji: "🙎‍♂️",
      },
      {
        keywords: ["woman pouting", "female", "girl", "woman"],
        name: "woman pouting",
        order: 83,
        unified: "1F64E-200D-2640-FE0F",
        emoji: "🙎‍♀️",
      },
      {
        keywords: [
          "not gonna happen",
          "not going to happen",
          "poor choice",
          "a no go",
          "denied",
          "rejected",
          "no deal",
          "man gesturing no",
          "male",
          "boy",
          "man",
          "nope",
        ],
        name: "man gesturing no",
        order: 85,
        unified: "1F645-200D-2642-FE0F",
        emoji: "🙅‍♂️",
      },
      {
        keywords: [
          "not gonna happen",
          "not going to happen",
          "poor choice",
          "a no go",
          "denied",
          "rejected",
          "no deal",
          "woman gesturing no",
          "female",
          "girl",
          "woman",
          "nope",
        ],
        name: "woman gesturing no",
        order: 86,
        unified: "1F645-200D-2640-FE0F",
        emoji: "🙅‍♀️",
      },
      {
        keywords: [
          "ok gesture",
          "hands on head",
          "stretching",
          "man gesturing ok",
          "men",
          "boy",
          "male",
          "blue",
          "human",
          "man",
        ],
        name: "man gesturing ok",
        order: 88,
        unified: "1F646-200D-2642-FE0F",
        emoji: "🙆‍♂️",
      },
      {
        keywords: [
          "ok gesture",
          "hands on head",
          "stretching",
          "woman gesturing ok",
          "women",
          "girl",
          "female",
          "pink",
          "human",
          "woman",
        ],
        name: "woman gesturing ok",
        order: 89,
        unified: "1F646-200D-2640-FE0F",
        emoji: "🙆‍♀️",
      },
      {
        keywords: [
          "man tipping hand",
          "male",
          "boy",
          "man",
          "human",
          "information",
        ],
        name: "man tipping hand",
        order: 91,
        unified: "1F481-200D-2642-FE0F",
        emoji: "💁‍♂️",
      },
      {
        keywords: [
          "hairdo",
          "hairstyle",
          "updo",
          "hair appointment",
          "my hair done",
          "diva",
          "hair flick",
          "hair toss",
          "hair flip",
          "sassy",
          "concierge",
          "bellhop",
          "information desk woman",
          "woman tipping hand",
          "female",
          "girl",
          "woman",
          "human",
          "information",
        ],
        name: "woman tipping hand",
        order: 92,
        unified: "1F481-200D-2640-FE0F",
        emoji: "💁‍♀️",
      },
      {
        keywords: ["man raising hand", "male", "boy", "man"],
        name: "man raising hand",
        order: 94,
        unified: "1F64B-200D-2642-FE0F",
        emoji: "🙋‍♂️",
      },
      {
        keywords: [
          "hand up",
          "raising hand",
          "woman raising hand",
          "female",
          "girl",
          "woman",
        ],
        name: "woman raising hand",
        order: 95,
        unified: "1F64B-200D-2640-FE0F",
        emoji: "🙋‍♀️",
      },
      {
        keywords: ["deaf person", "accessibility"],
        name: "deaf person",
        order: 96,
        unified: "1F9CF",
        emoji: "🧏",
      },
      {
        keywords: ["deaf man", "accessibility"],
        name: "deaf man",
        order: 97,
        unified: "1F9CF-200D-2642-FE0F",
        emoji: "🧏‍♂️",
      },
      {
        keywords: ["deaf woman", "accessibility"],
        name: "deaf woman",
        order: 98,
        unified: "1F9CF-200D-2640-FE0F",
        emoji: "🧏‍♀️",
      },
      {
        keywords: ["bow", "bowing", "man bowing", "man", "male", "boy"],
        name: "man bowing",
        order: 100,
        unified: "1F647-200D-2642-FE0F",
        emoji: "🙇‍♂️",
      },
      {
        keywords: ["bow", "bowing", "woman bowing", "woman", "female", "girl"],
        name: "woman bowing",
        order: 101,
        unified: "1F647-200D-2640-FE0F",
        emoji: "🙇‍♀️",
      },
      {
        keywords: ["person facepalming", "disappointed", "face palm"],
        name: "face palm",
        order: 102,
        unified: "1F926",
        emoji: "🤦",
      },
      {
        keywords: [
          "face palm",
          "face palming",
          "man doing a face palm",
          "man face palming",
          "hand on face",
          "smh",
          "oh no",
          "don’t tell me",
          "say it isn’t so",
          "facepalm",
          "why did i do that",
          "face palm man",
          "man face palm",
          "man facepalming",
          "man",
          "male",
          "boy",
          "disbelief",
        ],
        name: "man facepalming",
        order: 103,
        unified: "1F926-200D-2642-FE0F",
        emoji: "🤦‍♂️",
      },
      {
        keywords: [
          "face palm",
          "face palming",
          "woman doing a face palm",
          "woman face palming",
          "hand on face",
          "smh",
          "oh no",
          "don’t tell me",
          "say it isn’t so",
          "facepalm",
          "why did i do that",
          "face palm woman",
          "woman face palm",
          "woman facepalming",
          "woman",
          "female",
          "girl",
          "disbelief",
        ],
        name: "woman facepalming",
        order: 104,
        unified: "1F926-200D-2640-FE0F",
        emoji: "🤦‍♀️",
      },
      {
        keywords: ["person shrugging", "regardless", "shrug"],
        name: "shrug",
        order: 105,
        unified: "1F937",
        emoji: "🤷",
      },
      {
        keywords: [
          "shrug",
          "shrugging",
          "man shrugging",
          "confused man",
          "confused",
          "undecided",
          "indecisive",
          "i don’t know",
          "i dunno",
          "dunno",
          "shrugged",
          "shruggie",
          "don’t care",
          "i don’t care",
          "man",
          "male",
          "boy",
          "indifferent",
          "doubt",
        ],
        name: "man shrugging",
        order: 106,
        unified: "1F937-200D-2642-FE0F",
        emoji: "🤷‍♂️",
      },
      {
        keywords: [
          "shrug",
          "shrugging",
          "woman shrugging",
          "confused woman",
          "confused",
          "undecided",
          "indecisive",
          "i don’t know",
          "i dunno",
          "dunno",
          "shrugged",
          "shruggie",
          "don’t care",
          "i don’t care",
          "woman",
          "female",
          "girl",
          "indifferent",
          "doubt",
        ],
        name: "woman shrugging",
        order: 107,
        unified: "1F937-200D-2640-FE0F",
        emoji: "🤷‍♀️",
      },
      {
        keywords: ["health worker", "hospital"],
        name: "health worker",
        order: 108,
        unified: "1F9D1-200D-2695-FE0F",
        emoji: "🧑‍⚕️",
      },
      {
        keywords: [
          "health worker",
          "hospital worker",
          "nurse",
          "doctor",
          "physician",
          "chiropractor",
          "surgeon",
          "man health worker",
          "man hospital worker",
          "man doctor",
          "man physician",
          "man chiropractor",
          "man surgeon",
          "therapist",
          "healthcare",
          "man",
          "human",
          "male doctor",
        ],
        name: "man health worker",
        order: 109,
        unified: "1F468-200D-2695-FE0F",
        emoji: "👨‍⚕️",
      },
      {
        keywords: [
          "health worker",
          "hospital worker",
          "nurse",
          "doctor",
          "physician",
          "chiropractor",
          "surgeon",
          "woman health worker",
          "woman hospital worker",
          "woman doctor",
          "woman physician",
          "woman chiropractor",
          "woman surgeon",
          "therapist",
          "healthcare",
          "woman",
          "human",
          "female doctor",
        ],
        name: "woman health worker",
        order: 110,
        unified: "1F469-200D-2695-FE0F",
        emoji: "👩‍⚕️",
      },
      {
        keywords: ["student", "learn"],
        name: "student",
        order: 111,
        unified: "1F9D1-200D-1F393",
        emoji: "🧑‍🎓",
      },
      {
        keywords: [
          "student",
          "graduate",
          "pupil",
          "man student",
          "man graduate",
          "man pupil",
          "graduation",
          "graduation day",
          "alumnus",
          "alumni",
          "alma mater",
          "man",
          "human",
          "male student",
        ],
        name: "man student",
        order: 112,
        unified: "1F468-200D-1F393",
        emoji: "👨‍🎓",
      },
      {
        keywords: [
          "student",
          "graduate",
          "pupil",
          "woman student",
          "woman graduate",
          "woman pupil",
          "graduation",
          "graduation day",
          "alumnae",
          "alumna",
          "alumnus",
          "alumni",
          "alma mater",
          "woman",
          "human",
          "female student",
        ],
        name: "woman student",
        order: 113,
        unified: "1F469-200D-1F393",
        emoji: "👩‍🎓",
      },
      {
        keywords: ["teacher", "professor"],
        name: "teacher",
        order: 114,
        unified: "1F9D1-200D-1F3EB",
        emoji: "🧑‍🏫",
      },
      {
        keywords: [
          "teacher",
          "teachers",
          "professor",
          "professors",
          "lecturer",
          "educator",
          "educators",
          "schoolteacher",
          "schoolteachers",
          "prof",
          "faculty member",
          "faculty members",
          "instructor",
          "instructors",
          "scholar",
          "scholars",
          "man teacher",
          "man teachers",
          "man professor",
          "man professors",
          "man lecturer",
          "man educator",
          "man educators",
          "man schoolteacher",
          "man schoolteachers",
          "man faculty member",
          "man",
          "human",
          "male teacher",
        ],
        name: "man teacher",
        order: 115,
        unified: "1F468-200D-1F3EB",
        emoji: "👨‍🏫",
      },
      {
        keywords: [
          "teacher",
          "teachers",
          "professor",
          "professors",
          "lecturer",
          "educator",
          "educators",
          "schoolteacher",
          "schoolteachers",
          "prof",
          "faculty member",
          "faculty members",
          "instructor",
          "instructors",
          "scholar",
          "scholars",
          "woman teacher",
          "woman teachers",
          "woman professor",
          "woman professors",
          "woman lecturer",
          "woman educator",
          "woman educators",
          "woman schoolteacher",
          "woman schoolteachers",
          "woman faculty member",
          "woman",
          "human",
          "female teacher",
        ],
        name: "woman teacher",
        order: 116,
        unified: "1F469-200D-1F3EB",
        emoji: "👩‍🏫",
      },
      {
        keywords: ["judge", "law"],
        name: "judge",
        order: 117,
        unified: "1F9D1-200D-2696-FE0F",
        emoji: "🧑‍⚖️",
      },
      {
        keywords: [
          "judge",
          "court room",
          "moderator",
          "marshal",
          "justice of the peace",
          "legal official",
          "adjudicator",
          "judicial system",
          "justices",
          "man judge",
          "justice",
          "court",
          "man",
          "human",
          "male judge",
        ],
        name: "man judge",
        order: 118,
        unified: "1F468-200D-2696-FE0F",
        emoji: "👨‍⚖️",
      },
      {
        keywords: [
          "judge",
          "court room",
          "moderator",
          "marshal",
          "justice of the peace",
          "legal official",
          "adjudicator",
          "judge judy",
          "judicial system",
          "justices",
          "woman judge",
          "justice",
          "court",
          "woman",
          "human",
          "female judge",
        ],
        name: "woman judge",
        order: 119,
        unified: "1F469-200D-2696-FE0F",
        emoji: "👩‍⚖️",
      },
      {
        keywords: ["farmer", "crops"],
        name: "farmer",
        order: 120,
        unified: "1F9D1-200D-1F33E",
        emoji: "🧑‍🌾",
      },
      {
        keywords: [
          "farmer",
          "farmer joe",
          "old mc’donald",
          "man farmer",
          "rancher",
          "gardener",
          "man",
          "human",
          "male farmer",
        ],
        name: "man farmer",
        order: 121,
        unified: "1F468-200D-1F33E",
        emoji: "👨‍🌾",
      },
      {
        keywords: [
          "farmer",
          "farmer jane",
          "old mc’donald",
          "woman farmer",
          "rancher",
          "gardener",
          "woman",
          "human",
          "female farmer",
        ],
        name: "woman farmer",
        order: 122,
        unified: "1F469-200D-1F33E",
        emoji: "👩‍🌾",
      },
      {
        keywords: ["cook", "food", "kitchen", "culinary"],
        name: "cook",
        order: 123,
        unified: "1F9D1-200D-1F373",
        emoji: "🧑‍🍳",
      },
      {
        keywords: [
          "cook",
          "chef",
          "baker",
          "sous chef",
          "man cook",
          "man chef",
          "man baker",
          "man sous chef",
          "man",
          "human",
          "male cook",
        ],
        name: "man cook",
        order: 124,
        unified: "1F468-200D-1F373",
        emoji: "👨‍🍳",
      },
      {
        keywords: [
          "cook",
          "chef",
          "baker",
          "sous chef",
          "woman cook",
          "woman chef",
          "woman baker",
          "woman sous chef",
          "woman",
          "human",
          "female cook",
        ],
        name: "woman cook",
        order: 125,
        unified: "1F469-200D-1F373",
        emoji: "👩‍🍳",
      },
      {
        keywords: ["mechanic", "worker", "technician"],
        name: "mechanic",
        order: 126,
        unified: "1F9D1-200D-1F527",
        emoji: "🧑‍🔧",
      },
      {
        keywords: [
          "mechanic",
          "technician",
          "man mechanic",
          "man technician",
          "plumber",
          "man",
          "human",
          "wrench",
          "male mechanic",
        ],
        name: "man mechanic",
        order: 127,
        unified: "1F468-200D-1F527",
        emoji: "👨‍🔧",
      },
      {
        keywords: [
          "mechanic",
          "technician",
          "woman mechanic",
          "woman technician",
          "plumber",
          "woman",
          "human",
          "wrench",
          "female mechanic",
        ],
        name: "woman mechanic",
        order: 128,
        unified: "1F469-200D-1F527",
        emoji: "👩‍🔧",
      },
      {
        keywords: ["factory worker", "labor"],
        name: "factory worker",
        order: 129,
        unified: "1F9D1-200D-1F3ED",
        emoji: "🧑‍🏭",
      },
      {
        keywords: [
          "factory worker",
          "welder",
          "manufacturing",
          "soldering",
          "man factory worker",
          "man welder",
          "man in manufacturing",
          "assembly",
          "industrial",
          "man",
          "human",
          "male factory worker",
        ],
        name: "man factory worker",
        order: 130,
        unified: "1F468-200D-1F3ED",
        emoji: "👨‍🏭",
      },
      {
        keywords: [
          "factory worker",
          "welder",
          "manufacturing",
          "soldering",
          "woman factory worker",
          "woman welder",
          "woman in manufacturing",
          "assembly",
          "industrial",
          "woman",
          "human",
          "female factory worker",
        ],
        name: "woman factory worker",
        order: 131,
        unified: "1F469-200D-1F3ED",
        emoji: "👩‍🏭",
      },
      {
        keywords: ["office worker", "business"],
        name: "office worker",
        order: 132,
        unified: "1F9D1-200D-1F4BC",
        emoji: "🧑‍💼",
      },
      {
        keywords: [
          "office worker",
          "insurance agent",
          "salesman",
          "paper pusher",
          "clerical worker",
          "admin",
          "administrative worker",
          "secretary",
          "ceo",
          "cfo",
          "cpa",
          "cto",
          "man office worker",
          "man insurance agent",
          "man clerical worker",
          "man admin",
          "man administrative worker",
          "man secretary",
          "man ceo",
          "man cfo",
          "man cpa",
          "man cto",
          "business",
          "manager",
          "man",
          "human",
          "male office worker",
        ],
        name: "man office worker",
        order: 133,
        unified: "1F468-200D-1F4BC",
        emoji: "👨‍💼",
      },
      {
        keywords: [
          "office worker",
          "insurance agent",
          "saleswoman",
          "paper pusher",
          "clerical worker",
          "admin",
          "administrative worker",
          "secretary",
          "ceo",
          "cfo",
          "cpa",
          "cto",
          "woman office worker",
          "woman insurance agent",
          "woman clerical worker",
          "woman admin",
          "woman administrative worker",
          "woman secretary",
          "woman ceo",
          "woman cfo",
          "woman cpa",
          "woman cto",
          "business",
          "manager",
          "woman",
          "human",
          "female office worker",
        ],
        name: "woman office worker",
        order: 134,
        unified: "1F469-200D-1F4BC",
        emoji: "👩‍💼",
      },
      {
        keywords: ["scientist", "chemistry"],
        name: "scientist",
        order: 135,
        unified: "1F9D1-200D-1F52C",
        emoji: "🧑‍🔬",
      },
      {
        keywords: [
          "scientist",
          "chemist",
          "physicist",
          "lab technician",
          "rocket scientist",
          "man scientist",
          "man chemist",
          "man physicist",
          "man lab technician",
          "man rocket scientist",
          "biologist",
          "engineer",
          "man",
          "human",
          "male scientist",
        ],
        name: "man scientist",
        order: 136,
        unified: "1F468-200D-1F52C",
        emoji: "👨‍🔬",
      },
      {
        keywords: [
          "scientist",
          "chemist",
          "physicist",
          "lab technician",
          "rocket scientist",
          "woman scientist",
          "woman chemist",
          "woman physicist",
          "woman lab technician",
          "woman rocket scientist",
          "biologist",
          "engineer",
          "woman",
          "human",
          "female scientist",
        ],
        name: "woman scientist",
        order: 137,
        unified: "1F469-200D-1F52C",
        emoji: "👩‍🔬",
      },
      {
        keywords: ["technologist", "computer"],
        name: "technologist",
        order: 138,
        unified: "1F9D1-200D-1F4BB",
        emoji: "🧑‍💻",
      },
      {
        keywords: [
          "technology worker",
          "tech worker",
          "technologist",
          "techie",
          "it worker",
          "apple genius",
          "man in technology",
          "man tech worker",
          "man technologist",
          "man it worker",
          "man in it",
          "man apple genius",
          "coder",
          "developer",
          "engineer",
          "programmer",
          "software",
          "man",
          "human",
          "laptop",
          "computer",
          "male technologist",
        ],
        name: "man technologist",
        order: 139,
        unified: "1F468-200D-1F4BB",
        emoji: "👨‍💻",
      },
      {
        keywords: [
          "technology worker",
          "tech worker",
          "technologist",
          "techie",
          "it worker",
          "apple genius",
          "woman in technology",
          "woman tech worker",
          "woman technologist",
          "woman it worker",
          "woman in it",
          "woman apple genius",
          "coder",
          "developer",
          "engineer",
          "programmer",
          "software",
          "woman",
          "human",
          "laptop",
          "computer",
          "female technologist",
        ],
        name: "woman technologist",
        order: 140,
        unified: "1F469-200D-1F4BB",
        emoji: "👩‍💻",
      },
      {
        keywords: ["singer", "song", "artist", "performer"],
        name: "singer",
        order: 141,
        unified: "1F9D1-200D-1F3A4",
        emoji: "🧑‍🎤",
      },
      {
        keywords: [
          "singer",
          "musician",
          "artist",
          "musical artist",
          "man singer",
          "man musician",
          "man artist",
          "man musical artist",
          "diva",
          "vocalist",
          "minstrel",
          "soloist",
          "rockstar",
          "entertainer",
          "man",
          "human",
          "male singer",
        ],
        name: "man singer",
        order: 142,
        unified: "1F468-200D-1F3A4",
        emoji: "👨‍🎤",
      },
      {
        keywords: [
          "singer",
          "musician",
          "artist",
          "musical artist",
          "woman singer",
          "woman musician",
          "woman artist",
          "woman musical artist",
          "diva",
          "vocalist",
          "minstrel",
          "soloist",
          "rockstar",
          "entertainer",
          "woman",
          "human",
          "female singer",
        ],
        name: "woman singer",
        order: 143,
        unified: "1F469-200D-1F3A4",
        emoji: "👩‍🎤",
      },
      {
        keywords: ["artist", "painting", "draw", "creativity"],
        name: "artist",
        order: 144,
        unified: "1F9D1-200D-1F3A8",
        emoji: "🧑‍🎨",
      },
      {
        keywords: [
          "artist",
          "artisan",
          "painter",
          "artiste",
          "designer",
          "creative designer",
          "man artist",
          "man artisan",
          "man painter",
          "man artiste",
          "man creative designer",
          "man",
          "human",
          "male artist",
        ],
        name: "man artist",
        order: 145,
        unified: "1F468-200D-1F3A8",
        emoji: "👨‍🎨",
      },
      {
        keywords: [
          "artist",
          "artisan",
          "painter",
          "artiste",
          "designer",
          "creative designer",
          "woman artist",
          "woman artisan",
          "woman painter",
          "woman artiste",
          "woman creative designer",
          "woman",
          "human",
          "female artist",
        ],
        name: "woman artist",
        order: 146,
        unified: "1F469-200D-1F3A8",
        emoji: "👩‍🎨",
      },
      {
        keywords: ["pilot", "fly", "plane", "airplane"],
        name: "pilot",
        order: 147,
        unified: "1F9D1-200D-2708-FE0F",
        emoji: "🧑‍✈️",
      },
      {
        keywords: [
          "pilot",
          "aviator",
          "captain",
          "flight attendant",
          "navigator",
          "man pilot",
          "man aviator",
          "man captain",
          "man flight attendant",
          "man navigator",
          "steward",
          "plane",
          "man",
          "human",
          "male pilot",
        ],
        name: "man pilot",
        order: 148,
        unified: "1F468-200D-2708-FE0F",
        emoji: "👨‍✈️",
      },
      {
        keywords: [
          "pilot",
          "aviator",
          "captain",
          "flight attendant",
          "navigator",
          "woman pilot",
          "woman aviator",
          "woman captain",
          "woman flight attendant",
          "woman navigator",
          "stewardess",
          "plane",
          "woman",
          "human",
          "female pilot",
        ],
        name: "woman pilot",
        order: 149,
        unified: "1F469-200D-2708-FE0F",
        emoji: "👩‍✈️",
      },
      {
        keywords: ["astronaut", "outerspace"],
        name: "astronaut",
        order: 150,
        unified: "1F9D1-200D-1F680",
        emoji: "🧑‍🚀",
      },
      {
        keywords: [
          "astronaut",
          "cosmonaut",
          "man astronaut",
          "space",
          "rocket",
          "man",
          "human",
          "male astronaut",
        ],
        name: "man astronaut",
        order: 151,
        unified: "1F468-200D-1F680",
        emoji: "👨‍🚀",
      },
      {
        keywords: [
          "astronaut",
          "cosmonaut",
          "woman astronaut",
          "space",
          "rocket",
          "woman",
          "human",
          "female astronaut",
        ],
        name: "woman astronaut",
        order: 152,
        unified: "1F469-200D-1F680",
        emoji: "👩‍🚀",
      },
      {
        keywords: ["firefighter", "fire"],
        name: "firefighter",
        order: 153,
        unified: "1F9D1-200D-1F692",
        emoji: "🧑‍🚒",
      },
      {
        keywords: [
          "firefighter",
          "man firefighter",
          "fireman",
          "man",
          "human",
          "male firefighter",
        ],
        name: "man firefighter",
        order: 154,
        unified: "1F468-200D-1F692",
        emoji: "👨‍🚒",
      },
      {
        keywords: [
          "firefighter",
          "woman firefighter",
          "fireman",
          "woman",
          "human",
          "female firefighter",
        ],
        name: "woman firefighter",
        order: 155,
        unified: "1F469-200D-1F692",
        emoji: "👩‍🚒",
      },
      {
        keywords: [
          "police",
          "cop",
          "policeman",
          "constable",
          "police officer",
          "officer",
          "cops",
          "police officers",
          "policemen",
          "constables",
          "officers",
          "popo",
          "5 0",
          "man police officer",
          "man",
          "law",
          "legal",
          "enforcement",
          "arrest",
          "911",
          "male police officer",
        ],
        name: "man police officer",
        order: 157,
        unified: "1F46E-200D-2642-FE0F",
        emoji: "👮‍♂️",
      },
      {
        keywords: [
          "police",
          "cop",
          "policewoman",
          "constable",
          "police officer",
          "officer",
          "cops",
          "police officers",
          "policewomen",
          "constables",
          "officers",
          "popo",
          "5 0",
          "woman police officer",
          "woman",
          "law",
          "legal",
          "enforcement",
          "arrest",
          "911",
          "female",
          "female police officer",
        ],
        name: "woman police officer",
        order: 158,
        unified: "1F46E-200D-2640-FE0F",
        emoji: "👮‍♀️",
      },
      {
        keywords: [
          "spy",
          "spying",
          "sleuth",
          "sleuthing",
          "sherlock",
          "sherlock holmes",
          "man detective",
          "crime",
          "male detective",
        ],
        name: "man detective",
        order: 160,
        unified: "1F575-FE0F-200D-2642-FE0F",
        emoji: "🕵️‍♂️",
      },
      {
        keywords: [
          "spy",
          "spying",
          "sleuth",
          "sleuthing",
          "sherlock",
          "sherlock holmes",
          "woman detective",
          "human",
          "detective",
          "female",
          "woman",
          "female detective",
        ],
        name: "woman detective",
        order: 161,
        unified: "1F575-FE0F-200D-2640-FE0F",
        emoji: "🕵️‍♀️",
      },
      {
        keywords: [
          "man guard",
          "uk",
          "gb",
          "british",
          "male",
          "guy",
          "royal",
          "male guard",
        ],
        name: "man guard",
        order: 163,
        unified: "1F482-200D-2642-FE0F",
        emoji: "💂‍♂️",
      },
      {
        keywords: [
          "woman guard",
          "uk",
          "gb",
          "british",
          "female",
          "royal",
          "woman",
          "female guard",
        ],
        name: "woman guard",
        order: 164,
        unified: "1F482-200D-2640-FE0F",
        emoji: "💂‍♀️",
      },
      {
        keywords: ["ninja", "ninjutsu", "skills", "japanese"],
        name: "ninja",
        order: 165,
        unified: "1F977",
        emoji: "🥷",
      },
      {
        keywords: [
          "construction worker",
          "construction workers",
          "man construction worker",
          "male",
          "human",
          "wip",
          "guy",
          "build",
          "construction",
          "worker",
          "labor",
          "male construction worker",
        ],
        name: "man construction worker",
        order: 167,
        unified: "1F477-200D-2642-FE0F",
        emoji: "👷‍♂️",
      },
      {
        keywords: [
          "construction worker",
          "construction workers",
          "woman construction worker",
          "female",
          "human",
          "wip",
          "build",
          "construction",
          "worker",
          "labor",
          "woman",
          "female construction worker",
        ],
        name: "woman construction worker",
        order: 168,
        unified: "1F477-200D-2640-FE0F",
        emoji: "👷‍♀️",
      },
      {
        keywords: [
          "prince",
          "boy with crown",
          "crown",
          "man with crown",
          "king",
          "boy",
          "man",
          "male",
          "royal",
        ],
        name: "prince",
        order: 169,
        unified: "1F934",
        emoji: "🤴",
      },
      {
        keywords: [
          "princess",
          "queen",
          "crown",
          "little princess",
          "pretty princess",
          "princesses",
          "queens",
          "little princesses",
          "pretty princesses",
          "your highness",
          "duchess",
          "beauty queen",
          "pageant queen",
          "tiara",
          "girl",
          "woman",
          "female",
          "blond",
          "royal",
        ],
        name: "princess",
        order: 170,
        unified: "1F478",
        emoji: "👸",
      },
      {
        keywords: ["man wearing turban", "male", "indian", "hinduism", "arabs"],
        name: "man wearing turban",
        order: 172,
        unified: "1F473-200D-2642-FE0F",
        emoji: "👳‍♂️",
      },
      {
        keywords: [
          "woman wearing turban",
          "female",
          "indian",
          "hinduism",
          "arabs",
          "woman",
        ],
        name: "woman wearing turban",
        order: 173,
        unified: "1F473-200D-2640-FE0F",
        emoji: "👳‍♀️",
      },
      {
        keywords: [
          "man with skullcap",
          "male",
          "boy",
          "chinese",
          "man with gua pi mao",
        ],
        name: "man with gua pi mao",
        order: 174,
        unified: "1F472",
        emoji: "👲",
      },
      {
        keywords: [
          "headscarf",
          "hijab",
          "woman with headscarf",
          "female",
          "mantilla",
          "tichel",
          "person with headscarf",
        ],
        name: "person with headscarf",
        order: 175,
        unified: "1F9D5",
        emoji: "🧕",
      },
      {
        keywords: [
          "tuxedo",
          "man in tuxedo",
          "person in tuxedo",
          "formal attire",
          "groom",
          "bridegroom",
          "couple",
          "marriage",
          "wedding",
        ],
        name: "man in tuxedo",
        order: 176,
        unified: "1F935",
        emoji: "🤵",
      },
      {
        keywords: ["man in tuxedo", "formal", "fashion"],
        name: "man in tuxedo",
        order: 177,
        unified: "1F935-200D-2642-FE0F",
        emoji: "🤵‍♂️",
      },
      {
        keywords: ["woman in tuxedo", "formal", "fashion"],
        name: "woman in tuxedo",
        order: 178,
        unified: "1F935-200D-2640-FE0F",
        emoji: "🤵‍♀️",
      },
      {
        keywords: [
          "bride",
          "wedding",
          "blushing bride",
          "getting married",
          "weddings",
          "brides",
          "blushing brides",
          "bride with veil",
          "couple",
          "marriage",
          "woman",
        ],
        name: "bride with veil",
        order: 179,
        unified: "1F470",
        emoji: "👰",
      },
      {
        keywords: ["man with veil", "wedding", "marriage"],
        name: "man with veil",
        order: 180,
        unified: "1F470-200D-2642-FE0F",
        emoji: "👰‍♂️",
      },
      {
        keywords: ["woman with veil", "wedding", "marriage"],
        name: "woman with veil",
        order: 181,
        unified: "1F470-200D-2640-FE0F",
        emoji: "👰‍♀️",
      },
      {
        keywords: [
          "pregnant",
          "pregnant woman",
          "woman with child",
          "pregnancy",
          "having a baby",
          "giving birth",
          "pregnant lady",
          "baby",
        ],
        name: "pregnant woman",
        order: 182,
        unified: "1F930",
        emoji: "🤰",
      },
      {
        keywords: [
          "breast feeding",
          "breastfeeding",
          "breastfeed",
          "breast feed",
          "breastfed",
          "breast fed",
          "nursing",
          "baby",
        ],
        name: "breast-feeding",
        order: 183,
        unified: "1F931",
        emoji: "🤱",
      },
      {
        keywords: ["woman feeding baby", "birth", "food"],
        name: "woman feeding baby",
        order: 184,
        unified: "1F469-200D-1F37C",
        emoji: "👩‍🍼",
      },
      {
        keywords: ["man feeding baby", "birth", "food"],
        name: "man feeding baby",
        order: 185,
        unified: "1F468-200D-1F37C",
        emoji: "👨‍🍼",
      },
      {
        keywords: ["person feeding baby", "birth", "food"],
        name: "person feeding baby",
        order: 186,
        unified: "1F9D1-200D-1F37C",
        emoji: "🧑‍🍼",
      },
      {
        keywords: [
          "baby angel",
          "angel",
          "guardian angel",
          "baby angels",
          "angels",
          "guardian angels",
          "heaven",
          "wings",
          "halo",
        ],
        name: "baby angel",
        order: 187,
        unified: "1F47C",
        emoji: "👼",
      },
      {
        keywords: [
          "santa claus",
          "santa",
          "father christmas",
          "old saint nick",
          "kris kringle",
          "saint nick",
          "saint nicholas",
          "mr. claus",
          "festival",
          "man",
          "male",
          "xmas",
        ],
        name: "father christmas",
        order: 188,
        unified: "1F385",
        emoji: "🎅",
      },
      {
        keywords: [
          "claus",
          "mrs. claus",
          "mother christmas",
          "mrs claus",
          "woman",
          "female",
          "xmas",
        ],
        name: "mother christmas",
        order: 189,
        unified: "1F936",
        emoji: "🤶",
      },
      {
        keywords: ["mx claus", "christmas"],
        name: "mx claus",
        order: 190,
        unified: "1F9D1-200D-1F384",
        emoji: "🧑‍🎄",
      },
      {
        keywords: ["superhero", "marvel"],
        name: "superhero",
        order: 191,
        unified: "1F9B8",
        emoji: "🦸",
      },
      {
        keywords: [
          "man superhero",
          "man",
          "male",
          "good",
          "hero",
          "superpowers",
          "male superhero",
        ],
        name: "man superhero",
        order: 192,
        unified: "1F9B8-200D-2642-FE0F",
        emoji: "🦸‍♂️",
      },
      {
        keywords: [
          "woman superhero",
          "woman",
          "female",
          "good",
          "heroine",
          "superpowers",
          "female superhero",
        ],
        name: "woman superhero",
        order: 193,
        unified: "1F9B8-200D-2640-FE0F",
        emoji: "🦸‍♀️",
      },
      {
        keywords: ["supervillain", "marvel"],
        name: "supervillain",
        order: 194,
        unified: "1F9B9",
        emoji: "🦹",
      },
      {
        keywords: [
          "man supervillain",
          "man",
          "male",
          "evil",
          "bad",
          "criminal",
          "hero",
          "superpowers",
          "male supervillain",
        ],
        name: "man supervillain",
        order: 195,
        unified: "1F9B9-200D-2642-FE0F",
        emoji: "🦹‍♂️",
      },
      {
        keywords: [
          "woman supervillain",
          "woman",
          "female",
          "evil",
          "bad",
          "criminal",
          "heroine",
          "superpowers",
          "female supervillain",
        ],
        name: "woman supervillain",
        order: 196,
        unified: "1F9B9-200D-2640-FE0F",
        emoji: "🦹‍♀️",
      },
      {
        keywords: [
          "mage",
          "mages",
          "wizard",
          "wizards",
          "magical",
          "sorcerer",
          "sorcerers",
          "enchanter",
          "enchanters",
          "necromancer",
          "necromancers",
          "man mage",
          "merlin",
          "man",
          "male",
          "male mage",
        ],
        name: "man mage",
        order: 198,
        unified: "1F9D9-200D-2642-FE0F",
        emoji: "🧙‍♂️",
      },
      {
        keywords: [
          "mage",
          "mages",
          "magical",
          "enchanter",
          "enchanters",
          "necromancer",
          "necromancers",
          "woman mage",
          "sorceress",
          "sorceresses",
          "woman",
          "female",
          "witch",
          "female mage",
        ],
        name: "woman mage",
        order: 199,
        unified: "1F9D9-200D-2640-FE0F",
        emoji: "🧙‍♀️",
      },
      {
        keywords: [
          "man fairy",
          "man fairies",
          "fairy",
          "fairies",
          "pixie",
          "pixies",
          "man",
          "male",
          "male fairy",
        ],
        name: "man fairy",
        order: 201,
        unified: "1F9DA-200D-2642-FE0F",
        emoji: "🧚‍♂️",
      },
      {
        keywords: [
          "woman fairy",
          "woman fairies",
          "fairy",
          "fairies",
          "pixie",
          "pixies",
          "woman",
          "female",
          "female fairy",
        ],
        name: "woman fairy",
        order: 202,
        unified: "1F9DA-200D-2640-FE0F",
        emoji: "🧚‍♀️",
      },
      {
        keywords: [
          "man vampire",
          "man vampires",
          "vampire",
          "vampires",
          "undead",
          "dracula",
          "man",
          "male",
          "male vampire",
        ],
        name: "man vampire",
        order: 204,
        unified: "1F9DB-200D-2642-FE0F",
        emoji: "🧛‍♂️",
      },
      {
        keywords: [
          "woman vampire",
          "woman vampires",
          "vampire",
          "vampires",
          "undead",
          "woman",
          "female",
          "female vampire",
        ],
        name: "woman vampire",
        order: 205,
        unified: "1F9DB-200D-2640-FE0F",
        emoji: "🧛‍♀️",
      },
      {
        keywords: ["merman", "triton", "man", "male"],
        name: "merman",
        order: 207,
        unified: "1F9DC-200D-2642-FE0F",
        emoji: "🧜‍♂️",
      },
      {
        keywords: [
          "mermaid",
          "mermaids",
          "woman",
          "female",
          "merwoman",
          "ariel",
        ],
        name: "mermaid",
        order: 208,
        unified: "1F9DC-200D-2640-FE0F",
        emoji: "🧜‍♀️",
      },
      {
        keywords: [
          "elf",
          "man elf",
          "elves",
          "man elves",
          "elrond",
          "legolas",
          "man",
          "male",
          "male elf",
        ],
        name: "man elf",
        order: 210,
        unified: "1F9DD-200D-2642-FE0F",
        emoji: "🧝‍♂️",
      },
      {
        keywords: [
          "elf",
          "woman elf",
          "elves",
          "woman elves",
          "galadriel",
          "arwen",
          "woman",
          "female",
          "female elf",
        ],
        name: "woman elf",
        order: 211,
        unified: "1F9DD-200D-2640-FE0F",
        emoji: "🧝‍♀️",
      },
      {
        keywords: ["genie", "man genie", "djinn", "man", "male", "male genie"],
        name: "man genie",
        order: 213,
        unified: "1F9DE-200D-2642-FE0F",
        emoji: "🧞‍♂️",
      },
      {
        keywords: [
          "woman genie",
          "djinn",
          "genie",
          "genies",
          "woman",
          "female",
          "female genie",
        ],
        name: "woman genie",
        order: 214,
        unified: "1F9DE-200D-2640-FE0F",
        emoji: "🧞‍♀️",
      },
      {
        keywords: [
          "zombie",
          "zombies",
          "man zombie",
          "man zombies",
          "undead",
          "man",
          "male",
          "dracula",
          "walking dead",
          "male zombie",
        ],
        name: "man zombie",
        order: 216,
        unified: "1F9DF-200D-2642-FE0F",
        emoji: "🧟‍♂️",
      },
      {
        keywords: [
          "zombie",
          "zombies",
          "woman zombie",
          "woman zombies",
          "undead",
          "woman",
          "female",
          "walking dead",
          "female zombie",
        ],
        name: "woman zombie",
        order: 217,
        unified: "1F9DF-200D-2640-FE0F",
        emoji: "🧟‍♀️",
      },
      {
        keywords: [
          "massage",
          "massages",
          "man massage",
          "man getting massage",
          "male",
          "boy",
          "man",
          "head",
        ],
        name: "man getting massage",
        order: 219,
        unified: "1F486-200D-2642-FE0F",
        emoji: "💆‍♂️",
      },
      {
        keywords: [
          "massage",
          "massages",
          "woman massage",
          "woman getting massage",
          "female",
          "girl",
          "woman",
          "head",
        ],
        name: "woman getting massage",
        order: 220,
        unified: "1F486-200D-2640-FE0F",
        emoji: "💆‍♀️",
      },
      {
        keywords: [
          "haircut",
          "salon",
          "haircuts",
          "man haircut",
          "man getting haircut",
          "male",
          "boy",
          "man",
        ],
        name: "man getting haircut",
        order: 222,
        unified: "1F487-200D-2642-FE0F",
        emoji: "💇‍♂️",
      },
      {
        keywords: [
          "haircut",
          "salon",
          "haircuts",
          "woman haircut",
          "woman getting haircut",
          "female",
          "girl",
          "woman",
        ],
        name: "woman getting haircut",
        order: 223,
        unified: "1F487-200D-2640-FE0F",
        emoji: "💇‍♀️",
      },
      {
        keywords: [
          "pedestrian",
          "walking",
          "strolling",
          "stroll",
          "taking a walk",
          "pedestrians",
          "get away",
          "man walking",
          "human",
          "feet",
          "steps",
        ],
        name: "man walking",
        order: 225,
        unified: "1F6B6-200D-2642-FE0F",
        emoji: "🚶‍♂️",
      },
      {
        keywords: [
          "pedestrian",
          "walking",
          "strolling",
          "stroll",
          "taking a walk",
          "pedestrians",
          "get away",
          "woman walking",
          "human",
          "feet",
          "steps",
          "woman",
          "female",
        ],
        name: "woman walking",
        order: 226,
        unified: "1F6B6-200D-2640-FE0F",
        emoji: "🚶‍♀️",
      },
      {
        keywords: ["person standing", "still", "standing person"],
        name: "standing person",
        order: 227,
        unified: "1F9CD",
        emoji: "🧍",
      },
      {
        keywords: ["man standing", "still"],
        name: "man standing",
        order: 228,
        unified: "1F9CD-200D-2642-FE0F",
        emoji: "🧍‍♂️",
      },
      {
        keywords: ["woman standing", "still"],
        name: "woman standing",
        order: 229,
        unified: "1F9CD-200D-2640-FE0F",
        emoji: "🧍‍♀️",
      },
      {
        keywords: ["person kneeling", "pray", "respectful", "kneeling person"],
        name: "kneeling person",
        order: 230,
        unified: "1F9CE",
        emoji: "🧎",
      },
      {
        keywords: ["man kneeling", "pray", "respectful"],
        name: "man kneeling",
        order: 231,
        unified: "1F9CE-200D-2642-FE0F",
        emoji: "🧎‍♂️",
      },
      {
        keywords: ["woman kneeling", "respectful", "pray"],
        name: "woman kneeling",
        order: 232,
        unified: "1F9CE-200D-2640-FE0F",
        emoji: "🧎‍♀️",
      },
      {
        keywords: [
          "person with probing cane",
          "blind",
          "person with white cane",
        ],
        name: "person with white cane",
        order: 233,
        unified: "1F9D1-200D-1F9AF",
        emoji: "🧑‍🦯",
      },
      {
        keywords: ["man with probing cane", "blind", "man with white cane"],
        name: "man with white cane",
        order: 234,
        unified: "1F468-200D-1F9AF",
        emoji: "👨‍🦯",
      },
      {
        keywords: ["woman with probing cane", "blind", "woman with white cane"],
        name: "woman with white cane",
        order: 235,
        unified: "1F469-200D-1F9AF",
        emoji: "👩‍🦯",
      },
      {
        keywords: [
          "person in motorized wheelchair",
          "disability",
          "accessibility",
        ],
        name: "person in motorized wheelchair",
        order: 236,
        unified: "1F9D1-200D-1F9BC",
        emoji: "🧑‍🦼",
      },
      {
        keywords: [
          "man in motorized wheelchair",
          "disability",
          "accessibility",
        ],
        name: "man in motorized wheelchair",
        order: 237,
        unified: "1F468-200D-1F9BC",
        emoji: "👨‍🦼",
      },
      {
        keywords: [
          "woman in motorized wheelchair",
          "disability",
          "accessibility",
        ],
        name: "woman in motorized wheelchair",
        order: 238,
        unified: "1F469-200D-1F9BC",
        emoji: "👩‍🦼",
      },
      {
        keywords: [
          "person in manual wheelchair",
          "disability",
          "accessibility",
        ],
        name: "person in manual wheelchair",
        order: 239,
        unified: "1F9D1-200D-1F9BD",
        emoji: "🧑‍🦽",
      },
      {
        keywords: ["man in manual wheelchair", "disability", "accessibility"],
        name: "man in manual wheelchair",
        order: 240,
        unified: "1F468-200D-1F9BD",
        emoji: "👨‍🦽",
      },
      {
        keywords: ["woman in manual wheelchair", "disability", "accessibility"],
        name: "woman in manual wheelchair",
        order: 241,
        unified: "1F469-200D-1F9BD",
        emoji: "👩‍🦽",
      },
      {
        keywords: [
          "runner",
          "jogger",
          "jogging",
          "run",
          "running",
          "runners",
          "joggers",
          "get away",
          "man running",
          "man",
          "walking",
          "exercise",
          "race",
        ],
        name: "man running",
        order: 243,
        unified: "1F3C3-200D-2642-FE0F",
        emoji: "🏃‍♂️",
      },
      {
        keywords: [
          "runner",
          "jogger",
          "jogging",
          "run",
          "running",
          "runners",
          "woman running",
          "woman",
          "walking",
          "exercise",
          "race",
          "female",
        ],
        name: "woman running",
        order: 244,
        unified: "1F3C3-200D-2640-FE0F",
        emoji: "🏃‍♀️",
      },
      {
        keywords: [
          "dancer",
          "dancing woman",
          "woman dancing",
          "flamenco",
          "salsa",
          "dance",
          "dancing",
          "dancers",
          "disco",
          "female",
          "girl",
          "woman",
          "fun",
        ],
        name: "dancer",
        order: 245,
        unified: "1F483",
        emoji: "💃",
      },
      {
        keywords: [
          "dancer",
          "man dancer",
          "dancing man",
          "dancing",
          "man dancing",
          "elvis",
          "male",
          "boy",
          "fun",
        ],
        name: "man dancing",
        order: 246,
        unified: "1F57A",
        emoji: "🕺",
      },
      {
        keywords: [
          "man in suit levitating",
          "suit",
          "business",
          "levitate",
          "hover",
          "jump",
          "man in business suit levitating",
          "person in suit levitating",
        ],
        name: "person in suit levitating",
        order: 247,
        unified: "1F574-FE0F",
        emoji: "🕴️",
      },
      {
        keywords: [
          "men with bunny ears",
          "male",
          "bunny",
          "men",
          "boys",
          "man with bunny ears partying",
        ],
        name: "men with bunny ears",
        order: 249,
        unified: "1F46F-200D-2642-FE0F",
        emoji: "👯‍♂️",
      },
      {
        keywords: [
          "women with bunny ears",
          "female",
          "bunny",
          "women",
          "girls",
          "woman with bunny ears partying",
        ],
        name: "women with bunny ears",
        order: 250,
        unified: "1F46F-200D-2640-FE0F",
        emoji: "👯‍♀️",
      },
      {
        keywords: [
          "man in sauna",
          "sauna",
          "steam room",
          "man in steamy room",
          "male",
          "man",
          "spa",
          "steamroom",
        ],
        name: "man in steamy room",
        order: 252,
        unified: "1F9D6-200D-2642-FE0F",
        emoji: "🧖‍♂️",
      },
      {
        keywords: [
          "woman in sauna",
          "sauna",
          "steam room",
          "woman in steamy room",
          "female",
          "woman",
          "spa",
          "steamroom",
        ],
        name: "woman in steamy room",
        order: 253,
        unified: "1F9D6-200D-2640-FE0F",
        emoji: "🧖‍♀️",
      },
      {
        keywords: [
          "climber",
          "climbing",
          "rock climbing",
          "rock climber",
          "man climber",
          "man climbing",
          "sports",
          "hobby",
          "man",
          "male",
          "rock",
        ],
        name: "man climbing",
        order: 255,
        unified: "1F9D7-200D-2642-FE0F",
        emoji: "🧗‍♂️",
      },
      {
        keywords: [
          "climber",
          "climbing",
          "rock climbing",
          "rock climber",
          "woman climber",
          "woman climbing",
          "sports",
          "hobby",
          "woman",
          "female",
          "rock",
        ],
        name: "woman climbing",
        order: 256,
        unified: "1F9D7-200D-2640-FE0F",
        emoji: "🧗‍♀️",
      },
      {
        keywords: [
          "fencer",
          "fencers",
          "fencing",
          "duel",
          "dueling",
          "sword fighting",
          "sword fight",
          "person fencing",
          "sports",
          "sword",
        ],
        name: "fencer",
        order: 257,
        unified: "1F93A",
        emoji: "🤺",
      },
      {
        keywords: [
          "horse racing",
          "animal",
          "betting",
          "competition",
          "gambling",
          "luck",
        ],
        name: "horse racing",
        order: 258,
        unified: "1F3C7",
        emoji: "🏇",
      },
      {
        keywords: ["skier", "sports", "winter", "snow"],
        name: "skier",
        order: 259,
        unified: "26F7-FE0F",
        emoji: "⛷️",
      },
      {
        keywords: [
          "snowboarder",
          "snowboarding",
          "on the slopes",
          "sports",
          "winter",
        ],
        name: "snowboarder",
        order: 260,
        unified: "1F3C2",
        emoji: "🏂",
      },
      {
        keywords: [
          "golf",
          "golfer",
          "golfers",
          "golfing",
          "man golfing",
          "sport",
        ],
        name: "man golfing",
        order: 262,
        unified: "1F3CC-FE0F-200D-2642-FE0F",
        emoji: "🏌️‍♂️",
      },
      {
        keywords: [
          "golf",
          "golfer",
          "golfers",
          "golfing",
          "woman golfing",
          "sports",
          "business",
          "woman",
          "female",
        ],
        name: "woman golfing",
        order: 263,
        unified: "1F3CC-FE0F-200D-2640-FE0F",
        emoji: "🏌️‍♀️",
      },
      {
        keywords: [
          "surf",
          "surfing",
          "surfer",
          "surfers",
          "surf’s up",
          "hang ten",
          "man surfing",
          "sports",
          "ocean",
          "sea",
          "summer",
          "beach",
        ],
        name: "man surfing",
        order: 265,
        unified: "1F3C4-200D-2642-FE0F",
        emoji: "🏄‍♂️",
      },
      {
        keywords: [
          "surf",
          "surfing",
          "surfer",
          "surfers",
          "surf’s up",
          "hang ten",
          "woman surfing",
          "sports",
          "ocean",
          "sea",
          "summer",
          "beach",
          "woman",
          "female",
        ],
        name: "woman surfing",
        order: 266,
        unified: "1F3C4-200D-2640-FE0F",
        emoji: "🏄‍♀️",
      },
      {
        keywords: [
          "rowboat",
          "boat",
          "rowing",
          "rowboats",
          "boats",
          "man rowing",
          "man rowing boat",
          "sports",
          "hobby",
          "water",
          "ship",
        ],
        name: "man rowing boat",
        order: 268,
        unified: "1F6A3-200D-2642-FE0F",
        emoji: "🚣‍♂️",
      },
      {
        keywords: [
          "rowboat",
          "boat",
          "rowing",
          "rowboats",
          "boats",
          "woman rowing",
          "woman rowing boat",
          "sports",
          "hobby",
          "water",
          "ship",
          "woman",
          "female",
        ],
        name: "woman rowing boat",
        order: 269,
        unified: "1F6A3-200D-2640-FE0F",
        emoji: "🚣‍♀️",
      },
      {
        keywords: [
          "pool",
          "swim",
          "swimmer",
          "swimming",
          "swimmers",
          "man swimming",
          "sports",
          "exercise",
          "human",
          "athlete",
          "water",
          "summer",
        ],
        name: "man swimming",
        order: 271,
        unified: "1F3CA-200D-2642-FE0F",
        emoji: "🏊‍♂️",
      },
      {
        keywords: [
          "pool",
          "swimmer",
          "swimming",
          "swimmers",
          "woman swimming",
          "sports",
          "exercise",
          "human",
          "athlete",
          "water",
          "summer",
          "woman",
          "female",
        ],
        name: "woman swimming",
        order: 272,
        unified: "1F3CA-200D-2640-FE0F",
        emoji: "🏊‍♀️",
      },
      {
        keywords: [
          "basketball player",
          "basketball players",
          "baller",
          "ballers",
          "man playing basketball",
          "man bouncing ball",
          "sport",
        ],
        name: "man bouncing ball",
        order: 274,
        unified: "26F9-FE0F-200D-2642-FE0F",
        emoji: "⛹️‍♂️",
      },
      {
        keywords: [
          "basketball player",
          "basketball players",
          "baller",
          "ballers",
          "woman playing basketball",
          "woman bouncing ball",
          "sports",
          "human",
          "woman",
          "female",
        ],
        name: "woman bouncing ball",
        order: 275,
        unified: "26F9-FE0F-200D-2640-FE0F",
        emoji: "⛹️‍♀️",
      },
      {
        keywords: [
          "weightlifting",
          "weight lifting",
          "weight lifter",
          "weightlifter",
          "clean and jerk",
          "lift weights",
          "lifting weights",
          "working out",
          "work out",
          "man lifting weights",
          "sport",
        ],
        name: "man lifting weights",
        order: 277,
        unified: "1F3CB-FE0F-200D-2642-FE0F",
        emoji: "🏋️‍♂️",
      },
      {
        keywords: [
          "weightlifting",
          "weight lifting",
          "weight lifter",
          "weightlifter",
          "clean and jerk",
          "lift weights",
          "lifting weights",
          "working out",
          "work out",
          "woman lifting weights",
          "sports",
          "training",
          "exercise",
          "woman",
          "female",
        ],
        name: "woman lifting weights",
        order: 278,
        unified: "1F3CB-FE0F-200D-2640-FE0F",
        emoji: "🏋️‍♀️",
      },
      {
        keywords: [
          "cyclist",
          "bicyclist",
          "cycling",
          "biking",
          "biker",
          "bikers",
          "road bike",
          "cyclists",
          "bicyclists",
          "man biking",
          "sports",
          "bike",
          "exercise",
          "hipster",
        ],
        name: "man biking",
        order: 280,
        unified: "1F6B4-200D-2642-FE0F",
        emoji: "🚴‍♂️",
      },
      {
        keywords: [
          "cyclist",
          "bicyclist",
          "cycling",
          "biking",
          "biker",
          "bikers",
          "cyclists",
          "road bike",
          "bicyclists",
          "woman biking",
          "sports",
          "bike",
          "exercise",
          "hipster",
          "woman",
          "female",
        ],
        name: "woman biking",
        order: 281,
        unified: "1F6B4-200D-2640-FE0F",
        emoji: "🚴‍♀️",
      },
      {
        keywords: [
          "cyclist",
          "bicyclist",
          "cycling",
          "biking",
          "biker",
          "bikers",
          "cyclists",
          "bicyclists",
          "mountain bike",
          "mountain biking",
          "man biking",
          "man mountain biking",
          "transportation",
          "sports",
          "human",
          "race",
          "bike",
        ],
        name: "man mountain biking",
        order: 283,
        unified: "1F6B5-200D-2642-FE0F",
        emoji: "🚵‍♂️",
      },
      {
        keywords: [
          "cyclist",
          "bicyclist",
          "cycling",
          "biking",
          "biker",
          "bikers",
          "cyclists",
          "bicyclists",
          "mountain bike",
          "mountain biking",
          "woman biking",
          "woman mountain biking",
          "transportation",
          "sports",
          "human",
          "race",
          "bike",
          "woman",
          "female",
        ],
        name: "woman mountain biking",
        order: 284,
        unified: "1F6B5-200D-2640-FE0F",
        emoji: "🚵‍♀️",
      },
      {
        keywords: [
          "person cartwheeling",
          "sport",
          "gymnastic",
          "person doing cartwheel",
        ],
        name: "person doing cartwheel",
        order: 285,
        unified: "1F938",
        emoji: "🤸",
      },
      {
        keywords: [
          "cartwheel",
          "cartwheels",
          "doing a cartwheel",
          "doing cartwheels",
          "man doing a cartwheel",
          "gymnast",
          "man cartwheeling",
          "man gymnast",
          "gymnasts",
          "cartwheeling",
          "gymnastics",
        ],
        name: "man cartwheeling",
        order: 286,
        unified: "1F938-200D-2642-FE0F",
        emoji: "🤸‍♂️",
      },
      {
        keywords: [
          "cartwheel",
          "cartwheels",
          "doing a cartwheel",
          "doing cartwheels",
          "woman doing a cartwheel",
          "woman cartwheeling",
          "woman gymnast",
          "gymnast",
          "gymnasts",
          "cartwheeling",
          "gymnastics",
        ],
        name: "woman cartwheeling",
        order: 287,
        unified: "1F938-200D-2640-FE0F",
        emoji: "🤸‍♀️",
      },
      {
        keywords: ["people wrestling", "sport", "wrestlers"],
        name: "wrestlers",
        order: 288,
        unified: "1F93C",
        emoji: "🤼",
      },
      {
        keywords: [
          "wrestler",
          "wrestlers",
          "two men wrestling",
          "wrestling",
          "wrestling match",
          "men wrestling",
          "two wrestlers",
          "sports",
          "man wrestling",
        ],
        name: "men wrestling",
        order: 289,
        unified: "1F93C-200D-2642-FE0F",
        emoji: "🤼‍♂️",
      },
      {
        keywords: [
          "wrestler",
          "wrestlers",
          "two women wrestling",
          "wrestling",
          "wrestling match",
          "women wrestling",
          "two wrestlers",
          "sports",
          "woman wrestling",
        ],
        name: "women wrestling",
        order: 290,
        unified: "1F93C-200D-2640-FE0F",
        emoji: "🤼‍♀️",
      },
      {
        keywords: ["person playing water polo", "sport", "water polo"],
        name: "water polo",
        order: 291,
        unified: "1F93D",
        emoji: "🤽",
      },
      {
        keywords: [
          "water polo",
          "water polo player",
          "playing water polo",
          "water sport",
          "man playing water polo",
          "sports",
          "pool",
        ],
        name: "man playing water polo",
        order: 292,
        unified: "1F93D-200D-2642-FE0F",
        emoji: "🤽‍♂️",
      },
      {
        keywords: [
          "water polo",
          "water polo player",
          "playing water polo",
          "water sport",
          "woman playing water polo",
          "sports",
          "pool",
        ],
        name: "woman playing water polo",
        order: 293,
        unified: "1F93D-200D-2640-FE0F",
        emoji: "🤽‍♀️",
      },
      {
        keywords: ["person playing handball", "sport", "handball"],
        name: "handball",
        order: 294,
        unified: "1F93E",
        emoji: "🤾",
      },
      {
        keywords: [
          "handball",
          "playing handball",
          "man playing handball",
          "handball player",
          "sports",
        ],
        name: "man playing handball",
        order: 295,
        unified: "1F93E-200D-2642-FE0F",
        emoji: "🤾‍♂️",
      },
      {
        keywords: [
          "handball",
          "playing handball",
          "woman playing handball",
          "handball player",
          "sports",
        ],
        name: "woman playing handball",
        order: 296,
        unified: "1F93E-200D-2640-FE0F",
        emoji: "🤾‍♀️",
      },
      {
        keywords: ["person juggling", "performance", "balance", "juggling"],
        name: "juggling",
        order: 297,
        unified: "1F939",
        emoji: "🤹",
      },
      {
        keywords: [
          "juggling",
          "man juggling",
          "juggling balls",
          "juggler",
          "juggle",
          "balance",
          "skill",
          "multitask",
        ],
        name: "man juggling",
        order: 298,
        unified: "1F939-200D-2642-FE0F",
        emoji: "🤹‍♂️",
      },
      {
        keywords: [
          "juggling",
          "woman juggling",
          "juggling balls",
          "juggler",
          "juggle",
          "balance",
          "skill",
          "multitask",
        ],
        name: "woman juggling",
        order: 299,
        unified: "1F939-200D-2640-FE0F",
        emoji: "🤹‍♀️",
      },
      {
        keywords: [
          "yoga",
          "yogi",
          "lotus position",
          "meditation",
          "man in lotus position",
          "man",
          "male",
          "serenity",
          "zen",
          "mindfulness",
        ],
        name: "man in lotus position",
        order: 301,
        unified: "1F9D8-200D-2642-FE0F",
        emoji: "🧘‍♂️",
      },
      {
        keywords: [
          "yoga",
          "yogi",
          "lotus position",
          "meditation",
          "woman in lotus position",
          "woman",
          "female",
          "serenity",
          "zen",
          "mindfulness",
        ],
        name: "woman in lotus position",
        order: 302,
        unified: "1F9D8-200D-2640-FE0F",
        emoji: "🧘‍♀️",
      },
      {
        keywords: [
          "bubble bath",
          "bath",
          "bathtub",
          "tub",
          "baths",
          "bathtubs",
          "person taking bath",
          "clean",
          "shower",
          "bathroom",
        ],
        name: "bath",
        order: 303,
        unified: "1F6C0",
        emoji: "🛀",
      },
      {
        keywords: ["person in bed", "bed", "rest", "sleeping accommodation"],
        name: "sleeping accommodation",
        order: 304,
        unified: "1F6CC",
        emoji: "🛌",
      },
      {
        keywords: ["people holding hands", "friendship"],
        name: "people holding hands",
        order: 305,
        unified: "1F9D1-200D-1F91D-200D-1F9D1",
        emoji: "🧑‍🤝‍🧑",
      },
      {
        keywords: [
          "women holding hands",
          "pair",
          "friendship",
          "couple",
          "love",
          "like",
          "female",
          "people",
          "human",
          "two women holding hands",
        ],
        name: "two women holding hands",
        order: 306,
        unified: "1F46D",
        emoji: "👭",
      },
      {
        keywords: [
          "woman and man holding hands",
          "pair",
          "people",
          "human",
          "love",
          "date",
          "dating",
          "like",
          "affection",
          "valentines",
          "marriage",
          "man and woman holding hands",
          "couple",
        ],
        name: "man and woman holding hands",
        order: 307,
        unified: "1F46B",
        emoji: "👫",
      },
      {
        keywords: [
          "men holding hands",
          "pair",
          "couple",
          "love",
          "like",
          "bromance",
          "friendship",
          "people",
          "human",
          "two men holding hands",
        ],
        name: "two men holding hands",
        order: 308,
        unified: "1F46C",
        emoji: "👬",
      },
      {
        keywords: [
          "kiss woman man",
          "love",
          "woman kiss man",
          "kiss: woman, man",
        ],
        name: "kiss: woman, man",
        order: 310,
        unified: "1F469-200D-2764-FE0F-200D-1F48B-200D-1F468",
        emoji: "👩‍❤️‍💋‍👨",
      },
      {
        keywords: [
          "kiss man man",
          "pair",
          "valentines",
          "love",
          "like",
          "dating",
          "marriage",
          "man kiss man",
          "kiss: man, man",
        ],
        name: "kiss: man, man",
        order: 311,
        unified: "1F468-200D-2764-FE0F-200D-1F48B-200D-1F468",
        emoji: "👨‍❤️‍💋‍👨",
      },
      {
        keywords: [
          "kiss woman woman",
          "pair",
          "valentines",
          "love",
          "like",
          "dating",
          "marriage",
          "woman kiss woman",
          "kiss: woman, woman",
        ],
        name: "kiss: woman, woman",
        order: 312,
        unified: "1F469-200D-2764-FE0F-200D-1F48B-200D-1F469",
        emoji: "👩‍❤️‍💋‍👩",
      },
      {
        keywords: [
          "couple with heart woman man",
          "love",
          "woman heart man",
          "couple with heart: woman, man",
        ],
        name: "couple with heart: woman, man",
        order: 314,
        unified: "1F469-200D-2764-FE0F-200D-1F468",
        emoji: "👩‍❤️‍👨",
      },
      {
        keywords: [
          "couple with heart man man",
          "pair",
          "love",
          "like",
          "affection",
          "human",
          "dating",
          "valentines",
          "marriage",
          "man heart man",
          "couple with heart: man, man",
        ],
        name: "couple with heart: man, man",
        order: 315,
        unified: "1F468-200D-2764-FE0F-200D-1F468",
        emoji: "👨‍❤️‍👨",
      },
      {
        keywords: [
          "couple with heart woman woman",
          "pair",
          "love",
          "like",
          "affection",
          "human",
          "dating",
          "valentines",
          "marriage",
          "woman heart woman",
          "couple with heart: woman, woman",
        ],
        name: "couple with heart: woman, woman",
        order: 316,
        unified: "1F469-200D-2764-FE0F-200D-1F469",
        emoji: "👩‍❤️‍👩",
      },
      {
        keywords: [
          "family man woman boy",
          "love",
          "man woman boy",
          "family: man, woman, boy",
        ],
        name: "family: man, woman, boy",
        order: 318,
        unified: "1F468-200D-1F469-200D-1F466",
        emoji: "👨‍👩‍👦",
      },
      {
        keywords: [
          "family man woman girl",
          "home",
          "parents",
          "people",
          "human",
          "child",
          "man woman girl",
          "family: man, woman, girl",
        ],
        name: "family: man, woman, girl",
        order: 319,
        unified: "1F468-200D-1F469-200D-1F467",
        emoji: "👨‍👩‍👧",
      },
      {
        keywords: [
          "family man woman girl boy",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "man woman girl boy",
          "family: man, woman, girl, boy",
        ],
        name: "family: man, woman, girl, boy",
        order: 320,
        unified: "1F468-200D-1F469-200D-1F467-200D-1F466",
        emoji: "👨‍👩‍👧‍👦",
      },
      {
        keywords: [
          "family man woman boy boy",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "man woman boy boy",
          "family: man, woman, boy, boy",
        ],
        name: "family: man, woman, boy, boy",
        order: 321,
        unified: "1F468-200D-1F469-200D-1F466-200D-1F466",
        emoji: "👨‍👩‍👦‍👦",
      },
      {
        keywords: [
          "family man woman girl girl",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "man woman girl girl",
          "family: man, woman, girl, girl",
        ],
        name: "family: man, woman, girl, girl",
        order: 322,
        unified: "1F468-200D-1F469-200D-1F467-200D-1F467",
        emoji: "👨‍👩‍👧‍👧",
      },
      {
        keywords: [
          "family man man boy",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "man man boy",
          "family: man, man, boy",
        ],
        name: "family: man, man, boy",
        order: 323,
        unified: "1F468-200D-1F468-200D-1F466",
        emoji: "👨‍👨‍👦",
      },
      {
        keywords: [
          "family man man girl",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "man man girl",
          "family: man, man, girl",
        ],
        name: "family: man, man, girl",
        order: 324,
        unified: "1F468-200D-1F468-200D-1F467",
        emoji: "👨‍👨‍👧",
      },
      {
        keywords: [
          "family man man girl boy",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "man man girl boy",
          "family: man, man, girl, boy",
        ],
        name: "family: man, man, girl, boy",
        order: 325,
        unified: "1F468-200D-1F468-200D-1F467-200D-1F466",
        emoji: "👨‍👨‍👧‍👦",
      },
      {
        keywords: [
          "family man man boy boy",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "man man boy boy",
          "family: man, man, boy, boy",
        ],
        name: "family: man, man, boy, boy",
        order: 326,
        unified: "1F468-200D-1F468-200D-1F466-200D-1F466",
        emoji: "👨‍👨‍👦‍👦",
      },
      {
        keywords: [
          "family man man girl girl",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "man man girl girl",
          "family: man, man, girl, girl",
        ],
        name: "family: man, man, girl, girl",
        order: 327,
        unified: "1F468-200D-1F468-200D-1F467-200D-1F467",
        emoji: "👨‍👨‍👧‍👧",
      },
      {
        keywords: [
          "family woman woman boy",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "woman woman boy",
          "family: woman, woman, boy",
        ],
        name: "family: woman, woman, boy",
        order: 328,
        unified: "1F469-200D-1F469-200D-1F466",
        emoji: "👩‍👩‍👦",
      },
      {
        keywords: [
          "family woman woman girl",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "woman woman girl",
          "family: woman, woman, girl",
        ],
        name: "family: woman, woman, girl",
        order: 329,
        unified: "1F469-200D-1F469-200D-1F467",
        emoji: "👩‍👩‍👧",
      },
      {
        keywords: [
          "family woman woman girl boy",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "woman woman girl boy",
          "family: woman, woman, girl, boy",
        ],
        name: "family: woman, woman, girl, boy",
        order: 330,
        unified: "1F469-200D-1F469-200D-1F467-200D-1F466",
        emoji: "👩‍👩‍👧‍👦",
      },
      {
        keywords: [
          "family woman woman boy boy",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "woman woman boy boy",
          "family: woman, woman, boy, boy",
        ],
        name: "family: woman, woman, boy, boy",
        order: 331,
        unified: "1F469-200D-1F469-200D-1F466-200D-1F466",
        emoji: "👩‍👩‍👦‍👦",
      },
      {
        keywords: [
          "family woman woman girl girl",
          "home",
          "parents",
          "people",
          "human",
          "children",
          "woman woman girl girl",
          "family: woman, woman, girl, girl",
        ],
        name: "family: woman, woman, girl, girl",
        order: 332,
        unified: "1F469-200D-1F469-200D-1F467-200D-1F467",
        emoji: "👩‍👩‍👧‍👧",
      },
      {
        keywords: [
          "family man boy",
          "home",
          "parent",
          "people",
          "human",
          "child",
          "man boy",
          "family: man, boy",
        ],
        name: "family: man, boy",
        order: 333,
        unified: "1F468-200D-1F466",
        emoji: "👨‍👦",
      },
      {
        keywords: [
          "family man boy boy",
          "home",
          "parent",
          "people",
          "human",
          "children",
          "man boy boy",
          "family: man, boy, boy",
        ],
        name: "family: man, boy, boy",
        order: 334,
        unified: "1F468-200D-1F466-200D-1F466",
        emoji: "👨‍👦‍👦",
      },
      {
        keywords: [
          "family man girl",
          "home",
          "parent",
          "people",
          "human",
          "child",
          "man girl",
          "family: man, girl",
        ],
        name: "family: man, girl",
        order: 335,
        unified: "1F468-200D-1F467",
        emoji: "👨‍👧",
      },
      {
        keywords: [
          "family man girl boy",
          "home",
          "parent",
          "people",
          "human",
          "children",
          "man girl boy",
          "family: man, girl, boy",
        ],
        name: "family: man, girl, boy",
        order: 336,
        unified: "1F468-200D-1F467-200D-1F466",
        emoji: "👨‍👧‍👦",
      },
      {
        keywords: [
          "family man girl girl",
          "home",
          "parent",
          "people",
          "human",
          "children",
          "man girl girl",
          "family: man, girl, girl",
        ],
        name: "family: man, girl, girl",
        order: 337,
        unified: "1F468-200D-1F467-200D-1F467",
        emoji: "👨‍👧‍👧",
      },
      {
        keywords: [
          "family woman boy",
          "home",
          "parent",
          "people",
          "human",
          "child",
          "woman boy",
          "family: woman, boy",
        ],
        name: "family: woman, boy",
        order: 338,
        unified: "1F469-200D-1F466",
        emoji: "👩‍👦",
      },
      {
        keywords: [
          "family woman boy boy",
          "home",
          "parent",
          "people",
          "human",
          "children",
          "woman boy boy",
          "family: woman, boy, boy",
        ],
        name: "family: woman, boy, boy",
        order: 339,
        unified: "1F469-200D-1F466-200D-1F466",
        emoji: "👩‍👦‍👦",
      },
      {
        keywords: [
          "family woman girl",
          "home",
          "parent",
          "people",
          "human",
          "child",
          "woman girl",
          "family: woman, girl",
        ],
        name: "family: woman, girl",
        order: 340,
        unified: "1F469-200D-1F467",
        emoji: "👩‍👧",
      },
      {
        keywords: [
          "family woman girl boy",
          "home",
          "parent",
          "people",
          "human",
          "children",
          "woman girl boy",
          "family: woman, girl, boy",
        ],
        name: "family: woman, girl, boy",
        order: 341,
        unified: "1F469-200D-1F467-200D-1F466",
        emoji: "👩‍👧‍👦",
      },
      {
        keywords: [
          "family woman girl girl",
          "home",
          "parent",
          "people",
          "human",
          "children",
          "woman girl girl",
          "family: woman, girl, girl",
        ],
        name: "family: woman, girl, girl",
        order: 342,
        unified: "1F469-200D-1F467-200D-1F467",
        emoji: "👩‍👧‍👧",
      },
      {
        keywords: [
          "speaking head",
          "user",
          "person",
          "human",
          "sing",
          "say",
          "talk",
          "speaking head in silhouette",
        ],
        name: "speaking head",
        order: 343,
        unified: "1F5E3-FE0F",
        emoji: "🗣️",
      },
      {
        keywords: ["bust in silhouette", "user", "person", "human"],
        name: "bust in silhouette",
        order: 344,
        unified: "1F464",
        emoji: "👤",
      },
      {
        keywords: [
          "busts in silhouette",
          "user",
          "person",
          "human",
          "group",
          "team",
        ],
        name: "busts in silhouette",
        order: 345,
        unified: "1F465",
        emoji: "👥",
      },
      {
        keywords: ["people hugging", "care"],
        name: "people hugging",
        order: 346,
        unified: "1FAC2",
        emoji: "🫂",
      },
      {
        keywords: [
          "footprints",
          "footprint",
          "feet",
          "tracking",
          "walking",
          "beach",
        ],
        name: "footprints",
        order: 347,
        unified: "1F463",
        emoji: "👣",
      },
    ],
  },
  {
    title: "animals",
    data: [
      {
        keywords: [
          "monkey",
          "monkeys",
          "monkey face",
          "animal",
          "nature",
          "circus",
        ],
        name: "monkey face",
        order: 1,
        unified: "1F435",
        emoji: "🐵",
      },
      {
        keywords: ["monkey", "monkeys", "animal", "nature", "banana", "circus"],
        name: "monkey",
        order: 2,
        unified: "1F412",
        emoji: "🐒",
      },
      {
        keywords: [
          "gorilla",
          "ape",
          "gorillas",
          "apes",
          "animal",
          "nature",
          "circus",
        ],
        name: "gorilla",
        order: 3,
        unified: "1F98D",
        emoji: "🦍",
      },
      {
        keywords: ["orangutan", "animal"],
        name: "orangutan",
        order: 4,
        unified: "1F9A7",
        emoji: "🦧",
      },
      {
        keywords: [
          "puppy",
          "dog",
          "puppies",
          "dogs",
          "doggie",
          "dog face",
          "animal",
          "friend",
          "nature",
          "woof",
          "pet",
          "faithful",
        ],
        name: "dog face",
        order: 5,
        unified: "1F436",
        emoji: "🐶",
      },
      {
        keywords: [
          "dog",
          "dogs",
          "animal",
          "nature",
          "friend",
          "doge",
          "pet",
          "faithful",
          "dog2",
        ],
        name: "dog",
        order: 6,
        unified: "1F415",
        emoji: "🐕",
      },
      {
        keywords: ["guide dog", "animal", "blind"],
        name: "guide dog",
        order: 7,
        unified: "1F9AE",
        emoji: "🦮",
      },
      {
        keywords: ["service dog", "blind", "animal"],
        name: "service dog",
        order: 8,
        unified: "1F415-200D-1F9BA",
        emoji: "🐕‍🦺",
      },
      {
        keywords: [
          "poodle",
          "poodles",
          "dog",
          "animal",
          "101",
          "nature",
          "pet",
        ],
        name: "poodle",
        order: 9,
        unified: "1F429",
        emoji: "🐩",
      },
      {
        keywords: ["wolf", "wolves", "animal", "nature", "wild", "wolf face"],
        name: "wolf face",
        order: 10,
        unified: "1F43A",
        emoji: "🐺",
      },
      {
        keywords: ["fox", "foxes", "fox face", "animal", "nature", "face"],
        name: "fox face",
        order: 11,
        unified: "1F98A",
        emoji: "🦊",
      },
      {
        keywords: ["raccoon", "animal", "nature"],
        name: "raccoon",
        order: 12,
        unified: "1F99D",
        emoji: "🦝",
      },
      {
        keywords: [
          "kitten",
          "cat",
          "kitty",
          "meow",
          "kittens",
          "cats",
          "kitties",
          "cat face",
          "animal",
          "nature",
          "pet",
        ],
        name: "cat face",
        order: 13,
        unified: "1F431",
        emoji: "🐱",
      },
      {
        keywords: ["cat", "cats", "animal", "meow", "pet", "cat2"],
        name: "cat",
        order: 14,
        unified: "1F408",
        emoji: "🐈",
      },
      {
        keywords: ["black cat", "superstition", "luck"],
        name: "black cat",
        order: 15,
        unified: "1F408-200D-2B1B",
        emoji: "🐈‍⬛",
      },
      {
        keywords: ["lion", "lions", "animal", "nature", "lion face"],
        name: "lion face",
        order: 16,
        unified: "1F981",
        emoji: "🦁",
      },
      {
        keywords: [
          "tiger",
          "roar",
          "rawr",
          "rwar",
          "tigers",
          "tiger face",
          "animal",
          "cat",
          "danger",
          "wild",
          "nature",
        ],
        name: "tiger face",
        order: 17,
        unified: "1F42F",
        emoji: "🐯",
      },
      {
        keywords: ["tiger", "tigers", "animal", "nature", "roar", "tiger2"],
        name: "tiger",
        order: 18,
        unified: "1F405",
        emoji: "🐅",
      },
      {
        keywords: ["leopard", "animal", "nature"],
        name: "leopard",
        order: 19,
        unified: "1F406",
        emoji: "🐆",
      },
      {
        keywords: [
          "horse",
          "horses",
          "horse face",
          "animal",
          "brown",
          "nature",
        ],
        name: "horse face",
        order: 20,
        unified: "1F434",
        emoji: "🐴",
      },
      {
        keywords: [
          "horse",
          "derby",
          "horses",
          "horse race",
          "horse races",
          "animal",
          "gamble",
          "luck",
          "racehorse",
        ],
        name: "horse",
        order: 21,
        unified: "1F40E",
        emoji: "🐎",
      },
      {
        keywords: [
          "unicorn",
          "unicorns",
          "animal",
          "nature",
          "mystical",
          "unicorn face",
        ],
        name: "unicorn face",
        order: 22,
        unified: "1F984",
        emoji: "🦄",
      },
      {
        keywords: [
          "zebra",
          "zebras",
          "animal",
          "nature",
          "stripes",
          "safari",
          "zebra face",
        ],
        name: "zebra face",
        order: 23,
        unified: "1F993",
        emoji: "🦓",
      },
      {
        keywords: ["deer", "animal", "nature", "horns", "venison"],
        name: "deer",
        order: 24,
        unified: "1F98C",
        emoji: "🦌",
      },
      {
        keywords: ["bison", "ox"],
        name: "bison",
        order: 25,
        unified: "1F9AC",
        emoji: "🦬",
      },
      {
        keywords: [
          "cow",
          "cows",
          "cattle",
          "cow face",
          "beef",
          "ox",
          "animal",
          "nature",
          "moo",
          "milk",
        ],
        name: "cow face",
        order: 26,
        unified: "1F42E",
        emoji: "🐮",
      },
      {
        keywords: ["ox", "oxen", "animal", "cow", "beef"],
        name: "ox",
        order: 27,
        unified: "1F402",
        emoji: "🐂",
      },
      {
        keywords: ["water buffalo", "animal", "nature", "ox", "cow"],
        name: "water buffalo",
        order: 28,
        unified: "1F403",
        emoji: "🐃",
      },
      {
        keywords: [
          "cow",
          "cattle",
          "cows",
          "beef",
          "ox",
          "animal",
          "nature",
          "moo",
          "milk",
          "cow2",
        ],
        name: "cow",
        order: 29,
        unified: "1F404",
        emoji: "🐄",
      },
      {
        keywords: [
          "oink",
          "pig",
          "piggy",
          "porky",
          "pigs",
          "piggies",
          "pig face",
          "animal",
          "nature",
        ],
        name: "pig face",
        order: 30,
        unified: "1F437",
        emoji: "🐷",
      },
      {
        keywords: ["pig", "pigs", "animal", "nature", "pig2"],
        name: "pig",
        order: 31,
        unified: "1F416",
        emoji: "🐖",
      },
      {
        keywords: ["boar", "boars", "animal", "nature"],
        name: "boar",
        order: 32,
        unified: "1F417",
        emoji: "🐗",
      },
      {
        keywords: ["pig", "pigs", "pig nose", "animal", "oink"],
        name: "pig nose",
        order: 33,
        unified: "1F43D",
        emoji: "🐽",
      },
      {
        keywords: ["ram", "animal", "sheep", "nature"],
        name: "ram",
        order: 34,
        unified: "1F40F",
        emoji: "🐏",
      },
      {
        keywords: [
          "sheep",
          "ram",
          "rams",
          "ewe",
          "animal",
          "nature",
          "wool",
          "shipit",
        ],
        name: "sheep",
        order: 35,
        unified: "1F411",
        emoji: "🐑",
      },
      {
        keywords: ["goat", "goats", "animal", "nature"],
        name: "goat",
        order: 36,
        unified: "1F410",
        emoji: "🐐",
      },
      {
        keywords: [
          "camel",
          "desert",
          "camels",
          "animal",
          "hot",
          "hump",
          "dromedary camel",
        ],
        name: "dromedary camel",
        order: 37,
        unified: "1F42A",
        emoji: "🐪",
      },
      {
        keywords: [
          "camel",
          "desert",
          "camels",
          "two hump camel",
          "animal",
          "nature",
          "hot",
          "hump",
          "bactrian camel",
        ],
        name: "bactrian camel",
        order: 38,
        unified: "1F42B",
        emoji: "🐫",
      },
      {
        keywords: ["llama", "animal", "nature", "alpaca"],
        name: "llama",
        order: 39,
        unified: "1F999",
        emoji: "🦙",
      },
      {
        keywords: [
          "giraffe",
          "giraffes",
          "animal",
          "nature",
          "spots",
          "safari",
          "giraffe face",
        ],
        name: "giraffe face",
        order: 40,
        unified: "1F992",
        emoji: "🦒",
      },
      {
        keywords: [
          "elephant",
          "dumbo",
          "mammoth",
          "elephants",
          "mammoths",
          "animal",
          "nature",
          "nose",
          "th",
          "circus",
        ],
        name: "elephant",
        order: 41,
        unified: "1F418",
        emoji: "🐘",
      },
      {
        keywords: ["mammoth", "elephant", "tusks"],
        name: "mammoth",
        order: 42,
        unified: "1F9A3",
        emoji: "🦣",
      },
      {
        keywords: [
          "rhinoceros",
          "rhinoceroses",
          "rhino",
          "rhinos",
          "animal",
          "nature",
          "horn",
        ],
        name: "rhinoceros",
        order: 43,
        unified: "1F98F",
        emoji: "🦏",
      },
      {
        keywords: ["hippopotamus", "animal", "nature"],
        name: "hippopotamus",
        order: 44,
        unified: "1F99B",
        emoji: "🦛",
      },
      {
        keywords: [
          "mouse",
          "mice",
          "mouse face",
          "animal",
          "nature",
          "cheese wedge",
          "rodent",
        ],
        name: "mouse face",
        order: 45,
        unified: "1F42D",
        emoji: "🐭",
      },
      {
        keywords: ["mouse", "mice", "animal", "nature", "rodent", "mouse2"],
        name: "mouse",
        order: 46,
        unified: "1F401",
        emoji: "🐁",
      },
      {
        keywords: ["rat", "rats", "animal", "mouse", "rodent"],
        name: "rat",
        order: 47,
        unified: "1F400",
        emoji: "🐀",
      },
      {
        keywords: ["hamster", "hamsters", "animal", "nature", "hamster face"],
        name: "hamster face",
        order: 48,
        unified: "1F439",
        emoji: "🐹",
      },
      {
        keywords: [
          "rabbit",
          "bunny",
          "rabbits",
          "bunnies",
          "rabbit face",
          "animal",
          "nature",
          "pet",
          "spring",
          "magic",
        ],
        name: "rabbit face",
        order: 49,
        unified: "1F430",
        emoji: "🐰",
      },
      {
        keywords: [
          "rabbit",
          "rabbits",
          "animal",
          "nature",
          "pet",
          "magic",
          "spring",
          "rabbit2",
        ],
        name: "rabbit",
        order: 50,
        unified: "1F407",
        emoji: "🐇",
      },
      {
        keywords: ["chipmunk", "animal", "nature", "rodent", "squirrel"],
        name: "chipmunk",
        order: 51,
        unified: "1F43F-FE0F",
        emoji: "🐿️",
      },
      {
        keywords: ["beaver", "animal", "rodent"],
        name: "beaver",
        order: 52,
        unified: "1F9AB",
        emoji: "🦫",
      },
      {
        keywords: ["hedgehog", "hedgehogs", "animal", "nature", "spiny"],
        name: "hedgehog",
        order: 53,
        unified: "1F994",
        emoji: "🦔",
      },
      {
        keywords: ["bat", "bats", "animal", "nature", "blind", "vampire"],
        name: "bat",
        order: 54,
        unified: "1F987",
        emoji: "🦇",
      },
      {
        keywords: ["bear", "bears", "animal", "nature", "wild", "bear face"],
        name: "bear face",
        order: 55,
        unified: "1F43B",
        emoji: "🐻",
      },
      {
        keywords: ["polar bear", "animal", "arctic"],
        name: "polar bear",
        order: 56,
        unified: "1F43B-200D-2744-FE0F",
        emoji: "🐻‍❄️",
      },
      {
        keywords: ["koala", "koalas", "animal", "nature"],
        name: "koala",
        order: 57,
        unified: "1F428",
        emoji: "🐨",
      },
      {
        keywords: ["panda", "pandas", "animal", "nature", "panda face"],
        name: "panda face",
        order: 58,
        unified: "1F43C",
        emoji: "🐼",
      },
      {
        keywords: ["sloth", "animal"],
        name: "sloth",
        order: 59,
        unified: "1F9A5",
        emoji: "🦥",
      },
      {
        keywords: ["otter", "animal"],
        name: "otter",
        order: 60,
        unified: "1F9A6",
        emoji: "🦦",
      },
      {
        keywords: ["skunk", "animal"],
        name: "skunk",
        order: 61,
        unified: "1F9A8",
        emoji: "🦨",
      },
      {
        keywords: [
          "kangaroo",
          "animal",
          "nature",
          "australia",
          "joey",
          "hop",
          "marsupial",
        ],
        name: "kangaroo",
        order: 62,
        unified: "1F998",
        emoji: "🦘",
      },
      {
        keywords: ["badger", "animal", "nature", "honey"],
        name: "badger",
        order: 63,
        unified: "1F9A1",
        emoji: "🦡",
      },
      {
        keywords: [
          "paw",
          "paws",
          "paw print",
          "paw prints",
          "animal",
          "tracking",
          "footprints",
          "dog",
          "cat",
          "pet",
          "feet",
        ],
        name: "paw prints",
        order: 64,
        unified: "1F43E",
        emoji: "🐾",
      },
      {
        keywords: ["turkey", "turkeys", "animal", "bird"],
        name: "turkey",
        order: 65,
        unified: "1F983",
        emoji: "🦃",
      },
      {
        keywords: [
          "chicken",
          "rooster",
          "chickens",
          "roosters",
          "animal",
          "cluck",
          "nature",
          "bird",
        ],
        name: "chicken",
        order: 66,
        unified: "1F414",
        emoji: "🐔",
      },
      {
        keywords: [
          "rooster",
          "roosters",
          "chicken",
          "chickens",
          "animal",
          "nature",
        ],
        name: "rooster",
        order: 67,
        unified: "1F413",
        emoji: "🐓",
      },
      {
        keywords: [
          "chick",
          "baby duckling",
          "easter",
          "chicks",
          "baby ducklings",
          "hatch",
          "hatching",
          "hatched",
          "hatching chick",
          "animal",
          "chicken",
          "egg",
          "born",
          "baby",
          "bird",
        ],
        name: "hatching chick",
        order: 68,
        unified: "1F423",
        emoji: "🐣",
      },
      {
        keywords: [
          "chick",
          "chicks",
          "baby chick",
          "baby chicks",
          "chickadee",
          "chickadees",
          "animal",
          "chicken",
          "bird",
        ],
        name: "baby chick",
        order: 69,
        unified: "1F424",
        emoji: "🐤",
      },
      {
        keywords: [
          "duckling",
          "chicks",
          "ducklings",
          "chick",
          "chickadee",
          "chickadees",
          "front facing baby chick",
          "animal",
          "chicken",
          "baby",
          "bird",
          "hatched chick",
        ],
        name: "front-facing baby chick",
        order: 70,
        unified: "1F425",
        emoji: "🐥",
      },
      {
        keywords: [
          "bird",
          "birds",
          "animal",
          "nature",
          "fly",
          "tweet",
          "spring",
        ],
        name: "bird",
        order: 71,
        unified: "1F426",
        emoji: "🐦",
      },
      {
        keywords: ["penguin", "penguins", "animal", "nature"],
        name: "penguin",
        order: 72,
        unified: "1F427",
        emoji: "🐧",
      },
      {
        keywords: ["dove", "animal", "bird", "dove of peace"],
        name: "dove",
        order: 73,
        unified: "1F54A-FE0F",
        emoji: "🕊️",
      },
      {
        keywords: ["eagle", "eagles", "bird", "birds", "animal", "nature"],
        name: "eagle",
        order: 74,
        unified: "1F985",
        emoji: "🦅",
      },
      {
        keywords: [
          "duck",
          "ducks",
          "mallard",
          "mallards",
          "animal",
          "nature",
          "bird",
        ],
        name: "duck",
        order: 75,
        unified: "1F986",
        emoji: "🦆",
      },
      {
        keywords: ["swan", "animal", "nature", "bird"],
        name: "swan",
        order: 76,
        unified: "1F9A2",
        emoji: "🦢",
      },
      {
        keywords: ["owl", "owls", "animal", "nature", "bird", "hoot"],
        name: "owl",
        order: 77,
        unified: "1F989",
        emoji: "🦉",
      },
      {
        keywords: ["dodo", "animal", "bird"],
        name: "dodo",
        order: 78,
        unified: "1F9A4",
        emoji: "🦤",
      },
      {
        keywords: ["feather", "bird", "fly"],
        name: "feather",
        order: 79,
        unified: "1FAB6",
        emoji: "🪶",
      },
      {
        keywords: ["flamingo", "animal"],
        name: "flamingo",
        order: 80,
        unified: "1F9A9",
        emoji: "🦩",
      },
      {
        keywords: ["peacock", "animal", "nature", "peahen", "bird"],
        name: "peacock",
        order: 81,
        unified: "1F99A",
        emoji: "🦚",
      },
      {
        keywords: ["parrot", "animal", "nature", "bird", "pirate", "talk"],
        name: "parrot",
        order: 82,
        unified: "1F99C",
        emoji: "🦜",
      },
      {
        keywords: [
          "frog",
          "frogs",
          "animal",
          "nature",
          "croak",
          "toad",
          "frog face",
        ],
        name: "frog face",
        order: 83,
        unified: "1F438",
        emoji: "🐸",
      },
      {
        keywords: [
          "crocodile",
          "animal",
          "nature",
          "reptile",
          "lizard",
          "alligator",
        ],
        name: "crocodile",
        order: 84,
        unified: "1F40A",
        emoji: "🐊",
      },
      {
        keywords: ["turtle", "turtles", "animal", "slow", "nature", "tortoise"],
        name: "turtle",
        order: 85,
        unified: "1F422",
        emoji: "🐢",
      },
      {
        keywords: [
          "lizard",
          "lizards",
          "gecko",
          "geckos",
          "iguana",
          "iguanas",
          "reptile",
          "reptiles",
          "animal",
          "nature",
        ],
        name: "lizard",
        order: 86,
        unified: "1F98E",
        emoji: "🦎",
      },
      {
        keywords: [
          "snake",
          "python",
          "cobra",
          "snakes",
          "pythons",
          "cobras",
          "animal",
          "evil",
          "nature",
          "hiss",
        ],
        name: "snake",
        order: 87,
        unified: "1F40D",
        emoji: "🐍",
      },
      {
        keywords: [
          "dragon head",
          "dragon heads",
          "dragon face",
          "animal",
          "myth",
          "nature",
          "chinese",
          "green",
        ],
        name: "dragon face",
        order: 88,
        unified: "1F432",
        emoji: "🐲",
      },
      {
        keywords: [
          "dragon",
          "dragons",
          "animal",
          "myth",
          "nature",
          "chinese",
          "green",
        ],
        name: "dragon",
        order: 89,
        unified: "1F409",
        emoji: "🐉",
      },
      {
        keywords: [
          "dinosaur",
          "dinosaurs",
          "sauropod",
          "brachiosaurus",
          "apatosaurus",
          "brontosaurus",
          "animal",
          "nature",
          "diplodocus",
          "extinct",
        ],
        name: "sauropod",
        order: 90,
        unified: "1F995",
        emoji: "🦕",
      },
      {
        keywords: [
          "dinosaur",
          "dinosaurs",
          "t rex",
          "tyrannosaurus rex",
          "animal",
          "nature",
          "tyrannosaurus",
          "extinct",
        ],
        name: "t-rex",
        order: 91,
        unified: "1F996",
        emoji: "🦖",
      },
      {
        keywords: [
          "whale",
          "whales",
          "spouting whale",
          "animal",
          "nature",
          "sea",
          "ocean",
        ],
        name: "spouting whale",
        order: 92,
        unified: "1F433",
        emoji: "🐳",
      },
      {
        keywords: [
          "whale",
          "humpback",
          "whales",
          "humpbacks",
          "humpback whale",
          "humpback whales",
          "animal",
          "nature",
          "sea",
          "ocean",
          "whale2",
        ],
        name: "whale",
        order: 93,
        unified: "1F40B",
        emoji: "🐋",
      },
      {
        keywords: [
          "dolphin",
          "porpoise",
          "dolphins",
          "porpoises",
          "animal",
          "nature",
          "fish",
          "sea",
          "ocean",
          "flipper",
          "fins",
          "beach",
        ],
        name: "dolphin",
        order: 94,
        unified: "1F42C",
        emoji: "🐬",
      },
      {
        keywords: ["seal", "animal", "creature", "sea"],
        name: "seal",
        order: 95,
        unified: "1F9AD",
        emoji: "🦭",
      },
      {
        keywords: ["fish", "animal", "food", "nature"],
        name: "fish",
        order: 96,
        unified: "1F41F",
        emoji: "🐟",
      },
      {
        keywords: [
          "fish",
          "tropical",
          "tropical fish",
          "animal",
          "swim",
          "ocean",
          "beach",
          "nemo",
        ],
        name: "tropical fish",
        order: 97,
        unified: "1F420",
        emoji: "🐠",
      },
      {
        keywords: ["blowfish", "animal", "nature", "food", "sea", "ocean"],
        name: "blowfish",
        order: 98,
        unified: "1F421",
        emoji: "🐡",
      },
      {
        keywords: [
          "shark",
          "sharks",
          "animal",
          "nature",
          "fish",
          "sea",
          "ocean",
          "jaws",
          "fins",
          "beach",
        ],
        name: "shark",
        order: 99,
        unified: "1F988",
        emoji: "🦈",
      },
      {
        keywords: [
          "tentacle",
          "octopus",
          "octopuses",
          "octopi",
          "tentacles",
          "animal",
          "creature",
          "ocean",
          "sea",
          "nature",
          "beach",
        ],
        name: "octopus",
        order: 100,
        unified: "1F419",
        emoji: "🐙",
      },
      {
        keywords: [
          "shell",
          "hermit crab",
          "snail shell",
          "shells",
          "hermit crabs",
          "snail shells",
          "spiral shell",
          "nature",
          "sea",
          "beach",
        ],
        name: "spiral shell",
        order: 101,
        unified: "1F41A",
        emoji: "🐚",
      },
      {
        keywords: [
          "snail",
          "escargot",
          "slug",
          "snails",
          "slugs",
          "slow",
          "animal",
          "shell",
        ],
        name: "snail",
        order: 102,
        unified: "1F40C",
        emoji: "🐌",
      },
      {
        keywords: [
          "butterfly",
          "butterflies",
          "blue butterfly",
          "animal",
          "insect",
          "nature",
          "caterpillar",
        ],
        name: "butterfly",
        order: 103,
        unified: "1F98B",
        emoji: "🦋",
      },
      {
        keywords: [
          "bug",
          "worm",
          "caterpillar",
          "bugs",
          "worms",
          "caterpillars",
          "animal",
          "insect",
          "nature",
        ],
        name: "bug",
        order: 104,
        unified: "1F41B",
        emoji: "🐛",
      },
      {
        keywords: [
          "ant",
          "bug",
          "insect",
          "ants",
          "bugs",
          "insects",
          "animal",
          "nature",
        ],
        name: "ant",
        order: 105,
        unified: "1F41C",
        emoji: "🐜",
      },
      {
        keywords: [
          "bzz",
          "bzzz",
          "bzzzz",
          "bzzzzz",
          "bumble bee",
          "bee",
          "hornet",
          "yellow jacket",
          "bumble bees",
          "bees",
          "hornets",
          "yellow jackets",
          "honeybee",
          "animal",
          "insect",
          "nature",
          "bug",
          "spring",
          "honey",
        ],
        name: "honeybee",
        order: 106,
        unified: "1F41D",
        emoji: "🐝",
      },
      {
        keywords: ["beetle", "insect"],
        name: "beetle",
        order: 107,
        unified: "1FAB2",
        emoji: "🪲",
      },
      {
        keywords: [
          "ladybug",
          "insect",
          "bug",
          "ladybugs",
          "insects",
          "bugs",
          "lady beetle",
          "animal",
          "nature",
        ],
        name: "lady beetle",
        order: 108,
        unified: "1F41E",
        emoji: "🐞",
      },
      {
        keywords: ["cricket", "crickets", "animal", "chirp"],
        name: "cricket",
        order: 109,
        unified: "1F997",
        emoji: "🦗",
      },
      {
        keywords: ["cockroach", "insect", "pests"],
        name: "cockroach",
        order: 110,
        unified: "1FAB3",
        emoji: "🪳",
      },
      {
        keywords: ["spider", "animal", "arachnid"],
        name: "spider",
        order: 111,
        unified: "1F577-FE0F",
        emoji: "🕷️",
      },
      {
        keywords: ["spider web", "animal", "insect", "arachnid", "silk"],
        name: "spider web",
        order: 112,
        unified: "1F578-FE0F",
        emoji: "🕸️",
      },
      {
        keywords: ["scorpion", "scorpions", "animal", "arachnid"],
        name: "scorpion",
        order: 113,
        unified: "1F982",
        emoji: "🦂",
      },
      {
        keywords: ["mosquito", "animal", "nature", "insect", "malaria"],
        name: "mosquito",
        order: 114,
        unified: "1F99F",
        emoji: "🦟",
      },
      {
        keywords: ["fly", "insect"],
        name: "fly",
        order: 115,
        unified: "1FAB0",
        emoji: "🪰",
      },
      {
        keywords: ["worm", "animal"],
        name: "worm",
        order: 116,
        unified: "1FAB1",
        emoji: "🪱",
      },
      {
        keywords: ["microbe", "amoeba", "bacteria", "germs", "virus"],
        name: "microbe",
        order: 117,
        unified: "1F9A0",
        emoji: "🦠",
      },
      {
        keywords: [
          "bouquet",
          "flower",
          "tulip",
          "bouquets",
          "flowers",
          "tulips",
          "nature",
          "spring",
        ],
        name: "bouquet",
        order: 118,
        unified: "1F490",
        emoji: "💐",
      },
      {
        keywords: [
          "cherry blossom",
          "cherry blossoms",
          "nature",
          "plant",
          "spring",
          "flower",
        ],
        name: "cherry blossom",
        order: 119,
        unified: "1F338",
        emoji: "🌸",
      },
      {
        keywords: ["white flower", "white flowers", "japanese", "spring"],
        name: "white flower",
        order: 120,
        unified: "1F4AE",
        emoji: "💮",
      },
      {
        keywords: ["rosette", "flower", "decoration", "military"],
        name: "rosette",
        order: 121,
        unified: "1F3F5-FE0F",
        emoji: "🏵️",
      },
      {
        keywords: [
          "rose",
          "flower",
          "red rose",
          "roses",
          "flowers",
          "red roses",
          "valentines",
          "love",
          "spring",
        ],
        name: "rose",
        order: 122,
        unified: "1F339",
        emoji: "🌹",
      },
      {
        keywords: [
          "rose",
          "roses",
          "flower",
          "flowers",
          "dying flower",
          "dying rose",
          "wilting",
          "wilted",
          "wilted flower",
          "wilted rose",
          "red rose",
          "plant",
          "nature",
        ],
        name: "wilted flower",
        order: 123,
        unified: "1F940",
        emoji: "🥀",
      },
      {
        keywords: [
          "azalea",
          "flower",
          "hawaiian",
          "pua aloalo",
          "hibiscus",
          "azaleas",
          "flowers",
          "hibiscuses",
          "plant",
          "vegetable",
          "beach",
        ],
        name: "hibiscus",
        order: 124,
        unified: "1F33A",
        emoji: "🌺",
      },
      {
        keywords: ["sunflower", "sunflowers", "nature", "plant", "fall"],
        name: "sunflower",
        order: 125,
        unified: "1F33B",
        emoji: "🌻",
      },
      {
        keywords: [
          "flower",
          "daffodil",
          "flowers",
          "daffodils",
          "blossom",
          "nature",
          "yellow",
        ],
        name: "blossom",
        order: 126,
        unified: "1F33C",
        emoji: "🌼",
      },
      {
        keywords: [
          "tulip",
          "flower",
          "tulips",
          "flowers",
          "plant",
          "nature",
          "summer",
          "spring",
        ],
        name: "tulip",
        order: 127,
        unified: "1F337",
        emoji: "🌷",
      },
      {
        keywords: [
          "seedling",
          "plants",
          "seedlings",
          "vegan",
          "vegetarian",
          "plant",
          "nature",
          "grass",
          "lawn",
          "spring",
        ],
        name: "seedling",
        order: 128,
        unified: "1F331",
        emoji: "🌱",
      },
      {
        keywords: ["potted plant", "greenery", "house"],
        name: "potted plant",
        order: 129,
        unified: "1FAB4",
        emoji: "🪴",
      },
      {
        keywords: [
          "tree",
          "evergreen",
          "pine",
          "fir",
          "trees",
          "evergreens",
          "pine tree",
          "firs",
          "evergreen tree",
          "plant",
          "nature",
        ],
        name: "evergreen tree",
        order: 130,
        unified: "1F332",
        emoji: "🌲",
      },
      {
        keywords: [
          "tree",
          "elm",
          "aspen",
          "birch",
          "forest",
          "trees",
          "elms",
          "aspens",
          "birches",
          "forests",
          "deciduous tree",
          "plant",
          "nature",
        ],
        name: "deciduous tree",
        order: 131,
        unified: "1F333",
        emoji: "🌳",
      },
      {
        keywords: [
          "tropical",
          "coconut",
          "date tree",
          "palm tree",
          "island",
          "coconut tree",
          "coconuts",
          "islands",
          "coconut trees",
          "date trees",
          "palm trees",
          "plant",
          "vegetable",
          "nature",
          "summer",
          "beach",
          "mojito",
        ],
        name: "palm tree",
        order: 132,
        unified: "1F334",
        emoji: "🌴",
      },
      {
        keywords: [
          "cactus",
          "desert",
          "cactuses",
          "cacti",
          "deserts",
          "vegetable",
          "plant",
          "nature",
        ],
        name: "cactus",
        order: 133,
        unified: "1F335",
        emoji: "🌵",
      },
      {
        keywords: [
          "corn",
          "wheat",
          "sheaf of rice",
          "nature",
          "plant",
          "ear of rice",
        ],
        name: "ear of rice",
        order: 134,
        unified: "1F33E",
        emoji: "🌾",
      },
      {
        keywords: [
          "basil",
          "oregano",
          "herbs",
          "herb",
          "vegetable",
          "plant",
          "medicine",
          "weed",
          "grass",
          "lawn",
        ],
        name: "herb",
        order: 135,
        unified: "1F33F",
        emoji: "🌿",
      },
      {
        keywords: [
          "irish",
          "shamrock",
          "three leaf clover",
          "three leaf clovers",
          "vegetable",
          "plant",
          "nature",
          "clover",
        ],
        name: "shamrock",
        order: 136,
        unified: "2618-FE0F",
        emoji: "☘️",
      },
      {
        keywords: [
          "lucky clover",
          "lucky",
          "four leaf clover",
          "four leaf clovers",
          "lucky clovers",
          "leprechaun",
          "vegetable",
          "plant",
          "nature",
          "irish",
        ],
        name: "four leaf clover",
        order: 137,
        unified: "1F340",
        emoji: "🍀",
      },
      {
        keywords: [
          "maple",
          "leaf",
          "leaves",
          "maple leaf",
          "maple leaves",
          "nature",
          "plant",
          "vegetable",
          "ca",
          "fall",
        ],
        name: "maple leaf",
        order: 138,
        unified: "1F341",
        emoji: "🍁",
      },
      {
        keywords: [
          "autumn",
          "leaf",
          "fallen leaf",
          "nature",
          "plant",
          "vegetable",
          "leaves",
        ],
        name: "fallen leaf",
        order: 139,
        unified: "1F342",
        emoji: "🍂",
      },
      {
        keywords: [
          "leaf",
          "two leaves",
          "leaf fluttering in wind",
          "nature",
          "plant",
          "tree",
          "vegetable",
          "grass",
          "lawn",
          "spring",
          "leaves",
        ],
        name: "leaf fluttering in wind",
        order: 140,
        unified: "1F343",
        emoji: "🍃",
      },
    ],
  },
  {
    title: "food",
    data: [
      {
        keywords: ["grapes", "fruit", "grape", "fruits", "food", "wine"],
        name: "grapes",
        order: 1,
        unified: "1F347",
        emoji: "🍇",
      },
      {
        keywords: [
          "honeydew",
          "cantaloupe",
          "melon",
          "fruit",
          "honeydews",
          "cantaloupes",
          "melons",
          "fruits",
          "nature",
          "food",
        ],
        name: "melon",
        order: 2,
        unified: "1F348",
        emoji: "🍈",
      },
      {
        keywords: [
          "fruit",
          "watermelon",
          "melon",
          "fruits",
          "watermelons",
          "melons",
          "food",
          "picnic",
          "summer",
        ],
        name: "watermelon",
        order: 3,
        unified: "1F349",
        emoji: "🍉",
      },
      {
        keywords: [
          "orange",
          "tangerine",
          "fruit",
          "citrus",
          "oranges",
          "tangerines",
          "fruits",
          "food",
          "nature",
        ],
        name: "tangerine",
        order: 4,
        unified: "1F34A",
        emoji: "🍊",
      },
      {
        keywords: ["lemon", "fruit", "lemons", "fruits", "nature"],
        name: "lemon",
        order: 5,
        unified: "1F34B",
        emoji: "🍋",
      },
      {
        keywords: ["banana", "fruit", "bananas", "fruits", "food", "monkey"],
        name: "banana",
        order: 6,
        unified: "1F34C",
        emoji: "🍌",
      },
      {
        keywords: [
          "pineapple",
          "fruit",
          "pineapples",
          "fruits",
          "nature",
          "food",
        ],
        name: "pineapple",
        order: 7,
        unified: "1F34D",
        emoji: "🍍",
      },
      {
        keywords: ["mango", "fruit", "food", "tropical"],
        name: "mango",
        order: 8,
        unified: "1F96D",
        emoji: "🥭",
      },
      {
        keywords: [
          "apple",
          "fruit",
          "apples",
          "fruits",
          "red apple",
          "mac",
          "school",
        ],
        name: "red apple",
        order: 9,
        unified: "1F34E",
        emoji: "🍎",
      },
      {
        keywords: [
          "apple",
          "fruit",
          "fruits",
          "apples",
          "green apple",
          "nature",
        ],
        name: "green apple",
        order: 10,
        unified: "1F34F",
        emoji: "🍏",
      },
      {
        keywords: ["pear", "fruit", "pears", "fruits", "nature", "food"],
        name: "pear",
        order: 11,
        unified: "1F350",
        emoji: "🍐",
      },
      {
        keywords: ["peach", "fruit", "peaches", "fruits", "nature", "food"],
        name: "peach",
        order: 12,
        unified: "1F351",
        emoji: "🍑",
      },
      {
        keywords: ["fruit", "cherry", "fruits", "cherries", "food"],
        name: "cherries",
        order: 13,
        unified: "1F352",
        emoji: "🍒",
      },
      {
        keywords: [
          "fruit",
          "strawberry",
          "fruits",
          "strawberries",
          "food",
          "nature",
        ],
        name: "strawberry",
        order: 14,
        unified: "1F353",
        emoji: "🍓",
      },
      {
        keywords: ["blueberries", "fruit"],
        name: "blueberries",
        order: 15,
        unified: "1FAD0",
        emoji: "🫐",
      },
      {
        keywords: [
          "kiwi",
          "fruit",
          "kiwi fruit",
          "green fruit",
          "half kiwi",
          "kiwis",
          "fruits",
          "food",
          "kiwifruit",
        ],
        name: "kiwifruit",
        order: 16,
        unified: "1F95D",
        emoji: "🥝",
      },
      {
        keywords: [
          "tomato",
          "tomatoes",
          "tomatos",
          "vegetable",
          "vegetables",
          "veggies",
          "fruit",
          "nature",
          "food",
        ],
        name: "tomato",
        order: 17,
        unified: "1F345",
        emoji: "🍅",
      },
      {
        keywords: ["olive", "fruit"],
        name: "olive",
        order: 18,
        unified: "1FAD2",
        emoji: "🫒",
      },
      {
        keywords: ["coconut", "coconuts", "fruit", "nature", "food", "palm"],
        name: "coconut",
        order: 19,
        unified: "1F965",
        emoji: "🥥",
      },
      {
        keywords: ["avocado", "avocados", "half avocado", "fruit", "food"],
        name: "avocado",
        order: 20,
        unified: "1F951",
        emoji: "🥑",
      },
      {
        keywords: [
          "eggplant",
          "eggplants",
          "vegetable",
          "vegetables",
          "veggies",
          "nature",
          "food",
          "aubergine",
        ],
        name: "aubergine",
        order: 21,
        unified: "1F346",
        emoji: "🍆",
      },
      {
        keywords: [
          "potato",
          "potatoes",
          "spud",
          "spuds",
          "vegetable",
          "vegetables",
          "veggies",
          "food",
          "tuber",
          "vegatable",
          "starch",
        ],
        name: "potato",
        order: 22,
        unified: "1F954",
        emoji: "🥔",
      },
      {
        keywords: [
          "carrot",
          "carrots",
          "vegetable",
          "vegetables",
          "veggies",
          "food",
          "orange",
        ],
        name: "carrot",
        order: 23,
        unified: "1F955",
        emoji: "🥕",
      },
      {
        keywords: [
          "corn",
          "ear of corn",
          "vegetable",
          "vegetables",
          "veggies",
          "food",
          "plant",
          "ear of maize",
        ],
        name: "ear of maize",
        order: 24,
        unified: "1F33D",
        emoji: "🌽",
      },
      {
        keywords: [
          "spicy",
          "pepper",
          "hot",
          "hot pepper",
          "food",
          "chilli",
          "chili",
        ],
        name: "hot pepper",
        order: 25,
        unified: "1F336-FE0F",
        emoji: "🌶️",
      },
      {
        keywords: ["bell pepper", "fruit", "plant"],
        name: "bell pepper",
        order: 26,
        unified: "1FAD1",
        emoji: "🫑",
      },
      {
        keywords: [
          "cucumber",
          "cucumbers",
          "sliced cucumber",
          "sliced cucumbers",
          "vegetable",
          "vegetables",
          "veggies",
          "fruit",
          "food",
          "pickle",
        ],
        name: "cucumber",
        order: 27,
        unified: "1F952",
        emoji: "🥒",
      },
      {
        keywords: [
          "leafy green",
          "food",
          "vegetable",
          "plant",
          "bok choy",
          "cabbage",
          "kale",
          "lettuce",
        ],
        name: "leafy green",
        order: 28,
        unified: "1F96C",
        emoji: "🥬",
      },
      {
        keywords: ["broccoli", "fruit", "food", "vegetable"],
        name: "broccoli",
        order: 29,
        unified: "1F966",
        emoji: "🥦",
      },
      {
        keywords: ["garlic", "food", "spice", "cook"],
        name: "garlic",
        order: 30,
        unified: "1F9C4",
        emoji: "🧄",
      },
      {
        keywords: ["onion", "cook", "food", "spice"],
        name: "onion",
        order: 31,
        unified: "1F9C5",
        emoji: "🧅",
      },
      {
        keywords: ["mushroom", "mushrooms", "plant", "vegetable"],
        name: "mushroom",
        order: 32,
        unified: "1F344",
        emoji: "🍄",
      },
      {
        keywords: ["peanuts", "nut", "nuts", "peanut", "food"],
        name: "peanuts",
        order: 33,
        unified: "1F95C",
        emoji: "🥜",
      },
      {
        keywords: ["chestnut", "chestnuts", "food", "squirrel"],
        name: "chestnut",
        order: 34,
        unified: "1F330",
        emoji: "🌰",
      },
      {
        keywords: ["bread", "food", "wheat", "breakfast", "toast"],
        name: "bread",
        order: 35,
        unified: "1F35E",
        emoji: "🍞",
      },
      {
        keywords: [
          "croissant",
          "croissants",
          "butter croissant",
          "butter croissants",
          "food",
          "bread",
          "french",
        ],
        name: "croissant",
        order: 36,
        unified: "1F950",
        emoji: "🥐",
      },
      {
        keywords: [
          "bread",
          "baguette",
          "baguettes",
          "loaf of bread",
          "french bread",
          "baguette bread",
          "food",
          "french",
        ],
        name: "baguette bread",
        order: 37,
        unified: "1F956",
        emoji: "🥖",
      },
      {
        keywords: ["flatbread", "flour", "food"],
        name: "flatbread",
        order: 38,
        unified: "1FAD3",
        emoji: "🫓",
      },
      {
        keywords: ["pretzel", "pretzels", "food", "bread", "twisted"],
        name: "pretzel",
        order: 39,
        unified: "1F968",
        emoji: "🥨",
      },
      {
        keywords: ["bagel", "food", "bread", "bakery", "schmear"],
        name: "bagel",
        order: 40,
        unified: "1F96F",
        emoji: "🥯",
      },
      {
        keywords: [
          "pancake",
          "flapjack",
          "flapjacks",
          "short stack",
          "pancakes",
          "stack of pancakes",
          "hotcakes",
          "breakfast",
          "breakfast food",
          "food",
        ],
        name: "pancakes",
        order: 41,
        unified: "1F95E",
        emoji: "🥞",
      },
      {
        keywords: ["waffle", "food", "breakfast"],
        name: "waffle",
        order: 42,
        unified: "1F9C7",
        emoji: "🧇",
      },
      {
        keywords: [
          "cheese",
          "cheese wedge",
          "swiss",
          "cheeses",
          "food",
          "chadder",
        ],
        name: "cheese wedge",
        order: 43,
        unified: "1F9C0",
        emoji: "🧀",
      },
      {
        keywords: [
          "meat",
          "bone",
          "bbq",
          "meat on bone",
          "good",
          "food",
          "drumstick",
        ],
        name: "meat on bone",
        order: 44,
        unified: "1F356",
        emoji: "🍖",
      },
      {
        keywords: [
          "chicken",
          "drumstick",
          "bbq",
          "drumsticks",
          "poultry leg",
          "food",
          "meat",
          "bird",
          "turkey",
        ],
        name: "poultry leg",
        order: 45,
        unified: "1F357",
        emoji: "🍗",
      },
      {
        keywords: [
          "cut of meat",
          "steak",
          "steaks",
          "ribeye",
          "beef",
          "meat",
          "food",
          "cow",
          "cut",
          "chop",
          "lambchop",
          "porkchop",
        ],
        name: "cut of meat",
        order: 46,
        unified: "1F969",
        emoji: "🥩",
      },
      {
        keywords: [
          "bacon",
          "strips of bacon",
          "two slices of bacon",
          "slice of bacon",
          "slices of bacon",
          "food",
          "breakfast",
          "pork",
          "pig",
          "meat",
        ],
        name: "bacon",
        order: 47,
        unified: "1F953",
        emoji: "🥓",
      },
      {
        keywords: [
          "burger",
          "hamburger",
          "cheeseburger",
          "sliders",
          "burgers",
          "cheeseburgers",
          "meat",
          "fast food",
          "beef",
          "mcdonalds",
          "burger king",
        ],
        name: "hamburger",
        order: 48,
        unified: "1F354",
        emoji: "🍔",
      },
      {
        keywords: [
          "fries",
          "french fries",
          "french fry",
          "chips",
          "snack",
          "fast food",
        ],
        name: "french fries",
        order: 49,
        unified: "1F35F",
        emoji: "🍟",
      },
      {
        keywords: [
          "pizza",
          "pepperoni",
          "pizza slice",
          "pizzas",
          "food",
          "party",
          "slice of pizza",
        ],
        name: "slice of pizza",
        order: 50,
        unified: "1F355",
        emoji: "🍕",
      },
      {
        keywords: ["hot dog", "hot dogs", "food", "frankfurter", "hotdog"],
        name: "hot dog",
        order: 51,
        unified: "1F32D",
        emoji: "🌭",
      },
      {
        keywords: ["sandwich", "sandwiches", "food", "lunch", "bread"],
        name: "sandwich",
        order: 52,
        unified: "1F96A",
        emoji: "🥪",
      },
      {
        keywords: ["taco", "tacos", "food", "mexican"],
        name: "taco",
        order: 53,
        unified: "1F32E",
        emoji: "🌮",
      },
      {
        keywords: ["burrito", "burritos", "food", "mexican"],
        name: "burrito",
        order: 54,
        unified: "1F32F",
        emoji: "🌯",
      },
      {
        keywords: ["tamale", "food", "masa"],
        name: "tamale",
        order: 55,
        unified: "1FAD4",
        emoji: "🫔",
      },
      {
        keywords: [
          "pita",
          "pitas",
          "gyro",
          "gyros",
          "doner kebab",
          "falafel",
          "stuffed flatbread",
          "lunch",
          "pita sandwich",
          "gyro sandwich",
          "kebab sandwich",
          "falafel sandwich",
          "food",
          "flatbread",
          "stuffed",
        ],
        name: "stuffed flatbread",
        order: 56,
        unified: "1F959",
        emoji: "🥙",
      },
      {
        keywords: ["falafel", "food"],
        name: "falafel",
        order: 57,
        unified: "1F9C6",
        emoji: "🧆",
      },
      {
        keywords: ["egg", "eggs", "food", "chicken", "breakfast"],
        name: "egg",
        order: 58,
        unified: "1F95A",
        emoji: "🥚",
      },
      {
        keywords: [
          "fried egg",
          "frying pan",
          "skillet",
          "cooking",
          "egg",
          "breakfast",
          "fried eggs",
          "eggs",
          "breakfasts",
          "food",
          "kitchen",
        ],
        name: "cooking",
        order: 59,
        unified: "1F373",
        emoji: "🍳",
      },
      {
        keywords: [
          "pan of food",
          "paella",
          "food",
          "meal",
          "cooking",
          "shallow pan",
          "casserole",
          "dinner",
          "shallow pan of food",
        ],
        name: "shallow pan of food",
        order: 60,
        unified: "1F958",
        emoji: "🥘",
      },
      {
        keywords: [
          "soup",
          "pho",
          "noodle",
          "bowl",
          "pot of food",
          "food",
          "meat",
          "stew",
        ],
        name: "pot of food",
        order: 61,
        unified: "1F372",
        emoji: "🍲",
      },
      {
        keywords: ["fondue", "cheese", "pot", "food"],
        name: "fondue",
        order: 62,
        unified: "1FAD5",
        emoji: "🫕",
      },
      {
        keywords: [
          "bowl with spoon",
          "soup",
          "porridge",
          "cereal",
          "food",
          "breakfast",
          "oatmeal",
        ],
        name: "bowl with spoon",
        order: 63,
        unified: "1F963",
        emoji: "🥣",
      },
      {
        keywords: [
          "salad",
          "green salad",
          "lunch",
          "side salad",
          "vegetable",
          "vegetables",
          "veggies",
          "food",
          "healthy",
          "lettuce",
        ],
        name: "green salad",
        order: 64,
        unified: "1F957",
        emoji: "🥗",
      },
      {
        keywords: [
          "popcorn",
          "movie",
          "buttered popcorn",
          "food",
          "movie theater",
          "films",
          "snack",
        ],
        name: "popcorn",
        order: 65,
        unified: "1F37F",
        emoji: "🍿",
      },
      {
        keywords: ["butter", "food", "cook"],
        name: "butter",
        order: 66,
        unified: "1F9C8",
        emoji: "🧈",
      },
      {
        keywords: ["salt", "condiment", "shaker", "salt shaker"],
        name: "salt shaker",
        order: 67,
        unified: "1F9C2",
        emoji: "🧂",
      },
      {
        keywords: ["canned food", "tomato can", "food", "soup"],
        name: "canned food",
        order: 68,
        unified: "1F96B",
        emoji: "🥫",
      },
      {
        keywords: ["bento", "bentos", "bento box", "food", "japanese", "box"],
        name: "bento box",
        order: 69,
        unified: "1F371",
        emoji: "🍱",
      },
      {
        keywords: ["rice cracker", "rice crackers", "food", "japanese"],
        name: "rice cracker",
        order: 70,
        unified: "1F358",
        emoji: "🍘",
      },
      {
        keywords: ["rice ball", "rice balls", "onigiri", "food", "japanese"],
        name: "rice ball",
        order: 71,
        unified: "1F359",
        emoji: "🍙",
      },
      {
        keywords: [
          "rice",
          "rice bowl",
          "cooked rice",
          "food",
          "china",
          "asian",
        ],
        name: "cooked rice",
        order: 72,
        unified: "1F35A",
        emoji: "🍚",
      },
      {
        keywords: [
          "curry",
          "rice",
          "curry rice",
          "food",
          "spicy",
          "hot",
          "indian",
          "curry and rice",
        ],
        name: "curry and rice",
        order: 73,
        unified: "1F35B",
        emoji: "🍛",
      },
      {
        keywords: [
          "soup",
          "noodle",
          "ramen",
          "soba",
          "udon",
          "steaming bowl",
          "food",
          "japanese",
          "chopsticks",
        ],
        name: "steaming bowl",
        order: 74,
        unified: "1F35C",
        emoji: "🍜",
      },
      {
        keywords: [
          "noodles",
          "pasta",
          "spaghetti",
          "food",
          "italian",
          "noodle",
        ],
        name: "spaghetti",
        order: 75,
        unified: "1F35D",
        emoji: "🍝",
      },
      {
        keywords: [
          "sweet potato",
          "sweet potatoes",
          "vegetable",
          "vegetables",
          "veggies",
          "yam",
          "yams",
          "roasted sweet potato",
          "food",
          "nature",
        ],
        name: "roasted sweet potato",
        order: 76,
        unified: "1F360",
        emoji: "🍠",
      },
      {
        keywords: ["oden", "food", "japanese"],
        name: "oden",
        order: 77,
        unified: "1F362",
        emoji: "🍢",
      },
      {
        keywords: [
          "nigiri",
          "maguro",
          "sushi",
          "food",
          "fish",
          "japanese",
          "rice",
        ],
        name: "sushi",
        order: 78,
        unified: "1F363",
        emoji: "🍣",
      },
      {
        keywords: [
          "shrimp",
          "prawns",
          "scampi",
          "prawn",
          "fried shrimp",
          "food",
          "animal",
          "appetizer",
          "summer",
        ],
        name: "fried shrimp",
        order: 79,
        unified: "1F364",
        emoji: "🍤",
      },
      {
        keywords: [
          "fish cake",
          "fish cakes",
          "naruto",
          "fish cake with swirl",
          "food",
          "japan",
          "sea",
          "beach",
          "narutomaki",
          "pink",
          "swirl",
          "kamaboko",
          "surimi",
          "ramen",
          "fish cake with swirl design",
        ],
        name: "fish cake with swirl design",
        order: 80,
        unified: "1F365",
        emoji: "🍥",
      },
      {
        keywords: ["moon cake", "food", "autumn"],
        name: "moon cake",
        order: 81,
        unified: "1F96E",
        emoji: "🥮",
      },
      {
        keywords: [
          "dango",
          "food",
          "dessert",
          "sweet",
          "japanese",
          "barbecue",
          "meat",
        ],
        name: "dango",
        order: 82,
        unified: "1F361",
        emoji: "🍡",
      },
      {
        keywords: [
          "dumpling",
          "dumplings",
          "potsticker",
          "potstickers",
          "food",
          "empanada",
          "pierogi",
        ],
        name: "dumpling",
        order: 83,
        unified: "1F95F",
        emoji: "🥟",
      },
      {
        keywords: ["fortune cookie", "fortune cookies", "food", "prophecy"],
        name: "fortune cookie",
        order: 84,
        unified: "1F960",
        emoji: "🥠",
      },
      {
        keywords: ["takeout box", "takeout", "food", "leftovers"],
        name: "takeout box",
        order: 85,
        unified: "1F961",
        emoji: "🥡",
      },
      {
        keywords: ["crab", "crabs", "animal", "crustacean"],
        name: "crab",
        order: 86,
        unified: "1F980",
        emoji: "🦀",
      },
      {
        keywords: ["lobster", "animal", "nature", "bisque", "claws", "seafood"],
        name: "lobster",
        order: 87,
        unified: "1F99E",
        emoji: "🦞",
      },
      {
        keywords: [
          "shrimp",
          "shellfish",
          "prawn",
          "prawns",
          "animal",
          "ocean",
          "nature",
          "seafood",
        ],
        name: "shrimp",
        order: 88,
        unified: "1F990",
        emoji: "🦐",
      },
      {
        keywords: [
          "squid",
          "giant squid",
          "mollusk",
          "mollusks",
          "tentacles",
          "animal",
          "nature",
          "ocean",
          "sea",
        ],
        name: "squid",
        order: 89,
        unified: "1F991",
        emoji: "🦑",
      },
      {
        keywords: ["oyster", "food"],
        name: "oyster",
        order: 90,
        unified: "1F9AA",
        emoji: "🦪",
      },
      {
        keywords: [
          "ice cream",
          "icecream",
          "yogurt",
          "soft serve",
          "ice cream cone",
          "soft ice cream",
          "food",
          "hot",
          "dessert",
          "summer",
        ],
        name: "soft ice cream",
        order: 91,
        unified: "1F366",
        emoji: "🍦",
      },
      {
        keywords: [
          "gelato",
          "shaved ice",
          "snow cone",
          "snow cones",
          "hot",
          "dessert",
          "summer",
        ],
        name: "shaved ice",
        order: 92,
        unified: "1F367",
        emoji: "🍧",
      },
      {
        keywords: ["gelato", "ice cream", "dessert", "desserts", "food", "hot"],
        name: "ice cream",
        order: 93,
        unified: "1F368",
        emoji: "🍨",
      },
      {
        keywords: [
          "doughnut",
          "doughnuts",
          "donut",
          "donuts",
          "food",
          "dessert",
          "snack",
          "sweet",
        ],
        name: "doughnut",
        order: 94,
        unified: "1F369",
        emoji: "🍩",
      },
      {
        keywords: [
          "cookie",
          "cookies",
          "food",
          "snack",
          "oreo",
          "chocolate",
          "sweet",
          "dessert",
        ],
        name: "cookie",
        order: 95,
        unified: "1F36A",
        emoji: "🍪",
      },
      {
        keywords: [
          "cake",
          "birthday",
          "candles",
          "cakes",
          "birthdays",
          "birthday cake",
          "food",
          "dessert",
        ],
        name: "birthday cake",
        order: 96,
        unified: "1F382",
        emoji: "🎂",
      },
      {
        keywords: [
          "cake",
          "birthday",
          "cakes",
          "birthdays",
          "shortcake",
          "food",
          "dessert",
        ],
        name: "shortcake",
        order: 97,
        unified: "1F370",
        emoji: "🍰",
      },
      {
        keywords: ["cupcake", "food", "dessert", "bakery", "sweet"],
        name: "cupcake",
        order: 98,
        unified: "1F9C1",
        emoji: "🧁",
      },
      {
        keywords: ["pie", "food", "dessert", "pastry"],
        name: "pie",
        order: 99,
        unified: "1F967",
        emoji: "🥧",
      },
      {
        keywords: [
          "chocolate",
          "sweets",
          "chocolates",
          "chocolate bar",
          "chocolate candy",
          "food",
          "snack",
          "dessert",
          "sweet",
        ],
        name: "chocolate bar",
        order: 100,
        unified: "1F36B",
        emoji: "🍫",
      },
      {
        keywords: [
          "sweets",
          "candy",
          "candies",
          "snack",
          "dessert",
          "sweet",
          "lolly",
        ],
        name: "candy",
        order: 101,
        unified: "1F36C",
        emoji: "🍬",
      },
      {
        keywords: [
          "candy",
          "sweets",
          "lollipop",
          "candies",
          "lollipops",
          "food",
          "snack",
          "sweet",
        ],
        name: "lollipop",
        order: 102,
        unified: "1F36D",
        emoji: "🍭",
      },
      {
        keywords: ["flan", "dessert", "desserts", "custard", "food"],
        name: "custard",
        order: 103,
        unified: "1F36E",
        emoji: "🍮",
      },
      {
        keywords: ["honey", "honey pot", "bees", "sweet", "kitchen"],
        name: "honey pot",
        order: 104,
        unified: "1F36F",
        emoji: "🍯",
      },
      {
        keywords: [
          "baby",
          "baby bottle",
          "baby bottles",
          "milk bottle",
          "milk bottles",
          "food",
          "container",
          "milk",
        ],
        name: "baby bottle",
        order: 105,
        unified: "1F37C",
        emoji: "🍼",
      },
      {
        keywords: [
          "milk",
          "glass of milk",
          "dairy",
          "beverage",
          "drink",
          "cow",
        ],
        name: "glass of milk",
        order: 106,
        unified: "1F95B",
        emoji: "🥛",
      },
      {
        keywords: [
          "hot beverage",
          "beverage",
          "caffeine",
          "latte",
          "espresso",
          "coffee",
        ],
        name: "hot beverage",
        order: 107,
        unified: "2615",
        emoji: "☕",
      },
      {
        keywords: ["teapot", "drink", "hot"],
        name: "teapot",
        order: 108,
        unified: "1FAD6",
        emoji: "🫖",
      },
      {
        keywords: [
          "green tea",
          "matcha",
          "tea",
          "teacup without handle",
          "drink",
          "bowl",
          "breakfast",
          "green",
          "british",
        ],
        name: "teacup without handle",
        order: 109,
        unified: "1F375",
        emoji: "🍵",
      },
      {
        keywords: [
          "rice wine",
          "sake bottle",
          "bottle of sake",
          "sake",
          "wine",
          "drink",
          "drunk",
          "beverage",
          "japanese",
          "alcohol",
          "booze",
          "sake bottle and cup",
        ],
        name: "sake bottle and cup",
        order: 110,
        unified: "1F376",
        emoji: "🍶",
      },
      {
        keywords: [
          "champagne",
          "celebration",
          "bottle with popping cork",
          "drink",
          "wine",
          "bottle",
        ],
        name: "bottle with popping cork",
        order: 111,
        unified: "1F37E",
        emoji: "🍾",
      },
      {
        keywords: [
          "wine",
          "alcohol",
          "drink",
          "drinks",
          "wine glass",
          "beverage",
          "drunk",
          "booze",
        ],
        name: "wine glass",
        order: 112,
        unified: "1F377",
        emoji: "🍷",
      },
      {
        keywords: [
          "martini",
          "cocktail",
          "drink",
          "martinis",
          "cocktails",
          "drinks",
          "cocktail glass",
          "drunk",
          "alcohol",
          "beverage",
          "booze",
          "mojito",
        ],
        name: "cocktail glass",
        order: 113,
        unified: "1F378",
        emoji: "🍸",
      },
      {
        keywords: [
          "cocktail",
          "drink",
          "cocktails",
          "drinks",
          "tropical drink",
          "beverage",
          "summer",
          "beach",
          "alcohol",
          "booze",
          "mojito",
        ],
        name: "tropical drink",
        order: 114,
        unified: "1F379",
        emoji: "🍹",
      },
      {
        keywords: [
          "beer",
          "pint",
          "alcohol",
          "beers",
          "pints",
          "beer mug",
          "relax",
          "beverage",
          "drink",
          "drunk",
          "party",
          "pub",
          "summer",
          "booze",
        ],
        name: "beer mug",
        order: 115,
        unified: "1F37A",
        emoji: "🍺",
      },
      {
        keywords: [
          "beer",
          "cheers",
          "pints",
          "beers",
          "pint",
          "clinking beer mugs",
          "relax",
          "beverage",
          "drink",
          "drunk",
          "party",
          "pub",
          "summer",
          "alcohol",
          "booze",
        ],
        name: "clinking beer mugs",
        order: 116,
        unified: "1F37B",
        emoji: "🍻",
      },
      {
        keywords: [
          "champagne glasses",
          "champagne",
          "a toast",
          "celebratory toast",
          "cheers",
          "toasts",
          "clinking glasses",
          "two glasses",
          "champagne flutes",
          "beverage",
          "drink",
          "party",
          "alcohol",
          "celebrate",
          "wine",
          "toast",
        ],
        name: "clinking glasses",
        order: 117,
        unified: "1F942",
        emoji: "🥂",
      },
      {
        keywords: [
          "tumbler glass",
          "a glass",
          "a drink",
          "whisky",
          "whiskey",
          "bourbon",
          "lowball glass",
          "glass of whisky",
          "glass of whiskey",
          "glass of bourbon",
          "drink",
          "beverage",
          "drunk",
          "alcohol",
          "liquor",
          "booze",
          "scotch",
          "glass",
          "shot",
        ],
        name: "tumbler glass",
        order: 118,
        unified: "1F943",
        emoji: "🥃",
      },
      {
        keywords: [
          "cup with straw",
          "to go cup",
          "juice",
          "juices",
          "drink",
          "drinks",
          "soda",
          "sodas",
          "soda pop",
        ],
        name: "cup with straw",
        order: 119,
        unified: "1F964",
        emoji: "🥤",
      },
      {
        keywords: ["bubble tea", "taiwan", "boba", "milk tea", "straw"],
        name: "bubble tea",
        order: 120,
        unified: "1F9CB",
        emoji: "🧋",
      },
      {
        keywords: ["beverage box", "drink"],
        name: "beverage box",
        order: 121,
        unified: "1F9C3",
        emoji: "🧃",
      },
      {
        keywords: ["mate", "drink", "tea", "beverage", "mate drink"],
        name: "mate drink",
        order: 122,
        unified: "1F9C9",
        emoji: "🧉",
      },
      {
        keywords: ["ice", "water", "cold", "ice cube"],
        name: "ice cube",
        order: 123,
        unified: "1F9CA",
        emoji: "🧊",
      },
      {
        keywords: ["chopsticks", "food"],
        name: "chopsticks",
        order: 124,
        unified: "1F962",
        emoji: "🥢",
      },
      {
        keywords: [
          "fork and knife with plate",
          "food",
          "eat",
          "meal",
          "lunch",
          "dinner",
          "restaurant",
          "knife fork plate",
        ],
        name: "fork and knife with plate",
        order: 125,
        unified: "1F37D-FE0F",
        emoji: "🍽️",
      },
      {
        keywords: [
          "lunch",
          "dinner",
          "fork",
          "knife",
          "utensil",
          "silverware",
          "tableware",
          "flatware",
          "forks",
          "knives",
          "utensils",
          "fork and knife",
          "cutlery",
          "kitchen",
        ],
        name: "fork and knife",
        order: 126,
        unified: "1F374",
        emoji: "🍴",
      },
      {
        keywords: [
          "spoon",
          "spoons",
          "utensil",
          "utensils",
          "silverware",
          "cutlery",
          "kitchen",
          "tableware",
        ],
        name: "spoon",
        order: 127,
        unified: "1F944",
        emoji: "🥄",
      },
      {
        keywords: [
          "kitchen knife",
          "knife",
          "blade",
          "cutlery",
          "kitchen",
          "weapon",
          "hocho",
        ],
        name: "hocho",
        order: 128,
        unified: "1F52A",
        emoji: "🔪",
      },
      {
        keywords: ["amphora", "vase", "jar"],
        name: "amphora",
        order: 129,
        unified: "1F3FA",
        emoji: "🏺",
      },
    ],
  },
  {
    title: "activities",
    data: [
      {
        keywords: [
          "halloween",
          "pumpkin",
          "jack o lantern",
          "pumpkins",
          "jack o lanterns",
          "light",
          "creepy",
          "fall",
        ],
        name: "jack-o-lantern",
        order: 1,
        unified: "1F383",
        emoji: "🎃",
      },
      {
        keywords: [
          "christmas tree",
          "christmas",
          "christmas trees",
          "festival",
          "vacation",
          "december",
          "xmas",
          "celebration",
        ],
        name: "christmas tree",
        order: 2,
        unified: "1F384",
        emoji: "🎄",
      },
      {
        keywords: [
          "fireworks",
          "photo",
          "festival",
          "carnival",
          "congratulations",
        ],
        name: "fireworks",
        order: 3,
        unified: "1F386",
        emoji: "🎆",
      },
      {
        keywords: [
          "sparkler",
          "fireworks",
          "firework sparkler",
          "stars",
          "night",
          "shine",
        ],
        name: "firework sparkler",
        order: 4,
        unified: "1F387",
        emoji: "🎇",
      },
      {
        keywords: [
          "firecracker",
          "dynamite",
          "boom",
          "explode",
          "explosion",
          "explosive",
        ],
        name: "firecracker",
        order: 5,
        unified: "1F9E8",
        emoji: "🧨",
      },
      {
        keywords: [
          "stars",
          "glitter",
          "twinkle",
          "sparkles",
          "glittering",
          "sparkling",
          "twinkling",
          "shine",
          "shiny",
          "cool",
          "awesome",
          "good",
          "magic",
        ],
        name: "sparkles",
        order: 6,
        unified: "2728",
        emoji: "✨",
      },
      {
        keywords: [
          "balloon",
          "party",
          "birthday",
          "balloons",
          "parties",
          "birthdays",
          "celebration",
          "circus",
        ],
        name: "balloon",
        order: 7,
        unified: "1F388",
        emoji: "🎈",
      },
      {
        keywords: [
          "party",
          "celebrate",
          "celebration",
          "birthday",
          "confetti",
          "parties",
          "celebrations",
          "birthdays",
          "woohoo",
          "hooray",
          "party popper",
          "congratulations",
          "magic",
          "circus",
          "tada",
        ],
        name: "party popper",
        order: 8,
        unified: "1F389",
        emoji: "🎉",
      },
      {
        keywords: [
          "party",
          "celebrate",
          "celebration",
          "birthday",
          "confetti",
          "parties",
          "celebrations",
          "birthdays",
          "confetti ball",
          "festival",
          "circus",
        ],
        name: "confetti ball",
        order: 9,
        unified: "1F38A",
        emoji: "🎊",
      },
      {
        keywords: [
          "tanabata tree",
          "tanabata trees",
          "plant",
          "nature",
          "branch",
          "summer",
        ],
        name: "tanabata tree",
        order: 10,
        unified: "1F38B",
        emoji: "🎋",
      },
      {
        keywords: [
          "pine decoration",
          "pine decorations",
          "kadomatsu",
          "plant",
          "nature",
          "vegetable",
          "panda",
          "bamboo",
        ],
        name: "pine decoration",
        order: 11,
        unified: "1F38D",
        emoji: "🎍",
      },
      {
        keywords: ["japanese dolls", "japanese", "toy", "kimono", "dolls"],
        name: "japanese dolls",
        order: 12,
        unified: "1F38E",
        emoji: "🎎",
      },
      {
        keywords: [
          "carp streamer",
          "fish",
          "japanese",
          "koinobori",
          "carp",
          "banner",
          "flags",
        ],
        name: "carp streamer",
        order: 13,
        unified: "1F38F",
        emoji: "🎏",
      },
      {
        keywords: [
          "wind chime",
          "wind chimes",
          "nature",
          "ding",
          "spring",
          "bell",
        ],
        name: "wind chime",
        order: 14,
        unified: "1F390",
        emoji: "🎐",
      },
      {
        keywords: [
          "moon viewing ceremony",
          "photo",
          "japan",
          "asia",
          "tsukimi",
          "rice scene",
        ],
        name: "moon viewing ceremony",
        order: 15,
        unified: "1F391",
        emoji: "🎑",
      },
      {
        keywords: ["red envelope", "gift", "red gift envelope"],
        name: "red gift envelope",
        order: 16,
        unified: "1F9E7",
        emoji: "🧧",
      },
      {
        keywords: ["ribbon", "ribbons", "decoration", "pink", "girl", "bowtie"],
        name: "ribbon",
        order: 17,
        unified: "1F380",
        emoji: "🎀",
      },
      {
        keywords: [
          "gift",
          "present",
          "bow",
          "birthday",
          "gifts",
          "presents",
          "wrapped gift",
          "christmas",
          "xmas",
          "wrapped present",
        ],
        name: "wrapped present",
        order: 18,
        unified: "1F381",
        emoji: "🎁",
      },
      {
        keywords: [
          "reminder ribbon",
          "sports",
          "cause",
          "support",
          "awareness",
        ],
        name: "reminder ribbon",
        order: 19,
        unified: "1F397-FE0F",
        emoji: "🎗️",
      },
      {
        keywords: ["admission tickets", "sports", "concert", "entrance"],
        name: "admission tickets",
        order: 20,
        unified: "1F39F-FE0F",
        emoji: "🎟️",
      },
      {
        keywords: ["ticket", "event", "concert", "pass"],
        name: "ticket",
        order: 21,
        unified: "1F3AB",
        emoji: "🎫",
      },
      {
        keywords: ["military medal", "award", "winning", "army", "medal"],
        name: "military medal",
        order: 22,
        unified: "1F396-FE0F",
        emoji: "🎖️",
      },
      {
        keywords: [
          "trophy",
          "trophies",
          "win",
          "award",
          "contest",
          "place",
          "ftw",
          "ceremony",
        ],
        name: "trophy",
        order: 23,
        unified: "1F3C6",
        emoji: "🏆",
      },
      {
        keywords: ["medal", "medals", "sports medal", "award", "winning"],
        name: "sports medal",
        order: 24,
        unified: "1F3C5",
        emoji: "🏅",
      },
      {
        keywords: [
          "medal",
          "medals",
          "first place",
          "gold medal",
          "first place gold medal",
          "award",
          "1st place medal",
          "winning",
          "first",
          "first place medal",
        ],
        name: "first place medal",
        order: 25,
        unified: "1F947",
        emoji: "🥇",
      },
      {
        keywords: [
          "medal",
          "second place",
          "silver medal",
          "second place silver medal",
          "award",
          "2nd place medal",
          "second",
          "second place medal",
        ],
        name: "second place medal",
        order: 26,
        unified: "1F948",
        emoji: "🥈",
      },
      {
        keywords: [
          "medal",
          "third place",
          "bronze medal",
          "third place bronze medal",
          "award",
          "3rd place medal",
          "third",
          "third place medal",
        ],
        name: "third place medal",
        order: 27,
        unified: "1F949",
        emoji: "🥉",
      },
      {
        keywords: ["soccer ball", "sports", "football", "soccer"],
        name: "soccer ball",
        order: 28,
        unified: "26BD",
        emoji: "⚽",
      },
      {
        keywords: ["baseball", "sports", "balls"],
        name: "baseball",
        order: 29,
        unified: "26BE",
        emoji: "⚾",
      },
      {
        keywords: ["softball", "sports", "balls"],
        name: "softball",
        order: 30,
        unified: "1F94E",
        emoji: "🥎",
      },
      {
        keywords: [
          "basketball",
          "basketballs",
          "ball",
          "balls",
          "sports",
          "nba",
          "basketball and hoop",
        ],
        name: "basketball and hoop",
        order: 31,
        unified: "1F3C0",
        emoji: "🏀",
      },
      {
        keywords: ["volleyball", "volleyballs", "ball", "balls", "sports"],
        name: "volleyball",
        order: 32,
        unified: "1F3D0",
        emoji: "🏐",
      },
      {
        keywords: [
          "football",
          "footballs",
          "ball",
          "balls",
          "american football",
          "sports",
          "nfl",
        ],
        name: "american football",
        order: 33,
        unified: "1F3C8",
        emoji: "🏈",
      },
      {
        keywords: ["rugby", "rugby football", "sports", "team"],
        name: "rugby football",
        order: 34,
        unified: "1F3C9",
        emoji: "🏉",
      },
      {
        keywords: [
          "tennis ball",
          "tennis",
          "ball",
          "balls",
          "tennis balls",
          "sports",
          "green",
          "tennis racquet and ball",
        ],
        name: "tennis racquet and ball",
        order: 35,
        unified: "1F3BE",
        emoji: "🎾",
      },
      {
        keywords: ["flying disc", "sports", "frisbee", "ultimate"],
        name: "flying disc",
        order: 36,
        unified: "1F94F",
        emoji: "🥏",
      },
      {
        keywords: [
          "bowling",
          "bowling pins",
          "bowling ball",
          "bowling pin",
          "bowling balls",
          "ball",
          "sports",
          "fun",
          "play",
        ],
        name: "bowling",
        order: 37,
        unified: "1F3B3",
        emoji: "🎳",
      },
      {
        keywords: [
          "cricket bat",
          "cricket",
          "cricket game",
          "sports",
          "cricket bat and ball",
        ],
        name: "cricket bat and ball",
        order: 38,
        unified: "1F3CF",
        emoji: "🏏",
      },
      {
        keywords: ["field hockey", "sports", "field hockey stick and ball"],
        name: "field hockey stick and ball",
        order: 39,
        unified: "1F3D1",
        emoji: "🏑",
      },
      {
        keywords: [
          "ice hockey",
          "hockey",
          "hockey stick",
          "sports",
          "ice hockey stick and puck",
        ],
        name: "ice hockey stick and puck",
        order: 40,
        unified: "1F3D2",
        emoji: "🏒",
      },
      {
        keywords: [
          "lacrosse",
          "sports",
          "ball",
          "stick",
          "lacrosse stick and ball",
        ],
        name: "lacrosse stick and ball",
        order: 41,
        unified: "1F94D",
        emoji: "🥍",
      },
      {
        keywords: [
          "table tennis",
          "ping pong",
          "ping pong paddle",
          "table tennis racket",
          "sports",
          "pingpong",
          "table tennis paddle and ball",
        ],
        name: "table tennis paddle and ball",
        order: 42,
        unified: "1F3D3",
        emoji: "🏓",
      },
      {
        keywords: [
          "badminton",
          "badminton racquet",
          "badminton racket",
          "shuttlecock",
          "sports",
          "badminton racquet and shuttlecock",
        ],
        name: "badminton racquet and shuttlecock",
        order: 43,
        unified: "1F3F8",
        emoji: "🏸",
      },
      {
        keywords: [
          "boxing glove",
          "red boxing glove",
          "boxing",
          "punch",
          "glove",
          "sports",
          "fighting",
        ],
        name: "boxing glove",
        order: 44,
        unified: "1F94A",
        emoji: "🥊",
      },
      {
        keywords: [
          "uniform",
          "martial art",
          "martial arts",
          "martial arts uniform",
          "karate uniform",
          "karate",
          "judo uniform",
          "tae kwon do uniform",
          "karate gi",
          "tae kwon do",
          "judo",
          "hiyaa",
          "black belt",
          "karate chop",
          "kung fu",
          "taekwondo",
        ],
        name: "martial arts uniform",
        order: 45,
        unified: "1F94B",
        emoji: "🥋",
      },
      {
        keywords: ["goal", "goal net", "soccer", "sports"],
        name: "goal net",
        order: 46,
        unified: "1F945",
        emoji: "🥅",
      },
      {
        keywords: [
          "flag in hole",
          "sports",
          "business",
          "flag",
          "hole",
          "summer",
          "golf",
        ],
        name: "flag in hole",
        order: 47,
        unified: "26F3",
        emoji: "⛳",
      },
      {
        keywords: ["ice skate", "sports"],
        name: "ice skate",
        order: 48,
        unified: "26F8-FE0F",
        emoji: "⛸️",
      },
      {
        keywords: [
          "fishing",
          "fishing pole",
          "fish",
          "food",
          "hobby",
          "summer",
          "fishing pole and fish",
        ],
        name: "fishing pole and fish",
        order: 49,
        unified: "1F3A3",
        emoji: "🎣",
      },
      {
        keywords: ["diving mask", "sport", "ocean"],
        name: "diving mask",
        order: 50,
        unified: "1F93F",
        emoji: "🤿",
      },
      {
        keywords: [
          "running shirt",
          "play",
          "pageant",
          "running shirt with sash",
        ],
        name: "running shirt with sash",
        order: 51,
        unified: "1F3BD",
        emoji: "🎽",
      },
      {
        keywords: [
          "ski",
          "skis",
          "ski boot",
          "ski boots",
          "sports",
          "winter",
          "cold",
          "snow",
          "ski and ski boot",
        ],
        name: "ski and ski boot",
        order: 52,
        unified: "1F3BF",
        emoji: "🎿",
      },
      {
        keywords: ["sled", "sleds", "sleigh", "sleighs", "luge", "toboggan"],
        name: "sled",
        order: 53,
        unified: "1F6F7",
        emoji: "🛷",
      },
      {
        keywords: ["curling", "curling stone", "curling stones", "sports"],
        name: "curling stone",
        order: 54,
        unified: "1F94C",
        emoji: "🥌",
      },
      {
        keywords: [
          "darts",
          "bullseye",
          "dart",
          "bullseyes",
          "target",
          "direct hit",
          "game",
          "play",
          "bar",
        ],
        name: "direct hit",
        order: 55,
        unified: "1F3AF",
        emoji: "🎯",
      },
      {
        keywords: ["yo yo", "toy"],
        name: "yo-yo",
        order: 56,
        unified: "1FA80",
        emoji: "🪀",
      },
      {
        keywords: ["kite", "wind", "fly"],
        name: "kite",
        order: 57,
        unified: "1FA81",
        emoji: "🪁",
      },
      {
        keywords: [
          "pool",
          "billiards",
          "eight ball",
          "snooker",
          "ball",
          "balls",
          "pool 8 ball",
          "hobby",
          "game",
          "luck",
          "magic",
          "8ball",
        ],
        name: "billiards",
        order: 58,
        unified: "1F3B1",
        emoji: "🎱",
      },
      {
        keywords: [
          "crystal ball",
          "fortune",
          "crystal balls",
          "fortunes",
          "disco",
          "party",
          "magic",
          "circus",
          "fortune teller",
        ],
        name: "crystal ball",
        order: 59,
        unified: "1F52E",
        emoji: "🔮",
      },
      {
        keywords: ["magic wand", "supernature", "power"],
        name: "magic wand",
        order: 60,
        unified: "1FA84",
        emoji: "🪄",
      },
      {
        keywords: ["nazar amulet", "bead", "charm"],
        name: "nazar amulet",
        order: 61,
        unified: "1F9FF",
        emoji: "🧿",
      },
      {
        keywords: [
          "video game",
          "video game controller",
          "console game",
          "console controller",
          "play",
          "console",
          "ps4",
          "controller",
        ],
        name: "video game",
        order: 62,
        unified: "1F3AE",
        emoji: "🎮",
      },
      {
        keywords: ["joystick", "game", "play"],
        name: "joystick",
        order: 63,
        unified: "1F579-FE0F",
        emoji: "🕹️",
      },
      {
        keywords: [
          "slot machine",
          "bet",
          "gamble",
          "vegas",
          "fruit machine",
          "luck",
          "casino",
        ],
        name: "slot machine",
        order: 64,
        unified: "1F3B0",
        emoji: "🎰",
      },
      {
        keywords: ["dice", "game die", "random", "tabletop", "play", "luck"],
        name: "game die",
        order: 65,
        unified: "1F3B2",
        emoji: "🎲",
      },
      {
        keywords: [
          "puzzle piece",
          "interlocking",
          "puzzle",
          "piece",
          "jigsaw",
          "jigsaw puzzle piece",
        ],
        name: "jigsaw puzzle piece",
        order: 66,
        unified: "1F9E9",
        emoji: "🧩",
      },
      {
        keywords: ["teddy bear", "plush", "stuffed"],
        name: "teddy bear",
        order: 67,
        unified: "1F9F8",
        emoji: "🧸",
      },
      {
        keywords: ["pinata", "mexico", "candy", "celebration"],
        name: "pinata",
        order: 68,
        unified: "1FA85",
        emoji: "🪅",
      },
      {
        keywords: ["nesting dolls", "matryoshka", "toy"],
        name: "nesting dolls",
        order: 69,
        unified: "1FA86",
        emoji: "🪆",
      },
      {
        keywords: [
          "playing card",
          "playing cards",
          "spade",
          "spades",
          "spade suit",
          "poker",
          "cards",
          "suits",
          "magic",
          "black spade suit",
        ],
        name: "black spade suit",
        order: 70,
        unified: "2660-FE0F",
        emoji: "♠️",
      },
      {
        keywords: [
          "cards",
          "hearts",
          "heart suit",
          "poker",
          "magic",
          "suits",
          "black heart suit",
        ],
        name: "black heart suit",
        order: 71,
        unified: "2665-FE0F",
        emoji: "♥️",
      },
      {
        keywords: [
          "cards",
          "diamond",
          "diamonds",
          "diamond suit",
          "poker",
          "magic",
          "suits",
          "black diamond suit",
        ],
        name: "black diamond suit",
        order: 72,
        unified: "2666-FE0F",
        emoji: "♦️",
      },
      {
        keywords: [
          "playing card",
          "playing cards",
          "club suit",
          "poker",
          "cards",
          "magic",
          "suits",
          "clubs",
          "black club suit",
        ],
        name: "black club suit",
        order: 73,
        unified: "2663-FE0F",
        emoji: "♣️",
      },
      {
        keywords: ["chess pawn", "expendable"],
        name: "chess pawn",
        order: 74,
        unified: "265F-FE0F",
        emoji: "♟️",
      },
      {
        keywords: [
          "joker",
          "poker",
          "cards",
          "game",
          "play",
          "magic",
          "black joker",
          "playing card black joker",
        ],
        name: "playing card black joker",
        order: 75,
        unified: "1F0CF",
        emoji: "🃏",
      },
      {
        keywords: [
          "mahjong red dragon",
          "game",
          "play",
          "chinese",
          "kanji",
          "mahjong",
          "mahjong tile red dragon",
        ],
        name: "mahjong tile red dragon",
        order: 76,
        unified: "1F004",
        emoji: "🀄",
      },
      {
        keywords: ["flower playing cards", "game", "sunset", "red"],
        name: "flower playing cards",
        order: 77,
        unified: "1F3B4",
        emoji: "🎴",
      },
      {
        keywords: [
          "theatre",
          "theater",
          "thespians",
          "arts",
          "masks",
          "broadway",
          "theaters",
          "thespian",
          "performing arts",
          "acting",
          "drama",
        ],
        name: "performing arts",
        order: 78,
        unified: "1F3AD",
        emoji: "🎭",
      },
      {
        keywords: ["framed picture", "photography", "frame with picture"],
        name: "framed picture",
        order: 79,
        unified: "1F5BC-FE0F",
        emoji: "🖼️",
      },
      {
        keywords: [
          "artist",
          "paint",
          "palette",
          "tray",
          "palettes",
          "artist palette",
          "design",
          "draw",
          "colors",
          "art",
        ],
        name: "artist palette",
        order: 80,
        unified: "1F3A8",
        emoji: "🎨",
      },
      {
        keywords: [
          "thread",
          "needle",
          "sewing",
          "spool",
          "string",
          "spool of thread",
        ],
        name: "spool of thread",
        order: 81,
        unified: "1F9F5",
        emoji: "🧵",
      },
      {
        keywords: ["sewing needle", "stitches"],
        name: "sewing needle",
        order: 82,
        unified: "1FAA1",
        emoji: "🪡",
      },
      {
        keywords: ["yarn", "ball", "crochet", "knit", "ball of yarn"],
        name: "ball of yarn",
        order: 83,
        unified: "1F9F6",
        emoji: "🧶",
      },
      {
        keywords: ["knot", "rope", "scout"],
        name: "knot",
        order: 84,
        unified: "1FAA2",
        emoji: "🪢",
      },
    ],
  },
  {
    title: "travel",
    data: [
      {
        keywords: [
          "globe",
          "world",
          "planet",
          "globe showing europe africa",
          "international",
          "earth africa",
          "earth globe europe africa",
        ],
        name: "earth globe europe-africa",
        order: 1,
        unified: "1F30D",
        emoji: "🌍",
      },
      {
        keywords: [
          "globe",
          "world",
          "planet",
          "globe showing americas",
          "usa",
          "international",
          "earth americas",
          "earth globe americas",
        ],
        name: "earth globe americas",
        order: 2,
        unified: "1F30E",
        emoji: "🌎",
      },
      {
        keywords: [
          "globe",
          "planet",
          "earth",
          "globe showing asia australia",
          "world",
          "east",
          "international",
          "earth asia",
          "earth globe asia australia",
        ],
        name: "earth globe asia-australia",
        order: 3,
        unified: "1F30F",
        emoji: "🌏",
      },
      {
        keywords: [
          "globe with meridians",
          "earth",
          "international",
          "world",
          "internet",
          "interweb",
          "i18n",
        ],
        name: "globe with meridians",
        order: 4,
        unified: "1F310",
        emoji: "🌐",
      },
      {
        keywords: ["world map", "location", "direction"],
        name: "world map",
        order: 5,
        unified: "1F5FA-FE0F",
        emoji: "🗺️",
      },
      {
        keywords: [
          "map of japan",
          "nation",
          "country",
          "japanese",
          "asia",
          "japan",
          "silhouette of japan",
        ],
        name: "silhouette of japan",
        order: 6,
        unified: "1F5FE",
        emoji: "🗾",
      },
      {
        keywords: ["compass", "magnetic", "navigation", "orienteering"],
        name: "compass",
        order: 7,
        unified: "1F9ED",
        emoji: "🧭",
      },
      {
        keywords: [
          "snow capped mountain",
          "photo",
          "nature",
          "environment",
          "winter",
          "cold",
        ],
        name: "snow-capped mountain",
        order: 8,
        unified: "1F3D4-FE0F",
        emoji: "🏔️",
      },
      {
        keywords: ["mountain", "photo", "nature", "environment"],
        name: "mountain",
        order: 9,
        unified: "26F0-FE0F",
        emoji: "⛰️",
      },
      {
        keywords: [
          "volcano",
          "volcanos",
          "volcanoes",
          "eruption",
          "photo",
          "nature",
          "disaster",
        ],
        name: "volcano",
        order: 10,
        unified: "1F30B",
        emoji: "🌋",
      },
      {
        keywords: ["mount fuji", "photo", "mountain", "nature", "japanese"],
        name: "mount fuji",
        order: 11,
        unified: "1F5FB",
        emoji: "🗻",
      },
      {
        keywords: ["camping", "photo", "outdoors", "tent"],
        name: "camping",
        order: 12,
        unified: "1F3D5-FE0F",
        emoji: "🏕️",
      },
      {
        keywords: [
          "beach with umbrella",
          "weather",
          "summer",
          "sunny",
          "sand",
          "mojito",
        ],
        name: "beach with umbrella",
        order: 13,
        unified: "1F3D6-FE0F",
        emoji: "🏖️",
      },
      {
        keywords: ["desert", "photo", "warm", "saharah"],
        name: "desert",
        order: 14,
        unified: "1F3DC-FE0F",
        emoji: "🏜️",
      },
      {
        keywords: ["desert island", "photo", "tropical", "mojito"],
        name: "desert island",
        order: 15,
        unified: "1F3DD-FE0F",
        emoji: "🏝️",
      },
      {
        keywords: ["national park", "photo", "environment", "nature"],
        name: "national park",
        order: 16,
        unified: "1F3DE-FE0F",
        emoji: "🏞️",
      },
      {
        keywords: ["stadium", "photo", "place", "sports", "concert", "venue"],
        name: "stadium",
        order: 17,
        unified: "1F3DF-FE0F",
        emoji: "🏟️",
      },
      {
        keywords: ["classical building", "art", "culture", "history"],
        name: "classical building",
        order: 18,
        unified: "1F3DB-FE0F",
        emoji: "🏛️",
      },
      {
        keywords: ["building construction", "wip", "working", "progress"],
        name: "building construction",
        order: 19,
        unified: "1F3D7-FE0F",
        emoji: "🏗️",
      },
      {
        keywords: ["brick", "bricks"],
        name: "brick",
        order: 20,
        unified: "1F9F1",
        emoji: "🧱",
      },
      {
        keywords: ["rock", "stone"],
        name: "rock",
        order: 21,
        unified: "1FAA8",
        emoji: "🪨",
      },
      {
        keywords: ["wood", "nature", "timber", "trunk"],
        name: "wood",
        order: 22,
        unified: "1FAB5",
        emoji: "🪵",
      },
      {
        keywords: ["hut", "house", "structure"],
        name: "hut",
        order: 23,
        unified: "1F6D6",
        emoji: "🛖",
      },
      {
        keywords: ["houses", "buildings", "photo", "house buildings"],
        name: "houses",
        order: 24,
        unified: "1F3D8-FE0F",
        emoji: "🏘️",
      },
      {
        keywords: [
          "derelict house",
          "abandon",
          "evict",
          "broken",
          "building",
          "derelict house building",
        ],
        name: "derelict house",
        order: 25,
        unified: "1F3DA-FE0F",
        emoji: "🏚️",
      },
      {
        keywords: [
          "home",
          "house",
          "townhouse",
          "townhome",
          "town home",
          "homes",
          "houses",
          "townhouses",
          "townhomes",
          "town homes",
          "building",
          "house building",
        ],
        name: "house building",
        order: 26,
        unified: "1F3E0",
        emoji: "🏠",
      },
      {
        keywords: [
          "home",
          "house",
          "townhouse",
          "townhome",
          "town home",
          "estate",
          "homes",
          "houses",
          "townhouses",
          "townhomes",
          "town homes",
          "estates",
          "house with garden",
          "plant",
          "nature",
        ],
        name: "house with garden",
        order: 27,
        unified: "1F3E1",
        emoji: "🏡",
      },
      {
        keywords: ["office building", "building", "bureau", "work", "office"],
        name: "office building",
        order: 28,
        unified: "1F3E2",
        emoji: "🏢",
      },
      {
        keywords: [
          "japanese post office",
          "building",
          "envelope",
          "communication",
          "post office",
        ],
        name: "japanese post office",
        order: 29,
        unified: "1F3E3",
        emoji: "🏣",
      },
      {
        keywords: ["post office", "building", "email", "european post office"],
        name: "european post office",
        order: 30,
        unified: "1F3E4",
        emoji: "🏤",
      },
      {
        keywords: ["hospital", "building", "health", "surgery", "doctor"],
        name: "hospital",
        order: 31,
        unified: "1F3E5",
        emoji: "🏥",
      },
      {
        keywords: [
          "bank",
          "building",
          "money",
          "sales",
          "cash",
          "business",
          "enterprise",
        ],
        name: "bank",
        order: 32,
        unified: "1F3E6",
        emoji: "🏦",
      },
      {
        keywords: ["hotel", "building", "accomodation", "checkin"],
        name: "hotel",
        order: 33,
        unified: "1F3E8",
        emoji: "🏨",
      },
      {
        keywords: ["love hotel", "like", "affection", "dating"],
        name: "love hotel",
        order: 34,
        unified: "1F3E9",
        emoji: "🏩",
      },
      {
        keywords: ["convenience store", "building", "shopping", "groceries"],
        name: "convenience store",
        order: 35,
        unified: "1F3EA",
        emoji: "🏪",
      },
      {
        keywords: [
          "school",
          "building",
          "student",
          "education",
          "learn",
          "teach",
        ],
        name: "school",
        order: 36,
        unified: "1F3EB",
        emoji: "🏫",
      },
      {
        keywords: ["department store", "building", "shopping", "mall"],
        name: "department store",
        order: 37,
        unified: "1F3EC",
        emoji: "🏬",
      },
      {
        keywords: ["factory", "building", "industry", "pollution", "smoke"],
        name: "factory",
        order: 38,
        unified: "1F3ED",
        emoji: "🏭",
      },
      {
        keywords: ["japanese castle", "photo", "building"],
        name: "japanese castle",
        order: 39,
        unified: "1F3EF",
        emoji: "🏯",
      },
      {
        keywords: [
          "castle",
          "building",
          "royalty",
          "history",
          "european castle",
        ],
        name: "european castle",
        order: 40,
        unified: "1F3F0",
        emoji: "🏰",
      },
      {
        keywords: [
          "wedding",
          "love",
          "like",
          "affection",
          "couple",
          "marriage",
          "bride",
          "groom",
        ],
        name: "wedding",
        order: 41,
        unified: "1F492",
        emoji: "💒",
      },
      {
        keywords: ["tokyo tower", "photo", "japanese"],
        name: "tokyo tower",
        order: 42,
        unified: "1F5FC",
        emoji: "🗼",
      },
      {
        keywords: ["statue of liberty", "liberty", "american", "newyork"],
        name: "statue of liberty",
        order: 43,
        unified: "1F5FD",
        emoji: "🗽",
      },
      {
        keywords: ["church", "churches", "building", "religion", "christ"],
        name: "church",
        order: 44,
        unified: "26EA",
        emoji: "⛪",
      },
      {
        keywords: ["mosque", "mosques", "islam", "worship", "minaret"],
        name: "mosque",
        order: 45,
        unified: "1F54C",
        emoji: "🕌",
      },
      {
        keywords: ["hindu temple", "religion"],
        name: "hindu temple",
        order: 46,
        unified: "1F6D5",
        emoji: "🛕",
      },
      {
        keywords: [
          "synagogue",
          "synagogues",
          "judaism",
          "worship",
          "temple",
          "jewish",
        ],
        name: "synagogue",
        order: 47,
        unified: "1F54D",
        emoji: "🕍",
      },
      {
        keywords: ["shinto shrine", "temple", "japan", "kyoto"],
        name: "shinto shrine",
        order: 48,
        unified: "26E9-FE0F",
        emoji: "⛩️",
      },
      {
        keywords: ["kaaba", "mecca", "mosque", "islam"],
        name: "kaaba",
        order: 49,
        unified: "1F54B",
        emoji: "🕋",
      },
      {
        keywords: ["fountain", "photo", "summer", "water", "fresh"],
        name: "fountain",
        order: 50,
        unified: "26F2",
        emoji: "⛲",
      },
      {
        keywords: ["tent", "photo", "camping", "outdoors"],
        name: "tent",
        order: 51,
        unified: "26FA",
        emoji: "⛺",
      },
      {
        keywords: ["foggy", "photo", "mountain"],
        name: "foggy",
        order: 52,
        unified: "1F301",
        emoji: "🌁",
      },
      {
        keywords: [
          "skyline",
          "city",
          "night with stars",
          "evening",
          "downtown",
        ],
        name: "night with stars",
        order: 53,
        unified: "1F303",
        emoji: "🌃",
      },
      {
        keywords: ["cityscape", "photo", "night life", "urban"],
        name: "cityscape",
        order: 54,
        unified: "1F3D9-FE0F",
        emoji: "🏙️",
      },
      {
        keywords: ["sunrise over mountains", "view", "vacation", "photo"],
        name: "sunrise over mountains",
        order: 55,
        unified: "1F304",
        emoji: "🌄",
      },
      {
        keywords: [
          "sunset",
          "sunrise",
          "sun postcard",
          "sunsets",
          "sunrises",
          "morning",
          "view",
          "vacation",
          "photo",
        ],
        name: "sunrise",
        order: 56,
        unified: "1F305",
        emoji: "🌅",
      },
      {
        keywords: [
          "cityscape",
          "cityscape at dusk",
          "photo",
          "evening",
          "sky",
          "buildings",
          "city sunset",
        ],
        name: "cityscape at dusk",
        order: 57,
        unified: "1F306",
        emoji: "🌆",
      },
      {
        keywords: [
          "sunset",
          "photo",
          "good morning",
          "dawn",
          "city sunrise",
          "sunset over buildings",
        ],
        name: "sunset over buildings",
        order: 58,
        unified: "1F307",
        emoji: "🌇",
      },
      {
        keywords: ["bridge at night", "photo", "sanfrancisco"],
        name: "bridge at night",
        order: 59,
        unified: "1F309",
        emoji: "🌉",
      },
      {
        keywords: [
          "hot springs",
          "hot spring",
          "onsen",
          "onsens",
          "bath",
          "warm",
          "relax",
          "hotsprings",
        ],
        name: "hot springs",
        order: 60,
        unified: "2668-FE0F",
        emoji: "♨️",
      },
      {
        keywords: ["carousel horse", "photo", "carnival"],
        name: "carousel horse",
        order: 61,
        unified: "1F3A0",
        emoji: "🎠",
      },
      {
        keywords: [
          "ferris wheel",
          "carnival",
          "ferris wheels",
          "carnivals",
          "photo",
          "londoneye",
        ],
        name: "ferris wheel",
        order: 62,
        unified: "1F3A1",
        emoji: "🎡",
      },
      {
        keywords: [
          "rollercoaster",
          "rollercoasters",
          "roller coaster",
          "carnival",
          "playground",
          "photo",
          "fun",
        ],
        name: "roller coaster",
        order: 63,
        unified: "1F3A2",
        emoji: "🎢",
      },
      {
        keywords: ["barber pole", "hair", "salon", "style", "barber"],
        name: "barber pole",
        order: 64,
        unified: "1F488",
        emoji: "💈",
      },
      {
        keywords: ["circus tent", "festival", "carnival", "party"],
        name: "circus tent",
        order: 65,
        unified: "1F3AA",
        emoji: "🎪",
      },
      {
        keywords: [
          "train",
          "toy train",
          "locomotive",
          "trains",
          "toy trains",
          "locomotives",
          "transportation",
          "vehicle",
          "steam locomotive",
        ],
        name: "steam locomotive",
        order: 66,
        unified: "1F682",
        emoji: "🚂",
      },
      {
        keywords: [
          "railway",
          "trolley",
          "railways",
          "trolleys",
          "railway car",
          "transportation",
          "vehicle",
        ],
        name: "railway car",
        order: 67,
        unified: "1F683",
        emoji: "🚃",
      },
      {
        keywords: [
          "high speed train",
          "high speed trains",
          "transportation",
          "vehicle",
          "bullettrain side",
        ],
        name: "high-speed train",
        order: 68,
        unified: "1F684",
        emoji: "🚄",
      },
      {
        keywords: [
          "bullet train",
          "transportation",
          "vehicle",
          "speed",
          "fast",
          "public",
          "travel",
          "bullettrain front",
          "high speed train with bullet nose",
        ],
        name: "high-speed train with bullet nose",
        order: 69,
        unified: "1F685",
        emoji: "🚅",
      },
      {
        keywords: ["train", "transportation", "vehicle", "train2"],
        name: "train",
        order: 70,
        unified: "1F686",
        emoji: "🚆",
      },
      {
        keywords: [
          "metro",
          "subway",
          "transportation",
          "blue square",
          "mrt",
          "underground",
          "tube",
        ],
        name: "metro",
        order: 71,
        unified: "1F687",
        emoji: "🚇",
      },
      {
        keywords: ["light rail", "transportation", "vehicle"],
        name: "light rail",
        order: 72,
        unified: "1F688",
        emoji: "🚈",
      },
      {
        keywords: ["station", "transportation", "vehicle", "public"],
        name: "station",
        order: 73,
        unified: "1F689",
        emoji: "🚉",
      },
      {
        keywords: [
          "train",
          "subway",
          "tram",
          "trains",
          "subways",
          "trams",
          "transportation",
          "vehicle",
        ],
        name: "tram",
        order: 74,
        unified: "1F68A",
        emoji: "🚊",
      },
      {
        keywords: ["monorail", "transportation", "vehicle"],
        name: "monorail",
        order: 75,
        unified: "1F69D",
        emoji: "🚝",
      },
      {
        keywords: ["mountain railway", "transportation", "vehicle"],
        name: "mountain railway",
        order: 76,
        unified: "1F69E",
        emoji: "🚞",
      },
      {
        keywords: [
          "trolley",
          "tram",
          "trollies",
          "trams",
          "street car",
          "tram car",
          "transportation",
          "vehicle",
          "carriage",
          "public",
          "travel",
          "train",
        ],
        name: "tram car",
        order: 77,
        unified: "1F68B",
        emoji: "🚋",
      },
      {
        keywords: [
          "school bus",
          "bus",
          "school busses",
          "busses",
          "car",
          "vehicle",
          "transportation",
        ],
        name: "bus",
        order: 78,
        unified: "1F68C",
        emoji: "🚌",
      },
      {
        keywords: ["oncoming bus", "vehicle", "transportation"],
        name: "oncoming bus",
        order: 79,
        unified: "1F68D",
        emoji: "🚍",
      },
      {
        keywords: [
          "bus",
          "trolley",
          "busses",
          "trolleys",
          "trolleybus",
          "bart",
          "transportation",
          "vehicle",
        ],
        name: "trolleybus",
        order: 80,
        unified: "1F68E",
        emoji: "🚎",
      },
      {
        keywords: ["minibus", "minivan", "vehicle", "car", "transportation"],
        name: "minibus",
        order: 81,
        unified: "1F690",
        emoji: "🚐",
      },
      {
        keywords: ["ambulance", "ambulances", "health", "911", "hospital"],
        name: "ambulance",
        order: 82,
        unified: "1F691",
        emoji: "🚑",
      },
      {
        keywords: [
          "fire engine",
          "fire truck",
          "fireman",
          "fire engines",
          "fire trucks",
          "firemen",
          "transportation",
          "cars",
          "vehicle",
        ],
        name: "fire engine",
        order: 83,
        unified: "1F692",
        emoji: "🚒",
      },
      {
        keywords: [
          "police",
          "cop",
          "cops",
          "police car",
          "vehicle",
          "cars",
          "transportation",
          "law",
          "legal",
          "enforcement",
        ],
        name: "police car",
        order: 84,
        unified: "1F693",
        emoji: "🚓",
      },
      {
        keywords: [
          "police",
          "cops",
          "police car",
          "cop",
          "police cars",
          "oncoming police car",
          "vehicle",
          "law",
          "legal",
          "enforcement",
          "911",
        ],
        name: "oncoming police car",
        order: 85,
        unified: "1F694",
        emoji: "🚔",
      },
      {
        keywords: [
          "taxi",
          "cab",
          "taxi cab",
          "uber",
          "vehicle",
          "cars",
          "transportation",
        ],
        name: "taxi",
        order: 86,
        unified: "1F695",
        emoji: "🚕",
      },
      {
        keywords: ["oncoming taxi", "vehicle", "cars", "uber"],
        name: "oncoming taxi",
        order: 87,
        unified: "1F696",
        emoji: "🚖",
      },
      {
        keywords: [
          "car",
          "automobile",
          "hatchback",
          "vehicle",
          "cars",
          "automobiles",
          "hatchbacks",
          "vehicles",
          "red",
          "transportation",
          "red car",
        ],
        name: "automobile",
        order: 88,
        unified: "1F697",
        emoji: "🚗",
      },
      {
        keywords: [
          "car",
          "sedan",
          "cars",
          "sedans",
          "oncoming automobile",
          "vehicle",
          "transportation",
        ],
        name: "oncoming automobile",
        order: 89,
        unified: "1F698",
        emoji: "🚘",
      },
      {
        keywords: [
          "car",
          "jeep",
          "suv",
          "automobile",
          "cars",
          "jeeps",
          "suvs",
          "automobiles",
          "sport utility vehicle",
          "transportation",
          "vehicle",
          "blue car",
          "recreational vehicle",
        ],
        name: "recreational vehicle",
        order: 90,
        unified: "1F699",
        emoji: "🚙",
      },
      {
        keywords: ["pickup truck", "car", "transportation"],
        name: "pickup truck",
        order: 91,
        unified: "1F6FB",
        emoji: "🛻",
      },
      {
        keywords: ["delivery truck", "cars", "transportation", "truck"],
        name: "delivery truck",
        order: 92,
        unified: "1F69A",
        emoji: "🚚",
      },
      {
        keywords: [
          "articulated lorry",
          "vehicle",
          "cars",
          "transportation",
          "express",
        ],
        name: "articulated lorry",
        order: 93,
        unified: "1F69B",
        emoji: "🚛",
      },
      {
        keywords: ["tractor", "vehicle", "car", "farming", "agriculture"],
        name: "tractor",
        order: 94,
        unified: "1F69C",
        emoji: "🚜",
      },
      {
        keywords: ["racing car", "sports", "race", "fast", "formula", "f1"],
        name: "racing car",
        order: 95,
        unified: "1F3CE-FE0F",
        emoji: "🏎️",
      },
      {
        keywords: ["motorcycle", "race", "sports", "fast", "racing motorcycle"],
        name: "motorcycle",
        order: 96,
        unified: "1F3CD-FE0F",
        emoji: "🏍️",
      },
      {
        keywords: [
          "motor scooter",
          "motor scooters",
          "vespa",
          "vespas",
          "vehicle",
          "sasha",
        ],
        name: "motor scooter",
        order: 97,
        unified: "1F6F5",
        emoji: "🛵",
      },
      {
        keywords: ["manual wheelchair", "accessibility"],
        name: "manual wheelchair",
        order: 98,
        unified: "1F9BD",
        emoji: "🦽",
      },
      {
        keywords: ["motorized wheelchair", "accessibility"],
        name: "motorized wheelchair",
        order: 99,
        unified: "1F9BC",
        emoji: "🦼",
      },
      {
        keywords: ["auto rickshaw", "move", "transportation"],
        name: "auto rickshaw",
        order: 100,
        unified: "1F6FA",
        emoji: "🛺",
      },
      {
        keywords: [
          "bike",
          "bicycle",
          "bikes",
          "bicycles",
          "sports",
          "exercise",
          "hipster",
        ],
        name: "bicycle",
        order: 101,
        unified: "1F6B2",
        emoji: "🚲",
      },
      {
        keywords: [
          "scooter",
          "scooters",
          "razor scooter",
          "razor scooters",
          "kick scooter",
          "vehicle",
          "kick",
          "razor",
        ],
        name: "scooter",
        order: 102,
        unified: "1F6F4",
        emoji: "🛴",
      },
      {
        keywords: ["skateboard", "board"],
        name: "skateboard",
        order: 103,
        unified: "1F6F9",
        emoji: "🛹",
      },
      {
        keywords: ["roller skate", "footwear", "sports"],
        name: "roller skate",
        order: 104,
        unified: "1F6FC",
        emoji: "🛼",
      },
      {
        keywords: ["bus stop", "transportation", "wait", "busstop"],
        name: "bus stop",
        order: 105,
        unified: "1F68F",
        emoji: "🚏",
      },
      {
        keywords: ["motorway", "road", "cupertino", "interstate", "highway"],
        name: "motorway",
        order: 106,
        unified: "1F6E3-FE0F",
        emoji: "🛣️",
      },
      {
        keywords: ["railway track", "train", "transportation"],
        name: "railway track",
        order: 107,
        unified: "1F6E4-FE0F",
        emoji: "🛤️",
      },
      {
        keywords: ["oil drum", "barrell"],
        name: "oil drum",
        order: 108,
        unified: "1F6E2-FE0F",
        emoji: "🛢️",
      },
      {
        keywords: ["fuel pump", "gas station", "petroleum", "fuelpump"],
        name: "fuel pump",
        order: 109,
        unified: "26FD",
        emoji: "⛽",
      },
      {
        keywords: [
          "sirens",
          "police",
          "emergency",
          "alarm",
          "alert",
          "siren",
          "alarms",
          "police car light",
          "ambulance",
          "911",
          "error",
          "pinged",
          "law",
          "legal",
          "rotating light",
          "police cars revolving light",
        ],
        name: "police cars revolving light",
        order: 110,
        unified: "1F6A8",
        emoji: "🚨",
      },
      {
        keywords: [
          "traffic light",
          "traffic lights",
          "horizontal traffic light",
          "transportation",
          "signal",
        ],
        name: "horizontal traffic light",
        order: 111,
        unified: "1F6A5",
        emoji: "🚥",
      },
      {
        keywords: [
          "traffic light",
          "traffic lights",
          "vertical traffic light",
          "transportation",
          "driving",
        ],
        name: "vertical traffic light",
        order: 112,
        unified: "1F6A6",
        emoji: "🚦",
      },
      {
        keywords: [
          "stop sign",
          "red sign",
          "octagonal sign",
          "red octagonal sign",
          "stop",
        ],
        name: "octagonal sign",
        order: 113,
        unified: "1F6D1",
        emoji: "🛑",
      },
      {
        keywords: [
          "construction sign",
          "construction",
          "roadwork",
          "wip",
          "progress",
          "caution",
          "warning",
        ],
        name: "construction sign",
        order: 114,
        unified: "1F6A7",
        emoji: "🚧",
      },
      {
        keywords: ["anchor", "ship", "ferry", "sea", "boat"],
        name: "anchor",
        order: 115,
        unified: "2693",
        emoji: "⚓",
      },
      {
        keywords: [
          "sailboat",
          "ship",
          "summer",
          "transportation",
          "water",
          "sailing",
          "boat",
        ],
        name: "sailboat",
        order: 116,
        unified: "26F5",
        emoji: "⛵",
      },
      {
        keywords: [
          "canoe",
          "canoes",
          "green canoe",
          "boating",
          "canoeing",
          "boat",
          "paddle",
          "water",
          "ship",
        ],
        name: "canoe",
        order: 117,
        unified: "1F6F6",
        emoji: "🛶",
      },
      {
        keywords: [
          "boat",
          "speedboat",
          "powerboat",
          "motorboat",
          "boats",
          "speedboats",
          "powerboats",
          "motorboats",
          "ship",
          "transportation",
          "vehicle",
          "summer",
        ],
        name: "speedboat",
        order: 118,
        unified: "1F6A4",
        emoji: "🚤",
      },
      {
        keywords: ["passenger ship", "yacht", "cruise", "ferry"],
        name: "passenger ship",
        order: 119,
        unified: "1F6F3-FE0F",
        emoji: "🛳️",
      },
      {
        keywords: ["ferry", "boat", "ship", "yacht"],
        name: "ferry",
        order: 120,
        unified: "26F4-FE0F",
        emoji: "⛴️",
      },
      {
        keywords: ["motor boat", "ship"],
        name: "motor boat",
        order: 121,
        unified: "1F6E5-FE0F",
        emoji: "🛥️",
      },
      {
        keywords: [
          "ship",
          "cruise ship",
          "cruise",
          "vessel",
          "ships",
          "cruise ships",
          "cruises",
          "vessels",
          "transportation",
          "titanic",
          "deploy",
        ],
        name: "ship",
        order: 122,
        unified: "1F6A2",
        emoji: "🚢",
      },
      {
        keywords: [
          "plane",
          "airplane",
          "planes",
          "airplanes",
          "vehicle",
          "transportation",
          "flight",
          "fly",
        ],
        name: "airplane",
        order: 123,
        unified: "2708-FE0F",
        emoji: "✈️",
      },
      {
        keywords: [
          "small airplane",
          "flight",
          "transportation",
          "fly",
          "vehicle",
        ],
        name: "small airplane",
        order: 124,
        unified: "1F6E9-FE0F",
        emoji: "🛩️",
      },
      {
        keywords: [
          "takeoff",
          "plane is taking off",
          "for takeoff",
          "flight leaves",
          "flight departs",
          "flight will depart",
          "flight is departing",
          "plane is departing",
          "plane departs",
          "plane takes off",
          "plane will take off",
          "plane takeoff",
          "wheels up",
          "airplane departure",
          "airport",
          "flight",
          "landing",
        ],
        name: "airplane departure",
        order: 125,
        unified: "1F6EB",
        emoji: "🛫",
      },
      {
        keywords: [
          "plane is landing",
          "plane just landed",
          "we just landed",
          "we’re landing",
          "we’ll be landing",
          "we landed",
          "plane landed",
          "flight arrival",
          "plane arrival",
          "airplane arrival",
          "wheels down",
          "airport",
          "flight",
          "boarding",
          "airplane arriving",
        ],
        name: "airplane arriving",
        order: 126,
        unified: "1F6EC",
        emoji: "🛬",
      },
      {
        keywords: ["parachute", "fly", "glide"],
        name: "parachute",
        order: 127,
        unified: "1FA82",
        emoji: "🪂",
      },
      {
        keywords: [
          "seat",
          "seats",
          "sit",
          "airplane",
          "transport",
          "bus",
          "flight",
          "fly",
        ],
        name: "seat",
        order: 128,
        unified: "1F4BA",
        emoji: "💺",
      },
      {
        keywords: ["helicopter", "transportation", "vehicle", "fly"],
        name: "helicopter",
        order: 129,
        unified: "1F681",
        emoji: "🚁",
      },
      {
        keywords: ["suspension railway", "vehicle", "transportation"],
        name: "suspension railway",
        order: 130,
        unified: "1F69F",
        emoji: "🚟",
      },
      {
        keywords: ["mountain cableway", "transportation", "vehicle", "ski"],
        name: "mountain cableway",
        order: 131,
        unified: "1F6A0",
        emoji: "🚠",
      },
      {
        keywords: ["aerial tramway", "transportation", "vehicle", "ski"],
        name: "aerial tramway",
        order: 132,
        unified: "1F6A1",
        emoji: "🚡",
      },
      {
        keywords: [
          "satellite",
          "communication",
          "gps",
          "orbit",
          "spaceflight",
          "nasa",
          "iss",
        ],
        name: "satellite",
        order: 133,
        unified: "1F6F0-FE0F",
        emoji: "🛰️",
      },
      {
        keywords: [
          "space ship",
          "rocket",
          "launch",
          "space ships",
          "rockets",
          "launches",
          "spaceship",
          "spaceships",
          "ship",
          "staffmode",
          "nasa",
          "outer space",
          "fly",
        ],
        name: "rocket",
        order: 134,
        unified: "1F680",
        emoji: "🚀",
      },
      {
        keywords: [
          "ufo",
          "ufos",
          "flying saucer",
          "flying saucers",
          "transportation",
          "vehicle",
        ],
        name: "flying saucer",
        order: 135,
        unified: "1F6F8",
        emoji: "🛸",
      },
      {
        keywords: ["bellhop bell", "service"],
        name: "bellhop bell",
        order: 136,
        unified: "1F6CE-FE0F",
        emoji: "🛎️",
      },
      {
        keywords: ["luggage", "packing", "travel"],
        name: "luggage",
        order: 137,
        unified: "1F9F3",
        emoji: "🧳",
      },
      {
        keywords: [
          "hourglass done",
          "time",
          "clock",
          "oldschool",
          "limit",
          "exam",
          "quiz",
          "test",
          "hourglass",
        ],
        name: "hourglass",
        order: 138,
        unified: "231B",
        emoji: "⌛",
      },
      {
        keywords: [
          "hourglass not done",
          "oldschool",
          "time",
          "countdown",
          "hourglass flowing sand",
          "hourglass with flowing sand",
        ],
        name: "hourglass with flowing sand",
        order: 139,
        unified: "23F3",
        emoji: "⏳",
      },
      {
        keywords: ["watch", "time", "accessories"],
        name: "watch",
        order: 140,
        unified: "231A",
        emoji: "⌚",
      },
      {
        keywords: ["alarm clock", "alarm clocks", "time", "wake"],
        name: "alarm clock",
        order: 141,
        unified: "23F0",
        emoji: "⏰",
      },
      {
        keywords: ["stopwatch", "time", "deadline"],
        name: "stopwatch",
        order: 142,
        unified: "23F1-FE0F",
        emoji: "⏱️",
      },
      {
        keywords: ["timer clock", "alarm"],
        name: "timer clock",
        order: 143,
        unified: "23F2-FE0F",
        emoji: "⏲️",
      },
      {
        keywords: ["mantelpiece clock", "time"],
        name: "mantelpiece clock",
        order: 144,
        unified: "1F570-FE0F",
        emoji: "🕰️",
      },
      {
        keywords: [
          "0:00",
          "12:00",
          "24:00",
          "twelve o’clock",
          "midnight",
          "noon",
          "twelve o clock",
          "time",
          "midday",
          "late",
          "early",
          "schedule",
          "clock12",
          "clock face twelve oclock",
        ],
        name: "clock face twelve oclock",
        order: 145,
        unified: "1F55B",
        emoji: "🕛",
      },
      {
        keywords: [
          "0:30",
          "12:30",
          "twelve thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock1230",
          "clock face twelve thirty",
        ],
        name: "clock face twelve-thirty",
        order: 146,
        unified: "1F567",
        emoji: "🕧",
      },
      {
        keywords: [
          "01:00",
          "13:00",
          "1:00",
          "one o’clock",
          "one o clock",
          "time",
          "late",
          "early",
          "schedule",
          "clock1",
          "clock face one oclock",
        ],
        name: "clock face one oclock",
        order: 147,
        unified: "1F550",
        emoji: "🕐",
      },
      {
        keywords: [
          "01:30",
          "13:30",
          "1:30",
          "one thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock130",
          "clock face one thirty",
        ],
        name: "clock face one-thirty",
        order: 148,
        unified: "1F55C",
        emoji: "🕜",
      },
      {
        keywords: [
          "02:00",
          "14:00",
          "2:00",
          "two o’clock",
          "two o clock",
          "time",
          "late",
          "early",
          "schedule",
          "clock2",
          "clock face two oclock",
        ],
        name: "clock face two oclock",
        order: 149,
        unified: "1F551",
        emoji: "🕑",
      },
      {
        keywords: [
          "02:30",
          "14:30",
          "2:30",
          "two thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock230",
          "clock face two thirty",
        ],
        name: "clock face two-thirty",
        order: 150,
        unified: "1F55D",
        emoji: "🕝",
      },
      {
        keywords: [
          "03:00",
          "15:00",
          "3:00",
          "three o’clock",
          "three o clock",
          "time",
          "late",
          "early",
          "schedule",
          "clock3",
          "clock face three oclock",
        ],
        name: "clock face three oclock",
        order: 151,
        unified: "1F552",
        emoji: "🕒",
      },
      {
        keywords: [
          "03:30",
          "15:30",
          "3:30",
          "three thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock330",
          "clock face three thirty",
        ],
        name: "clock face three-thirty",
        order: 152,
        unified: "1F55E",
        emoji: "🕞",
      },
      {
        keywords: [
          "04:00",
          "16:00",
          "4:00",
          "four o’clock",
          "four o clock",
          "time",
          "late",
          "early",
          "schedule",
          "clock4",
          "clock face four oclock",
        ],
        name: "clock face four oclock",
        order: 153,
        unified: "1F553",
        emoji: "🕓",
      },
      {
        keywords: [
          "04:30",
          "16:30",
          "4:30",
          "four thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock430",
          "clock face four thirty",
        ],
        name: "clock face four-thirty",
        order: 154,
        unified: "1F55F",
        emoji: "🕟",
      },
      {
        keywords: [
          "05:00",
          "17:00",
          "5:00",
          "five o’clock",
          "five o clock",
          "time",
          "late",
          "early",
          "schedule",
          "clock5",
          "clock face five oclock",
        ],
        name: "clock face five oclock",
        order: 155,
        unified: "1F554",
        emoji: "🕔",
      },
      {
        keywords: [
          "05:30",
          "17:30",
          "5:30",
          "five thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock530",
          "clock face five thirty",
        ],
        name: "clock face five-thirty",
        order: 156,
        unified: "1F560",
        emoji: "🕠",
      },
      {
        keywords: [
          "06:00",
          "18:00",
          "6:00",
          "six o’clock",
          "six o clock",
          "time",
          "late",
          "early",
          "schedule",
          "dawn",
          "dusk",
          "clock6",
          "clock face six oclock",
        ],
        name: "clock face six oclock",
        order: 157,
        unified: "1F555",
        emoji: "🕕",
      },
      {
        keywords: [
          "06:30",
          "18:30",
          "6:30",
          "six thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock630",
          "clock face six thirty",
        ],
        name: "clock face six-thirty",
        order: 158,
        unified: "1F561",
        emoji: "🕡",
      },
      {
        keywords: [
          "07:00",
          "19:00",
          "7:00",
          "seven o’clock",
          "seven o clock",
          "time",
          "late",
          "early",
          "schedule",
          "clock7",
          "clock face seven oclock",
        ],
        name: "clock face seven oclock",
        order: 159,
        unified: "1F556",
        emoji: "🕖",
      },
      {
        keywords: [
          "07:30",
          "19:30",
          "7:30",
          "seven thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock730",
          "clock face seven thirty",
        ],
        name: "clock face seven-thirty",
        order: 160,
        unified: "1F562",
        emoji: "🕢",
      },
      {
        keywords: [
          "08:00",
          "20:00",
          "8:00",
          "eight o’clock",
          "eight o clock",
          "time",
          "late",
          "early",
          "schedule",
          "clock8",
          "clock face eight oclock",
        ],
        name: "clock face eight oclock",
        order: 161,
        unified: "1F557",
        emoji: "🕗",
      },
      {
        keywords: [
          "08:30",
          "20:30",
          "8:30",
          "eight thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock830",
          "clock face eight thirty",
        ],
        name: "clock face eight-thirty",
        order: 162,
        unified: "1F563",
        emoji: "🕣",
      },
      {
        keywords: [
          "09:00",
          "21:00",
          "9:00",
          "nine o’clock",
          "nine o clock",
          "time",
          "late",
          "early",
          "schedule",
          "clock9",
          "clock face nine oclock",
        ],
        name: "clock face nine oclock",
        order: 163,
        unified: "1F558",
        emoji: "🕘",
      },
      {
        keywords: [
          "09:30",
          "21:30",
          "9:30",
          "nine thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock930",
          "clock face nine thirty",
        ],
        name: "clock face nine-thirty",
        order: 164,
        unified: "1F564",
        emoji: "🕤",
      },
      {
        keywords: [
          "10:00",
          "22:00",
          "ten o’clock",
          "ten o clock",
          "time",
          "late",
          "early",
          "schedule",
          "clock10",
          "clock face ten oclock",
        ],
        name: "clock face ten oclock",
        order: 165,
        unified: "1F559",
        emoji: "🕙",
      },
      {
        keywords: [
          "10:30",
          "22:30",
          "ten thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock1030",
          "clock face ten thirty",
        ],
        name: "clock face ten-thirty",
        order: 166,
        unified: "1F565",
        emoji: "🕥",
      },
      {
        keywords: [
          "11:00",
          "23:00",
          "eleven o’clock",
          "eleven o clock",
          "time",
          "late",
          "early",
          "schedule",
          "clock11",
          "clock face eleven oclock",
        ],
        name: "clock face eleven oclock",
        order: 167,
        unified: "1F55A",
        emoji: "🕚",
      },
      {
        keywords: [
          "11:30",
          "23:30",
          "eleven thirty",
          "time",
          "late",
          "early",
          "schedule",
          "clock1130",
          "clock face eleven thirty",
        ],
        name: "clock face eleven-thirty",
        order: 168,
        unified: "1F566",
        emoji: "🕦",
      },
      {
        keywords: [
          "moon",
          "new moon",
          "nature",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "new moon symbol",
        ],
        name: "new moon symbol",
        order: 169,
        unified: "1F311",
        emoji: "🌑",
      },
      {
        keywords: [
          "moon",
          "waxing crescent moon",
          "nature",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "waxing crescent moon symbol",
        ],
        name: "waxing crescent moon symbol",
        order: 170,
        unified: "1F312",
        emoji: "🌒",
      },
      {
        keywords: [
          "moon",
          "first quarter moon",
          "nature",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "first quarter moon symbol",
        ],
        name: "first quarter moon symbol",
        order: 171,
        unified: "1F313",
        emoji: "🌓",
      },
      {
        keywords: [
          "moon",
          "waxing gibbous moon",
          "nature",
          "night",
          "sky",
          "gray",
          "twilight",
          "planet",
          "space",
          "evening",
          "sleep",
          "waxing gibbous moon symbol",
        ],
        name: "waxing gibbous moon symbol",
        order: 172,
        unified: "1F314",
        emoji: "🌔",
      },
      {
        keywords: [
          "full moon",
          "moon",
          "nature",
          "yellow",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "full moon symbol",
        ],
        name: "full moon symbol",
        order: 173,
        unified: "1F315",
        emoji: "🌕",
      },
      {
        keywords: [
          "moon",
          "waning gibbous moon",
          "nature",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "waxing gibbous moon",
          "waning gibbous moon symbol",
        ],
        name: "waning gibbous moon symbol",
        order: 174,
        unified: "1F316",
        emoji: "🌖",
      },
      {
        keywords: [
          "moon",
          "last quarter moon",
          "nature",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "last quarter moon symbol",
        ],
        name: "last quarter moon symbol",
        order: 175,
        unified: "1F317",
        emoji: "🌗",
      },
      {
        keywords: [
          "moon",
          "waning crescent moon",
          "nature",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "waning crescent moon symbol",
        ],
        name: "waning crescent moon symbol",
        order: 176,
        unified: "1F318",
        emoji: "🌘",
      },
      {
        keywords: [
          "crescent",
          "moon",
          "crescent moon",
          "night",
          "sleep",
          "sky",
          "evening",
          "magic",
        ],
        name: "crescent moon",
        order: 177,
        unified: "1F319",
        emoji: "🌙",
      },
      {
        keywords: [
          "moon",
          "new moon face",
          "nature",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "new moon with face",
        ],
        name: "new moon with face",
        order: 178,
        unified: "1F31A",
        emoji: "🌚",
      },
      {
        keywords: [
          "moon",
          "first quarter moon face",
          "nature",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "first quarter moon with face",
        ],
        name: "first quarter moon with face",
        order: 179,
        unified: "1F31B",
        emoji: "🌛",
      },
      {
        keywords: [
          "moon",
          "last quarter moon face",
          "nature",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "last quarter moon with face",
        ],
        name: "last quarter moon with face",
        order: 180,
        unified: "1F31C",
        emoji: "🌜",
      },
      {
        keywords: ["thermometer", "weather", "temperature", "hot", "cold"],
        name: "thermometer",
        order: 181,
        unified: "1F321-FE0F",
        emoji: "🌡️",
      },
      {
        keywords: [
          "sun",
          "sunshine",
          "sunny",
          "weather",
          "nature",
          "brightness",
          "summer",
          "beach",
          "spring",
          "black sun with rays",
        ],
        name: "black sun with rays",
        order: 182,
        unified: "2600-FE0F",
        emoji: "☀️",
      },
      {
        keywords: [
          "moon",
          "full moon face",
          "nature",
          "twilight",
          "planet",
          "space",
          "night",
          "evening",
          "sleep",
          "full moon with face",
        ],
        name: "full moon with face",
        order: 183,
        unified: "1F31D",
        emoji: "🌝",
      },
      {
        keywords: [
          "sunshine",
          "sunny",
          "sun",
          "sun with face",
          "nature",
          "morning",
          "sky",
        ],
        name: "sun with face",
        order: 184,
        unified: "1F31E",
        emoji: "🌞",
      },
      {
        keywords: ["ringed planet", "outerspace"],
        name: "ringed planet",
        order: 185,
        unified: "1FA90",
        emoji: "🪐",
      },
      {
        keywords: ["star", "night", "yellow", "white medium star"],
        name: "white medium star",
        order: 186,
        unified: "2B50",
        emoji: "⭐",
      },
      {
        keywords: [
          "star",
          "shining star",
          "bright star",
          "twinkling star",
          "stars",
          "bright stars",
          "twinkling stars",
          "glowing star",
          "night",
          "sparkle",
          "awesome",
          "good",
          "magic",
          "star2",
        ],
        name: "glowing star",
        order: 187,
        unified: "1F31F",
        emoji: "🌟",
      },
      {
        keywords: [
          "shooting star",
          "shooting stars",
          "night",
          "photo",
          "stars",
        ],
        name: "shooting star",
        order: 188,
        unified: "1F320",
        emoji: "🌠",
      },
      {
        keywords: [
          "starry night",
          "sky",
          "galaxy",
          "constellations",
          "starry nights",
          "skies",
          "galaxies",
          "constellation",
          "milky way",
          "photo",
          "space",
          "stars",
        ],
        name: "milky way",
        order: 189,
        unified: "1F30C",
        emoji: "🌌",
      },
      {
        keywords: ["cloud", "clouds", "weather", "sky"],
        name: "cloud",
        order: 190,
        unified: "2601-FE0F",
        emoji: "☁️",
      },
      {
        keywords: [
          "sun behind cloud",
          "weather",
          "nature",
          "cloudy",
          "morning",
          "fall",
          "spring",
          "partly sunny",
        ],
        name: "sun behind cloud",
        order: 191,
        unified: "26C5",
        emoji: "⛅",
      },
      {
        keywords: [
          "cloud with lightning and rain",
          "weather",
          "lightning",
          "thunder cloud and rain",
        ],
        name: "cloud with lightning and rain",
        order: 192,
        unified: "26C8-FE0F",
        emoji: "⛈️",
      },
      {
        keywords: [
          "sun behind small cloud",
          "weather",
          "mostly sunny",
          "sun small cloud",
        ],
        name: "sun behind small cloud",
        order: 193,
        unified: "1F324-FE0F",
        emoji: "🌤️",
      },
      {
        keywords: [
          "sun behind large cloud",
          "weather",
          "barely sunny",
          "sun behind cloud",
        ],
        name: "sun behind large cloud",
        order: 194,
        unified: "1F325-FE0F",
        emoji: "🌥️",
      },
      {
        keywords: ["sun behind rain cloud", "weather", "partly sunny rain"],
        name: "sun behind rain cloud",
        order: 195,
        unified: "1F326-FE0F",
        emoji: "🌦️",
      },
      {
        keywords: ["cloud with rain", "weather", "rain cloud"],
        name: "cloud with rain",
        order: 196,
        unified: "1F327-FE0F",
        emoji: "🌧️",
      },
      {
        keywords: ["cloud with snow", "weather", "snow cloud"],
        name: "cloud with snow",
        order: 197,
        unified: "1F328-FE0F",
        emoji: "🌨️",
      },
      {
        keywords: [
          "cloud with lightning",
          "weather",
          "thunder",
          "lightning",
          "lightning cloud",
        ],
        name: "cloud with lightning",
        order: 198,
        unified: "1F329-FE0F",
        emoji: "🌩️",
      },
      {
        keywords: ["tornado", "weather", "cyclone", "twister", "tornado cloud"],
        name: "tornado",
        order: 199,
        unified: "1F32A-FE0F",
        emoji: "🌪️",
      },
      {
        keywords: ["fog", "weather"],
        name: "fog",
        order: 200,
        unified: "1F32B-FE0F",
        emoji: "🌫️",
      },
      {
        keywords: ["wind face", "gust", "air", "wind blowing face"],
        name: "wind face",
        order: 201,
        unified: "1F32C-FE0F",
        emoji: "🌬️",
      },
      {
        keywords: [
          "cyclone",
          "cyclones",
          "hurricane",
          "hurricanes",
          "weather",
          "swirl",
          "blue",
          "cloud",
          "vortex",
          "spiral",
          "whirlpool",
          "spin",
          "tornado",
          "typhoon",
        ],
        name: "cyclone",
        order: 202,
        unified: "1F300",
        emoji: "🌀",
      },
      {
        keywords: [
          "rainbow",
          "rainbows",
          "nature",
          "happy",
          "unicorn face",
          "photo",
          "sky",
          "spring",
        ],
        name: "rainbow",
        order: 203,
        unified: "1F308",
        emoji: "🌈",
      },
      {
        keywords: [
          "umbrella",
          "umbrellas",
          "closed umbrella",
          "weather",
          "rain",
          "drizzle",
        ],
        name: "closed umbrella",
        order: 204,
        unified: "1F302",
        emoji: "🌂",
      },
      {
        keywords: ["umbrella", "weather", "spring"],
        name: "umbrella",
        order: 205,
        unified: "2602-FE0F",
        emoji: "☂️",
      },
      {
        keywords: ["umbrella with rain drops", "rainy", "weather", "spring"],
        name: "umbrella with rain drops",
        order: 206,
        unified: "2614",
        emoji: "☔",
      },
      {
        keywords: ["umbrella on ground", "weather", "summer"],
        name: "umbrella on ground",
        order: 207,
        unified: "26F1-FE0F",
        emoji: "⛱️",
      },
      {
        keywords: [
          "high voltage",
          "thunder",
          "weather",
          "lightning bolt",
          "fast",
          "zap",
          "high voltage sign",
        ],
        name: "high voltage sign",
        order: 208,
        unified: "26A1",
        emoji: "⚡",
      },
      {
        keywords: [
          "snow",
          "snowflake",
          "winter",
          "icy",
          "ice cold",
          "snowflakes",
          "season",
          "cold",
          "weather",
          "christmas",
          "xmas",
        ],
        name: "snowflake",
        order: 209,
        unified: "2744-FE0F",
        emoji: "❄️",
      },
      {
        keywords: [
          "snowman",
          "winter",
          "season",
          "cold",
          "weather",
          "christmas",
          "xmas",
          "frozen",
        ],
        name: "snowman",
        order: 210,
        unified: "2603-FE0F",
        emoji: "☃️",
      },
      {
        keywords: [
          "snowman without snow",
          "winter",
          "season",
          "cold",
          "weather",
          "christmas",
          "xmas",
          "frozen",
          "without snow",
        ],
        name: "snowman without snow",
        order: 211,
        unified: "26C4",
        emoji: "⛄",
      },
      {
        keywords: ["comet", "space"],
        name: "comet",
        order: 212,
        unified: "2604-FE0F",
        emoji: "☄️",
      },
      {
        keywords: [
          "bonfire",
          "campfire",
          "flames",
          "fire",
          "bonfires",
          "campfires",
          "flame",
          "fires",
          "hot",
          "cook",
        ],
        name: "fire",
        order: 213,
        unified: "1F525",
        emoji: "🔥",
      },
      {
        keywords: [
          "water",
          "sweat",
          "droplet",
          "droplets",
          "drip",
          "water drop",
          "faucet",
          "spring",
        ],
        name: "droplet",
        order: 214,
        unified: "1F4A7",
        emoji: "💧",
      },
      {
        keywords: [
          "ocean",
          "sea",
          "surf",
          "tsunami",
          "waves",
          "tsunamis",
          "wave",
          "oceans",
          "water wave",
          "water",
          "nature",
          "disaster",
        ],
        name: "water wave",
        order: 215,
        unified: "1F30A",
        emoji: "🌊",
      },
    ],
  },
  {
    title: "objects",
    data: [
      {
        keywords: [
          "glasses",
          "spectacles",
          "fashion",
          "accessories",
          "eyesight",
          "nerdy",
          "dork",
          "geek",
          "eyeglasses",
        ],
        name: "eyeglasses",
        order: 1,
        unified: "1F453",
        emoji: "👓",
      },
      {
        keywords: [
          "sunglasses",
          "face",
          "cool",
          "accessories",
          "dark sunglasses",
        ],
        name: "sunglasses",
        order: 2,
        unified: "1F576-FE0F",
        emoji: "🕶️",
      },
      {
        keywords: ["goggles", "eyes", "protection", "safety"],
        name: "goggles",
        order: 3,
        unified: "1F97D",
        emoji: "🥽",
      },
      {
        keywords: ["lab coat", "doctor", "experiment", "scientist", "chemist"],
        name: "lab coat",
        order: 4,
        unified: "1F97C",
        emoji: "🥼",
      },
      {
        keywords: ["safety vest", "protection"],
        name: "safety vest",
        order: 5,
        unified: "1F9BA",
        emoji: "🦺",
      },
      {
        keywords: [
          "necktie",
          "shirt",
          "neckties",
          "shirts",
          "dress shirt",
          "dress shirts",
          "suitup",
          "formal",
          "fashion",
          "cloth",
          "business",
        ],
        name: "necktie",
        order: 6,
        unified: "1F454",
        emoji: "👔",
      },
      {
        keywords: [
          "shirt",
          "polo",
          "shirts",
          "polos",
          "t shirt",
          "fashion",
          "cloth",
          "casual",
          "tee",
          "tshirt",
        ],
        name: "t-shirt",
        order: 7,
        unified: "1F455",
        emoji: "👕",
      },
      {
        keywords: ["jeans", "pants", "slacks", "fashion", "shopping"],
        name: "jeans",
        order: 8,
        unified: "1F456",
        emoji: "👖",
      },
      {
        keywords: ["scarf", "scarves", "neck", "winter", "clothes"],
        name: "scarf",
        order: 9,
        unified: "1F9E3",
        emoji: "🧣",
      },
      {
        keywords: ["glove", "gloves", "hands", "winter", "clothes"],
        name: "gloves",
        order: 10,
        unified: "1F9E4",
        emoji: "🧤",
      },
      {
        keywords: ["coat", "coats", "jacket", "jackets"],
        name: "coat",
        order: 11,
        unified: "1F9E5",
        emoji: "🧥",
      },
      {
        keywords: ["socks", "stockings", "clothes"],
        name: "socks",
        order: 12,
        unified: "1F9E6",
        emoji: "🧦",
      },
      {
        keywords: ["dress", "dresses", "clothes", "fashion", "shopping"],
        name: "dress",
        order: 13,
        unified: "1F457",
        emoji: "👗",
      },
      {
        keywords: ["kimono", "dress", "fashion", "women", "female", "japanese"],
        name: "kimono",
        order: 14,
        unified: "1F458",
        emoji: "👘",
      },
      {
        keywords: ["sari", "dress"],
        name: "sari",
        order: 15,
        unified: "1F97B",
        emoji: "🥻",
      },
      {
        keywords: ["one piece swimsuit", "fashion"],
        name: "one-piece swimsuit",
        order: 16,
        unified: "1FA71",
        emoji: "🩱",
      },
      {
        keywords: ["briefs", "clothing"],
        name: "briefs",
        order: 17,
        unified: "1FA72",
        emoji: "🩲",
      },
      {
        keywords: ["shorts", "clothing"],
        name: "shorts",
        order: 18,
        unified: "1FA73",
        emoji: "🩳",
      },
      {
        keywords: [
          "bikini",
          "bathing suit",
          "bikinis",
          "bathing suits",
          "swimming",
          "female",
          "woman",
          "girl",
          "fashion",
          "beach",
          "summer",
        ],
        name: "bikini",
        order: 19,
        unified: "1F459",
        emoji: "👙",
      },
      {
        keywords: [
          "shirt",
          "blouse",
          "shirts",
          "blouses",
          "woman s clothes",
          "fashion",
          "shopping bags",
          "female",
          "womans clothes",
        ],
        name: "womans clothes",
        order: 20,
        unified: "1F45A",
        emoji: "👚",
      },
      {
        keywords: [
          "purse",
          "purses",
          "fashion",
          "accessories",
          "money",
          "sales",
          "shopping",
        ],
        name: "purse",
        order: 21,
        unified: "1F45B",
        emoji: "👛",
      },
      {
        keywords: [
          "handbag",
          "purse",
          "tote",
          "handbags",
          "purses",
          "totes",
          "fashion",
          "accessory",
          "accessories",
          "shopping",
        ],
        name: "handbag",
        order: 22,
        unified: "1F45C",
        emoji: "👜",
      },
      {
        keywords: ["clutch bag", "bag", "accessories", "shopping", "pouch"],
        name: "pouch",
        order: 23,
        unified: "1F45D",
        emoji: "👝",
      },
      {
        keywords: ["shopping bags", "mall", "buy", "purchase"],
        name: "shopping bags",
        order: 24,
        unified: "1F6CD-FE0F",
        emoji: "🛍️",
      },
      {
        keywords: [
          "backpack",
          "rucksack",
          "backpacks",
          "rucksacks",
          "student",
          "education",
          "bag",
          "school satchel",
        ],
        name: "school satchel",
        order: 25,
        unified: "1F392",
        emoji: "🎒",
      },
      {
        keywords: ["thong sandal", "footwear", "summer"],
        name: "thong sandal",
        order: 26,
        unified: "1FA74",
        emoji: "🩴",
      },
      {
        keywords: [
          "shoe",
          "oxfords",
          "loafers",
          "shoes",
          "man s shoe",
          "fashion",
          "male",
          "mans shoe",
        ],
        name: "mans shoe",
        order: 27,
        unified: "1F45E",
        emoji: "👞",
      },
      {
        keywords: [
          "shoe",
          "sneakers",
          "gym shoe",
          "shoes",
          "sneaker",
          "gym shoes",
          "trainers",
          "running shoe",
          "sports",
          "athletic shoe",
        ],
        name: "athletic shoe",
        order: 28,
        unified: "1F45F",
        emoji: "👟",
      },
      {
        keywords: ["hiking boot", "backpacking", "camping", "hiking"],
        name: "hiking boot",
        order: 29,
        unified: "1F97E",
        emoji: "🥾",
      },
      {
        keywords: [
          "flat shoe",
          "ballet",
          "slip on",
          "slipper",
          "womans flat shoe",
        ],
        name: "flat shoe",
        order: 30,
        unified: "1F97F",
        emoji: "🥿",
      },
      {
        keywords: [
          "shoe",
          "heels",
          "stilettos",
          "high heels",
          "high heeled shoe",
          "shoes",
          "high heeled shoes",
          "fashion",
          "female",
          "pumps",
          "stiletto",
          "high heel",
        ],
        name: "high-heeled shoe",
        order: 31,
        unified: "1F460",
        emoji: "👠",
      },
      {
        keywords: [
          "sandal",
          "sandals",
          "woman s sandal",
          "shoes",
          "fashion",
          "flip flops",
          "womans sandal",
        ],
        name: "womans sandal",
        order: 32,
        unified: "1F461",
        emoji: "👡",
      },
      {
        keywords: ["ballet shoes", "dance"],
        name: "ballet shoes",
        order: 33,
        unified: "1FA70",
        emoji: "🩰",
      },
      {
        keywords: [
          "boot",
          "boots",
          "woman s boot",
          "shoes",
          "fashion",
          "womans boots",
        ],
        name: "womans boots",
        order: 34,
        unified: "1F462",
        emoji: "👢",
      },
      {
        keywords: [
          "crown",
          "king",
          "queen",
          "royal",
          "prince",
          "princess",
          "kod",
          "leader",
          "royalty",
          "lord",
        ],
        name: "crown",
        order: 35,
        unified: "1F451",
        emoji: "👑",
      },
      {
        keywords: [
          "woman’s hat",
          "ladies hat",
          "woman s hat",
          "fashion",
          "accessories",
          "female",
          "lady",
          "spring",
          "womans hat",
        ],
        name: "womans hat",
        order: 36,
        unified: "1F452",
        emoji: "👒",
      },
      {
        keywords: [
          "hat",
          "magician",
          "wedding",
          "hats",
          "magicians",
          "weddings",
          "top hat",
          "magic",
          "gentleman",
          "classy",
          "circus",
          "tophat",
        ],
        name: "top hat",
        order: 37,
        unified: "1F3A9",
        emoji: "🎩",
      },
      {
        keywords: [
          "graduation",
          "graduate",
          "cap and gown",
          "graduates",
          "graduation cap",
          "school",
          "college",
          "degree",
          "university",
          "cap",
          "hat",
          "legal",
          "learn",
          "education",
          "mortar board",
        ],
        name: "graduation cap",
        order: 38,
        unified: "1F393",
        emoji: "🎓",
      },
      {
        keywords: ["hat", "hats", "cap", "caps", "billed cap", "baseball"],
        name: "billed cap",
        order: 39,
        unified: "1F9E2",
        emoji: "🧢",
      },
      {
        keywords: ["military helmet", "army", "protection"],
        name: "military helmet",
        order: 40,
        unified: "1FA96",
        emoji: "🪖",
      },
      {
        keywords: [
          "rescue worker s helmet",
          "construction",
          "build",
          "helmet with white cross",
          "rescue workerâ€™s helmet",
        ],
        name: "rescue workerâ€™s helmet",
        order: 41,
        unified: "26D1-FE0F",
        emoji: "⛑️",
      },
      {
        keywords: ["prayer beads", "dhikr", "religious"],
        name: "prayer beads",
        order: 42,
        unified: "1F4FF",
        emoji: "📿",
      },
      {
        keywords: [
          "lipstick",
          "lipgloss",
          "makeup",
          "female",
          "girl",
          "fashion",
          "woman",
        ],
        name: "lipstick",
        order: 43,
        unified: "1F484",
        emoji: "💄",
      },
      {
        keywords: [
          "diamond",
          "engagement ring",
          "diamond ring",
          "diamond rings",
          "diamonds",
          "engagement rings",
          "ring",
          "wedding",
          "propose",
          "marriage",
          "valentines",
          "fashion",
          "jewelry",
          "gem",
          "engagement",
        ],
        name: "ring",
        order: 44,
        unified: "1F48D",
        emoji: "💍",
      },
      {
        keywords: [
          "diamond",
          "gem",
          "gemstone",
          "jewel",
          "diamonds",
          "gems",
          "gemstones",
          "jewels",
          "gem stone",
          "blue",
          "ruby",
          "jewelry",
        ],
        name: "gem stone",
        order: 45,
        unified: "1F48E",
        emoji: "💎",
      },
      {
        keywords: [
          "muted speaker",
          "sound",
          "volume",
          "silence",
          "quiet",
          "mute",
          "speaker with cancellation stroke",
        ],
        name: "speaker with cancellation stroke",
        order: 46,
        unified: "1F507",
        emoji: "🔇",
      },
      {
        keywords: [
          "speaker low volume",
          "sound",
          "volume",
          "silence",
          "broadcast",
          "speaker",
        ],
        name: "speaker",
        order: 47,
        unified: "1F508",
        emoji: "🔈",
      },
      {
        keywords: [
          "speaker medium volume",
          "volume",
          "speaker",
          "broadcast",
          "sound",
          "speaker with one sound wave",
        ],
        name: "speaker with one sound wave",
        order: 48,
        unified: "1F509",
        emoji: "🔉",
      },
      {
        keywords: [
          "speaker",
          "subwoofer",
          "speakers",
          "subwoofers",
          "speaker high volume",
          "volume",
          "noise",
          "noisy",
          "broadcast",
          "loud sound",
          "speaker with three sound waves",
        ],
        name: "speaker with three sound waves",
        order: 49,
        unified: "1F50A",
        emoji: "🔊",
      },
      {
        keywords: [
          "megaphone",
          "loudspeaker",
          "bullhorn",
          "megaphones",
          "loudspeakers",
          "bullhorns",
          "volume",
          "sound",
          "public address loudspeaker",
        ],
        name: "public address loudspeaker",
        order: 50,
        unified: "1F4E2",
        emoji: "📢",
      },
      {
        keywords: [
          "megaphone",
          "megaphones",
          "cheerleader",
          "cheerleaders",
          "sound",
          "speaker",
          "volume",
          "mega",
          "cheering megaphone",
        ],
        name: "cheering megaphone",
        order: 51,
        unified: "1F4E3",
        emoji: "📣",
      },
      {
        keywords: ["postal horn", "instrument", "music"],
        name: "postal horn",
        order: 52,
        unified: "1F4EF",
        emoji: "📯",
      },
      {
        keywords: [
          "church bell",
          "church bells",
          "bell",
          "sound",
          "notification",
          "christmas",
          "xmas",
          "chime",
        ],
        name: "bell",
        order: 53,
        unified: "1F514",
        emoji: "🔔",
      },
      {
        keywords: [
          "bell with slash",
          "sound",
          "volume",
          "mute",
          "quiet",
          "silent",
          "no bell",
          "bell with cancellation stroke",
        ],
        name: "bell with cancellation stroke",
        order: 54,
        unified: "1F515",
        emoji: "🔕",
      },
      {
        keywords: [
          "treble clef",
          "music",
          "music score",
          "musical score",
          "treble",
          "clef",
          "compose",
        ],
        name: "musical score",
        order: 55,
        unified: "1F3BC",
        emoji: "🎼",
      },
      {
        keywords: ["sing", "music", "musical note", "score", "tone", "sound"],
        name: "musical note",
        order: 56,
        unified: "1F3B5",
        emoji: "🎵",
      },
      {
        keywords: [
          "sing",
          "music",
          "musical notes",
          "score",
          "notes",
          "multiple musical notes",
        ],
        name: "multiple musical notes",
        order: 57,
        unified: "1F3B6",
        emoji: "🎶",
      },
      {
        keywords: [
          "studio microphone",
          "sing",
          "recording",
          "artist",
          "talkshow",
        ],
        name: "studio microphone",
        order: 58,
        unified: "1F399-FE0F",
        emoji: "🎙️",
      },
      {
        keywords: ["level slider", "scale"],
        name: "level slider",
        order: 59,
        unified: "1F39A-FE0F",
        emoji: "🎚️",
      },
      {
        keywords: ["control knobs", "dial"],
        name: "control knobs",
        order: 60,
        unified: "1F39B-FE0F",
        emoji: "🎛️",
      },
      {
        keywords: [
          "karaoke",
          "sing",
          "mic",
          "microphone",
          "singing",
          "microphones",
          "sound",
          "music",
          "pa",
          "talkshow",
        ],
        name: "microphone",
        order: 61,
        unified: "1F3A4",
        emoji: "🎤",
      },
      {
        keywords: [
          "earpods",
          "music",
          "headphone",
          "headphones",
          "score",
          "gadgets",
        ],
        name: "headphone",
        order: 62,
        unified: "1F3A7",
        emoji: "🎧",
      },
      {
        keywords: [
          "radio",
          "radios",
          "fm",
          "communication",
          "music",
          "podcast",
          "program",
        ],
        name: "radio",
        order: 63,
        unified: "1F4FB",
        emoji: "📻",
      },
      {
        keywords: [
          "saxophone",
          "sax",
          "instrument",
          "music",
          "saxophones",
          "jazz",
          "blues",
        ],
        name: "saxophone",
        order: 64,
        unified: "1F3B7",
        emoji: "🎷",
      },
      {
        keywords: ["accordion", "music"],
        name: "accordion",
        order: 65,
        unified: "1FA97",
        emoji: "🪗",
      },
      {
        keywords: [
          "electric guitar",
          "guitar",
          "rock and roll",
          "guitars",
          "electric guitars",
          "musical instrument",
          "musical instruments",
          "music",
          "instrument",
        ],
        name: "guitar",
        order: 66,
        unified: "1F3B8",
        emoji: "🎸",
      },
      {
        keywords: [
          "keyboard",
          "keys",
          "music",
          "keyboards",
          "piano",
          "pianos",
          "musical keyboard",
          "instrument",
          "compose",
        ],
        name: "musical keyboard",
        order: 67,
        unified: "1F3B9",
        emoji: "🎹",
      },
      {
        keywords: [
          "trumpet",
          "instrument",
          "music",
          "trumpets",
          "instruments",
          "brass",
        ],
        name: "trumpet",
        order: 68,
        unified: "1F3BA",
        emoji: "🎺",
      },
      {
        keywords: [
          "violin",
          "instrument",
          "music",
          "violins",
          "instruments",
          "orchestra",
          "symphony",
        ],
        name: "violin",
        order: 69,
        unified: "1F3BB",
        emoji: "🎻",
      },
      {
        keywords: ["banjo", "music", "instructment"],
        name: "banjo",
        order: 70,
        unified: "1FA95",
        emoji: "🪕",
      },
      {
        keywords: [
          "drum",
          "snare drum",
          "drumsticks",
          "drum with drumsticks",
          "musical instrument",
          "music",
          "instrument",
          "snare",
        ],
        name: "drum with drumsticks",
        order: 71,
        unified: "1F941",
        emoji: "🥁",
      },
      {
        keywords: ["long drum", "music"],
        name: "long drum",
        order: 72,
        unified: "1FA98",
        emoji: "🪘",
      },
      {
        keywords: [
          "cellphone",
          "cellphones",
          "mobile phone",
          "mobile phones",
          "iphone",
          "ipod",
          "phone",
          "iphones",
          "ipods",
          "phones",
          "technology",
          "apple",
          "gadgets",
          "dial",
        ],
        name: "mobile phone",
        order: 73,
        unified: "1F4F1",
        emoji: "📱",
      },
      {
        keywords: [
          "phone",
          "iphone",
          "phones",
          "iphones",
          "mobile phone with arrow",
          "incoming",
          "calling",
          "mobile phone with rightwards arrow at left",
        ],
        name: "mobile phone with rightwards arrow at left",
        order: 74,
        unified: "1F4F2",
        emoji: "📲",
      },
      {
        keywords: [
          "telephone",
          "phone",
          "telephones",
          "phones",
          "technology",
          "communication",
          "dial",
          "black telephone",
        ],
        name: "black telephone",
        order: 75,
        unified: "260E-FE0F",
        emoji: "☎️",
      },
      {
        keywords: [
          "telephone",
          "handset",
          "phone",
          "landline",
          "telephones",
          "handsets",
          "phones",
          "landlines",
          "telephone receiver",
          "technology",
          "communication",
          "dial",
        ],
        name: "telephone receiver",
        order: 76,
        unified: "1F4DE",
        emoji: "📞",
      },
      {
        keywords: ["pager", "bbcall", "oldschool", "90s"],
        name: "pager",
        order: 77,
        unified: "1F4DF",
        emoji: "📟",
      },
      {
        keywords: ["fax machine", "communication", "technology", "fax"],
        name: "fax machine",
        order: 78,
        unified: "1F4E0",
        emoji: "📠",
      },
      {
        keywords: ["battery", "power", "energy", "sustain"],
        name: "battery",
        order: 79,
        unified: "1F50B",
        emoji: "🔋",
      },
      {
        keywords: ["electric plug", "charger", "power"],
        name: "electric plug",
        order: 80,
        unified: "1F50C",
        emoji: "🔌",
      },
      {
        keywords: [
          "laptop",
          "macbook",
          "macbook pro",
          "macbook air",
          "computer",
          "laptops",
          "macbooks",
          "macbook pros",
          "macbook airs",
          "computers",
          "mac",
          "technology",
          "screen",
          "display",
          "monitor",
          "personal computer",
        ],
        name: "personal computer",
        order: 81,
        unified: "1F4BB",
        emoji: "💻",
      },
      {
        keywords: ["desktop computer", "technology", "computing", "screen"],
        name: "desktop computer",
        order: 82,
        unified: "1F5A5-FE0F",
        emoji: "🖥️",
      },
      {
        keywords: ["printer", "paper", "ink"],
        name: "printer",
        order: 83,
        unified: "1F5A8-FE0F",
        emoji: "🖨️",
      },
      {
        keywords: [
          "keyboard",
          "technology",
          "computer",
          "type",
          "input",
          "text",
        ],
        name: "keyboard",
        order: 84,
        unified: "2328-FE0F",
        emoji: "⌨️",
      },
      {
        keywords: ["computer mouse", "click", "three button mouse"],
        name: "computer mouse",
        order: 85,
        unified: "1F5B1-FE0F",
        emoji: "🖱️",
      },
      {
        keywords: ["trackball", "technology", "trackpad"],
        name: "trackball",
        order: 86,
        unified: "1F5B2-FE0F",
        emoji: "🖲️",
      },
      {
        keywords: [
          "computer disk",
          "technology",
          "record",
          "data",
          "disk",
          "90s",
          "minidisc",
        ],
        name: "minidisc",
        order: 87,
        unified: "1F4BD",
        emoji: "💽",
      },
      {
        keywords: [
          "floppy disk",
          "oldschool",
          "technology",
          "save",
          "90s",
          "80s",
        ],
        name: "floppy disk",
        order: 88,
        unified: "1F4BE",
        emoji: "💾",
      },
      {
        keywords: [
          "optical disk",
          "technology",
          "dvd",
          "disk",
          "disc",
          "90s",
          "cd",
          "optical disc",
        ],
        name: "optical disc",
        order: 89,
        unified: "1F4BF",
        emoji: "💿",
      },
      {
        keywords: ["dvd", "dvds", "cd", "disk", "disc"],
        name: "dvd",
        order: 90,
        unified: "1F4C0",
        emoji: "📀",
      },
      {
        keywords: ["abacus", "calculation"],
        name: "abacus",
        order: 91,
        unified: "1F9EE",
        emoji: "🧮",
      },
      {
        keywords: [
          "movie",
          "camera",
          "movies",
          "cameras",
          "movie camera",
          "film",
          "record",
        ],
        name: "movie camera",
        order: 92,
        unified: "1F3A5",
        emoji: "🎥",
      },
      {
        keywords: ["film frames", "movie"],
        name: "film frames",
        order: 93,
        unified: "1F39E-FE0F",
        emoji: "🎞️",
      },
      {
        keywords: ["film projector", "video", "tape", "record", "movie"],
        name: "film projector",
        order: 94,
        unified: "1F4FD-FE0F",
        emoji: "📽️",
      },
      {
        keywords: [
          "clapper board",
          "clapper boards",
          "movie",
          "film",
          "record",
          "clapper",
        ],
        name: "clapper board",
        order: 95,
        unified: "1F3AC",
        emoji: "🎬",
      },
      {
        keywords: [
          "tv",
          "television",
          "screen",
          "technology",
          "program",
          "oldschool",
          "show",
        ],
        name: "television",
        order: 96,
        unified: "1F4FA",
        emoji: "📺",
      },
      {
        keywords: ["camera", "cameras", "gadgets", "photography"],
        name: "camera",
        order: 97,
        unified: "1F4F7",
        emoji: "📷",
      },
      {
        keywords: ["camera with flash", "photography", "gadgets"],
        name: "camera with flash",
        order: 98,
        unified: "1F4F8",
        emoji: "📸",
      },
      {
        keywords: ["video camera", "film", "record"],
        name: "video camera",
        order: 99,
        unified: "1F4F9",
        emoji: "📹",
      },
      {
        keywords: [
          "videocassette",
          "record",
          "video",
          "oldschool",
          "90s",
          "80s",
          "vhs",
        ],
        name: "videocassette",
        order: 100,
        unified: "1F4FC",
        emoji: "📼",
      },
      {
        keywords: [
          "magnifying glass tilted left",
          "search",
          "zoom",
          "find",
          "detective",
          "mag",
          "left pointing magnifying glass",
        ],
        name: "left-pointing magnifying glass",
        order: 101,
        unified: "1F50D",
        emoji: "🔍",
      },
      {
        keywords: [
          "magnifying glass tilted right",
          "search",
          "zoom",
          "find",
          "detective",
          "mag right",
          "right pointing magnifying glass",
        ],
        name: "right-pointing magnifying glass",
        order: 102,
        unified: "1F50E",
        emoji: "🔎",
      },
      {
        keywords: ["candle", "fire", "wax"],
        name: "candle",
        order: 103,
        unified: "1F56F-FE0F",
        emoji: "🕯️",
      },
      {
        keywords: [
          "bulb",
          "bulbs",
          "light bulb",
          "idea",
          "light bulbs",
          "light",
          "ideas",
          "electricity",
          "electric light bulb",
        ],
        name: "electric light bulb",
        order: 104,
        unified: "1F4A1",
        emoji: "💡",
      },
      {
        keywords: [
          "flashlight",
          "flashlights",
          "dark",
          "camping",
          "sight",
          "night",
          "electric torch",
        ],
        name: "electric torch",
        order: 105,
        unified: "1F526",
        emoji: "🔦",
      },
      {
        keywords: [
          "izakaya",
          "izakaya lantern",
          "red paper lantern",
          "light",
          "paper",
          "halloween",
          "spooky",
          "lantern",
        ],
        name: "izakaya lantern",
        order: 106,
        unified: "1F3EE",
        emoji: "🏮",
      },
      {
        keywords: ["diya lamp", "lighting"],
        name: "diya lamp",
        order: 107,
        unified: "1FA94",
        emoji: "🪔",
      },
      {
        keywords: [
          "notebook with decorative cover",
          "classroom",
          "notes",
          "record",
          "paper",
          "study",
        ],
        name: "notebook with decorative cover",
        order: 108,
        unified: "1F4D4",
        emoji: "📔",
      },
      {
        keywords: [
          "closed book",
          "read",
          "library",
          "knowledge",
          "textbook",
          "learn",
        ],
        name: "closed book",
        order: 109,
        unified: "1F4D5",
        emoji: "📕",
      },
      {
        keywords: [
          "book",
          "study",
          "reading",
          "books",
          "open book",
          "read",
          "library",
          "knowledge",
          "literature",
          "learn",
        ],
        name: "open book",
        order: 110,
        unified: "1F4D6",
        emoji: "📖",
      },
      {
        keywords: ["green book", "read", "library", "knowledge", "study"],
        name: "green book",
        order: 111,
        unified: "1F4D7",
        emoji: "📗",
      },
      {
        keywords: [
          "blue book",
          "read",
          "library",
          "knowledge",
          "learn",
          "study",
        ],
        name: "blue book",
        order: 112,
        unified: "1F4D8",
        emoji: "📘",
      },
      {
        keywords: [
          "orange book",
          "read",
          "library",
          "knowledge",
          "textbook",
          "study",
        ],
        name: "orange book",
        order: 113,
        unified: "1F4D9",
        emoji: "📙",
      },
      {
        keywords: [
          "books",
          "textbooks",
          "library",
          "homework",
          "book",
          "textbook",
          "literature",
          "study",
        ],
        name: "books",
        order: 114,
        unified: "1F4DA",
        emoji: "📚",
      },
      {
        keywords: [
          "notebook",
          "journal",
          "notebooks",
          "stationery",
          "record",
          "notes",
          "paper",
          "study",
        ],
        name: "notebook",
        order: 115,
        unified: "1F4D3",
        emoji: "📓",
      },
      {
        keywords: ["ledger", "notes", "paper"],
        name: "ledger",
        order: 116,
        unified: "1F4D2",
        emoji: "📒",
      },
      {
        keywords: ["page with curl", "documents", "office", "paper"],
        name: "page with curl",
        order: 117,
        unified: "1F4C3",
        emoji: "📃",
      },
      {
        keywords: ["scroll", "documents", "ancient", "history", "paper"],
        name: "scroll",
        order: 118,
        unified: "1F4DC",
        emoji: "📜",
      },
      {
        keywords: [
          "page facing up",
          "documents",
          "office",
          "paper",
          "information",
        ],
        name: "page facing up",
        order: 119,
        unified: "1F4C4",
        emoji: "📄",
      },
      {
        keywords: ["newspaper", "press", "headline"],
        name: "newspaper",
        order: 120,
        unified: "1F4F0",
        emoji: "📰",
      },
      {
        keywords: ["rolled up newspaper", "press", "headline"],
        name: "rolled-up newspaper",
        order: 121,
        unified: "1F5DE-FE0F",
        emoji: "🗞️",
      },
      {
        keywords: ["bookmark tabs", "favorite", "save", "order", "tidy"],
        name: "bookmark tabs",
        order: 122,
        unified: "1F4D1",
        emoji: "📑",
      },
      {
        keywords: ["bookmark", "favorite", "label", "save"],
        name: "bookmark",
        order: 123,
        unified: "1F516",
        emoji: "🔖",
      },
      {
        keywords: ["label", "sale", "tag"],
        name: "label",
        order: 124,
        unified: "1F3F7-FE0F",
        emoji: "🏷️",
      },
      {
        keywords: [
          "money",
          "cash",
          "pay",
          "money bag",
          "dollar",
          "payment",
          "coins",
          "sale",
          "moneybag",
        ],
        name: "money bag",
        order: 125,
        unified: "1F4B0",
        emoji: "💰",
      },
      {
        keywords: ["coin", "money", "currency"],
        name: "coin",
        order: 126,
        unified: "1FA99",
        emoji: "🪙",
      },
      {
        keywords: [
          "yen",
          "cash",
          "money",
          "currency",
          "yen banknote",
          "sales",
          "japanese",
          "dollar",
          "banknote with yen sign",
        ],
        name: "banknote with yen sign",
        order: 127,
        unified: "1F4B4",
        emoji: "💴",
      },
      {
        keywords: [
          "dollar",
          "cash",
          "money",
          "dollars",
          "dollar banknote",
          "sales",
          "bill",
          "currency",
          "banknote with dollar sign",
        ],
        name: "banknote with dollar sign",
        order: 128,
        unified: "1F4B5",
        emoji: "💵",
      },
      {
        keywords: [
          "euro",
          "euros",
          "euro banknotes",
          "money",
          "cash",
          "euro banknote",
          "sales",
          "dollar",
          "currency",
          "banknote with euro sign",
        ],
        name: "banknote with euro sign",
        order: 129,
        unified: "1F4B6",
        emoji: "💶",
      },
      {
        keywords: [
          "money",
          "cash",
          "sterling pounds",
          "british pounds",
          "quid",
          "pound banknote",
          "british",
          "sterling",
          "sales",
          "bills",
          "uk",
          "england",
          "currency",
          "pound",
          "banknote with pound sign",
        ],
        name: "banknote with pound sign",
        order: 130,
        unified: "1F4B7",
        emoji: "💷",
      },
      {
        keywords: [
          "cash",
          "money",
          "money with wings",
          "dollar",
          "bills",
          "payment",
          "sale",
        ],
        name: "money with wings",
        order: 131,
        unified: "1F4B8",
        emoji: "💸",
      },
      {
        keywords: [
          "credit card",
          "credit cards",
          "debit card",
          "debit cards",
          "money",
          "sales",
          "dollar",
          "bill",
          "payment",
          "shopping",
        ],
        name: "credit card",
        order: 132,
        unified: "1F4B3",
        emoji: "💳",
      },
      {
        keywords: ["receipt", "accounting", "expenses"],
        name: "receipt",
        order: 133,
        unified: "1F9FE",
        emoji: "🧾",
      },
      {
        keywords: [
          "chart increasing with yen",
          "green square",
          "graph",
          "presentation",
          "stats",
          "chart",
          "chart with upwards trend and yen sign",
        ],
        name: "chart with upwards trend and yen sign",
        order: 134,
        unified: "1F4B9",
        emoji: "💹",
      },
      {
        keywords: [
          "envelope",
          "envelopes",
          "letter",
          "postal",
          "inbox",
          "communication",
          "email",
        ],
        name: "envelope",
        order: 135,
        unified: "2709-FE0F",
        emoji: "✉️",
      },
      {
        keywords: ["e mail", "communication", "inbox", "e mail symbol"],
        name: "e-mail symbol",
        order: 136,
        unified: "1F4E7",
        emoji: "📧",
      },
      {
        keywords: ["incoming envelope", "email", "inbox"],
        name: "incoming envelope",
        order: 137,
        unified: "1F4E8",
        emoji: "📨",
      },
      {
        keywords: [
          "envelope with arrow",
          "email",
          "communication",
          "envelope with downwards arrow above",
        ],
        name: "envelope with downwards arrow above",
        order: 138,
        unified: "1F4E9",
        emoji: "📩",
      },
      {
        keywords: ["outbox tray", "inbox", "email"],
        name: "outbox tray",
        order: 139,
        unified: "1F4E4",
        emoji: "📤",
      },
      {
        keywords: ["inbox tray", "email", "documents"],
        name: "inbox tray",
        order: 140,
        unified: "1F4E5",
        emoji: "📥",
      },
      {
        keywords: [
          "package",
          "box",
          "shipment",
          "delivery",
          "packages",
          "boxes",
          "shipments",
          "deliveries",
          "mail",
          "gift",
          "cardboard",
          "moving",
        ],
        name: "package",
        order: 141,
        unified: "1F4E6",
        emoji: "📦",
      },
      {
        keywords: [
          "closed mailbox with raised flag",
          "email",
          "inbox",
          "communication",
          "mailbox",
        ],
        name: "closed mailbox with raised flag",
        order: 142,
        unified: "1F4EB",
        emoji: "📫",
      },
      {
        keywords: [
          "closed mailbox with lowered flag",
          "email",
          "communication",
          "inbox",
          "mailbox closed",
        ],
        name: "closed mailbox with lowered flag",
        order: 143,
        unified: "1F4EA",
        emoji: "📪",
      },
      {
        keywords: [
          "open mailbox with raised flag",
          "email",
          "inbox",
          "communication",
          "mailbox with mail",
        ],
        name: "open mailbox with raised flag",
        order: 144,
        unified: "1F4EC",
        emoji: "📬",
      },
      {
        keywords: [
          "open mailbox with lowered flag",
          "email",
          "inbox",
          "mailbox with no mail",
        ],
        name: "open mailbox with lowered flag",
        order: 145,
        unified: "1F4ED",
        emoji: "📭",
      },
      {
        keywords: ["postbox", "mailbox", "email", "letter", "envelope"],
        name: "postbox",
        order: 146,
        unified: "1F4EE",
        emoji: "📮",
      },
      {
        keywords: ["ballot box with ballot", "election", "vote"],
        name: "ballot box with ballot",
        order: 147,
        unified: "1F5F3-FE0F",
        emoji: "🗳️",
      },
      {
        keywords: [
          "pencil",
          "eraser",
          "pencils",
          "erasers",
          "stationery",
          "write",
          "paper",
          "writing",
          "school",
          "study",
          "pencil2",
        ],
        name: "pencil",
        order: 148,
        unified: "270F-FE0F",
        emoji: "✏️",
      },
      {
        keywords: ["black nib", "pen", "stationery", "writing", "write"],
        name: "black nib",
        order: 149,
        unified: "2712-FE0F",
        emoji: "✒️",
      },
      {
        keywords: [
          "fountain pen",
          "stationery",
          "writing",
          "write",
          "lower left fountain pen",
        ],
        name: "fountain pen",
        order: 150,
        unified: "1F58B-FE0F",
        emoji: "🖋️",
      },
      {
        keywords: [
          "pen",
          "stationery",
          "writing",
          "write",
          "lower left ballpoint pen",
        ],
        name: "pen",
        order: 151,
        unified: "1F58A-FE0F",
        emoji: "🖊️",
      },
      {
        keywords: [
          "paintbrush",
          "drawing",
          "creativity",
          "art",
          "lower left paintbrush",
        ],
        name: "paintbrush",
        order: 152,
        unified: "1F58C-FE0F",
        emoji: "🖌️",
      },
      {
        keywords: ["crayon", "drawing", "creativity", "lower left crayon"],
        name: "crayon",
        order: 153,
        unified: "1F58D-FE0F",
        emoji: "🖍️",
      },
      {
        keywords: [
          "memo",
          "notes",
          "paper",
          "note",
          "memos",
          "notepad",
          "notepads",
          "write",
          "documents",
          "stationery",
          "pencil",
          "writing",
          "legal",
          "exam",
          "quiz",
          "test",
          "study",
          "compose",
        ],
        name: "memo",
        order: 154,
        unified: "1F4DD",
        emoji: "📝",
      },
      {
        keywords: [
          "briefcase",
          "portfolio",
          "bag",
          "briefcases",
          "portfolios",
          "bags",
          "business",
          "documents",
          "work",
          "law",
          "legal",
          "job",
          "career",
        ],
        name: "briefcase",
        order: 155,
        unified: "1F4BC",
        emoji: "💼",
      },
      {
        keywords: ["file folder", "documents", "business", "office"],
        name: "file folder",
        order: 156,
        unified: "1F4C1",
        emoji: "📁",
      },
      {
        keywords: ["open file folder", "documents", "load"],
        name: "open file folder",
        order: 157,
        unified: "1F4C2",
        emoji: "📂",
      },
      {
        keywords: [
          "card index dividers",
          "organizing",
          "business",
          "stationery",
        ],
        name: "card index dividers",
        order: 158,
        unified: "1F5C2-FE0F",
        emoji: "🗂️",
      },
      {
        keywords: ["calendar", "schedule", "date"],
        name: "calendar",
        order: 159,
        unified: "1F4C5",
        emoji: "📅",
      },
      {
        keywords: [
          "calendar",
          "calendars",
          "tear off calendar",
          "schedule",
          "date",
          "planning",
        ],
        name: "tear-off calendar",
        order: 160,
        unified: "1F4C6",
        emoji: "📆",
      },
      {
        keywords: ["spiral notepad", "memo", "stationery", "spiral note pad"],
        name: "spiral notepad",
        order: 161,
        unified: "1F5D2-FE0F",
        emoji: "🗒️",
      },
      {
        keywords: [
          "spiral calendar",
          "date",
          "schedule",
          "planning",
          "spiral calendar pad",
        ],
        name: "spiral calendar",
        order: 162,
        unified: "1F5D3-FE0F",
        emoji: "🗓️",
      },
      {
        keywords: ["card index", "business", "stationery"],
        name: "card index",
        order: 163,
        unified: "1F4C7",
        emoji: "📇",
      },
      {
        keywords: [
          "chart increasing",
          "graph",
          "presentation",
          "stats",
          "recovery",
          "business",
          "economics",
          "money",
          "sales",
          "good",
          "success",
          "chart with upwards trend",
        ],
        name: "chart with upwards trend",
        order: 164,
        unified: "1F4C8",
        emoji: "📈",
      },
      {
        keywords: [
          "chart decreasing",
          "graph",
          "presentation",
          "stats",
          "recession",
          "business",
          "economics",
          "money",
          "sales",
          "bad",
          "failure",
          "chart with downwards trend",
        ],
        name: "chart with downwards trend",
        order: 165,
        unified: "1F4C9",
        emoji: "📉",
      },
      {
        keywords: ["bar chart", "bar charts", "graph", "presentation", "stats"],
        name: "bar chart",
        order: 166,
        unified: "1F4CA",
        emoji: "📊",
      },
      {
        keywords: ["clipboard", "stationery", "documents"],
        name: "clipboard",
        order: 167,
        unified: "1F4CB",
        emoji: "📋",
      },
      {
        keywords: ["pushpin", "pushpins", "stationery", "mark", "here"],
        name: "pushpin",
        order: 168,
        unified: "1F4CC",
        emoji: "📌",
      },
      {
        keywords: ["round pushpin", "stationery", "location", "map", "here"],
        name: "round pushpin",
        order: 169,
        unified: "1F4CD",
        emoji: "📍",
      },
      {
        keywords: ["paperclip", "documents", "stationery"],
        name: "paperclip",
        order: 170,
        unified: "1F4CE",
        emoji: "📎",
      },
      {
        keywords: ["linked paperclips", "documents", "stationery"],
        name: "linked paperclips",
        order: 171,
        unified: "1F587-FE0F",
        emoji: "🖇️",
      },
      {
        keywords: [
          "straight ruler",
          "stationery",
          "calculate",
          "length",
          "math",
          "school",
          "drawing",
          "architect",
          "sketch",
        ],
        name: "straight ruler",
        order: 172,
        unified: "1F4CF",
        emoji: "📏",
      },
      {
        keywords: [
          "triangular ruler",
          "stationery",
          "math",
          "architect",
          "sketch",
        ],
        name: "triangular ruler",
        order: 173,
        unified: "1F4D0",
        emoji: "📐",
      },
      {
        keywords: ["scissors", "stationery", "cut", "black scissors"],
        name: "black scissors",
        order: 174,
        unified: "2702-FE0F",
        emoji: "✂️",
      },
      {
        keywords: ["card file box", "business", "stationery"],
        name: "card file box",
        order: 175,
        unified: "1F5C3-FE0F",
        emoji: "🗃️",
      },
      {
        keywords: ["file cabinet", "filing", "organizing"],
        name: "file cabinet",
        order: 176,
        unified: "1F5C4-FE0F",
        emoji: "🗄️",
      },
      {
        keywords: ["wastebasket", "trash", "garbage", "bin", "rubbish", "toss"],
        name: "wastebasket",
        order: 177,
        unified: "1F5D1-FE0F",
        emoji: "🗑️",
      },
      {
        keywords: [
          "lock",
          "padlock",
          "locked",
          "locks",
          "padlocks",
          "security",
          "password",
        ],
        name: "lock",
        order: 178,
        unified: "1F512",
        emoji: "🔒",
      },
      {
        keywords: ["unlocked", "privacy", "security", "unlock", "open lock"],
        name: "open lock",
        order: 179,
        unified: "1F513",
        emoji: "🔓",
      },
      {
        keywords: [
          "locked with pen",
          "security",
          "secret",
          "lock with ink pen",
        ],
        name: "lock with ink pen",
        order: 180,
        unified: "1F50F",
        emoji: "🔏",
      },
      {
        keywords: [
          "lock",
          "key",
          "padlock",
          "locks",
          "keys",
          "padlocks",
          "locked with key",
          "security",
          "privacy",
          "closed lock with key",
        ],
        name: "closed lock with key",
        order: 181,
        unified: "1F510",
        emoji: "🔐",
      },
      {
        keywords: ["key", "keys", "lock", "door", "password"],
        name: "key",
        order: 182,
        unified: "1F511",
        emoji: "🔑",
      },
      {
        keywords: ["old key", "lock", "door", "password"],
        name: "old key",
        order: 183,
        unified: "1F5DD-FE0F",
        emoji: "🗝️",
      },
      {
        keywords: ["hammer", "construction", "tools", "build", "create"],
        name: "hammer",
        order: 184,
        unified: "1F528",
        emoji: "🔨",
      },
      {
        keywords: ["axe", "tool", "chop", "cut"],
        name: "axe",
        order: 185,
        unified: "1FA93",
        emoji: "🪓",
      },
      {
        keywords: ["pick", "tools", "dig"],
        name: "pick",
        order: 186,
        unified: "26CF-FE0F",
        emoji: "⛏️",
      },
      {
        keywords: ["hammer and pick", "tools", "build", "create"],
        name: "hammer and pick",
        order: 187,
        unified: "2692-FE0F",
        emoji: "⚒️",
      },
      {
        keywords: ["hammer and wrench", "tools", "build", "create"],
        name: "hammer and wrench",
        order: 188,
        unified: "1F6E0-FE0F",
        emoji: "🛠️",
      },
      {
        keywords: ["dagger", "weapon", "dagger knife", "sword", "slay"],
        name: "dagger",
        order: 189,
        unified: "1F5E1-FE0F",
        emoji: "🗡️",
      },
      {
        keywords: ["sword", "swords", "crossed swords", "weapon"],
        name: "crossed swords",
        order: 190,
        unified: "2694-FE0F",
        emoji: "⚔️",
      },
      {
        keywords: ["pistol", "violence", "weapon", "revolver", "gun"],
        name: "pistol",
        order: 191,
        unified: "1F52B",
        emoji: "🔫",
      },
      {
        keywords: ["boomerang", "weapon"],
        name: "boomerang",
        order: 192,
        unified: "1FA83",
        emoji: "🪃",
      },
      {
        keywords: ["bow and arrow", "archery", "sports"],
        name: "bow and arrow",
        order: 193,
        unified: "1F3F9",
        emoji: "🏹",
      },
      {
        keywords: ["shield", "protection", "security"],
        name: "shield",
        order: 194,
        unified: "1F6E1-FE0F",
        emoji: "🛡️",
      },
      {
        keywords: ["carpentry saw", "cut", "chop"],
        name: "carpentry saw",
        order: 195,
        unified: "1FA9A",
        emoji: "🪚",
      },
      {
        keywords: [
          "wrench",
          "wrenches",
          "tools",
          "diy",
          "ikea",
          "fix",
          "maintainer",
        ],
        name: "wrench",
        order: 196,
        unified: "1F527",
        emoji: "🔧",
      },
      {
        keywords: ["screwdriver", "tools"],
        name: "screwdriver",
        order: 197,
        unified: "1FA9B",
        emoji: "🪛",
      },
      {
        keywords: ["nut and bolt", "nuts and bolts", "handy", "tools", "fix"],
        name: "nut and bolt",
        order: 198,
        unified: "1F529",
        emoji: "🔩",
      },
      {
        keywords: ["gear", "cog"],
        name: "gear",
        order: 199,
        unified: "2699-FE0F",
        emoji: "⚙️",
      },
      {
        keywords: ["clamp", "tool", "compression"],
        name: "clamp",
        order: 200,
        unified: "1F5DC-FE0F",
        emoji: "🗜️",
      },
      {
        keywords: ["balance scale", "law", "fairness", "weight", "scales"],
        name: "balance scale",
        order: 201,
        unified: "2696-FE0F",
        emoji: "⚖️",
      },
      {
        keywords: ["probing cane", "accessibility"],
        name: "probing cane",
        order: 202,
        unified: "1F9AF",
        emoji: "🦯",
      },
      {
        keywords: ["link", "rings", "url", "link symbol"],
        name: "link symbol",
        order: 203,
        unified: "1F517",
        emoji: "🔗",
      },
      {
        keywords: ["chains", "lock", "arrest"],
        name: "chains",
        order: 204,
        unified: "26D3-FE0F",
        emoji: "⛓️",
      },
      {
        keywords: ["hook", "tools"],
        name: "hook",
        order: 205,
        unified: "1FA9D",
        emoji: "🪝",
      },
      {
        keywords: ["toolbox", "tools", "diy", "fix", "maintainer", "mechanic"],
        name: "toolbox",
        order: 206,
        unified: "1F9F0",
        emoji: "🧰",
      },
      {
        keywords: ["magnet", "attraction", "magnetic"],
        name: "magnet",
        order: 207,
        unified: "1F9F2",
        emoji: "🧲",
      },
      {
        keywords: ["ladder", "tools"],
        name: "ladder",
        order: 208,
        unified: "1FA9C",
        emoji: "🪜",
      },
      {
        keywords: [
          "alembic",
          "distilling",
          "science",
          "experiment",
          "chemistry",
        ],
        name: "alembic",
        order: 209,
        unified: "2697-FE0F",
        emoji: "⚗️",
      },
      {
        keywords: ["test tube", "chemistry", "experiment", "lab", "science"],
        name: "test tube",
        order: 210,
        unified: "1F9EA",
        emoji: "🧪",
      },
      {
        keywords: ["petri dish", "bacteria", "biology", "culture", "lab"],
        name: "petri dish",
        order: 211,
        unified: "1F9EB",
        emoji: "🧫",
      },
      {
        keywords: ["dna", "biologist", "genetics", "life", "dna double helix"],
        name: "dna double helix",
        order: 212,
        unified: "1F9EC",
        emoji: "🧬",
      },
      {
        keywords: [
          "science",
          "microscope",
          "laboratory",
          "lab",
          "experiment",
          "zoomin",
          "study",
        ],
        name: "microscope",
        order: 213,
        unified: "1F52C",
        emoji: "🔬",
      },
      {
        keywords: [
          "telescope",
          "stars",
          "space",
          "zoom",
          "science",
          "astronomy",
        ],
        name: "telescope",
        order: 214,
        unified: "1F52D",
        emoji: "🔭",
      },
      {
        keywords: [
          "satellite antenna",
          "communication",
          "future",
          "radio",
          "space",
        ],
        name: "satellite antenna",
        order: 215,
        unified: "1F4E1",
        emoji: "📡",
      },
      {
        keywords: [
          "syringe",
          "health",
          "hospital",
          "drugs",
          "blood",
          "medicine",
          "needle",
          "doctor",
          "nurse",
        ],
        name: "syringe",
        order: 216,
        unified: "1F489",
        emoji: "💉",
      },
      {
        keywords: ["drop of blood", "period", "hurt", "harm", "wound"],
        name: "drop of blood",
        order: 217,
        unified: "1FA78",
        emoji: "🩸",
      },
      {
        keywords: ["pill", "health", "medicine", "doctor", "pharmacy", "drug"],
        name: "pill",
        order: 218,
        unified: "1F48A",
        emoji: "💊",
      },
      {
        keywords: ["adhesive bandage", "heal"],
        name: "adhesive bandage",
        order: 219,
        unified: "1FA79",
        emoji: "🩹",
      },
      {
        keywords: ["stethoscope", "health"],
        name: "stethoscope",
        order: 220,
        unified: "1FA7A",
        emoji: "🩺",
      },
      {
        keywords: ["door", "doors", "house", "entry", "exit"],
        name: "door",
        order: 221,
        unified: "1F6AA",
        emoji: "🚪",
      },
      {
        keywords: ["elevator", "lift"],
        name: "elevator",
        order: 222,
        unified: "1F6D7",
        emoji: "🛗",
      },
      {
        keywords: ["mirror", "reflection"],
        name: "mirror",
        order: 223,
        unified: "1FA9E",
        emoji: "🪞",
      },
      {
        keywords: ["window", "scenery"],
        name: "window",
        order: 224,
        unified: "1FA9F",
        emoji: "🪟",
      },
      {
        keywords: ["bed", "sleep", "rest"],
        name: "bed",
        order: 225,
        unified: "1F6CF-FE0F",
        emoji: "🛏️",
      },
      {
        keywords: ["couch and lamp", "read", "chill"],
        name: "couch and lamp",
        order: 226,
        unified: "1F6CB-FE0F",
        emoji: "🛋️",
      },
      {
        keywords: ["chair", "sit", "furniture"],
        name: "chair",
        order: 227,
        unified: "1FA91",
        emoji: "🪑",
      },
      {
        keywords: [
          "toilet",
          "toilets",
          "wc",
          "bathroom",
          "bathrooms",
          "restroom",
          "restrooms",
          "potty",
          "potties",
          "the loo",
          "porcelain throne",
          "pooper",
          "crapper",
          "crappers",
          "washroom",
        ],
        name: "toilet",
        order: 228,
        unified: "1F6BD",
        emoji: "🚽",
      },
      {
        keywords: ["plunger", "toilet"],
        name: "plunger",
        order: 229,
        unified: "1FAA0",
        emoji: "🪠",
      },
      {
        keywords: [
          "shower",
          "water",
          "shower head",
          "showers",
          "shower heads",
          "clean",
          "bathroom",
        ],
        name: "shower",
        order: 230,
        unified: "1F6BF",
        emoji: "🚿",
      },
      {
        keywords: ["bathtub", "clean", "shower", "bathroom"],
        name: "bathtub",
        order: 231,
        unified: "1F6C1",
        emoji: "🛁",
      },
      {
        keywords: ["mouse trap", "cheese"],
        name: "mouse trap",
        order: 232,
        unified: "1FAA4",
        emoji: "🪤",
      },
      {
        keywords: ["razor", "cut"],
        name: "razor",
        order: 233,
        unified: "1FA92",
        emoji: "🪒",
      },
      {
        keywords: ["lotion bottle", "moisturizer", "sunscreen"],
        name: "lotion bottle",
        order: 234,
        unified: "1F9F4",
        emoji: "🧴",
      },
      {
        keywords: ["safety pin", "diaper"],
        name: "safety pin",
        order: 235,
        unified: "1F9F7",
        emoji: "🧷",
      },
      {
        keywords: ["broom", "cleaning", "sweeping", "witch"],
        name: "broom",
        order: 236,
        unified: "1F9F9",
        emoji: "🧹",
      },
      {
        keywords: ["basket", "laundry"],
        name: "basket",
        order: 237,
        unified: "1F9FA",
        emoji: "🧺",
      },
      {
        keywords: ["roll of paper", "roll"],
        name: "roll of paper",
        order: 238,
        unified: "1F9FB",
        emoji: "🧻",
      },
      {
        keywords: ["bucket", "water", "container"],
        name: "bucket",
        order: 239,
        unified: "1FAA3",
        emoji: "🪣",
      },
      {
        keywords: [
          "soap",
          "bar",
          "bathing",
          "cleaning",
          "lather",
          "bar of soap",
        ],
        name: "bar of soap",
        order: 240,
        unified: "1F9FC",
        emoji: "🧼",
      },
      {
        keywords: ["toothbrush", "hygiene", "dental"],
        name: "toothbrush",
        order: 241,
        unified: "1FAA5",
        emoji: "🪥",
      },
      {
        keywords: ["sponge", "absorbing", "cleaning", "porous"],
        name: "sponge",
        order: 242,
        unified: "1F9FD",
        emoji: "🧽",
      },
      {
        keywords: ["fire extinguisher", "quench"],
        name: "fire extinguisher",
        order: 243,
        unified: "1F9EF",
        emoji: "🧯",
      },
      {
        keywords: [
          "cart",
          "carts",
          "shopping cart",
          "shopping carts",
          "shopping trolley",
          "shopping trollies",
          "shopping",
          "trolley",
        ],
        name: "shopping trolley",
        order: 244,
        unified: "1F6D2",
        emoji: "🛒",
      },
      {
        keywords: [
          "cigarette",
          "kills",
          "tobacco",
          "joint",
          "smoke",
          "smoking",
          "smoking symbol",
        ],
        name: "smoking symbol",
        order: 245,
        unified: "1F6AC",
        emoji: "🚬",
      },
      {
        keywords: [
          "coffin",
          "vampire",
          "dead",
          "die",
          "death",
          "rip",
          "graveyard",
          "cemetery",
          "casket",
          "funeral",
          "box",
        ],
        name: "coffin",
        order: 246,
        unified: "26B0-FE0F",
        emoji: "⚰️",
      },
      {
        keywords: ["headstone", "death", "rip", "grave"],
        name: "headstone",
        order: 247,
        unified: "1FAA6",
        emoji: "🪦",
      },
      {
        keywords: ["funeral urn", "dead", "die", "death", "rip", "ashes"],
        name: "funeral urn",
        order: 248,
        unified: "26B1-FE0F",
        emoji: "⚱️",
      },
      {
        keywords: [
          "moai",
          "moyai",
          "easter island",
          "easter island statue",
          "easter island statues",
          "rock",
        ],
        name: "moyai",
        order: 249,
        unified: "1F5FF",
        emoji: "🗿",
      },
      {
        keywords: ["placard", "announcement"],
        name: "placard",
        order: 250,
        unified: "1FAA7",
        emoji: "🪧",
      },
    ],
  },
  {
    title: "symbols",
    data: [
      {
        keywords: [
          "atm",
          "atms",
          "atm sign",
          "money",
          "sales",
          "cash",
          "blue square",
          "payment",
          "bank",
          "automated teller machine",
        ],
        name: "automated teller machine",
        order: 1,
        unified: "1F3E7",
        emoji: "🏧",
      },
      {
        keywords: [
          "litter in bin sign",
          "blue square",
          "sign",
          "human",
          "info",
          "put litter in its place",
          "put litter in its place symbol",
        ],
        name: "put litter in its place symbol",
        order: 2,
        unified: "1F6AE",
        emoji: "🚮",
      },
      {
        keywords: [
          "potable water",
          "blue square",
          "liquid",
          "restroom",
          "cleaning",
          "faucet",
          "potable water symbol",
        ],
        name: "potable water symbol",
        order: 3,
        unified: "1F6B0",
        emoji: "🚰",
      },
      {
        keywords: [
          "wheelchair symbol",
          "blue square",
          "disabled",
          "accessibility",
          "wheelchair",
        ],
        name: "wheelchair symbol",
        order: 4,
        unified: "267F",
        emoji: "♿",
      },
      {
        keywords: [
          "men s room",
          "toilet",
          "restroom",
          "wc",
          "blue square",
          "gender",
          "male",
          "mens",
          "mens symbol",
        ],
        name: "mens symbol",
        order: 5,
        unified: "1F6B9",
        emoji: "🚹",
      },
      {
        keywords: [
          "women s room",
          "purple square",
          "woman",
          "female",
          "toilet",
          "loo",
          "restroom",
          "gender",
          "womens",
          "womens symbol",
        ],
        name: "womens symbol",
        order: 6,
        unified: "1F6BA",
        emoji: "🚺",
      },
      {
        keywords: [
          "restroom",
          "blue square",
          "toilet",
          "refresh",
          "wc",
          "gender",
        ],
        name: "restroom",
        order: 7,
        unified: "1F6BB",
        emoji: "🚻",
      },
      {
        keywords: ["baby symbol", "orange square", "child"],
        name: "baby symbol",
        order: 8,
        unified: "1F6BC",
        emoji: "🚼",
      },
      {
        keywords: ["water closet", "toilet", "restroom", "blue square", "wc"],
        name: "water closet",
        order: 9,
        unified: "1F6BE",
        emoji: "🚾",
      },
      {
        keywords: ["passport control", "custom", "blue square"],
        name: "passport control",
        order: 10,
        unified: "1F6C2",
        emoji: "🛂",
      },
      {
        keywords: ["customs", "passport", "border", "blue square"],
        name: "customs",
        order: 11,
        unified: "1F6C3",
        emoji: "🛃",
      },
      {
        keywords: ["baggage claim", "blue square", "airport", "transport"],
        name: "baggage claim",
        order: 12,
        unified: "1F6C4",
        emoji: "🛄",
      },
      {
        keywords: ["left luggage", "blue square", "travel"],
        name: "left luggage",
        order: 13,
        unified: "1F6C5",
        emoji: "🛅",
      },
      {
        keywords: [
          "warning",
          "exclamation",
          "wip",
          "alert",
          "error",
          "problem",
          "issue",
          "warning sign",
        ],
        name: "warning sign",
        order: 14,
        unified: "26A0-FE0F",
        emoji: "⚠️",
      },
      {
        keywords: [
          "children crossing",
          "school",
          "warning",
          "danger",
          "sign",
          "driving",
          "yellow diamond",
        ],
        name: "children crossing",
        order: 15,
        unified: "1F6B8",
        emoji: "🚸",
      },
      {
        keywords: [
          "no entry",
          "limit",
          "security",
          "privacy",
          "bad",
          "denied",
          "stop",
          "circle",
        ],
        name: "no entry",
        order: 16,
        unified: "26D4",
        emoji: "⛔",
      },
      {
        keywords: [
          "forbidden",
          "prohibited",
          "forbid",
          "stop",
          "limit",
          "denied",
          "disallow",
          "circle",
          "no entry sign",
        ],
        name: "no entry sign",
        order: 17,
        unified: "1F6AB",
        emoji: "🚫",
      },
      {
        keywords: ["no bicycles", "cyclist", "prohibited", "circle"],
        name: "no bicycles",
        order: 18,
        unified: "1F6B3",
        emoji: "🚳",
      },
      {
        keywords: [
          "no smoking",
          "don’t smoke",
          "cigarette",
          "blue square",
          "smell",
          "smoke",
          "no smoking symbol",
        ],
        name: "no smoking symbol",
        order: 19,
        unified: "1F6AD",
        emoji: "🚭",
      },
      {
        keywords: [
          "no littering",
          "trash",
          "bin",
          "garbage",
          "circle",
          "do not litter",
          "do not litter symbol",
        ],
        name: "do not litter symbol",
        order: 20,
        unified: "1F6AF",
        emoji: "🚯",
      },
      {
        keywords: [
          "non potable water",
          "drink",
          "faucet",
          "tap",
          "circle",
          "non potable water symbol",
        ],
        name: "non-potable water symbol",
        order: 21,
        unified: "1F6B1",
        emoji: "🚱",
      },
      {
        keywords: ["no pedestrians", "rules", "crossing", "walking", "circle"],
        name: "no pedestrians",
        order: 22,
        unified: "1F6B7",
        emoji: "🚷",
      },
      {
        keywords: ["no mobile phones", "iphone", "mute", "circle"],
        name: "no mobile phones",
        order: 23,
        unified: "1F4F5",
        emoji: "📵",
      },
      {
        keywords: [
          "no one under eighteen",
          "18",
          "drink",
          "pub",
          "night",
          "minor",
          "circle",
          "underage",
          "no one under eighteen symbol",
        ],
        name: "no one under eighteen symbol",
        order: 24,
        unified: "1F51E",
        emoji: "🔞",
      },
      {
        keywords: ["radioactive", "nuclear", "danger", "radioactive sign"],
        name: "radioactive",
        order: 25,
        unified: "2622-FE0F",
        emoji: "☢️",
      },
      {
        keywords: ["biohazard", "danger", "biohazard sign"],
        name: "biohazard",
        order: 26,
        unified: "2623-FE0F",
        emoji: "☣️",
      },
      {
        keywords: [
          "up arrow",
          "blue square",
          "continue",
          "top",
          "direction",
          "arrow up",
          "upwards black arrow",
        ],
        name: "upwards black arrow",
        order: 27,
        unified: "2B06-FE0F",
        emoji: "⬆️",
      },
      {
        keywords: [
          "up right arrow",
          "blue square",
          "point",
          "direction",
          "diagonal",
          "northeast",
          "arrow upper right",
          "north east arrow",
        ],
        name: "north east arrow",
        order: 28,
        unified: "2197-FE0F",
        emoji: "↗️",
      },
      {
        keywords: [
          "right arrow",
          "blue square",
          "next",
          "arrow right",
          "black rightwards arrow",
        ],
        name: "black rightwards arrow",
        order: 29,
        unified: "27A1-FE0F",
        emoji: "➡️",
      },
      {
        keywords: [
          "down right arrow",
          "blue square",
          "direction",
          "diagonal",
          "southeast",
          "arrow lower right",
          "south east arrow",
        ],
        name: "south east arrow",
        order: 30,
        unified: "2198-FE0F",
        emoji: "↘️",
      },
      {
        keywords: [
          "down arrow",
          "blue square",
          "direction",
          "bottom",
          "arrow down",
          "downwards black arrow",
        ],
        name: "downwards black arrow",
        order: 31,
        unified: "2B07-FE0F",
        emoji: "⬇️",
      },
      {
        keywords: [
          "down left arrow",
          "blue square",
          "direction",
          "diagonal",
          "southwest",
          "arrow lower left",
          "south west arrow",
        ],
        name: "south west arrow",
        order: 32,
        unified: "2199-FE0F",
        emoji: "↙️",
      },
      {
        keywords: [
          "left arrow",
          "blue square",
          "previous",
          "back",
          "arrow left",
          "leftwards black arrow",
        ],
        name: "leftwards black arrow",
        order: 33,
        unified: "2B05-FE0F",
        emoji: "⬅️",
      },
      {
        keywords: [
          "up left arrow",
          "blue square",
          "point",
          "direction",
          "diagonal",
          "northwest",
          "arrow upper left",
          "north west arrow",
        ],
        name: "north west arrow",
        order: 34,
        unified: "2196-FE0F",
        emoji: "↖️",
      },
      {
        keywords: [
          "up down arrow",
          "blue square",
          "direction",
          "way",
          "vertical",
          "arrow up down",
        ],
        name: "up down arrow",
        order: 35,
        unified: "2195-FE0F",
        emoji: "↕️",
      },
      {
        keywords: [
          "left right arrow",
          "shape",
          "direction",
          "horizontal",
          "sideways",
        ],
        name: "left right arrow",
        order: 36,
        unified: "2194-FE0F",
        emoji: "↔️",
      },
      {
        keywords: [
          "right arrow curving left",
          "back",
          "return",
          "blue square",
          "undo",
          "enter",
          "leftwards arrow with hook",
        ],
        name: "leftwards arrow with hook",
        order: 37,
        unified: "21A9-FE0F",
        emoji: "↩️",
      },
      {
        keywords: [
          "left arrow curving right",
          "blue square",
          "return",
          "rotate",
          "direction",
          "arrow right hook",
          "rightwards arrow with hook",
        ],
        name: "rightwards arrow with hook",
        order: 38,
        unified: "21AA-FE0F",
        emoji: "↪️",
      },
      {
        keywords: [
          "right arrow curving up",
          "blue square",
          "direction",
          "top",
          "arrow heading up",
          "arrow pointing rightwards then curving upwards",
        ],
        name: "arrow pointing rightwards then curving upwards",
        order: 39,
        unified: "2934-FE0F",
        emoji: "⤴️",
      },
      {
        keywords: [
          "right arrow curving down",
          "blue square",
          "direction",
          "bottom",
          "arrow heading down",
          "arrow pointing rightwards then curving downwards",
        ],
        name: "arrow pointing rightwards then curving downwards",
        order: 40,
        unified: "2935-FE0F",
        emoji: "⤵️",
      },
      {
        keywords: [
          "clockwise vertical arrows",
          "sync",
          "cycle",
          "round",
          "repeat",
          "arrows clockwise",
          "clockwise downwards and upwards open circle arrows",
        ],
        name: "clockwise downwards and upwards open circle arrows",
        order: 41,
        unified: "1F503",
        emoji: "🔃",
      },
      {
        keywords: [
          "counterclockwise arrows button",
          "blue square",
          "sync",
          "cycle",
          "arrows counterclockwise",
          "anticlockwise downwards and upwards open circle arrows",
        ],
        name: "anticlockwise downwards and upwards open circle arrows",
        order: 42,
        unified: "1F504",
        emoji: "🔄",
      },
      {
        keywords: [
          "back arrow",
          "arrow",
          "words",
          "return",
          "back",
          "back with leftwards arrow above",
        ],
        name: "back with leftwards arrow above",
        order: 43,
        unified: "1F519",
        emoji: "🔙",
      },
      {
        keywords: [
          "end arrow",
          "words",
          "arrow",
          "end",
          "end with leftwards arrow above",
        ],
        name: "end with leftwards arrow above",
        order: 44,
        unified: "1F51A",
        emoji: "🔚",
      },
      {
        keywords: [
          "on arrow",
          "arrow",
          "words",
          "on",
          "on with exclamation mark with left right arrow above",
        ],
        name: "on with exclamation mark with left right arrow above",
        order: 45,
        unified: "1F51B",
        emoji: "🔛",
      },
      {
        keywords: [
          "soon arrow",
          "arrow",
          "words",
          "soon",
          "soon with rightwards arrow above",
        ],
        name: "soon with rightwards arrow above",
        order: 46,
        unified: "1F51C",
        emoji: "🔜",
      },
      {
        keywords: [
          "top arrow",
          "words",
          "blue square",
          "top",
          "top with upwards arrow above",
        ],
        name: "top with upwards arrow above",
        order: 47,
        unified: "1F51D",
        emoji: "🔝",
      },
      {
        keywords: [
          "place of worship",
          "religion",
          "church",
          "temple",
          "prayer",
        ],
        name: "place of worship",
        order: 48,
        unified: "1F6D0",
        emoji: "🛐",
      },
      {
        keywords: ["atom symbol", "science", "physics", "chemistry"],
        name: "atom symbol",
        order: 49,
        unified: "269B-FE0F",
        emoji: "⚛️",
      },
      {
        keywords: [
          "om",
          "hinduism",
          "buddhism",
          "sikhism",
          "jainism",
          "om symbol",
        ],
        name: "om",
        order: 50,
        unified: "1F549-FE0F",
        emoji: "🕉️",
      },
      {
        keywords: ["star of david", "judaism"],
        name: "star of david",
        order: 51,
        unified: "2721-FE0F",
        emoji: "✡️",
      },
      {
        keywords: [
          "wheel of dharma",
          "hinduism",
          "buddhism",
          "sikhism",
          "jainism",
        ],
        name: "wheel of dharma",
        order: 52,
        unified: "2638-FE0F",
        emoji: "☸️",
      },
      {
        keywords: ["yin yang", "balance"],
        name: "yin yang",
        order: 53,
        unified: "262F-FE0F",
        emoji: "☯️",
      },
      {
        keywords: ["latin cross", "christianity"],
        name: "latin cross",
        order: 54,
        unified: "271D-FE0F",
        emoji: "✝️",
      },
      {
        keywords: ["orthodox cross", "suppedaneum", "religion"],
        name: "orthodox cross",
        order: 55,
        unified: "2626-FE0F",
        emoji: "☦️",
      },
      {
        keywords: ["star and crescent", "islam"],
        name: "star and crescent",
        order: 56,
        unified: "262A-FE0F",
        emoji: "☪️",
      },
      {
        keywords: ["peace symbol", "hippie"],
        name: "peace symbol",
        order: 57,
        unified: "262E-FE0F",
        emoji: "☮️",
      },
      {
        keywords: [
          "menorah",
          "hanukkah",
          "candles",
          "jewish",
          "menorah with nine branches",
        ],
        name: "menorah with nine branches",
        order: 58,
        unified: "1F54E",
        emoji: "🕎",
      },
      {
        keywords: [
          "dotted six pointed star",
          "purple square",
          "religion",
          "jewish",
          "hexagram",
          "six pointed star",
          "six pointed star with middle dot",
        ],
        name: "six pointed star with middle dot",
        order: 59,
        unified: "1F52F",
        emoji: "🔯",
      },
      {
        keywords: ["aries", "sign", "purple square", "zodiac", "astrology"],
        name: "aries",
        order: 60,
        unified: "2648",
        emoji: "♈",
      },
      {
        keywords: ["taurus", "purple square", "sign", "zodiac", "astrology"],
        name: "taurus",
        order: 61,
        unified: "2649",
        emoji: "♉",
      },
      {
        keywords: ["gemini", "sign", "zodiac", "purple square", "astrology"],
        name: "gemini",
        order: 62,
        unified: "264A",
        emoji: "♊",
      },
      {
        keywords: ["cancer", "sign", "zodiac", "purple square", "astrology"],
        name: "cancer",
        order: 63,
        unified: "264B",
        emoji: "♋",
      },
      {
        keywords: ["leo", "sign", "purple square", "zodiac", "astrology"],
        name: "leo",
        order: 64,
        unified: "264C",
        emoji: "♌",
      },
      {
        keywords: ["virgo", "sign", "zodiac", "purple square", "astrology"],
        name: "virgo",
        order: 65,
        unified: "264D",
        emoji: "♍",
      },
      {
        keywords: ["libra", "sign", "purple square", "zodiac", "astrology"],
        name: "libra",
        order: 66,
        unified: "264E",
        emoji: "♎",
      },
      {
        keywords: [
          "scorpio",
          "sign",
          "zodiac",
          "purple square",
          "astrology",
          "scorpius",
        ],
        name: "scorpius",
        order: 67,
        unified: "264F",
        emoji: "♏",
      },
      {
        keywords: [
          "sagittarius",
          "sign",
          "zodiac",
          "purple square",
          "astrology",
        ],
        name: "sagittarius",
        order: 68,
        unified: "2650",
        emoji: "♐",
      },
      {
        keywords: ["capricorn", "sign", "zodiac", "purple square", "astrology"],
        name: "capricorn",
        order: 69,
        unified: "2651",
        emoji: "♑",
      },
      {
        keywords: ["aquarius", "sign", "purple square", "zodiac", "astrology"],
        name: "aquarius",
        order: 70,
        unified: "2652",
        emoji: "♒",
      },
      {
        keywords: ["pisces", "purple square", "sign", "zodiac", "astrology"],
        name: "pisces",
        order: 71,
        unified: "2653",
        emoji: "♓",
      },
      {
        keywords: [
          "ophiuchus",
          "sign",
          "purple square",
          "constellation",
          "astrology",
        ],
        name: "ophiuchus",
        order: 72,
        unified: "26CE",
        emoji: "⛎",
      },
      {
        keywords: [
          "shuffle tracks button",
          "blue square",
          "shuffle",
          "music",
          "random",
          "twisted rightwards arrows",
        ],
        name: "twisted rightwards arrows",
        order: 73,
        unified: "1F500",
        emoji: "🔀",
      },
      {
        keywords: [
          "repeat button",
          "loop",
          "record",
          "repeat",
          "clockwise rightwards and leftwards open circle arrows",
        ],
        name: "clockwise rightwards and leftwards open circle arrows",
        order: 74,
        unified: "1F501",
        emoji: "🔁",
      },
      {
        keywords: [
          "repeat single button",
          "blue square",
          "loop",
          "repeat one",
          "clockwise rightwards and leftwards open circle arrows with circled one overlay",
        ],
        name: "clockwise rightwards and leftwards open circle arrows with circled one overlay",
        order: 75,
        unified: "1F502",
        emoji: "🔂",
      },
      {
        keywords: [
          "play button",
          "blue square",
          "right",
          "direction",
          "play",
          "arrow forward",
          "black right pointing triangle",
        ],
        name: "black right-pointing triangle",
        order: 76,
        unified: "25B6-FE0F",
        emoji: "▶️",
      },
      {
        keywords: [
          "fast forward button",
          "blue square",
          "play",
          "speed",
          "continue",
          "fast forward",
          "black right pointing double triangle",
        ],
        name: "black right-pointing double triangle",
        order: 77,
        unified: "23E9",
        emoji: "⏩",
      },
      {
        keywords: [
          "next track button",
          "forward",
          "next",
          "blue square",
          "black right pointing double triangle with vertical bar",
        ],
        name: "next track button",
        order: 78,
        unified: "23ED-FE0F",
        emoji: "⏭️",
      },
      {
        keywords: [
          "play or pause button",
          "blue square",
          "play",
          "pause",
          "black right pointing triangle with double vertical bar",
        ],
        name: "play or pause button",
        order: 79,
        unified: "23EF-FE0F",
        emoji: "⏯️",
      },
      {
        keywords: [
          "reverse button",
          "blue square",
          "left",
          "direction",
          "arrow backward",
          "black left pointing triangle",
        ],
        name: "black left-pointing triangle",
        order: 80,
        unified: "25C0-FE0F",
        emoji: "◀️",
      },
      {
        keywords: [
          "fast reverse button",
          "play",
          "blue square",
          "rewind",
          "black left pointing double triangle",
        ],
        name: "black left-pointing double triangle",
        order: 81,
        unified: "23EA",
        emoji: "⏪",
      },
      {
        keywords: [
          "last track button",
          "backward",
          "black left pointing double triangle with vertical bar",
        ],
        name: "last track button",
        order: 82,
        unified: "23EE-FE0F",
        emoji: "⏮️",
      },
      {
        keywords: [
          "upwards button",
          "blue square",
          "triangle",
          "direction",
          "point",
          "forward",
          "top",
          "arrow up small",
          "up pointing small red triangle",
        ],
        name: "up-pointing small red triangle",
        order: 83,
        unified: "1F53C",
        emoji: "🔼",
      },
      {
        keywords: [
          "fast up button",
          "blue square",
          "direction",
          "top",
          "arrow double up",
          "black up pointing double triangle",
        ],
        name: "black up-pointing double triangle",
        order: 84,
        unified: "23EB",
        emoji: "⏫",
      },
      {
        keywords: [
          "downwards button",
          "blue square",
          "direction",
          "bottom",
          "arrow down small",
          "down pointing small red triangle",
        ],
        name: "down-pointing small red triangle",
        order: 85,
        unified: "1F53D",
        emoji: "🔽",
      },
      {
        keywords: [
          "fast down button",
          "blue square",
          "direction",
          "bottom",
          "arrow double down",
          "black down pointing double triangle",
        ],
        name: "black down-pointing double triangle",
        order: 86,
        unified: "23EC",
        emoji: "⏬",
      },
      {
        keywords: [
          "pause button",
          "pause",
          "blue square",
          "double vertical bar",
        ],
        name: "pause button",
        order: 87,
        unified: "23F8-FE0F",
        emoji: "⏸️",
      },
      {
        keywords: ["stop button", "blue square", "black square for stop"],
        name: "stop button",
        order: 88,
        unified: "23F9-FE0F",
        emoji: "⏹️",
      },
      {
        keywords: ["record button", "blue square", "black circle for record"],
        name: "record button",
        order: 89,
        unified: "23FA-FE0F",
        emoji: "⏺️",
      },
      {
        keywords: ["eject button", "blue square", "eject"],
        name: "eject button",
        order: 90,
        unified: "23CF-FE0F",
        emoji: "⏏️",
      },
      {
        keywords: [
          "cinema",
          "blue square",
          "record",
          "film",
          "movie",
          "curtain",
          "stage",
          "theater",
        ],
        name: "cinema",
        order: 91,
        unified: "1F3A6",
        emoji: "🎦",
      },
      {
        keywords: [
          "dim button",
          "sun",
          "afternoon",
          "warm",
          "summer",
          "low brightness",
          "low brightness symbol",
        ],
        name: "low brightness symbol",
        order: 92,
        unified: "1F505",
        emoji: "🔅",
      },
      {
        keywords: [
          "bright button",
          "sun",
          "light",
          "high brightness",
          "high brightness symbol",
        ],
        name: "high brightness symbol",
        order: 93,
        unified: "1F506",
        emoji: "🔆",
      },
      {
        keywords: [
          "signal strength symbol",
          "antenna bars",
          "blue square",
          "reception",
          "phone",
          "internet",
          "connection",
          "wifi",
          "bluetooth",
          "bars",
          "signal strength",
          "antenna with bars",
        ],
        name: "antenna with bars",
        order: 94,
        unified: "1F4F6",
        emoji: "📶",
      },
      {
        keywords: ["vibration mode", "orange square", "phone"],
        name: "vibration mode",
        order: 95,
        unified: "1F4F3",
        emoji: "📳",
      },
      {
        keywords: [
          "mobile phone off",
          "mute",
          "orange square",
          "silence",
          "quiet",
        ],
        name: "mobile phone off",
        order: 96,
        unified: "1F4F4",
        emoji: "📴",
      },
      {
        keywords: ["transgender symbol", "lgbtq"],
        name: "transgender symbol",
        order: 99,
        unified: "26A7-FE0F",
        emoji: "⚧️",
      },
      {
        keywords: [
          "multiplication sign",
          "math",
          "calculation",
          "heavy multiplication x",
        ],
        name: "heavy multiplication x",
        order: 100,
        unified: "2716-FE0F",
        emoji: "✖️",
      },
      {
        keywords: [
          "plus sign",
          "math",
          "calculation",
          "addition",
          "more",
          "increase",
          "heavy plus sign",
        ],
        name: "heavy plus sign",
        order: 101,
        unified: "2795",
        emoji: "➕",
      },
      {
        keywords: [
          "minus sign",
          "math",
          "calculation",
          "subtract",
          "less",
          "heavy minus sign",
        ],
        name: "heavy minus sign",
        order: 102,
        unified: "2796",
        emoji: "➖",
      },
      {
        keywords: [
          "division sign",
          "divide",
          "math",
          "calculation",
          "heavy division sign",
        ],
        name: "heavy division sign",
        order: 103,
        unified: "2797",
        emoji: "➗",
      },
      {
        keywords: ["infinity", "forever"],
        name: "infinity",
        order: 104,
        unified: "267E-FE0F",
        emoji: "♾️",
      },
      {
        keywords: [
          "double exclamation mark",
          "exclamation",
          "surprise",
          "bangbang",
        ],
        name: "double exclamation mark",
        order: 105,
        unified: "203C-FE0F",
        emoji: "‼️",
      },
      {
        keywords: [
          "exclamation question mark",
          "wat",
          "punctuation",
          "surprise",
          "interrobang",
        ],
        name: "exclamation question mark",
        order: 106,
        unified: "2049-FE0F",
        emoji: "⁉️",
      },
      {
        keywords: [
          "question mark",
          "doubt",
          "confused",
          "question",
          "black question mark ornament",
        ],
        name: "black question mark ornament",
        order: 107,
        unified: "2753",
        emoji: "❓",
      },
      {
        keywords: [
          "white question mark",
          "doubts",
          "gray",
          "huh",
          "confused",
          "grey question",
          "white question mark ornament",
        ],
        name: "white question mark ornament",
        order: 108,
        unified: "2754",
        emoji: "❔",
      },
      {
        keywords: [
          "white exclamation mark",
          "surprise",
          "punctuation",
          "gray",
          "wow",
          "warning",
          "grey exclamation",
          "white exclamation mark ornament",
        ],
        name: "white exclamation mark ornament",
        order: 109,
        unified: "2755",
        emoji: "❕",
      },
      {
        keywords: [
          "exclamation mark",
          "heavy exclamation mark",
          "danger",
          "surprise",
          "punctuation",
          "wow",
          "warning",
          "exclamation",
          "heavy exclamation mark symbol",
        ],
        name: "heavy exclamation mark symbol",
        order: 110,
        unified: "2757",
        emoji: "❗",
      },
      {
        keywords: [
          "wavy dash",
          "draw",
          "line",
          "moustache",
          "mustache",
          "squiggle",
          "scribble",
        ],
        name: "wavy dash",
        order: 111,
        unified: "3030-FE0F",
        emoji: "〰️",
      },
      {
        keywords: ["currency exchange", "money", "sales", "dollar", "travel"],
        name: "currency exchange",
        order: 112,
        unified: "1F4B1",
        emoji: "💱",
      },
      {
        keywords: [
          "heavy dollar sign",
          "money",
          "sales",
          "payment",
          "currency",
          "buck",
        ],
        name: "heavy dollar sign",
        order: 113,
        unified: "1F4B2",
        emoji: "💲",
      },
      {
        keywords: [
          "recycle",
          "recycling symbol",
          "arrow",
          "environment",
          "garbage",
          "trash",
          "black universal recycling symbol",
        ],
        name: "black universal recycling symbol",
        order: 115,
        unified: "267B-FE0F",
        emoji: "♻️",
      },
      {
        keywords: ["fleur de lis", "decorative", "scout"],
        name: "fleur-de-lis",
        order: 116,
        unified: "269C-FE0F",
        emoji: "⚜️",
      },
      {
        keywords: ["trident emblem", "weapon", "spear", "trident"],
        name: "trident emblem",
        order: 117,
        unified: "1F531",
        emoji: "🔱",
      },
      {
        keywords: ["name badge", "fire", "forbid"],
        name: "name badge",
        order: 118,
        unified: "1F4DB",
        emoji: "📛",
      },
      {
        keywords: [
          "beginner",
          "japanese symbol for beginner",
          "badge",
          "shield",
        ],
        name: "japanese symbol for beginner",
        order: 119,
        unified: "1F530",
        emoji: "🔰",
      },
      {
        keywords: [
          "hollow red circle",
          "circle",
          "round",
          "o",
          "heavy large circle",
        ],
        name: "heavy large circle",
        order: 120,
        unified: "2B55",
        emoji: "⭕",
      },
      {
        keywords: [
          "check mark",
          "done",
          "check mark button",
          "green square",
          "ok",
          "agree",
          "vote",
          "election",
          "answer",
          "tick",
          "white check mark",
          "white heavy check mark",
        ],
        name: "white heavy check mark",
        order: 121,
        unified: "2705",
        emoji: "✅",
      },
      {
        keywords: [
          "check box with check",
          "ok",
          "agree",
          "confirm",
          "black square",
          "vote",
          "election",
          "yes",
          "tick",
          "ballot box with check",
        ],
        name: "ballot box with check",
        order: 122,
        unified: "2611-FE0F",
        emoji: "☑️",
      },
      {
        keywords: [
          "check mark",
          "ok",
          "nike",
          "answer",
          "yes",
          "tick",
          "heavy check mark",
        ],
        name: "heavy check mark",
        order: 123,
        unified: "2714-FE0F",
        emoji: "✔️",
      },
      {
        keywords: [
          "cross mark",
          "no",
          "delete",
          "remove",
          "cancel",
          "red",
          "x",
        ],
        name: "cross mark",
        order: 124,
        unified: "274C",
        emoji: "❌",
      },
      {
        keywords: [
          "cross mark button",
          "x",
          "green square",
          "no",
          "deny",
          "negative squared cross mark",
        ],
        name: "negative squared cross mark",
        order: 125,
        unified: "274E",
        emoji: "❎",
      },
      {
        keywords: ["curly loop", "scribble", "draw", "shape", "squiggle"],
        name: "curly loop",
        order: 126,
        unified: "27B0",
        emoji: "➰",
      },
      {
        keywords: ["double curly loop", "tape", "cassette", "loop"],
        name: "double curly loop",
        order: 127,
        unified: "27BF",
        emoji: "➿",
      },
      {
        keywords: [
          "part alternation mark",
          "graph",
          "presentation",
          "stats",
          "business",
          "economics",
          "bad",
        ],
        name: "part alternation mark",
        order: 128,
        unified: "303D-FE0F",
        emoji: "〽️",
      },
      {
        keywords: ["eight spoked asterisk", "star", "sparkle", "green square"],
        name: "eight spoked asterisk",
        order: 129,
        unified: "2733-FE0F",
        emoji: "✳️",
      },
      {
        keywords: [
          "eight pointed star",
          "orange square",
          "shape",
          "polygon",
          "eight pointed black star",
        ],
        name: "eight pointed black star",
        order: 130,
        unified: "2734-FE0F",
        emoji: "✴️",
      },
      {
        keywords: [
          "sparkle",
          "stars",
          "green square",
          "awesome",
          "good",
          "fireworks",
        ],
        name: "sparkle",
        order: 131,
        unified: "2747-FE0F",
        emoji: "❇️",
      },
      {
        keywords: [
          "copyright",
          "ip",
          "license",
          "circle",
          "law",
          "legal",
          "copyright sign",
        ],
        name: "copyright sign",
        order: 132,
        unified: "00A9-FE0F",
        emoji: "©️",
      },
      {
        keywords: ["registered", "alphabet", "circle", "registered sign"],
        name: "registered sign",
        order: 133,
        unified: "00AE-FE0F",
        emoji: "®️",
      },
      {
        keywords: [
          "trade mark",
          "trademark",
          "brand",
          "law",
          "legal",
          "tm",
          "trade mark sign",
        ],
        name: "trade mark sign",
        order: 134,
        unified: "2122-FE0F",
        emoji: "™️",
      },
      {
        keywords: [
          "keycap ",
          "symbol",
          "blue square",
          "twitter",
          "hash",
          "hash key",
        ],
        name: "hash key",
        order: 135,
        unified: "0023-FE0F-20E3",
        emoji: "#️⃣",
      },
      {
        keywords: ["keycap ", "star", "keycap", "keycap star", "keycap: *"],
        name: "keycap: *",
        order: 136,
        unified: "002A-FE0F-20E3",
        emoji: "*️⃣",
      },
      {
        keywords: ["keycap 0", "0", "numbers", "blue square", "null", "zero"],
        name: "keycap 0",
        order: 137,
        unified: "0030-FE0F-20E3",
        emoji: "0️⃣",
      },
      {
        keywords: ["keycap 1", "blue square", "numbers", "1", "one"],
        name: "keycap 1",
        order: 138,
        unified: "0031-FE0F-20E3",
        emoji: "1️⃣",
      },
      {
        keywords: ["keycap 2", "numbers", "2", "prime", "blue square", "two"],
        name: "keycap 2",
        order: 139,
        unified: "0032-FE0F-20E3",
        emoji: "2️⃣",
      },
      {
        keywords: ["keycap 3", "3", "numbers", "prime", "blue square", "three"],
        name: "keycap 3",
        order: 140,
        unified: "0033-FE0F-20E3",
        emoji: "3️⃣",
      },
      {
        keywords: ["keycap 4", "4", "numbers", "blue square", "four"],
        name: "keycap 4",
        order: 141,
        unified: "0034-FE0F-20E3",
        emoji: "4️⃣",
      },
      {
        keywords: ["keycap 5", "5", "numbers", "blue square", "prime", "five"],
        name: "keycap 5",
        order: 142,
        unified: "0035-FE0F-20E3",
        emoji: "5️⃣",
      },
      {
        keywords: ["keycap 6", "6", "numbers", "blue square", "six"],
        name: "keycap 6",
        order: 143,
        unified: "0036-FE0F-20E3",
        emoji: "6️⃣",
      },
      {
        keywords: ["keycap 7", "7", "numbers", "blue square", "prime", "seven"],
        name: "keycap 7",
        order: 144,
        unified: "0037-FE0F-20E3",
        emoji: "7️⃣",
      },
      {
        keywords: ["keycap 8", "8", "blue square", "numbers", "eight"],
        name: "keycap 8",
        order: 145,
        unified: "0038-FE0F-20E3",
        emoji: "8️⃣",
      },
      {
        keywords: ["keycap 9", "blue square", "numbers", "9", "nine"],
        name: "keycap 9",
        order: 146,
        unified: "0039-FE0F-20E3",
        emoji: "9️⃣",
      },
      {
        keywords: ["keycap 10", "numbers", "10", "blue square", "keycap ten"],
        name: "keycap ten",
        order: 147,
        unified: "1F51F",
        emoji: "🔟",
      },
      {
        keywords: [
          "input latin uppercase",
          "alphabet",
          "words",
          "blue square",
          "capital abcd",
          "input symbol for latin capital letters",
        ],
        name: "input symbol for latin capital letters",
        order: 148,
        unified: "1F520",
        emoji: "🔠",
      },
      {
        keywords: [
          "input latin lowercase",
          "blue square",
          "alphabet",
          "abcd",
          "input symbol for latin small letters",
        ],
        name: "input symbol for latin small letters",
        order: 149,
        unified: "1F521",
        emoji: "🔡",
      },
      {
        keywords: [
          "input numbers",
          "numbers",
          "blue square",
          "1234",
          "input symbol for numbers",
        ],
        name: "input symbol for numbers",
        order: 150,
        unified: "1F522",
        emoji: "🔢",
      },
      {
        keywords: [
          "input symbols",
          "blue square",
          "music",
          "note",
          "ampersand",
          "percent",
          "glyphs",
          "characters",
          "symbols",
          "input symbol for symbols",
        ],
        name: "input symbol for symbols",
        order: 151,
        unified: "1F523",
        emoji: "🔣",
      },
      {
        keywords: [
          "input latin letters",
          "blue square",
          "alphabet",
          "abc",
          "input symbol for latin letters",
        ],
        name: "input symbol for latin letters",
        order: 152,
        unified: "1F524",
        emoji: "🔤",
      },
      {
        keywords: [
          "blood type a",
          "a button",
          "red square",
          "alphabet",
          "letter",
          "a",
          "negative squared latin capital letter a",
        ],
        name: "negative squared latin capital letter a",
        order: 153,
        unified: "1F170-FE0F",
        emoji: "🅰️",
      },
      {
        keywords: [
          "blood type ab",
          "ab button",
          "red square",
          "alphabet",
          "ab",
          "negative squared ab",
        ],
        name: "negative squared ab",
        order: 154,
        unified: "1F18E",
        emoji: "🆎",
      },
      {
        keywords: [
          "blood type b",
          "b button",
          "red square",
          "alphabet",
          "letter",
          "b",
          "negative squared latin capital letter b",
        ],
        name: "negative squared latin capital letter b",
        order: 155,
        unified: "1F171-FE0F",
        emoji: "🅱️",
      },
      {
        keywords: [
          "cl button",
          "alphabet",
          "words",
          "red square",
          "cl",
          "squared cl",
        ],
        name: "squared cl",
        order: 156,
        unified: "1F191",
        emoji: "🆑",
      },
      {
        keywords: [
          "cool button",
          "words",
          "blue square",
          "cool",
          "squared cool",
        ],
        name: "squared cool",
        order: 157,
        unified: "1F192",
        emoji: "🆒",
      },
      {
        keywords: [
          "free button",
          "blue square",
          "words",
          "free",
          "squared free",
        ],
        name: "squared free",
        order: 158,
        unified: "1F193",
        emoji: "🆓",
      },
      {
        keywords: [
          "information",
          "blue square",
          "alphabet",
          "letter",
          "information source",
        ],
        name: "information source",
        order: 159,
        unified: "2139-FE0F",
        emoji: "ℹ️",
      },
      {
        keywords: [
          "id",
          "identification",
          "id button",
          "purple square",
          "words",
          "squared id",
        ],
        name: "squared id",
        order: 160,
        unified: "1F194",
        emoji: "🆔",
      },
      {
        keywords: [
          "circled m",
          "alphabet",
          "blue circle",
          "letter",
          "m",
          "circled latin capital letter m",
        ],
        name: "circled latin capital letter m",
        order: 161,
        unified: "24C2-FE0F",
        emoji: "Ⓜ️",
      },
      {
        keywords: [
          "new button",
          "blue square",
          "words",
          "start",
          "new",
          "squared new",
        ],
        name: "squared new",
        order: 162,
        unified: "1F195",
        emoji: "🆕",
      },
      {
        keywords: [
          "no good",
          "ng button",
          "blue square",
          "words",
          "shape",
          "icon",
          "ng",
          "squared ng",
        ],
        name: "squared ng",
        order: 163,
        unified: "1F196",
        emoji: "🆖",
      },
      {
        keywords: [
          "blood type o",
          "o button",
          "alphabet",
          "red square",
          "letter",
          "o2",
          "negative squared latin capital letter o",
        ],
        name: "negative squared latin capital letter o",
        order: 164,
        unified: "1F17E-FE0F",
        emoji: "🅾️",
      },
      {
        keywords: [
          "okay",
          "ok",
          "ok button",
          "good",
          "agree",
          "yes",
          "blue square",
          "squared ok",
        ],
        name: "squared ok",
        order: 165,
        unified: "1F197",
        emoji: "🆗",
      },
      {
        keywords: [
          "parking",
          "parking sign",
          "p button",
          "cars",
          "blue square",
          "alphabet",
          "letter",
          "negative squared latin capital letter p",
        ],
        name: "negative squared latin capital letter p",
        order: 166,
        unified: "1F17F-FE0F",
        emoji: "🅿️",
      },
      {
        keywords: [
          "sos button",
          "help",
          "red square",
          "words",
          "emergency",
          "911",
          "sos",
          "squared sos",
        ],
        name: "squared sos",
        order: 167,
        unified: "1F198",
        emoji: "🆘",
      },
      {
        keywords: [
          "up button",
          "blue square",
          "above",
          "high",
          "up",
          "squared up with exclamation mark",
        ],
        name: "squared up with exclamation mark",
        order: 168,
        unified: "1F199",
        emoji: "🆙",
      },
      {
        keywords: ["vs button", "words", "orange square", "vs", "squared vs"],
        name: "squared vs",
        order: 169,
        unified: "1F19A",
        emoji: "🆚",
      },
      {
        keywords: [
          "japanese here button",
          "blue square",
          "here",
          "katakana",
          "japanese",
          "destination",
          "koko",
          "squared katakana koko",
        ],
        name: "squared katakana koko",
        order: 170,
        unified: "1F201",
        emoji: "🈁",
      },
      {
        keywords: [
          "japanese service charge button",
          "japanese",
          "blue square",
          "katakana",
          "sa",
          "squared katakana sa",
        ],
        name: "squared katakana sa",
        order: 171,
        unified: "1F202-FE0F",
        emoji: "🈂️",
      },
      {
        keywords: [
          "japanese monthly amount button",
          "chinese",
          "month",
          "moon",
          "japanese",
          "orange square",
          "kanji",
          "u6708",
          "squared cjk unified ideograph 6708",
        ],
        name: "squared cjk unified ideograph-6708",
        order: 172,
        unified: "1F237-FE0F",
        emoji: "🈷️",
      },
      {
        keywords: [
          "not free of charge",
          "japanese not free of charge button",
          "orange square",
          "chinese",
          "have",
          "kanji",
          "u6709",
          "squared cjk unified ideograph 6709",
        ],
        name: "squared cjk unified ideograph-6709",
        order: 173,
        unified: "1F236",
        emoji: "🈶",
      },
      {
        keywords: [
          "japanese reserved button",
          "chinese",
          "point",
          "green square",
          "kanji",
          "u6307",
          "squared cjk unified ideograph 6307",
        ],
        name: "squared cjk unified ideograph-6307",
        order: 174,
        unified: "1F22F",
        emoji: "🈯",
      },
      {
        keywords: [
          "japanese bargain button",
          "chinese",
          "kanji",
          "obtain",
          "get",
          "circle",
          "ideograph advantage",
          "circled ideograph advantage",
        ],
        name: "circled ideograph advantage",
        order: 175,
        unified: "1F250",
        emoji: "🉐",
      },
      {
        keywords: [
          "discount",
          "japanese discount button",
          "cut",
          "divide",
          "chinese",
          "kanji",
          "pink square",
          "u5272",
          "squared cjk unified ideograph 5272",
        ],
        name: "squared cjk unified ideograph-5272",
        order: 176,
        unified: "1F239",
        emoji: "🈹",
      },
      {
        keywords: [
          "japanese free of charge button",
          "nothing",
          "chinese",
          "kanji",
          "japanese",
          "orange square",
          "u7121",
          "squared cjk unified ideograph 7121",
        ],
        name: "squared cjk unified ideograph-7121",
        order: 177,
        unified: "1F21A",
        emoji: "🈚",
      },
      {
        keywords: [
          "prohibited",
          "japanese prohibited button",
          "kanji",
          "japanese",
          "chinese",
          "forbidden",
          "limit",
          "restricted",
          "red square",
          "u7981",
          "squared cjk unified ideograph 7981",
        ],
        name: "squared cjk unified ideograph-7981",
        order: 178,
        unified: "1F232",
        emoji: "🈲",
      },
      {
        keywords: [
          "japanese acceptable button",
          "ok",
          "good",
          "chinese",
          "kanji",
          "agree",
          "yes",
          "orange circle",
          "accept",
          "circled ideograph accept",
        ],
        name: "circled ideograph accept",
        order: 179,
        unified: "1F251",
        emoji: "🉑",
      },
      {
        keywords: [
          "japanese application button",
          "chinese",
          "japanese",
          "kanji",
          "orange square",
          "u7533",
          "squared cjk unified ideograph 7533",
        ],
        name: "squared cjk unified ideograph-7533",
        order: 180,
        unified: "1F238",
        emoji: "🈸",
      },
      {
        keywords: [
          "passing grade",
          "japanese passing grade button",
          "japanese",
          "chinese",
          "join",
          "kanji",
          "red square",
          "u5408",
          "squared cjk unified ideograph 5408",
        ],
        name: "squared cjk unified ideograph-5408",
        order: 181,
        unified: "1F234",
        emoji: "🈴",
      },
      {
        keywords: [
          "vacancy",
          "japanese vacancy button",
          "kanji",
          "japanese",
          "chinese",
          "empty",
          "sky",
          "blue square",
          "u7a7a",
          "squared cjk unified ideograph 7a7a",
        ],
        name: "squared cjk unified ideograph-7a7a",
        order: 182,
        unified: "1F233",
        emoji: "🈳",
      },
      {
        keywords: [
          "congratulations",
          "japanese congratulations button",
          "chinese",
          "kanji",
          "japanese",
          "red circle",
          "circled ideograph congratulation",
        ],
        name: "circled ideograph congratulation",
        order: 183,
        unified: "3297-FE0F",
        emoji: "㊗️",
      },
      {
        keywords: [
          "secret",
          "japanese secret button",
          "privacy",
          "chinese",
          "sshh",
          "kanji",
          "red circle",
          "circled ideograph secret",
        ],
        name: "circled ideograph secret",
        order: 184,
        unified: "3299-FE0F",
        emoji: "㊙️",
      },
      {
        keywords: [
          "japanese open for business button",
          "japanese",
          "opening hours",
          "orange square",
          "u55b6",
          "squared cjk unified ideograph 55b6",
        ],
        name: "squared cjk unified ideograph-55b6",
        order: 185,
        unified: "1F23A",
        emoji: "🈺",
      },
      {
        keywords: [
          "japanese no vacancy button",
          "full",
          "chinese",
          "japanese",
          "red square",
          "kanji",
          "u6e80",
          "squared cjk unified ideograph 6e80",
        ],
        name: "squared cjk unified ideograph-6e80",
        order: 186,
        unified: "1F235",
        emoji: "🈵",
      },
      {
        keywords: [
          "red",
          "hotspot",
          "dot",
          "red circle",
          "shape",
          "error",
          "danger",
          "large red circle",
        ],
        name: "large red circle",
        order: 187,
        unified: "1F534",
        emoji: "🔴",
      },
      {
        keywords: ["orange circle", "round", "large orange circle"],
        name: "large orange circle",
        order: 188,
        unified: "1F7E0",
        emoji: "🟠",
      },
      {
        keywords: ["yellow circle", "round", "large yellow circle"],
        name: "large yellow circle",
        order: 189,
        unified: "1F7E1",
        emoji: "🟡",
      },
      {
        keywords: ["green circle", "round", "large green circle"],
        name: "large green circle",
        order: 190,
        unified: "1F7E2",
        emoji: "🟢",
      },
      {
        keywords: [
          "blue circle",
          "shape",
          "icon",
          "button",
          "large blue circle",
        ],
        name: "large blue circle",
        order: 191,
        unified: "1F535",
        emoji: "🔵",
      },
      {
        keywords: ["purple circle", "round", "large purple circle"],
        name: "large purple circle",
        order: 192,
        unified: "1F7E3",
        emoji: "🟣",
      },
      {
        keywords: ["brown circle", "round", "large brown circle"],
        name: "large brown circle",
        order: 193,
        unified: "1F7E4",
        emoji: "🟤",
      },
      {
        keywords: [
          "black circle",
          "shape",
          "button",
          "round",
          "medium black circle",
        ],
        name: "medium black circle",
        order: 194,
        unified: "26AB",
        emoji: "⚫",
      },
      {
        keywords: ["white circle", "shape", "round", "medium white circle"],
        name: "medium white circle",
        order: 195,
        unified: "26AA",
        emoji: "⚪",
      },
      {
        keywords: ["red square", "large red square"],
        name: "large red square",
        order: 196,
        unified: "1F7E5",
        emoji: "🟥",
      },
      {
        keywords: ["orange square", "large orange square"],
        name: "large orange square",
        order: 197,
        unified: "1F7E7",
        emoji: "🟧",
      },
      {
        keywords: ["yellow square", "large yellow square"],
        name: "large yellow square",
        order: 198,
        unified: "1F7E8",
        emoji: "🟨",
      },
      {
        keywords: ["green square", "large green square"],
        name: "large green square",
        order: 199,
        unified: "1F7E9",
        emoji: "🟩",
      },
      {
        keywords: ["blue square", "large blue square"],
        name: "large blue square",
        order: 200,
        unified: "1F7E6",
        emoji: "🟦",
      },
      {
        keywords: ["purple square", "large purple square"],
        name: "large purple square",
        order: 201,
        unified: "1F7EA",
        emoji: "🟪",
      },
      {
        keywords: ["brown square", "large brown square"],
        name: "large brown square",
        order: 202,
        unified: "1F7EB",
        emoji: "🟫",
      },
      {
        keywords: ["black large square", "shape", "icon", "button"],
        name: "black large square",
        order: 203,
        unified: "2B1B",
        emoji: "⬛",
      },
      {
        keywords: ["white large square", "shape", "icon", "stone", "button"],
        name: "white large square",
        order: 204,
        unified: "2B1C",
        emoji: "⬜",
      },
      {
        keywords: ["black medium square", "shape", "button", "icon"],
        name: "black medium square",
        order: 205,
        unified: "25FC-FE0F",
        emoji: "◼️",
      },
      {
        keywords: ["white medium square", "shape", "stone", "icon"],
        name: "white medium square",
        order: 206,
        unified: "25FB-FE0F",
        emoji: "◻️",
      },
      {
        keywords: ["black medium small square", "icon", "shape", "button"],
        name: "black medium small square",
        order: 207,
        unified: "25FE",
        emoji: "◾",
      },
      {
        keywords: [
          "white medium small square",
          "shape",
          "stone",
          "icon",
          "button",
        ],
        name: "white medium small square",
        order: 208,
        unified: "25FD",
        emoji: "◽",
      },
      {
        keywords: ["black small square", "shape", "icon"],
        name: "black small square",
        order: 209,
        unified: "25AA-FE0F",
        emoji: "▪️",
      },
      {
        keywords: ["white small square", "shape", "icon"],
        name: "white small square",
        order: 210,
        unified: "25AB-FE0F",
        emoji: "▫️",
      },
      {
        keywords: ["large orange diamond", "shape", "jewel", "gem"],
        name: "large orange diamond",
        order: 211,
        unified: "1F536",
        emoji: "🔶",
      },
      {
        keywords: ["large blue diamond", "shape", "jewel", "gem"],
        name: "large blue diamond",
        order: 212,
        unified: "1F537",
        emoji: "🔷",
      },
      {
        keywords: ["small orange diamond", "shape", "jewel", "gem"],
        name: "small orange diamond",
        order: 213,
        unified: "1F538",
        emoji: "🔸",
      },
      {
        keywords: ["small blue diamond", "shape", "jewel", "gem"],
        name: "small blue diamond",
        order: 214,
        unified: "1F539",
        emoji: "🔹",
      },
      {
        keywords: [
          "red triangle pointed up",
          "shape",
          "direction",
          "up",
          "top",
          "small red triangle",
          "up pointing red triangle",
        ],
        name: "up-pointing red triangle",
        order: 215,
        unified: "1F53A",
        emoji: "🔺",
      },
      {
        keywords: [
          "red triangle pointed down",
          "shape",
          "direction",
          "bottom",
          "small red triangle down",
          "down pointing red triangle",
        ],
        name: "down-pointing red triangle",
        order: 216,
        unified: "1F53B",
        emoji: "🔻",
      },
      {
        keywords: [
          "diamond with a dot",
          "jewel",
          "blue",
          "gem",
          "crystal",
          "fancy",
          "diamond shape with a dot inside",
        ],
        name: "diamond shape with a dot inside",
        order: 217,
        unified: "1F4A0",
        emoji: "💠",
      },
      {
        keywords: ["radio button", "input", "old", "music", "circle"],
        name: "radio button",
        order: 218,
        unified: "1F518",
        emoji: "🔘",
      },
      {
        keywords: ["white square button", "shape", "input"],
        name: "white square button",
        order: 219,
        unified: "1F533",
        emoji: "🔳",
      },
      {
        keywords: ["black square button", "shape", "input", "frame"],
        name: "black square button",
        order: 220,
        unified: "1F532",
        emoji: "🔲",
      },
    ],
  },
  {
    title: "flags",
    data: [
      {
        keywords: [
          "checkered flag",
          "finish line",
          "chequered flag",
          "contest",
          "finishline",
          "race",
          "gokart",
        ],
        name: "chequered flag",
        order: 1,
        unified: "1F3C1",
        emoji: "🏁",
      },
      {
        keywords: [
          "triangular flag",
          "mark",
          "milestone",
          "place",
          "triangular flag on post",
        ],
        name: "triangular flag on post",
        order: 2,
        unified: "1F6A9",
        emoji: "🚩",
      },
      {
        keywords: ["crossed flags", "japanese", "nation", "country", "border"],
        name: "crossed flags",
        order: 3,
        unified: "1F38C",
        emoji: "🎌",
      },
      {
        keywords: ["black flag", "pirate", "waving black flag"],
        name: "waving black flag",
        order: 4,
        unified: "1F3F4",
        emoji: "🏴",
      },
      {
        keywords: [
          "white flag",
          "losing",
          "loser",
          "lost",
          "surrender",
          "give up",
          "fail",
          "waving white flag",
        ],
        name: "white flag",
        order: 5,
        unified: "1F3F3-FE0F",
        emoji: "🏳️",
      },
      {
        keywords: [
          "rainbow flag",
          "gay pride flag",
          "lgbt pride flag",
          "gay pride",
          "lgbt pride",
          "glbt",
          "lgbt",
          "lgbtq",
          "pride parade",
          "flag",
          "rainbow",
          "pride",
          "gay",
          "queer",
          "homosexual",
          "lesbian",
          "bisexual",
          "transgender",
        ],
        name: "rainbow flag",
        order: 6,
        unified: "1F3F3-FE0F-200D-1F308",
        emoji: "🏳️‍🌈",
      },
      {
        keywords: ["transgender flag", "lgbtq"],
        name: "transgender flag",
        order: 7,
        unified: "1F3F3-FE0F-200D-26A7-FE0F",
        emoji: "🏳️‍⚧️",
      },
      {
        keywords: ["pirate flag", "skull", "crossbones", "flag", "banner"],
        name: "pirate flag",
        order: 8,
        unified: "1F3F4-200D-2620-FE0F",
        emoji: "🏴‍☠️",
      },
      {
        keywords: ["flag ascension island", "flag ac", "ascension island flag"],
        name: "ascension island flag",
        order: 9,
        unified: "1F1E6-1F1E8",
        emoji: "🇦🇨",
      },
      {
        keywords: [
          "andorra",
          "andorran flag",
          "flag andorra",
          "ad",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ad",
          "andorra flag",
        ],
        name: "andorra flag",
        order: 10,
        unified: "1F1E6-1F1E9",
        emoji: "🇦🇩",
      },
      {
        keywords: [
          "united arab emirates",
          "uae",
          "uae flag",
          "emirates flag",
          "emirati flag",
          "flag united arab emirates",
          "united",
          "arab",
          "emirates",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ae",
          "united arab emirates flag",
        ],
        name: "united arab emirates flag",
        order: 11,
        unified: "1F1E6-1F1EA",
        emoji: "🇦🇪",
      },
      {
        keywords: [
          "afghanistan",
          "afghan flag",
          "flag afghanistan",
          "af",
          "flag",
          "nation",
          "country",
          "banner",
          "flag af",
          "afghanistan flag",
        ],
        name: "afghanistan flag",
        order: 12,
        unified: "1F1E6-1F1EB",
        emoji: "🇦🇫",
      },
      {
        keywords: [
          "antigua & barbuda",
          "antigua and barbuda",
          "antiguan flag",
          "barbudan flag",
          "flag antigua barbuda",
          "antigua",
          "barbuda",
          "flag",
          "nation",
          "country",
          "banner",
          "antigua barbuda",
          "flag ag",
          "antigua & barbuda flag",
        ],
        name: "antigua & barbuda flag",
        order: 13,
        unified: "1F1E6-1F1EC",
        emoji: "🇦🇬",
      },
      {
        keywords: [
          "anguilla",
          "anguillan flag",
          "flag anguilla",
          "ai",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ai",
          "anguilla flag",
        ],
        name: "anguilla flag",
        order: 14,
        unified: "1F1E6-1F1EE",
        emoji: "🇦🇮",
      },
      {
        keywords: [
          "albania",
          "albanian flag",
          "flag albania",
          "al",
          "flag",
          "nation",
          "country",
          "banner",
          "flag al",
          "albania flag",
        ],
        name: "albania flag",
        order: 15,
        unified: "1F1E6-1F1F1",
        emoji: "🇦🇱",
      },
      {
        keywords: [
          "armenia",
          "armenian flag",
          "flag armenia",
          "am",
          "flag",
          "nation",
          "country",
          "banner",
          "flag am",
          "armenia flag",
        ],
        name: "armenia flag",
        order: 16,
        unified: "1F1E6-1F1F2",
        emoji: "🇦🇲",
      },
      {
        keywords: [
          "angola",
          "angolan flag",
          "flag angola",
          "ao",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ao",
          "angola flag",
        ],
        name: "angola flag",
        order: 17,
        unified: "1F1E6-1F1F4",
        emoji: "🇦🇴",
      },
      {
        keywords: [
          "antarctica",
          "antarctic flag",
          "flag antarctica",
          "aq",
          "flag",
          "nation",
          "country",
          "banner",
          "flag aq",
          "antarctica flag",
        ],
        name: "antarctica flag",
        order: 18,
        unified: "1F1E6-1F1F6",
        emoji: "🇦🇶",
      },
      {
        keywords: [
          "argentina",
          "argentinian flag",
          "flag argentina",
          "ar",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ar",
          "argentina flag",
        ],
        name: "argentina flag",
        order: 19,
        unified: "1F1E6-1F1F7",
        emoji: "🇦🇷",
      },
      {
        keywords: [
          "american samoa",
          "american samoan flag",
          "flag american samoa",
          "american",
          "ws",
          "flag",
          "nation",
          "country",
          "banner",
          "flag as",
          "american samoa flag",
        ],
        name: "american samoa flag",
        order: 20,
        unified: "1F1E6-1F1F8",
        emoji: "🇦🇸",
      },
      {
        keywords: [
          "austria",
          "austrian flag",
          "flag austria",
          "at",
          "flag",
          "nation",
          "country",
          "banner",
          "flag at",
          "austria flag",
        ],
        name: "austria flag",
        order: 21,
        unified: "1F1E6-1F1F9",
        emoji: "🇦🇹",
      },
      {
        keywords: [
          "australia",
          "australian flag",
          "flag australia",
          "au",
          "flag",
          "nation",
          "country",
          "banner",
          "flag au",
          "australia flag",
        ],
        name: "australia flag",
        order: 22,
        unified: "1F1E6-1F1FA",
        emoji: "🇦🇺",
      },
      {
        keywords: [
          "aruba",
          "aruban flag",
          "flag aruba",
          "aw",
          "flag",
          "nation",
          "country",
          "banner",
          "flag aw",
          "aruba flag",
        ],
        name: "aruba flag",
        order: 23,
        unified: "1F1E6-1F1FC",
        emoji: "🇦🇼",
      },
      {
        keywords: [
          "aland islands",
          "aland island flag",
          "flag aland islands",
          "åland",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "aland islands",
          "flag ax",
          "aland islands flag",
        ],
        name: "aland islands flag",
        order: 24,
        unified: "1F1E6-1F1FD",
        emoji: "🇦🇽",
      },
      {
        keywords: [
          "azerbaijan",
          "azerbaijani flag",
          "flag azerbaijan",
          "az",
          "flag",
          "nation",
          "country",
          "banner",
          "flag az",
          "azerbaijan flag",
        ],
        name: "azerbaijan flag",
        order: 25,
        unified: "1F1E6-1F1FF",
        emoji: "🇦🇿",
      },
      {
        keywords: [
          "bosnia & herzegovina",
          "bosnia and herzegovina",
          "bosnia",
          "herzegovina",
          "bosnian flag",
          "herzegovinian flag",
          "flag bosnia herzegovina",
          "flag",
          "nation",
          "country",
          "banner",
          "bosnia herzegovina",
          "flag ba",
          "bosnia & herzegovina flag",
        ],
        name: "bosnia & herzegovina flag",
        order: 26,
        unified: "1F1E7-1F1E6",
        emoji: "🇧🇦",
      },
      {
        keywords: [
          "barbados",
          "barbadian flag",
          "flag barbados",
          "bb",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bb",
          "barbados flag",
        ],
        name: "barbados flag",
        order: 27,
        unified: "1F1E7-1F1E7",
        emoji: "🇧🇧",
      },
      {
        keywords: [
          "bangladesh",
          "bangladeshi flag",
          "flag bangladesh",
          "bd",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bd",
          "bangladesh flag",
        ],
        name: "bangladesh flag",
        order: 28,
        unified: "1F1E7-1F1E9",
        emoji: "🇧🇩",
      },
      {
        keywords: [
          "belgium",
          "belgian flag",
          "flag belgium",
          "be",
          "flag",
          "nation",
          "country",
          "banner",
          "flag be",
          "belgium flag",
        ],
        name: "belgium flag",
        order: 29,
        unified: "1F1E7-1F1EA",
        emoji: "🇧🇪",
      },
      {
        keywords: [
          "burkina faso",
          "burkinabe flag",
          "flag burkina faso",
          "burkina",
          "faso",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bf",
          "burkina faso flag",
        ],
        name: "burkina faso flag",
        order: 30,
        unified: "1F1E7-1F1EB",
        emoji: "🇧🇫",
      },
      {
        keywords: [
          "bulgaria",
          "bulgarian flag",
          "flag bulgaria",
          "bg",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bg",
          "bulgaria flag",
        ],
        name: "bulgaria flag",
        order: 31,
        unified: "1F1E7-1F1EC",
        emoji: "🇧🇬",
      },
      {
        keywords: [
          "bahrain",
          "bahraini flag",
          "flag bahrain",
          "bh",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bh",
          "bahrain flag",
        ],
        name: "bahrain flag",
        order: 32,
        unified: "1F1E7-1F1ED",
        emoji: "🇧🇭",
      },
      {
        keywords: [
          "burundi",
          "burundian flag",
          "flag burundi",
          "bi",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bi",
          "burundi flag",
        ],
        name: "burundi flag",
        order: 33,
        unified: "1F1E7-1F1EE",
        emoji: "🇧🇮",
      },
      {
        keywords: [
          "benin",
          "beninese flag",
          "flag benin",
          "bj",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bj",
          "benin flag",
        ],
        name: "benin flag",
        order: 34,
        unified: "1F1E7-1F1EF",
        emoji: "🇧🇯",
      },
      {
        keywords: [
          "saint barthélemy",
          "saint barthelemy",
          "barthélemois flag",
          "flag st barthelemy",
          "saint",
          "barthélemy",
          "flag",
          "nation",
          "country",
          "banner",
          "st barthelemy",
          "flag bl",
          "st. barthã©lemy flag",
        ],
        name: "st. barthã©lemy flag",
        order: 35,
        unified: "1F1E7-1F1F1",
        emoji: "🇧🇱",
      },
      {
        keywords: [
          "bermuda",
          "bermudan flag",
          "flag bermuda",
          "bm",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bm",
          "bermuda flag",
        ],
        name: "bermuda flag",
        order: 36,
        unified: "1F1E7-1F1F2",
        emoji: "🇧🇲",
      },
      {
        keywords: [
          "brunei",
          "bruneian flag",
          "flag brunei",
          "bn",
          "darussalam",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bn",
          "brunei flag",
        ],
        name: "brunei flag",
        order: 37,
        unified: "1F1E7-1F1F3",
        emoji: "🇧🇳",
      },
      {
        keywords: [
          "bolivia",
          "bolivian flag",
          "flag bolivia",
          "bo",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bo",
          "bolivia flag",
        ],
        name: "bolivia flag",
        order: 38,
        unified: "1F1E7-1F1F4",
        emoji: "🇧🇴",
      },
      {
        keywords: [
          "bonaire",
          "dutch caribbean flag",
          "flag caribbean netherlands",
          "flag",
          "nation",
          "country",
          "banner",
          "caribbean netherlands",
          "flag bq",
          "caribbean netherlands flag",
        ],
        name: "caribbean netherlands flag",
        order: 39,
        unified: "1F1E7-1F1F6",
        emoji: "🇧🇶",
      },
      {
        keywords: [
          "brazil",
          "brazilian flag",
          "flag brazil",
          "br",
          "flag",
          "nation",
          "country",
          "banner",
          "flag br",
          "brazil flag",
        ],
        name: "brazil flag",
        order: 40,
        unified: "1F1E7-1F1F7",
        emoji: "🇧🇷",
      },
      {
        keywords: [
          "bahamas",
          "bahamian flag",
          "flag bahamas",
          "bs",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bs",
          "bahamas flag",
        ],
        name: "bahamas flag",
        order: 41,
        unified: "1F1E7-1F1F8",
        emoji: "🇧🇸",
      },
      {
        keywords: [
          "bhutan",
          "bhutanese flag",
          "flag bhutan",
          "bt",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bt",
          "bhutan flag",
        ],
        name: "bhutan flag",
        order: 42,
        unified: "1F1E7-1F1F9",
        emoji: "🇧🇹",
      },
      {
        keywords: [
          "flag bouvet island",
          "norway",
          "flag bv",
          "bouvet island flag",
        ],
        name: "bouvet island flag",
        order: 43,
        unified: "1F1E7-1F1FB",
        emoji: "🇧🇻",
      },
      {
        keywords: [
          "botswana",
          "botswanan flag",
          "flag botswana",
          "bw",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bw",
          "botswana flag",
        ],
        name: "botswana flag",
        order: 44,
        unified: "1F1E7-1F1FC",
        emoji: "🇧🇼",
      },
      {
        keywords: [
          "belarus",
          "belarusian flag",
          "flag belarus",
          "by",
          "flag",
          "nation",
          "country",
          "banner",
          "flag by",
          "belarus flag",
        ],
        name: "belarus flag",
        order: 45,
        unified: "1F1E7-1F1FE",
        emoji: "🇧🇾",
      },
      {
        keywords: [
          "belize",
          "belizean flag",
          "flag belize",
          "bz",
          "flag",
          "nation",
          "country",
          "banner",
          "flag bz",
          "belize flag",
        ],
        name: "belize flag",
        order: 46,
        unified: "1F1E7-1F1FF",
        emoji: "🇧🇿",
      },
      {
        keywords: [
          "canada",
          "canadian flag",
          "flag canada",
          "ca",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ca",
          "canada flag",
        ],
        name: "canada flag",
        order: 47,
        unified: "1F1E8-1F1E6",
        emoji: "🇨🇦",
      },
      {
        keywords: [
          "cocos (keeling) islands",
          "cocos island flag",
          "flag cocos islands",
          "cocos",
          "keeling",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "cocos islands",
          "flag cc",
          "cocos (keeling) islands flag",
        ],
        name: "cocos (keeling) islands flag",
        order: 48,
        unified: "1F1E8-1F1E8",
        emoji: "🇨🇨",
      },
      {
        keywords: [
          "congo   kinshasa",
          "democratic republic of the congo",
          "congolese flag",
          "flag congo kinshasa",
          "congo",
          "democratic",
          "republic",
          "flag",
          "nation",
          "country",
          "banner",
          "congo kinshasa",
          "flag cd",
          "congo   kinshasa flag",
        ],
        name: "congo - kinshasa flag",
        order: 49,
        unified: "1F1E8-1F1E9",
        emoji: "🇨🇩",
      },
      {
        keywords: [
          "central african republic",
          "central african flag",
          "flag central african republic",
          "central",
          "african",
          "republic",
          "flag",
          "nation",
          "country",
          "banner",
          "flag cf",
          "central african republic flag",
        ],
        name: "central african republic flag",
        order: 50,
        unified: "1F1E8-1F1EB",
        emoji: "🇨🇫",
      },
      {
        keywords: [
          "congo   brazzaville",
          "republic of the congo",
          "congo republic",
          "congolese flag",
          "flag congo brazzaville",
          "congo",
          "flag",
          "nation",
          "country",
          "banner",
          "congo brazzaville",
          "flag cg",
          "congo   brazzaville flag",
        ],
        name: "congo - brazzaville flag",
        order: 51,
        unified: "1F1E8-1F1EC",
        emoji: "🇨🇬",
      },
      {
        keywords: [
          "switzerland",
          "swiss flag",
          "flag switzerland",
          "ch",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ch",
          "switzerland flag",
        ],
        name: "switzerland flag",
        order: 52,
        unified: "1F1E8-1F1ED",
        emoji: "🇨🇭",
      },
      {
        keywords: [
          "côte d’ivoire",
          "ivory coast",
          "ivorian flag",
          "flag cote d ivoire",
          "ivory",
          "coast",
          "flag",
          "nation",
          "country",
          "banner",
          "cote d ivoire",
          "flag ci",
          "cã´te dâ€™ivoire flag",
        ],
        name: "cã´te dâ€™ivoire flag",
        order: 53,
        unified: "1F1E8-1F1EE",
        emoji: "🇨🇮",
      },
      {
        keywords: [
          "cook islands",
          "cook island flag",
          "flag cook islands",
          "cook",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ck",
          "cook islands flag",
        ],
        name: "cook islands flag",
        order: 54,
        unified: "1F1E8-1F1F0",
        emoji: "🇨🇰",
      },
      {
        keywords: [
          "chile",
          "chilean flag",
          "flag chile",
          "flag",
          "nation",
          "country",
          "banner",
          "flag cl",
          "chile flag",
        ],
        name: "chile flag",
        order: 55,
        unified: "1F1E8-1F1F1",
        emoji: "🇨🇱",
      },
      {
        keywords: [
          "cameroon",
          "cameroonian flag",
          "flag cameroon",
          "cm",
          "flag",
          "nation",
          "country",
          "banner",
          "flag cm",
          "cameroon flag",
        ],
        name: "cameroon flag",
        order: 56,
        unified: "1F1E8-1F1F2",
        emoji: "🇨🇲",
      },
      {
        keywords: [
          "china",
          "chinese flag",
          "people’s republic of china",
          "peoples republic of china",
          "flag china",
          "chinese",
          "prc",
          "flag",
          "country",
          "nation",
          "banner",
          "cn",
          "flag cn",
          "china flag",
        ],
        name: "china flag",
        order: 57,
        unified: "1F1E8-1F1F3",
        emoji: "🇨🇳",
      },
      {
        keywords: [
          "colombia",
          "colombian flag",
          "flag colombia",
          "co",
          "flag",
          "nation",
          "country",
          "banner",
          "flag co",
          "colombia flag",
        ],
        name: "colombia flag",
        order: 58,
        unified: "1F1E8-1F1F4",
        emoji: "🇨🇴",
      },
      {
        keywords: [
          "flag clipperton island",
          "flag cp",
          "clipperton island flag",
        ],
        name: "clipperton island flag",
        order: 59,
        unified: "1F1E8-1F1F5",
        emoji: "🇨🇵",
      },
      {
        keywords: [
          "costa rica",
          "costa rican flag",
          "flag costa rica",
          "costa",
          "rica",
          "flag",
          "nation",
          "country",
          "banner",
          "flag cr",
          "costa rica flag",
        ],
        name: "costa rica flag",
        order: 60,
        unified: "1F1E8-1F1F7",
        emoji: "🇨🇷",
      },
      {
        keywords: [
          "cuba",
          "cuban flag",
          "flag cuba",
          "cu",
          "flag",
          "nation",
          "country",
          "banner",
          "flag cu",
          "cuba flag",
        ],
        name: "cuba flag",
        order: 61,
        unified: "1F1E8-1F1FA",
        emoji: "🇨🇺",
      },
      {
        keywords: [
          "cape verde",
          "cape verdean flag",
          "flag cape verde",
          "cabo",
          "verde",
          "flag",
          "nation",
          "country",
          "banner",
          "flag cv",
          "cape verde flag",
        ],
        name: "cape verde flag",
        order: 62,
        unified: "1F1E8-1F1FB",
        emoji: "🇨🇻",
      },
      {
        keywords: [
          "curaçao",
          "curacao",
          "curaçaoan flag",
          "flag curacao",
          "flag",
          "nation",
          "country",
          "banner",
          "flag cw",
          "curaã§ao flag",
        ],
        name: "curaã§ao flag",
        order: 63,
        unified: "1F1E8-1F1FC",
        emoji: "🇨🇼",
      },
      {
        keywords: [
          "christmas island",
          "christmas island flag",
          "flag christmas island",
          "christmas",
          "island",
          "flag",
          "nation",
          "country",
          "banner",
          "flag cx",
        ],
        name: "christmas island flag",
        order: 64,
        unified: "1F1E8-1F1FD",
        emoji: "🇨🇽",
      },
      {
        keywords: [
          "cyprus",
          "cypriot flag",
          "flag cyprus",
          "cy",
          "flag",
          "nation",
          "country",
          "banner",
          "flag cy",
          "cyprus flag",
        ],
        name: "cyprus flag",
        order: 65,
        unified: "1F1E8-1F1FE",
        emoji: "🇨🇾",
      },
      {
        keywords: [
          "czech republic",
          "czech flag",
          "czechia",
          "czechia flag",
          "flag czechia",
          "cz",
          "flag",
          "nation",
          "country",
          "banner",
          "flag cz",
        ],
        name: "czechia flag",
        order: 66,
        unified: "1F1E8-1F1FF",
        emoji: "🇨🇿",
      },
      {
        keywords: [
          "germany",
          "german flag",
          "flag germany",
          "german",
          "nation",
          "flag",
          "country",
          "banner",
          "de",
          "flag de",
          "germany flag",
        ],
        name: "germany flag",
        order: 67,
        unified: "1F1E9-1F1EA",
        emoji: "🇩🇪",
      },
      {
        keywords: ["flag diego garcia", "flag dg", "diego garcia flag"],
        name: "diego garcia flag",
        order: 68,
        unified: "1F1E9-1F1EC",
        emoji: "🇩🇬",
      },
      {
        keywords: [
          "djibouti",
          "djiboutian flag",
          "flag djibouti",
          "dj",
          "flag",
          "nation",
          "country",
          "banner",
          "flag dj",
          "djibouti flag",
        ],
        name: "djibouti flag",
        order: 69,
        unified: "1F1E9-1F1EF",
        emoji: "🇩🇯",
      },
      {
        keywords: [
          "denmark",
          "danish flag",
          "flag denmark",
          "dk",
          "flag",
          "nation",
          "country",
          "banner",
          "flag dk",
          "denmark flag",
        ],
        name: "denmark flag",
        order: 70,
        unified: "1F1E9-1F1F0",
        emoji: "🇩🇰",
      },
      {
        keywords: [
          "dominica",
          "dominican flag",
          "flag dominica",
          "dm",
          "flag",
          "nation",
          "country",
          "banner",
          "flag dm",
          "dominica flag",
        ],
        name: "dominica flag",
        order: 71,
        unified: "1F1E9-1F1F2",
        emoji: "🇩🇲",
      },
      {
        keywords: [
          "dominican republic",
          "dominican flag",
          "flag dominican republic",
          "dominican",
          "republic",
          "flag",
          "nation",
          "country",
          "banner",
          "flag do",
          "dominican republic flag",
        ],
        name: "dominican republic flag",
        order: 72,
        unified: "1F1E9-1F1F4",
        emoji: "🇩🇴",
      },
      {
        keywords: [
          "algeria",
          "algerian flag",
          "flag algeria",
          "dz",
          "flag",
          "nation",
          "country",
          "banner",
          "flag dz",
          "algeria flag",
        ],
        name: "algeria flag",
        order: 73,
        unified: "1F1E9-1F1FF",
        emoji: "🇩🇿",
      },
      {
        keywords: ["flag ceuta melilla", "flag ea", "ceuta & melilla flag"],
        name: "ceuta & melilla flag",
        order: 74,
        unified: "1F1EA-1F1E6",
        emoji: "🇪🇦",
      },
      {
        keywords: [
          "ecuador",
          "ecuadorean flag",
          "flag ecuador",
          "ec",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ec",
          "ecuador flag",
        ],
        name: "ecuador flag",
        order: 75,
        unified: "1F1EA-1F1E8",
        emoji: "🇪🇨",
      },
      {
        keywords: [
          "estonia",
          "estonian flag",
          "flag estonia",
          "ee",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ee",
          "estonia flag",
        ],
        name: "estonia flag",
        order: 76,
        unified: "1F1EA-1F1EA",
        emoji: "🇪🇪",
      },
      {
        keywords: [
          "egypt",
          "egyptian flag",
          "flag egypt",
          "eg",
          "flag",
          "nation",
          "country",
          "banner",
          "flag eg",
          "egypt flag",
        ],
        name: "egypt flag",
        order: 77,
        unified: "1F1EA-1F1EC",
        emoji: "🇪🇬",
      },
      {
        keywords: [
          "western sahara",
          "sahrawi flag",
          "flag western sahara",
          "western",
          "sahara",
          "flag",
          "nation",
          "country",
          "banner",
          "flag eh",
          "western sahara flag",
        ],
        name: "western sahara flag",
        order: 78,
        unified: "1F1EA-1F1ED",
        emoji: "🇪🇭",
      },
      {
        keywords: [
          "eritrea",
          "eritrean flag",
          "flag eritrea",
          "er",
          "flag",
          "nation",
          "country",
          "banner",
          "flag er",
          "eritrea flag",
        ],
        name: "eritrea flag",
        order: 79,
        unified: "1F1EA-1F1F7",
        emoji: "🇪🇷",
      },
      {
        keywords: [
          "spain",
          "spanish flag",
          "flag spain",
          "flag",
          "nation",
          "country",
          "banner",
          "es",
          "flag es",
          "spain flag",
        ],
        name: "spain flag",
        order: 80,
        unified: "1F1EA-1F1F8",
        emoji: "🇪🇸",
      },
      {
        keywords: [
          "ethiopia",
          "ethiopian flag",
          "flag ethiopia",
          "et",
          "flag",
          "nation",
          "country",
          "banner",
          "flag et",
          "ethiopia flag",
        ],
        name: "ethiopia flag",
        order: 81,
        unified: "1F1EA-1F1F9",
        emoji: "🇪🇹",
      },
      {
        keywords: [
          "european union",
          "european flag",
          "flag european union",
          "european",
          "union",
          "flag",
          "banner",
          "flag eu",
          "european union flag",
        ],
        name: "european union flag",
        order: 82,
        unified: "1F1EA-1F1FA",
        emoji: "🇪🇺",
      },
      {
        keywords: [
          "finland",
          "finnish flag",
          "flag finland",
          "fi",
          "flag",
          "nation",
          "country",
          "banner",
          "flag fi",
          "finland flag",
        ],
        name: "finland flag",
        order: 83,
        unified: "1F1EB-1F1EE",
        emoji: "🇫🇮",
      },
      {
        keywords: [
          "fiji",
          "fijian flag",
          "flag fiji",
          "fj",
          "flag",
          "nation",
          "country",
          "banner",
          "flag fj",
          "fiji flag",
        ],
        name: "fiji flag",
        order: 84,
        unified: "1F1EB-1F1EF",
        emoji: "🇫🇯",
      },
      {
        keywords: [
          "falkland islands",
          "falkland island flag",
          "flag falkland islands",
          "falkland",
          "islands",
          "malvinas",
          "flag",
          "nation",
          "country",
          "banner",
          "flag fk",
          "falkland islands flag",
        ],
        name: "falkland islands flag",
        order: 85,
        unified: "1F1EB-1F1F0",
        emoji: "🇫🇰",
      },
      {
        keywords: [
          "micronesia",
          "micronesian flag",
          "flag micronesia",
          "federated",
          "states",
          "flag",
          "nation",
          "country",
          "banner",
          "flag fm",
          "micronesia flag",
        ],
        name: "micronesia flag",
        order: 86,
        unified: "1F1EB-1F1F2",
        emoji: "🇫🇲",
      },
      {
        keywords: [
          "faroe islands",
          "faroe island flag",
          "flag faroe islands",
          "faroe",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "flag fo",
          "faroe islands flag",
        ],
        name: "faroe islands flag",
        order: 87,
        unified: "1F1EB-1F1F4",
        emoji: "🇫🇴",
      },
      {
        keywords: [
          "france",
          "french flag",
          "flag france",
          "banner",
          "flag",
          "nation",
          "french",
          "country",
          "fr",
          "flag fr",
          "france flag",
        ],
        name: "france flag",
        order: 88,
        unified: "1F1EB-1F1F7",
        emoji: "🇫🇷",
      },
      {
        keywords: [
          "gabon",
          "gabonese flag",
          "flag gabon",
          "ga",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ga",
          "gabon flag",
        ],
        name: "gabon flag",
        order: 89,
        unified: "1F1EC-1F1E6",
        emoji: "🇬🇦",
      },
      {
        keywords: [
          "united kingdom",
          "uk",
          "britain",
          "great britain",
          "union jack",
          "gb",
          "british flag",
          "uk flag",
          "flag united kingdom",
          "united",
          "kingdom",
          "great",
          "northern",
          "ireland",
          "flag",
          "nation",
          "country",
          "banner",
          "british",
          "english",
          "england",
          "flag gb",
          "united kingdom flag",
        ],
        name: "united kingdom flag",
        order: 90,
        unified: "1F1EC-1F1E7",
        emoji: "🇬🇧",
      },
      {
        keywords: [
          "grenada",
          "grenadian flag",
          "flag grenada",
          "gd",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gd",
          "grenada flag",
        ],
        name: "grenada flag",
        order: 91,
        unified: "1F1EC-1F1E9",
        emoji: "🇬🇩",
      },
      {
        keywords: [
          "georgia",
          "georgian flag",
          "flag georgia",
          "ge",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ge",
          "georgia flag",
        ],
        name: "georgia flag",
        order: 92,
        unified: "1F1EC-1F1EA",
        emoji: "🇬🇪",
      },
      {
        keywords: [
          "french guiana",
          "french guianese flag",
          "flag french guiana",
          "french",
          "guiana",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gf",
          "french guiana flag",
        ],
        name: "french guiana flag",
        order: 93,
        unified: "1F1EC-1F1EB",
        emoji: "🇬🇫",
      },
      {
        keywords: [
          "guernsey",
          "channel island flag",
          "flag guernsey",
          "gg",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gg",
          "guernsey flag",
        ],
        name: "guernsey flag",
        order: 94,
        unified: "1F1EC-1F1EC",
        emoji: "🇬🇬",
      },
      {
        keywords: [
          "ghana",
          "ghanaian flag",
          "flag ghana",
          "gh",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gh",
          "ghana flag",
        ],
        name: "ghana flag",
        order: 95,
        unified: "1F1EC-1F1ED",
        emoji: "🇬🇭",
      },
      {
        keywords: [
          "gibraltar",
          "gibraltar flag",
          "flag gibraltar",
          "gi",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gi",
        ],
        name: "gibraltar flag",
        order: 96,
        unified: "1F1EC-1F1EE",
        emoji: "🇬🇮",
      },
      {
        keywords: [
          "greenland",
          "greenlandic flag",
          "flag greenland",
          "gl",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gl",
          "greenland flag",
        ],
        name: "greenland flag",
        order: 97,
        unified: "1F1EC-1F1F1",
        emoji: "🇬🇱",
      },
      {
        keywords: [
          "gambia",
          "gambian flag",
          "flag gambia",
          "gm",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gm",
          "gambia flag",
        ],
        name: "gambia flag",
        order: 98,
        unified: "1F1EC-1F1F2",
        emoji: "🇬🇲",
      },
      {
        keywords: [
          "guinea",
          "guinean flag",
          "flag guinea",
          "gn",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gn",
          "guinea flag",
        ],
        name: "guinea flag",
        order: 99,
        unified: "1F1EC-1F1F3",
        emoji: "🇬🇳",
      },
      {
        keywords: [
          "guadeloupe",
          "guadeloupe flag",
          "flag guadeloupe",
          "gp",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gp",
        ],
        name: "guadeloupe flag",
        order: 100,
        unified: "1F1EC-1F1F5",
        emoji: "🇬🇵",
      },
      {
        keywords: [
          "equatorial guinea",
          "equatorial guinean flag",
          "equatoguinean flag",
          "flag equatorial guinea",
          "equatorial",
          "gn",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gq",
          "equatorial guinea flag",
        ],
        name: "equatorial guinea flag",
        order: 101,
        unified: "1F1EC-1F1F6",
        emoji: "🇬🇶",
      },
      {
        keywords: [
          "greece",
          "greek flag",
          "flag greece",
          "gr",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gr",
          "greece flag",
        ],
        name: "greece flag",
        order: 102,
        unified: "1F1EC-1F1F7",
        emoji: "🇬🇷",
      },
      {
        keywords: [
          "south georgia & the south sandwich islands",
          "south georgia and the south sandwich islands",
          "south georgia flag",
          "south sandwich islands flag",
          "flag south georgia south sandwich islands",
          "south",
          "georgia",
          "sandwich",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "south georgia south sandwich islands",
          "flag gs",
          "south georgia & south sandwich islands flag",
        ],
        name: "south georgia & south sandwich islands flag",
        order: 103,
        unified: "1F1EC-1F1F8",
        emoji: "🇬🇸",
      },
      {
        keywords: [
          "guatemala",
          "guatemalan flag",
          "flag guatemala",
          "gt",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gt",
          "guatemala flag",
        ],
        name: "guatemala flag",
        order: 104,
        unified: "1F1EC-1F1F9",
        emoji: "🇬🇹",
      },
      {
        keywords: [
          "guam",
          "guamanian flag",
          "flag guam",
          "gu",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gu",
          "guam flag",
        ],
        name: "guam flag",
        order: 105,
        unified: "1F1EC-1F1FA",
        emoji: "🇬🇺",
      },
      {
        keywords: [
          "guinea bissau",
          "bissau guinean flag",
          "flag guinea bissau",
          "gw",
          "bissau",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gw",
          "guinea bissau flag",
        ],
        name: "guinea-bissau flag",
        order: 106,
        unified: "1F1EC-1F1FC",
        emoji: "🇬🇼",
      },
      {
        keywords: [
          "guyana",
          "guyanese flag",
          "flag guyana",
          "gy",
          "flag",
          "nation",
          "country",
          "banner",
          "flag gy",
          "guyana flag",
        ],
        name: "guyana flag",
        order: 107,
        unified: "1F1EC-1F1FE",
        emoji: "🇬🇾",
      },
      {
        keywords: [
          "hong kong",
          "hong kong flag",
          "flag hong kong sar china",
          "hong",
          "kong",
          "flag",
          "nation",
          "country",
          "banner",
          "hong kong sar china",
          "flag hk",
          "hong kong sar china flag",
        ],
        name: "hong kong sar china flag",
        order: 108,
        unified: "1F1ED-1F1F0",
        emoji: "🇭🇰",
      },
      {
        keywords: [
          "flag heard mcdonald islands",
          "flag hm",
          "heard & mcdonald islands flag",
        ],
        name: "heard & mcdonald islands flag",
        order: 109,
        unified: "1F1ED-1F1F2",
        emoji: "🇭🇲",
      },
      {
        keywords: [
          "honduras",
          "honduran flag",
          "flag honduras",
          "hn",
          "flag",
          "nation",
          "country",
          "banner",
          "flag hn",
          "honduras flag",
        ],
        name: "honduras flag",
        order: 110,
        unified: "1F1ED-1F1F3",
        emoji: "🇭🇳",
      },
      {
        keywords: [
          "croatia",
          "croatian flag",
          "flag croatia",
          "hr",
          "flag",
          "nation",
          "country",
          "banner",
          "flag hr",
          "croatia flag",
        ],
        name: "croatia flag",
        order: 111,
        unified: "1F1ED-1F1F7",
        emoji: "🇭🇷",
      },
      {
        keywords: [
          "haiti",
          "haitian flag",
          "flag haiti",
          "ht",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ht",
          "haiti flag",
        ],
        name: "haiti flag",
        order: 112,
        unified: "1F1ED-1F1F9",
        emoji: "🇭🇹",
      },
      {
        keywords: [
          "hungary",
          "hungarian flag",
          "flag hungary",
          "hu",
          "flag",
          "nation",
          "country",
          "banner",
          "flag hu",
          "hungary flag",
        ],
        name: "hungary flag",
        order: 113,
        unified: "1F1ED-1F1FA",
        emoji: "🇭🇺",
      },
      {
        keywords: [
          "canary islands",
          "canary island flag",
          "flag canary islands",
          "canary",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ic",
          "canary islands flag",
        ],
        name: "canary islands flag",
        order: 114,
        unified: "1F1EE-1F1E8",
        emoji: "🇮🇨",
      },
      {
        keywords: [
          "indonesia",
          "indonesian flag",
          "flag indonesia",
          "flag",
          "nation",
          "country",
          "banner",
          "flag id",
          "indonesia flag",
        ],
        name: "indonesia flag",
        order: 115,
        unified: "1F1EE-1F1E9",
        emoji: "🇮🇩",
      },
      {
        keywords: [
          "ireland",
          "irish flag",
          "flag ireland",
          "ie",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ie",
          "ireland flag",
        ],
        name: "ireland flag",
        order: 116,
        unified: "1F1EE-1F1EA",
        emoji: "🇮🇪",
      },
      {
        keywords: [
          "israel",
          "israeli flag",
          "flag israel",
          "il",
          "flag",
          "nation",
          "country",
          "banner",
          "flag il",
          "israel flag",
        ],
        name: "israel flag",
        order: 117,
        unified: "1F1EE-1F1F1",
        emoji: "🇮🇱",
      },
      {
        keywords: [
          "isle of man",
          "manx flag",
          "flag isle of man",
          "isle",
          "man",
          "flag",
          "nation",
          "country",
          "banner",
          "flag im",
          "isle of man flag",
        ],
        name: "isle of man flag",
        order: 118,
        unified: "1F1EE-1F1F2",
        emoji: "🇮🇲",
      },
      {
        keywords: [
          "india",
          "indian flag",
          "flag india",
          "in",
          "flag",
          "nation",
          "country",
          "banner",
          "flag in",
          "india flag",
        ],
        name: "india flag",
        order: 119,
        unified: "1F1EE-1F1F3",
        emoji: "🇮🇳",
      },
      {
        keywords: [
          "british indian ocean territory",
          "biot flag",
          "flag british indian ocean territory",
          "british",
          "indian",
          "ocean",
          "territory",
          "flag",
          "nation",
          "country",
          "banner",
          "flag io",
          "british indian ocean territory flag",
        ],
        name: "british indian ocean territory flag",
        order: 120,
        unified: "1F1EE-1F1F4",
        emoji: "🇮🇴",
      },
      {
        keywords: [
          "iraq",
          "iraqi flag",
          "flag iraq",
          "iq",
          "flag",
          "nation",
          "country",
          "banner",
          "flag iq",
          "iraq flag",
        ],
        name: "iraq flag",
        order: 121,
        unified: "1F1EE-1F1F6",
        emoji: "🇮🇶",
      },
      {
        keywords: [
          "iran",
          "iranian flag",
          "flag iran",
          "islamic",
          "republic",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ir",
          "iran flag",
        ],
        name: "iran flag",
        order: 122,
        unified: "1F1EE-1F1F7",
        emoji: "🇮🇷",
      },
      {
        keywords: [
          "iceland",
          "icelandic flag",
          "flag iceland",
          "is",
          "flag",
          "nation",
          "country",
          "banner",
          "flag is",
          "iceland flag",
        ],
        name: "iceland flag",
        order: 123,
        unified: "1F1EE-1F1F8",
        emoji: "🇮🇸",
      },
      {
        keywords: [
          "italy",
          "italian flag",
          "flag italy",
          "flag",
          "nation",
          "country",
          "banner",
          "it",
          "flag it",
          "italy flag",
        ],
        name: "italy flag",
        order: 124,
        unified: "1F1EE-1F1F9",
        emoji: "🇮🇹",
      },
      {
        keywords: [
          "jersey flag",
          "channel island flag",
          "flag jersey",
          "je",
          "flag",
          "nation",
          "country",
          "banner",
          "jersey",
          "flag je",
        ],
        name: "jersey flag",
        order: 125,
        unified: "1F1EF-1F1EA",
        emoji: "🇯🇪",
      },
      {
        keywords: [
          "jamaica",
          "jamaican flag",
          "flag jamaica",
          "jm",
          "flag",
          "nation",
          "country",
          "banner",
          "flag jm",
          "jamaica flag",
        ],
        name: "jamaica flag",
        order: 126,
        unified: "1F1EF-1F1F2",
        emoji: "🇯🇲",
      },
      {
        keywords: [
          "jordan",
          "jordanian flag",
          "flag jordan",
          "jo",
          "flag",
          "nation",
          "country",
          "banner",
          "flag jo",
          "jordan flag",
        ],
        name: "jordan flag",
        order: 127,
        unified: "1F1EF-1F1F4",
        emoji: "🇯🇴",
      },
      {
        keywords: [
          "japan",
          "japanese flag",
          "flag japan",
          "japanese",
          "nation",
          "flag",
          "country",
          "banner",
          "jp",
          "flag jp",
          "japan flag",
        ],
        name: "japan flag",
        order: 128,
        unified: "1F1EF-1F1F5",
        emoji: "🇯🇵",
      },
      {
        keywords: [
          "kenya",
          "kenyan flag",
          "flag kenya",
          "ke",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ke",
          "kenya flag",
        ],
        name: "kenya flag",
        order: 129,
        unified: "1F1F0-1F1EA",
        emoji: "🇰🇪",
      },
      {
        keywords: [
          "kyrgyzstan",
          "kyrgyzstani flag",
          "kyrgyz flag",
          "flag kyrgyzstan",
          "kg",
          "flag",
          "nation",
          "country",
          "banner",
          "flag kg",
          "kyrgyzstan flag",
        ],
        name: "kyrgyzstan flag",
        order: 130,
        unified: "1F1F0-1F1EC",
        emoji: "🇰🇬",
      },
      {
        keywords: [
          "cambodia",
          "cambodian flag",
          "flag cambodia",
          "kh",
          "flag",
          "nation",
          "country",
          "banner",
          "flag kh",
          "cambodia flag",
        ],
        name: "cambodia flag",
        order: 131,
        unified: "1F1F0-1F1ED",
        emoji: "🇰🇭",
      },
      {
        keywords: [
          "kiribati",
          "kiribati flag",
          "flag kiribati",
          "ki",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ki",
        ],
        name: "kiribati flag",
        order: 132,
        unified: "1F1F0-1F1EE",
        emoji: "🇰🇮",
      },
      {
        keywords: [
          "comoros",
          "comoran flag",
          "flag comoros",
          "km",
          "flag",
          "nation",
          "country",
          "banner",
          "flag km",
          "comoros flag",
        ],
        name: "comoros flag",
        order: 133,
        unified: "1F1F0-1F1F2",
        emoji: "🇰🇲",
      },
      {
        keywords: [
          "saint kitts & nevis",
          "saint kitts and nevis",
          "saint kitts flag",
          "nevis flag",
          "flag st kitts nevis",
          "saint",
          "kitts",
          "nevis",
          "flag",
          "nation",
          "country",
          "banner",
          "st kitts nevis",
          "flag kn",
          "st. kitts & nevis flag",
        ],
        name: "st. kitts & nevis flag",
        order: 134,
        unified: "1F1F0-1F1F3",
        emoji: "🇰🇳",
      },
      {
        keywords: [
          "north korea",
          "north korean flag",
          "flag north korea",
          "north",
          "korea",
          "nation",
          "flag",
          "country",
          "banner",
          "flag kp",
          "north korea flag",
        ],
        name: "north korea flag",
        order: 135,
        unified: "1F1F0-1F1F5",
        emoji: "🇰🇵",
      },
      {
        keywords: [
          "south korea",
          "south korean flag",
          "flag south korea",
          "south",
          "korea",
          "nation",
          "flag",
          "country",
          "banner",
          "kr",
          "flag kr",
          "south korea flag",
        ],
        name: "south korea flag",
        order: 136,
        unified: "1F1F0-1F1F7",
        emoji: "🇰🇷",
      },
      {
        keywords: [
          "kuwait",
          "kuwaiti flag",
          "flag kuwait",
          "kw",
          "flag",
          "nation",
          "country",
          "banner",
          "flag kw",
          "kuwait flag",
        ],
        name: "kuwait flag",
        order: 137,
        unified: "1F1F0-1F1FC",
        emoji: "🇰🇼",
      },
      {
        keywords: [
          "cayman islands",
          "cayman island flag",
          "flag cayman islands",
          "cayman",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ky",
          "cayman islands flag",
        ],
        name: "cayman islands flag",
        order: 138,
        unified: "1F1F0-1F1FE",
        emoji: "🇰🇾",
      },
      {
        keywords: [
          "kazakhstan",
          "kazakh flag",
          "flag kazakhstan",
          "kz",
          "flag",
          "nation",
          "country",
          "banner",
          "flag kz",
          "kazakhstan flag",
        ],
        name: "kazakhstan flag",
        order: 139,
        unified: "1F1F0-1F1FF",
        emoji: "🇰🇿",
      },
      {
        keywords: [
          "laos",
          "laotian flag",
          "flag laos",
          "lao",
          "democratic",
          "republic",
          "flag",
          "nation",
          "country",
          "banner",
          "flag la",
          "laos flag",
        ],
        name: "laos flag",
        order: 140,
        unified: "1F1F1-1F1E6",
        emoji: "🇱🇦",
      },
      {
        keywords: [
          "lebanon",
          "lebanese flag",
          "flag lebanon",
          "lb",
          "flag",
          "nation",
          "country",
          "banner",
          "flag lb",
          "lebanon flag",
        ],
        name: "lebanon flag",
        order: 141,
        unified: "1F1F1-1F1E7",
        emoji: "🇱🇧",
      },
      {
        keywords: [
          "saint lucia",
          "saint lucian flag",
          "flag st lucia",
          "saint",
          "lucia",
          "flag",
          "nation",
          "country",
          "banner",
          "st lucia",
          "flag lc",
          "st. lucia flag",
        ],
        name: "st. lucia flag",
        order: 142,
        unified: "1F1F1-1F1E8",
        emoji: "🇱🇨",
      },
      {
        keywords: [
          "liechtenstein",
          "liechtenstein flag",
          "flag liechtenstein",
          "li",
          "flag",
          "nation",
          "country",
          "banner",
          "flag li",
        ],
        name: "liechtenstein flag",
        order: 143,
        unified: "1F1F1-1F1EE",
        emoji: "🇱🇮",
      },
      {
        keywords: [
          "sri lanka",
          "sri lankan flag",
          "flag sri lanka",
          "sri",
          "lanka",
          "flag",
          "nation",
          "country",
          "banner",
          "flag lk",
          "sri lanka flag",
        ],
        name: "sri lanka flag",
        order: 144,
        unified: "1F1F1-1F1F0",
        emoji: "🇱🇰",
      },
      {
        keywords: [
          "liberia",
          "liberian flag",
          "flag liberia",
          "lr",
          "flag",
          "nation",
          "country",
          "banner",
          "flag lr",
          "liberia flag",
        ],
        name: "liberia flag",
        order: 145,
        unified: "1F1F1-1F1F7",
        emoji: "🇱🇷",
      },
      {
        keywords: [
          "lesotho",
          "basotho flag",
          "flag lesotho",
          "ls",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ls",
          "lesotho flag",
        ],
        name: "lesotho flag",
        order: 146,
        unified: "1F1F1-1F1F8",
        emoji: "🇱🇸",
      },
      {
        keywords: [
          "lithuania",
          "lithuanian flag",
          "flag lithuania",
          "lt",
          "flag",
          "nation",
          "country",
          "banner",
          "flag lt",
          "lithuania flag",
        ],
        name: "lithuania flag",
        order: 147,
        unified: "1F1F1-1F1F9",
        emoji: "🇱🇹",
      },
      {
        keywords: [
          "luxembourg",
          "luxembourg flag",
          "luxembourgish flag",
          "flag luxembourg",
          "lu",
          "flag",
          "nation",
          "country",
          "banner",
          "flag lu",
        ],
        name: "luxembourg flag",
        order: 148,
        unified: "1F1F1-1F1FA",
        emoji: "🇱🇺",
      },
      {
        keywords: [
          "latvia",
          "latvian flag",
          "flag latvia",
          "lv",
          "flag",
          "nation",
          "country",
          "banner",
          "flag lv",
          "latvia flag",
        ],
        name: "latvia flag",
        order: 149,
        unified: "1F1F1-1F1FB",
        emoji: "🇱🇻",
      },
      {
        keywords: [
          "libya",
          "libyan flag",
          "flag libya",
          "ly",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ly",
          "libya flag",
        ],
        name: "libya flag",
        order: 150,
        unified: "1F1F1-1F1FE",
        emoji: "🇱🇾",
      },
      {
        keywords: [
          "morocco",
          "moroccan flag",
          "flag morocco",
          "ma",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ma",
          "morocco flag",
        ],
        name: "morocco flag",
        order: 151,
        unified: "1F1F2-1F1E6",
        emoji: "🇲🇦",
      },
      {
        keywords: [
          "monaco",
          "monégasque flag",
          "monacan flag",
          "flag monaco",
          "mc",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mc",
          "monaco flag",
        ],
        name: "monaco flag",
        order: 152,
        unified: "1F1F2-1F1E8",
        emoji: "🇲🇨",
      },
      {
        keywords: [
          "moldova",
          "moldovan flag",
          "flag moldova",
          "republic",
          "flag",
          "nation",
          "country",
          "banner",
          "flag md",
          "moldova flag",
        ],
        name: "moldova flag",
        order: 153,
        unified: "1F1F2-1F1E9",
        emoji: "🇲🇩",
      },
      {
        keywords: [
          "montenegro",
          "montenegrin flag",
          "flag montenegro",
          "me",
          "flag",
          "nation",
          "country",
          "banner",
          "flag me",
          "montenegro flag",
        ],
        name: "montenegro flag",
        order: 154,
        unified: "1F1F2-1F1EA",
        emoji: "🇲🇪",
      },
      {
        keywords: ["flag st martin", "flag mf", "st. martin flag"],
        name: "st. martin flag",
        order: 155,
        unified: "1F1F2-1F1EB",
        emoji: "🇲🇫",
      },
      {
        keywords: [
          "madagascar",
          "malagasy flag",
          "flag madagascar",
          "mg",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mg",
          "madagascar flag",
        ],
        name: "madagascar flag",
        order: 156,
        unified: "1F1F2-1F1EC",
        emoji: "🇲🇬",
      },
      {
        keywords: [
          "marshall islands",
          "marshall island flag",
          "flag marshall islands",
          "marshall",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mh",
          "marshall islands flag",
        ],
        name: "marshall islands flag",
        order: 157,
        unified: "1F1F2-1F1ED",
        emoji: "🇲🇭",
      },
      {
        keywords: [
          "macedonia",
          "macedonian flag",
          "flag north macedonia",
          "flag",
          "nation",
          "country",
          "banner",
          "north macedonia",
          "flag mk",
          "north macedonia flag",
        ],
        name: "north macedonia flag",
        order: 158,
        unified: "1F1F2-1F1F0",
        emoji: "🇲🇰",
      },
      {
        keywords: [
          "mali",
          "malian flag",
          "flag mali",
          "ml",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ml",
          "mali flag",
        ],
        name: "mali flag",
        order: 159,
        unified: "1F1F2-1F1F1",
        emoji: "🇲🇱",
      },
      {
        keywords: [
          "myanmar",
          "burma",
          "burmese flag",
          "flag myanmar",
          "mm",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mm",
          "myanmar (burma) flag",
        ],
        name: "myanmar (burma) flag",
        order: 160,
        unified: "1F1F2-1F1F2",
        emoji: "🇲🇲",
      },
      {
        keywords: [
          "mongolia",
          "mongolian flag",
          "flag mongolia",
          "mn",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mn",
          "mongolia flag",
        ],
        name: "mongolia flag",
        order: 161,
        unified: "1F1F2-1F1F3",
        emoji: "🇲🇳",
      },
      {
        keywords: [
          "macau",
          "macanese flag",
          "flag macao sar china",
          "macao",
          "flag",
          "nation",
          "country",
          "banner",
          "macao sar china",
          "flag mo",
          "macao sar china flag",
        ],
        name: "macao sar china flag",
        order: 162,
        unified: "1F1F2-1F1F4",
        emoji: "🇲🇴",
      },
      {
        keywords: [
          "northern mariana islands",
          "northern marianan flag",
          "flag northern mariana islands",
          "northern",
          "mariana",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mp",
          "northern mariana islands flag",
        ],
        name: "northern mariana islands flag",
        order: 163,
        unified: "1F1F2-1F1F5",
        emoji: "🇲🇵",
      },
      {
        keywords: [
          "martinique",
          "martiniquais flag",
          "flag martinique",
          "mq",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mq",
          "martinique flag",
        ],
        name: "martinique flag",
        order: 164,
        unified: "1F1F2-1F1F6",
        emoji: "🇲🇶",
      },
      {
        keywords: [
          "mauritania",
          "mauritanian flag",
          "flag mauritania",
          "mr",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mr",
          "mauritania flag",
        ],
        name: "mauritania flag",
        order: 165,
        unified: "1F1F2-1F1F7",
        emoji: "🇲🇷",
      },
      {
        keywords: [
          "montserrat",
          "montserratan flag",
          "flag montserrat",
          "ms",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ms",
          "montserrat flag",
        ],
        name: "montserrat flag",
        order: 166,
        unified: "1F1F2-1F1F8",
        emoji: "🇲🇸",
      },
      {
        keywords: [
          "malta",
          "maltese flag",
          "flag malta",
          "mt",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mt",
          "malta flag",
        ],
        name: "malta flag",
        order: 167,
        unified: "1F1F2-1F1F9",
        emoji: "🇲🇹",
      },
      {
        keywords: [
          "mauritius",
          "mauritian flag",
          "flag mauritius",
          "mu",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mu",
          "mauritius flag",
        ],
        name: "mauritius flag",
        order: 168,
        unified: "1F1F2-1F1FA",
        emoji: "🇲🇺",
      },
      {
        keywords: [
          "maldives",
          "maldivian flag",
          "flag maldives",
          "mv",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mv",
          "maldives flag",
        ],
        name: "maldives flag",
        order: 169,
        unified: "1F1F2-1F1FB",
        emoji: "🇲🇻",
      },
      {
        keywords: [
          "malawi",
          "malawian flag",
          "flag malawi",
          "mw",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mw",
          "malawi flag",
        ],
        name: "malawi flag",
        order: 170,
        unified: "1F1F2-1F1FC",
        emoji: "🇲🇼",
      },
      {
        keywords: [
          "mexico",
          "mexican flag",
          "flag mexico",
          "mx",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mx",
          "mexico flag",
        ],
        name: "mexico flag",
        order: 171,
        unified: "1F1F2-1F1FD",
        emoji: "🇲🇽",
      },
      {
        keywords: [
          "malaysia",
          "malaysian flag",
          "flag malaysia",
          "my",
          "flag",
          "nation",
          "country",
          "banner",
          "flag my",
          "malaysia flag",
        ],
        name: "malaysia flag",
        order: 172,
        unified: "1F1F2-1F1FE",
        emoji: "🇲🇾",
      },
      {
        keywords: [
          "mozambique",
          "mozambican flag",
          "flag mozambique",
          "mz",
          "flag",
          "nation",
          "country",
          "banner",
          "flag mz",
          "mozambique flag",
        ],
        name: "mozambique flag",
        order: 173,
        unified: "1F1F2-1F1FF",
        emoji: "🇲🇿",
      },
      {
        keywords: [
          "namibia",
          "namibian flag",
          "flag namibia",
          "na",
          "flag",
          "nation",
          "country",
          "banner",
          "flag na",
          "namibia flag",
        ],
        name: "namibia flag",
        order: 174,
        unified: "1F1F3-1F1E6",
        emoji: "🇳🇦",
      },
      {
        keywords: [
          "new caledonia",
          "new caledonian flag",
          "flag new caledonia",
          "new",
          "caledonia",
          "flag",
          "nation",
          "country",
          "banner",
          "flag nc",
          "new caledonia flag",
        ],
        name: "new caledonia flag",
        order: 175,
        unified: "1F1F3-1F1E8",
        emoji: "🇳🇨",
      },
      {
        keywords: [
          "niger",
          "nigerien flag",
          "flag niger",
          "ne",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ne",
          "niger flag",
        ],
        name: "niger flag",
        order: 176,
        unified: "1F1F3-1F1EA",
        emoji: "🇳🇪",
      },
      {
        keywords: [
          "norfolk island",
          "norfolk island flag",
          "flag norfolk island",
          "norfolk",
          "island",
          "flag",
          "nation",
          "country",
          "banner",
          "flag nf",
        ],
        name: "norfolk island flag",
        order: 177,
        unified: "1F1F3-1F1EB",
        emoji: "🇳🇫",
      },
      {
        keywords: [
          "nigeria",
          "nigerian flag",
          "flag nigeria",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ng",
          "nigeria flag",
        ],
        name: "nigeria flag",
        order: 178,
        unified: "1F1F3-1F1EC",
        emoji: "🇳🇬",
      },
      {
        keywords: [
          "nicaragua",
          "nicaraguan flag",
          "flag nicaragua",
          "ni",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ni",
          "nicaragua flag",
        ],
        name: "nicaragua flag",
        order: 179,
        unified: "1F1F3-1F1EE",
        emoji: "🇳🇮",
      },
      {
        keywords: [
          "netherlands",
          "caribbean netherlands",
          "dutch flag",
          "flag netherlands",
          "nl",
          "flag",
          "nation",
          "country",
          "banner",
          "flag nl",
          "netherlands flag",
        ],
        name: "netherlands flag",
        order: 180,
        unified: "1F1F3-1F1F1",
        emoji: "🇳🇱",
      },
      {
        keywords: [
          "norway",
          "norwegian flag",
          "flag norway",
          "no",
          "flag",
          "nation",
          "country",
          "banner",
          "flag no",
          "norway flag",
        ],
        name: "norway flag",
        order: 181,
        unified: "1F1F3-1F1F4",
        emoji: "🇳🇴",
      },
      {
        keywords: [
          "nepal",
          "nepalese flag",
          "flag nepal",
          "np",
          "flag",
          "nation",
          "country",
          "banner",
          "flag np",
          "nepal flag",
        ],
        name: "nepal flag",
        order: 182,
        unified: "1F1F3-1F1F5",
        emoji: "🇳🇵",
      },
      {
        keywords: [
          "nauru",
          "nauruan flag",
          "flag nauru",
          "nr",
          "flag",
          "nation",
          "country",
          "banner",
          "flag nr",
          "nauru flag",
        ],
        name: "nauru flag",
        order: 183,
        unified: "1F1F3-1F1F7",
        emoji: "🇳🇷",
      },
      {
        keywords: [
          "niue",
          "niuean flag",
          "flag niue",
          "nu",
          "flag",
          "nation",
          "country",
          "banner",
          "flag nu",
          "niue flag",
        ],
        name: "niue flag",
        order: 184,
        unified: "1F1F3-1F1FA",
        emoji: "🇳🇺",
      },
      {
        keywords: [
          "new zealand",
          "new zealand flag",
          "flag new zealand",
          "new",
          "zealand",
          "flag",
          "nation",
          "country",
          "banner",
          "flag nz",
        ],
        name: "new zealand flag",
        order: 185,
        unified: "1F1F3-1F1FF",
        emoji: "🇳🇿",
      },
      {
        keywords: [
          "oman",
          "omani flag",
          "flag oman",
          "om symbol",
          "flag",
          "nation",
          "country",
          "banner",
          "flag om",
          "oman flag",
        ],
        name: "oman flag",
        order: 186,
        unified: "1F1F4-1F1F2",
        emoji: "🇴🇲",
      },
      {
        keywords: [
          "panama",
          "panamanian flag",
          "flag panama",
          "pa",
          "flag",
          "nation",
          "country",
          "banner",
          "flag pa",
          "panama flag",
        ],
        name: "panama flag",
        order: 187,
        unified: "1F1F5-1F1E6",
        emoji: "🇵🇦",
      },
      {
        keywords: [
          "peru",
          "peruvian flag",
          "flag peru",
          "pe",
          "flag",
          "nation",
          "country",
          "banner",
          "flag pe",
          "peru flag",
        ],
        name: "peru flag",
        order: 188,
        unified: "1F1F5-1F1EA",
        emoji: "🇵🇪",
      },
      {
        keywords: [
          "french polynesia",
          "french polynesian flag",
          "flag french polynesia",
          "french",
          "polynesia",
          "flag",
          "nation",
          "country",
          "banner",
          "flag pf",
          "french polynesia flag",
        ],
        name: "french polynesia flag",
        order: 189,
        unified: "1F1F5-1F1EB",
        emoji: "🇵🇫",
      },
      {
        keywords: [
          "papua new guinea",
          "papua new guinean flag",
          "papuan flag",
          "flag papua new guinea",
          "papua",
          "new",
          "guinea",
          "flag",
          "nation",
          "country",
          "banner",
          "flag pg",
          "papua new guinea flag",
        ],
        name: "papua new guinea flag",
        order: 190,
        unified: "1F1F5-1F1EC",
        emoji: "🇵🇬",
      },
      {
        keywords: [
          "philippines",
          "filipino flag",
          "philippine flag",
          "flag philippines",
          "ph",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ph",
          "philippines flag",
        ],
        name: "philippines flag",
        order: 191,
        unified: "1F1F5-1F1ED",
        emoji: "🇵🇭",
      },
      {
        keywords: [
          "pakistan",
          "pakistani flag",
          "flag pakistan",
          "pk",
          "flag",
          "nation",
          "country",
          "banner",
          "flag pk",
          "pakistan flag",
        ],
        name: "pakistan flag",
        order: 192,
        unified: "1F1F5-1F1F0",
        emoji: "🇵🇰",
      },
      {
        keywords: [
          "poland",
          "polish flag",
          "flag poland",
          "pl",
          "flag",
          "nation",
          "country",
          "banner",
          "flag pl",
          "poland flag",
        ],
        name: "poland flag",
        order: 193,
        unified: "1F1F5-1F1F1",
        emoji: "🇵🇱",
      },
      {
        keywords: [
          "saint pierre & miquelon",
          "saint pierre and miquelon",
          "saint pierrais flag",
          "miquelonnais flag",
          "flag st pierre miquelon",
          "saint",
          "pierre",
          "miquelon",
          "flag",
          "nation",
          "country",
          "banner",
          "st pierre miquelon",
          "flag pm",
          "st. pierre & miquelon flag",
        ],
        name: "st. pierre & miquelon flag",
        order: 194,
        unified: "1F1F5-1F1F2",
        emoji: "🇵🇲",
      },
      {
        keywords: [
          "pitcairn",
          "pitcairn flag",
          "flag pitcairn islands",
          "flag",
          "nation",
          "country",
          "banner",
          "pitcairn islands",
          "flag pn",
          "pitcairn islands flag",
        ],
        name: "pitcairn islands flag",
        order: 195,
        unified: "1F1F5-1F1F3",
        emoji: "🇵🇳",
      },
      {
        keywords: [
          "puerto rico",
          "puerto rican flag",
          "flag puerto rico",
          "puerto",
          "rico",
          "flag",
          "nation",
          "country",
          "banner",
          "flag pr",
          "puerto rico flag",
        ],
        name: "puerto rico flag",
        order: 196,
        unified: "1F1F5-1F1F7",
        emoji: "🇵🇷",
      },
      {
        keywords: [
          "palestinian territories",
          "palestinian flag",
          "palestine",
          "state of palestine",
          "flag palestinian territories",
          "palestinian",
          "territories",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ps",
          "palestinian territories flag",
        ],
        name: "palestinian territories flag",
        order: 197,
        unified: "1F1F5-1F1F8",
        emoji: "🇵🇸",
      },
      {
        keywords: [
          "portugal",
          "portuguese flag",
          "flag portugal",
          "pt",
          "flag",
          "nation",
          "country",
          "banner",
          "flag pt",
          "portugal flag",
        ],
        name: "portugal flag",
        order: 198,
        unified: "1F1F5-1F1F9",
        emoji: "🇵🇹",
      },
      {
        keywords: [
          "palau",
          "palauan flag",
          "flag palau",
          "pw",
          "flag",
          "nation",
          "country",
          "banner",
          "flag pw",
          "palau flag",
        ],
        name: "palau flag",
        order: 199,
        unified: "1F1F5-1F1FC",
        emoji: "🇵🇼",
      },
      {
        keywords: [
          "paraguay",
          "paraguayan flag",
          "flag paraguay",
          "py",
          "flag",
          "nation",
          "country",
          "banner",
          "flag py",
          "paraguay flag",
        ],
        name: "paraguay flag",
        order: 200,
        unified: "1F1F5-1F1FE",
        emoji: "🇵🇾",
      },
      {
        keywords: [
          "qatar",
          "qatari flag",
          "flag qatar",
          "qa",
          "flag",
          "nation",
          "country",
          "banner",
          "flag qa",
          "qatar flag",
        ],
        name: "qatar flag",
        order: 201,
        unified: "1F1F6-1F1E6",
        emoji: "🇶🇦",
      },
      {
        keywords: [
          "réunion",
          "réunionese flag",
          "réunionnais flag",
          "flag reunion",
          "flag",
          "nation",
          "country",
          "banner",
          "reunion",
          "flag re",
          "rã©union flag",
        ],
        name: "rã©union flag",
        order: 202,
        unified: "1F1F7-1F1EA",
        emoji: "🇷🇪",
      },
      {
        keywords: [
          "romania",
          "romanian flag",
          "flag romania",
          "ro",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ro",
          "romania flag",
        ],
        name: "romania flag",
        order: 203,
        unified: "1F1F7-1F1F4",
        emoji: "🇷🇴",
      },
      {
        keywords: [
          "serbia",
          "serbian flag",
          "flag serbia",
          "rs",
          "flag",
          "nation",
          "country",
          "banner",
          "flag rs",
          "serbia flag",
        ],
        name: "serbia flag",
        order: 204,
        unified: "1F1F7-1F1F8",
        emoji: "🇷🇸",
      },
      {
        keywords: [
          "russia",
          "russian flag",
          "flag russia",
          "russian",
          "federation",
          "flag",
          "nation",
          "country",
          "banner",
          "ru",
          "flag ru",
          "russia flag",
        ],
        name: "russia flag",
        order: 205,
        unified: "1F1F7-1F1FA",
        emoji: "🇷🇺",
      },
      {
        keywords: [
          "rwanda",
          "rwandan flag",
          "flag rwanda",
          "rw",
          "flag",
          "nation",
          "country",
          "banner",
          "flag rw",
          "rwanda flag",
        ],
        name: "rwanda flag",
        order: 206,
        unified: "1F1F7-1F1FC",
        emoji: "🇷🇼",
      },
      {
        keywords: [
          "saudi arabia",
          "saudi arabian flag",
          "saudi flag",
          "flag saudi arabia",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sa",
          "saudi arabia flag",
        ],
        name: "saudi arabia flag",
        order: 207,
        unified: "1F1F8-1F1E6",
        emoji: "🇸🇦",
      },
      {
        keywords: [
          "solomon islands",
          "solomon island flag",
          "flag solomon islands",
          "solomon",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sb",
          "solomon islands flag",
        ],
        name: "solomon islands flag",
        order: 208,
        unified: "1F1F8-1F1E7",
        emoji: "🇸🇧",
      },
      {
        keywords: [
          "seychelles",
          "seychellois flag",
          "flag seychelles",
          "sc",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sc",
          "seychelles flag",
        ],
        name: "seychelles flag",
        order: 209,
        unified: "1F1F8-1F1E8",
        emoji: "🇸🇨",
      },
      {
        keywords: [
          "sudan",
          "sudanese flag",
          "flag sudan",
          "sd",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sd",
          "sudan flag",
        ],
        name: "sudan flag",
        order: 210,
        unified: "1F1F8-1F1E9",
        emoji: "🇸🇩",
      },
      {
        keywords: [
          "sweden",
          "swedish flag",
          "flag sweden",
          "se",
          "flag",
          "nation",
          "country",
          "banner",
          "flag se",
          "sweden flag",
        ],
        name: "sweden flag",
        order: 211,
        unified: "1F1F8-1F1EA",
        emoji: "🇸🇪",
      },
      {
        keywords: [
          "singapore",
          "singaporean flag",
          "flag singapore",
          "sg",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sg",
          "singapore flag",
        ],
        name: "singapore flag",
        order: 212,
        unified: "1F1F8-1F1EC",
        emoji: "🇸🇬",
      },
      {
        keywords: [
          "saint helena",
          "saint helenian flag",
          "flag st helena",
          "saint",
          "helena",
          "ascension",
          "tristan",
          "cunha",
          "flag",
          "nation",
          "country",
          "banner",
          "st helena",
          "flag sh",
          "st. helena flag",
        ],
        name: "st. helena flag",
        order: 213,
        unified: "1F1F8-1F1ED",
        emoji: "🇸🇭",
      },
      {
        keywords: [
          "slovenia",
          "slovene flag",
          "slovenian flag",
          "flag slovenia",
          "si",
          "flag",
          "nation",
          "country",
          "banner",
          "flag si",
          "slovenia flag",
        ],
        name: "slovenia flag",
        order: 214,
        unified: "1F1F8-1F1EE",
        emoji: "🇸🇮",
      },
      {
        keywords: [
          "flag svalbard jan mayen",
          "flag sj",
          "svalbard & jan mayen flag",
        ],
        name: "svalbard & jan mayen flag",
        order: 215,
        unified: "1F1F8-1F1EF",
        emoji: "🇸🇯",
      },
      {
        keywords: [
          "slovakia",
          "slovak flag",
          "flag slovakia",
          "sk",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sk",
          "slovakia flag",
        ],
        name: "slovakia flag",
        order: 216,
        unified: "1F1F8-1F1F0",
        emoji: "🇸🇰",
      },
      {
        keywords: [
          "sierra leone",
          "sierra leonian flag",
          "flag sierra leone",
          "sierra",
          "leone",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sl",
          "sierra leone flag",
        ],
        name: "sierra leone flag",
        order: 217,
        unified: "1F1F8-1F1F1",
        emoji: "🇸🇱",
      },
      {
        keywords: [
          "san marino",
          "sammarinese flag",
          "flag san marino",
          "san",
          "marino",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sm",
          "san marino flag",
        ],
        name: "san marino flag",
        order: 218,
        unified: "1F1F8-1F1F2",
        emoji: "🇸🇲",
      },
      {
        keywords: [
          "senegal",
          "senegalese flag",
          "flag senegal",
          "sn",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sn",
          "senegal flag",
        ],
        name: "senegal flag",
        order: 219,
        unified: "1F1F8-1F1F3",
        emoji: "🇸🇳",
      },
      {
        keywords: [
          "somalia",
          "somali flag",
          "flag somalia",
          "so",
          "flag",
          "nation",
          "country",
          "banner",
          "flag so",
          "somalia flag",
        ],
        name: "somalia flag",
        order: 220,
        unified: "1F1F8-1F1F4",
        emoji: "🇸🇴",
      },
      {
        keywords: [
          "suriname",
          "surinamese flag",
          "flag suriname",
          "sr",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sr",
          "suriname flag",
        ],
        name: "suriname flag",
        order: 221,
        unified: "1F1F8-1F1F7",
        emoji: "🇸🇷",
      },
      {
        keywords: [
          "south sudan",
          "south sudanese flag",
          "flag south sudan",
          "south",
          "sd",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ss",
          "south sudan flag",
        ],
        name: "south sudan flag",
        order: 222,
        unified: "1F1F8-1F1F8",
        emoji: "🇸🇸",
      },
      {
        keywords: [
          "são tomé & príncipe",
          "são tomé and príncipe",
          "sao tome & principe",
          "sao tome and principe",
          "são toméan flag",
          "flag sao tome principe",
          "sao",
          "tome",
          "principe",
          "flag",
          "nation",
          "country",
          "banner",
          "sao tome principe",
          "flag st",
          "sã£o tomã© & prã­ncipe flag",
        ],
        name: "sã£o tomã© & prã­ncipe flag",
        order: 223,
        unified: "1F1F8-1F1F9",
        emoji: "🇸🇹",
      },
      {
        keywords: [
          "el salvador",
          "salvadorean flag",
          "flag el salvador",
          "el",
          "salvador",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sv",
          "el salvador flag",
        ],
        name: "el salvador flag",
        order: 224,
        unified: "1F1F8-1F1FB",
        emoji: "🇸🇻",
      },
      {
        keywords: [
          "sint maarten",
          "sint maarten flag",
          "flag sint maarten",
          "sint",
          "maarten",
          "dutch",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sx",
        ],
        name: "sint maarten flag",
        order: 225,
        unified: "1F1F8-1F1FD",
        emoji: "🇸🇽",
      },
      {
        keywords: [
          "syria",
          "syrian flag",
          "flag syria",
          "syrian",
          "arab",
          "republic",
          "flag",
          "nation",
          "country",
          "banner",
          "flag sy",
          "syria flag",
        ],
        name: "syria flag",
        order: 226,
        unified: "1F1F8-1F1FE",
        emoji: "🇸🇾",
      },
      {
        keywords: [
          "swaziland",
          "swazi flag",
          "flag eswatini",
          "sz",
          "flag",
          "nation",
          "country",
          "banner",
          "eswatini",
          "flag sz",
          "eswatini flag",
        ],
        name: "eswatini flag",
        order: 227,
        unified: "1F1F8-1F1FF",
        emoji: "🇸🇿",
      },
      {
        keywords: ["flag tristan da cunha", "flag ta", "tristan da cunha flag"],
        name: "tristan da cunha flag",
        order: 228,
        unified: "1F1F9-1F1E6",
        emoji: "🇹🇦",
      },
      {
        keywords: [
          "turks & caicos islands",
          "turks and caicos islands",
          "turks and caicos",
          "turks and caicos island flag",
          "flag turks caicos islands",
          "turks",
          "caicos",
          "islands",
          "flag",
          "nation",
          "country",
          "banner",
          "turks caicos islands",
          "flag tc",
          "turks & caicos islands flag",
        ],
        name: "turks & caicos islands flag",
        order: 229,
        unified: "1F1F9-1F1E8",
        emoji: "🇹🇨",
      },
      {
        keywords: [
          "chad",
          "chadian flag",
          "flag chad",
          "td",
          "flag",
          "nation",
          "country",
          "banner",
          "flag td",
          "chad flag",
        ],
        name: "chad flag",
        order: 230,
        unified: "1F1F9-1F1E9",
        emoji: "🇹🇩",
      },
      {
        keywords: [
          "french southern territories",
          "french southern territories flag",
          "flag french southern territories",
          "french",
          "southern",
          "territories",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tf",
        ],
        name: "french southern territories flag",
        order: 231,
        unified: "1F1F9-1F1EB",
        emoji: "🇹🇫",
      },
      {
        keywords: [
          "togo",
          "togolese flag",
          "flag togo",
          "tg",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tg",
          "togo flag",
        ],
        name: "togo flag",
        order: 232,
        unified: "1F1F9-1F1EC",
        emoji: "🇹🇬",
      },
      {
        keywords: [
          "thailand",
          "thai flag",
          "flag thailand",
          "th",
          "flag",
          "nation",
          "country",
          "banner",
          "flag th",
          "thailand flag",
        ],
        name: "thailand flag",
        order: 233,
        unified: "1F1F9-1F1ED",
        emoji: "🇹🇭",
      },
      {
        keywords: [
          "tajikistan",
          "tajik flag",
          "tadjik flag",
          "flag tajikistan",
          "tj",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tj",
          "tajikistan flag",
        ],
        name: "tajikistan flag",
        order: 234,
        unified: "1F1F9-1F1EF",
        emoji: "🇹🇯",
      },
      {
        keywords: [
          "tokelau",
          "tokelauan flag",
          "flag tokelau",
          "tk",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tk",
          "tokelau flag",
        ],
        name: "tokelau flag",
        order: 235,
        unified: "1F1F9-1F1F0",
        emoji: "🇹🇰",
      },
      {
        keywords: [
          "timor leste",
          "timorese flag",
          "flag timor leste",
          "timor",
          "leste",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tl",
          "timor leste flag",
        ],
        name: "timor-leste flag",
        order: 236,
        unified: "1F1F9-1F1F1",
        emoji: "🇹🇱",
      },
      {
        keywords: [
          "turkmenistan",
          "turkmen flag",
          "turkoman flag",
          "flag turkmenistan",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tm",
          "turkmenistan flag",
        ],
        name: "turkmenistan flag",
        order: 237,
        unified: "1F1F9-1F1F2",
        emoji: "🇹🇲",
      },
      {
        keywords: [
          "tunisia",
          "tunisian flag",
          "flag tunisia",
          "tn",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tn",
          "tunisia flag",
        ],
        name: "tunisia flag",
        order: 238,
        unified: "1F1F9-1F1F3",
        emoji: "🇹🇳",
      },
      {
        keywords: [
          "tonga",
          "tongan flag",
          "flag tonga",
          "to",
          "flag",
          "nation",
          "country",
          "banner",
          "flag to",
          "tonga flag",
        ],
        name: "tonga flag",
        order: 239,
        unified: "1F1F9-1F1F4",
        emoji: "🇹🇴",
      },
      {
        keywords: [
          "turkey",
          "turkish flag",
          "flag turkey",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tr",
          "turkey flag",
        ],
        name: "turkey flag",
        order: 240,
        unified: "1F1F9-1F1F7",
        emoji: "🇹🇷",
      },
      {
        keywords: [
          "trinidad & tobago",
          "trinidad and tobago",
          "trinidad",
          "tobago",
          "trinidadian flag",
          "tobagonian flag",
          "flag trinidad tobago",
          "flag",
          "nation",
          "country",
          "banner",
          "trinidad tobago",
          "flag tt",
          "trinidad & tobago flag",
        ],
        name: "trinidad & tobago flag",
        order: 241,
        unified: "1F1F9-1F1F9",
        emoji: "🇹🇹",
      },
      {
        keywords: [
          "tuvalu",
          "tuvaluan flag",
          "flag tuvalu",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tv",
          "tuvalu flag",
        ],
        name: "tuvalu flag",
        order: 242,
        unified: "1F1F9-1F1FB",
        emoji: "🇹🇻",
      },
      {
        keywords: [
          "taiwan",
          "taiwanese flag",
          "republic of china",
          "flag taiwan",
          "tw",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tw",
          "taiwan flag",
        ],
        name: "taiwan flag",
        order: 243,
        unified: "1F1F9-1F1FC",
        emoji: "🇹🇼",
      },
      {
        keywords: [
          "tanzania",
          "tanzanian flag",
          "flag tanzania",
          "united",
          "republic",
          "flag",
          "nation",
          "country",
          "banner",
          "flag tz",
          "tanzania flag",
        ],
        name: "tanzania flag",
        order: 244,
        unified: "1F1F9-1F1FF",
        emoji: "🇹🇿",
      },
      {
        keywords: [
          "ukraine",
          "ukrainian flag",
          "flag ukraine",
          "ua",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ua",
          "ukraine flag",
        ],
        name: "ukraine flag",
        order: 245,
        unified: "1F1FA-1F1E6",
        emoji: "🇺🇦",
      },
      {
        keywords: [
          "uganda",
          "ugandan flag",
          "flag uganda",
          "ug",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ug",
          "uganda flag",
        ],
        name: "uganda flag",
        order: 246,
        unified: "1F1FA-1F1EC",
        emoji: "🇺🇬",
      },
      {
        keywords: [
          "flag u s outlying islands",
          "flag um",
          "u.s. outlying islands flag",
        ],
        name: "u.s. outlying islands flag",
        order: 247,
        unified: "1F1FA-1F1F2",
        emoji: "🇺🇲",
      },
      {
        keywords: [
          "flag united nations",
          "un",
          "flag",
          "banner",
          "flag un",
          "united nations flag",
        ],
        name: "united nations flag",
        order: 248,
        unified: "1F1FA-1F1F3",
        emoji: "🇺🇳",
      },
      {
        keywords: [
          "united states",
          "usa",
          "american flag",
          "united states of america",
          "us flag",
          "flag united states",
          "united",
          "states",
          "america",
          "flag",
          "nation",
          "country",
          "banner",
          "us",
          "flag us",
          "united states flag",
        ],
        name: "united states flag",
        order: 249,
        unified: "1F1FA-1F1F8",
        emoji: "🇺🇸",
      },
      {
        keywords: [
          "uruguay",
          "uruguayan flag",
          "flag uruguay",
          "uy",
          "flag",
          "nation",
          "country",
          "banner",
          "flag uy",
          "uruguay flag",
        ],
        name: "uruguay flag",
        order: 250,
        unified: "1F1FA-1F1FE",
        emoji: "🇺🇾",
      },
      {
        keywords: [
          "uzbekistan",
          "uzbek flag",
          "flag uzbekistan",
          "uz",
          "flag",
          "nation",
          "country",
          "banner",
          "flag uz",
          "uzbekistan flag",
        ],
        name: "uzbekistan flag",
        order: 251,
        unified: "1F1FA-1F1FF",
        emoji: "🇺🇿",
      },
      {
        keywords: [
          "holy see",
          "vatican",
          "vatican flag",
          "flag vatican city",
          "city",
          "flag",
          "nation",
          "country",
          "banner",
          "vatican city",
          "flag va",
          "vatican city flag",
        ],
        name: "vatican city flag",
        order: 252,
        unified: "1F1FB-1F1E6",
        emoji: "🇻🇦",
      },
      {
        keywords: [
          "saint vincent & grenadines",
          "saint vincent and grenadines",
          "saint vincentian flag",
          "vincentian flag",
          "grenadine flag",
          "flag st vincent grenadines",
          "saint",
          "vincent",
          "grenadines",
          "flag",
          "nation",
          "country",
          "banner",
          "st vincent grenadines",
          "flag vc",
          "st. vincent & grenadines flag",
        ],
        name: "st. vincent & grenadines flag",
        order: 253,
        unified: "1F1FB-1F1E8",
        emoji: "🇻🇨",
      },
      {
        keywords: [
          "venezuela",
          "venezuelan flag",
          "flag venezuela",
          "ve",
          "bolivarian",
          "republic",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ve",
          "venezuela flag",
        ],
        name: "venezuela flag",
        order: 254,
        unified: "1F1FB-1F1EA",
        emoji: "🇻🇪",
      },
      {
        keywords: [
          "british virgin islands",
          "virgin islands",
          "british virgin islands flag",
          "flag british virgin islands",
          "british",
          "virgin",
          "islands",
          "bvi",
          "flag",
          "nation",
          "country",
          "banner",
          "flag vg",
        ],
        name: "british virgin islands flag",
        order: 255,
        unified: "1F1FB-1F1EC",
        emoji: "🇻🇬",
      },
      {
        keywords: [
          "u.s. virgin islands",
          "us virgin islands",
          "virgin islands",
          "u.s. virgin islands flag",
          "us virgin islands flag",
          "flag u s virgin islands",
          "virgin",
          "islands",
          "us",
          "flag",
          "nation",
          "country",
          "banner",
          "u s virgin islands",
          "flag vi",
        ],
        name: "u.s. virgin islands flag",
        order: 256,
        unified: "1F1FB-1F1EE",
        emoji: "🇻🇮",
      },
      {
        keywords: [
          "vietnam",
          "vietnamese flag",
          "flag vietnam",
          "viet",
          "nam",
          "flag",
          "nation",
          "country",
          "banner",
          "flag vn",
          "vietnam flag",
        ],
        name: "vietnam flag",
        order: 257,
        unified: "1F1FB-1F1F3",
        emoji: "🇻🇳",
      },
      {
        keywords: [
          "vanuatu",
          "vanuatuan flag",
          "flag vanuatu",
          "vu",
          "flag",
          "nation",
          "country",
          "banner",
          "flag vu",
          "vanuatu flag",
        ],
        name: "vanuatu flag",
        order: 258,
        unified: "1F1FB-1F1FA",
        emoji: "🇻🇺",
      },
      {
        keywords: [
          "wallis & futuna",
          "wallis and futuna",
          "wallis flag",
          "futuna flag",
          "flag wallis futuna",
          "wallis",
          "futuna",
          "flag",
          "nation",
          "country",
          "banner",
          "wallis futuna",
          "flag wf",
          "wallis & futuna flag",
        ],
        name: "wallis & futuna flag",
        order: 259,
        unified: "1F1FC-1F1EB",
        emoji: "🇼🇫",
      },
      {
        keywords: [
          "samoa",
          "samoan flag",
          "flag samoa",
          "ws",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ws",
          "samoa flag",
        ],
        name: "samoa flag",
        order: 260,
        unified: "1F1FC-1F1F8",
        emoji: "🇼🇸",
      },
      {
        keywords: [
          "kosovo",
          "kosovar flag",
          "flag kosovo",
          "xk",
          "flag",
          "nation",
          "country",
          "banner",
          "flag xk",
          "kosovo flag",
        ],
        name: "kosovo flag",
        order: 261,
        unified: "1F1FD-1F1F0",
        emoji: "🇽🇰",
      },
      {
        keywords: [
          "yemen",
          "yemeni flag",
          "flag yemen",
          "ye",
          "flag",
          "nation",
          "country",
          "banner",
          "flag ye",
          "yemen flag",
        ],
        name: "yemen flag",
        order: 262,
        unified: "1F1FE-1F1EA",
        emoji: "🇾🇪",
      },
      {
        keywords: [
          "mayotte",
          "mahoran flag",
          "flag mayotte",
          "yt",
          "flag",
          "nation",
          "country",
          "banner",
          "flag yt",
          "mayotte flag",
        ],
        name: "mayotte flag",
        order: 263,
        unified: "1F1FE-1F1F9",
        emoji: "🇾🇹",
      },
      {
        keywords: [
          "south africa",
          "south african flag",
          "flag south africa",
          "south",
          "africa",
          "flag",
          "nation",
          "country",
          "banner",
          "flag za",
          "south africa flag",
        ],
        name: "south africa flag",
        order: 264,
        unified: "1F1FF-1F1E6",
        emoji: "🇿🇦",
      },
      {
        keywords: [
          "zambia",
          "zambian flag",
          "flag zambia",
          "zm",
          "flag",
          "nation",
          "country",
          "banner",
          "flag zm",
          "zambia flag",
        ],
        name: "zambia flag",
        order: 265,
        unified: "1F1FF-1F1F2",
        emoji: "🇿🇲",
      },
      {
        keywords: [
          "zimbabwe",
          "zimbabwean flag",
          "flag zimbabwe",
          "zw",
          "flag",
          "nation",
          "country",
          "banner",
          "flag zw",
          "zimbabwe flag",
        ],
        name: "zimbabwe flag",
        order: 266,
        unified: "1F1FF-1F1FC",
        emoji: "🇿🇼",
      },
      {
        keywords: [
          "england",
          "english flag",
          "flag england",
          "flag",
          "english",
          "england flag",
        ],
        name: "england flag",
        order: 267,
        unified: "1F3F4-E0067-E0062-E0065-E006E-E0067-E007F",
        emoji: "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
      },
      {
        keywords: [
          "scotland",
          "scottish flag",
          "flag scotland",
          "flag",
          "scottish",
          "scotland flag",
        ],
        name: "scotland flag",
        order: 268,
        unified: "1F3F4-E0067-E0062-E0073-E0063-E0074-E007F",
        emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
      },
      {
        keywords: [
          "wales",
          "welsh flag",
          "flag wales",
          "flag",
          "welsh",
          "wales flag",
        ],
        name: "wales flag",
        order: 269,
        unified: "1F3F4-E0067-E0062-E0077-E006C-E0073-E007F",
        emoji: "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
      },
    ],
  },
];
