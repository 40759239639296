import axios from "axios";

import { WEB_APP_URL } from "~/constants";

import { getApiHeaders } from "./utils";

type Props = {
  amount: number;
  currency: string;
  uid: string;
  isSubscription: boolean;
  name?: string;
  email?: string;
  env: string;
};

export const fetchPaymentIntentClientSecret = async ({
  amount,
  currency,
  isSubscription,
  name,
  email,
  env,
}: Props) => {
  const headers = await getApiHeaders();
  const response = await axios.post(
    `${WEB_APP_URL}/api/payments/create-intent-${env}`,
    {
      amount,
      currency,
      isSubscription,
      name,
      email,
    },
    headers
  );

  if (response?.data?.clientSecret) {
    return {
      clientSecret: response.data.clientSecret,
      customer: response.data.customer,
      paymentMethodId: response.data.data?.paymentMethodId,
    };
  }
  return {
    error: true,
  };
};
