import * as React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { useTheme } from "styled-components/native";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { colors } from "~/styles/theme";

interface Props {
  containerStyle: StyleProp<ViewStyle>;
  layout: Object[];
  isLoading: boolean;
  children?: React.ReactNode;
}

export const SkeletonContent = React.memo<Props>(
  ({ containerStyle, layout = [], isLoading, children }) => {
    const theme = useTheme();

    if (!isLoading) {
      return <View style={containerStyle}>{children}</View>;
    }

    return (
      <View style={containerStyle}>
        <SkeletonTheme
          baseColor={theme.colors.gray200}
          highlightColor={theme.colors.white}
        >
          {layout.map((layoutStyle, index) => (
            <Skeleton key={index} {...layoutStyle} borderRadius={4} />
          ))}
        </SkeletonTheme>
      </View>
    );
  }
);
