import React from "react";
import { GestureResponderEvent, ActivityIndicator } from "react-native";
import MaterialIcon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";

import { Icon, IconTypes, IconSizes } from "~/components/icon";
import { formatMessage, isIntlDescriptor } from "~/utils/translation";

import {
  PressableWrapper,
  Label,
  IconBox,
  LeftIconBox,
  LoadingBox,
  ListButtonWrapper,
  ListButtonLabel,
  pressedStyle,
  InlineWrapper,
  InlineText,
} from "./styles";
import { ButtonTypes } from "./types";

export type Props = {
  text: TextType;
  onPress: (e?: GestureResponderEvent) => void;
  onLongPress?: () => void;
  type?: ButtonTypes;
  isDisabled?: boolean;
  isLoading?: boolean;
  isUppercase?: boolean;
  stretch?: boolean;
  small?: boolean;
  isLast?: boolean;
  iconType?: keyof typeof MaterialIcon.glyphMap;
};

export const Button = React.memo<Props>(
  ({
    text,
    onPress,
    onLongPress,
    type = ButtonTypes.Primary,
    isUppercase,
    iconType,
    isDisabled,
    isLoading,
    isLast,
    stretch,
  }) => {
    const theme = useTheme();
    const labelText = isIntlDescriptor(text)
      ? formatMessage(text as MessageDescriptorValues)
      : (text as string);
    const label = isUppercase ? labelText.toUpperCase() : labelText;
    const mode = type === ButtonTypes.Primary ? "contained" : "text";
    const iconColor =
      type === ButtonTypes.Primary ? theme.colors.white : theme.colors.gray800;
    const hasIcon = !!iconType;

    return (
      <PressableWrapper
        type={type}
        disabled={isLoading || !!isDisabled}
        onPress={onPress}
        onLongPress={onLongPress}
        stretch={stretch}
        style={({ pressed }) => [pressed && pressedStyle]}
        hasIcon={hasIcon}
        isLast={isLast}
      >
        <Label
          type={type}
          disabled={!!isDisabled}
          hasIcon={hasIcon}
          isUppercase={isUppercase}
        >
          {label}
        </Label>
        {iconType ? (
          <IconBox>
            <MaterialIcon
              size={IconSizes.Small}
              name={iconType}
              color={iconColor}
            />
          </IconBox>
        ) : null}
        {isLoading ? (
          <LoadingBox>
            <ActivityIndicator color={theme.colors.white} />
          </LoadingBox>
        ) : null}
      </PressableWrapper>
    );
  }
);

export const SocialButton = React.memo<Props>(
  ({
    text,
    onPress,
    onLongPress,
    type = ButtonTypes.Google,
    isDisabled,
    isUppercase,
  }: Props): JSX.Element => {
    const iconType =
      type === ButtonTypes.Google ? IconTypes.LogoGoogle : IconTypes.LogoApple;
    const iconSize = type === ButtonTypes.Google ? 22 : 24;
    const labelText = isIntlDescriptor(text)
      ? formatMessage(text as MessageDescriptorValues)
      : (text as string);
    const label = isUppercase ? labelText.toUpperCase() : labelText;

    return (
      <PressableWrapper
        type={type}
        disabled={!!isDisabled}
        onPress={onPress}
        onLongPress={onLongPress}
        uppercase={false}
        mode="contained"
      >
        <LeftIconBox>
          <Icon type={iconType} size={iconSize} />
        </LeftIconBox>

        <Label type={type} disabled={!!isDisabled} isUppercase={isUppercase}>
          {label}
        </Label>
      </PressableWrapper>
    );
  }
);

export const ListButton = React.memo<Props>(
  ({ text, onPress, onLongPress, isUppercase }) => {
    const labelText = isIntlDescriptor(text)
      ? formatMessage(text as MessageDescriptorValues)
      : (text as string);
    const label = isUppercase ? labelText.toUpperCase() : labelText;
    const theme = useTheme();

    return (
      <ListButtonWrapper onPress={onPress} onLongPress={onLongPress}>
        <ListButtonLabel>{label}</ListButtonLabel>

        <IconBox>
          <MaterialIcon
            size={IconSizes.Medium}
            name="chevron-right"
            color={theme.colors.primaryBlue}
          />
        </IconBox>
      </ListButtonWrapper>
    );
  }
);

export const InlineButton = React.memo<Props>(
  ({
    text,
    onPress,
    onLongPress,
    isDisabled,
    isUppercase,
  }: Props): JSX.Element => {
    const labelText = isIntlDescriptor(text)
      ? formatMessage(text as MessageDescriptorValues)
      : (text as string);
    const label = isUppercase ? labelText.toUpperCase() : labelText;

    return (
      <InlineWrapper
        disabled={!!isDisabled}
        onPress={onPress}
        onLongPress={onLongPress}
      >
        <InlineText isUppercase={isUppercase}>{label}</InlineText>
      </InlineWrapper>
    );
  }
);
