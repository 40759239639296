import * as React from "react";
import { FlatList } from "react-native";
import { useTheme } from "styled-components/native";

import { ListItem } from "~/components/list-item";
import { useAppSelector } from "~/state/hooks";
import { getPersonalNotesFiltered } from "~/state/personal-notes/selectors";
import { SortEnum } from "~/state/notes/types";
import { getIsDarkMode } from "~/state/settings";
import { PersonalNote } from "~/state/personal-notes/types";

import { Section, EmptyText, contentContainerStyle } from "./styles";

interface Props {
  searchTerm: string;
  sortMethod: SortEnum;
  emptyMessage: TextType;
  onPress: (sessionId: string) => void;
}

export const Content = React.memo<Props>(
  ({ searchTerm, sortMethod, emptyMessage, onPress }) => {
    const theme = useTheme();
    const isDarkMode = useAppSelector(getIsDarkMode);
    const data = useAppSelector((state) =>
      getPersonalNotesFiltered(state, `${sortMethod}:${searchTerm}`)
    );

    const renderItem = ({
      item: { id, title, text, lastUpdated },
      index,
    }: {
      item: PersonalNote;
      index: number;
    }) => {
      return (
        <ListItem
          title={title}
          description={text}
          lastUpdated={lastUpdated}
          onPress={() => onPress(id)}
          isLast={index === data.length - 1}
          key={title}
          numberOfLines={2}
        />
      );
    };

    return data.length ? (
      <Section>
        <FlatList
          data={data}
          removeClippedSubviews
          windowSize={7}
          contentContainerStyle={{
            ...contentContainerStyle,
            backgroundColor: isDarkMode
              ? theme.colors.gray50
              : theme.colors.white,
          }}
          showsVerticalScrollIndicator={false}
          renderItem={renderItem}
          key={searchTerm}
        />
      </Section>
    ) : (
      <EmptyText>{emptyMessage}</EmptyText>
    );
  }
);
