import styled from "styled-components/native";
import { LinearGradient } from "expo-linear-gradient";

import { Text1, Text2, Text3 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

export const Container = styled.TouchableOpacity<{ isDarkMode?: boolean }>`
  background-color: ${({ theme, isDarkMode }) =>
    isDarkMode ? theme.colors.gray50 : theme.colors.white};
  margin-bottom: ${spacers.ss6};
  border-radius: 8px;
`;

export const Header = styled.View`
  flex-direction: row;
  padding-vertical: ${spacers.ss5};
  padding-horizontal: ${spacers.ss6};
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const HeaderText = styled(Text3)`
  padding-right: ${spacers.ss3};
`;

export const TextBold = styled(Text3)`
  font-family: SFProDisplayMedium;
`;

export const DateText = styled(Text1)`
  color: ${({ theme }) => theme.colors.gray500};
`;

export const VerseBox = styled.View`
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray200};
  padding-vertical: ${spacers.ss6};
  padding-horizontal: ${spacers.ss6};
  flex-direction: row;
`;

export const VerseBar = styled(LinearGradient).attrs({
  colors: ["#2563EB", "#EB9025"],
  start: { x: 0.5, y: 0 },
})`
  width: 2px;
  height: 100%;
  margin-right: ${spacers.ss4};
`;

export const VerseNumber = styled(Text1)`
  font-family: LoraSuperscript;
  font-size: 9px;
  color: ${({ theme }) => theme.colors.gray800};
`;

export const VerseText = styled(Text2)`
  line-height: 19.5px;
  color: ${({ theme }) => theme.colors.gray800};
`;

export const Content = styled.View`
  padding-vertical: ${spacers.ss5};
  padding-horizontal: ${spacers.ss6};
`;

export const NoteText = styled(Text2)`
  line-height: 21px;
  color: ${({ theme }) => theme.colors.gray800};
`;
