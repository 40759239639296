import * as React from "react";
import { useTheme } from "styled-components/native";

import { RichText } from "~/screens/session/components/content/components/rich-text";

import { QuestionBox, questionTextStyles } from "./styles";

interface Props {
  question: string;
}

export const NoteQuestion = React.memo<Props>(({ question }) => {
  const theme = useTheme();
  const text = `**Q**: ${question}`;
  return (
    <QuestionBox>
      <RichText
        extraMarkdownStyles={{
          body: {
            ...questionTextStyles.body,
            color: theme.colors.gray800,
          },
        }}
      >
        {text}
      </RichText>
    </QuestionBox>
  );
});
