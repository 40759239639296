import * as React from "react";
import { TouchableOpacity, Text } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useIsFocused } from "@react-navigation/native";
import { useTheme } from "styled-components/native";
import {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  useAnimatedScrollHandler,
  interpolate,
} from "react-native-reanimated";
import { ActivityIndicator } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { getIsDarkMode } from "~/state/settings";
import { LibraryStackParamList } from "~/navigation/library-stack";
import * as routes from "~/constants/routes";
import { useAppDispatch, useAppNetInfo, useAppSelector } from "~/state/hooks";
import { useLibrary } from "~/state/library";
import {
  getPlanById,
  getVolumesByPlanId,
  getPlanDaysCount,
  getPlanCover,
  getSessions,
} from "~/state/flamelink/selectors";
import {
  getMainButtonType,
  getNormalizedProgressByPlanId,
  MainButtonType,
  ProgressState,
  useAddSessionProgressMutation,
  useDeletePlanProgressMutation,
  useResetDayProgressMutation,
} from "~/state/content-progress";
import {
  getAudienceByPlanId,
  getSessionDataToOpen,
  getSessionItemsLength,
  getVolumeSessionsCount,
  loadPlanFiles,
} from "~/state/flamelink";
import { useTutorial, getTutorialStep } from "~/state/tutorial";

import { Loader } from "~/components/loader";
import { formatMessage } from "~/utils/translation";
import { asyncLogEvent, events } from "~/utils/analytics";
import { Screen } from "~/components/screen";
import { IconSizes } from "~/components/icon";
import { OfflineBanner } from "~/components/offline-banner";
import { useAlert } from "~/components/alert";
import { isWeb } from "~/utils/platform";
import { HeaderBar } from "~/components/header-bar";
import { Switch } from "~/components/switch";

import {
  PlanCoverBox,
  PlanCoverImage,
  PlanCoverImagePlaceholder,
  PlanCoverImageBox,
  Label,
  LabelText,
  MetaBox,
  MetaText,
  PlanTitle,
  VolumesView,
  Content,
  Header,
  HEADER_HEIGHT,
  ResetProgressText,
  ResetProgressSpinner,
  VolumesLoaderContainer,
} from "./styles";
import { PlanBackButton } from "./components/back-button";
import { PlanMainButton } from "./components/main-button";
import { VolumeSessions } from "./components/volume-sessions";
import { EmptyPlan } from "./components/empty-plan";
import { VolumeSection } from "./components/volume-section";
import { PlanDescription } from "./components/description";
import { messages } from "./intl";
import {
  getHierarchyIndexes,
  getSessionById,
  getSessionProgressCheckConditions,
} from "./utils";
import { ContentMode } from "./types";

export type PlanProps = NativeStackScreenProps<LibraryStackParamList, "plan">;

interface OnPressSessionParams {
  sessionId: string;
  volumeIndex: number;
  sessionIndex: number;
  dayIndex: number;
}

export const Plan: React.FC<PlanProps> = ({ navigation, route }) => {
  const { planId, backToHome } = route.params;

  const [contentMode, setContentMode] = React.useState(ContentMode.Sessions);
  const { bottom } = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const { showAlert } = useAlert();
  const { showTutorial } = useTutorial(route.name, isFocused);
  const theme = useTheme();

  const { planProgressId, setPlanProgressId } = useLibrary();
  const [isInitializingProgress, setIsInitializingProgress] =
    React.useState(false);

  const isDarkMode = useAppSelector(getIsDarkMode);
  const planData = useAppSelector((state) => getPlanById(state, planId));
  const audienceData = useAppSelector((state) =>
    getAudienceByPlanId(state, planId)
  );
  const planVolumes = useAppSelector((state) =>
    getVolumesByPlanId(state, planId)
  );
  const planCover = useAppSelector((state) => getPlanCover(state, planId));
  const planTitle = planData?.displayTitle || planData?.title || "";
  const labelTitle =
    audienceData?.displayTitle || audienceData?.carouselTitle || "";
  const isEmpty = !planVolumes?.length;

  const progressData = useAppSelector((state) =>
    getNormalizedProgressByPlanId(state, planId)
  );
  const mainButtonType = useAppSelector((state) =>
    getMainButtonType(state, planId)
  );
  const sessions = useAppSelector(getSessions);

  const [addSessionProgress] = useAddSessionProgressMutation();
  const [deletePlanProgress, { isLoading: isDeletingPlanProgress }] =
    useDeletePlanProgressMutation();
  const [resetDayProgress] = useResetDayProgressMutation();

  const totalDays = useAppSelector((state) => getPlanDaysCount(state, planId));
  const totalSessions = useAppSelector((state) =>
    getVolumeSessionsCount(state, planId)
  );

  const tutorialStep = useAppSelector((state) =>
    getTutorialStep(state, "plan")
  );

  const { isConnected } = useAppNetInfo();

  const onPressSession = React.useCallback(
    (params: OnPressSessionParams) => {
      asyncLogEvent(events.LIBRARY_OPEN_SESSION, {
        planId,
        audienceId: audienceData?.id,
        ...params,
      });
      navigation.navigate(routes.session, {
        planId,
        ...params,
      });
    },
    [navigation, planId, audienceData?.id]
  );

  const onResetButtonPress = React.useCallback(() => {
    showAlert(formatMessage(messages.resetConfirm), "", [
      {
        text: formatMessage(messages.cancel),
        style: "cancel",
      },
      {
        text: formatMessage(messages.resetButton),
        onPress: () => {
          deletePlanProgress({ planId });
        },
        style: "destructive",
      },
    ]);
  }, [deletePlanProgress, planId, showAlert]);

  const onRestartButtonPress = React.useCallback(() => {
    showAlert(formatMessage(messages.restartConfirm), "", [
      {
        text: formatMessage(messages.cancel),
        style: "cancel",
      },
      {
        text: formatMessage(messages.restartButton),
        onPress: () => {
          deletePlanProgress({ planId });
        },
        style: "destructive",
      },
    ]);
  }, [deletePlanProgress, planId, showAlert]);

  const onMainButtonPress = React.useCallback(async () => {
    if (mainButtonType === MainButtonType.Restart) {
      onRestartButtonPress();

      return;
    }

    const sessionToResume =
      progressData?.furthestSessionInProgress ||
      progressData?.firstSessionNotStarted;

    if (sessionToResume) {
      const sessionToOpenData = getSessionById(
        sessions,
        sessionToResume.sessionId
      );
      const sessionProgressCheckConditions = getSessionProgressCheckConditions(
        sessions,
        sessionToResume.sessionId
      );

      const { volumeIndex, sessionIndex, dayIndex } = getHierarchyIndexes(
        planVolumes,
        sessionToResume,
        sessionProgressCheckConditions
      );

      const childDataToOpen = getSessionDataToOpen({
        sessionIndex,
        volumeIndex,
        dayIndex,
        sessionSourceData: sessionToOpenData,
      });

      onPressSession({
        sessionId: sessionToResume.sessionId,
        ...childDataToOpen,
        volumeIndex,
        sessionIndex,
      });

      // as we are resuming, let's mark the record as in-progress
      if (sessionToResume?.sessionProgressId) {
        await resetDayProgress({
          sessionProgressId: sessionToResume.sessionProgressId,
          dayKey: `day${dayIndex}`,
          newState: ProgressState.InProgress,
        });
      }

      return;
    }

    // If we have sessions completed, it is likely there would be a session not started next to them.
    // We want to check and perform the necessary actions to start it and mark it as in-progress.
    if (
      mainButtonType === MainButtonType.Resume &&
      progressData?.firstSessionNotStartedWithoutProgressRecord
    ) {
      const { volumeIndex, sessionIndex, sessionId, volumeId } =
        progressData?.firstSessionNotStartedWithoutProgressRecord;

      const sessionToOpenData = getSessionById(sessions, sessionId);
      const sessionItemsLength = getSessionItemsLength(sessionToOpenData);

      // if a session has a volume heading, we should open the group day. Otherwise, open the first day
      const initialDayIndex =
        sessionToOpenData?.volumeHeading ||
        sessionToOpenData?.lessons.length ||
        sessionToOpenData?.sessions.length
          ? 0
          : 1;

      const childDataToOpen = getSessionDataToOpen({
        sessionIndex,
        volumeIndex,
        dayIndex: initialDayIndex,
        sessionSourceData: sessionToOpenData,
      });

      onPressSession({
        sessionId,
        ...childDataToOpen,
      });

      const result = await addSessionProgress({
        planId,
        volumeId,
        sessionId,
        totalDays: sessionItemsLength,
        planProgressId,
        dayKey: `day${initialDayIndex}`,
      });

      if ("data" in result) {
        setPlanProgressId(result.data);
      }

      return;
    }

    // Prevent accessing the arrays that might be null or empty
    if (
      !Array.isArray(planVolumes) ||
      !planVolumes.length ||
      !Array.isArray(planVolumes[0].sessions) ||
      !planVolumes[0].sessions.length
    ) {
      return;
    }

    // Flow when there are no sessions completed and no sessions in progress

    // Starts in the very first session of the volumes by default
    const sessionId = planVolumes[0].sessions[0];

    const sessionToOpenData = getSessionById(sessions, sessionId);
    const sessionItemsLength = getSessionItemsLength(sessionToOpenData);

    // if a session has a volume heading, we should open the group day. Otherwise, open the first day
    const initialDayIndex =
      sessionToOpenData?.volumeHeading ||
      sessionToOpenData?.lessons.length ||
      sessionToOpenData?.sessions.length
        ? 0
        : 1;

    const childDataToOpen = getSessionDataToOpen({
      sessionIndex: 0,
      volumeIndex: 0,
      dayIndex: initialDayIndex,
      sessionSourceData: sessionToOpenData,
    });

    onPressSession({
      sessionId,
      ...childDataToOpen,
    });

    const result = await addSessionProgress({
      planId,
      volumeId: planVolumes[0].id,
      sessionId,
      totalDays: sessionItemsLength,
      planProgressId,
      dayKey: `day${initialDayIndex}`,
    });

    if ("data" in result) {
      setPlanProgressId(result.data);
    }
  }, [
    mainButtonType,
    progressData?.furthestSessionInProgress,
    progressData?.firstSessionNotStarted,
    progressData?.firstSessionNotStartedWithoutProgressRecord,
    planVolumes,
    onPressSession,
    addSessionProgress,
    planId,
    planProgressId,
    onRestartButtonPress,
    resetDayProgress,
    setPlanProgressId,
    sessions,
  ]);

  const opacity = useSharedValue(1);
  const headerHeight = useSharedValue(HEADER_HEIGHT);
  const scrollPosition = useSharedValue(0);

  const loadingImageStyles = useAnimatedStyle(
    () => ({ opacity: opacity.value }),
    [opacity]
  );
  const headerStyles = useAnimatedStyle(
    () => ({ height: headerHeight.value }),
    [headerHeight]
  );

  const onLoad = React.useCallback(() => {
    opacity.value = withTiming(0, { duration: 1000 });
  }, [opacity]);

  const imageAnimatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(scrollPosition.value, [0, 100], [0, 50]);
    const imageOpacity = interpolate(scrollPosition.value, [0, 300], [1, 0]);
    return {
      transform: [{ translateY }],
      opacity: imageOpacity,
    };
  }, []);

  const headerStyle = useAnimatedStyle(() => {
    const headerOpacity = interpolate(scrollPosition.value, [0, 300], [0, 1]);
    return {
      opacity: headerOpacity,
    };
  }, []);

  const handleScroll = useAnimatedScrollHandler({
    onScroll: (event) => {
      scrollPosition.value = event.contentOffset.y;
    },
  });

  React.useEffect(() => {
    if (progressData?.planProgressId) {
      setPlanProgressId(progressData.planProgressId);
    }
  }, [progressData?.planProgressId, setPlanProgressId]);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(loadPlanFiles({ planId }));
  }, [dispatch, planId]);

  React.useEffect(() => {
    showTutorial(tutorialStep);
  }, [showTutorial, tutorialStep]);

  if (!planData) return <Loader fullScreen />;

  const screenProps = isWeb ? { backgroundColor: theme.colors.gray100 } : {};

  const switchOptions = [
    {
      message: messages.sessions,
      icon: "progress-check",
      id: ContentMode.Sessions,
    },
    {
      message: messages.downloads,
      icon: "download",
      id: ContentMode.Downloads,
      tutorialStep: "PLAN.SESSION.DOWNLOADS",
    },
  ];

  const isResetButtonVisible =
    !isEmpty && mainButtonType !== MainButtonType.Start;

  const bottomMargin = bottom + (isResetButtonVisible ? 60 : 70);

  return (
    <Screen screenName="plan" isFragment={!isWeb} {...screenProps}>
      {isWeb ? <HeaderBar withBackButton={false} /> : null}

      <Content
        onScroll={handleScroll}
        scrollEventThrottle={16}
        extraMargin={bottomMargin}
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
        showsVerticalScrollIndicator={false}
        isDarkMode={isDarkMode}
      >
        {planCover && (
          <PlanCoverBox style={headerStyles}>
            <PlanCoverImagePlaceholder style={loadingImageStyles}>
              <ActivityIndicator />
            </PlanCoverImagePlaceholder>

            <PlanCoverImageBox style={imageAnimatedStyle}>
              <PlanCoverImage source={{ uri: planCover }} onLoad={onLoad} />
            </PlanCoverImageBox>
          </PlanCoverBox>
        )}

        <VolumesView>
          {!isConnected ? <OfflineBanner withTopMargin /> : null}

          {labelTitle ? (
            <Label>
              <LabelText>{labelTitle}</LabelText>
            </Label>
          ) : null}

          <PlanTitle>{planTitle}</PlanTitle>

          <PlanDescription text={planData?.description} />

          {isEmpty && <EmptyPlan />}

          {!isEmpty && (
            <>
              <MetaBox>
                <MetaText>
                  {formatMessage(messages.planProgress, {
                    progress: totalDays
                      ? progressData?.numberOfDaysCompleted ?? 0 / totalDays
                      : 0,
                  })}
                </MetaText>
                <MetaText>
                  {formatMessage(messages.totalSessions, {
                    total: totalSessions,
                  })}
                </MetaText>
              </MetaBox>

              <Switch
                activeIndex={contentMode}
                setIndex={setContentMode}
                options={switchOptions}
              />

              {/* The volumes render cost a lot of memory while transitioning, so we need to have them visible only if the screen if focused */}
              {isFocused && planVolumes ? (
                planVolumes?.map((volume, volumeIndex) => (
                  <VolumeSection
                    title={formatMessage(messages.volumeTitle, {
                      volumeIndex: volumeIndex + 1,
                      volumeTitle: volume.title,
                    })}
                    planId={planId}
                    volumeId={volume.id}
                    key={`volume-${volumeIndex}`}
                    hidden={volume.hidden}
                  >
                    <VolumeSessions
                      sessions={volume.sessions}
                      planId={planId}
                      volumeId={volume.id}
                      audienceId={audienceData?.id}
                      volumeIndex={volumeIndex}
                      progressData={progressData?.planProgress ?? []}
                      isInitializingProgress={isInitializingProgress}
                      setIsInitializingProgress={setIsInitializingProgress}
                      contentMode={contentMode}
                    />
                  </VolumeSection>
                ))
              ) : (
                <VolumesLoaderContainer>
                  <ActivityIndicator size={IconSizes.Medium} />
                </VolumesLoaderContainer>
              )}
            </>
          )}
        </VolumesView>

        {isResetButtonVisible && (
          <TouchableOpacity onPress={onResetButtonPress}>
            {isDeletingPlanProgress ? (
              <ResetProgressSpinner />
            ) : (
              <ResetProgressText>
                {formatMessage(messages.resetPlanProgress)}
              </ResetProgressText>
            )}
          </TouchableOpacity>
        )}
      </Content>

      <Header style={headerStyle} />
      {!isWeb ? <PlanBackButton backToHome={backToHome} /> : null}

      {!isEmpty ? (
        <PlanMainButton
          onPress={onMainButtonPress}
          planId={planId}
          type={mainButtonType}
          isDisabled={!isConnected}
        />
      ) : null}
    </Screen>
  );
};
