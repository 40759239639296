import styled from "styled-components/native";
import { StyleSheet } from "react-native";

import { Text1, Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

export const Container = styled.View<{ height: number }>``;

export const Row = styled.View<{ isLast: boolean }>`
  flex-direction: row;
  border-bottom-width: ${({ isLast }) => (isLast ? 0 : 1)}px;
  border-color: ${({ theme }) => theme.colors.gray100};
  padding-horizontal: ${spacers.ss6};
  padding-vertical: ${spacers.ss4};
  align-items: center;
`;

export const EmojiText = styled.Text`
  font-size: 14px;
`;

export const TopBar = styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: ${spacers.ss6};
  padding-bottom: ${spacers.ss6};
`;

export const Content = styled.View`
  margin-left: ${spacers.ss5};
  flex: 1;
`;

export const UserName = styled(Text2)``;

export const CountBox = styled.View<{ isHighlighted?: boolean }>`
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  ${({ isHighlighted, theme }) =>
    isHighlighted
      ? `background-color: ${theme.colors.gray100};
         padding: ${spacers.ss3} ${spacers.ss4};`
      : `margin-left: ${spacers.ss5};`}
`;

export const CountEmoji = styled.Text`
  font-size: 12px;
`;

export const CountText = styled(Text1)`
  color: ${({ theme }) => theme.colors.gray500};
  margin-left: 3px;
`;

export const webModalContainerStyles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingTop: 12,
    width: 400,
    height: 380,
    alignSelf: "center",
    overflow: "hidden",
  },
}).container;

export const contentContainerStyle = isWeb
  ? {
      height: 320,
    }
  : {};
