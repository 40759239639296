import styled from "styled-components/native";

import { Text2, Text3 } from "~/components/text";

import { spacers, colors } from "~/styles/theme";

export const Container = styled.View<{ isLast: boolean; isLoading: boolean }>`
  flex-direction: row;
  align-items: center;
  padding: ${spacers.ss5} ${spacers.ss4};
  border-bottom-width: 1px;
  border-bottom-color: ${({ isLast, theme }) =>
    isLast ? colors.transparent : theme.colors.gray200};
  opacity: ${({ isLoading }) => (isLoading ? 0.6 : 1)};
`;

export const TextBox = styled.View`
  flex: 1;
  margin-left: ${spacers.ss4};
`;

export const Name = styled(Text3)``;

export const Role = styled(Text2)`
  font-family: SFProDisplayItalic;
  color: ${({ theme }) => theme.colors.gray600};
`;

export const ButtonBox = styled.TouchableOpacity`
  padding: ${spacers.ss4} ${spacers.ss6};
  align-items: center;
  justify-content: center;
`;
