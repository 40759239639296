import * as React from "react";
import { useNavigation, CommonActions } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import NothingStartedImage from "<assets>/resources/nothing-started.png";

import { Container, EmptyImage, Content, Title, Description } from "./styles";
import { messages } from "./intl";
import { isWeb } from "~/utils/platform";

interface Props {
  title?: TextType;
  text?: TextType;
  isLeader?: Boolean;
  children?: React.ReactNode;
}

export const ResourceEmpty = React.memo<Props>(
  ({ title, text, isLeader = true }) => {
    const navigation = useNavigation();

    const onPress = React.useCallback(() => {
      if (!isLeader) {
        return;
      }

      if (isWeb) {
        navigation.navigate(routes.library);
        return;
      }

      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [
            {
              name: routes.libraryTab,
              params: { screen: routes.library },
            },
          ],
        })
      );
    }, [navigation, isLeader]);

    return (
      <Container onPress={onPress}>
        <EmptyImage source={NothingStartedImage} />

        <Content>
          <Title>{title || messages.title}</Title>
          <Description>{text || messages.description}</Description>
        </Content>
      </Container>
    );
  }
);
