import * as React from "react";
import Icon from "@expo/vector-icons/MaterialIcons";
import IonIcon from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "styled-components/native";

import * as routes from "~/constants/routes";
import { IntroBlock as IntroBlockType } from "~/state/flamelink";

import { RichText } from "../rich-text";
import { messages } from "./intl";
import {
  Section,
  SectionTitle,
  SectionText,
  Scripture,
  VerseText,
  IconBox,
  VerseLink,
  LinkText,
  questionStyles,
} from "./styles";
import { navigateTo } from "~/utils/navigation";
import { Footnotes } from "../footnotes";

interface Props {
  data: IntroBlockType;
}

export const IntroBlock = React.memo<Props>(({ data }) => {
  const {
    faithVerseDescription,
    faithVerseTitle,
    faithVerse,
    questionOfTheDay,
    faithFactTitle,
    questionOfTheDayTitle,
    faithFact,
    foundationForFaithTitle,
    foundationForFaith,
    foundationForFaithFootnotes,
  } = data;

  const navigation = useNavigation();
  const theme = useTheme();

  const onVersePress = React.useCallback(() => {
    // @ts-ignore
    navigateTo(navigation, routes.bibleTab, {
      screen: routes.bible,
      params: {
        scripture: faithVerse,
        showBackButton: true,
        isSession: true,
      },
    });
  }, [navigation, faithVerse]);

  return (
    <>
      {faithFact ? (
        <Section>
          <IconBox>
            <Icon name="playlist-add-check" size={26} />
          </IconBox>
          <SectionTitle>{faithFactTitle}</SectionTitle>
          <SectionText>{faithFact}</SectionText>
        </Section>
      ) : null}

      {faithVerse ? (
        <Section>
          <IconBox>
            <Icon name="format-quote" size={26} />
          </IconBox>

          <SectionTitle>{faithVerseTitle}</SectionTitle>
          <Scripture>{faithVerse}</Scripture>
          <VerseText>{faithVerseDescription}</VerseText>

          <VerseLink onPress={onVersePress}>
            <LinkText>{messages.verseLink}</LinkText>
            <IonIcon
              name="book-outline"
              size={15}
              color={theme.colors.primaryBlue}
            />
          </VerseLink>
        </Section>
      ) : null}

      {foundationForFaith ? (
        <Section>
          <SectionTitle>{foundationForFaithTitle}</SectionTitle>
          <RichText extraMarkdownStyles={questionStyles}>
            {foundationForFaith}
          </RichText>
        </Section>
      ) : null}

      {questionOfTheDay ? (
        <Section isLast>
          <SectionTitle isLast>{questionOfTheDayTitle}</SectionTitle>
          <RichText extraMarkdownStyles={questionStyles}>
            {questionOfTheDay}
          </RichText>
        </Section>
      ) : null}

      <Footnotes data={foundationForFaithFootnotes} />
    </>
  );
});
