import * as React from "react";
import { isSameDay } from "date-fns";
import { Keyboard, ScrollView, ActivityIndicator, Alert } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { useNavigation } from "@react-navigation/native";
import { BottomSheetModal } from "@gorhom/bottom-sheet";

import { database } from "<config>/firebase";
import { useAppSelector, useAppDispatch, useAppNetInfo } from "~/state/hooks";
import { useAppState } from "~/utils/hooks/use-app-state";
import { getGroupById } from "~/state/groups/selectors";
import {
  getUserId,
  getProfileImage,
  getEnvironment,
} from "~/state/user/selectors";
import { Message, MessageType } from "~/state/chat/types";
import { addMessages } from "~/state/chat/slice";
import { getUserChatMessages } from "~/state/chat/selectors";
import { createMessage, createReaction } from "~/state/chat/actions";
import { setLastActivity } from "~/state/groups/actions";
import { countUpdate } from "~/state/groups/slice";
import { getMessagesCollection } from "~/constants/collections";
import { PostAuthParamList } from "~/navigation/post-auth";
import { GroupHeaderBar } from "~/components/group-header-bar";
import { ChatMessage } from "~/components/chat";
import { ViewData } from "~/components/chat/message";
import { OfflineBanner } from "~/components/offline-banner";

import { InputBox } from "./components/input-box";
import { DayLabel } from "./components/day-label";
import { ActivityMessage } from "./components/activity-message";
import { ActionOverlay } from "./components/action-overlay";

import { Container, Content, ChatContent, LoaderBox } from "./styles";
import { isWeb } from "~/utils/platform";
import { ReactionsBox } from "./components/reactions-box";
import { EmojiPicker } from "./components/emoji-picker";

export type Props = NativeStackScreenProps<PostAuthParamList, "group.chat">;

interface ContentProps {
  groupId: string;
}

const isActivityMessage = (type: MessageType) =>
  [MessageType.NewMember, MessageType.MemberRemoved].includes(type);

export const GroupChatComponent = ({ groupId }: ContentProps) => {
  const groupData = useAppSelector((state) => getGroupById(state, groupId));
  const messages = useAppSelector(getUserChatMessages);
  const currentUserId = useAppSelector(getUserId);
  const currentUserImage = useAppSelector(getProfileImage);
  const env = useAppSelector(getEnvironment);
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const { isConnected } = useAppNetInfo();

  const [isLoading, setIsLoading] = React.useState(true);
  const [isSending, setIsSending] = React.useState(true);
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const [isEmojiBarOpen, setIsEmojiBarOpen] = React.useState(false);
  const [contentType, setContentType] = React.useState(MessageType.Text);
  const [highligtedView, setHighligtedView] = React.useState({});
  const [numberOfLines, setNumberOfLines] = React.useState(1);
  const [reactionMessageId, setReactionMessageId] = React.useState("");
  const [replyMessageId, setReplyMessageId] = React.useState("");
  const [replyMessageName, setReplyMessageName] = React.useState("");
  const [isWebModalVisible, setIsWebModalVisible] = React.useState(false);
  const [isWebReactionsBoxVisible, setIsWebReactionsBoxVisible] =
    React.useState(false);

  const scrollViewRef = React.useRef<ScrollView>();
  const modalRef = React.useRef<BottomSheetModal>(null);
  const reactionsModalRef = React.useRef<BottomSheetModal>(null);
  const pickerModalRef = React.useRef<BottomSheetModal>(null);

  const onContentChange = React.useCallback(() => {
    scrollViewRef?.current?.scrollToEnd({ animated: !isWeb });
  }, []);

  React.useEffect(() => {
    if (messages.length) {
      setTimeout(() => {
        onContentChange();
      }, 100);
    }
  }, [messages.length, onContentChange]);

  React.useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        onContentChange();
      }, 500);
    }
  }, [isLoading, onContentChange]);

  React.useEffect(() => {
    const showKeyboardSubscription = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        onContentChange();
      }
    );

    return () => {
      showKeyboardSubscription.remove();
    };
  }, [onContentChange]);

  React.useEffect(() => {
    if (!isConnected) {
      setIsLoading(false);

      return;
    }

    const messagesCollection = getMessagesCollection(env);
    const messagesRef = query(
      collection(database, messagesCollection, groupId, "messages"),
      orderBy("timestamp")
    );

    const unsubscribe = onSnapshot(messagesRef, (snap) => {
      const data: Message[] = [];

      snap.forEach((doc) => {
        data.push(doc.data() as Message);
      });

      setIsLoading(false);
      dispatch(addMessages(data));
      setTimeout(() => {
        dispatch(countUpdate({ groupId, userId: currentUserId }));
      }, 1000);
    });

    return () => unsubscribe();
  }, [groupId, currentUserId, dispatch, env, isConnected]);

  const onSubmitMessage = React.useCallback(
    (content: string) => {
      if (!content.trim()) {
        return;
      }
      setIsSending(true);

      dispatch(
        createMessage({
          content,
          groupId,
          type: MessageType.Text,
          replyMessageId,
          onSuccess: () => setIsSending(false),
          onError: () => setIsSending(false),
        })
      );
    },
    [dispatch, groupId, replyMessageId]
  );

  React.useEffect(() => {
    return () => {
      // Update activity when the screen is unmounted
      dispatch(setLastActivity({ groupId }));
    };
  }, [dispatch, groupId]);

  useAppState({
    onBackground: () => {
      // Update activity when the app is backgrounded
      dispatch(setLastActivity({ groupId }));
    },
  });

  const onMessagePress = React.useCallback(
    (type: MessageType) => (data: ViewData) => {
      modalRef?.current?.dismiss();
      setContentType(type);
      setHighligtedView(data);
      setIsOverlayOpen(true);
    },
    []
  );

  const onEmojiPress = React.useCallback(
    (messageId: string) => () => {
      modalRef?.current?.dismiss();
      pickerModalRef?.current?.dismiss();
      setReactionMessageId(messageId);
      setIsEmojiBarOpen(true);
    },
    []
  );

  const onMorePress = React.useCallback((messageId?: string) => {
    if (isWeb) {
      setReactionMessageId(messageId ?? "");
      setIsWebModalVisible(true);
      return;
    }
    pickerModalRef?.current?.present();
  }, []);

  const onEmojiSummaryPress = React.useCallback(
    (messageId: string) => () => {
      setReactionMessageId(messageId);
      if (isWeb) {
        setIsWebReactionsBoxVisible(true);
        return;
      }
      reactionsModalRef?.current?.present();
    },
    []
  );

  const onReset = React.useCallback(() => {
    modalRef?.current?.present();
    setIsOverlayOpen(false);
    setIsEmojiBarOpen(false);
  }, []);

  const onReply = React.useCallback(
    (name: string) => (messageId: string) => {
      modalRef?.current?.present();
      setReplyMessageName(name);
      setReplyMessageId(messageId);
    },
    []
  );

  const resetReply = React.useCallback(() => {
    setReplyMessageName("");
    setReplyMessageId("");
  }, []);

  const closeOverlay = React.useCallback(() => {
    setIsOverlayOpen(false);
    setIsEmojiBarOpen(false);
    setReactionMessageId("");
    setIsWebModalVisible(false);
    modalRef?.current?.snapToIndex(0);
    pickerModalRef?.current?.dismiss();
  }, []);

  const handleEmojiPress = React.useCallback(
    (icon: string) => {
      dispatch(
        createReaction({
          groupId,
          messageId: reactionMessageId,
          reaction: icon,
        })
      );
      closeOverlay();
    },
    [dispatch, groupId, reactionMessageId, closeOverlay]
  );

  React.useEffect(() => {
    if (!groupData && !isWeb) {
      navigation.goBack();
      return;
    }
  }, [navigation, groupData]);

  if (!groupData) {
    return null;
  }
  const { name, imageUri } = groupData;
  const replyMessagePadding = replyMessageId ? 80 : 0;
  const paddingBottom = numberOfLines * 20 + replyMessagePadding + 52;

  return (
    <Container>
      <Content behavior="padding">
        {!isWeb ? (
          <GroupHeaderBar
            groupId={groupId}
            uri={imageUri}
            name={name}
            hideChatIcon
            hideBackButton={isWeb}
          />
        ) : null}

        <OfflineBanner withTopMargin={false} />

        {!isLoading ? (
          <ChatContent ref={scrollViewRef} paddingBottom={paddingBottom}>
            {messages.map(
              (
                {
                  id,
                  content,
                  sender,
                  timestamp,
                  type,
                  data,
                  replyMessageId: replyId,
                  isDeleted,
                },
                index
              ) => {
                const {
                  id: userId,
                  firstName,
                  imageUri: senderImageUri,
                } = sender;
                const previousMessage = messages[index - 1];
                const lastType = previousMessage?.type;
                const lastTimestamp = previousMessage?.timestamp;
                const lastSenderId = previousMessage?.sender?.id;
                // After 30s start displaying the header again
                const isFirst =
                  !lastTimestamp ||
                  timestamp - lastTimestamp > 30000 ||
                  userId !== lastSenderId ||
                  isActivityMessage(lastType);
                const shouldShowDayLabel =
                  !lastTimestamp || !isSameDay(timestamp, lastTimestamp);
                const isCurrentUser = userId === currentUserId;
                const image = isCurrentUser ? currentUserImage : senderImageUri;

                const showActivityMessage = [
                  MessageType.NewMember,
                  MessageType.MemberRemoved,
                ].includes(type);

                if (showActivityMessage) {
                  return (
                    <ActivityMessage
                      key={id}
                      username={firstName}
                      type={type}
                    />
                  );
                }

                return [
                  shouldShowDayLabel ? (
                    <DayLabel key={`${id}-label`} timestamp={timestamp} />
                  ) : null,
                  <ChatMessage
                    key={`${id}-message`}
                    isReversed={!isCurrentUser}
                    isFirst={isFirst}
                    text={content}
                    data={data}
                    name={firstName}
                    imageUri={image}
                    timestamp={timestamp}
                    messageId={id}
                    groupId={groupId}
                    isDeleted={isDeleted}
                    onPress={onMessagePress(type)}
                    onEmojiPress={onEmojiPress(id)}
                    onEmojiSummaryPress={onEmojiSummaryPress(id)}
                    onMorePress={onMorePress}
                    onDismiss={closeOverlay}
                    onReply={onReply(firstName)}
                    type={type}
                    replyId={replyId}
                  />,
                ];
              }
            )}
          </ChatContent>
        ) : (
          <LoaderBox>
            <ActivityIndicator />
          </LoaderBox>
        )}

        <InputBox
          onContentSizeChange={onContentChange}
          onSubmit={onSubmitMessage}
          groupName={name}
          numberOfLines={numberOfLines}
          setNumberOfLines={setNumberOfLines}
          replyMessageId={replyMessageId}
          replyMessageName={replyMessageName}
          resetReply={resetReply}
          modalRef={modalRef}
        />

        <ReactionsBox
          modalRef={reactionsModalRef}
          messageId={reactionMessageId}
          onReset={onReset}
          isModalVisible={isWebReactionsBoxVisible}
          dismissModal={() => setIsWebReactionsBoxVisible(false)}
        />

        <EmojiPicker
          modalRef={pickerModalRef}
          messageId={reactionMessageId}
          onItemPress={handleEmojiPress}
          onReset={onReset}
          isModalVisible={isWebModalVisible}
          dismissModal={() => setIsWebModalVisible(false)}
        />
      </Content>
      {isOverlayOpen || isEmojiBarOpen ? (
        <ActionOverlay
          type={contentType}
          onPress={closeOverlay}
          onEmojiPress={handleEmojiPress}
          onMorePress={onMorePress}
          isEmoji={isEmojiBarOpen}
          {...highligtedView}
        />
      ) : null}
    </Container>
  );
};

export const GroupChat = ({
  route: {
    params: { groupId },
  },
}: Props) => {
  return <GroupChatComponent groupId={groupId} />;
};
