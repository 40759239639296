import * as React from "react";
import { RadioButton, Switch } from "react-native-paper";
import { useTheme } from "styled-components/native";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { TextInput } from "~/components/text-input";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  getInAppNotificationById,
  NotificationType,
  ResourceType,
  LinkType,
} from "~/state/in-app-notifications-setup";
import {
  addInAppNotification,
  removeInAppNotification,
  editInAppNotification,
} from "~/state/in-app-notifications-setup/actions";
import { formatMessage } from "~/utils/translation";
import { ActionButtons } from "~/components/cms/action-buttons";
import { CharacterCount } from "~/components/cms/character-count";
import {
  Container,
  InputBox,
  Label,
  InputSection,
  SectionTitle,
  HeaderBox,
  inputStyle,
  Disclaimer,
  FormImage,
  RadioOptions,
  RadioOption,
  RadioText,
  InfoIcon,
  LabelRow,
  InputSectionRelative,
} from "~/components/cms/styles";
import { useAlerts } from "~/state/alerts";
import { genericMessages } from "~/constants/intl";
import { colors } from "~/styles/theme";
import {
  LanguageKey,
  LocalisedFile,
} from "~/state/in-app-notifications-setup/types";

import { messages } from "./intl";
import { messages as sectionMessages } from "../../intl";
import { Options } from "../options";
import { getRouteOptions } from "~/utils/strings";
import { Select } from "../select";
import { languageOptions, defaultString } from "../../constants";
import { handleError } from "~/utils/logger";

interface Props {
  id?: string;
  onBack: () => void;
}

const MAX_BUTTON_TEXT_LENGTH = 100;
const MAX_TITLE_LENGTH = 160;
const MAX_DESCRIPTION_LENGTH = 2000;
const IMAGE_SIZE_LIMIT = 300 * 1024; // 300KB
const VIDEO_SIZE_LIMIT = 20 * 1000 * 1024; // 20MB

export const AnnouncementForm = React.memo<Props>(({ id = "", onBack }) => {
  const data = useAppSelector((state) => getInAppNotificationById(state, id));

  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();
  const theme = useTheme();

  const defaultRoute = data?.route || "";
  const defaultUrl = data?.url || "";
  const defaultNavigateTo = data?.navigateTo || "";
  const defaultLinkType = data?.linkType || LinkType.INTERNAL;
  const defaultResourceType = data?.resourceType || ResourceType.IMAGE;
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [language, setLanguage] = React.useState<LanguageKey>("en");
  const [type, setType] = React.useState(NotificationType.MODAL);
  const [resourceType, setResourceType] = React.useState(defaultResourceType);
  const [title, setTitle] = React.useState(data?.titleText || defaultString);
  const [description, setDescription] = React.useState(
    data?.descriptionText || defaultString
  );
  const [schedule, setSchedule] = React.useState(data?.schedule || "");
  const [expiration, setExpiration] = React.useState(data?.expiration || "");
  const [imageSet, setImageSet] = React.useState<LocalisedFile | undefined>(
    undefined
  );
  const [videoSet, setVideoSet] = React.useState<LocalisedFile | undefined>(
    undefined
  );
  const [imageError, setImageError] = React.useState("");
  const [videoError, setVideoError] = React.useState("");
  const [route, setRoute] = React.useState(data?.route || "");
  const [linkType, setLinkType] = React.useState(defaultLinkType);
  const [navigateTo, setNavigateTo] = React.useState(defaultNavigateTo);
  const [url, setUrl] = React.useState(data?.url || "");
  const [buttonText, setButtonText] = React.useState(
    data?.ctaText || defaultString
  );
  const [options, setOptions] = React.useState(data?.options || {});
  const [isActive, setIsActive] = React.useState(!!data?.isActive);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const isModal = type === NotificationType.MODAL;
  const isBanner = type === NotificationType.BANNER;

  const isInitialState =
    type === data?.type &&
    resourceType === defaultResourceType &&
    title === data?.titleText &&
    description === data?.descriptionText &&
    schedule === data?.schedule &&
    expiration === data?.expiration &&
    route === defaultRoute &&
    navigateTo === defaultNavigateTo &&
    url === defaultUrl &&
    buttonText === data?.ctaText &&
    isActive === data?.isActive &&
    linkType === defaultLinkType;

  const isModalRequired = isModal && (!title || !route || !buttonText);

  const isButtonDisabled =
    isInitialState ||
    isSaving ||
    isModalRequired ||
    !description ||
    !schedule ||
    !expiration ||
    (linkType === LinkType.INTERNAL && !navigateTo) ||
    (linkType === LinkType.EXTERNAL && !url);

  const sectionTitle = id ? sectionMessages.edit : sectionMessages.create;

  React.useEffect(() => {
    try {
      if (data?.schedule) {
        const utcSchedule = data.schedule?.toDate();
        const localSchedule = utcToZonedTime(utcSchedule, userTimezone);
        setSchedule(format(localSchedule, "yyyy-MM-dd'T'HH:mm"));
      }
      if (data?.expiration) {
        const utcExpiration = data.expiration?.toDate();
        const localExpiration = utcToZonedTime(utcExpiration, userTimezone);
        setExpiration(format(localExpiration, "yyyy-MM-dd'T'HH:mm"));
      }
    } catch (e) {
      handleError("Error parsing date");
    }
  }, [data?.schedule, data?.expiration, userTimezone]);

  const handleSetTitle = React.useCallback(
    (titleValue: string) => {
      if (title[language]?.length <= MAX_TITLE_LENGTH) {
        setTitle({ ...title, [language]: titleValue });
      }
    },
    [setTitle, language, title]
  );

  const handleSetBody = React.useCallback(
    (descriptionValue: string) => {
      if (description[language]?.length <= MAX_DESCRIPTION_LENGTH) {
        setDescription({ ...description, [language]: descriptionValue });
      }
    },
    [setDescription, language, description]
  );

  const handleSetButtonText = React.useCallback(
    (buttonTextValue: string) => {
      if (buttonText[language]?.length <= MAX_BUTTON_TEXT_LENGTH) {
        setButtonText({ ...buttonText, [language]: buttonTextValue });
      }
    },
    [setButtonText, language, buttonText]
  );

  const onSave = React.useCallback(() => {
    setIsSaving(true);

    const dataToSave = {
      type,
      resourceType,
      titleText: title,
      descriptionText: description,
      schedule,
      expiration,
      imageSet,
      videoSet,
      route,
      linkType,
      navigateTo,
      url,
      ctaText: buttonText,
      isActive,
      options,
    };

    if (!id) {
      dispatch(
        addInAppNotification({
          ...dataToSave,
          onSuccess: () => {
            setIsSaving(false);
            pushAlert({
              message: genericMessages.saveSuccess,
              color: colors.emerald600,
            });
            onBack();
          },
          onError: () => {
            setIsSaving(false);
            pushAlert({
              message: genericMessages.error,
            });
            onBack();
          },
        })
      );
      return;
    }

    dispatch(
      editInAppNotification({
        id,
        ...dataToSave,
        onSuccess: () => {
          setIsSaving(false);
          pushAlert({
            message: genericMessages.saveSuccess,
            color: colors.emerald600,
          });
          onBack();
        },
        onError: () => {
          setIsSaving(false);
          pushAlert({
            message: genericMessages.error,
          });
          onBack();
        },
      })
    );
  }, [
    id,
    type,
    resourceType,
    title,
    description,
    schedule,
    expiration,
    buttonText,
    imageSet,
    videoSet,
    route,
    linkType,
    navigateTo,
    url,
    isActive,
    options,
    dispatch,
    onBack,
    pushAlert,
  ]);

  const onDelete = () => {
    if (!id) {
      return;
    }
    setIsDeleting(true);
    dispatch(
      removeInAppNotification({
        id,
        onSuccess: () => {
          setIsDeleting(false);
          pushAlert({
            message: genericMessages.itemDeleted,
            color: colors.emerald600,
          });
          onBack();
        },
        onError: () => {
          setIsDeleting(false);
          pushAlert({
            message: genericMessages.error,
          });
          onBack();
        },
      })
    );
  };

  const handleImageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const selectedFile = e.target.files?.[0];
      if (selectedFile) {
        if (selectedFile.size > IMAGE_SIZE_LIMIT) {
          setImageError("File size exceeds 300KB");
          setImageSet(undefined);
        } else {
          // @ts-ignore
          setImageSet({ ...(imageSet || {}), [language]: selectedFile });
          setImageError("");
        }
      }
    },
    [imageSet, language]
  );

  const handleVideoChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const selectedFile = e.target.files?.[0];
      if (selectedFile) {
        if (selectedFile.size > VIDEO_SIZE_LIMIT) {
          setVideoError("File size exceeds 20MB");
          setVideoSet(undefined);
        } else {
          // @ts-ignore
          setVideoSet({ ...(videoSet || {}), [language]: selectedFile });
          setVideoError("");
        }
      }
    },
    [videoSet, language]
  );

  const displayImage = imageSet?.[language]
    ? URL.createObjectURL(imageSet[language])
    : data?.imageUrls?.[language];

  const displayVideo = videoSet?.[language]
    ? URL.createObjectURL(videoSet[language])
    : data?.videoUrls?.[language];

  const radioProps = {
    uncheckedColor: theme.colors.gray600,
  };

  const dateInputStyle = {
    ...inputStyle,
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
  };

  return (
    <Container>
      <HeaderBox>
        <SectionTitle>{sectionTitle}</SectionTitle>
      </HeaderBox>

      <InputBox>
        <Label>{messages.enabled}</Label>
        <Switch value={isActive} onValueChange={setIsActive} />
      </InputBox>

      <Select
        label={messages.language}
        options={languageOptions}
        setOption={setLanguage}
        value={language}
        hasMargin
      />

      <>
        <Label>{messages.type}</Label>
        <RadioButton.Group
          onValueChange={(value) => setType(value as NotificationType)}
          value={type}
        >
          <RadioOptions>
            <RadioOption>
              <RadioButton value={NotificationType.MODAL} {...radioProps} />
              <RadioText>{messages.typeModal}</RadioText>
            </RadioOption>
            <RadioOption>
              <RadioButton value={NotificationType.BANNER} {...radioProps} />
              <RadioText>{messages.typeBanner}</RadioText>
            </RadioOption>
          </RadioOptions>
        </RadioButton.Group>
      </>

      {!isBanner ? (
        <>
          <TextInput
            label={messages.title}
            onChangeText={handleSetTitle}
            value={title[language]}
          />
          <CharacterCount text={title[language]} limit={MAX_TITLE_LENGTH} />
        </>
      ) : null}

      <TextInput
        label={messages.description}
        onChangeText={handleSetBody}
        value={description[language]}
      />
      <CharacterCount
        text={description[language]}
        limit={MAX_DESCRIPTION_LENGTH}
      />

      {!isBanner ? (
        <>
          <TextInput
            label={messages.buttonText}
            onChangeText={handleSetButtonText}
            value={buttonText[language]}
          />
          <CharacterCount
            text={buttonText[language]}
            limit={MAX_DESCRIPTION_LENGTH}
          />
        </>
      ) : null}

      <InputSectionRelative>
        <Label>{messages.buttonLink}</Label>

        <RadioButton.Group
          onValueChange={(value) => setLinkType(value as LinkType)}
          value={linkType}
        >
          <RadioOptions>
            <RadioOption>
              <RadioButton value={LinkType.INTERNAL} {...radioProps} />
              <RadioText>{messages.internalLink}</RadioText>
            </RadioOption>
            <RadioOption>
              <RadioButton value={LinkType.EXTERNAL} {...radioProps} />
              <RadioText>{messages.externalLink}</RadioText>
            </RadioOption>
          </RadioOptions>
        </RadioButton.Group>

        {linkType === LinkType.INTERNAL ? (
          <Select
            label={messages.buttonLinkScreen}
            options={getRouteOptions()}
            setOption={setNavigateTo}
            value={navigateTo}
          />
        ) : (
          <TextInput
            label={messages.buttonLinkUrl}
            onChangeText={setUrl}
            value={url}
          />
        )}

        <Disclaimer>{messages.buttonLinkDisclaimer}</Disclaimer>
      </InputSectionRelative>

      {!isBanner ? (
        <>
          <Select
            label={messages.screen}
            options={getRouteOptions()}
            setOption={setRoute}
            value={route}
          />
          <Disclaimer>{messages.screenDisclaimer}</Disclaimer>
        </>
      ) : null}

      <InputSection>
        <Label>{messages.schedule}</Label>
        <input
          type="datetime-local"
          onChange={(e) => setSchedule(e.target.value)}
          value={schedule}
          style={dateInputStyle}
        />
      </InputSection>

      <InputSection>
        <Label>{messages.expiration}</Label>
        <input
          type="datetime-local"
          onChange={(e) => setExpiration(e.target.value)}
          value={expiration}
          style={dateInputStyle}
        />
      </InputSection>

      {!isBanner ? (
        <>
          <InputSection>
            <Label>{messages.resourceType}</Label>
            <RadioButton.Group
              onValueChange={(value) => setResourceType(value as ResourceType)}
              value={resourceType}
            >
              <RadioOptions>
                <RadioOption>
                  <RadioButton value={ResourceType.IMAGE} {...radioProps} />
                  <RadioText>{messages.image}</RadioText>
                </RadioOption>
                <RadioOption>
                  <RadioButton value={ResourceType.VIDEO} {...radioProps} />
                  <RadioText>{messages.video}</RadioText>
                </RadioOption>
              </RadioOptions>
            </RadioButton.Group>
          </InputSection>

          {resourceType === ResourceType.IMAGE ? (
            <>
              <InputSection>
                <Label>{messages.image}</Label>

                {displayImage ? (
                  <FormImage source={{ uri: displayImage }} />
                ) : null}
                <input
                  type="file"
                  onChange={handleImageChange}
                  style={dateInputStyle}
                />
              </InputSection>
              {imageError ? (
                <Disclaimer isError>{imageError}</Disclaimer>
              ) : null}
            </>
          ) : null}

          {resourceType === ResourceType.VIDEO ? (
            <>
              <InputSection>
                <Label>{messages.video}</Label>

                {displayVideo ? (
                  <video
                    style={{ maxWidth: 300 }}
                    controls
                    key={`${displayVideo}`}
                  >
                    <source src={displayVideo} type="video/mp4" />
                  </video>
                ) : null}
                <input
                  type="file"
                  onChange={handleVideoChange}
                  style={dateInputStyle}
                />
              </InputSection>
              {videoError ? (
                <Disclaimer isError>{videoError}</Disclaimer>
              ) : null}
            </>
          ) : null}

          <InputSection>
            <div title={formatMessage(messages.optionsTooltip)}>
              <LabelRow>
                <Label>{messages.options}</Label>

                <InfoIcon />
              </LabelRow>
            </div>

            <Options options={options} setOptions={setOptions} />
          </InputSection>
          <Disclaimer>{messages.optionsDisclaimer}</Disclaimer>
        </>
      ) : null}

      <ActionButtons
        onCancel={onBack}
        onSave={onSave}
        onDelete={onDelete}
        isSaveDisabled={isButtonDisabled}
        isDeleteDisabled={isDeleting}
        hideDelete={!id}
      />
    </Container>
  );
});
