import Svg from "react-native-svg";
import styled from "styled-components/native";

import { Text1, Text2, Text3 } from "~/components/text";
import { HeaderTitle } from "~/components/header-title";
import { spacers } from "~/styles/theme";
import { getWebContainerStyle } from "~/utils/web";
import { isWeb, windowHeight, windowWidth } from "~/utils/platform";

export const gradientColors = {
  start0: "#FFF",
  stop0: "#FBD8CE",
  start1: "#FFF",
  stop1: "#F2573D",
};

const getContentHeight = (
  isShort: boolean,
  hasInvite: boolean,
  hasDisclaimer: boolean
) =>
  new Map([
    [true, 80],
    [hasInvite, 110],
    [hasDisclaimer, 126],
    [isShort, 0],
  ]).get(true);

export const BackgroundWrapper = styled.View`
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
`;

export const BackgroundImage = styled.Image`
  width: ${isWeb ? "100%" : `${windowWidth}px`};
  height: ${isWeb ? "100%" : `${windowHeight}px`};
`;

export const BackgroundContainer = styled(Svg)`
  width: 100%;
  height: 100%;
`;

export const Gradient = styled.View`
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.red700};
`;

export const Container = styled.View`
  flex: 1;
`;

export const TopContainer = styled.View<{
  hasBackButton: boolean;
  paddingTop: number;
}>`
  padding-top: ${({ hasBackButton, paddingTop }) =>
    hasBackButton ? paddingTop : paddingTop + 20}px;
  padding-bottom: 40px;
  flex: 1;
`;

export const BottomContainer = styled.View<{
  isShort: boolean;
  hasInvite: boolean;
  hasDisclaimer: boolean;
}>`
  justify-content: center;
  height: ${({ isShort, hasInvite, hasDisclaimer }) =>
    getContentHeight(isShort, hasInvite, hasDisclaimer)}px;
  padding-bottom: 30px;
  ${getWebContainerStyle()}
`;

export const Content = styled.View<{
  hasMargin?: boolean;
  hasPadding?: boolean;
  maxWidth?: number;
}>`
  padding-horizontal: ${({ hasPadding }) =>
    hasPadding ? spacers.ss8 : spacers.ss0};
  padding-top: ${({ hasMargin }) => (hasMargin ? spacers.ss5 : spacers.ss0)};
  flex: 1;
  ${({ maxWidth }) => getWebContainerStyle(maxWidth)}
`;

export const TitleBox = styled.View`
  margin-bottom: ${spacers.ss7};
`;

export const Title = styled(HeaderTitle)``;

export const Description = styled(Text3)`
  font-family: Montserrat;
  color: ${({ theme }) => theme.colors.gray600};
  margin-top: ${spacers.ss4};
`;

export const InputBox = styled.View`
  margin-bottom: ${spacers.ss7};
`;

export const ButtonBox = styled.View`
  margin-bottom: ${spacers.ss5};
`;

export const ButtonRightBox = styled.View`
  margin-top: ${spacers.ss3};
  margin-bottom: ${spacers.ss5};
  align-self: flex-end;
`;

export const LogoutButton = styled.TouchableOpacity`
  position: absolute;
  top: 60px;
  right: 0px;
  padding-horizontal: ${spacers.ss8};
`;

export const Links = styled.View`
  flex-direction: row;
  padding-bottom: ${spacers.ss4};
`;

export const LinkBox = styled.TouchableOpacity<{ isRight: boolean }>`
  flex: 1;
  align-items: ${({ isRight }) => (isRight ? "flex-start" : "flex-end")};
  padding-horizontal: ${spacers.ss4};
`;

export const LinkText = styled(Text2)`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: SFProDisplayMedium;
`;

export const DisclaimerText = styled(Text1)`
  padding-horizontal: ${spacers.ss8};
  color: ${({ theme }) => theme.colors.gray800};
  text-align: center;
  line-height: 16px;
`;

export const FaqButton = styled.TouchableOpacity`
  position: absolute;
  top: 12px;
  right: 20px;
`;
