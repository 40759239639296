import React from "react";

import { SkeletonContent } from "~/components/skeleton-content";
import {
  getIsPlanPublished,
  getPlanThumbnail,
} from "~/state/flamelink/selectors";
import { useAppSelector } from "~/state/hooks";
import { isWeb } from "~/utils/platform";
import { getIsDarkMode } from "~/state/settings/selectors";

import {
  PlanThumbnail,
  PlanShadow,
  shadowStyle,
  skeletonContainerStyle,
  planContainerSkeletonLayout,
  PlanThumbnailOverlay,
  LockedPlanText,
} from "./styles";
import { messages } from "./intl";

interface PlanProps {
  onPress(): void;
  planId: string;
  withLoader?: boolean;
}

export const Plan: React.FC<PlanProps> = React.memo(
  ({ planId, onPress, withLoader = true }) => {
    const [isLoading, setIsLoading] = React.useState(withLoader);
    const planThumbnail = useAppSelector((state) =>
      getPlanThumbnail(state, planId)
    );
    const isPlanPublished = useAppSelector((state) =>
      getIsPlanPublished(state, planId)
    );
    const isDarkMode = useAppSelector(getIsDarkMode);
    const onLoad = React.useCallback(() => setIsLoading(false), []);

    const handleOnPress = React.useCallback(() => {
      if (isPlanPublished) {
        onPress();
      }
    }, [isPlanPublished, onPress]);

    return (
      <>
        {!isWeb && !isDarkMode ? (
          <SkeletonContent
            isLoading={true}
            containerStyle={skeletonContainerStyle}
            // @ts-ignore it doesn't accept positon absolute as a valid property
            layout={planContainerSkeletonLayout}
          />
        ) : null}

        <PlanShadow style={shadowStyle} onPress={handleOnPress}>
          {planThumbnail && (
            <>
              <PlanThumbnail
                uri={planThumbnail}
                // @ts-ignore
                onLoad={onLoad}
              />

              {!isPlanPublished && (
                <PlanThumbnailOverlay>
                  <LockedPlanText>{messages.lockedPlanTitle}</LockedPlanText>
                </PlanThumbnailOverlay>
              )}
            </>
          )}
        </PlanShadow>
      </>
    );
  }
);
