import uuid from "react-native-uuid";
import { SagaIterator } from "redux-saga";
import { call, select, takeLatest, all, put } from "redux-saga/effects";
import {
  doc,
  collection,
  setDoc,
  updateDoc,
  getCountFromServer,
  runTransaction,
} from "firebase/firestore";

import { database } from "<config>/firebase";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { asyncLogEvent, events } from "~/utils/analytics";

import {
  createMessage,
  createMessages,
  createReaction,
  deleteMessage,
} from "./actions";
import { getRecentMessageByGroupId } from "~/state/groups/selectors";
import {
  MessageType,
  AddMessageAction,
  AddMessagesAction,
  DeleteMessageAction,
  AddReactionAction,
} from "./types";
import { getDataByType } from "./utils";
import {
  getGroupsCollection,
  getMessagesCollection,
} from "~/constants/collections";
import { handleError } from "~/utils/logger";

export function* getMessagesCount(groupId: string): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const messagesCollection = getMessagesCollection(env);
    const messagesRef = collection(
      database,
      messagesCollection,
      groupId,
      "messages"
    );
    // @ts-ignore
    const messagesSnapshot = yield call(getCountFromServer, messagesRef);
    return messagesSnapshot.data().count || 0;
  } catch (e) {
    yield call(handleError, e);
    return 0;
  }
}

export function* createMessageFn({
  payload: {
    content,
    groupId,
    data: messageData,
    replyMessageId,
    type = MessageType.Text,
    onSuccess = () => {},
    onError = () => {},
  },
}: AddMessageAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const messagesCollection = getMessagesCollection(env);
    const groupsCollection = getGroupsCollection(env);

    const userId = yield select(getUserId);
    const id = uuid.v4() as string;
    const timestamp = new Date().getTime();

    const contentData = getDataByType({
      content,
      type,
      data: messageData,
    });

    const replyData = replyMessageId ? { replyMessageId } : {};

    const data = {
      ...contentData,
      ...replyData,
      id,
      type,
      userId,
      timestamp,
    };

    // Add the message document to the corresponding group collection
    yield call(
      // @ts-ignore
      setDoc,
      doc(database, messagesCollection, groupId, "messages", id),
      data,
      { merge: true }
    );
    yield call(asyncLogEvent, events.MESSAGES_ADD);

    // Count the number of read messages
    const totalMessages = yield call(getMessagesCount, groupId);

    const recentMessage = [
      MessageType.Text,
      MessageType.NoteSession,
      MessageType.NoteBible,
      MessageType.NotePersonal,
    ].includes(type)
      ? {
          content,
          sentAt: timestamp,
          sentBy: userId,
          type,
          id,
        }
      : {};

    const groupData = {
      recentMessage,
      totalMessages,
    };

    // @ts-ignore
    groupData[`readMessages.${userId}`] = totalMessages;

    yield call(
      // @ts-ignore
      updateDoc,
      doc(database, groupsCollection, groupId),
      groupData,
      { merge: true }
    );

    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const createMessageSaga = function* () {
  yield takeLatest(createMessage.type, createMessageFn);
};

export function* deleteMessageFn({
  payload: { groupId, messageId, onSuccess = () => {}, onError = () => {} },
}: DeleteMessageAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const messagesCollection = getMessagesCollection(env);
    const groupsCollection = getGroupsCollection(env);

    const data = {
      content: "",
      isDeleted: true,
    };

    yield call(
      // @ts-ignore
      updateDoc,
      doc(database, messagesCollection, groupId, "messages", messageId),
      data,
      { merge: true }
    );
    yield call(asyncLogEvent, events.MESSAGES_DELETE);

    // If the deleted message is also the most recent in the chat, we have to make sure
    // to update it as well accordingly so it doesn't appear in the chat list screen
    const recentMessageId = yield select(getRecentMessageByGroupId, groupId);
    if (recentMessageId === messageId) {
      const userId = yield select(getUserId);
      const sentAt = new Date().getTime();
      yield call(
        // @ts-ignore
        updateDoc,
        doc(database, groupsCollection, groupId),
        {
          recentMessage: {
            ...data,
            id: recentMessageId,
            sentBy: userId,
            sentAt,
          },
        },
        { merge: true }
      );
    }

    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const deleteMessageSaga = function* () {
  yield takeLatest(deleteMessage.type, deleteMessageFn);
};

export function* addMessagesFn({
  payload: {
    groupIds,
    data,
    content,
    type,
    onSuccess = () => {},
    onError = () => {},
  },
}: AddMessagesAction): SagaIterator {
  try {
    yield all(
      groupIds.map((groupId) =>
        put(
          createMessage({
            groupId,
            data,
            content,
            type,
            onSuccess,
            onError,
          })
        )
      )
    );
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const createMessagesSaga = function* () {
  yield takeLatest(createMessages.type, addMessagesFn);
};

export function* createReactionFn({
  payload: { groupId, messageId, reaction },
}: AddReactionAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const messagesCollection = getMessagesCollection(env);
    const userId = yield select(getUserId);

    const messageRef = doc(
      database,
      messagesCollection,
      groupId,
      "messages",
      messageId
    );

    // Use a transaction to add the reaction to the user's array atomically
    yield call(async () => {
      await runTransaction(database, async (transaction) => {
        const messageDoc = await transaction.get(messageRef);

        if (!messageDoc.exists()) {
          throw new Error("Message does not exist");
        }

        const currentReactions = messageDoc.data().reactions || {};

        const userReactions = currentReactions[userId] || [];
        if (!userReactions.includes(reaction)) {
          userReactions.push(reaction);
        }

        // Update the reactions in the document
        transaction.update(messageRef, {
          [`reactions.${userId}`]: userReactions,
        });
      });
    });

    yield call(asyncLogEvent, events.MESSAGES_REACTION_ADDED);
  } catch (e) {
    yield call(handleError, e);
  }
}

export const createReactionSaga = function* () {
  yield takeLatest(createReaction.type, createReactionFn);
};
