import * as React from "react";
import Icon from "@expo/vector-icons/Ionicons";

import { useAppSelector } from "~/state/hooks";
import { getMessageById } from "~/state/chat/selectors";

import { Container, TextBox, SenderName, Text, CancelButton } from "./styles";

interface Props {
  author: string;
  messageId: string;
  onClose: () => void;
}

export const MessageReply = React.memo<Props>(
  ({ author, messageId, onClose }) => {
    const message = useAppSelector((state) => getMessageById(state, messageId));
    if (!message) {
      return null;
    }
    return (
      <Container>
        <TextBox>
          {author ? <SenderName>{author}</SenderName> : null}
          {message.content ? (
            <Text numberOfLines={1}>{message.content}</Text>
          ) : null}
        </TextBox>
        <CancelButton onPress={onClose}>
          <Icon name="close-circle-outline" size={20} />
        </CancelButton>
      </Container>
    );
  }
);
