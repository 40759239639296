import styled from "styled-components/native";

import { Text2, Text4 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

export const Container = styled.View`
  padding: ${spacers.ss8};
`;

export const Title = styled(Text4)`
  margin-bottom: ${spacers.ss6};
  text-align: center;
  font-weight: 600;
`;

export const ShareContainer = styled.View`
  flex-direction: row;
`;

export const IconContainer = styled.View`
  align-items: center;
`;

export const IconBox = styled.View`
  border-radius: 4px;
  margin-bottom: ${spacers.ss4};
  margin-horizontal: ${spacers.ss3};
  overflow: hidden;
`;

export const Text = styled(Text2)`
  border-radius: 4px;
`;

export const ShareSection = styled.View`
  margin-top: ${spacers.ss4};
  min-height: 64px;
`;

export const ShareLinkTitle = styled(Text2)`
  font-family: SFProDisplayBold;
  margin-top: ${spacers.ss4};
  margin-bottom: ${spacers.ss2};
`;

export const ShareLinkBox = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ShareLink = styled(Text2)`
  flex: 1;
`;

export const ShareLinkMeta = styled(Text2)`
  margin-top: ${spacers.ss2};
  color: ${({ theme }) => theme.colors.gray400};
`;
