import styled, { css } from "styled-components/native";
import { Text3 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { pxToNumber } from "~/utils/theme";

export const ScriptureContainer = styled.View<{ hasMargin?: boolean }>`
  flex-direction: row;
  align-items: center;
  padding: ${spacers.ss3} ${spacers.ss5};
  background-color: ${({ theme }) => theme.colors.indigo50};
  ${({ hasMargin }) =>
    isWeb ? `margin-top: ${hasMargin ? spacers.ss7 : spacers.ss4}` : ""}
`;

export const IconBox = styled.View`
  padding-right: ${spacers.ss5};
`;

export const ScriptureText = styled(Text3)<{
  isFirst: boolean;
  suppressHighlighting: boolean;
}>`
  font-family: SFPro;
  padding-top: ${spacers.ss5};
  padding-bottom: ${spacers.ss5};
  color: ${({ theme }) => theme.colors.primaryBlue};
  letter-spacing: -${pxToNumber(spacers.ss1) / 3}px;
  ${({ isFirst }) =>
    isFirst &&
    css`
      padding-left: ${spacers.ss5};
      padding-right: 0;
    `}
`;

export const TextWrapper = styled.Text`
  flex: 1;
  flex-wrap: wrap;
`;
