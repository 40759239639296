import * as React from "react";
import { styled, ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "styled-components/native";
import { merge } from "lodash";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { Screen } from "~/components/screen";
import { HeaderBar } from "~/components/header-bar";
import { getIsDarkMode } from "~/state/settings";
import { themeColors } from "~/dashboard/theme/colors";
import { Screens } from "~/dashboard/constants";

import { Dashboard } from "./dashboard";
import { ChurchesData } from "./churches-data";
import { ChurchType } from "~/state/stats/types";
import { getChurchesData, getStatsHistory } from "~/state/stats";

const AnalyticsRoot = styled("div")(({ theme }) => ({
  margin: "30px",
  marginTop: "0px",
  paddingBottom: "30px",
  paddingTop: "30px",
  fontFamily: "Roboto",
  flex: 1,
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
}));

export const Analytics = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [screen, setScreen] = React.useState(Screens.Dashboard);
  const [churchType, setChurchType] = React.useState(ChurchType.All);

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const scrollViewRef = React.useRef<HTMLDivElement>();

  const isDarkMode = useAppSelector(getIsDarkMode);

  const appTheme = isDarkMode
    ? createTheme(merge({}, themeColors.slateDark1))
    : createTheme(merge({}, themeColors.whiteBlue));

  const onNavigate = (item: Screens) => {
    scrollViewRef?.current?.scrollTo({ top: 0 });
    setScreen(item);
  };

  React.useEffect(() => {
    dispatch(getStatsHistory({}));
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getChurchesData({ onSuccess: () => {}, onError: () => {} }));
  }, [dispatch]);

  if (isLoading) {
    return null;
  }

  return (
    <ThemeProvider theme={appTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Screen
          screenName="analytics"
          backgroundColor={theme.colors.gray50}
          isListView
          isView
        >
          <HeaderBar withBackButton />

          <AnalyticsRoot ref={scrollViewRef}>
            {screen === Screens.Dashboard ? (
              <Dashboard
                onNavigate={onNavigate}
                setChurchType={setChurchType}
              />
            ) : null}
            {screen === Screens.Churches ? (
              <ChurchesData
                type={churchType}
                onBack={() => onNavigate(Screens.Dashboard)}
              />
            ) : null}
          </AnalyticsRoot>
        </Screen>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
