import * as React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useNavigation, useRoute } from "@react-navigation/native";

import { useAppSelector } from "~/state/hooks";
import {
  getGroupById,
  getIsGroupLeaderByGroupId,
} from "~/state/groups/selectors";
import { GroupsStackParamList } from "~/navigation/groups-stack";
import { ResourceEmpty } from "~/components/resource-empty";
import { GroupHeaderBar } from "~/components/group-header-bar";
import { Screen } from "~/components/screen";
import { useTheme } from "styled-components/native";

import { ResourceItem, KidsResourceItem } from "./components/resource-item";
import { Content, Title, ResouceBox, Section } from "./styles";
import { messages } from "./intl";
import { isWeb } from "~/utils/platform";

export type Props = NativeStackScreenProps<
  GroupsStackParamList,
  "group.resources"
>;

interface ComponentProps {
  groupId: string;
}

export const GroupResourcesComponent = ({ groupId }: ComponentProps) => {
  const data = useAppSelector((state) => getGroupById(state, groupId));
  const isLeader = useAppSelector((state) =>
    getIsGroupLeaderByGroupId(state, groupId)
  );
  const navigation = useNavigation();
  const theme = useTheme();

  const { name = "", imageUri, members = [], plans } = data || {};

  React.useEffect(() => {
    if (!data && !isWeb) {
      navigation.goBack();
      return;
    }
  }, [navigation, data]);

  const backgroundColor = isWeb ? theme.colors.gray75 : theme.colors.white;

  return (
    <Screen
      screenName="group-resources"
      isModal
      backgroundColor={backgroundColor}
    >
      {!isWeb ? (
        <GroupHeaderBar
          groupId={groupId}
          uri={imageUri}
          name={name}
          count={members.length}
        />
      ) : null}

      <Content>
        <Section>
          <Title>{messages.title}</Title>

          {plans?.length ? (
            plans.map((planId) => (
              <ResouceBox key={planId}>
                <ResourceItem planId={planId} groupId={groupId} />
              </ResouceBox>
            ))
          ) : (
            <ResourceEmpty isLeader={isLeader} />
          )}
        </Section>
      </Content>
    </Screen>
  );
};

export const GroupResources = ({
  route: { params: { groupId = "" } = {} },
}) => {
  return <GroupResourcesComponent groupId={groupId} />;
};
