import * as React from "react";

import { Container, Item, SectionIcon } from "./styles";

interface Props {
  onPress: (name: number) => void;
}

const sections = [
  { name: "smileys", icon: "mood" },
  { name: "people", icon: "people" },
  { name: "animals", icon: "pets" },
  { name: "food", icon: "fastfood" },
  { name: "activities", icon: "sports-tennis" },
  { name: "travel", icon: "directions-car" },
  { name: "objects", icon: "lightbulb-outline" },
  { name: "symbols", icon: "star-outline" },
  { name: "flags", icon: "outlined-flag" },
];

export const EmojiPickerSection = React.memo<Props>(({ onPress }) => {
  return (
    <Container>
      {sections.map((section, index) => (
        <Item
          isLast={index === sections.length}
          key={section.name}
          onPress={() => onPress(index)}
        >
          <SectionIcon name={section.icon} />
        </Item>
      ))}
    </Container>
  );
});
