import { StyleSheet } from "react-native";
import styled from "styled-components/native";

import { ImageCache } from "~/components/image-cache";
import { Text5 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { hexToRGB } from "~/utils/colors";
import { isWeb } from "~/utils/platform";
import { createShadow } from "~/utils/shadow";
import { pxToNumber } from "~/utils/theme";
import { planSize } from "~/screens/library/components/audiences/styles";

export const PlansContainer = styled.ScrollView.attrs({
  horizontal: true,
  showsHorizontalScrollIndicator: false,
})`
  flex-direction: row;
  padding: ${spacers.ss1} ${spacers.ss6} ${pxToNumber(planSize) / 8}px
    ${spacers.ss6};
`;

export const PlanThumbnail = styled(ImageCache)`
  width: ${planSize};
  height: ${planSize};
  border-radius: ${spacers.ss5};
  margin: 0 ${spacers.ss6} 0 0;
`;

export const PlanThumbnailOverlay = styled.View`
  width: ${planSize};
  height: ${planSize};
  border-radius: ${spacers.ss5};
  position: absolute;
  background-color: ${({ theme }) => theme.colors.gray700};
  opacity: 0.85;
  align-items: center;
  justify-content: center;
`;

export const LockedPlanText = styled(Text5)`
  color: ${({ theme }) => theme.colors.white};
`;

export const PlanShadow = styled.TouchableOpacity`
  border-radius: ${spacers.ss5};
  width: ${planSize};
  margin: 0 ${spacers.ss6} ${spacers.ss6} 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const shadowStyle = {
  ...createShadow({
    color: hexToRGB(colors.black, 0.3),
    opacity: isWeb ? 0.3 : 0.6,
    radius: isWeb ? 16 : 8,
    offsetWidth: 0,
    offsetHeight: 15,
    elevation: 12,
  }),
};

export const skeletonContainerStyle = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
}).container;

export const planContainerSkeletonLayout = [
  {
    width: pxToNumber(planSize),
    height: pxToNumber(planSize),
    marginRight: pxToNumber(spacers.ss6),
    borderRadius: pxToNumber(spacers.ss5),
    position: "absolute",
  },
];
