import styled from "styled-components/native";

import { Text1 } from "~/components/text";
import { spacers } from "~/styles/theme";

export const Container = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${spacers.ss3};
  border-width: 2px;
  border-color: ${({ theme }) => theme.colors.gray50};
  overflow: hidden;
  flex-direction: row;
  border-radius: 12px;
  position: absolute;
  bottom: -12px;
  left: 5px;
`;

export const EmojiBox = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const EmojiText = styled.Text`
  font-size: 12px;
  text-align-vertical: center;
`;

export const Count = styled(Text1)`
  color: ${({ theme }) => theme.colors.gray500};
`;

export const CountBox = styled.View`
  margin-left: ${spacers.ss2};
`;
