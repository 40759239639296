import * as React from "react";
import { GestureResponderEvent } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";

import { Avatar } from "~/components/avatar";
import { IconSizes } from "~/components/icon";
import { TutorialStep } from "~/components/tutorial-step";

import { Container, TextBox, Name, Role, ButtonBox } from "./styles";
import { messages } from "./intl";

interface Props {
  userId: string;
  name: string;
  imageUri: string;
  isLast: boolean;
  isLoading: boolean;
  isLeader: boolean;
  canEdit: boolean;
  onPress: (
    userId: string,
    isLeader: boolean,
    name: string,
    event: GestureResponderEvent
  ) => void;
}

export const MemberItem = React.memo<Props>(
  ({
    userId,
    isLast,
    imageUri,
    name,
    isLeader,
    canEdit,
    onPress,
    isLoading,
  }) => {
    const theme = useTheme();
    const handleOnPress = React.useCallback(
      (event: GestureResponderEvent) => {
        if (isLoading) {
          return;
        }
        onPress(userId, !isLeader, name, event);
      },
      [onPress, userId, name, isLeader, isLoading]
    );

    const role = isLeader ? messages.leader : messages.member;

    return (
      <Container isLast={isLast} isLoading={isLoading}>
        <Avatar uri={imageUri} name={name} />
        <TextBox>
          <Name>{name}</Name>
          <Role>{role}</Role>
        </TextBox>
        {canEdit ? (
          <TutorialStep id="GROUPS.ACTION.MEMBERS.EDIT">
            <ButtonBox onPress={handleOnPress}>
              <Icon
                name="more-horiz"
                size={IconSizes.Medium}
                color={theme.colors.black}
              />
            </ButtonBox>
          </TutorialStep>
        ) : null}
      </Container>
    );
  }
);
