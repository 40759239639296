import React from "react";
import { ActivityIndicator, GestureResponderEvent, View } from "react-native";
import { useTheme } from "styled-components/native";

import { isWeb } from "~/utils/platform";

import { messages } from "./intl";
import {
  ListItem,
  ChevronRightIcon,
  ChevronDownIcon,
  EditLabel,
  LeftIcon,
  ListItemLabel,
  ListContainer,
  ListItemDivider,
  Loader,
  StyledSwitch,
} from "./styles";

interface SettingsListItemProps {
  title: string;
  leftIcon?: string;
  onPress?(event: GestureResponderEvent, position?: DOMRect): void;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  isLoading?: boolean;
  isActive?: boolean;
  isSelected?: boolean;
  type?: "navigate" | "edit" | "select" | "checkbox";
}

export const SettingsListItem: React.FC<SettingsListItemProps> = React.memo(
  ({
    title,
    isLastItem,
    isFirstItem,
    type,
    leftIcon,
    isLoading,
    isActive,
    isSelected = true,
    onPress,
  }) => {
    const triggerRef = React.useRef<View>(null);
    const theme = useTheme();

    const renderRightIcon = () => {
      if (isLoading) {
        return (
          <Loader>
            <ActivityIndicator />
          </Loader>
        );
      }
      switch (type) {
        case "navigate":
          return <ChevronRightIcon />;
        case "edit":
          return <EditLabel>{messages.edit}</EditLabel>;
        case "select":
          return <ChevronDownIcon />;
        case "checkbox":
          return (
            <StyledSwitch
              value={isActive}
              onChange={onPress}
              disabled={isLoading}
            />
          );
        default:
          return null;
      }
    };

    const onPressItem = (data: any) => {
      if (!onPress) {
        return;
      }
      if (type !== "select" || !isWeb) {
        onPress(data);
        return;
      }
      // @ts-ignore
      const position = triggerRef?.current?.getBoundingClientRect();
      if (position) {
        onPress(data, position);
      }
    };

    return (
      <View ref={triggerRef}>
        <ListItem
          right={renderRightIcon}
          left={() => (leftIcon ? <LeftIcon name={leftIcon} /> : null)}
          title={title}
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          onPress={onPressItem}
          leftIcon={leftIcon}
          disabled={isLoading}
          isSelected={isSelected}
          textColor={theme?.colors?.black}
        />
      </View>
    );
  }
);

interface SettingsListItemLabelProps {
  label: string;
}

export const SettingsListItemLabel: React.FC<SettingsListItemLabelProps> =
  React.memo(({ label }) => <ListItemLabel>{label}</ListItemLabel>);

export const SettingsListItemContainer = React.memo(ListContainer);
export const SettingsListItemDivider = React.memo(ListItemDivider);
