import styled, { css } from "styled-components/native";
import { Text2 } from "~/components/text";

import { spacers, colors } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { getWebContainerStyle } from "~/utils/web";

interface Props {
  hasBorder?: boolean;
}

export const containerStyle = {
  paddingBottom: !isWeb ? 300 : 0,
};

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
`;

export const HeaderContainer = styled.View<Props>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-vertical: ${spacers.ss6};
  padding-left: ${spacers.ss6};
  border-bottom-width: 1px;
  border-bottom-color: ${({ hasBorder, theme }) =>
    hasBorder ? theme.colors.gray300 : colors.transparent};
  ${getWebContainerStyle(960)}
`;

export const IconButton = styled.TouchableOpacity`
  padding: ${spacers.ss6};
`;

const listWebStyle = css`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${spacers.ss3};
  overflow: hidden;
`;

export const ListContainer = styled.View`
  ${getWebContainerStyle(960)};
  ${isWeb ? listWebStyle : null}
`;

export const WebContainer = styled.View`
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  flex-direction: row;
  flex: 1;
`;

export const NavigationContainer = styled.View`
  width: 300px;
`;

export const ContentContainer = styled.View`
  flex: 1;
  ${isWeb ? "overflow-y: auto" : ""}
`;

export const WebEmptyContainer = styled.View`
  margin-vertical: ${spacers.ss9};
`;

export const EmptyListText = styled(Text2)`
  margin-horizontal: ${spacers.ss6};
`;
