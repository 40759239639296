import * as React from "react";
import { Breadcrumbs, Box, Icon, styled, useTheme } from "@mui/material";

const BreadcrumbRoot = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
}));

const SubName = styled("span")(({ theme }) => ({
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
}));

type Segment = {
  name: string;
  onClick?: () => void;
};

interface Props {
  routeSegments: Segment[];
}

export const Breadcrumb = ({ routeSegments }: Props) => {
  const theme = useTheme();
  const hint = theme.palette.text.hint;

  return (
    <BreadcrumbRoot>
      <Breadcrumbs
        separator={<Icon sx={{ color: hint }}>navigate_next</Icon>}
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        {routeSegments
          ? routeSegments.map((route, index) => {
              return index !== routeSegments.length - 1 ? (
                <Box
                  onClick={route.onClick}
                  sx={{ cursor: "pointer" }}
                  key={index}
                >
                  <SubName>{route.name}</SubName>
                </Box>
              ) : (
                <SubName key={index}>{route.name}</SubName>
              );
            })
          : null}
      </Breadcrumbs>
    </BreadcrumbRoot>
  );
};
