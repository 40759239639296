export const messages = {
  title: {
    id: "theme.title",
    defaultMessage: "Theme",
  },
  light: {
    id: "theme.light",
    defaultMessage: "Light Theme",
  },
  dark: {
    id: "theme.dark",
    defaultMessage: "Dark Theme",
  },
  default: {
    id: "theme.default",
    defaultMessage: "Default",
  },
};
