import styled from "styled-components/native";
import { StyleSheet } from "react-native";

import { spacers, colors, lineHeights, fontSizes } from "~/styles/theme";
import { pxToNumber } from "~/utils/theme";

export const QuestionBox = styled.View`
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: ${spacers.ss3} ${spacers.ss4};
  margin-bottom: ${spacers.ss5};
  border-radius: 12px;
`;

export const questionTextStyles: StyleSheet.NamedStyles<any> = {
  body: {
    letterSpacing: -pxToNumber(spacers.ss1) / 2,
    paddingHorizontal: pxToNumber(spacers.ss5),
    lineHeight: pxToNumber(lineHeights.lh3),
    fontSize: pxToNumber(fontSizes.fs3),
  },
};
