import * as React from "react";
import { TouchableOpacity } from "react-native";
import {
  Audio,
  InterruptionModeAndroid,
  InterruptionModeIOS,
  ResizeMode,
  Video as ExpoVideo,
  VideoFullscreenUpdate,
} from "expo-av";
import { useTheme } from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";
import * as ScreenOrientation from "expo-screen-orientation";
import type { AVPlaybackStatus, VideoFullscreenUpdateEvent } from "expo-av";

import { isAndroid, isWeb } from "~/utils/platform";
import { IconSizes } from "~/components/icon";
import { GoogleCastButton } from "~/components/cast-button";
import { colors } from "~/styles/theme";
import {
  FlamelinkFile,
  makeDownloadUrl,
  makeMediaUrl,
  MediaSize,
} from "~/state/flamelink";
import { useAppSelector } from "~/state/hooks";
import { getVideoQuality } from "~/state/settings";
import { getInternetSpeedKey, InternetSpeed } from "~/utils/internet-speed";
import { isIOS } from "~/utils/platform";
import {
  GoogleCastBox,
  DebugContainer,
  DebugText,
  ErrorText,
  Thumbnail,
  ThumbnailContainer,
  VideoContainer,
  VideoMask,
  videoStyles,
} from "./styles";
import { getFileUrlAndSpeed } from "./utils";
import { messages } from "./intl";

const VIDEO_DEBUG_ENABLED = false;

interface VideoProps {
  file?: string;
  isDownloaded?: boolean;
  fileData?: FlamelinkFile;
}

export const Video: React.FC<VideoProps> = ({
  file,
  isDownloaded,
  fileData,
}) => {
  const video = React.useRef<ExpoVideo>(null);
  const [maskVisible, setMaskVisible] = React.useState(true);
  const [castButtonVisible, setCastButtonVisible] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState<string | undefined>(undefined);
  const [videoSpeed, setVideoSpeed] = React.useState<
    InternetSpeed | undefined
  >();
  const [thumbnail, setThumbnail] = React.useState<string | null>(null);
  const [error, setError] = React.useState<boolean>(false);
  const videoQuality = useAppSelector(getVideoQuality);
  const videoActionDelay = isIOS ? 100 : 0;
  const theme = useTheme();

  const handleFullscreenUpdate = React.useCallback(async function ({
    fullscreenUpdate,
  }: VideoFullscreenUpdateEvent) {
    if (!isAndroid) {
      return;
    }

    switch (fullscreenUpdate) {
      case VideoFullscreenUpdate.PLAYER_DID_PRESENT:
        await ScreenOrientation.unlockAsync();
        break;
      case VideoFullscreenUpdate.PLAYER_WILL_DISMISS:
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.PORTRAIT
        );
        break;
    }
  },
  []);

  const getVideoUrl = React.useCallback(
    async function () {
      if (isDownloaded) {
        setVideoUrl(file);

        return;
      }

      const { fileUrl, speed: fileSpeed } = await getFileUrlAndSpeed(
        fileData,
        videoQuality
      );

      if (fileUrl) setVideoUrl(makeDownloadUrl(fileUrl));
      if (fileSpeed) setVideoSpeed(fileSpeed);
    },
    [file, fileData, isDownloaded, videoQuality]
  );

  const enableAudio = async () => {
    try {
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
        interruptionModeIOS: InterruptionModeIOS.DoNotMix,
        playsInSilentModeIOS: true,
        staysActiveInBackground: false,
        interruptionModeAndroid: InterruptionModeAndroid.DoNotMix,
        shouldDuckAndroid: false,
      });
    } catch (err) {
      setError(true);
    }
  };

  const loadVideo = React.useCallback(async () => {
    if (!videoUrl) return;

    try {
      video.current?.loadAsync({ uri: videoUrl });
      enableAudio();
    } catch (err) {
      setError(true);
    }
  }, [videoUrl]);

  const loadThumbnail = React.useCallback(async () => {
    const previewUri = fileData?.preview?.file
      ? makeMediaUrl(fileData.preview.file, MediaSize.Small)
      : undefined;

    if (previewUri) setThumbnail(previewUri);
  }, [fileData?.preview?.file]);

  const onLoadThumbnailError = React.useCallback(() => {
    // if we are offline, it is most likely that this will land here.
    // we set the thumbnail as null so we show the fallback mask.
    setThumbnail(null);
  }, []);

  const onPlay = React.useCallback(() => {
    setMaskVisible(false);

    try {
      setTimeout(() => {
        video.current?.playAsync();
      }, videoActionDelay);
    } catch (err) {
      setError(true);
    }
  }, [videoActionDelay]);

  const onRetry = React.useCallback(() => {
    setError(false);
    loadVideo();
    setMaskVisible(true);
  }, [loadVideo]);

  React.useEffect(() => {
    loadVideo();
    loadThumbnail();
    getVideoUrl();
  }, [loadThumbnail, getVideoUrl, loadVideo]);

  React.useEffect(
    () => () => {
      setTimeout(() => {
        video.current?.stopAsync();
      }, videoActionDelay);
    },
    [videoActionDelay]
  );

  const onPlaybackStatusUpdate = React.useCallback(
    (status: AVPlaybackStatus) => {
      if (castButtonVisible && status.isPlaying) {
        setCastButtonVisible(false);
        return;
      }
      if (!castButtonVisible && !status.isPlaying) {
        setCastButtonVisible(true);
        return;
      }
    },
    [castButtonVisible, setCastButtonVisible]
  );

  if (!videoUrl) return null;

  return (
    <VideoContainer>
      {isWeb ? (
        <video style={videoStyles} controls>
          <source src={videoUrl} type="video/mp4" />
        </video>
      ) : (
        <>
          <ExpoVideo
            ref={video}
            style={videoStyles}
            resizeMode={ResizeMode.CONTAIN}
            shouldPlay={false}
            useNativeControls
            onError={() => setError(true)}
            isLooping={true}
            usePoster={false}
            rate={1.0}
            volume={1.0}
            isMuted={false}
            onFullscreenUpdate={handleFullscreenUpdate}
            onPlaybackStatusUpdate={onPlaybackStatusUpdate}
          />

          {maskVisible ? (
            <>
              {thumbnail ? (
                <ThumbnailContainer onPress={onPlay}>
                  <Thumbnail
                    source={{ uri: thumbnail }}
                    onError={onLoadThumbnailError}
                  />
                </ThumbnailContainer>
              ) : (
                <VideoMask>
                  <TouchableOpacity onPress={onPlay}>
                    <MaterialIcons
                      name="play-circle-fill"
                      size={IconSizes.XLarge}
                      color={theme.colors.white}
                    />
                  </TouchableOpacity>
                </VideoMask>
              )}
            </>
          ) : error ? (
            <VideoMask>
              <TouchableOpacity onPress={onRetry}>
                <MaterialIcons
                  name="replay"
                  size={IconSizes.XLarge}
                  color={theme.colors.white}
                />
              </TouchableOpacity>

              <ErrorText>{messages.error}</ErrorText>
            </VideoMask>
          ) : null}

          {castButtonVisible && !isWeb && !maskVisible ? (
            <GoogleCastBox>
              <GoogleCastButton contentUrl={videoUrl} />
            </GoogleCastBox>
          ) : null}
        </>
      )}

      {VIDEO_DEBUG_ENABLED && (
        <DebugContainer>
          <DebugText>
            {videoSpeed
              ? `Speed: ${getInternetSpeedKey(videoSpeed as InternetSpeed)}`
              : ``}
          </DebugText>

          <DebugText>{`Video: ${videoUrl}`}</DebugText>
        </DebugContainer>
      )}
    </VideoContainer>
  );
};
