import styled from "styled-components/native";

import { colors, spacers } from "~/styles/theme";
import { Text2 } from "~/components/text";
import { isAndroid } from "~/utils/platform";

type Props = {
  isActive?: boolean;
  isLast?: boolean;
  isUppercase?: boolean;
  isLoading?: boolean;
};

export const Container = styled.View`
  margin-horizontal: ${spacers.ss6};
  margin-top: ${isAndroid ? spacers.ss9 : spacers.ss8};
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.View`
  width: 100px;
`;

export const Button = styled.TouchableOpacity`
  width: 100px;
`;

export const ButtonText = styled(Text2)<Props>`
  font-family: SFProDisplayMedium;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primaryBlue : theme.colors.gray500};
  letter-spacing: 0.5px;
  ${({ isLast }) => (isLast ? "text-align: right;" : "")}
  ${({ isUppercase }) => (isUppercase ? "text-transform: uppercase;" : "")}
  ${({ isLoading }) => (isLoading ? "opacity: 0.5;" : "")}
`;

export const Title = styled(Text2)`
  font-family: SFProDisplayMedium;
  color: ${({ theme }) => theme.colors.gray700};
  letter-spacing: 0.5px;
`;

export const AccountContainer = styled.View`
  margin-top: ${isAndroid ? spacers.ss9 : spacers.ss6};
  margin-bottom: ${spacers.ss8};
  flex-direction: row;
  justify-content: space-between;
`;
