import styled from "styled-components/native";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";

import { Text3 } from "~/components/text";

import { spacers, colors } from "~/styles/theme";

export const Button = styled.TouchableOpacity`
  flex-direction: row;
  align-self: center;
  padding-bottom: ${spacers.ss7};
`;

export const DeleteIcon = styled(Icon).attrs({
  name: "delete-forever",
  color: colors.red500,
  size: 20,
})``;

export const DeleteText = styled(Text3)`
  color: ${({ theme }) => theme.colors.red500};
`;
