import styled from "styled-components/native";

import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const LinkTitle = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleBox = styled.View`
  padding: ${spacers.ss6};
  margin-top: ${spacers.ss6};
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray100};
  flex: 1;
`;

export const Title = styled(Text2)<{ isLink?: boolean }>``;
