import styled, { css } from "styled-components/native";
import { Text1 } from "~/components/text";

import { spacers } from "~/styles/theme";

const inputStyle = css`
  border-radius: 8px;
  border-width: 1px;
  background-color: ${({ theme }) => theme.colors.gray50};
  margin-left: ${spacers.ss5};
`;

const buttonStyle = css`
  padding: ${spacers.ss5};
`;

export const Section = styled.View`
  flex: 1;
`;

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  padding: ${spacers.ss3};
`;

export const WebInput = styled.View`
  ${inputStyle}
`;

export const Input = styled.View`
  flex: 1;
  ${inputStyle}
`;

export const TextInput = styled.TextInput`
  padding-horizontal: ${spacers.ss4};
  padding-top: 9px;
  padding-bottom: 9px;
  color: ${({ theme }) => theme.colors.black};
`;

export const WebActionButton = styled.TouchableOpacity`
  margin-top: -${spacers.ss6};
  ${buttonStyle}
`;

export const TextWraper = styled.Text`
  text-align: right;
`;

export const MetaText = styled(Text1)`
  color: ${({ theme }) => theme.colors.gray500};
`;

export const MetaTextBold = styled(Text1)`
  font-family: SFProDisplayBold;
  margin-right: 2px;
  color: ${({ theme }) => theme.colors.gray500};
`;
