import * as React from "react";

import { useAppSelector } from "~/state/hooks";
import { getMessageById } from "~/state/chat/selectors";

import { Wrapper, Content, Text, TextWrapper, SenderName } from "./styles";

interface Props {
  replyId?: string;
  isReversed: boolean;
  children: React.ReactNode;
}

export const Reply = React.memo<Props>(
  ({ children, isReversed, replyId = "" }) => {
    const replyMessage = useAppSelector((state) =>
      getMessageById(state, replyId)
    );
    if (!replyMessage) {
      return children;
    }
    const senderName = replyMessage?.sender?.firstName;

    return (
      <Wrapper isReversed={isReversed}>
        <TextWrapper isReversed={isReversed}>
          {senderName ? (
            <SenderName isReversed={isReversed}>{senderName}</SenderName>
          ) : null}
          <Text isReversed={isReversed} numberOfLines={1}>
            {replyMessage.content ?? ""}
          </Text>
        </TextWrapper>

        <Content>{children}</Content>
      </Wrapper>
    );
  }
);

export const ReplyWrapper = React.memo<Props>(
  ({ children, isReversed, replyId }) => {
    if (!replyId) {
      return children;
    }
    return (
      <Reply isReversed={isReversed} replyId={replyId}>
        {children}
      </Reply>
    );
  }
);
