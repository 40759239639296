import { Verse } from "../bible/types";
import { User } from "../user/types";

export type Sender = {
  id: string;
  imageUri: string;
  firstName: string;
  lastName: string;
};

type Callbacks = {
  onSuccess?: () => void;
  onError?: () => void;
};

type Action<T> = {
  type: string;
  payload: T;
  meta: any;
  error: any;
};

export type MessageData = {
  [key: string]: any;
};

export enum MessageType {
  Text = "text",
  Reaction = "reaction",
  Image = "image",
  NewMember = "new-member",
  MemberRemoved = "member-removed",
  NoteBible = "note-bible",
  NoteSession = "note-session",
  NotePersonal = "note-personal",
  GroupRemoved = "group-removed",
}

export type RawMessage = {
  id: string;
  type: MessageType;
  content?: string;
  userId: string;
  data?: MessageData;
  timestamp: number;
};

export type Reactions = {
  [userId: string]: string[];
};

export type Message = {
  id: string;
  timestamp: number;
  userId: string;
  content: string;
  type: MessageType;
  sender: Sender;
  data?: MessageData;
  isDeleted?: boolean;
  replyMessageId?: string;
  reactions?: Reactions;
};

export type MessageWithSender = Message & {
  sender: User;
};

export type UserReactions = Array<{
  reaction: string;
  users: User[];
}>;

export type GroupedReaction = {
  reaction: string;
  user: User;
};

export type ChatState = {
  messages: Message[];
};

export type GroupMessage = {
  id: string;
  senderName: string;
  groupId: string;
  groupName: string;
  groupUri: string;
  timestamp: number;
  text: string;
  isCurrentUser: boolean;
  isRead: boolean;
};

export type MessageContent = {
  content: string;
  data?: MessageData;
  type?: MessageType;
  replyMessageId?: string;
};

export type AddMessagePayload = Callbacks &
  MessageContent & {
    groupId: string;
  };

export type AddMessageAction = Action<AddMessagePayload>;

export type DeleteMessagePayload = Callbacks & {
  groupId: string;
  messageId: string;
};

export type DeleteMessageAction = Action<DeleteMessagePayload>;

export type AddMessagesPayload = Callbacks &
  MessageContent & {
    groupIds: string[];
  };

export type AddReactionPayload = {
  messageId: string;
  groupId: string;
  reaction: string;
};

export type AddMessagesAction = Action<AddMessagesPayload>;
export type AddReactionAction = Action<AddReactionPayload>;
