import * as React from "react";
import { CommonActions, useNavigation } from "@react-navigation/native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";

import * as routes from "~/constants/routes";
import { IconSizes } from "~/components/icon";
import { HomeProps } from "~/components/day-bubbles";
import { navigateTo } from "~/utils/navigation";
import { isWeb } from "~/utils/platform";

import { Container, AnimatedContainer, IconContainer } from "./styles";

interface PlanBackButtonProps {
  backToHome?: boolean;
}

export const PlanBackButton: React.FC<PlanBackButtonProps> = React.memo(
  ({ backToHome }) => {
    const navigation = useNavigation<HomeProps["navigation"]>();
    const theme = useTheme();

    const handleOnPress = React.useCallback(() => {
      if (isWeb) {
        navigation.navigate(routes.home);
      }
      // If we come to the plan screen, from a session that comes from the home tab
      // we need to make sure we navigate back to the home screen
      if (backToHome) {
        navigateTo(navigation, routes.homeTab, { screen: routes.home });

        return;
      }

      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: routes.libraryTab }],
        })
      );
    }, [backToHome, navigation]);

    return (
      <Container>
        <AnimatedContainer>
          <IconContainer onPress={handleOnPress}>
            <Icon
              name="chevron-left"
              size={IconSizes.XLarge}
              color={theme.colors.black}
            />
          </IconContainer>
        </AnimatedContainer>
      </Container>
    );
  }
);
