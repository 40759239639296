import React, { useState } from "react";
import { useTheme } from "styled-components/native";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import "./styles.css";

import { getIsDarkMode } from "~/state/settings/selectors";

import { MenuContext } from "./context.web";
import { useAppSelector } from "../hooks";

interface Props {
  children: React.ReactNode;
}

const MENU_ID = "context-menu";

export const MenuProvider = ({ children }: Props) => {
  const [options, setOptions] = useState<string[]>([]);
  const [style, setStyle] = useState({});
  const [callbackFn, setCallbackFn] = useState<(selectedIndex: number) => void>(
    () => {}
  );
  const theme = useTheme();
  const isDarkMode = useAppSelector(getIsDarkMode);

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const showMenu = (
    opts: string[],
    fn: (selectedIndex: number) => void,
    event: React.MouseEvent,
    position: DOMRect
  ) => {
    const positionProps = position
      ? { position: { x: position.left, y: position.bottom + 8 } }
      : {};

    if (position?.width) {
      setStyle({ width: position.width - 20 });
    }

    setCallbackFn(() => fn);
    setOptions(opts);
    show({
      event,
      ...positionProps,
    });
  };

  const state = {
    showMenu,
  };

  return (
    <MenuContext.Provider value={state}>
      {children}
      <Menu
        id={MENU_ID}
        style={{
          backgroundColor: isDarkMode
            ? theme.colors.gray100
            : theme.colors.white,
        }}
        theme={isDarkMode ? "dark" : "light"}
      >
        {options.map((option, index) => (
          <Item key={index} onClick={() => callbackFn(index)} style={style}>
            {option}
          </Item>
        ))}
      </Menu>
    </MenuContext.Provider>
  );
};
