import * as React from "react";
import { useIsFocused, useRoute } from "@react-navigation/native";
import { View } from "react-native";

import { useTutorial } from "~/state/tutorial";
import { tutorialSteps } from "~/state/tutorial/constants";

interface Props {
  id: string | string[];
  children: React.ReactNode;
  isDark?: boolean;
  positionX?: number;
  positionY?: number;
  style?: Object;
}

export const TutorialStep = React.memo<Props>(
  ({ id, children, isDark, style = {} }) => {
    const route = useRoute();
    const isFocused = useIsFocused();
    const { stepId, setPosition, setIsDark } = useTutorial(
      route.name,
      isFocused
    );

    const ids = Array.isArray(id) ? id : [id];

    const viewRef = React.useRef<View>(null);

    React.useEffect(() => {
      setTimeout(() => {
        if (viewRef?.current) {
          viewRef.current?.measure((x, y, width, height, pageX, pageY) => {
            setPosition({
              x: Math.round(x),
              y: Math.round(y),
              width: Math.round(width),
              height: Math.round(height),
              pageX: Math.round(pageX || 0),
              pageY: Math.round(pageY || 0),
            });
            setIsDark(!!isDark);
          });
        }
      }, 0);
      // eslint-disable-next-line
    }, [stepId, setPosition, setIsDark]);

    if (!ids.includes(stepId || "")) {
      return children;
    }

    const data = tutorialSteps.find((step) => step.id === stepId);

    if (!data) {
      return children;
    }

    return (
      <View ref={viewRef} style={style}>
        {children}
      </View>
    );
  }
);
