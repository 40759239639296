import React from "react";
import { GestureResponderEvent } from "react-native";

import { formatMessage } from "~/utils/translation";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { AppTheme, setAppTheme, getAppTheme } from "~/state/settings";
import { asyncLogEvent, events } from "~/utils/analytics";
import { getOptionText } from "~/utils/options";
import { useActionSheet } from "~/components/action-sheet";
import { genericMessages } from "~/constants/intl";

import { messages } from "./intl";

export const useAppTheme = () => {
  const { showActionSheetWithOptions } = useActionSheet();
  const dispatch = useAppDispatch();
  const appTheme = useAppSelector(getAppTheme);

  const themeOptions = [
    { message: formatMessage(messages.light), type: AppTheme.Light },
    { message: formatMessage(messages.dark), type: AppTheme.Dark },
    { message: formatMessage(messages.default), type: AppTheme.Default },
  ].map(getOptionText<AppTheme>(appTheme));

  const selectAppTheme = (
    event?: GestureResponderEvent,
    position?: DOMRect
  ) => {
    showActionSheetWithOptions(
      {
        title: formatMessage(messages.title),
        options: [...themeOptions, formatMessage(genericMessages.cancel)],
        cancelButtonIndex: 3,
      },
      (selectedOption?: number) => {
        switch (selectedOption) {
          case 0:
            dispatch(setAppTheme(AppTheme.Light));
            asyncLogEvent(events.APP_THEME_CHANGE, {
              appTheme: AppTheme.Light,
            });

            break;
          case 1:
            dispatch(setAppTheme(AppTheme.Dark));
            asyncLogEvent(events.APP_THEME_CHANGE, { appTheme: AppTheme.Dark });

            break;
          case 2:
            dispatch(setAppTheme(AppTheme.Default));
            asyncLogEvent(events.APP_THEME_CHANGE, {
              appTheme: AppTheme.Default,
            });

            break;
        }
      },
      // @ts-ignore
      event,
      position
    );
  };

  const selectedAppTheme = React.useMemo(() => {
    if (appTheme) {
      const translationKey = `${appTheme}` as keyof typeof messages;

      return formatMessage(messages[translationKey]);
    }

    return formatMessage(messages.default);
  }, [appTheme]);

  return { selectAppTheme, selectedAppTheme };
};
