import * as React from "react";
import { GestureResponderEvent, TouchableOpacity } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import {
  MaterialCommunityIcons as Icon,
  Ionicons as IonIcon,
} from "@expo/vector-icons";
import * as FileSystem from "~/utils/file-system";
import { useTheme } from "styled-components/native";

import { IconSizes } from "~/components/icon";
import { DownloadProgressBar } from "~/components/download-progress-bar";
import { colors } from "~/styles/theme";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getFileIcon } from "~/utils/downloads";
import { InternetSpeed, getInternetSpeed } from "~/utils/internet-speed";
import {
  downloadFile,
  FlamelinkMediaFile,
  getFileResourceLocationByFlamelinkFileId,
  getFlamelinkFilesById,
  loadFlamelinkFile,
  makeDownloadUrl,
  openFile,
} from "~/state/flamelink";
import { getVideoQuality, VideoQuality } from "~/state/settings";
import { useVideoQuality } from "~/utils/hooks/use-video-quality";
import { useAlerts } from "~/state/alerts";
import { isWeb } from "~/utils/platform";
import { asyncLogEvent, events } from "~/utils/analytics";

import { Container, Content, Title, IconBox } from "./styles";
import { messages } from "./intl";

interface Props {
  isLast?: boolean;
  sessionId: string;
  audienceId: string;
  isAutoDownload?: boolean;
  fileId: string;
}

export const DownloadItem = React.memo<Props>(
  ({ isLast, fileId, sessionId, audienceId, isAutoDownload }) => {
    const { selectVideoQuality } = useVideoQuality();
    const { pushAlert } = useAlerts();
    const fileData = useAppSelector((state) =>
      getFlamelinkFilesById(state, fileId)
    );

    const icon = getFileIcon(fileData?.file?.contentType);
    const dispatch = useAppDispatch();
    const downloadedResourceLocation = useAppSelector((state) =>
      getFileResourceLocationByFlamelinkFileId(state, fileId)
    );
    const videoQuality = useAppSelector(getVideoQuality);
    const [downloadProgress, setDownloadProgress] = React.useState<number>(0);
    const [internetSpeed, setInternetSpeed] = React.useState(
      InternetSpeed.Normal
    );

    const {
      file,
      fileHD,
      fileUltraHD,
      downloadTitle,
      title: fileTitle,
    } = fileData || {};
    const title = downloadTitle || fileTitle || "";

    const updateDownloadProgress = React.useCallback(
      (progress: FileSystem.DownloadProgressData) => {
        const totalProgress =
          progress.totalBytesWritten / progress.totalBytesExpectedToWrite;
        setDownloadProgress(totalProgress);
      },
      []
    );

    const onDownloadError = React.useCallback(() => {
      pushAlert({
        message: messages.error,
        color: colors.red600,
        duration: 1000,
      });
    }, [pushAlert]);

    const getFileQuality = React.useCallback(
      (quality?: VideoQuality) => {
        // For the auto download choose the file according to the internet speed
        if (!quality) {
          switch (internetSpeed) {
            case InternetSpeed.Excellent:
              return fileUltraHD || fileHD || file;
            case InternetSpeed.Good:
              return fileHD || file;
            default:
              return file || fileHD || fileUltraHD;
          }
        }

        // Always default to base file in case there is no higher resolution available
        switch (quality) {
          case VideoQuality.UltraHigh:
            return fileUltraHD || fileHD || file;
          case VideoQuality.High:
            return fileHD ?? file;
          default:
            return file || fileHD || fileUltraHD;
        }
      },
      [file, fileHD, fileUltraHD, internetSpeed]
    );

    const triggerDownload = React.useCallback(
      (fileToDownload?: FlamelinkMediaFile) => {
        if (!fileToDownload) return;

        asyncLogEvent(events.DOWNLOAD_FILE, {
          ...fileToDownload,
          sessionId,
          audienceId,
        });

        dispatch(
          downloadFile({
            fileId: fileToDownload?.id,
            fileName: fileToDownload.file,
            sessionId,
            onUpdate: updateDownloadProgress,
            onError: onDownloadError,
          })
        );
      },
      [dispatch, onDownloadError, sessionId, audienceId, updateDownloadProgress]
    );

    const openQualityOptions = React.useCallback(
      (event?: GestureResponderEvent) => {
        selectVideoQuality(
          {
            onQualitySet(quality) {
              const fileToDownload = getFileQuality(quality);

              triggerDownload(fileToDownload);
            },
          },
          event
        );
      },
      [getFileQuality, selectVideoQuality, triggerDownload]
    );

    const onDownloadItem = React.useCallback(
      (event?: GestureResponderEvent) => {
        if (file?.contentType?.includes("video") && !videoQuality) {
          openQualityOptions(event);
          return;
        }

        const fileToDownload = getFileQuality(videoQuality);

        triggerDownload(fileToDownload);
      },
      [file, triggerDownload, videoQuality, getFileQuality, openQualityOptions]
    );

    const onAutoDownload = React.useCallback(() => {
      const fileToDownload = getFileQuality(
        videoQuality || VideoQuality.Normal
      );
      triggerDownload(fileToDownload);
    }, [triggerDownload, videoQuality, getFileQuality]);

    const onOpenItem = React.useCallback(() => {
      if (!downloadedResourceLocation || !file) return;

      dispatch(
        openFile({
          downloadedResourceLocation,
          fileType: file.contentType,
          fileName: file.file,
        })
      );
    }, [dispatch, downloadedResourceLocation, file]);

    const shouldRenderSpinner = React.useMemo(() => {
      const isDownloading = downloadProgress > 0 && downloadProgress < 1;

      return isDownloading && !downloadedResourceLocation;
    }, [downloadProgress, downloadedResourceLocation]);

    React.useEffect(() => {
      if (!fileId) return;

      dispatch(loadFlamelinkFile({ fileId }));
    }, [dispatch, fileId]);

    React.useEffect(() => {
      getInternetSpeed().then(setInternetSpeed);
    }, []);

    React.useEffect(() => {
      if (isAutoDownload && !downloadedResourceLocation) {
        onAutoDownload();
      }
    }, [isAutoDownload, onAutoDownload, downloadedResourceLocation]);

    return fileData ? (
      <>
        <Item
          onPress={downloadedResourceLocation ? onOpenItem : onDownloadItem}
          isLast={isLast}
          leftIcon={icon}
          rightIcon={downloadedResourceLocation ? "export-variant" : "download"}
          isLoading={shouldRenderSpinner}
          title={title}
        />

        {!isWeb ? (
          <DownloadProgressBar downloadProgress={downloadProgress} />
        ) : null}
      </>
    ) : null;
  }
);

interface ItemProps {
  onPress: () => void;
  isLast?: boolean;
  title: string;
  rightIcon: string;
  leftIcon: string;
  isLoading?: boolean;
  isBold?: boolean;
}

export const Item = ({
  onPress,
  isLast,
  title,
  leftIcon,
  rightIcon,
  isLoading,
  isBold,
}: ItemProps) => {
  const theme = useTheme();
  const ionIcons = ["file-tray-stacked"];

  return (
    <TouchableOpacity onPress={onPress}>
      <Container isLast={!!isLast}>
        <IconBox>
          {ionIcons.includes(leftIcon) ? (
            <IonIcon name={leftIcon} size={14} color={theme.colors.gray500} />
          ) : (
            <Icon
              name={leftIcon}
              size={IconSizes.Small}
              color={theme.colors.gray500}
            />
          )}
        </IconBox>

        <Content>
          <Title isBold={isBold}>{title}</Title>
        </Content>

        {isLoading ? (
          <ActivityIndicator size={IconSizes.Small} />
        ) : (
          <Icon
            name={rightIcon}
            size={IconSizes.Small}
            color={theme.colors.gray700}
          />
        )}
      </Container>
    </TouchableOpacity>
  );
};
