import styled from "styled-components/native";
import { Animated } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

import { colors } from "~/styles/theme";

export const Container = styled.View`
  height: 6px;
  width: 100%;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.gray200};
  overflow: hidden;
`;

interface BarProps {
  width: number;
  isCompleted?: boolean;
}

export const Bar = styled(LinearGradient).attrs(
  ({ isCompleted }: BarProps) => ({
    colors: isCompleted
      ? [colors.emerald200, colors.emerald500]
      : ["#5357FA", "#7DC0FE"],
    start: { x: 0, y: 1 },
  })
)<BarProps>`
  border-radius: 3px;
  height: 100%;
  width: ${({ width }) => width}%;
`;

export const AnimatedBar = styled(Animated.View)`
  background-color: ${({ theme }) => theme.colors.emerald600};
  border-radius: 3px;
  height: 100%;
`;
