import React from "react";
import { GestureResponderEvent } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";

import { Avatar } from "~/components/avatar";
import { Camera } from "~/components/camera";
import { Alerts } from "~/components/alerts";
import { useAppDispatch, useAppNetInfo, useAppSelector } from "~/state/hooks";
import {
  getProfileImage,
  getUserDisplayName,
  getUserFullName,
} from "~/state/user/selectors";
import { editProfile } from "~/state/user/actions";
import { useAlerts } from "~/state/alerts";
import { ImageProps, useCamera } from "~/utils/hooks/use-camera";
import { colors } from "~/styles/theme";
import { genericMessages } from "~/constants/intl";

import {
  AvatarContainer,
  AvatarPressable,
  CameraBox,
  UserName,
} from "./styles";
import { messages } from "./intl";

interface SettingsAvatarProps {
  allowEdition?: boolean;
}

export const SettingsAvatar: React.FC<SettingsAvatarProps> = ({
  allowEdition,
}) => {
  const [localImageUri, setLocalImageUri] = React.useState<
    string | undefined
  >();
  const [isSaving, setIsSaving] = React.useState(false);
  const profileImage = useAppSelector(getProfileImage);
  const userDisplayName = useAppSelector(getUserDisplayName);
  const userFullName = useAppSelector(getUserFullName);
  const dispatch = useAppDispatch();
  const { isConnected } = useAppNetInfo();
  const { pushAlert } = useAlerts();
  const theme = useTheme();

  const handleImage = React.useCallback(
    ({ uri }: ImageProps) => {
      setIsSaving(true);
      setLocalImageUri(uri);

      dispatch(
        editProfile({
          image: uri,
          onSuccess: () => {
            pushAlert({
              message: messages.imageChangeSuccess,
              color: colors.emerald600,
              duration: 1000,
            });

            setIsSaving(false);
          },
          onError: () => {
            pushAlert({
              message: messages.imageChangeError,
              color: colors.red600,
              duration: 1000,
            });

            setIsSaving(false);
          },
        })
      );
    },
    [dispatch, pushAlert]
  );

  const { onShowOptions, isModalVisible, handleCloseModal } = useCamera({
    size: { width: 250, height: 250 },
    handleImage,
  });

  const onPress = React.useCallback(
    (e: GestureResponderEvent) => {
      if (!isConnected) {
        pushAlert({
          message: genericMessages.featureUnavailableOffline,
          color: colors.gray600,
        });

        return;
      }

      onShowOptions(e);
    },
    [isConnected, onShowOptions, pushAlert]
  );

  return (
    <>
      <AvatarContainer>
        <AvatarPressable
          onPress={allowEdition ? onPress : undefined}
          activeOpacity={allowEdition ? 0.5 : 1}
        >
          <Avatar
            size={90}
            name={userFullName ?? userDisplayName}
            uri={profileImage}
            localUri={localImageUri}
            isSettings
            isSaving={isSaving}
          />

          {allowEdition && (
            <CameraBox>
              <Icon name="camera-alt" color={theme?.colors.black} />
            </CameraBox>
          )}
        </AvatarPressable>

        <UserName>{userFullName ?? userDisplayName}</UserName>

        {isModalVisible ? (
          <Camera handlePicture={handleImage} onClose={handleCloseModal} />
        ) : null}
      </AvatarContainer>

      <Alerts />
    </>
  );
};
