import styled from "styled-components/native";
import { Text2, Text3 } from "~/components/text";
import { TextInput } from "~/components/text-input";
import { colors, spacers } from "~/styles/theme";

export const EditInput = styled(TextInput)`
  margin: 0;
`;

export const InputsContainer = styled.View`
  margin: 0 ${spacers.ss6} 0 ${spacers.ss6};
`;

export const ForgotPasswordLink = styled(Text3)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  align-self: center;
  margin: ${spacers.ss6} 0;
`;

export const HintContainer = styled.View`
  padding: ${spacers.ss6} ${spacers.ss8};
`;

export const HintText = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray500};
`;
