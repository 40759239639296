import styled from "styled-components/native";
import { Text3, Text5, Text6, Text7 } from "~/components/text";
import { colors, lineHeights, spacers } from "~/styles/theme";
import { pxToNumber } from "~/utils/theme";

import { horizontalMargin } from "../../styles";

export const SectionTitle = styled(Text7)`
  font-family: Montserrat;
  padding: ${spacers.ss5} ${spacers.ss7} 0px ${horizontalMargin};
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: -${pxToNumber(spacers.ss1) / 3}px;
`;

export const SubsectionTitle = styled(Text6)`
  font-family: Montserrat;
  padding: ${spacers.ss5} ${horizontalMargin};
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: -${pxToNumber(spacers.ss1) / 3}px;
`;

export const SubsectionSubtitle = styled(Text5)`
  font-family: SFProDisplayMedium;
  margin: ${spacers.ss4} ${spacers.ss8};
`;

export const DayTitle = styled(Text5)`
  font-family: SFPro;
  padding: ${spacers.ss5} ${horizontalMargin};
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: -${pxToNumber(spacers.ss1) / 3}px;
`;

export const Disclaimer = styled(Text3)`
  font-family: SFProDisplayItalic;
  line-height: ${lineHeights.lh5};
  color: ${({ theme }) => theme.colors.amber700};
  margin: ${spacers.ss7} 0 ${horizontalMargin} ${spacers.ss9};
`;
