export const messages = {
  backButton: {
    id: "bible-notes-view.back",
    defaultMessage: "All Bible Notes",
  },
  noteDeleted: {
    id: "bible-notes-view.delete",
    defaultMessage: "Note successfully deleted",
  },
};
