import styled from "styled-components/native";
import Icon from "@expo/vector-icons/MaterialIcons";

import { spacers } from "~/styles/theme";

export const MessageBox = styled.View<{
  hasBottomMargin: boolean;
  isReversed: boolean;
}>`
  flex-direction: row;
  align-items: center;
  justify-content: ${({ isReversed }) =>
    isReversed ? "flex-start" : "flex-end"};
  margin-bottom: ${({ hasBottomMargin }) => (hasBottomMargin ? 10 : 0)}px;
  align-self: flex-start;
`;

export const EmojiBox = styled.TouchableOpacity<{ isReversed: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.gray200};
  ${({ isReversed }) =>
    isReversed
      ? `margin-left: ${spacers.ss4}`
      : `margin-right: ${spacers.ss4}`};
  align-items: center;
  justify-content: center;
`;

export const EmojiIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.gray300,
  size: 18,
  name: "tag-faces",
}))``;
