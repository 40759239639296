import * as React from "react";
import { Text, FlatList } from "react-native";

import { SearchInput } from "~/components/search-input";
import { isWeb } from "~/utils/platform";

import { EmojiPickerSection } from "../emoji-picker-section";

import {
  SectionHeader,
  SectionTitle,
  Item,
  TopSection,
  SearchRow,
  EmojiDescription,
  SearchContainer,
  contentContainerStyle,
} from "./styles";
import { messages } from "./intl";
import { titleMap } from "./constants";
import { emojis } from "./data";
import { Emoji } from "./types";

interface Props {
  searchText: string;
  setSearchText: (text: string) => void;
  onItemPress: (text: string) => void;
}

const ITEM_SIZE = 20;
const COLUMNS_SIZE = 10;

export const PickerContent = React.memo(
  ({ searchText, setSearchText, onItemPress }: Props) => {
    const listRef = React.useRef<FlatList>(null);

    const onSectionPress = React.useCallback((index: number) => {
      listRef?.current?.scrollToIndex({ index, animated: true });
    }, []);

    const searchResults = React.useMemo(() => {
      if (!searchText) {
        return [];
      }
      return emojis
        .reduce((acc: Emoji[], item) => {
          const sectionResults = item.data.filter(
            (emoji) =>
              emoji.keywords
                .join("")
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) > -1
          );
          if (!sectionResults) {
            return acc;
          }
          return [...acc, ...sectionResults];
        }, [])
        .sort((a, b) => a.name.localeCompare(b.name));
    }, [searchText]);

    return (
      <>
        <TopSection>
          <SearchInput
            value={searchText}
            onChange={setSearchText}
            placeholder={messages.search}
          />
          {!searchText ? <EmojiPickerSection onPress={onSectionPress} /> : null}
        </TopSection>

        {!searchText ? (
          <FlatList
            ref={listRef}
            data={emojis}
            contentContainerStyle={{
              paddingHorizontal: 12,
            }}
            removeClippedSubviews={!isWeb}
            renderItem={({ item }) => {
              const titleText = titleMap.get(item.title) || messages.others;
              return (
                <>
                  <SectionHeader>
                    <SectionTitle>{titleText}</SectionTitle>
                  </SectionHeader>
                  <FlatList
                    data={item.data}
                    numColumns={COLUMNS_SIZE}
                    renderItem={({ item: emojiItem }) => {
                      return (
                        <Item
                          size={ITEM_SIZE}
                          onPress={() => onItemPress(emojiItem.emoji)}
                        >
                          <Text>{emojiItem.emoji}</Text>
                        </Item>
                      );
                    }}
                  />
                </>
              );
            }}
          />
        ) : (
          <SearchContainer>
            <SectionHeader>
              <SectionTitle>{messages.searchTitle}</SectionTitle>
            </SectionHeader>
            <FlatList
              data={searchResults}
              contentContainerStyle={contentContainerStyle}
              renderItem={({ item, index }) => {
                if (index > 20) {
                  return null;
                }
                return (
                  <SearchRow
                    key={item.emoji}
                    onPress={() => onItemPress(item.emoji)}
                  >
                    <Text>{item.emoji}</Text>
                    <EmojiDescription>{item.name}</EmojiDescription>
                  </SearchRow>
                );
              }}
            />
          </SearchContainer>
        )}
      </>
    );
  }
);
