import styled from "styled-components/native";
import { StyleSheet } from "react-native";

import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

export const Item = styled.TouchableOpacity<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  align-items: center;
  margin: ${spacers.ss4};
`;

export const Row = styled.View`
  flex-direction: row;
`;

export const TopSection = styled.View`
  margin-horizontal: ${spacers.ss6};
`;

export const EmojiText = styled.Text`
  font-size: 14px;
`;

export const SearchContainer = styled.View`
  padding-horizontal: ${spacers.ss5};
  padding-bottom: ${spacers.ss8};
`;

export const SectionHeader = styled.View`
  padding: ${spacers.ss4} ${spacers.ss4};
`;

export const SectionTitle = styled(Text2)`
  font-family: SFProDisplayMedium;
  color: ${({ theme }) => theme.colors.gray600};
`;

export const SearchRow = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  padding: ${spacers.ss3} ${spacers.ss4};
`;

export const EmojiDescription = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray600};
  margin-left: ${spacers.ss4};
`;

export const webModalContainerStyles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingTop: 12,
    width: 400,
    height: 600,
    alignSelf: "center",
    overflow: "hidden",
  },
}).container;

export const contentContainerStyle = isWeb
  ? {
      height: 490,
    }
  : {};
