import * as React from "react";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useTheme } from "styled-components/native";
import { TutorialStep } from "~/components/tutorial-step";

import {
  Container,
  Button,
  ButtonText,
  IconBox,
  ButtonWrapper,
} from "./styles";
import { colors } from "~/styles/theme";

type Option = {
  message: TextType;
  icon?: string;
  id?: number;
  tutorialStep?: string;
};

interface Props {
  activeIndex: number;
  options: Option[];
  setIndex: (index: number) => void;
}

export const Switch = React.memo<Props>(
  ({ activeIndex, setIndex, options }) => {
    const theme = useTheme();
    const renderContent = (
      index: number,
      isActive: boolean,
      option: Option,
      iconColor: string,
      iconType?: string
    ) => (
      <>
        <Button key={index} isActive={isActive} onPress={() => setIndex(index)}>
          {iconType ? (
            <IconBox>
              <Icon name={iconType} color={iconColor} size={16} />
            </IconBox>
          ) : null}
          <ButtonText isActive={isActive}>{option.message}</ButtonText>
        </Button>
      </>
    );

    return (
      <Container>
        {options.map((option, index) => {
          const isActive = activeIndex === option?.id || activeIndex === index;
          const iconType = option?.icon;
          const iconColor = isActive
            ? theme.colors.white
            : theme.colors.gray600;

          if (!!option?.tutorialStep) {
            return (
              <ButtonWrapper key={`step-wrapper-${index}`}>
                <TutorialStep id={option?.tutorialStep} key={`step-${index}`}>
                  {renderContent(index, isActive, option, iconColor, iconType)}
                </TutorialStep>
              </ButtonWrapper>
            );
          }

          return (
            <ButtonWrapper key={`wrapper-${index}`}>
              {renderContent(index, isActive, option, iconColor, iconType)}
            </ButtonWrapper>
          );
        })}
      </Container>
    );
  }
);
