import { StyleSheet, View } from "react-native";
import styled from "styled-components/native";
import { Text2, Text2Center, Text3, Text3Center } from "~/components/text";
import { fontSizes, lineHeights, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { pxToNumber } from "~/utils/theme";

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
  ${isWeb ? "flex-basis: auto;" : ""}
`;

export const AnswerHeaderContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0 ${spacers.ss4};
  padding-top: ${isWeb ? spacers.ss6 : "0px"};
`;

const AnswerHeaderButton = styled.TouchableOpacity`
  width: 100px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: ${spacers.ss4} ${spacers.ss5};
  border-radius: 42px;
`;

export const AnswerButtonText = styled(Text3Center)`
  font-family: SFPro;
  color: ${({ theme }) => theme.colors.gray800};
  line-height: ${lineHeights.lh3};
`;

export const AnswerDoneButton = styled(AnswerHeaderButton)`
  background-color: ${({ theme }) => theme.colors.gray200};
`;

export const AnswerCancelButton = styled(AnswerHeaderButton)``;

export const AnswerTitle = styled(Text2Center)`
  flex: 1;
  font-family: SFPro;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

export const AnswerDivider = styled(View)`
  border: ${StyleSheet.hairlineWidth}px solid
    ${({ theme }) => theme.colors.gray300};
  margin: ${spacers.ss5} 0;
`;

export const AnswerQuestionContent = styled(Text3)`
  font-family: SFPro;
  color: ${({ theme }) => theme.colors.gray800};
  letter-spacing: -${pxToNumber(spacers.ss1) / 2}px;
  padding: ${spacers.ss3} ${spacers.ss5};
`;

export const AnswerInput = styled.TextInput.attrs(({ theme }) => ({
  placeholderTextColor: theme.colors.gray500,
}))<{ keyboardHeight: number }>`
  min-height: 200px;
  padding: 0 ${spacers.ss5};
  padding-top: ${spacers.ss3};
  padding-bottom: ${({ keyboardHeight = 0 }) => 160 + keyboardHeight}px;
  font-size: ${fontSizes.fs3};
  color: ${({ theme }) => theme.colors.black};
`;

export const LastUpdated = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray500};
  padding: ${spacers.ss1} ${spacers.ss5};
`;
