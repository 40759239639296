import uuid from "react-native-uuid";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  doc,
  getDocs,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  DocumentSnapshot,
  deleteDoc,
} from "firebase/firestore";
import NetInfo from "@react-native-community/netinfo";

import { database } from "<config>/firebase";
import { getEnvironment, getUserId } from "~/state/user/selectors";

import {
  updateNotes,
  addNote,
  deleteNote,
  deleteNotes,
  editNote,
  updateHighlights,
  addEditHighlight,
} from "./slice";
import {
  getBibleNotes,
  addBibleNote,
  deleteBibleNote,
  deleteBibleNotes,
  editBibleNote,
  addBibleHighlight,
  getBibleHighlights,
} from "./actions";
import {
  GetBibleNoteAction,
  AddBibleNoteAction,
  DeleteBibleNoteAction,
  DeleteBibleNotesAction,
  EditBibleNoteAction,
  BibleNote,
  HighlightData,
  AddBibleHighlightAction,
} from "./types";
import {
  getBibleHighlightsCollection,
  getBibleNotesCollection,
} from "~/constants/collections";
import { handleError } from "~/utils/logger";

export function* getBibleNotesFn({
  payload: { onSuccess, onError },
}: GetBibleNoteAction): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    yield call(onSuccess);

    return;
  }

  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }

  const env = yield select(getEnvironment);
  const bibleNotesCollection = getBibleNotesCollection(env);

  try {
    const q = query(
      collection(database, bibleNotesCollection),
      where("userId", "==", userId)
    );

    const notesSnapshots = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const data = notesSnapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as BibleNote[];

    if (data && Array.isArray(data)) {
      yield put(updateNotes(data));
      yield call(onSuccess);
    } else {
      throw new Error();
    }
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* addBibleNoteFn({
  payload: { bookId, chapterId, verses, text, onSuccess, onError },
}: AddBibleNoteAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const bibleNotesCollection = getBibleNotesCollection(env);

    const userId = yield select(getUserId);
    const id = uuid.v4() as string;
    const lastUpdated = new Date().getTime();

    const note = {
      id,
      verses,
      bookId,
      chapterId,
      text,
      lastUpdated,
      userId,
    };

    yield call(
      // @ts-ignore
      setDoc,
      doc(database, bibleNotesCollection, id),
      note
    );

    // Update the local state to avoid an extra Firebase query
    yield put(addNote(note));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* editBibleNoteFn({
  payload: { noteId, text, onSuccess, onError },
}: EditBibleNoteAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const bibleNotesCollection = getBibleNotesCollection(env);

    yield call(
      // @ts-ignore
      updateDoc,
      doc(database, bibleNotesCollection, noteId),
      { text }
    );

    // Update the local state to avoid an extra Firebase query
    yield put(editNote({ noteId, text }));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* deleteBibleNoteFn({
  payload: { noteId, onSuccess, onError },
}: DeleteBibleNoteAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const bibleNotesCollection = getBibleNotesCollection(env);

    yield call(
      // @ts-ignore
      deleteDoc,
      doc(database, bibleNotesCollection, noteId)
    );

    // Update the local state to avoid an extra Firebase query
    yield put(deleteNote(noteId));
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* deleteBibleNotesFn({
  payload: { noteIds, onSuccess, onError },
}: DeleteBibleNotesAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const bibleNotesCollection = getBibleNotesCollection(env);

    for (const noteId of noteIds) {
      yield call(
        // @ts-ignore
        deleteDoc,
        doc(database, bibleNotesCollection, noteId)
      );
    }

    yield put(deleteNotes(noteIds));

    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* getBibleHighlightsFn(): SagaIterator {
  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }
  try {
    const env = yield select(getEnvironment);
    const bibleHighlightsCollection = getBibleHighlightsCollection(env);

    const q = query(
      collection(database, bibleHighlightsCollection),
      where("userId", "==", userId)
    );

    const highlightsSnapshots = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const data = highlightsSnapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as HighlightData[];

    if (data && Array.isArray(data)) {
      yield put(updateHighlights(data));
    } else {
      throw new Error();
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* addBibleHighlightFn({
  payload: { bookId, chapterId, highlights },
}: AddBibleHighlightAction): SagaIterator {
  try {
    const env = yield select(getEnvironment);
    const bibleHighlightsCollection = getBibleHighlightsCollection(env);

    const userId = yield select(getUserId);
    const id = `${userId}${bookId}${chapterId}`;

    const highlight = {
      id,
      highlights,
      bookId,
      chapterId,
      userId,
    };

    yield call(
      // @ts-ignore
      setDoc,
      doc(database, bibleHighlightsCollection, id),
      highlight
    );

    // Update the local state to avoid an extra Firebase query
    yield put(addEditHighlight(highlight));
  } catch (e) {
    yield call(handleError, e);
  }
}

export const addBibleNotesSaga = function* () {
  yield takeLatest(addBibleNote.type, addBibleNoteFn);
};

export const getBibleNotesSaga = function* () {
  yield takeLatest(getBibleNotes.type, getBibleNotesFn);
};

export const editBibleNoteSaga = function* () {
  yield takeLatest(editBibleNote.type, editBibleNoteFn);
};

export const deleteBibleNoteSaga = function* () {
  yield takeLatest(deleteBibleNote.type, deleteBibleNoteFn);
};

export const deleteBibleNotesSaga = function* () {
  yield takeLatest(deleteBibleNotes.type, deleteBibleNotesFn);
};

export const addBibleHighlightSaga = function* () {
  yield takeLatest(addBibleHighlight.type, addBibleHighlightFn);
};

export const getBibleHighlightsSaga = function* () {
  yield takeLatest(getBibleHighlights.type, getBibleHighlightsFn);
};
