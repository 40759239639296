import styled from "styled-components/native";

import { colors, spacers } from "~/styles/theme";
import { Text1 } from "~/components/text";

export const Container = styled.View`
  margin: ${spacers.ss6} ${spacers.ss4};
  padding-bottom: 20px;
`;

export const TextRow = styled.Text`
  letter-spacing: 0.5px;
  font-size: 13px;
`;

export const TextRegular = styled(Text1)``;

export const TextBold = styled(Text1)`
  font-family: SFProDisplayBold;
`;

export const TextLink = styled(Text1)`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

export const TextLinkBold = styled(Text1)`
  font-family: SFProDisplayMedium;
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-transform: uppercase;
`;
