import "./styles.css";
import * as React from "react";
import { ScrollView } from "react-native";

import { BottomSheet as RSBottomSheet } from "react-spring-bottom-sheet";

import "react-spring-bottom-sheet/dist/style.css";

interface Props {
  ref: React.Ref<any>;
  index: number;
  snapPoints: string[];
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const getSnapPoint = (value: string = "", maxHeight: number) => {
  if (typeof value === "string" && value.includes("%")) {
    return (maxHeight * Number(value)) / 100;
  }
  return Number(value);
};

export const BottomSheetModal = ({
  ref,
  index,
  snapPoints,
  children,
  isOpen,
  setIsOpen,
}: Props) => {
  return (
    <RSBottomSheet
      ref={ref}
      open={isOpen}
      defaultSnap={({ maxHeight }) => {
        const value = snapPoints[index] || "";
        return getSnapPoint(value, maxHeight);
      }}
      snapPoints={({ maxHeight }) =>
        snapPoints.map((value) => getSnapPoint(value, maxHeight))
      }
      onDismiss={() => {
        setIsOpen(false);
      }}
    >
      {children}
    </RSBottomSheet>
  );
};

export const BottomSheetModalProvider = ({ children }: any) => <>{children}</>;

type ScrollViewProps = {
  children: React.ReactNode;
  ref: React.Ref<any>;
};

export const BottomSheetScrollView = ({ children, ref }: ScrollViewProps) => {
  return <ScrollView ref={ref}>{children}</ScrollView>;
};

export const BottomSheetBackdrop = () => null;

export const useBottomSheetTimingConfigs = () => {};

export default BottomSheetModal;
