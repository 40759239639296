import styled from "styled-components/native";
import { Text2, Text3 } from "~/components/text";
import { spacers, colors, lineHeights } from "~/styles/theme";

export const Container = styled.View`
  margin: ${spacers.ss6};
  padding: ${spacers.ss6};
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray300};
  background-color: ${({ theme }) => theme.colors.gray50};
  border-radius: ${spacers.ss6};
  margin-bottom: ${spacers.ss6};
  align-items: center;
`;

export const Title = styled(Text2)`
  font-family: MontserratBold;
  margin: ${spacers.ss3} 0;
  color: ${({ theme }) => theme.colors.gray600};
  line-height: ${lineHeights.lh2};
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const ResetButton = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
`;

export const ResetButtonText = styled(Text3)`
  font-family: SFCompact;
  color: ${({ theme }) => theme.colors.red600};
  line-height: ${lineHeights.lh2};
  text-align: center;
`;
