import * as React from "react";
import { Modal, Portal } from "react-native-paper";

import { BottomSheetModal } from "~/components/bottom-sheet";

import { PickerContent } from "./component";
import { webModalContainerStyles } from "./styles";

interface Props {
  messageId: string;
  modalRef: React.Ref<BottomSheetModal>;
  onItemPress: (item: string) => void;
  onReset: () => void;
  dismissModal: () => void;
  isModalVisible: boolean;
}

export const EmojiPicker = React.memo<Props>(
  ({ isModalVisible, dismissModal, onItemPress }) => {
    const [searchText, setSearchText] = React.useState("");

    return (
      <Portal>
        <Modal
          visible={isModalVisible}
          onDismiss={dismissModal}
          contentContainerStyle={webModalContainerStyles}
        >
          <PickerContent
            searchText={searchText}
            setSearchText={setSearchText}
            onItemPress={onItemPress}
          />
        </Modal>
      </Portal>
    );
  }
);
