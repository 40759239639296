import React from "react";
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useNavigation } from "@react-navigation/native";

import { Plan } from "~/components/plan";
import { NoPlansFound } from "~/components/no-plans-found";
import { FeaturedCarousel } from "~/components/featured-carousel";
import { TutorialStep } from "~/components/tutorial-step";
import { AudiencesContent, getFilteredAudiences } from "~/state/flamelink";
import { resetFilters } from "~/state/ui";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import * as routes from "~/constants/routes";
import { formatMessage } from "~/utils/translation";
import { isWeb } from "~/utils/platform";

import { NavigationArrow } from "~/components/day-bubbles/components/navigation-arrow";
import { BubbleDirection } from "~/components/day-bubbles/types";

import { LibraryProps } from "../..";
import { LibraryProgress } from "../library-progress";
import { LibraryHeader } from "../library-header";
import {
  AudienceContainer,
  AudiencesContainer,
  AudienceTitle,
  PlansContainer,
  InnerContainer,
  NavigationBox,
} from "./styles";
import { messages } from "./intl";

const AudienceSection = ({
  audience,
  onPlanPress,
  containerWidth,
}: {
  audience: AudiencesContent;
  containerWidth: number;
  onPlanPress: (id: string) => void;
}) => {
  const scrollViewRef = React.useRef<ScrollView>(null);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [contentWidth, setContentWidth] = React.useState(0);

  const onNavigationPress = (direction: BubbleDirection) => {
    if (direction === BubbleDirection.Next) {
      scrollViewRef?.current?.scrollToEnd();
    }
    if (direction === BubbleDirection.Prev) {
      scrollViewRef?.current?.scrollTo(0);
    }
  };

  const handleContentSizeChange = (width: number) => {
    setContentWidth(width);
  };

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const currentPosition = event.nativeEvent.contentOffset.x;
    setScrollPosition(currentPosition);
  };

  const isScrollable = contentWidth > containerWidth;
  const isAtStart = scrollPosition <= 0;
  const isAtEnd = scrollPosition >= contentWidth - containerWidth;

  return (
    <AudienceContainer>
      <AudienceTitle>{audience.carouselTitle ?? audience.title}</AudienceTitle>

      {isScrollable && !isAtStart ? (
        <NavigationBox>
          <NavigationArrow
            direction={BubbleDirection.Prev}
            onPress={() => onNavigationPress(BubbleDirection.Prev)}
            loading={false}
          />
        </NavigationBox>
      ) : null}

      <PlansContainer
        ref={scrollViewRef}
        scrollEventThrottle={16}
        onScroll={handleScroll}
        onContentSizeChange={handleContentSizeChange}
      >
        {audience.plans.map((plan) => (
          <Plan
            key={`${plan}`}
            planId={plan}
            onPress={() => onPlanPress(plan)}
          />
        ))}
      </PlansContainer>

      {isScrollable && !isAtEnd ? (
        <NavigationBox isRight>
          <NavigationArrow
            direction={BubbleDirection.Next}
            onPress={() => onNavigationPress(BubbleDirection.Next)}
            loading={false}
          />
        </NavigationBox>
      ) : null}
    </AudienceContainer>
  );
};

export function Audiences() {
  const navigation = useNavigation<LibraryProps["navigation"]>();
  const dispatch = useAppDispatch();
  const audiences = useAppSelector(getFilteredAudiences);
  const { bottom } = useSafeAreaInsets();
  const [containerWidth, setContainerWidth] = React.useState(0);

  const handleLayout = (event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  };

  const extraMargin = isWeb ? bottom : bottom + 110;

  const onPlanPress = React.useCallback(
    (planId: string) => navigation.navigate(routes.plan, { planId }),
    [navigation]
  );

  const onResetPress = React.useCallback(
    () => dispatch(resetFilters()),
    [dispatch]
  );

  if (!audiences || !audiences.length) {
    return (
      <NoPlansFound
        resetText={formatMessage(messages.resetFilters)}
        onResetPress={onResetPress}
      />
    );
  }

  const renderSection = (audience: AudiencesContent, index: number) => {
    return (
      <AudienceSection
        audience={audience}
        onPlanPress={onPlanPress}
        containerWidth={containerWidth}
        key={`audience-container-${index}`}
      />
    );
  };

  return (
    <AudiencesContainer extraMargin={extraMargin} hasFixedHeader>
      <InnerContainer onLayout={handleLayout}>
        <LibraryHeader />

        <FeaturedCarousel />
        {audiences.map((audience, i) => {
          if (i === 0) {
            return (
              <TutorialStep id="LIBRARY.AUDIENCE" key="tutorial-step">
                {renderSection(audience, i)}
              </TutorialStep>
            );
          }
          return renderSection(audience, i);
        })}

        <LibraryProgress />
      </InnerContainer>
    </AudiencesContainer>
  );
}
