import { createSelector } from "@reduxjs/toolkit";

import { getUsers } from "~/state/users/selectors";

import { RootState } from "../store";
import {
  Message,
  RawMessage,
  MessageWithSender,
  UserReactions,
  GroupedReaction,
} from "./types";

const getState = (state: RootState) => state.chat;

export const getChatMessages: (state: RootState) => RawMessage[] =
  createSelector(getState, (state) => state.messages || []);

export const getSortedChatMessages: (state: RootState) => RawMessage[] =
  createSelector(getChatMessages, (messages) =>
    [...messages].sort((a, b) => a.timestamp - b.timestamp)
  );

const emptyUser = {
  id: "",
  imageUri: "",
  firstName: "",
  lastName: "",
};

export const getUserChatMessages: (state: RootState) => MessageWithSender[] =
  createSelector([getSortedChatMessages, getUsers], (messages, users) => {
    return messages.map((message) => {
      const sender = users.find(({ id }) => id === message.userId) || emptyUser;
      return { ...message, sender };
    });
  });

export const getMessageById: (
  state: RootState,
  messageId: string
) => MessageWithSender | undefined = createSelector(
  [getUserChatMessages, (_, props) => props],
  (messages, messageId) => {
    if (!messageId) {
      return;
    }
    return messages.find(({ id }) => id === messageId);
  }
);

export const getReactionsByMessageId: (
  state: RootState,
  messageId: string
) => UserReactions = createSelector(
  [getMessageById, getUsers, (_, props) => props],
  (message, users, messageId) => {
    if (!messageId || !message?.reactions) {
      return [];
    }
    const reactions = message.reactions || {};
    const reactionMap: Record<string, string[]> = {};

    Object.entries(reactions).forEach(([userId, userReactions]) => {
      userReactions.forEach((reaction) => {
        if (!reactionMap[reaction]) {
          reactionMap[reaction] = [];
        }
        reactionMap[reaction].push(userId);
      });
    });

    return Object.entries(reactionMap)
      .map(([reaction, userArray]) => ({
        reaction,
        users: userArray.map(
          (id) => users.find((user) => id === user.id) || emptyUser
        ),
      }))
      .sort((a, b) => a.users.length - b.users.length);
  }
);

export const getGroupedReactionsByMessageId: (
  state: RootState,
  messageId: string
) => GroupedReaction[] = createSelector([getReactionsByMessageId], (data) => {
  return data.reduce((acc: GroupedReaction[], item) => {
    const userReactions = item.users.map((user) => ({
      reaction: item.reaction,
      user,
    }));
    return [...acc, ...userReactions];
  }, []);
});
