import * as React from "react";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "styled-components/native";

import { useAppSelector } from "~/state/hooks";
import {
  getDevotionsBySessionId,
  getLessonsBySessionId,
  getSessionById,
  getSessionsBySessionId,
} from "~/state/flamelink/selectors";
import {
  ProgressHierarchyIds,
  ProgressState,
  SessionProgress,
  useAddSessionProgressMutation,
  useResetDayProgressMutation,
  useUpdateSessionProgressMutation,
} from "~/state/content-progress";
import { colors } from "~/styles/theme";
import * as routes from "~/constants/routes";
import { useLibrary } from "~/state/library";
import type { SessionProps } from "~/screens/session/types";
import {
  AccordionListIconContainer,
  AccordionListItem,
  AccordionListItemIcon,
  ActionButtonsContainer,
} from "../../styles";
import { ProgressIcon } from "../progress-icon";
import { PlanProps } from "../..";
import {
  SessionTitle,
  SessionTitleSubWrapper,
  SessionTitleWrapper,
} from "../volume-sessions/styles";
import { getSessionItemsLength } from "~/state/flamelink";
import { getIsDarkMode } from "~/state/settings";

interface SessionItemsProps extends ProgressHierarchyIds {
  sessionProgressData?: SessionProgress;
  sessionIndex: number;
  volumeIndex: number;
}

export const SessionItems: React.FC<SessionItemsProps> = React.memo((props) => {
  const sessionData = useAppSelector((state) =>
    getSessionById(state, props.sessionId)
  );
  const days = useAppSelector((state) =>
    getDevotionsBySessionId(state, props.sessionId)
  );
  const lessons = useAppSelector((state) =>
    getLessonsBySessionId(state, props.sessionId)
  );
  const sessions = useAppSelector((state) =>
    getSessionsBySessionId(state, props.sessionId)
  );

  const day0 = {
    id: sessionData?.volumeHeading,
    title: sessionData?.volumeHeading,
  };

  return (
    <>
      {sessionData?.volumeHeading ? (
        <SessionItem title={day0.title ?? ""} index={0} key="day0" {...props} />
      ) : null}

      {days.map((day, index) => {
        // as we are allocating already day0 separately, we need to add 1 to the index to open and track the correct day
        const key = index + 1;

        return (
          <SessionItem
            title={day.title}
            index={key}
            key={`day${key}`}
            isLast={index === days.length - 1}
            {...props}
          />
        );
      })}

      {lessons.map((lesson, index) => {
        return (
          <SessionItem
            title={lesson.heading}
            index={index}
            lessonId={lesson.id}
            key={`lesson${index}`}
            isLast={index === lessons.length - 1}
            {...props}
          />
        );
      })}

      {sessions.map((session, index) => {
        // as we are allocating already day0 separately, we need to add 1 to the index to open and track the correct day
        const key = index + 1;

        return (
          <SessionItem
            title={session.title}
            index={index}
            childSessionId={session.id}
            key={`session${key}`}
            isLast={index === sessions.length - 1}
            {...props}
          />
        );
      })}
    </>
  );
});

interface SessionItem
  extends Pick<
    SessionItemsProps,
    "planId" | "volumeId" | "sessionId" | "sessionProgressData"
  > {
  lessonId?: string;
  childSessionId?: string;
  index: number;
  title: string;
  sessionIndex: number;
  volumeIndex: number;
  isLast?: boolean;
}

const SessionItem: React.FC<SessionItem> = React.memo(
  ({
    index,
    planId,
    volumeId,
    sessionId,
    lessonId,
    childSessionId,
    sessionProgressData,
    title,
    sessionIndex,
    volumeIndex,
    isLast,
  }) => {
    const navigation = useNavigation<PlanProps["navigation"]>();
    const { planProgressId, setPlanProgressId } = useLibrary();
    const sessionData = useAppSelector((state) =>
      getSessionById(state, sessionId)
    );
    const isDarkMode = useAppSelector(getIsDarkMode);
    const itemsLength = getSessionItemsLength(sessionData);
    const theme = useTheme();

    const [updateSessionProgress, { isLoading: updateLoading }] =
      useUpdateSessionProgressMutation();
    const [addSessionProgress, { isLoading: addLoading }] =
      useAddSessionProgressMutation();
    const [resetDayProgress, { isLoading: resetLoading }] =
      useResetDayProgressMutation();

    const dayKey = `day${index}` as string;
    const { days: daysProgress, sessionProgressId } = sessionProgressData ?? {};

    const dayProgress = daysProgress
      ? daysProgress.find((progress) => progress.key === dayKey)?.state
      : ProgressState.NotStarted;

    const createNewRecord = React.useCallback(async () => {
      const result = await addSessionProgress({
        planId,
        volumeId,
        sessionId,
        dayKey,
        totalDays: itemsLength,
        planProgressId,
      });

      if ("data" in result) {
        setPlanProgressId(result.data);
      }
    }, [
      addSessionProgress,
      planId,
      volumeId,
      sessionId,
      dayKey,
      itemsLength,
      planProgressId,
      setPlanProgressId,
    ]);

    const handleDayUpdate = React.useCallback(
      async (newState: ProgressState) => {
        if (!sessionProgressId) {
          await createNewRecord();

          return;
        }

        await resetDayProgress({ sessionProgressId, dayKey, newState });
      },
      [sessionProgressId, resetDayProgress, dayKey, createNewRecord]
    );

    const getChildDataToOpen = (): Pick<
      SessionProps["route"]["params"],
      | "lessonId"
      | "sessionIndex"
      | "volumeIndex"
      | "dayIndex"
      | "childSessionId"
    > => {
      if (lessonId) {
        return { lessonId, sessionIndex, volumeIndex, dayIndex: 0 };
      }

      if (childSessionId) {
        // the purpose of this is to open the first day of the child session as we will only use the "group" day
        return { childSessionId, sessionIndex, volumeIndex, dayIndex: 0 };
      }

      return { sessionIndex, volumeIndex, dayIndex: index };
    };

    const onPress = async () => {
      navigation.navigate(routes.session, {
        planId,
        sessionId,
        ...getChildDataToOpen(),
      });

      // we create a new record if there is none associated to the selected session
      if (!sessionProgressId) {
        await createNewRecord();
      } else {
        await updateSessionProgress({ dayKey, sessionProgressId });
      }
    };

    const onProgressIconPress = React.useCallback(() => {
      const dayProgressState = dayProgress ?? ProgressState.NotStarted;

      switch (dayProgressState) {
        case ProgressState.NotStarted:
          handleDayUpdate(ProgressState.InProgress);
          break;
        case ProgressState.InProgress:
          handleDayUpdate(ProgressState.Completed);
          break;
        case ProgressState.Completed:
          handleDayUpdate(ProgressState.NotStarted);
          break;
      }
    }, [dayProgress, handleDayUpdate]);

    return (
      <AccordionListItem isLast={!!isLast} isDarkMode={isDarkMode}>
        <SessionTitleWrapper onPress={onPress}>
          <TouchableOpacity
            onPress={onProgressIconPress}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          >
            <ProgressIcon
              state={dayProgress}
              isSession={false}
              isLoading={updateLoading || addLoading || resetLoading}
            />
          </TouchableOpacity>

          <SessionTitleSubWrapper isChild={true}>
            <SessionTitle>{title}</SessionTitle>
          </SessionTitleSubWrapper>
        </SessionTitleWrapper>

        <ActionButtonsContainer>
          <AccordionListIconContainer onPress={onPress}>
            <AccordionListItemIcon color={theme.colors.black} />
          </AccordionListIconContainer>
        </ActionButtonsContainer>
      </AccordionListItem>
    );
  }
);
