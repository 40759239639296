import * as React from "react";
import { FlatList } from "react-native";

import { windowHeight } from "~/utils/platform";
import { toUpperCase } from "~/utils/strings";
import { Avatar } from "~/components/avatar";
import { useAppSelector } from "~/state/hooks";
import {
  getGroupedReactionsByMessageId,
  getReactionsByMessageId,
} from "~/state/chat/selectors";

import {
  Container,
  EmojiText,
  Row,
  Content,
  UserName,
  CountBox,
  CountEmoji,
  CountText,
  TopBar,
  contentContainerStyle,
} from "./styles";
import { messages } from "./intl";

const LINE_HEIGHT = 50;

interface Props {
  messageId: string;
}

export const BoxContent = React.memo(({ messageId }: Props) => {
  const reactions = useAppSelector((state) =>
    getGroupedReactionsByMessageId(state, messageId)
  );
  const totalReactions = useAppSelector((state) =>
    getReactionsByMessageId(state, messageId)
  );

  const countAllReactions = totalReactions.reduce(
    (acc, item) => acc + item.users.length,
    0
  );

  const containerHeight = Math.min(
    windowHeight * 0.5 - 50,
    reactions.length * LINE_HEIGHT + 50
  );

  return (
    <Container height={containerHeight}>
      <TopBar>
        <CountBox isHighlighted>
          <CountText>
            {{ ...messages.all, values: { value: countAllReactions } }}
          </CountText>
        </CountBox>
        <FlatList
          data={totalReactions}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => {
            return (
              <CountBox>
                <CountEmoji>{item.reaction}</CountEmoji>
                <CountText>{item.users.length}</CountText>
              </CountBox>
            );
          }}
          horizontal
        />
      </TopBar>
      <FlatList
        data={reactions}
        contentContainerStyle={contentContainerStyle}
        renderItem={({ item, index }) => {
          const userName = [
            toUpperCase(item.user.firstName || ""),
            toUpperCase(item.user.lastName || ""),
          ].join(" ");
          return (
            <Row isLast={index + 1 === reactions.length}>
              <Avatar uri={item.user.imageUri} name={userName} />
              <Content>
                <UserName>{userName}</UserName>
              </Content>
              <EmojiText>{item.reaction}</EmojiText>
            </Row>
          );
        }}
      />
    </Container>
  );
});
