import styled from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";

import { Text2 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";
import { IconSizes } from "~/components/icon";

interface Props {
  isExpanded: boolean;
}

export const Container = styled.View``;

export const Panel = styled.TouchableOpacity<{ isActive: boolean }>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: ${spacers.ss6};
`;

export const TitleBox = styled.View`
  padding: ${spacers.ss6} 0px;
  margin-right: ${spacers.ss4};
`;

export const Title = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray700};
  font-family: SFProDisplayItalic;
`;

export const PanelWrapper = styled.View`
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray100};
`;

export const AccordionListIcon = styled(MaterialIcons).attrs(
  ({ isExpanded }: Props) => ({
    name: isExpanded ? "arrow-drop-up" : "arrow-drop-down",
    size: IconSizes.SMedium,
  })
)<Props>`
  margin: 0 -${spacers.ss2};
`;
