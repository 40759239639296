export const messages = {
  copy: {
    id: "messages.chat-item.copy",
    defaultMessage: "Copy message",
  },
  copied: {
    id: "messages.chat-item.copied",
    defaultMessage: "Text copied to clipboard",
  },
  delete: {
    id: "messages.chat-item.delete",
    defaultMessage: "Delete message",
  },
  cancel: {
    id: "messages.chat-item.cancel",
    defaultMessage: "Cancel",
  },
  reply: {
    id: "messages.chat-item.reply",
    defaultMessage: "Reply",
  },
};
