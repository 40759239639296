import * as React from "react";
import { Linking } from "react-native";

import { APP_STORE_URL, PLAY_STORE_URL, BUNDLE_ID } from "~/constants";
import { isMobileDevice } from "~/utils/platform";

import {
  Container,
  ScrollContent,
  Content,
  Title,
  BackgroundBox,
  BackgroundImage,
  CenterBox,
  ImageBox,
  Image,
  FooterBox,
  FooterText,
  FooterLink,
  FooterLinkText,
  GetStartedBox,
  GetStartedHeader,
  GetStarted,
  ComingSoon,
} from "./styles";
import { messages } from "./intl";
import { formatMessage } from "~/utils/translation";

export const WebLanding = React.memo(() => {
  const bgImage = require("<assets>/resources/app-grid.png");
  const appleImage = require("<assets>/resources/apple.png");
  const googleImage = require("<assets>/resources/google.png");

  const goToAppStore = React.useCallback(() => {
    Linking.openURL(APP_STORE_URL);
  }, []);

  const goToPlayStore = React.useCallback(() => {
    Linking.openURL(PLAY_STORE_URL);
  }, []);

  const goToApp = React.useCallback(() => {
    Linking.openURL(`${BUNDLE_ID}://`);
  }, []);

  return (
    <Container>
      <ScrollContent>
        <Content>
          <BackgroundBox>
            <BackgroundImage source={bgImage} />
          </BackgroundBox>

          <Title>{formatMessage(messages.title)}</Title>

          <GetStartedBox>
            <GetStartedHeader>
              {formatMessage(messages.getStartedHeader)}
            </GetStartedHeader>
            <GetStarted>{formatMessage(messages.getStarted)}</GetStarted>
          </GetStartedBox>

          <CenterBox>
            <ImageBox onPress={goToAppStore}>
              <Image source={appleImage} />
            </ImageBox>

            <ImageBox onPress={goToPlayStore}>
              <Image source={googleImage} />
            </ImageBox>
          </CenterBox>

          <ComingSoon>{formatMessage(messages.comingSoon)}</ComingSoon>

          {isMobileDevice ? (
            <FooterBox>
              <FooterText>{formatMessage(messages.footerText)}</FooterText>
              <FooterLink onPress={goToApp}>
                <FooterLinkText>
                  {formatMessage(messages.footerLink)}
                </FooterLinkText>
              </FooterLink>
            </FooterBox>
          ) : null}
        </Content>
      </ScrollContent>
    </Container>
  );
});
