import styled from "styled-components/native";
import { Text2, Text4 } from "~/components/text";
import { TextInput } from "~/components/text-input";
import { spacers } from "~/styles/theme";

export const AppFeedbackTitle = styled(Text4)`
  margin: ${spacers.ss6} ${spacers.ss6} 0;
  font-family: MontserratMedium;
`;

export const AppFeedbackDescription = styled(Text2)`
  margin: ${spacers.ss6};
  font-family: Montserrat;
`;

export const StarsContainer = styled.View`
  margin: 0 ${spacers.ss10};
  flex-direction: row;
  justify-content: space-between;
`;

export const ChipsContainer = styled.View`
  margin: 0 ${spacers.ss6} 0;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Separator = styled.View`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray200};
  margin: ${spacers.ss6} ${spacers.ss6} 0;
`;

export const FeedbackContainer = styled.View`
  flex: 1;
  margin: ${spacers.ss8} ${spacers.ss6};
`;

export const FeedbackInput = styled(TextInput).attrs({
  multiline: true,
  numberOfLines: 3,
  label: "Tell us more",
})`
  min-height: 100px;
  background-color: ${({ theme }) => theme.colors.gray100};
  color: ${({ theme }) => theme.colors.black};
`;

export const SubmitButtonContainer = styled.View`
  margin: ${spacers.ss6} 0;
`;
