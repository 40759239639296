import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SvgLogo = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 65"
    {...props}
  >
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 64.483h13.33V61.26H7.85c-1.864 0-3.387-2.122-3.387-4.747V8.083c0-2.434 1.4-4.406 3.142-4.406h5.727V.494H0v63.989Zm50.67 0H64V.494H50.67v3.183h5.577c1.822 0 3.305 2.067 3.305 4.624v48.348c0 2.544-1.483 4.61-3.305 4.61H50.67v3.224Zm-7.672-30.736a9.047 9.047 0 0 1 2.68 2.87c.707 1.183 1.06 2.61 1.06 4.297 0 1.686-.367 3.21-1.115 4.488a9.713 9.713 0 0 1-2.91 3.128c-1.17.802-2.477 1.4-3.946 1.822a16.36 16.36 0 0 1-4.543.626h-17.52v-1.85c.422-.04 1.02-.108 1.823-.217.802-.109 1.373-.245 1.714-.408.544-.245.925-.571 1.142-.98.232-.407.34-.938.34-1.577V19.371c0-.584-.095-1.115-.299-1.564-.204-.448-.598-.816-1.183-1.088a7.286 7.286 0 0 0-1.741-.598c-.64-.136-1.184-.231-1.62-.258v-1.85h17.072c1.32 0 2.584.136 3.795.394 1.21.259 2.353.721 3.455 1.374a7.582 7.582 0 0 1 2.53 2.475c.667 1.047.993 2.312.993 3.808 0 1.306-.231 2.462-.707 3.482a7.34 7.34 0 0 1-1.986 2.611 11.567 11.567 0 0 1-2.748 1.755c-1.034.476-2.149.843-3.346 1.101v.231c1.061.123 2.244.395 3.55.79 1.306.407 2.476.978 3.51 1.713ZM36.7 28.17c.64-.748 1.088-1.564 1.333-2.448.245-.884.38-1.945.38-3.156 0-2.04-.557-3.63-1.659-4.787-1.115-1.156-2.856-1.74-5.25-1.74-.558 0-1.252.013-2.095.04l-.816.027c-.494.017-.93.032-1.306.041v14.226h3.305c1.428 0 2.64-.177 3.646-.544 1.02-.367 1.836-.911 2.462-1.646v-.013Zm3.618 11.818c0-2.19-.734-3.985-2.204-5.386-1.469-1.4-3.563-2.108-6.284-2.108a81.062 81.062 0 0 0-3.958.086c-.223.01-.414.018-.572.023v13.03c0 1.087.45 1.917 1.333 2.474.885.558 2.122.83 3.7.83 2.585 0 4.57-.762 5.945-2.271 1.373-1.51 2.054-3.727 2.054-6.664l-.014-.014Zm-15.86 14.348 2.72 1.959v-4.121h-5.441v4.12l2.72-1.958Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default SvgLogo;
