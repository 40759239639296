import React from "react";
import { TouchableOpacity } from "react-native";
import Animated, {
  FadeInDown,
  LinearTransition,
} from "react-native-reanimated";
import { Ionicons } from "@expo/vector-icons";
import { useTheme } from "styled-components/native";

import { EmojiText, styles } from "./styles";

const EMOJI_DATA = [
  { id: "0", emoji: "❤️" },
  { id: "1", emoji: "👍" },
  { id: "2", emoji: "👎" },
  { id: "3", emoji: "😂" },
  { id: "4", emoji: "😮" },
  { id: "5", emoji: "😢" },
];

const EMOJI_HEIGHT = 22;

interface Props {
  onPress: (icon: string) => void;
  onMorePress: () => void;
}

export const EmojiBar = ({ onPress, onMorePress }: Props) => {
  const theme = useTheme();

  return (
    <Animated.View
      style={[styles.container, { backgroundColor: theme.colors.white }]}
    >
      {EMOJI_DATA.map((emoji, i) => (
        <Animated.View
          layout={LinearTransition}
          entering={FadeInDown.delay(i * 50)}
          key={emoji.id}
        >
          <TouchableOpacity onPress={() => onPress(emoji.emoji)}>
            <EmojiText>{emoji.emoji}</EmojiText>
          </TouchableOpacity>
        </Animated.View>
      ))}
      <Animated.View
        layout={LinearTransition}
        entering={FadeInDown.delay(EMOJI_DATA.length * 50)}
      >
        <TouchableOpacity onPress={onMorePress}>
          <Ionicons
            name="ellipsis-horizontal-circle-sharp"
            size={EMOJI_HEIGHT + 12}
            color={theme.colors.gray300}
          />
        </TouchableOpacity>
      </Animated.View>
    </Animated.View>
  );
};
