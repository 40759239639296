import * as React from "react";
import { Card, Box, Grid, Icon, IconButton, styled } from "@mui/material";
import { H3, Paragraph } from "~/dashboard/components/typography";

import { getCurrentStats } from "~/state/stats/selectors";
import { useAppSelector } from "~/state/hooks";
import { ChurchType } from "~/state/stats/types";
import { formatMessage } from "~/utils/translation";
import { Screens } from "~/dashboard/constants";

import { messages } from "./intl";

const StyledCard = styled(Card)(() => ({
  padding: "20px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}));

type StatItemProps = {
  title: string;
  icon: string;
  value: string;
  grid: number;
  onPress?: () => void;
};

const StatItem = ({ title, icon, value, grid, onPress }: StatItemProps) => {
  const isClickable = typeof onPress === "function";
  const props = isClickable
    ? { onClick: onPress, sx: { cursor: "pointer" } }
    : {};

  return (
    <Grid key={title} item md={grid} xs={12} {...props}>
      <StyledCard sx={{ position: "relative" }} elevation={3}>
        <IconButton sx={{ padding: "12px", background: "rgba(0, 0, 0, 0.01)" }}>
          <Icon sx={{ color: "text.secondary" }}>{icon}</Icon>
        </IconButton>

        <H3 sx={{ mt: "4px", fontSize: 32 }}>{value}</H3>
        <Paragraph sx={{ m: 0, color: "text.secondary" }}>{title}</Paragraph>

        {isClickable ? (
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
            }}
          >
            <Icon sx={{ color: "#7467ef" }}>toc</Icon>
          </Box>
        ) : null}
      </StyledCard>
    </Grid>
  );
};

interface Props {
  onNavigate: (screen: Screens) => void;
  setChurchType: (type: ChurchType) => void;
}

export const StatCards = ({ onNavigate, setChurchType }: Props) => {
  const data = useAppSelector(getCurrentStats);

  const churchData = [
    {
      icon: "church",
      amount: data?.totalChurches,
      title: formatMessage(messages.churches),
      onPress: () => {
        setChurchType(ChurchType.All);
        onNavigate(Screens.Churches);
      },
    },
    {
      icon: "church",
      amount: data?.usChurches,
      title: formatMessage(messages.churchesUs),
      onPress: () => {
        setChurchType(ChurchType.US);
        onNavigate(Screens.Churches);
      },
    },
    {
      icon: "church",
      amount: data?.agChurches ?? 0,
      title: formatMessage(messages.churchesAg),
      onPress: () => {
        setChurchType(ChurchType.AG);
        onNavigate(Screens.Churches);
      },
    },
  ];

  const osData = [
    {
      icon: "apple",
      amount: "TBD",
      title: formatMessage(messages.iosDownloads),
    },
    {
      icon: "android",
      amount: "TBD",
      title: formatMessage(messages.androidDownloads),
    },
  ];

  return (
    <Grid container spacing={3}>
      {churchData.map((item) => {
        return (
          <StatItem
            title={item.title}
            icon={item.icon}
            value={item?.amount?.toLocaleString()}
            onPress={item.onPress}
            grid={4}
          />
        );
      })}
      {osData.map((item) => {
        return (
          <StatItem
            title={item.title}
            icon={item.icon}
            value={item?.amount?.toLocaleString()}
            grid={6}
          />
        );
      })}
    </Grid>
  );
};
