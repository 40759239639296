import styled from "styled-components/native";

import { Text2, Text6 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const Container = styled.View`
  padding-top: ${spacers.ss4};
  padding-horizontal: ${spacers.ss8};
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
`;

export const Title = styled(Text6)`
  margin-bottom: ${spacers.ss7};
  font-family: MontserratMedium;
`;

export const Section = styled.View<{ isLast?: boolean }>`
  margin-bottom: ${({ isLast }) => (isLast ? spacers.ss4 : spacers.ss8)};
`;

export const SectionTitle = styled(Text2)`
  font-family: SFProDisplayMedium;
  margin-bottom: ${spacers.ss5};
  text-transform: uppercase;
  letter-spacing: 1px;
`;
