import * as React from "react";
import Icon from "@expo/vector-icons/MaterialIcons";
import { format } from "date-fns";
import { useTheme } from "styled-components/native";

import { GroupAvatar } from "~/components/group-avatar";
import { IconSizes } from "~/components/icon";

import {
  Container,
  Content,
  Title,
  Description,
  SideBox,
  DescriptionBold,
  RightTextBox,
  RightText,
  Badge,
  BadgeText,
  MetaText,
} from "./styles";
import { useAppSelector } from "~/state/hooks";
import { getUnreadGroupMessagesCount } from "~/state/groups/selectors";
import { getIsDarkMode } from "~/state/settings/selectors";

interface Props {
  title: string;
  description: TextType;
  onPress: () => void;
  uri?: string;
  hasAvatar?: boolean;
  isLast?: boolean;
  isHighlighted?: boolean;
  rightText?: TextType;
  numberOfLines?: number;
  groupId?: string;
  badgeCount?: number;
  lastUpdated?: number;
  isActive?: boolean;
}

export const ListItem = React.memo<Props>(
  ({
    title,
    description,
    onPress,
    uri,
    hasAvatar,
    isLast,
    isHighlighted,
    numberOfLines,
    rightText,
    lastUpdated,
    badgeCount,
    isActive,
  }) => {
    const DescriptionComponent = isHighlighted ? DescriptionBold : Description;
    const theme = useTheme();
    const isDarkMode = useAppSelector(getIsDarkMode);

    return (
      <Container
        onPress={onPress}
        isLast={!!isLast}
        isActive={!!isActive}
        isDarkMode={isDarkMode}
      >
        {hasAvatar ? (
          <SideBox>
            <GroupAvatar text={title} uri={uri} />
          </SideBox>
        ) : null}
        <Content>
          <Title numberOfLines={numberOfLines}>{title}</Title>

          <DescriptionComponent numberOfLines={2}>
            {description}
          </DescriptionComponent>

          {lastUpdated ? (
            <MetaText>{format(lastUpdated, "MM/dd/yy")}</MetaText>
          ) : null}
        </Content>

        {rightText ? (
          <RightTextBox>
            <RightText>{rightText}</RightText>
            {badgeCount ? (
              <Badge>
                <BadgeText>{`${badgeCount}`}</BadgeText>
              </Badge>
            ) : null}
          </RightTextBox>
        ) : (
          <Icon
            name="chevron-right"
            size={IconSizes.Medium}
            color={theme.colors.primaryBlue}
          />
        )}
      </Container>
    );
  }
);

interface GroupProps extends Props {
  groupId: string;
}

export const GroupListItem = React.memo<GroupProps>(({ groupId, ...rest }) => {
  const unreadMessagesCount = useAppSelector((state) =>
    getUnreadGroupMessagesCount(state, groupId)
  );
  return <ListItem {...rest} badgeCount={unreadMessagesCount} />;
});
