import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SvgChurchBig = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 60 60"
    {...props}
  >
    <Path
      fill="currentColor"
      d="M10.782 60h48.28a.938.938 0 1 0 0-1.875h-4.296V46.024l2.2-2.607a.937.937 0 0 0-.294-1.442L41.25 34.19v-7.207l3.04-.76c.013-.003.024-.01.037-.013a.946.946 0 0 0 .17-.069.89.89 0 0 0 .152-.099c.008-.006.016-.01.024-.017.014-.012.024-.026.037-.04a.884.884 0 0 0 .266-.475.91.91 0 0 0 .019-.27v.001a.909.909 0 0 0-.015-.1c-.003-.018-.003-.037-.008-.055-.002-.01-.007-.017-.01-.026a.92.92 0 0 0-.034-.096c-.01-.027-.02-.054-.034-.08-.014-.026-.03-.051-.047-.077a.907.907 0 0 0-.056-.08c-.006-.008-.01-.016-.017-.024L30.926 8.546a.927.927 0 0 0 .012-.109V4.22h1.406a.938.938 0 0 0 0-1.875h-1.407V.937a.938.938 0 0 0-1.875 0v1.407h-1.406a.938.938 0 0 0 0 1.875h1.407v4.218a.949.949 0 0 0 .01.11L15.225 24.701l-.016.025a.939.939 0 0 0-.056.079c-.016.026-.033.05-.047.078-.013.025-.023.052-.034.078a.942.942 0 0 0-.035.097c-.002.009-.007.017-.01.026-.004.018-.004.037-.007.055a.909.909 0 0 0 .058.54v-.001a.893.893 0 0 0 .212.306c.013.012.023.027.037.04l.024.016a.96.96 0 0 0 .218.13.967.967 0 0 0 .104.038c.013.003.024.01.037.013l3.04.76v7.207L3.327 41.975a.937.937 0 0 0-.293 1.442l2.242 2.656a.929.929 0 0 0 .349.256v11.796H.937a.938.938 0 0 0 0 1.875h9.845Zm.937-1.875v-4.219a1.406 1.406 0 1 1 2.813 0v4.219h-2.813Zm5.918-33.354 12.364-14.424 12.363 14.424-1.712.428-9.936-11.742a.968.968 0 0 0-1.43 0L19.35 25.2l-1.713-.428Zm11.426 33.354h-3.75V47.812a4.696 4.696 0 0 1 3.75-4.593v14.906Zm1.875 0V43.219a4.694 4.694 0 0 1 3.75 4.593v10.313h-3.75Zm5.625 0V47.812a6.562 6.562 0 1 0-13.125 0v10.313h-2.812V26.593L30 15.514l9.375 11.08v31.53h-2.813Zm9.102 0v-4.219a1.406 1.406 0 1 1 2.813 0v4.219h-2.813Zm4.688 0v-4.219a3.281 3.281 0 0 0-6.563 0v4.219h-2.54V40.289l11.641 5.678v12.158h-2.538Zm4.416-15.01-1.006 1.191-12.512-6.103v-1.914l13.518 6.825Zm-49.536 0 13.518-6.826v1.914L6.239 44.306l-1.006-1.192ZM7.5 45.776l11.25-5.488v17.836h-2.344v-4.22a3.281 3.281 0 0 0-6.562 0v4.22H7.5V45.777Z"
    />
    <Path
      fill="currentColor"
      d="M30 27.124a3.285 3.285 0 0 0-3.28 3.281v5.156a.937.937 0 0 0 .937.938h4.687a.938.938 0 0 0 .938-.938v-5.156a3.284 3.284 0 0 0-3.281-3.281Zm1.407 7.5h-2.813v-4.219a1.406 1.406 0 1 1 2.813 0v4.219Z"
    />
  </Svg>
);
export default SvgChurchBig;
