import React from "react";
import { useTheme } from "styled-components/native";

import { Screen } from "~/components/screen";
import { RichText } from "~/screens/session/components/content/components/rich-text";
import { HeaderBar } from "~/components/header-bar";

import { content } from "./content";
import { Container } from "./styles";

export const CookiePolicy = () => {
  const theme = useTheme();
  return (
    <Screen screenName="cookies-policy" hasFixedHeader>
      <HeaderBar iconColor={theme.colors.black} withBackButton isSticky />
      <Container>
        <RichText extraMarkdownStyles={{ heading1: { marginTop: 24 } }}>
          {content}
        </RichText>
      </Container>
    </Screen>
  );
};
