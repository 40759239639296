import { List } from "react-native-paper";
import styled from "styled-components/native";
import { fontSizes, spacers } from "~/styles/theme";
import { pxToNumber } from "~/utils/theme";

export const ListSection = styled(List.Section).attrs(({ theme }) => ({
  titleStyle: {
    fontSize: pxToNumber(fontSizes.fs1),
    textTransform: "uppercase",
    letterSpacing: 0.5,
    color: theme.colors.black,
    marginLeft: -pxToNumber(spacers.ss6),
  },
}))`
  margin: ${spacers.ss5};
`;
