import "firebase/compat/firestore";
import "firebase/compat/storage";
import { initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { initializeApp, getApps, getApp, FirebaseApp } from "firebase/app";
import { initializeAuth, browserLocalPersistence } from "firebase/auth";
import { getMessaging, onMessage } from "firebase/messaging";

import { firebaseConfig } from "./config";

const isAppInitialised = !!getApps().length;
export const app = isAppInitialised ? getApp() : initializeApp(firebaseConfig);

const getFirebaseMessaging = (firebaseApp: FirebaseApp) => {
  try {
    return getMessaging(firebaseApp);
  } catch (e) {
    return {};
  }
};

const getFirebaseStorage = () => {
  try {
    return getStorage();
  } catch (e) {
    return {};
  }
};

export const database = initializeFirestore(app, {
  experimentalAutoDetectLongPolling: true,
});
export const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
});
export const storage = getFirebaseStorage();
export const functions = getFunctions();
export const messaging = getFirebaseMessaging(app);

// Use it in the root component if in the future we want to show the foreground notifications
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
