import styled from "styled-components/native";
import MaterialIcon from "@expo/vector-icons/MaterialCommunityIcons";

export const Container = styled.TouchableOpacity`
  position: absolute;
  top: 0px;
  right: 24px;
`;

export const Icon = styled(MaterialIcon).attrs(({ theme }) => ({
  color: theme.colors.gray500,
  size: 20,
  name: "select-compare",
}))``;
