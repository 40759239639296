import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { spacers } from "~/styles/theme";
import { pxToNumber } from "~/utils/theme";
import { isWeb, windowWidth } from "~/utils/platform";
import { Text3 } from "~/components/text";
import { CIRCLE_SIZE } from "../day-bubbles/constants";
import { getWebContainerStyle, MaxWidthBreakpoints } from "~/utils/web";

export const RESOURCE_HEIGHT = 118;

export const ResourcesContainer = styled.View``;

export const WebResourcesContainer = styled.View`
  padding-vertical: ${spacers.ss10};
  ${getWebContainerStyle(MaxWidthBreakpoints.Desktop, false, true)};
  min-height: 100vh;
`;

export const WebResourcesList = styled.View``;

export const EmptyResourcesContainer = styled.View`
  padding-horizontal: ${spacers.ss6};
`;

export const ResourcesTopBar = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${spacers.ss4};
  margin: 0 ${spacers.ss8};
`;

export const ResourcesTopBarTitle = styled(Text3)`
  font-family: Montserrat;
`;

export const carouselStyles = StyleSheet.create({
  carousel: {
    width: "100%",
    marginTop: pxToNumber(spacers.ss2),
    justifyContent: "center",
  },
}).carousel;

export const bubblesSkeletonLayout = [
  {
    height: CIRCLE_SIZE / 1.2,
    width: windowWidth - 2 * pxToNumber(spacers.ss6),
    backgroundColor: "red",
  },
];

export const bubblesSkeletonStyles = StyleSheet.create({
  content: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
    margin: pxToNumber(spacers.ss6),
    height: CIRCLE_SIZE,
  },
});

export const resourcesSkeletonLayout = [
  {
    height: RESOURCE_HEIGHT,
    width: "100%",
  },
];

export const resourcesSkeletonStyles = StyleSheet.create({
  content: {
    margin: pxToNumber(spacers.ss7),
    height: RESOURCE_HEIGHT,
  },
});
