import * as React from "react";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";

import {
  Container,
  InviteText,
  InviteLink,
  Content,
  IconBox,
  ImageBox,
  Image,
  Title,
  Description,
  contentBoxHeight,
} from "./styles";
import { messages } from "./intl";

export const InviteBox = React.memo(() => {
  const bottomSheetModalRef = React.useRef<BottomSheetModal>(null);
  const snapPoints = React.useMemo(() => [`${contentBoxHeight}%`], []);
  const theme = useTheme();

  const handleOpenModal = React.useCallback(() => {
    bottomSheetModalRef.current?.present();
  }, []);

  const handleCloseModal = React.useCallback(() => {
    bottomSheetModalRef.current?.dismiss();
  }, []);

  return (
    <>
      <Container onPress={handleOpenModal}>
        <InviteText>{messages.inviteText}</InviteText>
        <InviteLink>{messages.inviteLink}</InviteLink>
      </Container>

      <BottomSheetModal
        ref={bottomSheetModalRef}
        index={0}
        snapPoints={snapPoints}
        handleIndicatorStyle={{ backgroundColor: theme.colors.black }}
        handleStyle={{
          backgroundColor: theme.colors.white,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <Content>
          <IconBox onPress={handleCloseModal}>
            <Icon name="close" size={20} />
          </IconBox>

          <ImageBox>
            <Image source={require("<assets>/icon.png")} />
          </ImageBox>

          <Title>{messages.title}</Title>
          <Description>{messages.description}</Description>
        </Content>
      </BottomSheetModal>
    </>
  );
});
