import * as React from "react";
import { ThemeProvider } from "styled-components/native";
import { StatusBar } from "expo-status-bar";
import { Provider as PaperProvider } from "react-native-paper";

import { useAppSelector } from "~/state/hooks";
import { getIsDarkMode } from "~/state/settings/selectors";
import { lightPaperTheme, darkPaperTheme } from "~/styles/theme";

import { lightTheme, darkTheme } from "~/styles/theme";

interface Props {
  children: React.ReactNode;
}

export const StyleProvider = ({ children }: Props) => {
  const isDarkTheme = useAppSelector(getIsDarkMode);
  const theme = !isDarkTheme ? lightTheme : darkTheme;
  const defaultPaperTheme = !isDarkTheme ? lightPaperTheme : darkPaperTheme;
  const statusBarStyle = !isDarkTheme ? "dark" : "light";

  return (
    <ThemeProvider theme={theme}>
      <PaperProvider theme={defaultPaperTheme}>
        <StatusBar style={statusBarStyle} />
        {children}
      </PaperProvider>
    </ThemeProvider>
  );
};

export const DefaultStyleProvider = ({ children }: Props) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <PaperProvider theme={lightPaperTheme}>
        <StatusBar style="dark" />
        {children}
      </PaperProvider>
    </ThemeProvider>
  );
};
