import styled from "styled-components/native";

import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { getWebContainerStyle } from "~/utils/web";

interface Props {
  isReversed: boolean;
  isDisabled?: boolean;
}

export const Container = styled.TouchableOpacity``;

export const Wrapper = styled.View<{ isReversed: boolean }>`
  background-color: ${colors.white};
  border-radius: 10px;
  padding-horizontal: ${spacers.ss3};
  margin-bottom: ${spacers.ss3};
`;

export const Content = styled.View``;

export const TextWrapper = styled.View<Props>`
  border-left-width: 3px;
  border-color: ${({ theme }) => theme.colors.primaryBlue};
  background-color: ${colors.gray75};
  margin-bottom: ${({ isReversed }) => (isReversed ? "0px" : spacers.ss4)};
  margin-top: ${spacers.ss5};
  margin-horizontal: ${spacers.ss4};
  padding: ${spacers.ss4};
  border-radius: 2px;
  overflow: hidden;
  ${getWebContainerStyle(580)}
`;

export const Text = styled(Text2)<Props>`
  color: ${colors.gray500};
  letter-spacing: -0.2px;
`;

export const SenderName = styled(Text2)<Props>`
  color: ${({ theme }) => theme.colors.primaryBlue};
  font-family: SFProDisplayBold;
  margin-bottom: 2px;
`;
