import * as appRoutes from "./appRoutes";
import * as webRoutes from "./webRoutes";

const settingsTabs = [
  appRoutes.profile,
  appRoutes.appSettings,
  appRoutes.about,
  appRoutes.payItForward,
  appRoutes.devTools,
];

export const getWebUrl = (
  appRoute: string
): { route: string; options: Record<string, string> } => {
  const routeMap = new Map();

  for (const [key, value] of Object.entries(appRoutes)) {
    const webRoute = (webRoutes as Record<string, string>)[key];
    if (webRoute) {
      routeMap.set(value, { route: webRoute });
    }
    if (settingsTabs.includes(value)) {
      routeMap.set(value, {
        route: webRoutes.settingsModal,
        options: { tab: webRoute },
      });
    }
  }

  return routeMap.get(appRoute);
};
