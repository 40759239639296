import * as React from "react";
import { useTheme } from "styled-components/native";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { SkeletonContent } from "~/components/skeleton-content";

import * as routes from "~/constants/routes";
import { useAppSelector } from "~/state/hooks";
import {
  getPlanById,
  getPlanThumbnail,
  getSessionById,
  getVolumeIndexBySessionId,
} from "~/state/flamelink/selectors";
import { getGroupsWithPlanId } from "~/state/groups/selectors";
import { formatMessage } from "~/utils/translation";
import { GroupAvatar } from "~/components/group-avatar";
import { AvatarSize } from "~/components/group-avatar/types";
import type { HomeProps } from "~/components/day-bubbles";

import {
  Container,
  Content,
  Description,
  Title,
  ResourceCover,
  AvatarContainer,
  AvatarBox,
  imageSkeletonLayout,
  skeletonStyles,
  contentSkeletonLayout,
} from "./styles";
import { messages } from "../../intl";
import { navigateTo } from "~/utils/navigation";

interface Props {
  planId: string;
  sessionId: string;
}

export const ResourceItem = React.memo<Props>(({ planId, sessionId }) => {
  const navigation = useNavigation<HomeProps["navigation"]>();
  const [isLoading, setIsLoading] = React.useState(true);
  const theme = useTheme();

  const planData = useAppSelector((state) => getPlanById(state, planId));
  const planThumbnail = useAppSelector((state) =>
    getPlanThumbnail(state, planId)
  );
  const sessionData = useAppSelector((state) =>
    getSessionById(state, sessionId)
  );
  const volumeIndex = useAppSelector((state) =>
    getVolumeIndexBySessionId(state, sessionId)
  );
  const groups = useAppSelector((state) => getGroupsWithPlanId(state, planId));

  const handleResourcePress = React.useCallback(
    (id: string) => {
      navigateTo(navigation, routes.libraryTab, {
        screen: routes.plan,
        params: { planId: id, backToHome: true },
        initial: false,
      });
    },
    [navigation]
  );

  const onLoad = React.useCallback(() => {
    setIsLoading(false);
  }, []);

  const renderGroups = React.useCallback(() => {
    const maxGroupsToShow = 4;
    const remainingGroupsCount = Math.max(groups.length - maxGroupsToShow, 0);

    const renderedGroups = groups
      .slice(0, maxGroupsToShow)
      .map(({ id, name, imageUri }) => (
        <AvatarBox key={id}>
          <GroupAvatar text={name} uri={imageUri} size={AvatarSize.Small} />
        </AvatarBox>
      ));

    if (remainingGroupsCount > 0) {
      return (
        <>
          {renderedGroups}

          <AvatarBox key={"remaining-groups"}>
            <GroupAvatar count={remainingGroupsCount} size={AvatarSize.Small} />
          </AvatarBox>
        </>
      );
    }

    return renderedGroups;
  }, [groups]);

  return (
    <Container key={`carousel-resource-${planData?.id}`}>
      <SkeletonContent
        isLoading={isLoading}
        containerStyle={{
          ...skeletonStyles.image,
          backgroundColor: theme?.colors?.white,
        }}
        layout={imageSkeletonLayout}
      />

      <TouchableOpacity onPress={() => handleResourcePress(planData?.id ?? "")}>
        {planThumbnail ? (
          <ResourceCover uri={planThumbnail} onLoad={onLoad} />
        ) : null}
      </TouchableOpacity>

      <Content>
        <SkeletonContent
          isLoading={!planData?.title && !sessionData?.title}
          containerStyle={{
            ...skeletonStyles.content,
            backgroundColor: theme?.colors?.white,
          }}
          layout={contentSkeletonLayout}
        >
          {planData?.title ? <Title>{planData?.title}</Title> : null}

          {sessionData?.title ? (
            <Description numberOfLines={3}>
              {formatMessage(messages.resourceDescription, {
                volumeIndex: volumeIndex + 1,
                sessionTitle: sessionData?.title,
              })}
            </Description>
          ) : null}
        </SkeletonContent>

        <AvatarContainer>{renderGroups()}</AvatarContainer>
      </Content>
    </Container>
  );
});
