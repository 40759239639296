import React from "react";
import { Swipeable } from "react-native-gesture-handler";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { useTheme } from "styled-components/native";

import { IconSizes } from "~/components/icon";
import { useAlert } from "~/components/alert";
import { colors } from "~/styles/theme";
import { getFileIcon } from "~/utils/downloads";
import { useAppDispatch } from "~/state/hooks";
import { deleteDownload, DownloadedFile, openFile } from "~/state/flamelink";
import { formatMessage } from "~/utils/translation";

import { bytesToMegabytes } from "../../utils";
import {
  DeleteIcon,
  DownloadedItemContainer,
  DownloadedItemSize,
  DownloadedItemTextWrapper,
  DownloadedItemTitle,
  SwipeableDeleteIconContainer,
  DownloadedItemBox,
} from "./styles";
import { messages } from "./intl";

interface DownloadedItemProps {
  item: DownloadedFile;
  isLast: boolean;
  isActive: boolean;
}

export const DownloadedItem: React.FC<DownloadedItemProps> = ({
  item,
  isLast,
  isActive,
}) => {
  const swipeableRef = React.useRef<Swipeable>(null);
  const dispatch = useAppDispatch();
  const { showAlert } = useAlert();
  const theme = useTheme();

  const onDeletePress = React.useCallback(() => {
    swipeableRef.current?.close();

    showAlert(
      formatMessage(messages.delete),
      formatMessage(messages.deleteConfirmation),
      [
        {
          text: formatMessage(messages.cancel),
          style: "cancel",
        },
        {
          text: formatMessage(messages.delete),
          style: "destructive",
          onPress: () =>
            dispatch(
              deleteDownload({
                fileId: item.id,
                filePath: item.resourceLocation,
                sessionId: item?.sessionId,
              })
            ),
        },
      ]
    );
  }, [dispatch, item.id, item.resourceLocation, item?.sessionId, showAlert]);

  const onOpenItem = React.useCallback(() => {
    if (!item?.resourceLocation || !isActive) return;

    dispatch(
      openFile({
        downloadedResourceLocation: item.resourceLocation,
        fileType: item.contentType || "",
        fileName: item.title || "",
      })
    );
  }, [dispatch, item, isActive]);

  return (
    <Swipeable
      ref={swipeableRef}
      renderRightActions={() => (
        <SwipeableDeleteIconContainer onPress={onDeletePress}>
          <DeleteIcon />
        </SwipeableDeleteIconContainer>
      )}
      overshootRight={false}
    >
      <DownloadedItemContainer
        isLast={isLast}
        isActive={isActive}
        onPress={onOpenItem}
      >
        <DownloadedItemBox>
          <Icon
            name={getFileIcon(item.contentType)}
            size={IconSizes.SMedium}
            color={theme.colors.gray500}
          />

          <DownloadedItemTextWrapper>
            {item?.title && (
              <DownloadedItemTitle>{item.title}</DownloadedItemTitle>
            )}

            <DownloadedItemSize>{`${bytesToMegabytes(
              item.size
            )} MB`}</DownloadedItemSize>
          </DownloadedItemTextWrapper>
        </DownloadedItemBox>
        {isActive ? (
          <Icon
            name="export-variant"
            size={IconSizes.Small}
            color={theme.colors.gray700}
          />
        ) : null}
      </DownloadedItemContainer>
    </Swipeable>
  );
};
