import styled, { css } from "styled-components/native";

import { spacers, colors } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

interface Props {
  hasBorder: boolean;
}

const webStyles = isWeb
  ? css`
      max-width: 960px;
      margin: 0 auto;
      width: 100%;
    `
  : "";

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
`;

export const HeaderContainer = styled.View<Props>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-vertical: ${spacers.ss6};
  padding-left: ${spacers.ss6};
  border-bottom-width: 1px;
  border-bottom-color: ${({ hasBorder, theme }) =>
    hasBorder ? theme.colors.gray300 : colors.transparent};
`;

export const WebContainer = styled.View`
  ${webStyles}
  flex-direction: row;
  flex: 1;
`;

export const NavigationContainer = styled.View`
  width: 300px;
`;

export const ContentContainer = styled.View`
  flex: 1;
`;
