import { StyleSheet } from "react-native";
import styled, { css } from "styled-components/native";
import { ImageBackground } from "expo-image";

import { Text2, Text3, Text4 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

const itemStyle = css`
  height: 200px;
  width: 300px;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray200};
  margin-right: ${spacers.ss6};
`;

export const Container = styled.View`
  margin-vertical: ${spacers.ss6};
  margin-horizontal: ${spacers.ss6};
`;

export const Content = styled.ScrollView``;

export const SectionTitle = styled(Text3)`
  text-transform: uppercase;
  font-family: SFProDisplayMedium;
  letter-spacing: 0.5px;
  margin-bottom: ${spacers.ss6};
`;

export const Item = styled.View`
  ${itemStyle}
`;

export const ImageItem = styled(ImageBackground)`
  ${itemStyle}
`;

export const Text = styled(Text4)`
  font-family: SFProDisplayBold;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${spacers.ss4};
`;

export const ItemButton = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.colors.gray600};
  padding: ${spacers.ss4} ${spacers.ss6};
  border-radius: 20px;
`;

export const ButtonText = styled(Text2)`
  color: ${({ theme }) => theme.colors.white};
  font-family: SFProDisplayMedium;
`;

export const Overlay = styled.Pressable`
  background-color: ${({ theme }) => theme.colors.black};
  ${StyleSheet.absoluteFill};
  opacity: 0.3;
`;

export const NavigationBar = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const NavButton = styled.TouchableOpacity<{ isLast?: boolean }>`
  padding-left: ${spacers.ss4};
  padding-right: ${({ isLast }) => (isLast ? spacers.ss6 : spacers.ss4)};
  align-items: center;
  justify-content: center;
`;
