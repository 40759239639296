import * as React from "react";
import { TouchableOpacity, GestureResponderEvent } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";
import { formatMessage } from "~/utils/translation";
import { genericMessages } from "~/constants/intl";
import { useAlert } from "~/components/alert";
import { useActionSheet } from "~/components/action-sheet";

import { messages } from "./intl";
import { ButtonText } from "./styles";

interface Props {
  onEdit: () => void;
  onDelete?: () => void;
  onSubmit: () => void;
  onShare: () => void;
  isEditMode?: boolean;
}

export const NotesAction = React.memo<Props>(
  ({ onEdit, onDelete, onSubmit, onShare, isEditMode }) => {
    const { showActionSheetWithOptions } = useActionSheet();
    const { showAlert } = useAlert();
    const theme = useTheme();
    const [isEditing, setIsEditing] = React.useState(isEditMode);

    const options = [
      messages.share,
      messages.edit,
      messages.delete,
      messages.cancel,
    ];
    const translatedOptions = options.map((message) => formatMessage(message));

    const handleShare = React.useCallback(() => {
      onShare();
    }, [onShare]);

    const handleEdit = React.useCallback(() => {
      onEdit();
      setIsEditing(true);
    }, [onEdit]);

    const handleDelete = React.useCallback(() => {
      if (typeof onDelete !== "function") {
        return;
      }
      showAlert(
        formatMessage(messages.deleteNote),
        formatMessage(messages.deleteQuestion),
        [
          {
            text: formatMessage(genericMessages.cancel),
            style: "cancel",
          },
          {
            text: formatMessage(messages.delete),
            onPress: onDelete,
          },
        ]
      );
    }, [onDelete, showAlert]);

    const handleSubmit = React.useCallback(() => {
      onSubmit();
      setIsEditing(false);
    }, [onSubmit]);

    const handleOnPress = React.useCallback(
      (event: GestureResponderEvent) => {
        showActionSheetWithOptions(
          {
            options: translatedOptions,
            destructiveButtonIndex: 2,
            cancelButtonIndex: options.length - 1,
          },
          (selectedIndex?: number) => {
            switch (selectedIndex) {
              case 0: {
                handleShare();
                return;
              }
              case 1: {
                handleEdit();
                return;
              }
              case 2: {
                handleDelete();
                return;
              }
            }
          },
          // @ts-ignore
          event
        );
      },
      [
        showActionSheetWithOptions,
        handleDelete,
        handleShare,
        handleEdit,
        translatedOptions,
        options.length,
      ]
    );

    return isEditing ? (
      <TouchableOpacity onPress={handleSubmit}>
        <ButtonText>{messages.done}</ButtonText>
      </TouchableOpacity>
    ) : (
      <TouchableOpacity onPress={handleOnPress}>
        <Icon name="more-horiz" size={24} color={theme.colors.black} />
      </TouchableOpacity>
    );
  }
);
