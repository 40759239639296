import * as React from "react";
import { Pressable } from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useRoute } from "@react-navigation/native";
import { useTheme } from "styled-components/native";

import type { SessionProps } from "~/screens/session/types";
import { IconSizes } from "~/components/icon";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getChecklistById, toggleChecklistItem } from "~/state/checklist";
import { colors } from "~/styles/theme";
import {
  Bullet,
  ChecklistContainer,
  ItemText,
  ListItem,
  ListTitle,
} from "./styles";

interface ChecklistProps {
  title: string;
  content: string;
  checklistId: string;
}

function extractList(text?: string) {
  if (!text) return { list: [] };

  const lines = text.split("\n").filter((line) => line.trim() !== "");

  return { list: lines.map((item) => item.trim()) };
}

export const Checklist: React.FC<ChecklistProps> = ({
  title,
  content,
  checklistId,
}) => {
  const dispatch = useAppDispatch();
  const { sessionId } = useRoute<SessionProps["route"]>().params;
  const checklist = useAppSelector(getChecklistById(sessionId, checklistId));
  const theme = useTheme();

  const { list } = extractList(content);

  const handleToggleItem = React.useCallback(
    (itemId: number) => {
      dispatch(
        toggleChecklistItem({
          parentId: sessionId,
          checklistId,
          itemId,
        })
      );
    },
    [checklistId, dispatch, sessionId]
  );

  const renderItem = React.useCallback(
    (item: string, index: number) => {
      const isChecked = checklist?.items.find(
        (listItem) => listItem.id === index
      )?.checked;

      const checkboxRegex = /\[\s*\]/;
      const bulletRegex = /- /;
      const isBullet = bulletRegex.test(item);
      const isCheckListItem = checkboxRegex.test(item);

      return (
        <ListItem key={index}>
          {isCheckListItem ? (
            <Pressable onPress={() => handleToggleItem(index)}>
              {isChecked ? (
                <Icon
                  name="checkbox-marked"
                  color={colors.green600}
                  size={IconSizes.Medium}
                />
              ) : (
                <Icon
                  name="checkbox-blank-outline"
                  size={IconSizes.Medium}
                  color={theme?.colors.black}
                />
              )}
            </Pressable>
          ) : null}

          {isBullet && !isCheckListItem && <Bullet />}

          <ItemText withMargin={isCheckListItem}>
            {item.replace(bulletRegex, "").replace(checkboxRegex, "")}
          </ItemText>
        </ListItem>
      );
    },
    [checklist?.items, handleToggleItem]
  );

  return (
    <ChecklistContainer>
      <ListTitle>{title}</ListTitle>

      {list.map(renderItem)}
    </ChecklistContainer>
  );
};
