import * as React from "react";
import { Text } from "react-native";
import { format } from "date-fns";

import { getScriptureText } from "~/utils/bible";
import {
  getVerseNumber,
  getVerseNumbers,
} from "~/screens/notes-bible-view/utils";
import { Verse } from "~/state/bible/types";
import { getIsDarkMode } from "~/state/settings/selectors";
import { useAppSelector } from "~/state/hooks";

import {
  Container,
  Header,
  HeaderTitle,
  HeaderText,
  TextBold,
  DateText,
  VerseBox,
  VerseBar,
  VerseNumber,
  VerseText,
  Content,
  NoteText,
} from "./styles";
import { messages } from "./intl";

interface Props {
  bookId: string;
  chapterId: string;
  timestamp: number;
  verses: Verse[];
  text: string;
  onPress: (verseId: string) => void;
}

export const NoteItem = ({
  bookId,
  chapterId,
  verses,
  text,
  timestamp,
  onPress,
}: Props) => {
  const isDarkMode = useAppSelector(getIsDarkMode);
  const verseNumbers = getVerseNumbers(verses, bookId);
  const scripture = getScriptureText(bookId, chapterId, verseNumbers);

  return (
    <Container onPress={onPress} isDarkMode={isDarkMode}>
      <Header>
        <HeaderTitle>
          <HeaderText>{messages.header}</HeaderText>
          <TextBold>{scripture}</TextBold>
        </HeaderTitle>

        <DateText>{format(timestamp, "MM/dd/yyyy")}</DateText>
      </Header>
      <VerseBox>
        <VerseBar />
        <Text numberOfLines={3}>
          {verses.map(({ verseId, content }, index) => {
            const id = getVerseNumber(verseId, bookId);
            const numberText = index === 0 ? `${id} ` : ` ${id} `;
            return [
              <VerseNumber key={id}>{numberText}</VerseNumber>,
              <VerseText key={`verse-${id}`}>{content}</VerseText>,
            ];
          })}
        </Text>
      </VerseBox>
      <Content>
        <NoteText numberOfLines={3}>{text}</NoteText>
      </Content>
    </Container>
  );
};
