import * as React from "react";
import { Grid, useTheme } from "@mui/material";
import Chart from "react-apexcharts";

import { H2, H3, Paragraph } from "~/dashboard/components/typography";
import { useAppSelector } from "~/state/hooks";
import { getAppSessionsData } from "~/state/stats/selectors";
import { formatSeconds } from "~/state/stats/utils";
import { getIsDarkMode } from "~/state/settings";
import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";

export const AppSessions = () => {
  const sessionsData = useAppSelector(getAppSessionsData);
  const isDarkMode = useAppSelector(getIsDarkMode);

  const labels = sessionsData?.dailyEngagementTime?.map(({ label }) => label);
  const values = sessionsData?.dailyEngagementTime?.map(({ value }) => value);

  const series = [
    {
      name: formatMessage(messages.avgEngagement),
      data: values,
    },
  ];
  const { palette } = useTheme();
  const textColor = palette.text.primary;

  return (
    <Grid container sx={{ m: 2 }}>
      <Grid container md={12} spacing={3}>
        <Grid item md={6}>
          <H3 sx={{ color: textColor }}>
            {formatMessage(messages.engagedPerUser)}
          </H3>
          <H2 sx={{ color: textColor }}>{sessionsData.engagedPerUser}</H2>
        </Grid>
        <Grid item md={6}>
          <H3 sx={{ color: textColor }}>
            {formatMessage(messages.avgEngagement)}
          </H3>
          <H2 sx={{ color: textColor }}>{sessionsData.avgEngagementTime}</H2>
        </Grid>
      </Grid>

      <div style={{ width: "100%" }}>
        <Chart
          options={{
            theme: {
              mode: isDarkMode ? "dark" : "light",
              palette: "palette3",
            },
            chart: {
              toolbar: {
                show: false,
              },
            },
            xaxis: {
              categories: labels,
            },
            series,
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              y: {
                formatter: (value: number) => formatSeconds(value * 60),
              },
            },
          }}
          series={series}
          type="line"
          height={380}
        />
      </div>

      <Paragraph sx={{ color: "text.secondary" }}>
        {formatMessage(messages.engagementDisclaimer)}
      </Paragraph>
    </Grid>
  );
};
