import { createSelector } from "@reduxjs/toolkit";
import { Appearance } from "react-native";

import type { RootState } from "../store";
import { initialState } from "./slice";
import { FontSize, VideoQuality, AppTheme } from "./types";

const getState = (state: RootState) => state.settings;

export const getLanguagesToDisplay: (state: RootState) => string[] =
  createSelector(
    getState,
    (state) => state.languagesToDisplay || initialState.languagesToDisplay
  );

export const getVideoQuality: (state: RootState) => VideoQuality | undefined =
  createSelector(
    getState,
    (state) => state?.videoQuality || initialState.videoQuality
  );

export const getFontSize: (state: RootState) => FontSize | undefined =
  createSelector(getState, (state) => state.fontSize || initialState.fontSize);

export const getAppTheme: (state: RootState) => AppTheme = createSelector(
  getState,
  (state) => state.appTheme || initialState.appTheme
);

export const getIsDarkMode: (state: RootState) => boolean = createSelector(
  getAppTheme,
  (theme) => {
    return (
      theme === AppTheme.Dark ||
      (theme === AppTheme.Default && Appearance.getColorScheme() === "dark")
    );
  }
);

export const getAppLanguage: (state: RootState) => string | undefined =
  createSelector(
    getState,
    (state) => state.appLanguage || initialState.appLanguage
  );
