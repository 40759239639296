import * as React from "react";
import { Modal, Portal } from "react-native-paper";

import { BottomSheetModal } from "~/components/bottom-sheet";

import { BoxContent } from "./content";
import { webModalContainerStyles } from "./styles";

interface Props {
  messageId: string;
  modalRef: React.Ref<BottomSheetModal>;
  onReset: () => void;
  dismissModal: () => void;
  isModalVisible: boolean;
}

export const ReactionsBox = React.memo<Props>(
  ({ messageId, onReset, isModalVisible, dismissModal }) => {
    React.useEffect(() => {
      if (!messageId) {
        onReset();
      }
    }, [messageId, onReset]);

    return (
      <Portal>
        <Modal
          visible={isModalVisible}
          onDismiss={dismissModal}
          contentContainerStyle={webModalContainerStyles}
        >
          <BoxContent messageId={messageId} />
        </Modal>
      </Portal>
    );
  }
);
