import styled from "styled-components/native";
import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingVertical: 5,
    paddingHorizontal: 10,
    gap: 8,
    borderRadius: 200,
    width: 254,
    alignItems: "center",
  },
});

export const EmojiText = styled.Text`
  font-size: 22px;
  text-align-vertical: center;
`;
