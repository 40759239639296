import * as React from "react";
import { TouchableOpacity } from "react-native";
import { useTheme } from "styled-components/native";

import { useAppSelector } from "~/state/hooks";
import { getIsDarkMode } from "~/state/settings";

import type { ChipProps } from "./types";
import { ChipsContainer, ChipLabel, ChipCheck } from "./styles";

export const Chip = React.memo<ChipProps>(
  ({ label, value, onPress, selected }) => {
    const handleOnPress = React.useCallback(() => {
      onPress(value);
    }, [onPress, value]);
    const isDarkMode = useAppSelector(getIsDarkMode);
    const theme = useTheme();

    return (
      <TouchableOpacity onPress={handleOnPress}>
        <ChipsContainer
          selected={selected}
          colors={
            selected
              ? ["#FEFFD7", "#FFE792"]
              : [theme.colors.white, theme.colors.white]
          }
        >
          {selected && <ChipCheck isDarkMode={isDarkMode} />}

          <ChipLabel selected={selected} isDarkMode={isDarkMode}>
            {label}
          </ChipLabel>
        </ChipsContainer>
      </TouchableOpacity>
    );
  }
);
