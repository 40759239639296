export const documentDirectory = "/root";

export const EncodingType = {
  UTF8: "utf8",
  Base64: "base64",
};

export const getInfoAsync = async (filePath: string) => {
  try {
    const fileInfo = await fetch(filePath);
    if (!fileInfo.ok) {
      throw new Error(`Failed to get file info: ${fileInfo.statusText}`);
    }
    const contentLength = fileInfo.headers.get("content-length") || "0";
    return { exists: true, isDirectory: false, size: parseInt(contentLength) };
  } catch (error) {
    return { exists: false, isDirectory: false, size: 0 };
  }
};

export const getContentUriAsync = async (filePath: string) => {
  throw new Error("Content URIs are not supported in web environment");
};

export const readAsStringAsync = async (filePath: string) => {
  try {
    const response = await fetch(filePath);
    if (!response.ok) {
      throw new Error(`Failed to read file as string: ${response.statusText}`);
    }
    return await response.text();
  } catch (error) {
    throw new Error(`Failed to read file as string: ${error}`);
  }
};

export const writeAsStringAsync = async (filePath: string, content: string) => {
  try {
    const response = await fetch(filePath, {
      method: "PUT",
      body: content,
    });
    if (!response.ok) {
      throw new Error(`Failed to write string to file: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error(`Failed to write string to file: ${error}`);
  }
};

export const makeDirectoryAsync = async () => {
  throw new Error("Creating directories is not supported in web environment");
};

export const deleteAsync = async (filePath: string) => {
  try {
    const response = await fetch(filePath, {
      method: "DELETE",
    });
    if (!response.ok) {
      throw new Error(
        `Failed to delete file/directory: ${response.statusText}`
      );
    }
  } catch (error) {
    throw new Error(`Failed to delete file/directory: ${error}`);
  }
};

export const createDownloadResumable = () => {
  throw new Error("Creating directories is not supported in web environment");
};

export type DownloadProgressData = {
  totalBytesWritten: number;
  totalBytesExpectedToWrite: number;
};

export const moveAsync = () => {};

export const readDirectoryAsync = () => {};
