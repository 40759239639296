import * as React from "react";
import { useTheme } from "styled-components/native";
import { useIsFocused, useRoute } from "@react-navigation/native";

import { HeaderBar } from "~/components/header-bar";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { LibraryStackParamList } from "~/navigation/library-stack";
import { useAppSelector } from "~/state/hooks";
import { getTutorialStep } from "~/state/tutorial/selectors";
import { useTutorial } from "~/state/tutorial";

import { Audiences } from "./components/audiences";
import { Container } from "./styles";

export type LibraryProps = NativeStackScreenProps<
  LibraryStackParamList,
  "library"
>;

export const Library: React.FC<LibraryProps> = () => {
  const route = useRoute();
  const isFocused = useIsFocused();
  const { showTutorial } = useTutorial(route.name, isFocused);
  const tutorialStep = useAppSelector((state) =>
    getTutorialStep(state, "library")
  );
  const theme = useTheme();

  React.useEffect(() => {
    showTutorial(tutorialStep);
  }, [showTutorial, tutorialStep]);

  return (
    <Container>
      <HeaderBar
        iconColor={theme?.colors.black}
        withBackButton={false}
        hasShadow={false}
        isSticky
      />

      <Audiences />
    </Container>
  );
};
