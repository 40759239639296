import styled from "styled-components/native";

import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const Container = styled.Pressable`
  padding: ${spacers.ss3} ${spacers.ss5} 0px;
`;

export const Text = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray500};
`;
