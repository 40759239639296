export const messages = {
  engagedPerUser: {
    id: "stats.engaged-per-user",
    defaultMessage: "Engaged sessions per user",
  },
  avgEngagement: {
    id: "stats.avg-engagement",
    defaultMessage: "Avg engagement time per active user",
  },
  users: {
    id: "stats.users",
    defaultMessage: "Users",
  },
  profiles: {
    id: "stats.profiles",
    defaultMessage: "Profiles",
  },
  groups: {
    id: "stats.groups",
    defaultMessage: "Groups",
  },
  membersPerGroup: {
    id: "stats.members-per-group",
    defaultMessage: "Members per group",
  },
  milestones: {
    id: "stats.milestones",
    defaultMessage: "Milestones",
  },
  goal: {
    id: "stats.goal",
    defaultMessage: "Close to reach {{value}}k users!",
  },
  overview: {
    id: "stats.overview",
    defaultMessage: "Overview",
  },
  statistics: {
    id: "stats.statistics",
    defaultMessage: "Statistics",
  },
  lastUpdated: {
    id: "stats.last-updated",
    defaultMessage: "Last Updated: {{value}}",
  },
  downloads: {
    id: "stats.downloads",
    defaultMessage: "Monthly downloads",
  },
  plan: {
    id: "stats.plans",
    defaultMessage: "Plans",
  },
  completed: {
    id: "stats.completed",
    defaultMessage: "Completed",
  },
  completedSome: {
    id: "stats.completed-some",
    defaultMessage: "Completed Some",
  },
  completedAll: {
    id: "stats.completed-all",
    defaultMessage: "Completed All",
  },
  started: {
    id: "stats.started",
    defaultMessage: "Started",
  },
  churches: {
    id: "stats.churches",
    defaultMessage: "Churches (worldwide)",
  },
  churchesUs: {
    id: "stats.churches-us",
    defaultMessage: "Churches (US)",
  },
  churchesAg: {
    id: "stats.churches-ag",
    defaultMessage: "AG Churches",
  },
  iosDownloads: {
    id: "stats.ios-downloads",
    defaultMessage: "iOS Downloads",
  },
  androidDownloads: {
    id: "stats.android-downloads",
    defaultMessage: "Android Downloads",
  },
  totalDownloads: {
    id: "stats.total-downloads",
    defaultMessage: "Total downloads",
  },
  activeUsers: {
    id: "stats.active-users",
    defaultMessage: "Active Users (30 days)",
  },
  impact: {
    id: "stats.impact",
    defaultMessage: "Impact",
  },
  sessionDisclaimer: {
    id: "stats.session-disclaimer",
    defaultMessage:
      "Note: Session progress data are being collected at the end of each month",
  },
  engagementDisclaimer: {
    id: "stats.engagement-disclaimer",
    defaultMessage:
      "Note: The data collection is most likely to be delayed 24-48h",
  },
  churchName: {
    id: "stats.church-name",
    defaultMessage: "Church Name",
  },
  churchState: {
    id: "stats.church-state",
    defaultMessage: "State",
  },
  churchZipCode: {
    id: "stats.church-zip",
    defaultMessage: "Zip Code",
  },
  churchCountry: {
    id: "stats.church-country",
    defaultMessage: "Country",
  },
  agChurch: {
    id: "stats.church-ag",
    defaultMessage: "AG Church",
  },
  userCount: {
    id: "stats.church-users",
    defaultMessage: "Users count",
  },
};
