import * as React from "react";
import { TextInputProps } from "react-native";
import { TextInputMask } from "react-native-masked-text";
import { useTheme } from "styled-components/native";

import { formatMessage, isIntlDescriptor } from "~/utils/translation";
import { IconTypes } from "~/components/icon";
import { MessageDescriptor } from "~/types/messages";

import {
  Container,
  Input,
  IconButton,
  StyledIcon,
  ErrorMessage,
  HintMessage,
  MaskedInputContainer,
  Overlay,
  InputBox,
  StyledTextInput,
  RightIcon,
  inputStyles,
  SecondaryBox,
  EditText,
  NativeTextInput,
} from "./styles";
import { messages } from "./intl";

type Props = TextInputProps & {
  label?: TextType;
  placeholder?: TextType;
  value: string;
  onChangeText: (arg0: string) => void;
  mask?: string;
  maskType?: string;
  maskOptions?: Record<string, string>;
  isSecure?: boolean;
  errorMessage?: MessageDescriptor;
  hintMessage?: MessageDescriptor;
  onOverlayPress?: (event?: React.PointerEvent) => void;
  isSecondary?: boolean;
  showEdit?: boolean;
  iconType?: string;
};

export const TextInput = React.memo(
  ({
    label,
    isSecure,
    secureTextEntry,
    errorMessage,
    hintMessage,
    onOverlayPress,
    mask,
    maskType,
    maskOptions = {},
    isSecondary,
    placeholder,
    iconType,
    ...rest
  }: Props) => {
    const [passwordVisibility, setPasswordVisibility] =
      React.useState(secureTextEntry);
    const formattedLabel = isIntlDescriptor(label)
      ? formatMessage(label as MessageDescriptorValues)
      : label;
    const formattedPlaceholder =
      placeholder && isIntlDescriptor(placeholder)
        ? formatMessage(placeholder as MessageDescriptorValues)
        : placeholder;
    const passwordIcon = passwordVisibility
      ? IconTypes.EyeNotVisible
      : IconTypes.EyeOpen;
    const theme = useTheme();

    const onIconPress = () => {
      setPasswordVisibility(!passwordVisibility);
    };

    const optionalProps = mask
      ? {
          render: (props: any) => (
            <MaskedInputContainer>
              <TextInputMask
                {...props}
                style={inputStyles}
                type={maskType || "custom"}
                options={{ mask, ...maskOptions }}
              />
            </MaskedInputContainer>
          ),
        }
      : {};

    return (
      <>
        <Container>
          {!isSecondary ? (
            <Input
              label={formattedLabel as string}
              secureTextEntry={passwordVisibility}
              error={!!errorMessage}
              underlineColor={theme?.colors.black}
              theme={{
                colors: {
                  text: theme?.colors.black,
                  placeholder: theme?.colors.gray600,
                },
              }}
              {...optionalProps}
              {...rest}
            />
          ) : (
            <InputBox>
              <StyledTextInput
                label={formattedLabel as string}
                secureTextEntry={passwordVisibility}
                error={!!errorMessage}
                placeholderTextColor={theme?.colors.gray500}
                style={{ color: theme?.colors.black }}
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                placeholder={formattedPlaceholder}
                {...optionalProps}
                {...rest}
              />
            </InputBox>
          )}

          {secureTextEntry ? (
            <IconButton onPress={onIconPress}>
              <StyledIcon
                type={passwordIcon}
                size={20}
                isActive={!passwordVisibility}
              />
            </IconButton>
          ) : null}

          {iconType ? <RightIcon name={iconType} size={20} /> : null}
          {onOverlayPress ? <Overlay onPress={onOverlayPress} /> : null}
        </Container>
        {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
        {hintMessage && !errorMessage ? (
          <HintMessage>{hintMessage}</HintMessage>
        ) : null}
      </>
    );
  }
);

export const BoxedTextInput = React.memo<Props>(
  ({ showEdit, onOverlayPress, ...props }) => {
    return (
      <SecondaryBox>
        <NativeTextInput {...props} />
        {showEdit ? <EditText>{messages.edit}</EditText> : null}
        {onOverlayPress ? <Overlay onPress={onOverlayPress} /> : null}
      </SecondaryBox>
    );
  }
);
