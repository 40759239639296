export enum Usage {
  SmallGroups = "small-groups",
  SundaySchool = "sunday-school",
  PersonalDevos = "personal-devotions",
  FamilyDevos = "family-devotions",
  KidsMinistry = "kids-ministry",
  YouthMinistry = "youth-ministry",
}

export enum Roles {
  LeadPastor = "lead-pastor",
  AssociatePastor = "associate-pastor",
  ChildrensPastor = "childrens-pastor",
  YouthPastor = "youth-pastor",
  SmallGroupLeader = "small-group-leader",
  Attendee = "attendee",
  Other = "other",
}

export enum Attendance {
  Weekly = "weekly",
  Monthly = "few-times-month",
  Yearly = "few-times-year",
  NotRegularly = "not-regularly",
}

export interface UserState {
  user: User | null;
  profile: Profile | null;
  emailVerified: boolean;
  environment: Environment;
  firstLoginDate: Date | null;
  hasUserBeenPromptedForFeedback: boolean;
}

export enum Environment {
  Staging = "stg",
  Production = "prd",
}

export enum AppRoles {
  Regular = "regular",
  Admin = "admin",
  Editor = "editor",
}

export enum AccountType {
  Individual = "individual",
  Church = "church",
}

export type Callbacks = {
  onSuccess: () => void;
  onError: (error?: string) => void;
};

export type OptionalCallbacks = {
  onSuccess?: () => void;
  onError?: (error?: string) => void;
};

type Action<T> = {
  type: string;
  payload: T;
  meta: any;
  error: any;
};

export enum AuthModes {
  ResetPassword = "resetPassword",
  VerifyEmail = "verifyEmail",
}

export type ProviderData = {
  uid: string;
  providerId: string;
  displayName: string;
};

export type User = {
  createdAt?: string;
  displayName?: string;
  email: string;
  emailVerified?: boolean;
  isAnonymous?: boolean;
  lastLoginAt?: string;
  phoneNumber?: string;
  photoURL?: string;
  isAdmin?: boolean;
  firstName?: string;
  lastName?: string;
  providerData?: ProviderData[];
  reloadUserInfo?: {
    displayName: string;
  };
  stsTokenManager?: {
    accessToken: string;
    expirationTime: number;
    refreshToken: string;
  };
  uid?: string;
};

export enum OptInStatus {
  pending = "unsubscribed",
  subscribed = "subscribed",
}

export type Profile = {
  firstName: string;
  lastName: string;
  churchName: string;
  churchCity: string;
  churchState?: string;
  churchZip?: string;
  churchCountry?: string;
  isAgChurch?: boolean;
  usage: Usage[];
  roles: Roles[];
  attendance: Attendance;
  churchUsage: string;
  image: string;
  appRole: AppRoles;
  emailOptIn?: OptInStatus;
  customerId_stg?: string;
  customerId_prd?: string;
  financial_supporter?: boolean;
  permissions?: string[];
  appLanguage?: string;
};

export type UserData = {
  user?: User | null;
  profile?: Profile | null;
};

export type LoginPayload = Callbacks & {
  email: string;
  password: string;
};

export type LoginSagaPayload = {
  type: string;
  payload: LoginPayload;
};

export type VerifyEmailPayload = Callbacks & {
  code: string;
};

export type VerifyEmailAction = Action<VerifyEmailPayload>;

export type SendEmailVerificationAction = Action<Callbacks>;

export type ForgottenPasswordPayload = Callbacks & {
  email: string;
};

export type ForgottenPasswordAction = Action<ForgottenPasswordPayload>;

export type UpdatePasswordPayload = Callbacks & {
  password: string;
  code: string;
};

export type UpdatePasswordAction = Action<UpdatePasswordPayload>;

export type SignupPayload = Callbacks & {
  email: string;
  password: string;
};

export type SignupSagaPayload = {
  type: string;
  payload: SignupPayload;
};

export type SaveProfilePayload = Profile & Callbacks;

export type SaveProfileSagaPayload = {
  type: string;
  payload: SaveProfilePayload;
};

export type GoogleLoginPayload = {
  idToken: string;
};

export type GoogleLoginAction = {
  type: string;
  payload: GoogleLoginPayload;
  meta: any;
  error: any;
};

export type EditProfilePayload = Partial<Profile> & OptionalCallbacks;

export type EditProfileAction = {
  type: string;
  payload: EditProfilePayload;
};

export type ChangePasswordPayload = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
} & Callbacks;

export type ChangePasswordAction = {
  type: string;
  payload: ChangePasswordPayload;
};

export type DeleteAccountPayload = Callbacks & {
  deleteOnlyData?: boolean;
};

export type DeleteAccountAction = {
  type: string;
  payload: DeleteAccountPayload;
};

export type EmailOptInPayload = Callbacks & {
  isActive: boolean;
};

export type EmailOptInAction = {
  type: string;
  payload: EmailOptInPayload;
};

export type UserReadyPayload = {
  userId: string;
};

export type ChangeEmailPayload = Callbacks & {
  email: string;
};

export type ChangeEmailAction = {
  type: string;
  payload: ChangeEmailPayload;
};

export type SetTutorialSeenPayload = Callbacks & {
  groups: string[];
};

export type SetTutorialSeenAction = {
  type: string;
  payload: SetTutorialSeenPayload;
};
