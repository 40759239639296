import * as React from "react";
import { Box, Card, Icon, styled, useTheme } from "@mui/material";
import { H5, Paragraph, Span } from "~/dashboard/components/typography";
import Chart from "react-apexcharts";

import { getCurrentStats } from "~/state/stats/selectors";
import { useAppSelector } from "~/state/hooks";
import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";

const CardHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  padding: "12px 16px",
  justifyContent: "space-between",
  background: "rgba(0, 0, 0, 0.01)",
}));

export const GaugeProgressCard = () => {
  const theme = useTheme();
  const data = useAppSelector(getCurrentStats);
  const value = Math.round(data?.totalUsers / 1000);

  const options = {
    chart: {},
    grid: { padding: { left: 0, right: 0 } },
    plotOptions: {
      radialBar: {
        startAngle: -120,
        endAngle: 120,
        offsetY: 0,
        hollow: { margin: 0, size: "68%" },
        dataLabels: {
          showOn: "always",
          name: { show: false },
          value: {
            show: true,
            offsetY: 38,
            fontSize: "24px",
            fontWeight: "600",
            color: theme.palette.text.primary,
            formatter: (val: number) => val + "K",
          },
        },
        track: {
          background: "#eee",
          strokeWidth: "100%",
        },
      },
    },
    colors: [theme.palette.secondary.main],
    stroke: { lineCap: "round" },
    responsive: [
      {
        breakpoint: 767,
        options: { chart: { offsetX: 0, offsetY: 0 } },
      },
    ],
  };

  return (
    <Card sx={{ height: 338 }} elevation={3}>
      <CardHeader>
        <Span sx={{ fontWeight: "500", color: "text.secondary" }}>
          {formatMessage(messages.milestones)}
        </Span>
      </CardHeader>

      <Box position="relative" mt={3}>
        <Chart
          options={options}
          series={[value]}
          type="radialBar"
          height={200}
        />
        <Icon
          sx={{
            fontSize: "36px",
            position: "absolute",
            color: "text.secondary",
            top: "calc(50% - 24px)",
            left: "calc(50% - 18px)",
          }}
        >
          people
        </Icon>
      </Box>

      <H5 sx={{ mb: 1, textAlign: "center", fontWeight: "500" }}>Awesome</H5>

      <Paragraph sx={{ mb: 2, textAlign: "center", color: "text.secondary" }}>
        {formatMessage(messages.goal, { value: 100 })}
      </Paragraph>
    </Card>
  );
};
