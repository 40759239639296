import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { StatsState, Stats, DateRange, ChurchStats } from "./types";

export const initialState: StatsState = {
  statsHistory: [],
  churches: [],
  dateRange: {
    from: dayjs().subtract(6, "month"),
    to: dayjs(new Date()),
  },
  isLoading: false,
};

export const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    setStatsHistory: (state, action: PayloadAction<Stats[]>) => {
      state.statsHistory = action.payload;
      state.isLoading = false;
    },
    setChurchData: (state, action: PayloadAction<ChurchStats[]>) => {
      state.churches = action.payload;
    },
    changeDateRange: (state, action: PayloadAction<DateRange>) => {
      state.dateRange = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setStatsHistory, changeDateRange, setChurchData, setIsLoading } =
  statsSlice.actions;

export default statsSlice.reducer;
