import styled from "styled-components/native";

import { Text1 } from "~/components/text";
import { spacers } from "~/styles/theme";

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.gray50};
  padding: ${spacers.ss4} ${spacers.ss5};
  margin-horizontal: ${spacers.ss6};
  margin-bottom: ${spacers.ss5};
  flex-direction: row;
  border-left-width: 3px;
  border-left-color: ${({ theme }) => theme.colors.primaryBlue};
  align-items: center;
`;

export const TextBox = styled.View`
  flex: 1;
`;

export const SenderName = styled(Text1)`
  font-family: SFProDisplayBold;
  color: ${({ theme }) => theme.colors.gray500};
  margin-bottom: ${spacers.ss3};
`;

export const Text = styled(Text1)`
  color: ${({ theme }) => theme.colors.gray600};
`;

export const CancelButton = styled.TouchableOpacity``;
