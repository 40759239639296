import * as React from "react";
import axios, { AxiosProgressEvent } from "axios";
import { ActivityIndicator } from "react-native";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { useTheme } from "styled-components/native";

import { Container, Text } from "./styles";
import { useAppSelector } from "~/state/hooks";
import { colors } from "~/styles/theme";
import { getPlanByVolumeId } from "~/state/flamelink";

import { messages } from "./intl";
import { STORAGE_URL } from "~/constants";

interface Props {
  volumeIndex: number;
  volumeId: string;
}

export const DownloadAll = ({ volumeIndex, volumeId }: Props) => {
  const [progress, setProgress] = React.useState(0);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const plan = useAppSelector((state) => getPlanByVolumeId(state, volumeId));
  const theme = useTheme();

  const planName = (plan?.title.trim() || "").replace(/\s/g, "_");
  const volumeNumber = volumeIndex + 1;
  const fileName = `${planName}_Volume${volumeNumber}.zip`;
  const normalisedFileName = encodeURIComponent(fileName.normalize("NFD"));

  const handlePress = React.useCallback(async () => {
    if (isDownloading) {
      return;
    }
    setIsDownloading(true);

    try {
      const response = await axios({
        method: "get",
        url: `${STORAGE_URL}/zips%2F${normalisedFileName}?alt=media`,
        responseType: "blob",
        onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
          if (!progressEvent?.total) {
            return;
          }
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });

      if (response?.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsDownloading(false);
        return;
      }
      throw new Error("Response data is empty");
    } catch (e) {
      console.error("Error downloading the file:", e);
      setIsDownloading(false);
    }
  }, [fileName, isDownloading]);

  const message = !isDownloading
    ? { ...messages.downloadVolume, values: { volumeNumber } }
    : {
        ...messages.downloadVolumeProgress,
        values: { volumeNumber, progress },
      };

  return (
    <Container onPress={handlePress}>
      <Icon name="download" color={theme.colors.gray500} size={14} />
      <Text>{message}</Text>
      {isDownloading ? (
        <ActivityIndicator size={12} color={theme.colors.gray500} />
      ) : null}
    </Container>
  );
};
