import { Platform } from "react-native";
import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";

import { formatMessage } from "~/utils/translation";
import { handleError } from "~/utils/logger";
import { Alert } from "~/components/alert";

import { messages } from "./intl";
import { ANDROID_DEFAULT_RATIO } from "./constants";
import { Size } from "./types";

export const getCameraRatio = async (
  cameraRef: Camera | null,
  width: number,
  height: number
): Promise<string> => {
  if (Platform.OS !== "android" || !cameraRef) {
    return ANDROID_DEFAULT_RATIO;
  }

  const supportedRatios = await cameraRef.getSupportedRatiosAsync();

  const wantedRatio = height / width;

  const { ratio } = supportedRatios.reduce(
    ({ ratio: defaultRatio, error }, newRatio: string) => {
      const ratioElements = newRatio.split(":");
      const r0 = Number(ratioElements[0]) || 1;
      const r1 = Number(ratioElements[1]) || 1;
      const newError = Math.abs(wantedRatio - r0 / r1);

      if (newError < error) {
        return {
          ratio: newRatio,
          error: newError,
        };
      }

      return { ratio: defaultRatio, error };
    },
    { ratio: ANDROID_DEFAULT_RATIO, error: 100000 }
  ) as { ratio: string; error: number };

  return ratio;
};

const getCroppingParams = ({
  height,
  width,
}: {
  height: number;
  width: number;
}) => {
  return {
    originX: Math.max(0, (width - height) / 2) || 0,
    originY: Math.max(0, (height - width) / 2) || 0,
    width: Math.min(width, height) || 0,
    height: Math.min(width, height) || 0,
  };
};

type ProcessImageOptions = {
  image: ImagePicker.ImagePickerAsset;
  size: Size;
};

const processImage = async ({
  image,
  size: { width, height },
}: ProcessImageOptions) => {
  const cropOptions = {
    crop: getCroppingParams(image),
  };
  const options =
    width && height
      ? [cropOptions, { resize: { width, height } }]
      : [cropOptions];

  const processedImage = await ImageManipulator.manipulateAsync(
    image.uri,
    options,
    {
      compress: 0.7,
      format: ImageManipulator.SaveFormat.JPEG,
    }
  );

  return {
    ...processedImage,
    type: "image",
  };
};

export const pickFromLibrary = async () => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  if (status !== "granted") {
    await Alert.alert(
      formatMessage(messages.needPermissionsTitle),
      formatMessage(messages.needPermissionsBody)
    );
    return;
  }
  try {
    const result = (await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    })) as ImagePicker.ImagePickerResult;

    return result;
  } catch (e) {
    handleError(e);
    return;
  }
};

export const pickAndProcessImage = async ({ size }: { size: Size }) => {
  try {
    const result = await pickFromLibrary();

    if (result && !result.canceled) {
      const resource = Array.isArray(result?.assets)
        ? result?.assets[0]
        : result;
      const image = await processImage({
        image: resource as ImagePicker.ImagePickerAsset,
        size,
      });

      return image;
    }
  } catch (e) {
    handleError(e);
    console.warn("Error picking a processing an image from Library");
    return;
  }
};
