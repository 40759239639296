import styled from "styled-components/native";
import { StyleSheet } from "react-native";

import { Text1, Text2, Text3 } from "~/components/text";
import { colors, spacers, fontSizes } from "~/styles/theme";
import { pxToNumber } from "~/utils/theme";

export const Section = styled.View<{ isLast?: boolean }>`
  margin-horizontal: ${spacers.ss8};
  padding-vertical: ${spacers.ss8};
  border-bottom-width: ${({ isLast }) => (isLast ? 0 : 1)}px;
  border-bottom-color: ${({ theme }) => theme.colors.gray400};
`;

export const SectionTitle = styled(Text1)<{ isLast?: boolean }>`
  text-transform: uppercase;
  margin-bottom: ${({ isLast }) => (isLast ? 0 : spacers.ss5)};
  font-family: SFProDisplayBold;
`;

export const SectionText = styled(Text3)`
  margin-bottom: ${spacers.ss4};
`;

export const Scripture = styled(Text3)`
  margin-bottom: ${spacers.ss2};
  font-family: LoraMedium;
`;

export const VerseText = styled(Text3)`
  margin-bottom: ${spacers.ss6};
  font-family: Lora;
  line-height: 22px;
`;

export const IconBox = styled.View`
  margin-bottom: ${spacers.ss4};
`;

export const VerseLink = styled.TouchableOpacity`
  margin-bottom: ${spacers.ss4};
  flex-direction: row;
`;

export const LinkText = styled(Text2)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  margin-right: ${spacers.ss3};
`;

export const questionStyles: StyleSheet.NamedStyles<any> = {
  body: {
    marginHorizontal: 0,
    fontSize: pxToNumber(fontSizes.fs3),
  },
};
