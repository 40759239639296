export const messages = {
  smileys: {
    id: "emoji-picker.smileys",
    defaultMessage: "Smileys & Emotion",
  },
  people: {
    id: "emoji-picker.people",
    defaultMessage: "People & Body",
  },
  animals: {
    id: "emoji-picker.animals",
    defaultMessage: "Animals & Nature",
  },
  food: {
    id: "emoji-picker.food",
    defaultMessage: "Food & Drink",
  },
  activities: {
    id: "emoji-picker.activities",
    defaultMessage: "Activities",
  },
  travel: {
    id: "emoji-picker.travel",
    defaultMessage: "Travel",
  },
  objects: {
    id: "emoji-picker.objects",
    defaultMessage: "Objects",
  },
  flags: {
    id: "emoji-picker.flags",
    defaultMessage: "Flags",
  },
  symbols: {
    id: "emoji-picker.symbols",
    defaultMessage: "Symbols",
  },
  others: {
    id: "emoji-picker.others",
    defaultMessage: "Others",
  },
  searchTitle: {
    id: "emoji-picker.search-title",
    defaultMessage: "Search results",
  },
  search: {
    id: "emoji-picker.search",
    defaultMessage: "Search emoji",
  },
};
