import * as React from "react";
import { useTheme } from "@mui/material";
import Chart from "react-apexcharts";

import { getIsDarkMode } from "~/state/settings";
import { useAppSelector } from "~/state/hooks";
import { getIsLoadingData, getStatsRelative } from "~/state/stats/selectors";

export const ComparisonChart = () => {
  const { palette } = useTheme();

  const data = useAppSelector(getStatsRelative);
  const isDarkMode = useAppSelector(getIsDarkMode);
  const isLoading = useAppSelector(getIsLoadingData);

  const options = {
    theme: {
      mode: isDarkMode ? "dark" : "light",
      palette: "palette3",
    },
    chart: {
      type: "bar",
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 16,
        borderRadius: 8,
        borderRadiusApplication: "top",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: data?.categories,
    },
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
    colors: [
      palette.error.light,
      palette.warning.light,
      palette.secondary.light,
    ],
  };

  return (
    <div style={{ width: "100%", opacity: isLoading ? 0.5 : 1 }}>
      {data.series.length ? (
        <Chart
          options={options}
          series={data?.series}
          type="bar"
          height={415}
        />
      ) : null}
    </div>
  );
};
