import * as React from "react";
import { Keyboard } from "react-native";
import { Modal, Portal } from "react-native-paper";
import { useRoute } from "@react-navigation/native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";

import { BottomSheetModal } from "~/components/bottom-sheet";
import { SessionRouteProp } from "~/screens/session/types";
import { useGetPrayerRequestsQuery } from "~/state/prayer-requests";
import { formatMessage } from "~/utils/translation";
import { IconSizes } from "~/components/icon";
import type { ListenToGodSection } from "~/state/flamelink/types";
import { isWeb, isAndroid } from "~/utils/platform";
import { handleError } from "~/utils/logger";
import { RichText } from "../rich-text";
import { SubsectionTitle } from "../common";
import {
  SavePrayerRequestsActionContainer,
  SavePrayerRequestsActionText,
  webModalContainerStyles,
} from "./styles";
import { messages } from "./intl";
import { SavePrayerRequests } from "../save-prayer-requests";
import { ContentIcon } from "../content-icon";

interface PrayerRequestsProps {
  prayerRequests: ListenToGodSection["prayerRequests"];
}

export const PrayerRequests: React.FC<PrayerRequestsProps> = ({
  prayerRequests,
}) => {
  const { params } = useRoute<SessionRouteProp>();
  const sessionId = params.sessionId;
  const bottomSheetModalRef = React.useRef<BottomSheetModal>(null);
  const snapPoints = React.useMemo(() => [isAndroid ? "88%" : "92%"], []);
  const [visible, setVisible] = React.useState(false);
  const theme = useTheme();

  const { data, refetch } = useGetPrayerRequestsQuery(sessionId);

  const showModal = React.useCallback(() => setVisible(true), []);
  const hideModal = React.useCallback(() => setVisible(false), []);

  const handlePresentModalPress = React.useCallback(() => {
    isWeb ? showModal() : bottomSheetModalRef.current?.present();
  }, [showModal]);

  const handleCloseModal = React.useCallback(() => {
    try {
      isWeb ? hideModal() : bottomSheetModalRef.current?.dismiss();

      if (typeof Keyboard.dismiss === "function") {
        Keyboard.dismiss();
      }
    } catch (e) {
      handleError(e);
    }
  }, [hideModal, bottomSheetModalRef]);

  return (
    <>
      {prayerRequests?.sectionTitle && (
        <SubsectionTitle>{prayerRequests.sectionTitle}</SubsectionTitle>
      )}

      {prayerRequests?.intro && <RichText>{prayerRequests.intro}</RichText>}

      {prayerRequests?.content && <RichText>{prayerRequests.content}</RichText>}

      <SavePrayerRequestsActionContainer onPress={handlePresentModalPress}>
        <Icon
          name={data ? "remove-red-eye" : "edit"}
          color={theme.colors.primaryBlue}
          size={IconSizes.SMedium}
        />

        <SavePrayerRequestsActionText>
          {data
            ? formatMessage(messages.viewPrayerRequests)
            : formatMessage(messages.addPrayerRequests)}
        </SavePrayerRequestsActionText>
      </SavePrayerRequestsActionContainer>

      <ContentIcon content={[prayerRequests?.intro, prayerRequests?.content]} />

      {isWeb ? (
        <Portal>
          <Modal
            visible={visible}
            onDismiss={hideModal}
            contentContainerStyle={webModalContainerStyles}
          >
            <SavePrayerRequests
              onClose={handleCloseModal}
              sessionId={sessionId}
              prayerRequest={data?.prayerRequests}
              onSuccess={refetch}
            />
          </Modal>
        </Portal>
      ) : (
        <BottomSheetModal
          ref={bottomSheetModalRef}
          index={0}
          snapPoints={snapPoints}
          handleIndicatorStyle={{ backgroundColor: theme.colors.black }}
          handleStyle={{
            backgroundColor: theme.colors.white,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          }}
        >
          <SavePrayerRequests
            onClose={handleCloseModal}
            sessionId={sessionId}
            prayerRequest={data?.prayerRequests}
            onSuccess={refetch}
          />
        </BottomSheetModal>
      )}
    </>
  );
};
