import * as React from "react";
import i18next from "i18next";
import { Pressable, BackHandler } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";

import * as routes from "~/constants/routes";
import { useAppSelector } from "~/state/hooks";
import { getFeaturedContent } from "~/state/flamelink";
import { getLanguagesToDisplay } from "~/state/settings";
import { BottomSheetModal } from "~/components/bottom-sheet";
import { isAndroid, isWeb, windowHeight } from "~/utils/platform";
import { asyncLogEvent, events } from "~/utils/analytics";

import { Content as WebViewContent } from "./content";
import {
  Container,
  Content,
  SectionTitle,
  ImageItem,
  Item,
  ItemButton,
  ButtonText,
  Text,
  Overlay,
  NavigationBar,
  NavButton,
} from "./styles";
import { messages } from "./intl";

interface Props {}

export const FeaturedCarousel = React.memo<Props>(() => {
  const data = useAppSelector(getFeaturedContent);
  const languages = useAppSelector(getLanguagesToDisplay);

  const navigation = useNavigation<any>();
  const { top } = useSafeAreaInsets();
  const snapPoints = [windowHeight - top];

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalUri, setModalUri] = React.useState("");
  const theme = useTheme();

  const bottomSheetModalRef = React.useRef<BottomSheetModal>(null);

  const onModalPress = (uri: string) => {
    if (isWeb) {
      window.open(uri, "_blank", "noopener,noreferrer");
      return;
    }

    bottomSheetModalRef.current?.present();
    setModalUri(uri);
    setIsModalOpen(true);
  };

  const handleClose = React.useCallback(() => {
    bottomSheetModalRef?.current?.close();
    setIsModalOpen(false);
  }, [bottomSheetModalRef, setIsModalOpen]);

  React.useEffect(() => {
    if (isAndroid) {
      BackHandler.addEventListener("hardwareBackPress", () => {
        handleClose();
        return true;
      });
      return () => {
        BackHandler.removeEventListener("hardwareBackPress", () => {
          handleClose();
          return true;
        });
      };
    }
  }, []);

  const handleOnPress = React.useCallback(
    ({
      plan,
      session,
      url,
    }: {
      plan?: string;
      session?: string;
      url?: string;
    }) => {
      asyncLogEvent(events.FEATURED_CONTENT_PRESS, {
        plan,
        session,
        url,
      });
      if (url) {
        onModalPress(url);
        return;
      }
      if (plan) {
        navigation.navigate(routes.plan, { planId: plan });
        return;
      }
      if (plan && session) {
        navigation.navigate(routes.session, {
          planId: plan,
          sessionId: session,
          volumeIndex: 0,
          sessionIndex: 0,
          dayIndex: 0,
        });
        return;
      }
    },
    [navigation]
  );

  const title = React.useMemo(() => {
    if (languages.length === 1 && languages[0] === "es") {
      const es = i18next.getFixedT("es");
      return es(messages.title?.id);
    } else {
      return messages.title;
    }
  }, [languages]);

  if (!data.length) {
    return;
  }

  return (
    <Container>
      <SectionTitle>{title}</SectionTitle>
      <Content showsHorizontalScrollIndicator={false} horizontal>
        {data.map(
          ({
            id,
            text,
            buttonText,
            type,
            imageUri = "",
            url,
            plan,
            session,
          }) => {
            const onPress = () => handleOnPress({ plan, session, url });
            if (type === "text") {
              return (
                <Item key={id}>
                  {text ? <Text>{text}</Text> : null}
                  {buttonText ? (
                    <ItemButton onPress={onPress}>
                      <ButtonText>{buttonText}</ButtonText>
                    </ItemButton>
                  ) : null}
                </Item>
              );
            }
            if (type === "image" && imageUri) {
              return (
                <Pressable onPress={onPress} key={id}>
                  <ImageItem source={{ uri: imageUri }} />
                </Pressable>
              );
            }
            return null;
          }
        )}
      </Content>
      <BottomSheetModal
        ref={bottomSheetModalRef}
        index={0}
        snapPoints={snapPoints}
        backdropComponent={() => <Overlay onPress={handleClose} />}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      >
        <>
          <NavigationBar>
            <NavButton>
              <Icon
                onPress={handleClose}
                name="chevron-left"
                color={theme.colors.gray600}
                size={26}
              />
            </NavButton>
            <NavButton isLast>
              <Icon onPress={handleClose} name="close" size={20} />
            </NavButton>
          </NavigationBar>

          <WebViewContent uri={modalUri} />
        </>
      </BottomSheetModal>
    </Container>
  );
});
