import { Searchbar as RNSearchBar } from "react-native-paper";
import styled, { Theme } from "styled-components/native";
import { TAB_BAR_HEIGHT } from "~/components/tab-bar/styles";
import { Text3, Text4 } from "~/components/text";
import { colors, lineHeights, spacers } from "~/styles/theme";
import { hexToRGB } from "~/utils/colors";
import { pxToNumber } from "~/utils/theme";

export const SearchContainer = styled.View`
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({ theme }) => (theme as Theme)?.colors.gray400};
  border-top-width: 0;
`;

export const SearchBar = styled(RNSearchBar)`
  flex: 1;
  margin: ${spacers.ss6};
  border-radius: 10px;
  background-color: ${({ theme }) => hexToRGB(theme?.colors.warmGray500, 0.12)};
`;

export const CancelText = styled(Text3)`
  font-family: SFProDisplayMedium;
  color: ${({ theme }) => theme.colors.indigo600};
  margin: ${spacers.ss6} ${spacers.ss6} ${spacers.ss6} 0;
`;

export const PlansContainer = styled.FlatList.attrs({
  contentInset: {
    bottom: TAB_BAR_HEIGHT + pxToNumber(spacers.ss6),
  },
  contentContainerStyle: {
    paddingBottom: 100,
  },
})`
  padding: ${spacers.ss6};
`;

export const PlanContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const PlanTitle = styled(Text4)`
  width: 100%;
  font-family: SFProDisplayMedium;
  line-height: ${lineHeights.lh2};
  margin: 0 0 ${spacers.ss6};
`;
