import * as React from "react";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "styled-components/native";

import { GroupsProps } from "~/navigation/groups-stack";
import { useActionSheet } from "~/components/action-sheet";
import { IconSizes } from "~/components/icon";
import { colors } from "~/styles/theme";
import { formatMessage } from "~/utils/translation";
import { useAppNetInfo, useAppSelector } from "~/state/hooks";
import { getGroupsOrderedByTime } from "~/state/groups/selectors";
import { useAlerts } from "~/state/alerts";
import { genericMessages } from "~/constants/intl";

import { IconButton } from "../styles";
import { messages } from "../intl";

const options = [
  {
    label: messages.createGroup,
  },
  {
    label: messages.joinGroup,
  },
  {
    label: messages.cancel,
  },
];

interface Props {
  onCreate: () => void;
  onJoin: () => void;
}

export const GroupsActionButton = ({ onCreate, onJoin }: Props) => {
  const navigation = useNavigation<GroupsProps["navigation"]>();
  const data = useAppSelector(getGroupsOrderedByTime);
  const { showActionSheetWithOptions } = useActionSheet();
  const { pushAlert } = useAlerts();
  const { isConnected } = useAppNetInfo();
  const theme = useTheme();

  const translatedOptions = options?.map(({ label: optionLabel }) =>
    formatMessage(optionLabel)
  );

  const handleOffline = React.useCallback(() => {
    pushAlert({
      message: genericMessages.featureUnavailableOffline,
      color: colors.gray600,
    });
  }, [pushAlert]);

  const handleCreate = React.useCallback(() => {
    if (!isConnected) {
      handleOffline();

      return;
    }

    onCreate();
  }, [handleOffline, isConnected, onCreate]);

  const handleJoin = React.useCallback(() => {
    if (!isConnected) {
      handleOffline();

      return;
    }

    onJoin();
  }, [handleOffline, isConnected, onJoin]);

  const handleActionButton = React.useCallback(
    (e) => {
      const cancelButtonIndex = options.length - 1;
      showActionSheetWithOptions(
        {
          options: translatedOptions,
          cancelButtonIndex,
        },
        (selectedIndex?: number) => {
          switch (selectedIndex) {
            case 0: {
              handleCreate();
              return;
            }
            case 1: {
              handleJoin();
              return;
            }
          }
        },
        e
      );
    },
    [showActionSheetWithOptions, handleCreate, handleJoin, translatedOptions]
  );

  return (
    <IconButton onPress={handleActionButton}>
      <Icon name="add" size={IconSizes.Medium} color={theme.colors.black} />
    </IconButton>
  );
};
