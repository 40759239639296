import styled from "styled-components/native";

import { hexToRGB } from "~/utils/colors";
import { isWeb } from "~/utils/platform";
import { createShadow } from "~/utils/shadow";
import { colors, spacers } from "~/styles/theme";

export const Container = styled.View<{ top: number }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-width: 0.5px;
  border-color: #ddd;
  position: absolute;
  right: 20px;
  top: ${({ top }) => top}px;
  z-index: 9;
`;

export const EditBox = styled.View`
  align-items: center;
  justify-content: center;
  padding-horizontal: ${spacers.ss4};
  flex-direction: row;
`;

export const HighlightBox = styled.View`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: ${spacers.ss3};
  border-left-width: 0.5px;
  border-color: ${({ theme }) => theme.colors.gray300};
`;

export const IconButton = styled.TouchableOpacity`
  padding-horizontal: ${spacers.ss2};
`;

export const ColorCircle = styled.TouchableOpacity<{ color: string }>`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ color }) => color};
  margin: ${spacers.ss3};
`;

export const shadowStyle = {
  ...createShadow({
    color: hexToRGB(colors.black, 0.3),
    opacity: isWeb ? 0.3 : 0.6,
    radius: isWeb ? 16 : 8,
    offsetWidth: 0,
    offsetHeight: 10,
    elevation: 8,
  }),
};
