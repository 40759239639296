import * as React from "react";
import { useTheme } from "styled-components/native";

import { isAndroid } from "~/utils/platform";
import { Screen } from "~/components/screen";

interface Props {
  children: React.ReactNode;
  screenName: string;
  withGrayBackground?: boolean;
  paddingBottom?: number;
}

export const SettingsScreen = ({
  children,
  screenName,
  withGrayBackground,
  paddingBottom,
}: Props) => {
  const theme = useTheme();
  return (
    <Screen
      screenName={screenName}
      backgroundColor={
        withGrayBackground ? theme.colors.gray100 : theme.colors.white
      }
      isView={!isAndroid}
      paddingBottom={paddingBottom}
    >
      {children}
    </Screen>
  );
};
