import { differenceInSeconds, formatDistance, format } from "date-fns";
import { Timestamp } from "firebase/firestore";

import { formatMessage } from "~/utils/translation";

import { Stats, ChurchStats } from "./types";
import { messages } from "./intl";
import { Profile } from "../user/types";

export function getDifference(date: Date) {
  return differenceInSeconds(new Date(), date);
}

export interface FormatRelativeOptions {
  addSuffix?: boolean;
  includeSeconds?: boolean;
}

export const getTimestamp = (input: any) => {
  if (input?.hasOwnProperty("_seconds")) {
    return input._seconds * 1000;
  }
  if (input?.hasOwnProperty("seconds")) {
    return input.seconds * 1000;
  }
  if (typeof input?.toDate === "function") {
    return new Date(input.toDate()).getTime();
  }
  if (typeof input?.getTime === "function") {
    return input.getTime();
  }
  return new Date().getTime();
};

export function formatRelative(date: Date, options?: FormatRelativeOptions) {
  const diff = getDifference(date);

  if (diff < 30) {
    return "now";
  } else {
    return `${formatDistance(date, new Date(), options)} ago`;
  }
}

export function getInterval(date: Date) {
  const diff = getDifference(date);

  if (diff < 3600) {
    return 60000;
  } else if (diff >= 3600 && diff <= 86400) {
    return 3600000;
  } else {
    return 0;
  }
}

export const formatSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);
  return `${minutes}m ${remainingSeconds}s`;
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getMonthString = (date: Date) => {
  return monthNames[(date.getUTCMonth() + 11) % 12];
};

export const sortStatsData = (historyData: Stats[]) => {
  return [...historyData].sort((a, b) => {
    return getTimestamp(a?.date) - getTimestamp(b?.date);
  });
};

export const transformDataRelative = (historyData: Stats[]) => {
  const data = sortStatsData(historyData);

  const totalUsers = [];
  const totalChurches = [];
  const totalGroups = [];
  const months = [];

  // Start with the second item, as the first item has no previous month for comparison
  for (let i = 1; i < data.length; i++) {
    const currentDate = new Date(getTimestamp(data[i].date));
    const previousData = data[i - 1];

    months.push(getMonthString(currentDate));

    const users = data[i].totalUsers || 0;
    const churches = data[i].totalChurches || 0;
    const groups = data[i].totalGroups || 0;

    const prevUsers = previousData.totalUsers || 0;
    const prevChurches = previousData.totalChurches || 0;
    const prevGroups = previousData.totalGroups || 0;

    const diffUsers = users - prevUsers;
    const diffChurches = churches - prevChurches;
    const diffGroups = groups - prevGroups;

    totalUsers.push(diffUsers);
    totalChurches.push(diffChurches);
    totalGroups.push(diffGroups);
  }

  return {
    series: [
      {
        name: formatMessage(messages.newUsers),
        data: totalUsers,
      },
      {
        name: formatMessage(messages.newChurches),
        data: totalChurches,
      },
      {
        name: formatMessage(messages.newGroups),
        data: totalGroups,
      },
    ],
    categories: months,
  };
};

export const getRecentDataWithLabels = (
  data: Array<{ date?: Timestamp; value: number }>
) => {
  const sortedData = data.sort(
    (a, b) => getTimestamp(a.date) - getTimestamp(b.date)
  );

  // Get the 28 most recent entries just like in Google Analytics
  const recentData = sortedData.slice(-28);

  // Calculate the step for labels based on the data length (max 7 labels)
  const labelStep = Math.max(1, Math.floor(recentData.length / 7));

  return recentData.map((item, index) => ({
    label:
      index % labelStep === 0 ? format(getTimestamp(item.date), "MM/dd") : "",
    value: Math.round((item.value * 100) / 60) / 100,
  }));
};
