import styled from "styled-components/native";

import { Text3, Text3Center, Text8Center } from "~/components/text";
import { spacers } from "~/styles/theme";

export const Container = styled.SafeAreaView`
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
`;

export const ScrollContent = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingHorizontal: 24,
    alignItems: "center",
  },
})``;

export const Content = styled.View`
  max-width: 450px;
  align-items: center;
`;

export const Title = styled(Text8Center)`
  font-family: SFProDisplayBold;
  margin: ${spacers.ss8} ${spacers.ss6};
`;

export const BackgroundBox = styled.View`
  margin-bottom: ${spacers.ss4};
`;

export const ImageBox = styled.TouchableOpacity`
  margin-bottom: ${spacers.ss4};
`;

export const BackgroundImage = styled.Image`
  width: 400px;
  height: 171px;
`;

export const CenterBox = styled.View`
  flex-direction: row;
  margin-vertical: ${spacers.ss9};
  align-items: center;
`;

export const Image = styled.Image`
  width: 160px;
  height: 48px;
  margin-horizontal: ${spacers.ss3};
`;

export const GetStartedBox = styled.View`
  margin-vertical: ${spacers.ss6};
  align-items: center;
`;

export const GetStartedHeader = styled(Text3)`
  font-family: SFProDisplayBold;
  margin-bottom: ${spacers.ss4};
  min-width: 203px;
`;

export const GetStarted = styled(Text3)``;

export const ComingSoon = styled(Text3Center)``;

export const FooterBox = styled.View`
  margin-vertical: ${spacers.ss9};
  flex-direction: row;
`;

export const FooterText = styled(Text3)`
  margin-right: ${spacers.ss3};
`;

export const FooterLink = styled.TouchableOpacity``;

export const FooterLinkText = styled(Text3)`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
