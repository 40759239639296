import React from "react";

import { UserReactions } from "~/state/chat/types";

import { Container, EmojiBox, EmojiText, Count, CountBox } from "./styles";

interface Props {
  reactions: UserReactions;
  onPress: () => void;
}

export const EmojiSummary = ({ reactions, onPress }: Props) => {
  const reactionsCount = reactions.length;
  if (!reactionsCount) {
    return null;
  }

  return (
    <Container onPress={onPress}>
      <EmojiBox>
        {reactions.map((item) => (
          <EmojiText key={item.reaction}>{item.reaction}</EmojiText>
        ))}
      </EmojiBox>
      {reactionsCount > 1 ? (
        <CountBox>
          <Count>{`${reactionsCount}`}</Count>
        </CountBox>
      ) : null}
    </Container>
  );
};
