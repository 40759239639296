import styled from "styled-components/native";

import { spacers } from "~/styles/theme";

export const Container = styled.View`
  background-color: ${({ theme }) => theme.colors.warmGray100};
  padding-bottom: ${spacers.ss9};
  flex: 1;
`;

export const Content = styled.KeyboardAvoidingView`
  flex: 1;
`;

export const ChatContent = styled.ScrollView.attrs(
  ({ paddingBottom }: { paddingBottom: number }) => ({
    contentContainerStyle: {
      flexGrow: 1,
      justifyContent: "flex-end",
      paddingBottom,
    },
  })
)`
  flex: 1;
  padding-horizontal: ${spacers.ss6};
`;

export const LoaderBox = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;
