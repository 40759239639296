import * as React from "react";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";
import {
  Modal,
  TouchableWithoutFeedback,
  View,
  StyleSheet,
} from "react-native";

import { Verse } from "~/state/bible/types";
import { BibleNoteAddComponent } from "~/screens/notes-bible-view/content";

import {
  Container,
  EditBox,
  HighlightBox,
  ColorCircle,
  IconButton,
  shadowStyle,
} from "./styles";
import { highlightColors } from "./constants";
import { useModal } from "~/state/modal/hook.web";
import { useShare } from "~/components/share/index.web";
import { useAppSelector } from "~/state/hooks";
import { getGroupsOrderedByTime } from "~/state/groups/selectors";

interface Props {
  top: number;
  selectedVerses: Verse[];
  bookId: string;
  chapterId: number;
  noteId?: string;
  onHighlightPress: (type: number) => void;
  onNavigation: () => void;
  onDismiss: () => void;
  isOpen: boolean;
  hasNote: boolean;
  scriptureText: string;
  onDeleteNotes: () => void;
  onCopy: () => void;
  onShare: () => void;
}

export const EditMenu = React.memo<Props>(
  ({
    top,
    bookId,
    chapterId,
    noteId,
    selectedVerses,
    onHighlightPress,
    onNavigation,
    isOpen,
    onDismiss,
    hasNote,
    scriptureText,
    onDeleteNotes,
    onCopy,
    onShare,
  }) => {
    const groups = useAppSelector(getGroupsOrderedByTime);
    const { showModal, hideModal } = useModal();
    const theme = useTheme();
    const { share } = useShare();

    const noteIcon = hasNote ? "delete" : "edit";

    const onEditPress = React.useCallback(() => {
      if (hasNote) {
        onDeleteNotes();
        return;
      }
      onNavigation();

      showModal(
        <BibleNoteAddComponent
          bookId={bookId}
          chapterId={chapterId}
          noteId={noteId}
          selectedVerses={selectedVerses}
          onSuccess={hideModal}
        />
      );
    }, [
      bookId,
      chapterId,
      noteId,
      selectedVerses,
      onNavigation,
      hideModal,
      showModal,
      hasNote,
      onDeleteNotes,
    ]);

    const onShareGeneric = React.useCallback(async () => {
      await share({
        message: scriptureText,
      });
    }, [share, scriptureText]);

    const onSharePress = React.useCallback(() => {
      onDismiss();
      if (groups.length) {
        onShare();
      } else {
        onShareGeneric();
      }
    }, [onShareGeneric, groups.length, onShare, onDismiss]);

    if (!isOpen) {
      return;
    }

    return (
      <Modal transparent visible onRequestClose={onDismiss}>
        <TouchableWithoutFeedback onPress={onDismiss}>
          <View style={styles.overlay}>
            <Container style={shadowStyle} top={top}>
              <EditBox>
                <IconButton onPress={onEditPress}>
                  <Icon
                    name={noteIcon}
                    size={24}
                    color={theme.colors.gray700}
                  />
                </IconButton>
                <IconButton onPress={onCopy}>
                  <Icon
                    name="content-copy"
                    size={20}
                    color={theme.colors.gray600}
                  />
                </IconButton>
                <IconButton onPress={onSharePress}>
                  <Icon name="share" size={22} color={theme.colors.gray600} />
                </IconButton>
              </EditBox>
              <HighlightBox>
                <IconButton onPress={() => onHighlightPress(-1)}>
                  <Icon
                    name="do-not-disturb-alt"
                    size={26}
                    color={theme.colors.gray500}
                  />
                </IconButton>

                {highlightColors.map((color, index) => (
                  <ColorCircle
                    color={color}
                    onPress={() => onHighlightPress(index)}
                    key={index}
                  />
                ))}
              </HighlightBox>
            </Container>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  overlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0)",
    justifyContent: "center",
    alignItems: "center",
  },
});
