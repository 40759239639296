import styled from "styled-components/native";

import { HEADER_BAR_HEIGHT } from "~/components/header-bar/web/styles";

export const SafeScrollView = styled.View<{
  extraMargin?: number;
  hasFixedHeader?: boolean;
}>`
  margin-bottom: 0px;
  padding-top: ${({ hasFixedHeader }) =>
    hasFixedHeader ? HEADER_BAR_HEIGHT : 0}px;
  background-color: ${({ theme }) => theme.colors.gray50};
`;
