import styled from "styled-components/native";
import { colors, spacers } from "~/styles/theme";
import { Text4 } from "~/components/text";
import { isWeb } from "~/utils/platform";

export const AvatarContainer = styled.View`
  align-items: center;
  ${isWeb ? `padding-top: ${spacers.ss8}` : ""}
`;

export const UserName = styled(Text4)`
  font-family: SFProDisplayMedium;
`;

export const AvatarPressable = styled.TouchableOpacity`
  width: 88px;
  align-self: center;
  margin-bottom: ${spacers.ss6};
`;

export const CameraBox = styled.View`
  background-color: ${({ theme }) => theme.colors.gray200};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 2px;
  right: 2px;
  border-color: ${({ theme }) => theme.colors.white};
  border-width: 2px;
`;
