import styled, { Theme } from "styled-components/native";
import { Platform } from "react-native";

import { Text2, Text5, Text6 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { getWebContainerStyle } from "~/utils/web";

type Props = {
  isHighlighted?: boolean;
  isSelected?: boolean;
  highlightColor?: string;
  hasComment?: boolean;
  theme: Theme;
  isDarkMode?: boolean;
};

const getBackgroundColor = ({ isSelected, highlightColor, theme }: Props) =>
  isSelected ? "#eaeaea" : highlightColor || theme.colors.white;

export const Container = styled.View`
  ${getWebContainerStyle(960)}
`;

export const Title = styled(Text6)`
  font-family: SFProDisplayMedium;
  font-weight: 600;
  padding-top: 30px;
  padding-horizontal: ${spacers.ss7};
`;

export const TextContent = styled.View`
  padding: ${spacers.ss7};
`;

export const Number = styled(Text2)<Props>`
  color: ${({ theme }) => theme.colors.amber600};
  font-family: ${Platform.OS === "ios" ? "LoraSuperscript" : "Lora"};
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  background-color: ${getBackgroundColor};
`;

const getVerseColor = ({
  hasComment,
  theme,
  isSelected,
  isDarkMode,
  highlightColor,
}: Props) => {
  if ((isSelected || highlightColor !== theme.colors.white) && isDarkMode) {
    return theme.colors.white;
  }
  if (hasComment) return theme.colors.teal700;

  return theme.colors.gray800;
};

export const Verse = styled(Text5)<Props>`
  color: ${getVerseColor};
  font-family: Lora;
  letter-spacing: 0.5px;
  line-height: 36px;
  ${({ isHighlighted }) => (isHighlighted ? "text-decoration: underline" : "")};
  background-color: ${getBackgroundColor};
  text-decoration-color: ${getVerseColor};
`;

export const CommentIcon = styled.TouchableOpacity`
  padding-right: ${spacers.ss3};
  top: -10px;
`;
