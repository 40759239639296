import { messages } from "./intl";

export const titleMap = new Map([
  ["smileys", messages.smileys],
  ["animals", messages.animals],
  ["people", messages.people],
  ["food", messages.food],
  ["activities", messages.activities],
  ["travel", messages.travel],
  ["objects", messages.objects],
  ["flags", messages.flags],
  ["symbols", messages.symbols],
]);
