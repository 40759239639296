import styled from "styled-components/native";
import { Text2 } from "~/components/text";

import { colors } from "~/styles/theme";
import { getWebContainerStyle } from "~/utils/web";

export const Container = styled.SafeAreaView`
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
  ${getWebContainerStyle()}
`;

export const TextBox = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Currency = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray600};
  margin-right: 2px;
`;
