import React from "react";
import { BottomSheetModal } from "~/components/bottom-sheet";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "styled-components/native";

import * as routes from "~/constants/routes";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getLeaderGroupsWithoutPlan } from "~/state/groups/selectors";
import { addResource } from "~/state/groups/actions";
import { useAlerts } from "~/state/alerts";
import { colors } from "~/styles/theme";

import { GroupAvatar } from "~/components/group-avatar";
import { AvatarSize } from "~/components/group-avatar/types";
import { PlanProps } from "~/screens/plan";

import {
  Container,
  Content,
  Title,
  Item,
  ImageBox,
  Name,
  Overlay,
} from "./styles";
import { messages } from "./intl";

interface Props {
  planId: string;
  modalRef: React.RefObject<BottomSheetModal>;
  onLoading: (arg0: boolean) => void;
  isResume: boolean;
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
}
const HEADER_HEIGHT = 110;
const ITEM_HEIGHT = 56;
const MAX_HEIGHT = 500;

export const GroupsResourceSheet = React.memo<Props>(
  ({ planId, modalRef, onLoading, isResume, isOpen, setIsOpen }) => {
    const groups = useAppSelector((state) =>
      getLeaderGroupsWithoutPlan(state, planId)
    );

    const snapPoints = React.useMemo(() => {
      const height = Math.min(
        ITEM_HEIGHT * (groups.length + 1) + HEADER_HEIGHT,
        MAX_HEIGHT
      );
      return [height];
    }, [groups.length]);

    const { pushAlert } = useAlerts();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const navigation = useNavigation<PlanProps["navigation"]>();

    const handleClose = React.useCallback(() => {
      modalRef?.current?.close();
      setIsOpen(false);
    }, [modalRef, setIsOpen]);

    const onSuccess = React.useCallback(() => {
      onLoading(false);

      pushAlert({
        message: messages.success,
        color: colors.emerald600,
      });
    }, [onLoading, pushAlert]);

    const onError = React.useCallback(() => {
      onLoading(false);
    }, [onLoading]);

    const handleAddResourceToGroup = React.useCallback(
      (groupId: string) => {
        onLoading(true);
        handleClose();
        dispatch(
          addResource({
            groupId,
            planId,
            onSuccess,
            onError,
          })
        );
      },
      [onLoading, handleClose, dispatch, planId, onSuccess, onError]
    );

    const handleAddResourceToNewGroup = React.useCallback(() => {
      handleClose();
      navigation.navigate(routes.groupModal, {
        planId,
      });
    }, [handleClose, navigation, planId]);

    const title = isResume ? messages.title : messages.titleStart;

    return (
      <BottomSheetModal
        ref={modalRef}
        index={0}
        snapPoints={snapPoints}
        backdropComponent={() => <Overlay onPress={handleClose} />}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleIndicatorStyle={{ backgroundColor: theme.colors.black }}
        handleStyle={{
          backgroundColor: theme.colors.white,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <Container>
          <Title>{title}</Title>
          <Content>
            {groups.map(({ id, name }) => (
              <Item key={id} onPress={() => handleAddResourceToGroup(id)}>
                <ImageBox>
                  <GroupAvatar text={name} size={AvatarSize.Regular} />
                </ImageBox>
                <Name>{name}</Name>
              </Item>
            ))}
            <Item key="new-group" onPress={handleAddResourceToNewGroup}>
              <ImageBox>
                <MaterialIcons
                  name="group-add"
                  size={22}
                  color={theme.colors.white}
                />
              </ImageBox>
              <Name>{messages.newGroup}</Name>
            </Item>
          </Content>
        </Container>
      </BottomSheetModal>
    );
  }
);
