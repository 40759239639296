import React from "react";
import { ActivityIndicator } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { colors } from "~/styles/theme";

import { BubbleDirection } from "../../types";
import { NeutralCircle, NeutralCircleContainer, bubbleShadow } from "./styles";
import { useAppNetInfo } from "~/state/hooks";

interface NavigationArrowProps {
  direction: BubbleDirection;
  loading: boolean;
  onPress(direction: BubbleDirection): void;
}

export const NavigationArrow = React.memo(
  ({ direction, loading, onPress }: NavigationArrowProps) => {
    const { isConnected } = useAppNetInfo();

    const onPressHandler = React.useCallback(() => {
      if (loading) return;

      onPress(direction);
    }, [direction, loading, onPress]);

    const iconName =
      direction === BubbleDirection.Prev ? "chevron-left" : "chevron-right";

    return (
      <NeutralCircleContainer onPress={onPressHandler} disabled={!isConnected}>
        <NeutralCircle style={bubbleShadow} disabled={!isConnected}>
          {loading ? (
            <ActivityIndicator />
          ) : (
            <Icon
              name={iconName}
              size={40}
              color={!isConnected ? colors.gray200 : colors.white}
            />
          )}
        </NeutralCircle>
      </NeutralCircleContainer>
    );
  }
);
