import styled, { css } from "styled-components/native";

import { Text2, Text3, Text4, Text5 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { getWebContainerStyle } from "~/utils/web";

const textStyle = css`
  line-height: 22px;
  letter-spacing: 0.5px;
  font-family: SFPro;
  color: ${({ theme }) => theme.colors.gray900};
`;

const titleTextStyle = css`
  font-family: MontserratMedium;
  margin-bottom: ${spacers.ss3};
  padding-right: ${spacers.ss3};
  letter-spacing: -0.5px;
  flex: 1;
`;

const inputStyle = isWeb
  ? css`
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme.colors.gray300};
      padding: ${spacers.ss3};
      margin-bottom: ${spacers.ss4};
      font-size: 16px;
      font-family: SFPro;
      letter-spacing: 0px;
    `
  : "";

export const Container = styled.View`
  padding-horizontal: ${spacers.ss6};
  padding-top: ${spacers.ss6};
  ${getWebContainerStyle(960)}
`;

export const HeaderBox = styled.View`
  margin-top: ${spacers.ss3};
  margin-bottom: ${spacers.ss7};
`;

export const TitleBox = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const Title = styled(Text5)`
  ${titleTextStyle}
`;

export const LastEdited = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray500};
`;

export const Section = styled.ScrollView`
  margin-bottom: ${spacers.ss7};
`;

export const NoteText = styled(Text3)`
  ${textStyle};
  padding-top: 6px;
`;

export const NoteLabel = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray500};
  padding-bottom: ${spacers.ss3};
`;

export const NoteInputText = styled.TextInput.attrs(({ theme }) => ({
  placeholderTextColor: theme.colors.gray600,
}))`
  font-size: 16px;
  ${isWeb ? "min-height: 260px;" : ""}
  ${textStyle};
  ${inputStyle};
`;

export const TitleInputText = styled.TextInput`
  font-size: 20px;
  line-height: 28px;
  ${titleTextStyle};
  ${inputStyle}
  color: ${({ theme }) => theme.colors.black}
`;
