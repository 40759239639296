import React from "react";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { BottomSheetModal } from "~/components/bottom-sheet";
import { useTheme } from "styled-components/native";

import { IconSizes } from "~/components/icon";
import { MainButtonType } from "~/state/content-progress";

import { GroupsResourceSheet } from "./group-resource";
import { messages } from "./intl";
import {
  ButtonText,
  ResumeButtonGroup,
  ResumeButtonProgress,
  ButtonContainer,
  ActionButton,
  ButtonTextBox,
} from "./styles";

interface PlanMainButtonProps {
  onPress(): void;
  planId: string;
  type: MainButtonType;
  isDisabled: boolean;
}

const getLeftIcon = (type: MainButtonType, color: string) => {
  const icons = {
    [MainButtonType.Resume]: (
      <ResumeButtonProgress>
        <MaterialCommunityIcons
          name="progress-check"
          size={IconSizes.Medium}
          color={color}
        />
      </ResumeButtonProgress>
    ),
    [MainButtonType.Restart]: (
      <ResumeButtonProgress>
        <MaterialCommunityIcons
          name="restart"
          size={IconSizes.Medium}
          color={color}
        />
      </ResumeButtonProgress>
    ),
    [MainButtonType.Start]: null,
  };

  return icons[type];
};

export const PlanMainButton: React.FC<PlanMainButtonProps> = React.memo(
  ({ onPress, type, planId, isDisabled }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const bottomSheetModalRef = React.useRef<BottomSheetModal>(null);
    const theme = useTheme();

    const onGroupPress = () => {
      bottomSheetModalRef.current?.present();
      setIsModalOpen(true);
    };

    const message =
      type === MainButtonType.Restart
        ? messages.restartButton
        : type === MainButtonType.Resume
        ? messages.inProgress
        : messages.startButton;

    // @TODO: for v1, the resume button is disabled
    const actionButtonProps =
      type === MainButtonType.Resume
        ? { onPress: undefined, disabled: true }
        : { onPress };

    const isGroupButtonDisabled = isDisabled || isLoading;

    return (
      <>
        <ButtonContainer>
          <ActionButton {...actionButtonProps}>
            {getLeftIcon(type, theme.colors.white)}

            <ButtonTextBox
              hasMargin={
                type === MainButtonType.Resume ||
                type === MainButtonType.Restart
              }
            >
              <ButtonText>{message}</ButtonText>
            </ButtonTextBox>
          </ActionButton>

          <ResumeButtonGroup
            onPress={onGroupPress}
            disabled={isGroupButtonDisabled}
          >
            <MaterialIcons
              name="group-add"
              size={IconSizes.Medium}
              color={theme.colors.white}
            />
          </ResumeButtonGroup>
        </ButtonContainer>

        <GroupsResourceSheet
          planId={planId}
          modalRef={bottomSheetModalRef}
          onLoading={setIsLoading}
          isResume={type === MainButtonType.Resume}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        />
      </>
    );
  }
);
