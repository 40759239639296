import * as React from "react";
import { Platform, Linking } from "react-native";
import Constants from "expo-constants";
import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";
import Icon from "@expo/vector-icons/Ionicons";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTheme } from "styled-components/native";

import { BackButton } from "~/components/back-button";
import { SocialButton } from "~/components/button";
import { Screen } from "~/components/screen";
import { ButtonTypes } from "~/components/button/types";
import { useAlert } from "~/components/alert";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  loginWithApple,
  loginWithGoogle,
  preLogout,
} from "~/state/user/actions";
import { formatMessage } from "~/utils/translation";
import { getIsFirstLaunch } from "~/utils/first-launch";
import { makeExternalLinks } from "~/utils/external-links";
import { getIsDarkMode } from "~/state/settings";
import { getBackgroundImage } from "~/constants";

import { InviteBox } from "./components/invite-box";
import {
  Content,
  TopContainer,
  BackgroundWrapper,
  BackgroundImage,
  BottomContainer,
  Title,
  ButtonBox,
  LogoutButton,
  Links,
  LinkBox,
  LinkText,
  FaqButton,
  DisclaimerText,
  TitleBox,
  Description,
} from "./styles";
import { messages } from "./intl";

interface Props {
  title?: MessageDescriptor;
  description?: MessageDescriptor;
  children: React.ReactNode;
  hideSocialButtons?: boolean;
  hasBackButton?: boolean;
  hasLogoutButton?: boolean;
  hasLinks?: boolean;
  hasDisclaimer?: boolean;
  hasFAQ?: boolean;
  hasPadding?: boolean;
  id: string;
  screenName: string;
  onBack?: () => void;
  isSignIn?: boolean;
  step?: number;
}

WebBrowser.maybeCompleteAuthSession();

const backgroundLight = require("<assets>/resources/gradient.png");
const backgroundDark = require("<assets>/resources/gradient-dark.jpg");
const backgroundWebLight = require("<assets>/resources/gradient-web.jpg");
const backgroundWebDark = require("<assets>/resources/gradient-web-dark.jpg");

export const AuthScreen = React.memo<Props>(
  ({
    title,
    description,
    children,
    hideSocialButtons,
    hasBackButton,
    hasLogoutButton,
    hasLinks,
    hasDisclaimer,
    hasFAQ,
    hasPadding = true,
    screenName,
    isSignIn,
    id,
    step,
    onBack,
  }) => {
    const [showInvite, setShowInvite] = React.useState(false);
    const dispatch = useAppDispatch();
    const isDarkMode = useAppSelector(getIsDarkMode);

    const { top } = useSafeAreaInsets();
    const { showAlert } = useAlert();
    const theme = useTheme();

    const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
      webClientId: Constants?.expoConfig?.extra?.clientId,
      expoClientId: Constants?.expoConfig?.extra?.clientId,
      iosClientId: Constants?.expoConfig?.extra?.appClientId,
      androidClientId: Constants?.expoConfig?.extra?.appClientId,
      scopes: ["email", "profile"],
    });

    React.useEffect(() => {
      if (response?.type === "success") {
        const { id_token } = response.params;

        dispatch(loginWithGoogle({ idToken: id_token }));
      }
    }, [response, dispatch]);

    const handleLoginWithApple = React.useCallback(() => {
      dispatch(loginWithApple());
    }, [dispatch]);

    const handleOpenLink = React.useCallback((url: string) => {
      Linking.openURL(url);
    }, []);

    const handleLogout = React.useCallback(() => {
      showAlert(
        formatMessage(messages.logOutTitle),
        formatMessage(messages.logOutText),
        [
          {
            text: formatMessage(messages.logOutCancel),
            style: "cancel",
          },
          {
            text: formatMessage(messages.logOutOk),
            onPress: () => dispatch(preLogout()),
          },
        ]
      );
    }, [dispatch, showAlert]);

    React.useEffect(() => {
      getIsFirstLaunch().then(setShowInvite);
    }, []);

    const showBottomContainer =
      !hideSocialButtons || hasLinks || showInvite || hasDisclaimer;

    const backButtonProps =
      typeof onBack === "function" ? { onPress: onBack } : {};

    const buttonAppleText = isSignIn
      ? messages.buttonAppleSignIn
      : messages.buttonAppleSignUp;
    const buttonGoogleText = isSignIn
      ? messages.buttonGoogleSignIn
      : messages.buttonGoogleSignUp;

    const image = getBackgroundImage(isDarkMode);

    return (
      <Screen screenName={screenName} isView>
        <BackgroundWrapper>
          <BackgroundImage source={image} resizeMode="cover" />
        </BackgroundWrapper>

        <TopContainer hasBackButton={!!hasBackButton} paddingTop={top}>
          {hasBackButton ? (
            <BackButton hasText {...backButtonProps} key={step} />
          ) : null}

          {hasLogoutButton ? (
            <LogoutButton onPress={handleLogout}>
              <Icon name="close" size={20} color={theme.colors.gray800} />
            </LogoutButton>
          ) : null}

          <Content hasMargin={!title} hasPadding={hasPadding}>
            {title || description ? (
              <TitleBox>
                {title ? <Title>{title}</Title> : null}
                {description ? <Description>{description}</Description> : null}
              </TitleBox>
            ) : null}
            {children}

            {!hideSocialButtons ? (
              <Content hasMargin>
                {Platform.OS === "ios" || Platform.OS === "web" ? (
                  <ButtonBox>
                    <SocialButton
                      text={buttonAppleText}
                      onPress={handleLoginWithApple}
                      type={ButtonTypes.Apple}
                    />
                  </ButtonBox>
                ) : null}

                <ButtonBox>
                  <SocialButton
                    text={buttonGoogleText}
                    onPress={() => promptAsync()}
                    type={ButtonTypes.Google}
                  />
                </ButtonBox>
              </Content>
            ) : null}

            {hasFAQ ? (
              <FaqButton
                onPress={() => handleOpenLink(makeExternalLinks().FAQS)}
              >
                <Icon
                  name="help-circle-outline"
                  size={20}
                  color={theme.colors.gray500}
                />
              </FaqButton>
            ) : null}
          </Content>
        </TopContainer>

        {showBottomContainer ? (
          <BottomContainer
            isShort={hideSocialButtons}
            hasInvite={showInvite}
            hasDisclaimer={hasDisclaimer}
          >
            {hasLinks ? (
              <Links>
                <LinkBox
                  onPress={() =>
                    handleOpenLink(makeExternalLinks().PRIVACY_POLICY)
                  }
                >
                  <LinkText>{messages.privacyPolicy}</LinkText>
                </LinkBox>
                <LinkBox
                  onPress={() =>
                    handleOpenLink(makeExternalLinks().TERMS_AND_CONDITIONS)
                  }
                  isRight
                >
                  <LinkText>{messages.terms}</LinkText>
                </LinkBox>
              </Links>
            ) : null}

            {showInvite ? <InviteBox /> : null}

            {hasDisclaimer ? (
              <DisclaimerText>{messages.disclaimer}</DisclaimerText>
            ) : null}
          </BottomContainer>
        ) : null}
      </Screen>
    );
  }
);
