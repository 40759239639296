import styled from "styled-components/native";
import { colors, spacers } from "~/styles/theme";
import { Text3 } from "~/components/text";
import { isWeb } from "~/utils/platform";

export const FiltersContainer = styled.ScrollView``;

export const ResetFiltersButton = styled.TouchableOpacity`
  align-items: center;
  margin: ${spacers.ss8} 0 ${isWeb ? spacers.ss12 : spacers.ss10};
`;

export const ResetFiltersButtonText = styled(Text3)`
  color: ${({ theme }) => theme.colors.red500};
`;
