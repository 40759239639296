import styled from "styled-components/native";
import { spacers } from "~/styles/theme";

export const ListContainer = styled.View<{ isDarkMode: boolean }>`
  background-color: ${({ theme, isDarkMode }) =>
    isDarkMode ? theme.colors.gray300 : theme.colors.blue50};
`;

export const WebListContainer = styled.View`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${spacers.ss5};
  max-height: 500px;
`;
