import GestureRecognizer from "react-native-swipe-gestures";
import styled from "styled-components/native";
import { css } from "styled-components/native";

import { TAB_BAR_HEIGHT } from "~/components/tab-bar/styles";
import { spacers } from "~/styles/theme";
import { isWeb, windowHeight } from "~/utils/platform";

export const horizontalMargin = isWeb ? spacers.ss8 : spacers.ss5;

export const GestureRecognizerContainer = styled(GestureRecognizer).attrs({
  config: {
    directionalOffsetThreshold: 150,
  },
})`
  ${isWeb
    ? css`
        height: ${windowHeight * 1.1};
      `
    : css`
        flex: 1;
      `}
`;

export const ScrollViewFakePadding = styled.View<{ extraMargin: number }>`
  height: ${({ extraMargin }) => TAB_BAR_HEIGHT + extraMargin ?? 0}px;
`;

export const ContentBox = styled.View<{ minHeight: number }>`
  min-height: ${({ minHeight }) => minHeight}px;
  margin-bottom: ${spacers.ss6};
  ${isWeb ? "padding-top: 12px;" : ""}
`;
