import styled, { css, Theme } from "styled-components/native";

import { Text3, Text3Center } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

const webStyles = isWeb
  ? css`
      flex-basis: auto;
      padding-bottom: ${spacers.ss8};
    `
  : "";

export const Container = styled.View`
  flex: 1;
  padding-bottom: 120px;
  background-color: ${({ theme }) => (theme as Theme)?.colors.white};
  ${webStyles}
`;

export const TitleBox = styled.View`
  margin-bottom: ${spacers.ss6};
`;

export const Content = styled.View`
  margin-top: ${spacers.ss8};
  margin-horizontal: ${spacers.ss8};
  flex: 1;
  ${isWeb ? "flex-basis: auto;" : ""}
`;

export const Description = styled(Text3)`
  margin-bottom: ${spacers.ss8};
`;

export const ErrorText = styled(Text3)`
  color: ${({ theme }) => theme.colors.red500};
  text-align: center;
`;

export const InputBox = styled.View`
  margin-bottom: ${spacers.ss8};
`;

export const ButtonBox = styled.View`
  margin-horizontal: ${spacers.ss8};
`;

export const PasteButton = styled.TouchableOpacity`
  margin-vertical: ${spacers.ss4};
`;

export const PasteText = styled(Text3Center)`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
