import * as React from "react";
import { FlatList, ListRenderItem } from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useRoute } from "@react-navigation/native";
import { LibraryStackParamList } from "~/navigation/library-stack";
import { useTheme } from "styled-components/native";

import { HeaderBar } from "~/components/header-bar";
import { Screen } from "~/components/screen";
import * as routes from "~/constants/routes";
import {
  GetPlanProgressesByUserIdData,
  ProgressState,
  getPlansProgressCount,
} from "~/state/content-progress";
import { colors } from "~/styles/theme";
import { useAppSelector } from "~/state/hooks";
import { formatMessage } from "~/utils/translation";

import { ProgressRow } from "./components/progress-row";
import { NoPlansFound } from "./components/no-plans-found";
import { Container, EmptyContainer, flatListStyles } from "./styles";
import { messages } from "./intl";

const keyExtractor = (item: GetPlanProgressesByUserIdData, index: number) =>
  `${item.planProgressId}-${index}`;

export type LibraryProgressProps = NativeStackScreenProps<
  LibraryStackParamList,
  typeof routes.libraryProgress
>;

export const LibraryProgress: React.FC<LibraryProgressProps> = () => {
  const data = useAppSelector(getPlansProgressCount);
  const { type } = useRoute<LibraryProgressProps["route"]>().params;
  const theme = useTheme();

  const plans =
    type === ProgressState.InProgress
      ? data?.plansInProgress ?? []
      : data?.plansCompleted ?? [];

  const headerBarTitle = React.useMemo(
    () =>
      type === ProgressState.InProgress
        ? formatMessage(messages.inProgressTitle, { count: plans.length })
        : formatMessage(messages.completedTitle, { count: plans.length }),
    [plans.length, type]
  );

  const noItemsTitle = React.useMemo(
    () =>
      type === ProgressState.InProgress
        ? formatMessage(messages.noPlansInProgress)
        : formatMessage(messages.noPlansCompleted),
    [type]
  );

  const renderItem: ListRenderItem<GetPlanProgressesByUserIdData> =
    React.useCallback(
      ({ item, index }) => (
        <ProgressRow isLast={index === plans.length - 1} planProgress={item} />
      ),
      [plans.length]
    );

  return (
    <Screen screenName="library-progress" isView hasFixedHeader>
      <HeaderBar
        withCastButton={false}
        iconColor={theme.colors.black}
        title={headerBarTitle}
        isSticky
      />

      <Container>
        {plans?.length > 0 ? (
          <FlatList
            data={plans}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            contentContainerStyle={flatListStyles}
          />
        ) : (
          <EmptyContainer>
            <NoPlansFound title={noItemsTitle} />
          </EmptyContainer>
        )}
      </Container>
    </Screen>
  );
};
