import * as React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  SortDirection,
} from "@mui/material";
import { Done } from "@mui/icons-material";

import {
  TableHead,
  stableSort,
  getComparator,
} from "~/dashboard/components/table";

import { Breadcrumb } from "~/dashboard/components/breadcrumb";
import { useTable } from "~/dashboard/hooks/useTable";
import { useAppSelector } from "~/state/hooks";
import { getDetailedChurchData } from "~/state/stats/selectors";
import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";
import { ChurchType } from "~/state/stats/types";

interface Props {
  onBack: () => void;
  type: ChurchType;
}

const getTitle = (type: ChurchType) => {
  switch (type) {
    case ChurchType.US:
      return messages.churchesUs;
    case ChurchType.AG:
      return messages.churchesAg;
    default:
      return messages.churches;
  }
};

export const ChurchesData = ({ onBack, type }: Props) => {
  const stats = useAppSelector((state) => getDetailedChurchData(state, type));

  const columns = [
    {
      id: "churchName",
      align: "left",
      disablePadding: false,
      label: formatMessage(messages.churchName),
    },
    {
      id: "churchState",
      align: "center",
      disablePadding: false,
      label: formatMessage(messages.churchState),
    },
    {
      id: "churchZip",
      align: "center",
      disablePadding: false,
      label: formatMessage(messages.churchZipCode),
    },
    {
      id: "country",
      align: "center",
      disablePadding: false,
      label: formatMessage(messages.churchCountry),
    },
    {
      id: "isAgChurch",
      align: "center",
      disablePadding: false,
      label: formatMessage(messages.agChurch),
    },
    {
      id: "users",
      align: "center",
      disablePadding: false,
      label: formatMessage(messages.userCount),
    },
  ];

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    handleChangePage,
    handleRequestSort,
    handleChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "users", defaultRowsPerPage: 12 });

  if (!stats.length) {
    return null;
  }

  return (
    <>
      <Box className="breadcrumb" sx={{ mb: 2 }}>
        <Breadcrumb
          routeSegments={[
            {
              name: "Dashboard",
              onClick: onBack,
            },
            { name: formatMessage(getTitle(type)) },
          ]}
        />
      </Box>

      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 600 }}>
            <TableHead
              order={order as SortDirection}
              orderBy={orderBy}
              headCells={columns}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {stableSort(stats, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.planId}
                      onClick={() => {}}
                    >
                      <TableCell component="th" scope="row">
                        {row.churchName}
                      </TableCell>

                      <TableCell align="center">{row.churchState}</TableCell>

                      <TableCell align="center">{row.churchZip}</TableCell>

                      <TableCell align="center">{row.churchCountry}</TableCell>

                      <TableCell align="center">
                        {row.isAgChurch ? (
                          <Done color="success" fontSize="small" />
                        ) : null}
                      </TableCell>

                      <TableCell align="center">{row.count}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          page={page}
          component="div"
          count={stats.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[12, 48, 96]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};
