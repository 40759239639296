import * as React from "react";
import { TouchableOpacity, TextInput } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";

import { formatMessage } from "~/utils/translation";

import { useAppSelector } from "~/state/hooks";
import { getIsDarkMode } from "~/state/settings";

import { Container, Input } from "./styles";

interface Props {
  value: string;
  placeholder: MessageDescriptorValues;
  onChange: (arg0: string) => void;
}

export const SearchInput = React.memo<Props>(
  ({ value, placeholder, onChange }) => {
    const translatedPlaceholder = formatMessage(placeholder);
    const inputRef = React.useRef<TextInput>();
    const theme = useTheme();
    const isDarkMode = useAppSelector(getIsDarkMode);

    const onIconPress = React.useCallback(() => {
      inputRef.current?.focus();
    }, []);

    return (
      <Container>
        <TouchableOpacity
          onPress={onIconPress}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
        >
          <Icon
            name="search"
            size={20}
            color={isDarkMode ? theme.colors.gray500 : theme.colors.gray400}
          />
        </TouchableOpacity>

        <Input
          value={value}
          placeholder={translatedPlaceholder}
          onChangeText={onChange}
          placeholderTextColor={
            isDarkMode ? theme.colors.gray500 : theme.colors.gray300
          }
          ref={inputRef}
        />
      </Container>
    );
  }
);
