import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { BlurView } from "expo-blur";

export const Container = styled.TouchableOpacity`
  ${StyleSheet.absoluteFill};
`;

export const Content = styled(BlurView).attrs({
  intensity: 0,
})`
  ${StyleSheet.absoluteFill};
  background-color: rgba(0, 0, 0, 0.7);
`;

export const MessageContainer = styled.View`
  position: absolute;
`;

export const EmojiContainer = styled.View`
  position: absolute;
`;
