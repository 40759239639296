import * as React from "react";
import { useTheme } from "styled-components/native";

import { Icon, IconTypes } from "~/components/icon";

import {
  Container,
  IconContainer,
  Title,
  TextContent,
  TextRegular,
} from "./styles";
import { messages } from "./intl";

export const Content = React.memo(() => {
  const theme = useTheme();
  return (
    <Container>
      <IconContainer>
        <Icon
          type={IconTypes.ChurchBig}
          size={50}
          color={theme?.colors.black}
        />
      </IconContainer>

      <Title>{messages.title}</Title>
      <TextContent>
        <TextRegular>{messages.description}</TextRegular>
      </TextContent>
    </Container>
  );
});
