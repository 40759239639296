import styled from "styled-components/native";

import { Text1, Text3 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

export const VerseBox = styled.View`
  background-color: ${({ theme }) => theme.colors.gray100};
  padding: ${spacers.ss5} ${spacers.ss6};
  margin-bottom: ${spacers.ss6};
  border-radius: 12px;
`;

export const TextWrapper = styled.Text`
  line-height: 24px;
`;

export const VerseNumber = styled(Text1)`
  font-family: LoraSuperscript;
  font-size: 9px;
  color: ${({ theme }) => theme.colors.amber600};
`;

export const VerseText = styled(Text3)`
  font-family: Lora;
  letter-spacing: 0.5px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.gray800};
`;
