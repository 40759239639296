import styled from "styled-components/native";
import { Text3 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

export const contentContainerStyle = {
  borderRadius: 8,
};

export const Section = styled.View`
  margin-bottom: ${isWeb ? 32 : 370}px;
`;

export const EmptyText = styled(Text3)`
  color: ${({ theme }) => theme.colors.gray800};
  text-align: center;
  padding: ${spacers.ss6};
`;
