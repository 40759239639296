import * as React from "react";

import { getContent } from "~/components/chat/message";
import { EmojiBar } from "~/components/chat/emoji-bar";

import { MessageData, MessageType } from "~/state/chat/types";

import { Container, Content, MessageContainer, EmojiContainer } from "./styles";

interface Props {
  onPress: () => void;
  onEmojiPress: (icon: string) => void;
  onMorePress: () => void;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  text?: string;
  type: MessageType;
  data?: MessageData;
  isReversed?: boolean;
  isReply?: boolean;
  isEmoji?: boolean;
}

export const ActionOverlay = React.memo<Props>(
  ({
    onPress,
    onEmojiPress,
    onMorePress,
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    text = "",
    type,
    data,
    isReversed,
    isReply,
    isEmoji,
  }) => {
    const top = isReply ? 70 + y : y;
    return (
      <Container onPress={onPress}>
        <Content>
          {isEmoji ? (
            <EmojiContainer style={{ top: top - 50, left: x, width, height }}>
              <EmojiBar onPress={onEmojiPress} onMorePress={onMorePress} />
            </EmojiContainer>
          ) : null}

          <MessageContainer style={{ top, left: x, width, height }}>
            {getContent(type, text, isReversed, data)}
          </MessageContainer>
        </Content>
      </Container>
    );
  }
);
