import * as React from "react";
import { Modal, Portal } from "react-native-paper";
import { useTheme } from "styled-components/native";

import { ModalContext } from "./context.web";

interface Props {
  children: React.ReactNode;
}

export const ModalProvider = ({ children }: Props) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [content, setContent] = React.useState<React.ReactNode | null>(null);
  const theme = useTheme();

  const showModal = (node: React.ReactNode) => {
    setContent(node);
    setIsVisible(true);
  };
  const hideModal = () => setIsVisible(false);

  const state = {
    isVisible,
    showModal,
    hideModal,
  };

  const containerStyle = {
    backgroundColor: theme.colors.white,
    maxWidth: 600,
    minWidth: 400,
    borderRadius: 6,
    alignSelf: "center",
    overflow: "hidden",
  };

  return (
    <ModalContext.Provider value={state}>
      {children}
      {isVisible ? (
        <Portal>
          <div
            style={{
              zIndex: 9998,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Modal
              visible
              onDismiss={hideModal}
              contentContainerStyle={containerStyle}
            >
              {content}
            </Modal>
          </div>
        </Portal>
      ) : null}
    </ModalContext.Provider>
  );
};
