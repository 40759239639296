import * as React from "react";
import { FlatList } from "react-native";

import { SortEnum } from "~/state/notes/types";
import { useAppSelector } from "~/state/hooks";
import { getBibleNotesFiltered } from "~/state/bible-notes/selectors";
import { BibleNote } from "~/state/bible-notes/types";

import { NoteItem } from "../note-item";
import { Section, EmptyText, contentContainerStyle } from "./styles";

interface Props {
  searchTerm: string;
  sortMethod: SortEnum;
  emptyMessage: TextType;
  onPress: (sessionId: string) => void;
}

export const Content = React.memo<Props>(
  ({ searchTerm, sortMethod, emptyMessage, onPress }) => {
    const data = useAppSelector((state) =>
      getBibleNotesFiltered(state, `${sortMethod}:${searchTerm}`)
    );

    const renderItem = ({
      item: { id, bookId, chapterId, verses, text, lastUpdated },
    }: {
      item: BibleNote;
    }) => {
      return (
        <NoteItem
          bookId={bookId}
          chapterId={chapterId}
          verses={verses}
          text={text}
          timestamp={lastUpdated}
          onPress={() => onPress(id)}
        />
      );
    };

    return data.length ? (
      <Section>
        <FlatList
          data={data}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={contentContainerStyle}
          removeClippedSubviews
          windowSize={7}
          renderItem={renderItem}
          key={searchTerm}
        />
      </Section>
    ) : (
      <EmptyText>{emptyMessage}</EmptyText>
    );
  }
);
