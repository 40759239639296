export enum BubbleEnum {
  PrevSession = "prev-session",
  Completed = "completed",
  InProgress = "in-progress",
  NotStarted = "not-started",
  NextSession = "next-session",
}

export enum BubbleDirection {
  Prev = "prev",
  Next = "next",
}

export interface BubbleItem {
  id: number;
  title?: string;
  body?: string;
  type: BubbleEnum;
}

export interface BubbleProps {
  bubbleType?: BubbleEnum;
  children?: React.ReactNode;
}
