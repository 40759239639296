import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Card, Grid, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { changeDateRange, getStatsHistory } from "~/state/stats";
import {
  getCurrentStatsRelativeTime,
  getiOSDownloadsHistory,
  getAndroidDownloadsHistory,
  getDateRange,
  getCurrentStats,
  getIsLoadingData,
} from "~/state/stats/selectors";
import { FlexBetween } from "~/dashboard/components/flex-box";
import { H3, Span, Paragraph } from "~/dashboard/components/typography";
import { LOOKER_GEO_REPORT, Screens } from "~/dashboard/constants";
import { formatMessage } from "~/utils/translation";
import { ChurchType, DateRange } from "~/state/stats/types";

import { messages } from "./intl";
import { ComparisonChart } from "./chart";
import { GaugeProgressCard } from "./gauge-card";
import { Cards } from "./cards";
import { StatCards } from "./stat-cards";
import { LineChart } from "./line-chart";
import { AppSessions } from "./app-sessions";
import { SessionProgress } from "./session-progress";

interface Props {
  onNavigate: (screen: Screens) => void;
  setChurchType: (type: ChurchType) => void;
}

export const Dashboard = ({ onNavigate, setChurchType }: Props) => {
  const dispatch = useAppDispatch();

  const statsRelativeTime = useAppSelector(getCurrentStatsRelativeTime);
  const iosDownloads = useAppSelector(getiOSDownloadsHistory);
  const androidDownloads = useAppSelector(getAndroidDownloadsHistory);
  const initialDateRange = useAppSelector(getDateRange);
  const currentStats = useAppSelector(getCurrentStats);
  const isLoading = useAppSelector(getIsLoadingData);

  const [dateRange, setDateRange] = React.useState(initialDateRange);

  const handleDateChange = React.useCallback(
    (newDateRange: DateRange) => {
      setDateRange(newDateRange);
      dispatch(changeDateRange(newDateRange));
      dispatch(getStatsHistory({ dateRange: newDateRange }));
    },
    [dispatch]
  );

  const setDateFrom = React.useCallback(
    (newDate: Dayjs | null) => {
      if (!newDate) {
        return;
      }
      handleDateChange({ ...dateRange, from: newDate });
    },
    [dateRange, handleDateChange]
  );

  const setDateTo = React.useCallback(
    (newDate: Dayjs | null) => {
      if (!newDate) {
        return;
      }
      handleDateChange({ ...dateRange, to: newDate });
    },
    [dateRange, handleDateChange]
  );

  if (!currentStats) {
    return null;
  }

  const slotProps = {
    openPickerIcon: {
      sx: {
        color: "text.primary",
      },
    },
  };

  const opacity = isLoading ? 0.5 : 1;

  return (
    <>
      <FlexBetween mb={2}>
        <H3 sx={{ m: 0, color: "text.primary" }}>
          {formatMessage(messages.overview)}
        </H3>
        <FlexBetween>
          <Box sx={{ marginRight: "8px" }}>
            <DatePicker
              label="From"
              defaultValue={dayjs(dateRange.from)}
              onChange={setDateFrom}
              slotProps={slotProps}
              maxDate={dayjs(dateRange.to).subtract(1, "day")}
              sx={{ color: "pink" }}
            />
          </Box>
          <Box>
            <DatePicker
              label="To"
              defaultValue={dayjs(dateRange.to)}
              onChange={setDateTo}
              disableFuture
              slotProps={slotProps}
              minDate={dayjs(dateRange.from)}
            />
          </Box>
        </FlexBetween>
      </FlexBetween>

      <Cards />

      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <Card sx={{ my: 3 }} elevation={3}>
            <FlexBetween p={2} mb={2} bgcolor="rgba(0, 0, 0, 0.02)">
              <Span sx={{ fontWeight: "500", color: "text.primary" }}>
                {formatMessage(messages.statistics)}
              </Span>
            </FlexBetween>

            <ComparisonChart height={400} />
          </Card>
        </Grid>

        <Grid item md={4} xs={12} sx={{ my: 3, opacity }}>
          <LineChart
            title="iOS"
            subtitle={formatMessage(messages.totalDownloads)}
            data={iosDownloads}
          />
          <LineChart
            title="Android"
            subtitle={formatMessage(messages.totalDownloads)}
            data={androidDownloads}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ opacity }}>
        <Grid item md={5} xs={12}>
          <StatCards onNavigate={onNavigate} setChurchType={setChurchType} />
        </Grid>

        <Grid item md={3} xs={12} sx={{ opacity }}>
          <GaugeProgressCard />
        </Grid>

        <Grid item md={4} xs={12}>
          <iframe
            width="100%"
            height="360"
            src={LOOKER_GEO_REPORT}
            style={{ border: 0 }}
          ></iframe>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12} sx={{ opacity }}>
          <AppSessions />
        </Grid>

        <Grid item md={6} xs={12} sx={{ opacity }}>
          <SessionProgress />
        </Grid>
      </Grid>
      <Grid>
        {statsRelativeTime ? (
          <Paragraph sx={{ m: 0 }}>
            {formatMessage(messages.lastUpdated, {
              value: statsRelativeTime,
            })}
          </Paragraph>
        ) : null}
      </Grid>
    </>
  );
};
