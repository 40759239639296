import * as React from "react";
import { Box, Card, Grid, Icon, IconButton, useTheme } from "@mui/material";

import { H3, Paragraph } from "~/dashboard/components/typography";
import { getCurrentStats, getIsLoadingData } from "~/state/stats/selectors";
import { useAppSelector } from "~/state/hooks";
import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";

export const Cards = () => {
  const data = useAppSelector(getCurrentStats);
  const isLoading = useAppSelector(getIsLoadingData);

  const statList = [
    {
      icon: "people",
      amount: data.totalUsers,
      title: formatMessage(messages.users),
    },
    {
      icon: "people",
      amount: data.totalProfiles,
      title: formatMessage(messages.profiles),
    },
    {
      icon: "people",
      amount: data.activeUsers,
      title: formatMessage(messages.activeUsers),
    },
    {
      icon: "groups",
      amount: data.totalGroups,
      title: formatMessage(messages.groups),
    },
    {
      icon: "trending_up",
      amount: data.usersPerGroup,
      title: formatMessage(messages.membersPerGroup),
    },
    {
      icon: "trending_up",
      amount: data.impact,
      title: formatMessage(messages.impact),
    },
  ];
  const { palette } = useTheme();
  const textMuted = palette.text.secondary;

  return (
    <div>
      <Grid container spacing={3}>
        {statList.map((item) => (
          <Grid key={item.title} item lg={2} md={4} sm={6} xs={12}>
            <Card
              elevation={3}
              sx={{
                p: {
                  xs: "10px",
                  md: "12px",
                  lg: "16px",
                },
                display: "flex",
                opacity: isLoading ? 0.5 : 1,
              }}
            >
              <div>
                <IconButton
                  size="small"
                  sx={{
                    padding: {
                      md: "4px",
                      lg: "8px",
                    },
                    background: "rgba(0, 0, 0, 0.01)",
                  }}
                >
                  <Icon sx={{ color: textMuted }}>{item.icon}</Icon>
                </IconButton>
              </div>
              <Box
                sx={{
                  ml: {
                    xs: "16px",
                    md: "12px",
                    lg: "8px",
                  },
                  width: "100%",
                }}
              >
                <H3
                  sx={{
                    mt: "-4px",
                    fontSize: {
                      xs: "18px",
                      sm: "22px",
                      md: "26px",
                      lg: "28px",
                    },
                    textAlign: "left",
                  }}
                >
                  {item.amount?.toLocaleString()}
                </H3>
                <Paragraph sx={{ m: 0, color: textMuted, textAlign: "left" }}>
                  {item.title}
                </Paragraph>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
