import * as React from "react";
import { View, TouchableOpacity } from "react-native";
import Svg, { Polygon } from "react-native-svg";
import { useTheme } from "styled-components/native";

import { Alignment, Position } from "~/state/tutorial/types";
import { colors } from "~/styles/theme";

import { Container, Text, Tip, ButtonBox, ButtonText } from "./styles";
import { messages } from "./intl";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";

interface Props {
  text: string;
  position: Position | null;
  alignmnent?: Alignment;
  isLast: boolean;
  buttonText?: TextType;
  onSkip: () => void;
  onNext: () => void;
  onDone: () => void;
}

const TIP_SIZE = 10;
const HORIZONTAL_PADDING = 10;

interface TriangleProps {
  size: number;
  color: string;
  borderRadius: number;
  upsideDown: boolean;
}

const Triangle = React.memo<TriangleProps>(
  ({ size, color, borderRadius, upsideDown }) => {
    const height = size / 1.8;
    const rotation = upsideDown ? 180 : 0;

    const points = upsideDown
      ? `0,${height} ${size},${height} ${size / 2},0`
      : `0,0 ${size},${0} ${size / 2},${height}`;

    return (
      <Svg height={height} width={size} rotation={rotation}>
        <Polygon
          points={points}
          fill={color}
          stroke="transparent"
          strokeWidth={borderRadius}
        />
      </Svg>
    );
  }
);

export const Tooltip = React.memo<Props>(
  ({
    text,
    position,
    alignmnent = Alignment.Bottom,
    buttonText,
    isLast,
    onSkip,
    onNext,
    onDone,
  }) => {
    const [tooltipHeight, setTooltipHeight] = React.useState(0);

    const viewRef = React.useRef<View>(null);
    const theme = useTheme();

    React.useEffect(() => {
      setTimeout(() => {
        if (viewRef?.current) {
          viewRef.current?.measure((x, y, width, height) => {
            setTooltipHeight(height);
          });
        }
      }, 100);
    }, [tooltipHeight, setTooltipHeight]);

    if (!position) {
      return null;
    }

    const isBottom = alignmnent === Alignment.Bottom;

    const top = isBottom
      ? Math.round(position.pageY + position.height + TIP_SIZE)
      : Math.round(position.pageY - tooltipHeight - TIP_SIZE);

    const tipLeft = Math.round(
      position.pageX + position.width / 2 - HORIZONTAL_PADDING - TIP_SIZE / 2
    );

    const doneText = buttonText || messages.done;
    const nextText = buttonText || messages.next;
    const onDismiss = isLast ? onDone : onNext;

    return (
      <Container top={top} padding={HORIZONTAL_PADDING} ref={viewRef}>
        <Tip size={TIP_SIZE} isBottom={isBottom} left={tipLeft}>
          <Triangle
            size={18}
            color={theme.colors.gray800}
            borderRadius={4}
            upsideDown={isBottom}
          />
        </Tip>
        <TouchableWithoutFeedback onPress={onDismiss}>
          <Text>{text}</Text>
        </TouchableWithoutFeedback>

        {isLast ? (
          <ButtonBox isSingle>
            <TouchableOpacity onPress={onDone}>
              <ButtonText isActive>{doneText}</ButtonText>
            </TouchableOpacity>
          </ButtonBox>
        ) : (
          <ButtonBox>
            <TouchableOpacity onPress={onSkip}>
              <ButtonText>{messages.skip}</ButtonText>
            </TouchableOpacity>
            <TouchableOpacity onPress={onNext}>
              <ButtonText isActive>{nextText}</ButtonText>
            </TouchableOpacity>
          </ButtonBox>
        )}
      </Container>
    );
  }
);
