import { createAction } from "@reduxjs/toolkit";

import {
  AddMessagePayload,
  AddMessagesPayload,
  AddReactionPayload,
  DeleteMessagePayload,
} from "./types";

export const createMessage = createAction<AddMessagePayload>("createMessage");

export const createReaction =
  createAction<AddReactionPayload>("createReaction");

export const createMessages =
  createAction<AddMessagesPayload>("createMessages");

export const deleteMessage =
  createAction<DeleteMessagePayload>("deleteMessage");
