import styled from "styled-components/native";
import Icon from "@expo/vector-icons/MaterialIcons";

import { spacers } from "~/styles/theme";

export const Container = styled.View`
  flex-direction: row;
  border-radius: 6px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray400};
  margin-top: ${spacers.ss6};
`;

export const Item = styled.TouchableOpacity<{ isLast: boolean }>`
  align-items: center;
  justify-content: center;
  padding: ${spacers.ss3};
  border-right-width: ${({ isLast }) => (isLast ? 0 : 1)}px;
  border-right-color: ${({ theme }) => theme.colors.gray400};
  flex: 1;
`;

export const SectionIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.colors.gray400,
  size: 16,
}))``;
