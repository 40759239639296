import styled, { css } from "styled-components/native";
import { MaterialIcons } from "@expo/vector-icons";

import { IconSizes } from "~/components/icon";
import { Text2 } from "~/components/text";
import { spacers } from "~/styles/theme";

export const DownloadedItemContainer = styled.TouchableOpacity<{
  isLast?: boolean;
  isActive?: boolean;
}>`
  flex-direction: row;
  align-items: center;
  background-color: ${({ isActive, theme }) =>
    !isActive ? theme.colors.white : theme.colors.gray50};
  padding: ${spacers.ss5} ${spacers.ss5};

  ${({ isLast }) =>
    !isLast &&
    css`
      border-bottom-color: ${({ theme }) => theme.colors.gray100};
      border-bottom-width: 1px;
    `};
`;

export const DownloadedItemBox = styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const DownloadedItemTextWrapper = styled.View`
  flex-direction: column;
  margin: 0 ${spacers.ss5};
`;

export const DownloadedItemTitle = styled(Text2)`
  font-family: SFCompact;
`;

export const DownloadedItemSize = styled(Text2)`
  margin: ${spacers.ss4} 0 0;
  font-family: SFCompact;
`;

export const SwipeableDeleteIconContainer = styled.TouchableOpacity`
  justify-content: center;
  padding: ${spacers.ss5};
  background-color: ${({ theme }) => theme.colors.red600};
`;

export const DeleteIcon = styled(MaterialIcons).attrs(({ theme }) => ({
  name: "delete-outline",
  size: IconSizes.SMedium,
  color: theme.colors.white,
}))``;
