import styled from "styled-components/native";

import { Text3 } from "~/components/text";
import { spacers, colors } from "~/styles/theme";

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: ${spacers.ss5};
  padding-bottom: ${spacers.ss6};
`;

export const Text = styled(Text3)`
  color: ${({ theme }) => theme.colors.gray500};
`;
