import { StyleSheet } from "react-native";
import styled from "styled-components/native";
import { colors } from "~/styles/theme";
import { createShadow } from "~/utils/shadow";
import { hexToRGB } from "~/utils/colors";
import { isWeb } from "~/utils/platform";
import {
  CIRCLE_SIZE,
  CIRCLE_OFFSET,
  NEUTRAL_CIRCLE_SIZE,
} from "../../constants";

interface NeutralCircleProps {
  disabled?: boolean;
}

export const NeutralCircle = styled.View<NeutralCircleProps>`
  height: ${NEUTRAL_CIRCLE_SIZE / 3}px;
  width: ${NEUTRAL_CIRCLE_SIZE / 3}px;
  border-radius: ${NEUTRAL_CIRCLE_SIZE / 2}px;
  background-color: ${({ disabled }) =>
    disabled ? colors.gray300 : colors.gray600};
  justify-content: center;
  align-items: center;
`;

export const NeutralCircleContainer = styled.TouchableOpacity`
  height: ${CIRCLE_SIZE}px;
  width: ${CIRCLE_SIZE / 2.8}px;
  justify-content: center;
  align-items: center;
`;

export const bubbleShadow = StyleSheet.create({
  shadow: {
    ...createShadow({
      color: hexToRGB(colors.black, 0.3),
      opacity: isWeb ? 0.3 : 0.5,
      radius: isWeb ? 16 : 8,
      offsetWidth: 0,
      offsetHeight: CIRCLE_OFFSET,
      elevation: 12,
    }),
  },
}).shadow;
